// @packages
import React from 'react';
import AppQuickAccess from 'smu-app-components/AppQuickAccess';
import Header from 'smu-app-components/MainHeader';
import MainUserMenu from 'smu-app-components/MainUserMenu';
import PropTypes from 'prop-types';
import keys from 'lodash/keys';
import { Link, withRouter } from 'react-router';
import { buildImageUrl } from 'smu-utils/image';
import { connect } from 'react-redux';
import { openModal } from 'smu-app-components/RootModalV2/actions';

// @app
import MainSearch from 'containers/MainSearch';
import Notifications from 'components/Notifications';
import { errorHandler } from 'utils/errorHandler';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { logout } from 'containers/Authorization/actions';
import {
  selectOrganizationImageId,
  selectSessionUser,
  selectSessionUserId,
} from 'containers/Authorization/selectors';
import {
  ENABLED_INTERCOM,
  ENABLED_QUICK_ACCESS,
  ONBOARDING_ENABLED,
} from 'services/communityConfigs/constants';

// @own
import { getUserMenuLinks } from './helpers';

function MainHeaderContainer({
  enabledQuickAccess,
  intercomEnabled,
  livingValuesEnabled,
  livingValuesTexts,
  location,
  logout,
  onboardingEnabled,
  openModal,
  organization,
  organizationImageId,
  router,
  user,
  userID,
}) {
  const hasLivingValuesTexts = !!keys(livingValuesTexts).length;

  const communityLogo = organizationImageId
    ? buildImageUrl({
        code: organizationImageId,
        height: 40,
        width: 40,
      })
    : '';

  const handleOrganizationChanged = () => {
    window.location.reload();
  };

  const handleOpenModalSuggestions = () => {
    openModal({ modalType: 'FOOTEREXP_CONFIRMATION', modalProps: { user } });
  };

  const handleMyProfileClick = () => {
    router.push('/me');
  };

  const renderLink = ({ href, show, ...rest }, children) => (
    <Link to={href} {...rest}>
      {children}
    </Link>
  );

  const linksConfig = getUserMenuLinks({
    handleOpenModalSuggestions,
    hasLivingValuesTexts,
    intercomEnabled,
    livingValuesEnabled,
    livingValuesTexts,
    location,
    logout,
    onboardingEnabled,
    renderLink,
  });

  return (
    <Header
      components={{
        appQuickAccess: enabledQuickAccess ? <AppQuickAccess /> : null,
        notifications: <Notifications unifiedExperience />,
        search: <MainSearch />,
        userMenu: (
          <MainUserMenu
            communityLogo={communityLogo}
            linksConfig={linksConfig}
            onClickMyProfile={handleMyProfileClick}
            onError={errorHandler}
            onOrganizationChanged={handleOrganizationChanged}
            organizationId={organization?.id}
            origin="SMU_WEB_v3"
            user={user}
          />
        ),
      }}
      userUID={String(userID)}
    />
  );
}

MainHeaderContainer.propTypes = {
  enabledQuickAccess: PropTypes.bool,
  intercomEnabled: PropTypes.bool,
  livingValuesEnabled: PropTypes.bool,
  livingValuesTexts: PropTypes.object,
  location: PropTypes.object,
  logout: PropTypes.func,
  onboardingEnabled: PropTypes.bool,
  openModal: PropTypes.func,
  organization: PropTypes.object,
  router: PropTypes.object,
  user: PropTypes.object,
  userID: PropTypes.number,
};

const mapStateToProps = (state) => ({
  enabledQuickAccess: getCommunityConfigValueBool(state, ENABLED_QUICK_ACCESS),
  intercomEnabled: getCommunityConfigValueBool(state, ENABLED_INTERCOM),
  livingValuesEnabled: state?.session?.communityConfigurations?.livingValuesEnabled,
  livingValuesTexts: state?.livingValues?.texts,
  onboardingEnabled: getCommunityConfigValueBool(state, ONBOARDING_ENABLED),
  organization: state?.session?.communityConfigurations?.organization,
  organizationImageId: selectOrganizationImageId(state),
  switchLogosEnabled: state?.session?.communityConfigurations?.switchLogosEnabled,
  unifiedProfile: state?.userConfigs?.unifiedProfile,
  user: selectSessionUser(state),
  userID: selectSessionUserId(state),
});

const mapDispatchToProps = {
  logout,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(MainHeaderContainer),
);
