// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { apiGetAdvancedUserFilters } from './api';
import { REQUEST_ADVANCED_USER_FILTERS } from './actionTypes';
import {
  requestAdvancedUserFiltersFail,
  requestAdvancedUserFiltersSuccess,
} from './actions';

function* getAdvancedUserFiltersWorker({ payload }) {
  try {
    const { data } = yield call(apiGetAdvancedUserFilters, payload);
    yield put(requestAdvancedUserFiltersSuccess({ result: data?.result }));
  } catch (error) {
    yield put(requestAdvancedUserFiltersFail({ error }));
  }
}

export function* getAdvancedUserFiltersWatcher() {
  yield takeEvery(REQUEST_ADVANCED_USER_FILTERS, getAdvancedUserFiltersWorker);
}

export default {
  getAdvancedUserFiltersWatcher,
};
