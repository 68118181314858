// @packages
import React, { forwardRef } from 'react';
import Avatar from 'smu-ui-components/Avatar';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';

function ShareBadge({ badge, className, title, user }, ref) {
  return (
    <div
      className={cn('flex flex-col space-y-8 px-8 pt-8 pb-10', className)}
      style={{ backgroundColor: '#F7F7FD', width: '576px' }}
    >
      <Typography
        className="font-rubik font-semibold text-base text-blue leading-5 relative"
        style={{ top: '-9px' }}
      >
        {title}
      </Typography>
      <div className="flex space-x-5">
        <div className="flex flex-col w-1/3 flex-none items-center space-y-2">
          <Avatar height={75} src={user.imageUrl} width={75} />
          <div className="flex flex-col space-y-1 text-center">
            <Typography
              className="font-rubik font-semibold text-sm text-blue leading-5 relative"
              style={{ top: '-7px' }}
            >
              {user.name}
            </Typography>
            <Typography
              className="font-rubik text-xs text-blue relative"
              style={{ top: '-5px' }}
            >
              {user.job}
            </Typography>
          </div>
        </div>
        <Paper className="flex flex-col w-full space-y-5 p-4" ref={ref}>
          <div className="flex items-center space-x-2">
            <Avatar circular={false} height={36} src={badge.imageUrl} width={36} />
            <Typography
              className="font-rubik font-semibold text-base text-blue leading-5 relative"
              style={{ top: '-9px' }}
            >
              {badge.name}
            </Typography>
          </div>
          <Typography className="font-openSans text-sm text-blue relative" style={{ top: '-7px' }}>
            {badge.description}
          </Typography>
        </Paper>
      </div>
      <div className="flex space-x-2 ml-auto">
        <Typography
          className="font-openSans text-[10px] text-black relative"
          style={{ top: '-6px' }}
        >
          powered by
        </Typography>
        <Avatar
          circular={false}
          height={12}
          legacy={false}
          src="/assets/img/logo_smu.png"
          width={56}
        />
      </div>
    </div>
  );
}

ShareBadge.defaultProps = {
  badge: {},
  user: {},
};

ShareBadge.propTypes = {
  badge: PropTypes.shape({
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.shape({
    imageUrl: PropTypes.string,
    job: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default forwardRef(ShareBadge);
