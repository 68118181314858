import { reduce, merge } from 'lodash';

import {
  FILTERS_INITIAL,
  UPDATE_FILTERS,
} from './actionTypes';

const initialState = {
  country: {},
  city: {
    parentField: 'country',
  },
  office: {
    parentField: 'city',
  },
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case FILTERS_INITIAL:
      return { ...payload };
    case UPDATE_FILTERS:
      return { ...state, ...payload };
    default:
      return state;
  }
}

export const buildFiltersNeeded = (availableFilters, fields = {}) =>
  reduce(
    availableFilters,
    (memo, filterName) => merge({}, memo, { [filterName]: fields[filterName] }),
    {},
  );
