import { buildClosePath } from '../helpers';

const newLevel = ({ hookModal, onClose, query }) => {
  const closePath = buildClosePath(['params']);
  const handleClose = () => onClose(closePath);
  const params = query?.params ?? '';

  try {
    const decodedParams = decodeURIComponent(params);
    const decodeB64Params = window.atob(decodedParams);
    const parsedParams = JSON.parse(decodeB64Params);
    const { level, userId } = parsedParams;

    hookModal.open({
      level: Number(level),
      onClose: handleClose,
      onEmpty: handleClose,
      showOnlyIfLevelData: true,
      showOnlyIfSameUser: true,
      userId: Number(userId),
    });
  } catch (error) {
    onClose(closePath);
  }
};

export default newLevel;
