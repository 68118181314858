// @packages
import some from 'lodash/some';
import omit from 'lodash/omit';
import map from 'lodash/map';

// @own
import { ORGANIZATION_MODERATOR, BETHERE_POST } from './constants';

const isItemEnabled = (moderator, item) => moderator || item?.data?.enabled;

export const getFeedResults = (results, rights) => {
  const moderator = some(rights, (r) => r?.right === ORGANIZATION_MODERATOR);

  return map(results, (item) => {
    if (isItemEnabled(moderator, item)) return item;
    return omit(item, 'data.notes');
  });
};

export const normalizeItems = (posts) =>
  posts.reduce((acc, cur) => {
    if (cur?.type === BETHERE_POST) {
      acc.push({
        id: cur?.data?.post_id,
        data: {
          post: cur?.data,
          postInteractions: cur?.data?.comments,
        },
      });
    } else if (cur?.data?.id) {
      acc.push({
        id: cur?.data?.id,
        data: cur?.data,
      });
    }
    return acc;
  }, []);
