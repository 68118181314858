import { defineMessages } from 'react-intl';

export default defineMessages({
  ContentTypeTitleTodayBirthdays: {
    id: 'ContentType.Title.TodayBirthdays',
    defaultMessage: "TODAY'S BIRTHDAYS!",
  },
  ContentTypeTitleYesterdayBirthdays: {
    id: 'ContentType.Title.YesterdayBirthdays',
    defaultMessage: "YESTERDAY'S BIRTHDAYS!",
  },
  ContentTypeYear: {
    id: 'ContentType.Year',
    defaultMessage: '{xxx} year',
  },
  ContentTypeYears: {
    id: 'ContentType.Years',
    defaultMessage: '{xxx} years',
  },
  ContentTypeSubtitleBirthdays: {
    id: 'ContentType.Subtitle.Birthdays',
    defaultMessage: 'Let’s celebrate life, wish them a Happy Birthday!',
  },
  ContentTypeTitleTodayAnniversaries: {
    id: 'ContentType.Title.TodayAnniversaries',
    defaultMessage: "TODAY'S ANNIVERSARIES!",
  },
  ContentTypeTitleYesterdayAnniversaries: {
    id: 'ContentType.Title.YesterdayAnniversaries',
    defaultMessage: "YESTERDAY'S ANNIVERSARIES!",
  },
  ContentTypeSubtitleAnniversaries: {
    id: 'ContentType.Subtitle.Anniversaries',
    defaultMessage:
      'Celebrate another year in the organization, wish them a Happy Anniversary!!',
  },
  ContentTypeTitleCustomBirthdays: {
    id: 'ContentType.TitleCustom.Birthdays',
    defaultMessage: 'BIRTHDAYS - {value}',
  },
  ContentTypeTitleCustomAnniversaries: {
    id: 'ContentType.TitleCustom.Anniversaries',
    defaultMessage: 'ANNIVERSARIES - {value}',
  },
  ContentTypeLinkBirthdays: {
    id: 'ContentType.Link.Birthdays',
    defaultMessage: 'WISH HAPPY BIRTHDAY',
  },
  ContentTypeLinkAnniversaries: {
    id: 'ContentType.Link.Anniversaries',
    defaultMessage: 'WISH HAPPY ANNIVERSARY',
  },
  ContentTypeLinkViewProfile: {
    id: 'ContentType.Link.ViewProfile',
    defaultMessage: 'VIEW PROFILE',
  },
});
