// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFeedActivityById } from 'smu-services/feed/api/v1/activities/[id]/get';
import { withRouter } from 'react-router';

// @app
import ShareBeHealthyInsightComponent from 'components/Share/BeHealthyInsight';
import { buildImageUrl } from 'smu-utils/image';

function ShareBeHealthyInsightContainer({ onReady, router: { location } }) {
  const activityId = location?.query?.id;
  const useFeedActivityByIdHook = useFeedActivityById({ id: activityId });
  const activity = useFeedActivityByIdHook.selectResult();
  const isSuccess = Boolean(activity?.id);

  useEffect(() => {
    if (isSuccess && onReady) {
      onReady();
    }
  }, [isSuccess]);

  return (
    <ShareBeHealthyInsightComponent
      iconImageUrl={buildImageUrl({
        code: activity?.data?.iconImageCode,
        height: 150,
        width: 150,
      })}
      text={activity?.data?.title}
      type={activity?.data?.type}
    />
  );
}

ShareBeHealthyInsightContainer.propTypes = {
  onReady: PropTypes.func,
  router: PropTypes.object,
};

export default withRouter(ShareBeHealthyInsightContainer);
