// @packages
import { take, put, call } from 'redux-saga/effects';

// @own
import { apiGetBethereProperties } from './api';
import { REQUEST_BETHERE_PROPERTIES } from './actionTypes';
import {
  actionBetherePropertiesFail,
  actionBetherePropertiesSuccess,
} from './actions';

function* getBetherePropertiesWorker({ payload: { communityId } }) {
  try {
    const { data } = yield call(apiGetBethereProperties, communityId);
    yield put(actionBetherePropertiesSuccess(data?.result));
  } catch (error) {
    yield put(actionBetherePropertiesFail(error));
  }
}

export function* getBetherePropertiesWatcher() {
  yield take(REQUEST_BETHERE_PROPERTIES, getBetherePropertiesWorker);
}

export default {
  getBetherePropertiesWatcher,
};
