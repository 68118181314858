// @packages
import React from 'react';

// @app
import withCard from 'containers/withCard';
import Card from 'components/Bethere/Card';

function BethereCardDetails(props) {
  return <Card {...props} />;
}

export default withCard(BethereCardDetails);
