import {
  REQUEST_GET_INTERACTIONS_POSTS,
  REQUEST_GET_INTERACTIONS_POSTS_SUCCESS,
  REQUEST_GET_INTERACTIONS_POSTS_FAIL,
} from './actionTypes';

export function actionGetInteractionsPosts(communityId, postId) {
  return {
    type: REQUEST_GET_INTERACTIONS_POSTS,
    payload: {
      communityId,
      postId,
    },
  };
}

export function actionGetInteractionsPostsSuccess(result) {
  return {
    type: REQUEST_GET_INTERACTIONS_POSTS_SUCCESS,
    payload: {
      result,
    },
  };
}

export function actionGetInteractionsPostsFail(error) {
  return {
    type: REQUEST_GET_INTERACTIONS_POSTS_FAIL,
    payload: {
      error,
    },
  };
}
