import { useState, useEffect } from 'react';

function useWindowSize(enabled = true) {
  const isWindowClient = typeof window === 'object';
  const [windowSize, setWindowSize] = useState({
    height: enabled && isWindowClient ? window.innerHeight : undefined,
    width: enabled && isWindowClient ? window.innerWidth : undefined,
  });

  useEffect(() => {
    function setSize() {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    if (enabled && isWindowClient) {
      window.addEventListener('resize', setSize);
    }

    return () =>
      enabled &&
      isWindowClient &&
      window.removeEventListener('resize', setSize);
  }, [enabled, isWindowClient, setWindowSize]);

  return windowSize;
}

export default useWindowSize;
