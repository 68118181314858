import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from './../../api';

export function getProfile(uid) {
  const baseUrl = `/v2/stellar/users/find/${uid}`;
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times() }),
    method: 'get',
    headers: {
      language: 'en',
    },
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}
