// @packages
import React from 'react';
import IconV2 from 'smu-ui-components/IconV2';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import UserAvatarList from 'smu-ui-components/UserAvatarList';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

// @own
import './styles.scss';

function SurveyAnswerDetail({
  answer,
  className,
  isImage,
  isOpen,
  onAvatarClick,
  onClose,
  option,
  texts,
  users,
  ...rest
}) {
  return (
    <Panel className={cn('survey-answer-detail', className)}>
      <div className="survey-answer-detail__option">
        <div className="survey-answer-detail__title">{texts.option}</div>
        <div className="survey-answer-detail__text">
          {isImage
            ? (<img alt="option" className="survey-answer-detail__image" src={option} />)
            : option
          }
        </div>
      </div>
      <div className="survey-answer-detail__answer">
        <div className="survey-answer-detail__title">{texts.answer}</div>
        <div className="survey-answer-detail__text">{`${answer}%`}</div>
      </div>
      {!isEmpty(users) && (
        <div className="survey-answer-detail__users">
          <div className="survey-answer-detail__title">{texts.users}</div>
          <UserAvatarList
            onAvatarClick={onAvatarClick}
            size={30}
            users={users}
            {...rest}
          />
        </div>
      )}
      <IconV2
        className="survey-answer-detail__close"
        icon="close"
        onClick={onClose}
        size="nano"
      />
    </Panel>
  );
}

SurveyAnswerDetail.propTypes = {
  answer: PropTypes.string,
  className: PropTypes.string,
  isImage: PropTypes.bool,
  isOpen: PropTypes.bool,
  onAvatarClick: PropTypes.func,
  onClose: PropTypes.func,
  onHiddenClick: PropTypes.func,
  option: PropTypes.string,
  texts: PropTypes.func,
  users: PropTypes.array,
};

export default SurveyAnswerDetail;
