export const AVAILABLE_SURVEYS = 'AVAILABLE_SURVEYS';
export const STATUS_CLOSED = 'CLOSED';
export const STATUS_OPEN = 'OPEN';
export const STATUS_AVAILABLED_VIEWED = 'AVAILABLED_VIEWED';
export const STATUS_RESULT_VIEWED = 'RESULT_VIEWED';
export const STATUS_ARCHIVED = 'ARCHIVED';
export const SURVEYS_INITIAL_PAGE = 0;
export const SURVEYS_PAGE_SIZE = 10;
export const SURVEYS_RESULTS = 'SURVEYS_RESULTS';
export const DISMISS_SURVEYS = 'DISMISS_SURVEYS';

