// @packages
import React from 'react';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';

// @own
import './styles.scss';

const MentionUserSkeleton = ({ className }) => (
  <div className={cn('mention-user-skeleton', className)}>
    <Skeleton
      animation="wave"
      className="mention-user-skeleton__avatar"
      height={50}
      variant="circle"
      width={50}
    />
    <div className="mention-user-skeleton__wrapper">
      <Skeleton
        animation="wave"
        className="mention-user-skeleton__title"
        height={14}
        width="60%"
      />
      <Skeleton
        animation="wave"
        className="mention-user-skeleton__description"
        height={12}
        width="90%"
      />
      <Skeleton
        animation="wave"
        className="mention-user-skeleton__action"
        height={22}
        width="70%"
      />
    </div>
  </div>
);

export default MentionUserSkeleton;
