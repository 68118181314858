import { buildClosePath } from '../helpers';

const share = ({ hookModal, query, onClose }) => {
  const closePath = buildClosePath(['type', 'step']);
  hookModal.open({
    onClose: () => onClose(closePath),
    type: query?.type,
    step: query?.step,
  });
};

export default share;
