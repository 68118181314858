// @packages
import moment from 'moment';
import omit from 'lodash/omit';

// @app
import { fullName } from 'utils/user';

export default function normalice(data) {
  const reactions = data?.reactions || [];
  const like = reactions.find((r) => r.type === 'LIKE');
  const comment = reactions.find((r) => r.type === 'COMMENT');

  return {
    id: data?.id,
    referredYear: data?.referredYear,
    reactions: [
      {
        count: 0,
        hasInteracted: false,
        type: 'LIKE',
        ...like,
      },
      {
        count: 0,
        hasInteracted: false,
        type: 'COMMENT',
        ...comment,
      },
    ],
    type: data?.type,
    user: {
      firstName: data?.user?.firstName,
      id: data?.user?.id,
      lastName: data?.user?.lastName,
      profileImageCode: data?.user?.profileImageCode || data?.user?.imageCode,
      properties: {
        culturalLevelNumber: data?.user?.properties?.culturalLevelNumber,
      },
      uid: data?.user?.uuid || data?.user?.uid || data?.user?.properties?.uid,
    },
  };
}

export const normaliceMentions = (mentions = []) =>
  mentions.map(mention => ({
    ...omit(mention, ['firstName', 'lastname', 'uuid', 'imageCode']),
    profileImageCode: mention?.imageCode,
    fullName: `${mention?.firstName} ${mention?.lastName}`,
    uid: mention?.uuid,
}));


export function normaliceComments(comments = []) {
  return comments.map((comment) => ({
    ...omit(comment, ['mentions']),
    comments: comment.comment,
    dateComment: moment(comment.createdDate).subtract(
      moment().utcOffset(),
      'minutes',
    ),
    enabled: true,
    user: {
      ...comment.user,
      profileImageCode: comment?.user?.imageCode,
      uid: comment?.user?.uuid || comment?.user?.uid,
    },
    listMentions: normaliceMentions(comment?.mentions),
  }));
}

export function normaliceLikes(likes) {
  return likes?.map(({ user }) => ({
    fullName: fullName(user, 'firstName', 'lastName'),
    job: user?.job,
    profileImageCode: user?.imageCode,
    uid: user?.uuid,
  }));
}
