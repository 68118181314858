/**
 * Set a log in the local storage
 * @param {string} code - The code of the log
 * @param {string} message - The message of the log
 *
 * CODES:
 * LOGOUT_ACTION: When the user is logged out with the logout action
 * LOGOUT_APP_LOADER: When the user is logged out in the app loader component
 * TOKEN_NOT_FOUND: The user token is not found in the cookies
 * UNAUTHORIZED_ERROR: When the user is unauthorized
 */

function setLog(code, message) {
  try {
    const currentDate = new Date().toLocaleDateString('en-US');
    let logs = JSON.parse(localStorage.getItem('smu-logs'));

    if (!logs) {
      logs = {
        date: currentDate,
        logs: [],
      };
    }

    if (logs.date !== currentDate) {
      logs.date = currentDate;
      logs.logs = [];
    }

    logs.logs.unshift({
      code,
      date: new Date().toISOString(),
      message,
    });

    localStorage.setItem('smu-logs', JSON.stringify(logs));
  } catch {
    // eslint-disable-next-line no-console
    console.error('Error while setting the log', message);
  }
}

export default setLog;
