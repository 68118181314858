// @packages
import React from 'react';
import BeKind from 'smu-app-components/BeKind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @app
import { ENABLED_BEHEALTHY } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { selectSessionUserFirstName } from 'containers/Authorization/selectors';

const BeKindContainer = ({ enabledBeHealthy, userFirstName }) => {
  const handleEmptyStateActionClick = id => {
    window.location = `/home?questionnaire=${id}`;
  };

  return (
    <BeKind
      beHealthyIsEnabled={enabledBeHealthy}
      beHealthyNewQRIsEnabled
      className="p-2"
      onEmptyStateActionClick={handleEmptyStateActionClick}
      userFirstName={userFirstName}
    />
  );
};

const mapStateToProps = state => ({
  enabledBeHealthy: getCommunityConfigValueBool(state, ENABLED_BEHEALTHY),
  userFirstName: selectSessionUserFirstName(state),
});

BeKindContainer.propTypes = {
  enabledBeHealthy: PropTypes.bool,
  userFirstName: PropTypes.string,
};

export default connect(mapStateToProps)(BeKindContainer);
