// @packages
import React from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Link from 'smu-ui-components/Link';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import InfiniteScroll from 'containers/InfiniteScroll';

// @own
import './styles.scss';

function AdvancedUserSearch({
  emptyMessage,
  hasMore,
  isEmpty,
  isLoading,
  loadingType,
  onNext,
  users,
}) {
  return (
    <div
      className={cn('advanced-user-search', {
        'advanced-user-search--with-users': users?.length,
      })}
    >
      {isEmpty ? (
        <p className="advanced-user-search__empty">{emptyMessage}</p>
      ) : (
        <InfiniteScroll
          className="advanced-user-search__users"
          dataLength={users?.length || 0}
          hasMore={hasMore}
          loadingType={loadingType}
          next={onNext}
        >
          {users &&
            users.map(
              ({
                email,
                firstName,
                id,
                lastName,
                profileImageCode,
                uid,
              }) => (
                <div className="advanced-user-search__user" key={`user-${id}`}>
                  <Link to={`/profile/${uid}`}>
                    <AvatarMedia
                      className="advanced-user-search__info"
                      firstName={`${firstName} ${lastName}`}
                      identification={uid}
                      job={email}
                      noHash
                      profileImageCode={profileImageCode}
                      title={firstName}
                      type=""
                      useLink={false}
                    />
                  </Link>
                </div>
              ),
            )}
        </InfiniteScroll>
      )}
      {isLoading && (
        <LottieSpinner
          className="advanced-user-search__loader"
          height={40}
          loadingType="starmeup"
          width={40}
        />
      )}
    </div>
  );
}

AdvancedUserSearch.defaultProps = {
  emptyMessage: '',
  hasMore: false,
  isEmpty: false,
  isLoading: false,
  loadingType: 'starmeup',
  onNext: () => undefined,
  users: [],
};

AdvancedUserSearch.propTypes = {
  emptyMessage: PropTypes.string,
  hasMore: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingType: PropTypes.string,
  onNext: PropTypes.func,
  users: PropTypes.array,
};

export default AdvancedUserSearch;
