// @packages
import React from 'react';
import PropTypes from 'prop-types';
import withFullScreenModal from 'smu-app-components/FullScreenModalV2/withFullScreenModal';
import { FEEDBACK_CONFIRMATION } from 'smu-app-components/RootModalV2/modalTypes';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { openModal } from 'smu-app-components/RootModalV2/actions';
import { withRouter } from 'react-router';

// @app
import SendStarConfirmation from 'containers/SendStarConfirmation';
import SendStarFlowStatus from 'components/SendStarFlowStatus';
import SendStarModal from 'containers/SendStarModal';
import messages from 'containers/SendStar/messages';
import { DEFAULT_PARAMS } from 'containers/OSFeed/constants';
import { ORIGIN, STATUS } from 'services/sendStarFlow/constants';
import { entitiesAmount } from 'containers/SendStar/helpers';
import { getOsFeed } from 'containers/OSFeed/actions';
import { setNone } from 'services/sendStarFlow/actions';
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';
import ErrorModal from './ErrorModal';

class SendStarFlow extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancelOrClose = this.handleCancelOrClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleSendStarConfirmationClose = this.handleSendStarConfirmationClose.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const {
      editId,
      pathname,
      showSendStarWidget,
      star,
      starForMyself,
      status: newStatus,
    } = newProps;

    const { status } = this.props;

    if (status === STATUS.NONE && newStatus === STATUS.INITIAL && !editId) {
      let trackEventJson = {
        category: 'Give a Star',
        label: `Star: ${star?.nameShowed || star?.name}`,
      };

      if (showSendStarWidget) {
        trackEventJson.action = starForMyself
          ? 'Received Star Detail'
          : 'Received Star Detail Other Users';
      } else {
        trackEventJson.action = pathname === '/home' ? 'Home' : 'Profile';
      }

      trackEvent(trackEventJson);
    }
  }

  handleCancelOrClose(origin) {
    const {
      closeFullscreenModal,
      editId,
      pathname,
      setNone,
      showSendStarWidget,
      starForMyself,
    } = this.props;

    if (!editId) {
      let trackEventJson = {
        category: 'Give a Star',
        label: origin,
      };

      if (showSendStarWidget) {
        trackEventJson.action = starForMyself
          ? 'Received Star Detail'
          : 'Received Star Detail Other Users';
      } else {
        trackEventJson.action = pathname === '/home' ? 'Home' : 'Profile';
      }

      trackEvent(trackEventJson);
    }

    closeFullscreenModal();
    setNone();
  }

  handleEdit() {
    const { closeFullscreenModal, openModal } = this.props;
    closeFullscreenModal();
    openModal({ modalType: FEEDBACK_CONFIRMATION });
  }

  handleSend() {
    const {
      closeFullscreenModal,
      getOsFeed,
      openModal,
      pathname,
      router,
    } = this.props;

    closeFullscreenModal();
    openModal({ modalType: FEEDBACK_CONFIRMATION });

    if (pathname === '/home') {
      getOsFeed({ params: DEFAULT_PARAMS });

      if (router?.location?.query?.tab) {
        router.replace('/home');
      }
    }
  }

  handleSendStarConfirmationClose() {
    const { setNone } = this.props;
    setNone();
  }

  render() {
    const {
      currentStar,
      editId,
      entities,
      intl: { formatMessage },
      origin,
      star,
      status,
    } = this.props;

    const entitiesQuantity = entities ? entitiesAmount(entities) : 0;
    const isMultiple = entitiesQuantity > 1;
    const isKudos = star && star.isKudos;

    const getSendStarFlowStatusProps = () => {
      const background = currentStar?.backgroundColor;
      const color = currentStar?.backgroundColor;
      const variables = status === STATUS.SENDING && isMultiple && !isKudos
        && { n: entitiesQuantity };

      const message = {
        [STATUS.SENDING]: isMultiple && !isKudos ? 'sendingStarMultiple' : 'sendingStar',
        [STATUS.WAITING]: isMultiple && !isKudos ? 'successfulySentMultiple' : 'successfulySent',
      };

      const messageToShow = variables
        ? <FormattedMessage {...messages[message[status]]} values={{ ...variables }} />
        : <FormattedMessage {...messages[message[status]]} />;

      return ({
        background,
        color,
        messageToShow,
      });
    };

    return [ORIGIN.SEND_STAR_FLOW, ORIGIN.LIFT_STAR].includes(origin)
      ? (
        <div className="smu-send-star-flow">
          <SendStarModal
            modalProps={{ editId }}
            onCancel={() => this.handleCancelOrClose('Cancel')}
            onClose={() => this.handleCancelOrClose('Close')}
            onEdit={this.handleEdit}
            onSend={this.handleSend}
          />
          {[STATUS.SENDING, STATUS.WAITING].includes(status) && (
            <SendStarFlowStatus status={status} {...getSendStarFlowStatusProps()} />
          )}
          <SendStarConfirmation
            isModal
            onClose={this.handleSendStarConfirmationClose}
            showCloseIcon
          />
          <ErrorModal
            description={formatMessage(messages.usersNotFoundModalErrorDescription)}
            title={formatMessage(messages.usersNotFoundModalErrorTitle)}
          />
        </div>
      ) : null;
  }
}

SendStarFlow.propTypes = {
  closeFullscreenModal: PropTypes.func.isRequired,
  currentStar: PropTypes.object,
  editId: PropTypes.number,
  entities: PropTypes.arrayOf(PropTypes.object),
  getOsFeed: PropTypes.func,
  intl: PropTypes.object,
  openModal: PropTypes.func,
  origin: PropTypes.string,
  pathname: PropTypes.string,
  router: PropTypes.object,
  setNone: PropTypes.func,
  showSendStarWidget: PropTypes.bool,
  star: PropTypes.object,
  starForMySelf: PropTypes.bool,
  status: PropTypes.string.isRequired,
};

const mapStateToProps = ({ feed, routing, starFlow }) => {
  const { locationBeforeTransitions: { pathname } } = routing;

  return {
    currentStar: starFlow.star,
    editId: starFlow.editId,
    entities: starFlow.entities,
    origin: starFlow.origin,
    pathname,
    showSendStarWidget: feed.showSendStarWidget,
    star: starFlow.star,
    starForMyself: feed.starForMyself,
    status: starFlow.status,
  };
};

const mapDispatchToProps = {
  getOsFeed,
  openModal,
  setNone,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withFullScreenModal(injectIntl(SendStarFlow))),
);
