// @packages
import React, { forwardRef } from 'react';
import cn from 'classnames';

// @app
import BethereCardDetails from 'components/BethereCardDetails';
import { StarContext } from 'containers/OSFeed/OSFeedItem';

function Sponsored({ className, ...rest }, ref) {
  const { data, index } = React.useContext(StarContext);

  return (
    <div className={cn('os-feed-sponsored', className)} ref={ref}>
      <BethereCardDetails
        index={index}
        post={data}
        postLocation={data.location}
        showComments
        {...rest}
      />
    </div>
  );
}

export default forwardRef(Sponsored);
