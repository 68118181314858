// @packages
import React from 'react';
import { trackEvent } from 'smu-utils/gtm';

// @app
import Onboarding from 'containers/Onboarding';
import { withRouter } from 'react-router';

const OnboardingPage = ({ location }) => (
  <Onboarding
    onSendStar={() => {
      trackEvent({
        action: 'click',
        category: 'onboarding',
        label: 'send_star',
      });
      window.location = 'onboardingTour/close';
    }}
    onCompleteProfile={() => {
      trackEvent({
        action: 'click',
        category: 'onboarding',
        label: 'complete_profile',
      });
      window.location = '/me?goToDetail=true';
    }}
    onClose={(step) => {
      if (step) {
        trackEvent({
          action: 'close',
          category: 'onboarding',
          label: `${step}`,
        });
      }
      window.location = 'onboardingTour/close';
    }}
    onError={() => {
      window.location = 'onboardingTour/error';
    }}
    showOnlyPerformance={location?.query?.performance === 'true'}
  />
);

export default withRouter(OnboardingPage);
