// @packages
import { take, put, call } from 'redux-saga/effects';

// @own
import { apiGetBethereInteractions } from './api';
import { REQUEST_GET_BETHERE_INTERACTIONS } from './actionTypes';
import {
  actionGetBethereInteractionsFail,
  actionGetBethereInteractionsSuccess,
} from './actions';

function* getBethereGetInteractionsWorker({
  payload: { communityId, postId },
}) {
  try {
    const { data } = yield call(apiGetBethereInteractions, communityId, postId);
    yield put(actionGetBethereInteractionsSuccess(data?.result));
  } catch (error) {
    yield put(actionGetBethereInteractionsFail(error));
  }
}

export function* getBethereGetInteractionsWatcher() {
  yield take(REQUEST_GET_BETHERE_INTERACTIONS, getBethereGetInteractionsWorker);
}

export default {
  getBethereGetInteractionsWatcher,
};
