import { UPLOAD_USER_IMAGE, SET_OSFEED_PREFERENCE } from './actionTypes';

export function changeProfileImage({ file, id, ...rest }) {
  return {
    type: UPLOAD_USER_IMAGE,
    payload: { file, id, ...rest },
  };
}

export function setOsFeedPreference(payload) {
  return {
    type: SET_OSFEED_PREFERENCE,
    payload,
  };
}
