// @packages
import React from 'react';
import ButtonV2 from 'smu-ui-components/ButtonV2';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

// @app
import Title from 'components/Title';

// @own
import './styles.scss';
import messages from './messages';

function EmptyState({
  intl: {
    formatMessage,
  },
  router: { push },
}) {
  return (
    <div className="insights-empty-state" noPadding>
      <img alt="empty" className="insights-empty-state__image" src="assets/img/empty_state_insights.png" />
      <Title className="insights-empty-state__title">
        {formatMessage(messages.emptyStateTitle)}
      </Title>
      <div className="insights-empty-state__description">
        {formatMessage(messages.emptyStateDescription)}
      </div>
      <ButtonV2
        className="insights-empty-state__button"
        color="blue"
        onClick={() => push('/home')}
        size="medium"
      >
        {formatMessage(messages.emptyStateButton)}
      </ButtonV2>
    </div>
  );
}

EmptyState.defaultProps = {};

export default withRouter(injectIntl(EmptyState));
