import {
  REQUEST_TOP_STARS,
  REQUEST_TOP_STARS_SUCCESS,
  REQUEST_TOP_STARS_FAILED,
} from './actionTypes';

const initialState = {
  results: [],
  fetching: false,
  error: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_TOP_STARS:
      return {
        ...state,
        fetching: true,
      };
    case REQUEST_TOP_STARS_SUCCESS:
      return {
        ...state,
        results: payload,
        fetching: false,
        error: '',
      };
    case REQUEST_TOP_STARS_FAILED:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    default:
      return state;
  }
}
