// @packages
import React from 'react';
import ActionBrighten from 'smu-ui-components/Cards/components/ActionBrighten';
import { injectIntl } from 'react-intl';

// @own
import messages from './messages';

const ActionBrightenContainer = ({ active, intl, onClickIcon, ...rest }) => {
  const popupText = onClickIcon
    ? intl.formatMessage(messages.tooltip)
    : intl.formatMessage(messages.disabled);
  return (
    <ActionBrighten
      active={active}
      onClickIcon={onClickIcon}
      popupText={active ? '' : popupText}
      {...rest}
    />
  );
};

export default injectIntl(ActionBrightenContainer);
