import {
  GET_MUTUAL_CONNECTIONS,
  GET_MUTUAL_CONNECTIONS_SUCCESS,
  GET_MUTUAL_CONNECTIONS_FAIL,
} from './actionTypes';

export function getMutualConnections({ uid, page, pageSize }) {
  return {
    type: GET_MUTUAL_CONNECTIONS,
    payload: { uid, page, pageSize },
  };
}

export function getMutualConnectionsSuccess(payload) {
  return {
    type: GET_MUTUAL_CONNECTIONS_SUCCESS,
    payload,
  };
}

export function getMutualConnectionsFail(error) {
  return {
    type: GET_MUTUAL_CONNECTIONS_FAIL,
    payload: error,
  };
}
