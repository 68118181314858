import { takepartApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { SURVEY_USERS_PAGE_SIZE } from './constants';

export const apiGetSurveyUsers = ({
  optionId,
  page,
  questionId,
  questionnaireId,
}) =>
  takepartApiInstance({
    params: { page, size: SURVEY_USERS_PAGE_SIZE },
    url: `/api/v2/community/me/questionnaires/${questionnaireId}/questions/${questionId}/options/${optionId}/members`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
