// @packages
import addToast from 'utils/defaultToastAdd';
import { FEEDBACK_CONFIRMATION } from 'smu-app-components/RootModalV2/modalTypes';
import { call, put, takeEvery } from 'redux-saga/effects';
import { openModal } from 'smu-app-components/RootModalV2/actions';

// @own
import { VOTE_USER, VOTE_USER_SUCCESS, VOTE_USER_ERROR } from './actionTypes';
import { voteUserApi } from './api';

function* voteUserWorker(payload) {
  try {
    const data = yield call(voteUserApi, payload.payload);
    if (data.status === 'FAIL') {
      yield put(addToast({
        message: data.message,
      }));
    } else {
      yield put({ type: VOTE_USER_SUCCESS, payload: data.result });
      yield put(openModal({ modalType: FEEDBACK_CONFIRMATION }));
    }
  } catch (e) {
    yield put({ type: VOTE_USER_ERROR, payload: e });
  }
}

function* voteUserWatcher() {
  yield takeEvery(VOTE_USER, voteUserWorker);
}

export default { voteUserWatcher };
