import {
  OS_GET_FILTERS_BY_USES,
  OS_GET_FILTERS_BY_USES_FAIL,
  OS_GET_FILTERS_BY_USES_SUCCESS,
  OS_GET_FILTERS_VALUES_BY_USES,
  OS_GET_FILTERS_VALUES_BY_USES_FAIL,
  OS_GET_FILTERS_VALUES_BY_USES_SUCCESS,
} from './actionTypes';

export function getFiltersByUses(payload) {
  return {
    type: OS_GET_FILTERS_BY_USES,
    payload,
  };
}

export function getFiltersByUsesFail(payload) {
  return {
    type: OS_GET_FILTERS_BY_USES_FAIL,
    payload,
  };
}

export function getFiltersByUsesSuccess(payload) {
  return {
    type: OS_GET_FILTERS_BY_USES_SUCCESS,
    payload,
  };
}

export function getFiltersValuesByUses(payload) {
  return {
    type: OS_GET_FILTERS_VALUES_BY_USES,
    payload,
  };
}

export function getFiltersValuesByUsesFail(payload) {
  return {
    type: OS_GET_FILTERS_VALUES_BY_USES_FAIL,
    payload,
  };
}

export function getFiltersValuesByUsesSuccess(payload) {
  return {
    type: OS_GET_FILTERS_VALUES_BY_USES_SUCCESS,
    payload,
  };
}
