// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Checkbox from 'smu-ui-components/Checkbox';
import get from 'lodash/get';

// @own
import './styles.scss';

function Text({ disabled, onClick, option }) {
  const active = get(option, 'selected.data.value');
  const text = get(option, 'data.text');

  return (
    text && (
      <div
        className={cn('questionnaires-flex-type-text', {
          'questionnaires-flex-type-text--active': active,
          'questionnaires-flex-type-text--disabled': disabled,
        })}
        onClick={onClick}
      >
        {text}
        {active && (
          <div className="questionnaires-flex-type-text__checkbox">
            <Checkbox
              checked={active}
              color="white"
              variant="rounded"
            />
          </div>
        )}
      </div>
    )
  );
}

Text.defaultProps = {
  disabled: false,
};

Text.propTypes = {
  onClick: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default Text;
