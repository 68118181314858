// @packages
import { useEffect } from 'react';
import parse from 'url-parse';

// @app
import { paths } from 'utils/routes';
import { getFullPathname, getPathnameByHash } from 'utils/url';

function DefaultLayout({ router }) {
  useEffect(() => {
    const parseUrl = parse(window.location.href);
    const pathnameHash = getPathnameByHash();

    if (pathnameHash) {
      router.replace(pathnameHash);
    } else {
      parseUrl.set('pathname', paths.home());
      const path = getFullPathname(parseUrl.href);
      router.replace(path);
    }
  }, []);

  return null;
}

export default DefaultLayout;
