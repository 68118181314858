// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import { map } from 'lodash';
import Button from 'smu-ui-components/Button';

// @own
import './styles.scss';
// remove when the app will support device language
import messages from '../messages'; // eslint-disable-line

function ListItems({
  buttonText,
  currentId,
  handleSubmitAnswer,
  // remove when the app will support device language
  intl: { formatMessage }, // eslint-disable-line
  items,
  text,
  type,
}) {
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setOptionsSelected([]);
    setAnimation(true);
  }, [items]);

  function handleOptionSelected(option) {
    if (type === 'subQuestions') {
      if (!optionsSelected.includes(option) && optionsSelected.length < 2) {
        setOptionsSelected([...optionsSelected, option]);
      } else {
        const newOptionsSelected = optionsSelected.filter(options => options !== option);
        setOptionsSelected(newOptionsSelected);
      }
    } else {
      setOptionsSelected([option]);
    }
  }

  return (
    <>
      <div className="list-items">
        <div className="list-items__container">
          {type === 'subQuestions' && (
            <h4 className="list-items__title">
              {text}
            </h4>
          )}
          <div
            className={cn('list-items__content', { 'list-items__content--animate-content': animation })}
            onAnimationEnd={() => setAnimation(false)}
          >
            {map(items, (item, key) => (
              <h5
                className={cn('list-items__options', { 'list-items__options--selected': optionsSelected.includes(item) })}
                key={key}
                onClick={() => handleOptionSelected(item, 'options')}
                role="presentation"
              >
                {item}
              </h5>
            ))}
          </div>
        </div>
      </div>
      <div className="list-items__button-container">
        <Button
          className={cn('list-items__button', { 'list-items__button--disabled': !optionsSelected.length > 0 })}
          disabled={!optionsSelected.length > 0}
          noMargin
          noPadding
          onClick={() => handleSubmitAnswer(optionsSelected, currentId)}
        >
          {buttonText}
        </Button>
      </div>
    </>
  );
}

ListItems.propTypes = {
  buttonText: PropTypes.string.isRequired,
  currentId: PropTypes.number.isRequired,
  handleSubmitAnswer: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default injectIntl(ListItems);
