// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';

// @app
import CongratulationsWidget from 'components/CongratulationsWidget';

// @own
import messages from './messages';
function CongratulationsWidgetContainer({ intl }) {
  function handleClick() {
    trackEvent({
      action: 'congrats',
      category: 'home',
      label: 'click',
    });
  }

  useEffect(() => {
    trackEvent({
      action: 'congrats',
      category: 'home',
      label: 'impression',
    });
  }, []);

  return (
    <CongratulationsWidget
      linkProps={{
        href: '/congratulations',
        onClick: handleClick,
      }}
      text={intl.formatMessage(messages.congratulationsWidgetText)}
    />
  );
}

CongratulationsWidgetContainer.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CongratulationsWidgetContainer);
