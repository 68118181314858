// @own
import {
  REQUEST_USERS_BY_KUDOS_STAR_ID,
  REQUEST_USERS_BY_KUDOS_STAR_ID_CLEAN,
  REQUEST_USERS_BY_KUDOS_STAR_ID_FAIL,
  REQUEST_USERS_BY_KUDOS_STAR_ID_SUCCESS,
} from './actionTypes';

const initialState = {
  error: null,
  hasMore: false,
  page: 0,
  requesting: false,
  result: undefined,
  starId: undefined,
  total: 0,
};

const getSuccessState = (state, payload) => {
  const result = state?.page
    ? [...state.result, ...payload?.result?.content]
    : [...payload?.result?.content];
  const total = payload?.result?.total || 0;
  const hasMore = total > result.length;

  return {
    ...state,
    requesting: false,
    hasMore,
    result,
    total,
  };
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_USERS_BY_KUDOS_STAR_ID:
      return {
        ...state,
        ...payload,
        error: null,
        hasMore: true,
        page: payload.page || 0,
        requesting: true,
        starId: payload?.starId,
      };
    case REQUEST_USERS_BY_KUDOS_STAR_ID_SUCCESS:
      return getSuccessState(state, payload);
    case REQUEST_USERS_BY_KUDOS_STAR_ID_FAIL:
      return {
        ...state,
        ...payload,
        requesting: false,
        error: payload?.error,
      };
    case REQUEST_USERS_BY_KUDOS_STAR_ID_CLEAN:
      return {
        ...initialState,
        ...payload,
      };
    default:
      return state;
  }
}
