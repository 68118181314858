// @ Packages
import React, { Component } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { keys, map, merge } from 'lodash';
import PropTypes from 'prop-types';
// @ App
import Button from 'smu-ui-components/Button';
import FeedProfile from 'containers/FeedProfile';
import { feedSetOptions } from 'containers/withFeed/actions';
import FilterSelect from 'components/FilterSelect';
import { FILTER_TYPES } from 'containers/FiltersV2/constants';
import { getDefaultValues } from 'containers/FiltersV2/functions';
import { handleSelection } from 'components/Select';
import Icon from 'smu-ui-components/Icon';
import Panel from 'smu-ui-components/Panel';
import Tabs from 'smu-ui-components/Tabs';
import { trackEvent } from 'utils/gtm';

// @ Own
import { CUSTOMER, SENT, RECEIVED } from './constants';
import {
  setRecognitionFields,
} from './actions';
import messages from './messages';
import './styles.scss';

class Recognition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      showFilters: false,
    };

    this.handleApply = this.handleApply.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.renderSelect = this.renderSelect.bind(this);
    this.toogleFilters = this.toogleFilters.bind(this);
  }

  componentWillMount() {
    const {
      dispatch,
      filterSent,
      receivedFields,
      sentFields,
      userId,
    } = this.props;
    const fields = filterSent ? sentFields : receivedFields;
    if (filterSent) this.setState({ activeTab: 1 });
    dispatch(feedSetOptions({
      filterBy: filterSent ? SENT : RECEIVED,
      optionalFields: fields,
      updateFeed: true,
      useProfileUrl: true,
      userId,
    }));
  }

  componentDidUpdate(prevProps) {
    const {
      receivedFilters: prevReceivedFilters,
      sendFilters: prevSentFilters,
    } = prevProps;
    const {
      dispatch,
      receivedFields,
      receivedFilters,
      sentFields,
      sentFilters,
    } = this.props;

    const hasReceivedFilters = !!keys(receivedFilters).length;
    const hasSentFilters = !!keys(sentFilters).length;

    if (!prevReceivedFilters && receivedFilters && hasReceivedFilters) {
      const hasReceivedFields = !!keys(receivedFields).length;
      const fields = {
        receivedFields: hasReceivedFields ? receivedFields : getDefaultValues(receivedFilters),
        receivedFiltersApplied: false,
      };
      dispatch(setRecognitionFields(fields));
    }

    if (!prevSentFilters && sentFilters && hasSentFilters) {
      const hasSentFields = !!keys(sentFields).length;
      const fields = {
        sentFields: hasSentFields ? sentFields : getDefaultValues(sentFilters),
        sentFiltersApplied: false,
      };
      dispatch(setRecognitionFields(fields));
    }
  }

  componentWillUnmount() {
    const { dispatch, receivedFilters, sentFilters } = this.props;
    const fields = {
      receivedFields: getDefaultValues(receivedFilters),
      receivedFiltersApplied: false,
      sentFields: getDefaultValues(sentFilters),
      sentFiltersApplied: false,
    };
    dispatch(setRecognitionFields(fields));
  }

  handleOnFilterChange(value) {
    const { dispatch, userId } = this.props;
    trackEvent({
      category: 'CustomerMode',
      action: 'StarsFilter',
      label: value === 1 ? 'Coworkers' : 'Customers',
    });
    dispatch(feedSetOptions({
      filterBy: value === 1 ? RECEIVED : CUSTOMER,
      updateFeed: true,
      useProfileUrl: true,
      userId,
    }));
  }

  handleGoogleAnalytics({ action, category, label }) {
    trackEvent({
      action,
      category,
      label,
    });
  }

  handleApply() {
    const {
      dispatch,
      options: { filterBy },
      otherProfile,
      receivedFields,
      sentFields,
      userId,
    } = this.props;
    const isReceivedView = filterBy === RECEIVED || filterBy === CUSTOMER;
    const fields = isReceivedView ? receivedFields : sentFields;
    const filtersApplied = isReceivedView ? 'receivedFiltersApplied' : 'sentFiltersApplied';
    const selectedFields = keys(fields);
    this.toogleFilters();
    this.handleGoogleAnalytics({
      action: isReceivedView ? 'Profile_Stars_Received_Filter' : 'Profile_Stars_Sent_Filter',
      category: otherProfile ? 'Profile' : 'MyProfile',
      label: selectedFields.length ? selectedFields.join(',') : '',
    });
    const filters = {};
    filters[filtersApplied] = true;
    dispatch(setRecognitionFields(filters));
    dispatch(feedSetOptions({
      filterBy,
      optionalFields: fields,
      updateFeed: true,
      useProfileUrl: true,
      userId,
    }));
  }

  handleClear(isReceivedView) {
    const {
      dispatch,
      options: { filterBy },
      receivedFilters,
      sentFilters,
      userId,
    } = this.props;
    const fieldType = isReceivedView ? 'receivedFields' : 'sentFields';
    const filtersApplied = isReceivedView ? 'receivedFiltersApplied' : 'sentFiltersApplied';
    const fields = isReceivedView
      ? getDefaultValues(receivedFilters)
      : getDefaultValues(sentFilters);
    const fieldToClean = {};
    fieldToClean[fieldType] = fields;
    fieldToClean[filtersApplied] = false;
    this.toogleFilters();
    dispatch(setRecognitionFields(fieldToClean));
    dispatch(feedSetOptions({
      filterBy,
      optionalFields: fields,
      updateFeed: true,
      useProfileUrl: true,
      userId,
    }));
  }

  handleClickTab(category, filterBy) {
    const {
      dispatch,
      receivedFields,
      sentFields,
      userId,
    } = this.props;
    let actionTrackEvent = 'Profile_Recognitions_Received';
    const fields = filterBy === RECEIVED
      ? receivedFields
      : sentFields;

    if (filterBy === RECEIVED) {
      this.setState({ activeTab: 0 });
    } else {
      actionTrackEvent = 'Profile_Recognitions_Sent';
      this.setState({ activeTab: 1 });
    }

    trackEvent({
      category,
      action: actionTrackEvent,
    });

    dispatch(feedSetOptions({
      filterBy,
      optionalFields: fields,
      updateFeed: true,
      useProfileUrl: true,
      userId,
    }));
  }

  handleInputChange(fields, filterId, value, tab) {
    const { dispatch } = this.props;
    const fieldType = tab ? 'sentFields' : 'receivedFields';
    let selection = {};
    selection[filterId] = value;
    const selectedFields = {};
    selectedFields[fieldType] = merge(fields, selection);
    dispatch(setRecognitionFields(selectedFields));
  }

  toogleFilters() {
    this.setState(prevState => ({
      showFilters: !prevState.showFilters,
    }));
  }

  renderFilter(filter, tab, fields) {
    const { STRING } = FILTER_TYPES;
    const { type } = filter;
    if (type === STRING) {
      return this.renderInput(filter, tab, fields);
    }
    return this.renderSelect(filter, tab, fields);
  }

  renderInput(filter, tab) {
    const {
      label,
      field,
    } = filter;
    const { receivedFields, sentFields } = this.props;
    const fields = tab ? sentFields : receivedFields;
    return (
      <div className="recognition__filter-input-wrapper" key={field}>
        <span className="recognition__filter-input-label">
          {label}
        </span>
        <input
          className="recognition__filter-input"
          onChange={e => this.handleInputChange(fields, field, e.target.value, tab)}
          type="text"
          value={fields[field]}
        />
      </div>
    );
  }

  renderSelect(filter, tab, fields) {
    const {
      field,
      label,
      parentField,
      values,
    } = filter;
    const {
      dispatch,
      receivedFilters,
      sentFilters,
    } = this.props;
    const hasOptions = filter.values && keys(filter.values).length > 0;
    if (hasOptions) {
      const parentValue = parseInt(parentField ? fields[parentField] : 0, 10);
      const availableFilters = tab ? sentFilters : receivedFilters;
      const fieldType = tab ? 'sentFields' : 'receivedFields';
      const allOption = values.ALL.find(item => item.id === '0') || {};
      const optionsToRender = parentValue ? [allOption, ...values[parentValue]] : values.ALL;
      return (
        <div className="recognition__filter-select-wrapper">
          <span className="recognition__filter-select-label">
            {label}
          </span>
          <FilterSelect
            avoidTranslation
            className="recognition__filter-select"
            key={field}
            field={field}
            onChange={(selected) => {
              const selectedFields = {};
              selectedFields[fieldType] = handleSelection(
                availableFilters,
                filter,
                fields,
                field,
                selected,
              );
              dispatch(setRecognitionFields({
                ...selectedFields,
              }));
            }}
            orderByLabel
            options={optionsToRender}
            value={fields[field] || ''}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      intl: { formatMessage },
      options: { filterBy },
      otherProfile,
      receivedFields,
      receivedFilters,
      receivedFiltersApplied,
      sentFields,
      sentFilters,
      sentFiltersApplied,
      userId,
    } = this.props;

    const { activeTab, showFilters } = this.state;
    const isReceivedView = filterBy === RECEIVED || filterBy === CUSTOMER;
    const filtersApplied = isReceivedView ? receivedFiltersApplied : sentFiltersApplied;
    const profileTrackCategory = !otherProfile ? 'My Profile' : 'Profile';
    const tabs = [
      {
        name: formatMessage(messages.newProfileTabReceived),
        onClick: () => this.handleClickTab(profileTrackCategory, RECEIVED),
      },
      {
        name: formatMessage(messages.newProfileTabSent),
        onClick: () => this.handleClickTab(profileTrackCategory, SENT),
      },
    ];

    return (
      <div className="recognition">
        <Panel noPadding className="recognition-tabs-filter">
          <Tabs tabs={tabs} active={activeTab} />
          <Button
            className={cn(
              'recognition-tabs-filter__button',
              'hide-from-mobile',
              { 'recognition-tabs-filter__button--filters-applied': filtersApplied },
            )}
            noMargin
            onClick={this.toogleFilters}
            transparent
          >
            <Icon icon="filters" size="small" color={filtersApplied ? 'white' : 'black'} />
          </Button>
        </Panel>

        <Panel
          noPadding
          className={cn(
            'ui-components recognition__filter-panel',
            { 'recognition__filter-panel--active': showFilters },
          )}
        >
          <div className="recognition__filter-wrapper">
            {isReceivedView ? (
              map(keys(receivedFilters), filter =>
                this.renderFilter(
                  { field: filter, ...receivedFilters[filter] },
                  activeTab,
                  receivedFields,
                ))
            ) : (
              map(keys(sentFilters), filter =>
                this.renderFilter(
                  { field: filter, ...sentFilters[filter] },
                  activeTab,
                  sentFields,
                ))
            )}
          </div>
          <div className="recognition__filter-button-wrapper">
            <Button
              className="recognition__filter-button recognition__filter-button-cancel"
              noMargin
              onClick={() => this.handleClear(isReceivedView)}
              size="large"
              transparent
            >
              {formatMessage(messages.recognitionClearButton)}
            </Button>
            <Button
              className="recognition__filter-button"
              color="black"
              onClick={this.handleApply}
              size="large"
            >
              {formatMessage(messages.recognitionApplyButton)}
            </Button>
          </div>
        </Panel>

        <FeedProfile userId={userId} myProfile={!otherProfile} />
      </div>
    );
  }
}

Recognition.defualtProps = {
  receivedFilters: [],
  sentFilters: [],
};

Recognition.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filterSent: PropTypes.bool,
  intl: PropTypes.object,
  otherProfile: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    filterBy: PropTypes.string,
  }).isRequired,
  receivedFilters: PropTypes.array,
  receivedFiltersApplied: PropTypes.bool,
  receivedFields: PropTypes.object,
  sentFilters: PropTypes.array,
  sentFiltersApplied: PropTypes.bool,
  sentFields: PropTypes.object,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const {
    feed: { options },
    filtersV2: {
      PROFILE: receivedFilters,
      PROFILE_SENT: sentFilters,
    },
    recognition: {
      receivedFields,
      receivedFiltersApplied,
      sentFields,
      sentFiltersApplied,
    },
  } = state;

  return {
    options,
    receivedFields,
    receivedFilters,
    receivedFiltersApplied,
    sentFields,
    sentFilters,
    sentFiltersApplied,
  };
};

export default connect(mapStateToProps)(injectIntl(Recognition));
