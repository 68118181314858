import { smuInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams } from '../../api';

export function getInteractions({ uid, page, pageSize }) {
  return smuInstance({
    url: addParams(`/os-member/api/v1/community/member/connections/${uid}`, {
      page,
      pageSize,
    }),
    data: {},
    method: 'get',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
