// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'smu-ui-components/Panel';
import Skeleton from 'smu-ui-components/Skeleton';

// @own
import './styles.scss';

function SkeletonOverallActivity({ items }) {
  const buildStat = (key) => (
    <div className="skeleton-overall-activity__line" key={key}>
      <Skeleton
        className="skeleton-overall-activity__description"
        animation="wave"
        height={14}
        width={140}
      />
      <Skeleton
        animation="wave"
        className="skeleton-overall-activity__stat"
        height={14}
        width={50}
      />
    </div>
  );

  return (
    <Panel className="skeleton-overall-activity">
      <Skeleton
        animation="wave"
        className="skeleton-overall-activity__title"
        height={20}
        width={140}
      />
      {items?.map(buildStat)}
    </Panel>
  );
}

SkeletonOverallActivity.defaultProps = {
  items: Array.from(Array(2).keys()),
};

SkeletonOverallActivity.propTypes = {
  items: PropTypes.array,
};

export default SkeletonOverallActivity;
