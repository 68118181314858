// @packages
import { all, call, put, takeLatest } from 'redux-saga/effects';
import keys from 'lodash/keys';
import omit from 'lodash/omit';

// @own
import { GET_FILTERS_BY_USES } from './actionTypes';
import {
  getFiltersByUsesFail,
  getFiltersByUsesSuccess,
  updateFiltersValues,
} from './actions';
import {
  getFiltersByUses,
  getFiltersValues as getFilterValuesAPI,
} from './api';
import { getFiltersValuesParams, normalizeFiltersValues } from './functions';

function* getFiltersByUsesWorker({ payload }) {
  try {
    const data = yield call(getFiltersByUses, payload);
    const filters = data.reduce((filters, item) => {
      const { use, filters: activeFilters } = item;
      filters[use] = activeFilters.reduce((availables, item) => {
        availables[item.field] = omit(item, ['field']);
        return availables;
      }, {});
      return filters;
    }, {});

    const values = yield all(
      keys(filters).map((use) =>
        call(
          getFilterValuesAPI,
          getFiltersValuesParams({
            availableFilters: filters[use],
            use,
          }),
        )),
    );

    yield all(
      keys(filters).map((use, key) =>
        put(
          updateFiltersValues({
            [use]: normalizeFiltersValues({
              availableFilters: filters[use],
              categoryValues: values[key],
            }),
          }),
        )),
    );
    yield put(getFiltersByUsesSuccess(filters));
  } catch (e) {
    yield put(getFiltersByUsesFail(e));
  }
}

function* getFiltersByUsesWatcher() {
  yield takeLatest(GET_FILTERS_BY_USES, getFiltersByUsesWorker);
}

export default {
  getFiltersByUsesWatcher,
};
