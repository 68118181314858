// @packages
import React, { useState } from 'react';
import Avatar from 'smu-ui-components/Avatar';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import Link from 'smu-ui-components/Link';
import Menu from 'smu-ui-components/Menu';
import MenuItem from 'smu-ui-components/MenuItem';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import { buildImageUrl } from 'smu-utils/image';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import { MODAL_SHARE_BADGE, MODAL_UPLOAD_MEDIA } from 'containers/QueryModals/constants';
import { TYPE_BADGE } from 'containers/Share/constants';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { goToQueryModal } from 'containers/QueryModals/helpers';

// @own
import Header from '../Header';

const Single = ({ badge, ownId, router, texts, user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOwner = ownId === user.id;

  const handleButtonClick = event => {
    if (isOwner) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else {
      trackEvent({
        action: 'card_click',
        badge: badge.name,
        category: 'badge',
        label: 'send_star',
        type: 'single',
      });

      router.push(buildSendStarModalUrl({
        params: { uid: user?.uid },
      }));
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleShareOnSocialMedia = () => {
    handleMenuClose();

    trackEvent({
      action: 'card_click',
      badge: badge.name,
      category: 'badge',
      label: 'share',
      type: 'social_media',
    });

    goToQueryModal(MODAL_SHARE_BADGE, router, { id: badge.id });
  };

  const handleShareAsMoment = () => {
    handleMenuClose();

    trackEvent({
      action: 'card_click',
      badge: badge.name,
      category: 'badge',
      label: 'share',
      type: 'moment',
    });

    goToQueryModal(MODAL_UPLOAD_MEDIA, router, {
      comment: texts.shareAsMomentComment,
      id: badge.id,
      type: TYPE_BADGE,
    });
  };

  return (
    <Paper className="overflow-hidden" shadow>
      <Header imageCode={badge.imageCode} name={badge.name} />
      <div className="flex justify-between items-center space-x-4 px-4 py-6">
        <div className="flex space-x-4 items-center">
          <Avatar
            component={Link}
            height={64}
            href={`/profile/${user.uid}`}
            legacy={false}
            src={buildImageUrl({
              code: user.profileImageCode,
              height: 64,
              width: 64,
            })}
            width={64}
          />
          <div className="flex flex-col">
            <Typography className="font-rubik font-semibold text-sm text-blue">
              {texts.title}
            </Typography>
            {!isOwner && (
              <Typography className="font-openSans text-sm text-gray-dune">
                {texts.subtitle}
              </Typography>
            )}
          </div>
        </div>
        <Button
          className="flex-none"
          color="unifyBlue"
          enabledUnification
          endIcon={<Icon icon={isOwner ? 'share' : 'sent'} size="xsmall" />}
          onClick={handleButtonClick}
          size="medium"
          variant="outline"
        >
          {isOwner ? texts.button.share : texts.button.sendStar}
        </Button>
        {isOwner && (
          <Menu
            anchorEl={anchorEl}
            onClickOutside={handleMenuClose}
            open={!!anchorEl}
            placement="bottom-end"
          >
            <MenuItem onClick={handleShareAsMoment}>
              {texts.buttonMenu.shareAsMoment}
            </MenuItem>
            <MenuItem onClick={handleShareOnSocialMedia}>
              {texts.buttonMenu.shareOnSocialMedia}
            </MenuItem>
          </Menu>
        )}
      </div>
    </Paper>
  );
};

Single.defaultProps = {
  badge: {},
  texts: {},
  user: {},
};

Single.propTypes = {
  badge: PropTypes.shape({
    id: PropTypes.number,
    imageCode: PropTypes.string,
    name: PropTypes.string,
  }),
  ownId: PropTypes.number,
  router: PropTypes.object,
  texts: PropTypes.shape({
    button: PropTypes.shape({
      sendStar: PropTypes.string,
      share: PropTypes.string,
    }),
    buttonMenu: PropTypes.shape({
      shareAsMoment: PropTypes.string,
      shareOnSocialMedia: PropTypes.string,
    }),
    shareAsMomentComment: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    profileImageCode: PropTypes.string,
    uid: PropTypes.string,
  }),
};

export default withRouter(Single);
