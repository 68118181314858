import React from 'react';
import PropTypes from 'prop-types';

const Stroboscope = ({
  className,
  text,
}) => (
  <div className={`new-features__stroboscope ${className}`}>
    <div className="new-features__stroboscope-front">{text}</div>
    <div className="new-features__stroboscope-middle">{text}</div>
    <div className="new-features__stroboscope-back">{text}</div>
  </div>
);

Stroboscope.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default Stroboscope;
