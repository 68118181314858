// @packages
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';

// @own
import { DEFAULT_STAR, MAX_COMMENT_CHARACTERS, ORIGIN, STATUS } from './constants';
import {
  ADD_LIFT_STAR_ENTITY,
  CHANGE_SELECTED_STAR,
  GET_REMAINING_STARS,
  GET_REMAINING_STARS_FAIL,
  GET_REMAINING_STARS_SUCCESS,
  LIFT_STAR,
  LIFT_STAR_FAIL,
  LIFT_STAR_SUCCESS,
  SET_EMOJI,
  STAR_FLOW_CLEAN_PLUS_RECEIVED,
  STAR_FLOW_CLOSE_SUGGEST_SENT_STAR,
  STAR_FLOW_COMMENT,
  STAR_FLOW_COMMENT_COUNTER,
  STAR_FLOW_DEC_OFFSET,
  STAR_FLOW_DEC_OFFSET_CC,
  STAR_FLOW_ENTITIES,
  STAR_FLOW_ENTITY,
  STAR_FLOW_ENTITY_CC,
  STAR_FLOW_ENTITIES_CC,
  STAR_FLOW_ERROR,
  STAR_FLOW_GET_MENTION,
  STAR_FLOW_GET_MENTION_ERROR,
  STAR_FLOW_GET_MENTION_SUCCESS,
  STAR_FLOW_GET_STAR_BY_ID,
  STAR_FLOW_GET_STAR_BY_ID_FAIL,
  STAR_FLOW_GET_STAR_BY_ID_SUCCESS,
  STAR_FLOW_INC_OFFSET,
  STAR_FLOW_INC_OFFSET_CC,
  STAR_FLOW_INITIAL,
  STAR_FLOW_NONE,
  STAR_FLOW_OPEN_SUGGEST_SENT_STAR,
  STAR_FLOW_PLUS_RECEIVED,
  STAR_FLOW_REMOVE_ENTITY,
  STAR_FLOW_REMOVE_ENTITY_CC,
  STAR_FLOW_REMOVE_IMAGE,
  STAR_FLOW_SEND,
  STAR_FLOW_SENT,
  STAR_FLOW_SENT_WAITING,
  STAR_FLOW_SERVER_ERROR,
  STAR_FLOW_TOGGLE_PICKER,
  STAR_FLOW_UPLOAD_IMAGE,
  STAR_FLOW_UPLOAD_IMAGE_FAILED,
  STAR_FLOW_UPLOAD_IMAGE_SUCCESS,
} from './actionTypes';

const initialState = {
  comment: undefined,
  coreValueId: undefined,
  editId: undefined,
  editorState: undefined,
  emoji: undefined,
  entities: [],
  entitiesQuantity: 0,
  entitiesCC: [],
  errorCode: undefined,
  fetchingMentions: false,
  fetchingStarById: false,
  hasStarsToSend: false,
  image: null,
  liftStarEntities: [],
  listMentions: undefined,
  mentionError: undefined,
  mentionResult: undefined,
  mustPlus: false,
  offset: 0,
  offsetCC: 0,
  origin: ORIGIN.SEND_STAR_FLOW,
  pickerVisibility: false,
  remainingCounter: undefined,
  remainingStars: {
    hasKudosRemaining: false,
    hasUnlimitedStars: false,
    remainingStarCounter: {},
  },
  remainingStarsRequest: {
    error: null,
    requesting: false,
  },
  sendError: undefined,
  showConfetti: false,
  star: undefined,
  status: STATUS.NONE,
  suggestSendStar: false,
  uploadingImage: false,
};

const addEntity = (entities, entity) =>
  uniqBy([...(entities || []), entity], entity => entity.id).reverse();

export default function reducer(state = initialState, { type, payload }) {
  const newState = omit(state, 'sendError');
  switch (type) {
    case ADD_LIFT_STAR_ENTITY:
      return {
        ...newState,
        liftStarEntities: [...newState.liftStarEntities, payload.id],
      };
    case CHANGE_SELECTED_STAR:
      return {
        ...newState,
        star: payload.star,
      };
    case GET_REMAINING_STARS:
      return {
        ...newState,
        remainingStarsRequest: {
          error: null,
          requesting: true,
        },
      };
    case GET_REMAINING_STARS_FAIL:
      return {
        ...newState,
        remainingStarsRequest: {
          error: payload?.error,
          requesting: false,
          success: false,
        },
      };
    case GET_REMAINING_STARS_SUCCESS:
      return {
        ...newState,
        hasStarsToSend:
          payload?.remainingStarCounter?.remaining > 0 ||
          payload?.hasUnlimitedStars ||
          payload?.hasKudosRemaining,
        remainingStars: payload,
        remainingCounter: (
          payload?.remainingStarCounter && payload?.remainingStarCounter?.remaining
        ) || 0,
        remainingStarsRequest: {
          error: null,
          requesting: false,
          success: true,
        },
      };
    case LIFT_STAR:
      return {
        ...newState,
        origin: ORIGIN.LIFT_STAR,
        star: payload.starValues,
        status: STATUS.SENDING,
      };
    case LIFT_STAR_FAIL:
      return {
        ...newState,
        status: STATUS.NONE,
      };
    case LIFT_STAR_SUCCESS:
      return {
        ...newState,
        entities: [payload.toUser],
        status: STATUS.LAST,
      };
    case SET_EMOJI:
      return {
        ...newState,
        emoji: payload || '',
      };
    case STAR_FLOW_CLEAN_PLUS_RECEIVED:
      return {
        ...initialState,
        hasStarsToSend: newState.hasStarsToSend,
        remainingCounter: newState.remainingCounter,
        remainingStars: newState.remainingStars,
        remainingStarsRequest: newState.remainingStarsRequest,
      };
    case STAR_FLOW_CLOSE_SUGGEST_SENT_STAR:
      return {
        ...newState,
        suggestSendStar: false,
      };
    case STAR_FLOW_COMMENT:
      return {
        ...newState,
        comment: payload,
      };
    case STAR_FLOW_COMMENT_COUNTER:
      return {
        ...newState,
        commentLength: payload || 0,
      };
    case STAR_FLOW_DEC_OFFSET:
      return {
        ...newState,
        offset: newState.offset < 1 ? 0 : newState.offset - 1,
      };
    case STAR_FLOW_DEC_OFFSET_CC:
      return {
        ...newState,
        offsetCC: newState.offsetCC < 1 ? 0 : newState.offsetCC - 1,
      };
    case STAR_FLOW_ENTITIES:
      return {
        ...newState,
        entities: payload?.entities,
        entitiesCC: newState?.entitiesCC?.filter(
          (c) => !payload?.entities?.some((e) => e.id === c.id),
        ),
      };
    case STAR_FLOW_ENTITIES_CC:
      return {
        ...newState,
        entitiesCC: payload?.entities,
      };
    case STAR_FLOW_ENTITY:
      if (payload) {
        return {
          ...newState,
          entities: addEntity(newState.entities, payload) || [],
        };
      }
      return {
        ...omit(newState, ['entities', 'comment']),
        offset: 0,
      };
    case STAR_FLOW_ENTITY_CC:
      if (payload) {
        return {
          ...newState,
          entitiesCC: addEntity(newState.entitiesCC, payload) || [],
        };
      }
      return {
        ...omit(newState, ['entitiesCC', 'comment']),
        offsetCC: 0,
      };
    case STAR_FLOW_ERROR:
      return {
        ...newState,
        status: STATUS.INITIAL,
      };
    case STAR_FLOW_GET_MENTION:
      return {
        ...newState,
        fetchingMentions: true,
      };
    case STAR_FLOW_GET_MENTION_ERROR:
      return {
        ...newState,
        fetchingMentions: false,
        mentionError: payload,
      };
    case STAR_FLOW_GET_MENTION_SUCCESS:
      return {
        ...newState,
        fetchingMentions: false,
        mentionResult: payload,
      };
    case STAR_FLOW_INC_OFFSET:
      return {
        ...newState,
        offset: newState.offset + 1,
      };
    case STAR_FLOW_INC_OFFSET_CC:
      return {
        ...newState,
        offsetCC: newState.offsetCC + 1,
      };
    case STAR_FLOW_INITIAL:
      return {
        ...newState,
        comment: payload?.comment,
        coreValueId: payload?.coreValueId,
        editId: payload?.editId,
        entities: payload?.entities || initialState.entities,
        origin: payload?.origin || initialState.origin,
        star: payload?.star || DEFAULT_STAR,
        status: STATUS.INITIAL,
      };
    case STAR_FLOW_NONE:
      return {
        ...initialState,
        hasStarsToSend: newState.hasStarsToSend,
        remainingCounter: newState.remainingCounter,
        remainingStars: newState?.remainingStars,
        remainingStarsRequest: newState.remainingStarsRequest,
      };
    case STAR_FLOW_OPEN_SUGGEST_SENT_STAR:
      return {
        ...newState,
        suggestSendStar: true,
      };
    case STAR_FLOW_PLUS_RECEIVED:
      return {
        ...newState,
        mustPlus: true,
      };
    case STAR_FLOW_REMOVE_ENTITY:
      return {
        ...newState,
        entities: filter(newState.entities, entity => entity.id !== payload),
        comment: !newState.entities.lenght && undefined,
        entitiesCC: !newState.entities.lenght && undefined,
      };
    case STAR_FLOW_REMOVE_ENTITY_CC:
      return {
        ...newState,
        entitiesCC: filter(newState.entitiesCC, entity => entity.id !== payload),
      };
    case STAR_FLOW_REMOVE_IMAGE:
      return {
        ...newState,
        image: null,
      };
    case STAR_FLOW_SEND:
      return {
        ...newState,
        status: STATUS.SENDING,
      };
    case STAR_FLOW_SENT:
      return {
        ...newState,
        status: STATUS.LAST,
        showConfetti: payload,
      };
    case STAR_FLOW_SENT_WAITING:
      return {
        ...newState,
        status: STATUS.WAITING,
      };
    case STAR_FLOW_SERVER_ERROR:
      return {
        ...newState,
        errorCode: payload,
        status: STATUS.INITIAL,
      };
    case STAR_FLOW_TOGGLE_PICKER:
      return {
        ...newState,
        pickerVisibility: !newState.pickerVisibility,
      };
    case STAR_FLOW_UPLOAD_IMAGE:
      return {
        ...newState,
        uploadingImage: true,
        image: { file: payload.name },
      };
    case STAR_FLOW_UPLOAD_IMAGE_FAILED:
      return {
        ...newState,
        uploadingImage: false,
      };
    case STAR_FLOW_UPLOAD_IMAGE_SUCCESS:
      return {
        ...newState,
        uploadingImage: false,
        image: { ...state.image, ...payload },
      };
    case STAR_FLOW_GET_STAR_BY_ID:
      return {
        ...newState,
        fetchingStarById: true,
      };
    case STAR_FLOW_GET_STAR_BY_ID_FAIL:
      return {
        ...newState,
        fetchingStarById: false,
      };
    case STAR_FLOW_GET_STAR_BY_ID_SUCCESS:
      return {
        ...state,
        comment: payload?.comment,
        entities: payload?.entities,
        entitiesQuantity: payload?.entitiesQuantity,
        fetchingStarById: false,
        image: { code: payload?.imageCode },
        listMentions: payload?.listMentions,
        star: payload?.star,
      };
    default:
      return state;
  }
}
