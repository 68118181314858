
export const sortMessages = (messages = [], sortBy = 'dateComment') => {
  const sortedMessages = messages.sort((a, b) => {
    const dateA = new Date(a[sortBy]).getTime();
    const dateB = new Date(b[sortBy]).getTime();

    return dateA - dateB;
  });

  return sortedMessages;
};
