// @own
import { buildClosePath } from '../helpers';

const beHealthyEnroll = ({ hookModal, onClose }) => {
  const closePath = buildClosePath();

  hookModal.open({
    onClose: () => onClose(closePath),
  });
};

export default beHealthyEnroll;
