export const normalizeItems = (posts) =>
  posts.reduce((acc, cur) => {
    if (cur?.id) {
      acc.push({
        id: cur?.id,
        data: cur,
      });
    }
    return acc;
  }, []);
