// @own
import React, { Fragment } from 'react';
import InputSelect from 'smu-ui-components/InputSelect';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { get } from 'lodash';
import Icon from 'smu-ui-components/Icon';

// @own
import image from './assets/image.svg';
import Chip from './Chip';
import './styles.scss';
import {
  CHIPS_AVAILABLE_DAYS,
  VALUE_DAYS,
  VALUE_END_TIME,
  VALUE_PREFERENCES,
  VALUE_START_TIME,
} from './constants';

function ChillOutConfig({
  className,
  defaultEndtime,
  defaultStartTime,
  formatTime,
  intervalTime,
  onChange,
  values,
}) {
  const startTime = get(values, 'startTime', defaultStartTime);
  const endTime = get(values, 'endTime', defaultEndtime);
  const days = get(values, 'days', []);
  const preferences = get(values, 'preferences', []);
  const hobbies = get(values, 'hobbies', []);

  const handleOnChangeDay = (day) => {
    const index = days.findIndex(d => d === day);
    if (index > -1) {
      onChange(
        VALUE_DAYS,
        days.filter((_, key) => key !== index),
      );
    } else {
      onChange(VALUE_DAYS, days.concat(day));
    }
  };

  const handleOnChangePreferences = (preference, key) => {
    preferences[key] = {
      ...preference,
      value: !preference.value,
    };

    onChange(VALUE_PREFERENCES, preferences);
  };

  const handleOnChangeHobbies = (hobbie, key) => {
    hobbies[key] = {
      ...hobbie,
      value: !hobbie.value,
    };

    onChange(VALUE_PREFERENCES, preferences);
  };

  const getTimes = () =>
    Array.from(Array(24 * (60 / intervalTime)), (_, i) => i).reduce((acc, cur) => {
      let time = moment()
        .startOf('day')
        .add({ minutes: cur * intervalTime })
        .format(formatTime);
      acc.push({
        displayValue: time,
        value: time,
      });
      return acc;
    }, []);

  const times = getTimes();
  const startTimes = times.filter(time => time.value !== endTime &&
    moment(time.value, formatTime) < moment(endTime, formatTime));

  const endTimes = times.filter(time =>
    time.value !== startTime && moment(time.value, formatTime) > moment(startTime, formatTime));

  return (
    <div className={cn('suite-widget-chillout-config', className)}>
      <div className="suite-widget-chillout-config__section">
        <img className="suite-widget-chillout-config__image" alt="Chill out" src={image} />
      </div>
      <div className="suite-widget-chillout-config__section">
        <div className="suite-widget-chillout-config__options">
          <label className="suite-widget-chillout-config__options-title">AVAILABLE DAYS</label>
          <div className="suite-widget-chillout-config__options-content">
            {CHIPS_AVAILABLE_DAYS.map(({ day, ...rest }) => (
              <Chip
                className="suite-widget-chillout-config__options-chip"
                clickable
                key={`available-days-${day}`}
                onClick={() => handleOnChangeDay(day)}
                outlined={!days.find(d => d === day)}
                {...rest}
              />
            ))}
          </div>
        </div>
        <div className="suite-widget-chillout-config__options">
          <label className="suite-widget-chillout-config__options-title">AVAILABLE HOURS</label>
          <div className="suite-widget-chillout-config__options-content suite-widget-chillout-config__options-content--time">
            <InputSelect
              formFieldClassName="suite-widget-chillout-config__select-time"
              label="From"
              onChange={e => onChange(VALUE_START_TIME, e.target.value)}
              options={startTimes}
              value={startTime}
            />
            <InputSelect
              formFieldClassName="suite-widget-chillout-config__select-time"
              label="To"
              onChange={e => onChange(VALUE_END_TIME, e.target.value)}
              options={endTimes}
              value={endTime}
            />
          </div>
        </div>
        <div className="suite-widget-chillout-config__options">
          {!!preferences.length && (
            <Fragment>
              <label className="suite-widget-chillout-config__options-title">
                WE SHOULD SUGGEST YOU CHILL-OUTS BY
              </label>
              <div className="suite-widget-chillout-config__options-content">
                {preferences.map((preference, key) => (
                  <Chip
                    className="suite-widget-chillout-config__options-chip"
                    clickable
                    key={`preferences-${key}`}
                    onClick={() => handleOnChangePreferences(preference, key)}
                    outlined={!preference.value}
                  >
                    {preference.label}
                  </Chip>
                ))}
              </div>
            </Fragment>
          )}
          {!!hobbies.length && (
            <Fragment>
              <label className="suite-widget-chillout-config__options-title">
                BASED ON YOUR HOBBIES AND PREFERENCES
              </label>
              <div className="suite-widget-chillout-config__options-content">
                {hobbies.map((hobbie, key) => (
                  <Chip
                    className="suite-widget-chillout-config__options-chip"
                    clickable
                    key={`hobbies-${key}`}
                    onClick={() => handleOnChangeHobbies(hobbie, key)}
                    outlined={!hobbie.value}
                  >
                    {hobbie.label}
                  </Chip>
                ))}
              </div>
            </Fragment>
          )}
          <div className="suite-widget-chillout-config__options-warning">
            <Icon
              className="suite-widget-chillout-config__options-warning-icon"
              color="black"
              icon="info"
              size="jumbo"
              type="base"
            />
            <span className="suite-widget-chillout-config__options-warning-text">
              Remember to update your hobbies & interests in your profile
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

ChillOutConfig.propTypes = {
  className: PropTypes.string,
  defaultEndtime: PropTypes.string,
  defaultStartTime: PropTypes.string,
  formatTime: PropTypes.string,
  intervalTime: PropTypes.number,
  onChange: PropTypes.func,
  values: PropTypes.object,
};

ChillOutConfig.defaultProps = {
  className: '',
  defaultEndtime: '23:30',
  defaultStartTime: '00:00',
  formatTime: 'HH:mm',
  intervalTime: 30,
  onChange: () => {},
  values: {},
};

export default ChillOutConfig;
