// @packages
import React from 'react';
import PropTypes from 'prop-types';
import EmojiPicker from 'smu-ui-components/EmojiPicker';
import onClickOutside from 'smu-utils/onClickOutside';
import { getUserLanguage } from 'smu-utils/language';

const Emojis = ({ autoFocus, handleClick, style }) => (
  <div className="smu-emojis" onClick={handleClick} style={style}>
    <EmojiPicker
      autoFocus={autoFocus}
      locale={getUserLanguage()}
      native
      onClick={handleClick}
      sheetSize={16}
      showPreview={false}
      showSkinTones={false}
      style={{
        boxShadow: '0px 10px 30px -20px rgba(0,0,0,.5)',
        width: '100%',
      }}
      title=""
    />
  </div>
);

Emojis.propTypes = {
  autoFocus: PropTypes.bool,
  handleClick: PropTypes.func,
  style: PropTypes.object,
};

Emojis.defaultProps = {
  autoFocus: true,
  style: {
    position: 'absolute',
    right: '170px',
    top: '440px',
    width: '280px',
  },
};

export default onClickOutside(Emojis, {
  handleClickOutside: (instance) => () => {
    instance.props.onClickOutside();
  },
});
