import React from 'react';
import Container from 'smu-layout-components/Container';
// @App
import NewFeatures from 'containers/NewFeatures';
// @Own
import './styles.scss';

const NewFeaturesLayout = () => (
  <div>
    <Container centered className="new-features-layout ">
      <NewFeatures />
    </Container>
  </div>
);

export default NewFeaturesLayout;
