// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import Switch from 'smu-ui-components/Switch';
import isEqual from 'lodash/isEqual';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';

// @app
import EmptyState from 'containers/EmptyState';
import EmptyStateImage from 'containers/EmptyState/assets/empty_state.svg';
import InfiniteScroll from 'components/InfiniteScroll';
import SkeletonOSFeed from 'components/Skeletons/OSFeed';
import { COMMUNICATION } from 'containers/CommunicationsFeed/constants';
import { DISABLED_FEED_ORDERBY_TOGGLE } from 'services/communityConfigs/constants';
import { castStringToBoolean } from 'utils/formatString';
import { getCommunityConfigValue } from 'services/communityConfigs/selectors';
import {
  selectOsFeed,
  selectOsFeedCommentLoadingId,
  selectOsFeedCustomResult,
  selectOsFeedEnabled,
  selectOsFeedFetching,
  selectOsFeedHasMore,
  selectOsFeedParams,
  selectOsFeedSendingComment,
  selectOsFeedSendingLike,
  selectOsFeedToggleFetching,
  selectOsFeedTotalElements,
} from 'containers/OSFeed/selectors';
import { selectSessionUserId } from 'containers/Authorization/selectors';

// @own
import './styles.scss';
import * as actions from './actions';
import OSFeedItem from './OSFeedItem';
import OSFeedBanner from './OSFeedBanner';
import messages from './messages';
import {
  DEFAULT_PARAMS,
  HIGHLIGHTS,
} from './constants';

const OSFeed = ({
  api,
  app,
  children,
  disabledFeedOrderByToggle,
  fetching,
  filters,
  getEmptyStateCustom,
  getOsFeed,
  getOsFeedHighlights,
  getOsFeedPreferenceV2,
  hasMore,
  intl: { formatMessage },
  isMedia,
  noResults,
  onLoaded,
  osFeedEnabled,
  osFeedParams,
  ownId,
  postOsFeedPreferenceV2,
  postOsFeedVisitedPosts,
  results,
  setOsFeedParams,
  showFeedOrderByToggle,
  showHighlights,
  toggleFetching,
}) => {
  const customFilters = Object.keys(filters)
    .filter(key => filters[key])
    .reduce((acc, actualItem) => {
      acc = { ...acc, [actualItem]: filters[actualItem] };
      return acc;
    }, {});
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const prevOsFeedParams = usePrevious(osFeedParams);
  const prevFetching = usePrevious(fetching);
  const prevShowHighlights = usePrevious(showHighlights);
  const prevFilters = usePrevious(customFilters);
  const updateFilters = (prevFilters && !isEqual(prevFilters, customFilters)) || false;
  const updateFeedParams = (prevOsFeedParams && !isEqual(prevOsFeedParams, osFeedParams)) || false;
  const updateHighlights =
    prevShowHighlights !== undefined && prevShowHighlights !== showHighlights;
  const isCommunications = app === COMMUNICATION;
  const showLoader = isLoading && !results.length;

  const fetchData = (params) => {
    if (!params?.page) {
      setIsLoading(true);
    }

    if (showHighlights) {
      getOsFeedHighlights({ api, params: DEFAULT_PARAMS });
    } else {
      const osFeedParamsToObject = osFeedParams.reduce((acc, actualItem) => {
        acc = { ...acc, ...actualItem };
        return acc;
      }, {});
      getOsFeed({
        params: {
          ...DEFAULT_PARAMS,
          ...customFilters,
          ...osFeedParamsToObject,
          ...params,
          app,
        },
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      postOsFeedVisitedPosts();
    }, 600000);
    fetchData({});
    getOsFeedPreferenceV2();

    return () => {
      postOsFeedVisitedPosts();
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (updateFilters || updateFeedParams || updateHighlights) {
      setPage(0);
      fetchData({});
    }
  }, [updateFilters, updateFeedParams, showHighlights]);

  useEffect(() => {
    if (prevFetching && !fetching) {
      setIsLoading(false);
    } else if (fetching && !isLoading) {
      setIsLoading(true);
    }
  }, [prevFetching, fetching, isLoading]);

  function getNextItems() {
    const nextPage = page + 1;
    const params = {
      page: nextPage,
    };

    setPage(nextPage);
    fetchData(params);
  }

  const handleToggleClick = () => {
    trackEvent({
      category: 'Recent_Sort_By_Connections',
      action: 'Interactions',
    });
    setPage(0);
    setOsFeedParams({
      fields: {},
      options: {},
    });
    postOsFeedPreferenceV2({
      api,
      osFeed: !osFeedEnabled,
      tab: showHighlights ? HIGHLIGHTS : undefined,
    });
  };

  const handleBulletClick = () => {
    trackEvent({
      action: 'Dots',
      category: 'Feed banner',
      label: 'Click a dot',
    });
  };

  function getBannerItem() {
    const bannerArray = results.find(item => item.type === 'BANNER_ARRAY');
    const images = bannerArray?.data?.banners?.map(item => ({
      original: item?.data?.image_url,
      link: item?.data?.hyperlink,
      name: item?.data?.name,
      bulletOnClick: handleBulletClick,
    }));
    if (images?.length) {
      return <OSFeedBanner images={images}/>;
    }
    return null;
  }

  function getFeedItem() {
    return (
      results.length > 0 &&
      results.map((item, index) => (
        <OSFeedItem
          data={{
            id: item.id,
            data: item.data,
            date: item.data.date,
          }}
          firstCard={index === 0}
          index={index}
          key={`os-feed-item-${item?.id}-${index}`}
          ownId={ownId}
          type={item.type}
        >
          {children}
        </OSFeedItem>
      ))
    );
  }

  function getEmptyState() {
    if (getEmptyStateCustom && noResults) {
      return getEmptyStateCustom();
    }
    return (
      <Segment loading={fetching || toggleFetching} withChildren={false} loadingType="starmeup">
        {noResults && (
          <EmptyState
            imgSrc={EmptyStateImage}
            messages={{
              subtitle: formatMessage(messages.refreshActivityFeedLater),
              title: formatMessage(messages.issueActivityFeed),
            }}
          />
        )}
      </Segment>
    );
  }

  useEffect(() => {
    if (!isLoading && results?.length && onLoaded) {
      onLoaded();
    }
  }, [isLoading]);

  if (showLoader) {
    return (
      <div className="os-feed">
        <SkeletonOSFeed media={isMedia}/>
      </div>
    );
  }

  return (
    <div className="os-feed">
      {showFeedOrderByToggle && !disabledFeedOrderByToggle && !isCommunications && (
        <div className="os-feed__toggle">
          <p className="os-feed__toggle-description">
            {formatMessage(messages.personalizedFeedToggle)}
          </p>
          <Switch
            checked={osFeedEnabled}
            disabled={fetching}
            idName="PersonalizedFeed"
            onChange={handleToggleClick}
            type="admin"
          />
        </div>
      )}
      {getBannerItem()}
      {results.length > 0 ? (
        <InfiniteScroll
          className="space-y-3"
          dataLength={results.length}
          hasMore={hasMore}
          loader={null}
          loadingType="starmeup"
          next={getNextItems}
        >
          {getFeedItem()}
          {hasMore && (
            <SkeletonOSFeed media={isMedia} items={isMedia ? 1 : 2} />
          )}
        </InfiniteScroll>
      ) : (
        getEmptyState()
      )}
    </div>
  );
};

OSFeed.defaultProps = {
  filters: {},
  isMedia: false,
  showFeedOrderByToggle: true,
};

OSFeed.propTypes = {
  api: PropTypes.func.isRequired,
  children: PropTypes.node,
  cleanOsFeed: PropTypes.func,
  cleanOsFeedParams: PropTypes.func,
  disabledFeedOrderByToggle: PropTypes.bool,
  fetching: PropTypes.bool,
  filters: PropTypes.object,
  getEmptyStateCustom: PropTypes.func,
  getOsFeed: PropTypes.func,
  getOsFeedHighlights: PropTypes.func,
  getOsFeedPreferenceV2: PropTypes.func,
  hasMore: PropTypes.bool,
  intl: PropTypes.object,
  isMedia: PropTypes.bool,
  loadingId: PropTypes.number,
  noResults: PropTypes.bool,
  onLoaded: PropTypes.func,
  osFeedEnabled: PropTypes.bool,
  osFeedParams: PropTypes.array,
  postOsFeedComment: PropTypes.func,
  postOsFeedLike: PropTypes.func,
  postOsFeedLoadImage: PropTypes.func,
  postOsFeedModerateComment: PropTypes.func,
  postOsFeedPreferenceV2: PropTypes.func.isRequired,
  postOsFeedRemoveLike: PropTypes.func,
  results: PropTypes.array.isRequired,
  sendingComment: PropTypes.bool,
  sendingLike: PropTypes.bool,
  setOsFeedParams: PropTypes.func,
  showFeedOrderByToggle: PropTypes.bool,
  showHighlights: PropTypes.bool,
  showProfile: PropTypes.func,
  showSendStarWidget: PropTypes.bool,
  tabSelected: PropTypes.string,
  toggleFetching: PropTypes.bool,
  totalElements: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  const { userRights } = ownProps;
  const disabledFeedOrderByToggle = getCommunityConfigValue(state, DISABLED_FEED_ORDERBY_TOGGLE);
  const loadingId = selectOsFeedCommentLoadingId(state);
  const osFeedEnabled = selectOsFeedEnabled(state);
  const osFeedFetching = selectOsFeedFetching(state);
  const osFeedParams = selectOsFeedParams(state);
  const sendingComment = selectOsFeedSendingComment(state);
  const sendingLike = selectOsFeedSendingLike(state);
  const totalElements = selectOsFeedTotalElements(state);
  const { showSendStarWidget } = selectOsFeed(state);
  const toggleFetching = selectOsFeedToggleFetching(state);
  const feedResults = selectOsFeedCustomResult(state, userRights);
  const hasMore = selectOsFeedHasMore(state);
  const ownId = selectSessionUserId(state);

  return {
    disabledFeedOrderByToggle: castStringToBoolean(disabledFeedOrderByToggle),
    fetching: osFeedFetching,
    hasMore,
    loadingId,
    noResults: feedResults && feedResults.length === 0 && !osFeedFetching,
    osFeedEnabled,
    osFeedParams,
    ownId,
    results: feedResults,
    sendingComment,
    sendingLike,
    showSendStarWidget,
    toggleFetching,
    totalElements,
  };
};

export default connect(mapStateToProps, actions)(injectIntl(OSFeed));
