// @packages
import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'smu-ui-components/UserAvatar';
import cn from 'classnames';

// @app
import Title from 'components/Title';

// @own
import './styles.scss';

function HeaderUsersAvatars({
  description,
  maxDisplay,
  title,
  users,
}) {
  const avatars = users.slice(0, maxDisplay);
  return (
    <div className="header-users-avatars">
      <div
        className={cn('header-users-avatars__list', {
          'header-users-avatars__list--multiple': avatars.length > 1,
        })}
      >
        {avatars.map((user, key) => (
          <div className="header-users-avatars__list-item" key={`user-key-${key}`}>
            <UserAvatar
              className="header-users-avatars__list-avatar"
              height={60}
              width={60}
              {...user}
            />
          </div>
        ))}
        {users.length > maxDisplay && (
          <div className="header-users-avatars__list-count">+{users.length - maxDisplay}</div>
        )}
      </div>
      <div className="header-users-avatars__texts">
        <Title className="header-users-avatars__title">{title}</Title>
        <span className="header-users-avatars__description">{description}</span>
      </div>
    </div>
  );
}

HeaderUsersAvatars.propTypes = {
  description: PropTypes.string,
  maxDisplay: PropTypes.number,
  title: PropTypes.string,
  users: PropTypes.array,
};

HeaderUsersAvatars.defaultProps = {
  description: '',
  maxDisplay: 3,
  title: '',
  users: [],
};

export default HeaderUsersAvatars;
