export const MONTHS = {
  1: { id: 'interactionHighchart.january', key: 'interactionHighchartJanuary' },
  2: { id: 'interactionHighchart.february', key: 'interactionHighchartFebruary' },
  3: { id: 'interactionHighchart.march', key: 'interactionHighchartMarch' },
  4: { id: 'interactionHighchart.april', key: 'interactionHighchartApril' },
  5: { id: 'interactionHighchart.may', key: 'interactionHighchartMay' },
  6: { id: 'interactionHighchart.june', key: 'interactionHighchartJune' },
  7: { id: 'interactionHighchart.july', key: 'interactionHighchartJuly' },
  8: { id: 'interactionHighchart.august', key: 'interactionHighchartAugust' },
  9: { id: 'interactionHighchart.september', key: 'interactionHighchartSeptember' },
  10: { id: 'interactionHighchart.october', key: 'interactionHighchartOctober' },
  11: { id: 'interactionHighchart.november', key: 'interactionHighchartNovember' },
  12: { id: 'interactionHighchart.december', key: 'interactionHighchartDecember' },
};

export const INTERACTION_CHART_PAGE = 1;
export const INTERACTION_CHART_PAGE_SIZE = 12;
