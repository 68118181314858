// @packages
import React, { useMemo } from 'react';
import Select from 'smu-ui-components/SelectV2';
import { useSelector } from 'react-redux';

// @own
import SelectOptionStarType from 'components/SelectOptions/StarType';
import { selectSessionStars } from 'containers/Authorization/selectors';

const StarTypeContainer = (props) => {
  const stars = useSelector(selectSessionStars);

  const options = useMemo(
    () =>
      stars?.map((star) => ({
        label: <SelectOptionStarType {...star} key={star.id} />,
        value: star.id,
      })),
    [stars],
  );

  return <Select options={options} {...props} />;
};

export default StarTypeContainer;
