// @ Packages
import omit from 'lodash/omit';
import cache, { getHash } from 'smu-utils/requestCache';

// @ App
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times } from 'api';

const fixedFilters = [
  'typeLeaderboard',
];

export function getNewLeaderboard(fields = {}) {
  const baseUrl = '/v2/stellar/customFields/leaderboard';
  const config = {
    customFields: JSON.stringify(omit(fields, fixedFilters)),
    size: 10,
    typeLeaderboard: fields.typeLeaderboard === 'RECEIVER' ? 1 : 0,
  };

  const hash = getHash({...config, url: baseUrl});
  const resultCache = cache.get(hash);

  if (resultCache) {
    return resultCache;
  }

  const params = {
    ...times(),
    ...config,
  };
  return apiInstance({
    url: addParams(baseUrl, params),
    method: 'get',
  }).then(({ data }) => {
    cache.set(hash, data.result);
    return data.result;
  }).catch(errorHandler);
}
