// @packages
import React, { useEffect } from 'react';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import { selectSessionUserId } from 'containers/Authorization/selectors';
import { useMembersById } from 'smu-services/os-member/api/v1/community/members/[id]';
import { useSelector } from 'react-redux';

// @app
import WidgetErrorContainer from 'containers/WidgetError';

const OtherProfileLayout = (props) => {
  const ownerId = useSelector(selectSessionUserId);
  const sendStarProfile = props?.params?.sendStarProfile;
  const replace = props?.router?.replace;
  const isOwner = ownerId === Number(sendStarProfile);
  const memberById = useMembersById({
    userId: !isOwner ? sendStarProfile : null,
  });
  const userUID = memberById.selectPropertyUID();

  useEffect(() => {
    if (isOwner) {
      replace('/me');
    }
  }, [isOwner]);

  useEffect(() => {
    if (userUID && replace) {
      replace(`/profile/${userUID}`);
    }
  }, [userUID, replace]);

  if (memberById.error) {
    return (
      <WidgetErrorContainer
        className="w-full h-full pt-20 flex items-center justify-center"
        onClick={memberById.mutate}
      />
    );
  }

  return (
    <div className="w-full h-full pt-20 flex items-center justify-center">
      <LottieSpinner loadingType="starmeup" />
    </div>
  );
};

OtherProfileLayout.propTypes = {
  params: {
    profileId: PropTypes.string,
    sendStarProfile: PropTypes.string,
  },
  router: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default OtherProfileLayout;
