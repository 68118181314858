import { call, put, takeEvery } from 'redux-saga/effects';
import {
  MODERATE_COMMENT,
  MODERATE_COMMENT_SUCCESS,
  MODERATE_COMMENT_FAILED,
} from './actionTypes';

import { moderateComment } from './api';

function* moderateCommentWorker({ payload }) {
  try {
    const data = yield call(moderateComment, payload);
    yield put({ type: MODERATE_COMMENT_SUCCESS, payload: data.result });
  } catch (e) {
    yield put({ type: MODERATE_COMMENT_FAILED, payload: e });
  }
}

export default function* moderateCommentWatcher() {
  yield takeEvery(MODERATE_COMMENT, moderateCommentWorker);
}
