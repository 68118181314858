// @packages
import React from 'react';
import ActionLike from 'smu-ui-components/Cards/components/ActionLike';
import ActionImage from 'smu-ui-components/Cards/components/ActionImage';
import { injectIntl } from 'react-intl';

// @own
import messages from './messages';

const ActionLikeContainer = ({ intl, activeImage, inactiveImage, ...rest }) => {
  const showActionImage = activeImage && inactiveImage;
  if (showActionImage) {
    return (
      <ActionImage
        activeImage={activeImage}
        inactiveImage={inactiveImage}
        popupText={intl.formatMessage(messages.tooltip)}
        {...rest}
      />
    );
  }
  return (
    <ActionLike popupText={intl.formatMessage(messages.tooltip)} {...rest} />
  );
};

export default injectIntl(ActionLikeContainer);
