// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { add } from 'smu-app-components/ToastNotifications/actions';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

// @app
import OSFeedItem from 'containers/OSFeed/OSFeedItem';
import SkeletonFeedItem from 'components/Skeletons/FeedItem';
import Star from 'components/Star';
import useRequest from 'hooks/useRequest';
import { actionItemsUpdate } from 'services/items/actions';
import { apiGetStarById } from 'services/stars/api';
import { selectItemById } from 'services/items/selectors';
import { selectSessionUserId } from 'containers/Authorization/selectors';
import { DEFAULT_TOAST_TIMEOUT } from 'utils/constants';

// @own
import messages from './messages';

const StarDetailsContainer = ({ activityId, onError, intl, ...rest }) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectSessionUserId);
  const feedItem = useSelector((state) => selectItemById(state, activityId));
  const request = useRequest({
    api: apiGetStarById,
    id: `GET_STAR_${activityId}`,
    onSuccess: ({ result }) => {
      const item = result?.[0];
      if (item) {
        dispatch(actionItemsUpdate(activityId, item));
      } else if (onError) {
        dispatch(add({
          message: intl.formatMessage(messages.notFound),
          timeout: DEFAULT_TOAST_TIMEOUT,
        }));
        onError();
      }
    },
    onError,
  });

  useEffect(() => {
    request.fetch({
      params: {
        userId,
        starId: activityId,
      },
    });
  }, []);

  if (request?.isRequesting) {
    return <SkeletonFeedItem showComments />;
  }

  if (feedItem) {
    return (
      <OSFeedItem
        type={feedItem.type}
        data={{
          data: feedItem,
          date: feedItem.date,
          id: feedItem.id,
        }}
        {...rest}
      >
        <Star type="CORE_VALUE" />
        <Star type="KUDOS" />
        <Star type="SUPERNOVA" />
      </OSFeedItem>
    );
  }

  return null;
};

StarDetailsContainer.propTypes = {
  activityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  intl: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onError: PropTypes.func,
  onLiftStar: PropTypes.func,
  onModerate: PropTypes.func,
};

export default injectIntl(StarDetailsContainer);
