import { defineMessages } from 'react-intl';

export default defineMessages({
  loading: {
    id: 'select.seachUser.loading',
    defaultMessage: 'Searching...',
  },
  noOptions: {
    id: 'select.seachUser.noOptions',
    defaultMessage: 'User not found. Try our advanced search.',
  },
});
