// @packages
import React, { useEffect } from 'react';
import Container from 'smu-layout-components/Container';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import SurveysFeed from 'smu-app-components/SurveysFeed';
import { useSelector, useDispatch } from 'react-redux';

// @app
import StickyWrapper from 'components/StickyWrapper';
import UserProfile from 'containers/UserProfile';
import { ENABLED_TAKEPART_SURVEY } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { getProfile } from 'containers/withProfile/actions';
import {
  selectCommunityId,
  selectSessionUserUid,
} from 'containers/Authorization/selectors';

function Questionnaires({ location }) {
  const tab = location.query?.tab;
  const filter = location.query?.filter;
  const communityId = useSelector(selectCommunityId);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile?.result);
  const userUid = useSelector(selectSessionUserUid);
  const enabledTakePartSurvey = useSelector((state) =>
    getCommunityConfigValueBool(state, ENABLED_TAKEPART_SURVEY));

  useEffect(() => {
    if (!profile) {
      dispatch(
        getProfile({
          uid: userUid,
        }),
      );
    }
  }, [userUid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!enabledTakePartSurvey) {
    return <GenericErrorState image="padlock" statusCode={403} />;
  }

  return (
    <Container centered compact className="!pt-20 !py-10 ui-components">
      <div className="flex w-full">
        <div className="px-2 w-1/3">
          <StickyWrapper top={80} innerZ={10}>
            <UserProfile showStars showPanel />
          </StickyWrapper>
        </div>
        <div className="px-2 w-2/3">
          <SurveysFeed
            className="w-full"
            communityId={communityId}
            status={tab}
            userStatus={filter}
          />
        </div>
      </div>
    </Container>
  );
}

export default Questionnaires;
