// @packages
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

// @app
import CommentsContainer from 'containers/Comments';
import HeaderComments from 'components/Comments/Header';
import useRequest from 'hooks/useRequest';
import { StarContext } from 'containers/OSFeed/OSFeedItem';
import { actionItemsUpdate } from 'services/items/actions';
import { apiAddComment, apiAddCommentImage } from 'services/stars/api';
import { postOsFeedModerateComment } from 'containers/OSFeed/actions';

// @own
import { getCommentsProps } from './helpers';

const Messages = ({
  className,
  expanded,
  messages,
  onAddComment,
  onAddCommentImage,
}) => {
  const {
    api,
    data: { id, comments },
  } = useContext(StarContext);
  const dispatch = useDispatch();

  const addComment = useRequest({
    api: apiAddComment,
    id: `ADD_COMMENT_STAR_${id}`,
  });

  const addImage = useRequest({
    api: apiAddCommentImage,
    id: `ADD_COMMENT_IMAGE_STAR_${id}`,
  });

  function handleSuccessAddComment({ result }) {
    dispatch(actionItemsUpdate(id, { comments: [...comments, result] }));
    if (onAddComment) onAddComment();
  }

  function handleSuccessAddCommentImage(result) {
    dispatch(actionItemsUpdate(id, { comments: [...comments, result] }));
    if (onAddCommentImage) onAddCommentImage();
  }

  function handleModerate(params) {
    const config = {
      api,
      id: params?.id,
      starId: id,
      status: params?.enabled,
    };
    dispatch(postOsFeedModerateComment(config));
  }

  function handleAddComment(comment) {
    addComment.fetch({
      onSuccess: handleSuccessAddComment,
      params: {
        starId: id,
        comment,
      },
    });
  }

  function handleImageUpload(file) {
    addImage.fetch({
      onSuccess: handleSuccessAddCommentImage,
      params: {
        file,
        starId: id,
      },
    });
  }

  const getSortedMessages = () =>
    [...messages].sort((a, b) => {
      const dateA = new Date(a.dateComment).getTime();
      const dateB = new Date(b.dateComment).getTime();
      return dateB - dateA;
    });

  if (!expanded) {
    return null;
  }

  return (
    <CommentsContainer
      className={className}
      comments={getCommentsProps(getSortedMessages())}
      header={
        <HeaderComments
          className={cn({
            'mb-4': messages?.length,
          })}
          isLoading={addComment.isRequesting || addImage.isRequesting}
          mediaUploadEnabled
          onAddComment={handleAddComment}
          onUploadImage={handleImageUpload}
        />
      }
      moderationEnabled
      onModerate={handleModerate}
    />
  );
};

Messages.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool,
  messages: PropTypes.array,
  onAddComment: PropTypes.func,
  onAddCommentImage: PropTypes.func,
};

export default Messages;
