// @packages
import React from 'react';
import { Router } from 'react-router';
import { useSelector } from 'react-redux';
import { selectorAppLoaderHasError } from 'services/appLoader/selectors';

// @own
import routes from './routes';

const RouterRoot = ({ history, store }) => {
  const maintenanceEnabled = useSelector(selectorAppLoaderHasError);

  return maintenanceEnabled ? null : (
    <Router history={history} routes={routes(store)} />
  );
};

export default RouterRoot;
