// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';
import messages from '../messages';
import { actionLinks } from './actions';

function ActionsDetail({
  actions,
  eventData,
  intl: {
    formatMessage,
  },
}) {
  return (
    <div className="insights-content-actions">
      <p className="insights-content-actions__title">{formatMessage(messages.insightActionsTitle)}</p>
      {actions.map(({ data }) => (
        <a href={actionLinks[`${data?.label}`]?.link} target="_blank">
          <div
            className="insights-content-actions__item"
            onClick={() => {
              trackEvent({
                action: `Action_${data?.label}`,
                category: 'Insights',
                ...eventData,
              });
            }}
          >
            {messages[`${data?.label}`] ? formatMessage(messages[`${data?.label}`]) : data?.label}
          </div>
        </a>
      ))}
    </div>
  );
}

ActionsDetail.propTypes = {
  actions: PropTypes.array,
};

export default injectIntl(ActionsDetail);
