import { smuInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function getConnections({ id, clusterBy, rolValue }) {
  return smuInstance({
    url: `/os-member/api/v1/community/members/${id}/connections`,
    data: {
      connectionTypes: [
        'starTo',
      ],
      clusterBy,
      labelBy: 'any',
      limit: clusterBy === 'none' ? 20 : 15,
      propertiesFilter: rolValue
        ? [{ key: 'job', value: [rolValue] }]
        : [],
    },
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
