import {
  ADD_ITEM_TO_FEED,
  CLEAN_FEED,
  FEED_SET_FIELDS,
  FEED_SET_OPTIONS,
  REMOVE_LIKE,
  REMOVE_LIKE_FAIL,
  REMOVE_LIKE_SUCCESS,
  REQUEST_FEED,
  REQUEST_FEED_FAILED,
  REQUEST_FEED_NEXT_PAGE,
  REQUEST_FEED_NEXT_PAGE_SUCCESS,
  REQUEST_FEED_RESET_PAGE,
  REQUEST_FEED_SUCCESS,
  SEND_COMMENT,
  SEND_COMMENT_FAIL,
  SEND_COMMENT_SUCCESS,
  SEND_IMAGE,
  SEND_LIKE,
  SEND_LIKE_FAIL,
  SEND_LIKE_SUCCESS,
  SHOW_SEND_STAR_FLOW,
  TOGGLE_MAIN_COMMENT,
  TOGGLE_SEND_STAR_WIDGET,
  SEND_LIFT_STAR_SUCCESS,
} from './actionTypes';

export function getFeed() {
  return {
    type: REQUEST_FEED,
  };
}

export function getFeedSuccess(payload) {
  return {
    type: REQUEST_FEED_SUCCESS,
    payload,
  };
}

export function getFeedFailed(payload) {
  return {
    type: REQUEST_FEED_FAILED,
    payload,
  };
}

export function cleanFeed() {
  return {
    type: CLEAN_FEED,
  };
}

export function getFeedResetPage() {
  return {
    type: REQUEST_FEED_RESET_PAGE,
  };
}

export function getFeedNextPage(payload) {
  return {
    type: REQUEST_FEED_NEXT_PAGE,
    payload,
  };
}

export function getFeedNextPageSuccess(payload) {
  return {
    type: REQUEST_FEED_NEXT_PAGE_SUCCESS,
    payload,
  };
}

export function feedSetOptions(payload) {
  return {
    type: FEED_SET_OPTIONS,
    payload,
  };
}

export function feedSetFields(payload) {
  return {
    type: FEED_SET_FIELDS,
    payload,
  };
}

export function sendComment(payload) {
  return {
    type: SEND_COMMENT,
    payload,
  };
}

export function sendCommentSuccess(payload) {
  return {
    type: SEND_COMMENT_SUCCESS,
    payload,
  };
}

export function sendCommentFail(payload) {
  return {
    type: SEND_COMMENT_FAIL,
    payload,
  };
}

export function sendLike(payload) {
  return {
    type: SEND_LIKE,
    payload,
  };
}

export function sendLikeSuccess(payload) {
  return {
    type: SEND_LIKE_SUCCESS,
    payload,
  };
}

export function sendLikeFail(payload) {
  return {
    type: SEND_LIKE_FAIL,
    payload,
  };
}

export function removeLike(payload) {
  return {
    type: REMOVE_LIKE,
    payload,
  };
}

export function removeLikeSuccess(payload) {
  return {
    type: REMOVE_LIKE_SUCCESS,
    payload,
  };
}

export function removeLikeFail(payload) {
  return {
    type: REMOVE_LIKE_FAIL,
    payload,
  };
}

export function toggleMainComment(payload) {
  return {
    type: TOGGLE_MAIN_COMMENT,
    payload,
  };
}

export function sendImage(payload) {
  return {
    type: SEND_IMAGE,
    payload,
  };
}

export function showSendStarFlow() {
  return {
    type: SHOW_SEND_STAR_FLOW,
  };
}


export function addItemToFeed(payload) {
  return {
    type: ADD_ITEM_TO_FEED,
    payload,
  };
}

export function addLiftStar(payload) {
  return {
    type: SEND_LIFT_STAR_SUCCESS,
    payload,
  };
}

export function toggleSendStarWidget(payload) {
  return {
    type: TOGGLE_SEND_STAR_WIDGET,
    payload,
  };
}

