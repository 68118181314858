// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { getCommunityMember } from './api';
import { REQUEST_COMMUNITY_MEMBER } from './actionTypes';
import { actionCommunityMemberFail, actionCommunityMemberSuccess } from './actions';

function* getCommunityMemberWorker({ id, payload }) {
  try {
    const { data } = yield call(getCommunityMember, payload);
    yield put(actionCommunityMemberSuccess(id, { result: data?.result }));
  } catch (error) {
    yield put(actionCommunityMemberFail(id, { error }));
  }
}

export function* getCommunityMemberWatcher() {
  yield takeEvery(REQUEST_COMMUNITY_MEMBER, getCommunityMemberWorker);
}

export default {
  getCommunityMemberWatcher,
};
