// @packages
import React from 'react';
import ImageModal from 'smu-ui-components/ImageModal';
import PropTypes from 'prop-types';

// @own
import { buildImageSMU } from 'utils/image';

const ModalImageSMU = ({ imageCode, imageHeight, imageWidth, ...rest }) => (
  <ImageModal
    src={buildImageSMU({
      code: imageCode,
      height: imageHeight,
      width: imageWidth,
    })}
    {...rest}
  />
);

ModalImageSMU.propTypes = {
  imageCode: PropTypes.string.isRequired,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
};

export default ModalImageSMU;
