// @packages
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'smu-ui-components/SelectV2';
import SelectOptionSearchUser from 'components/SelectOptions/SearchUser';
import { buildImageUrl } from 'smu-utils/image';
import { injectIntl } from 'react-intl';
import { useLocate } from 'smu-services/starmeup-api/v2/stellar/locate';
import { useSuggestedUsers } from 'smu-services/starmeup-api/v2/stellar/users/suggested';
import { useEmailsUsers } from 'smu-services/starmeup-api/v2/stellar/users/emails';

// @app
import useDebounce from 'hooks/useDebounce';
import { fullName } from 'utils/user';

// @own
import messages from './messages';
import { getValidEmails } from './helpers';

const SelectSearchUser = ({ delay, intl, exclude, onChange, ...rest }) => {
  const [searchValue, setSearchValue] = useState('');
  const validEmails = getValidEmails(searchValue);
  const debouncedSearchValue = useDebounce(searchValue, delay);
  const suggested = useSuggestedUsers();
  const emails = useEmailsUsers({ emails: validEmails });
  const emailsUsers = emails.selectValidUsers();
  const locate = useLocate({
    filterType: 'USER',
    searchString: debouncedSearchValue,
    shouldFetch: debouncedSearchValue && !validEmails?.length,
  });
  const suggestedUsers = suggested.selectResult();
  const locateUsers = locate.selectResult();
  const isLoading =
    !!debouncedSearchValue && (suggested.isLoading || locate.isLoading);

  const options = useMemo(() => {
    const users = debouncedSearchValue ? locateUsers : suggestedUsers;
    return users?.reduce((acc, cur) => {
      if (!exclude?.some(e => e.id === cur.id)) {
        acc.push({
          label: fullName(cur, 'firstName', 'lastName'),
          user: cur,
          value: cur.id,
        });
      }
      return acc;
    }, []);
  }, [debouncedSearchValue, locateUsers, suggestedUsers, exclude]);

  const handleSearchChange = (value) => {
    setSearchValue(value || '');
  };

  const handleChange = (value) => {
    if (onChange) {
      const user = options?.find((option) => option?.user?.id === value)?.user;
      onChange(user);
    }
  };

  const renderOptionLabel = useCallback(
    ({ user }) => (
      <SelectOptionSearchUser
        avatar={{
          height: 40,
          src: buildImageUrl({
            code: user?.profileImageCode,
            width: 40,
            height: 40,
          }),
          width: 40,
        }}
        fullName={fullName(user, 'firstName', 'lastName')}
        job={user?.job}
      />
    ),
    [],
  );

  useEffect(() => {
    if (emailsUsers?.length) {
      setSearchValue('');
      onChange(emailsUsers);
    }
  }, [emailsUsers]);

  return (
    <Select
      disabled={emails.isLoading}
      filterOptionsEnabled={false}
      isLoading={isLoading}
      key={`select-users-${emailsUsers?.length}`}
      loadingMessage={intl.formatMessage(messages.loading)}
      noOptionsMessage={intl.formatMessage(messages.noOptions)}
      onChange={handleChange}
      onSearchChange={handleSearchChange}
      options={options}
      renderOptionLabel={renderOptionLabel}
      size="large"
      {...rest}
    />
  );
};

SelectSearchUser.propTypes = {
  delay: PropTypes.number,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  exclude: PropTypes.array,
  onChange: PropTypes.func,
};

SelectSearchUser.defaultProps = {
  delay: 500,
};

export default injectIntl(SelectSearchUser);
