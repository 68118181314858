import parse from 'url-parse';

export const onErrorWebviewShareUrl = (error) => {
  const { origin } = parse(window.location.href);
  window.location.href = `${origin}/share/image_result_handler?errorMessage=${error?.message}`;
};

export const onSuccessWebviewShareUrl = (shareImageUrl) => {
  const { origin } = parse(window.location.href);
  window.location.href = `${origin}/share/image_result_handler?imageUrl=${shareImageUrl}`;
};
