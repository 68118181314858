// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styes.scss';

const Chip = ({
  className,
  clickable,
  outlined,
  ...rest
}) => (
  <div
    className={cn('chillout-chip', className, {
      'chillout-chip--clickable': clickable,
      'chillout-chip--outlined': outlined,
    })}
    {...rest}
  />
);

Chip.defaultProps = {
  className: '',
  clickable: false,
  outlined: false,
};

Chip.propTypes = {
  className: PropTypes.string,
  clickable: PropTypes.bool,
  outlined: PropTypes.bool,
};

export default Chip;
