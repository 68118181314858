// @app
import { paths } from 'utils/routes';

// @own
import Layout from './Layout';

export default () => ({
  component: Layout,
  path: paths.component(),
});
