import { takeEvery, call, put } from 'redux-saga/effects';

import { getCulturalDataFail, getCulturalDataSuccess } from './actions';
import { fetchCulturalData as fetchCulturalDataAPI } from './api';
import { GET_CULTURAL_DATA } from './actionTypes';

function* getCulturalDataWorker({ payload }) {
  try {
    const data = yield call(fetchCulturalDataAPI, { userId: payload });
    yield put(getCulturalDataSuccess(data));
  } catch (e) {
    yield put(getCulturalDataFail());
  }
}

export function* fetchCulturalWatcher() {
  yield takeEvery(GET_CULTURAL_DATA, getCulturalDataWorker);
}

export default {
  fetchCulturalWatcher,
};
