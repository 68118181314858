// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';

// @own
import './styles.scss';

const SkeletonCommentInput = ({ className }) => (
  <div className={cn('skeleton-comment-input', className)}>
    <Skeleton animation="wave" height={30} width="100%" />
    <Skeleton
      animation="wave"
      className="skeleton-comment-input__button"
      height={30}
      variant="circle"
      width={30}
    />
  </div>
);

SkeletonCommentInput.propTypes = {
  className: PropTypes.string,
};

export default SkeletonCommentInput;
