// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import Title from 'components/Title';
import emptyUsers from 'assets/img/emptyUsers.svg';

// @own
import './styles.scss';

function DiscoverUsersEmpty({ texts: { emptyTitle, emptySubtitle } }) {
  return (
    <div className="discover-users-empty">
      <img alt="empty" className="discover-users-empty__image" src={emptyUsers} />
      <h4 className="discover-users-empty__title">{emptyTitle}</h4>
      <span className="discover-users-empty__subtitle">{emptySubtitle}</span>
    </div>
  );
}

DiscoverUsersEmpty.propTypes = {
  texts: PropTypes.shape({
    emptyTitle: PropTypes.string,
    emptySubtitle: PropTypes.string,
  }),
};

DiscoverUsersEmpty.defaultProps = {
  texts: PropTypes.shape({
    emptyTitle: '',
    emptySubtitle: '',
  }),
};

export default DiscoverUsersEmpty;
