import { put, call, takeEvery } from 'redux-saga/effects';
import {
  REQUEST_TOP_STARS,
  REQUEST_TOP_STARS_SUCCESS,
  REQUEST_TOP_STARS_FAILED,
} from './actionTypes';

function* getTopStarsWorker({ payload }) {
  try {
    const results = yield call(payload.api);
    const resultsWithAmount = results.map(result => ({ ...result[0], amount: result[1] }));
    yield put({ type: REQUEST_TOP_STARS_SUCCESS, payload: resultsWithAmount.splice(0, 3) });
  } catch (error) {
    yield put({ type: REQUEST_TOP_STARS_FAILED, payload: error });
  }
}

function* getTopStarsWatcher() {
  yield takeEvery(REQUEST_TOP_STARS, getTopStarsWorker);
}

export default { getTopStarsWatcher };
