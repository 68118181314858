// @packages
import React from 'react';
import Card from 'smu-ui-components/Card';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';

// @app
import SkeletonTopStar from 'components/Skeletons/TopStar';

function SkeletonTopStars({ className, items }) {
  return (
    <Card className={cn(className, 'px-3 py-4')}>
      <Skeleton className="mt-b" animation="wave" height={20} width={140} />
      {items?.map(SkeletonTopStar)}
    </Card>
  );
}

SkeletonTopStars.defaultProps = {
  items: Array.from(Array(3).keys()),
};

SkeletonTopStars.propTypes = {
  items: PropTypes.array,
};

export default SkeletonTopStars;
