// @packages
import React, { useState, useEffect } from 'react';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';
import Segment from 'smu-ui-components/Segment';
import cn from 'classnames';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { selectRequest, selectResultProp } from 'smu-utils/reduxRequests/selectors';
import { trackEvent as trackAnalytics } from 'smu-utils/gtm';

// @app
import QuestionnairesFlex from 'components/Questionnaires/utils/QuestionnairesFlex';
import { isNotFound, isNoRights } from 'components/Questionnaires/utils/errorHandler';
import {
  getRequestIdQuestById,
  getRequestIdQuestDemographics,
  getRequestIdQuestLanguage,
  getRequestIdQuestQuestion,
  getRequestIdQuestComment,
} from 'components/Questionnaires/utils/requestIds';
import { REQUEST_VOTE_QUESTION } from 'components/Questionnaires/utils/QuestionnairesFlex/constants';
import { apiGetQuestionaries } from 'containers/SurveysWidget/api';
import { AVAILABLE_SURVEYS, STATUS_OPEN } from 'containers/SurveysWidget/constants';
import { selectLanguageCode } from 'containers/Authorization/selectors';

// @own
import Demographics from '../Demographics';
import QuestionnaireError from '../QuestionnaireError';
import QuestionnaireFinished from '../QuestionnaireFinished';
import QuestionnaireLanguage from '../QuestionnaireLanguage';
import QuestionnairePanel from '../QuestionnairePanel';
import messages from '../messages';
import { OPEN_QUESTIONNAIRE_PANEL, LANGUAGE_NONE } from '../constants';
import * as actions from '../actions';
import {
  apiGetQuestById,
  apiPostDemographics,
  apiPostQuestion,
  apiPutLanguage,
  apoPostComments,
} from '../api';
import { getCurrent } from './normalice';
import './styles.scss';

function QuestionnaireModal({
  actionRequestDestroy,
  actionRequestInit,
  closeModal,
  getPendingQuestionnaires,
  intl: { formatMessage },
  isQuestionnaireFinished,
  onClose,
  orgId,
  questionnaireId,
  requestComment,
  requestDemographics,
  requestLanguage,
  requestQuest,
  requestQuestion,
  router: {
    location: { pathname },
  },
  userLanguage,
}) {
  const [values, setValues] = useState({
    questionnaire: undefined,
    isLoading: true,
    isError: false,
  });
  const {
    appearance,
    demographicsInput,
    language,
    showDemographics,
    title,
    totalQuestions,
  } = get(
    values,
    'questionnaire',
    {},
  );
  const questionnaire = get(requestQuest, 'result.data');
  const questionnaireError = get(requestQuest, 'error');
  const isNotFoundOrNotRights = isNotFound(questionnaireError) || isNoRights(questionnaireError);
  const type = get(requestQuest, 'result.type');
  const current = getCurrent(values.questionnaire);
  const prevRequestComment = usePrevious(requestComment);
  const prevRequestDemographics = usePrevious(requestDemographics);
  const prevRequestLanguage = usePrevious(requestLanguage);
  const prevRequestQuest = usePrevious(requestQuest);
  const prevRequestQuestion = usePrevious(requestQuestion);
  const prevIsCompleted = usePrevious(current.isCompleted);

  function isRequestSuccess(prev, current) {
    return prev && prev.requesting && !current.requesting && !current.error;
  }

  function isRequestError(prev, current) {
    return prev && prev.requesting && !current.requesting && current.error;
  }

  function setLocalValues(update) {
    setValues({
      ...values,
      ...update,
    });
  }

  function getErrorTexts() {
    let errorTexts = {
      messageError: formatMessage(messages.QuestionnaireMessageError),
      buttonRetry: formatMessage(messages.QuestionnaireButtonRetry),
    };

    if (isNotFound(questionnaireError)) {
      errorTexts = {
        messageError: formatMessage(messages.ErrorQuestionnariesNotFound),
        buttonRetry: formatMessage(messages.QuestionnaireButtonClose),
      };
    }

    if (isNoRights(questionnaireError)) {
      errorTexts = {
        messageError: formatMessage(messages.ErrorQuestionnariesNoRights),
        buttonRetry: formatMessage(messages.QuestionnaireButtonClose),
      };
    }

    return errorTexts;
  }

  function getQuestById() {
    actionRequestInit({
      api: apiGetQuestById,
      id: getRequestIdQuestById(questionnaireId),
      params: {
        lang: userLanguage,
        orgId,
        questionnaireId,
      },
    });
  }

  function postDemographics(demographicsInput) {
    const data = { demographicsInput };

    actionRequestInit({
      api: apiPostDemographics,
      id: getRequestIdQuestDemographics(questionnaireId),
      params: {
        data,
        orgId,
        questionnaireId,
      },
      onSuccess: getQuestById,
    });

    setLocalValues({
      isLoading: true,
      isError: false,
    });
  }

  function putLanguange(language) {
    actionRequestInit({
      api: apiPutLanguage,
      id: getRequestIdQuestLanguage(questionnaireId),
      params: {
        language,
        orgId,
        questionnaireId,
      },
      onSuccess: getQuestById,
    });

    setLocalValues({
      isLoading: true,
      isError: false,
    });
  }

  function postQuestion(value, id) {
    const data = { choices: value };

    actionRequestInit({
      api: apiPostQuestion,
      id: getRequestIdQuestQuestion(questionnaireId),
      params: {
        data,
        lang: userLanguage,
        orgId,
        questionId: id,
        questionnaireId,
      },
      onSuccess: getQuestById,
    });

    setLocalValues({
      isLoading: true,
      isError: false,
    });
  }

  function postComment(comment) {
    if (comment || comment === '') {
      actionRequestInit({
        api: apoPostComments,
        id: getRequestIdQuestComment(questionnaireId),
        params: {
          categoryId: current.categoryId,
          comment,
          orgId,
          questionnaireId,
        },
        onSuccess: getQuestById,
      });

      setLocalValues({
        isLoading: true,
        isError: false,
      });
    }
  }

  function refreshAvailableSurveys() {
    actionRequestInit({
      api: apiGetQuestionaries,
      id: AVAILABLE_SURVEYS,
      params: { status: STATUS_OPEN },
    });
  }

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      closeModal();
    }
  };

  const closeOnError = () => {
    handleClose();
  };

  const closeHandler = () => {
    const event = {
      category: 'questionnaires',
      label: questionnaire?.id,
      q_name: questionnaire?.title,
    };
    if (isQuestionnaireFinished || questionnaire.finished) {
      event.action = 'finish_close';
      event.category = 'questionnaires';
      refreshAvailableSurveys();
    } else {
      event.action = 'incomplete';
    }
    handleClose();
    trackAnalytics(event);
  };

  useEffect(() => {
    getQuestById();

    return () => {
      actionRequestDestroy(getRequestIdQuestById(questionnaireId));
      actionRequestDestroy(getRequestIdQuestDemographics(questionnaireId));
      actionRequestDestroy(getRequestIdQuestLanguage(questionnaireId));
      actionRequestDestroy(getRequestIdQuestQuestion(questionnaireId));
      actionRequestDestroy(getRequestIdQuestComment(questionnaireId));
    };
  }, []);

  useEffect(() => {
    if (!prevIsCompleted && current.isCompleted) {
      getPendingQuestionnaires({ orgId });
    }
  }, [prevIsCompleted, current.isCompleted]);

  useEffect(() => {
    if (isRequestSuccess(prevRequestQuest, requestQuest)) {
      setValues({
        ...values,
        questionnaire: get(requestQuest, 'result.data'),
        isLoading: false,
      });
      if (!questionnaire.isFinished && questionnaire.currentQuestionIndex > 0) {
        trackAnalytics({
          action: 'resume',
          category: 'questionnaires',
          label: questionnaire?.id,
          q_name: questionnaire?.title,
          q_type: questionnaire?.question?.type,
        });
      }
    }
  }, [prevRequestQuest, requestQuest]);

  useEffect(() => {
    if (isRequestError(prevRequestQuestion, requestQuestion)) {
      setLocalValues({
        isLoading: false,
        isError: true,
      });
    }
  }, [prevRequestQuestion, requestQuestion]);

  useEffect(() => {
    if (isRequestError(prevRequestQuest, requestQuest)) {
      setLocalValues({
        isLoading: false,
        isError: true,
      });
    }
  }, [prevRequestQuest, requestQuest]);

  useEffect(() => {
    if (isRequestError(prevRequestComment, requestComment)) {
      setLocalValues({
        isLoading: false,
        isError: true,
      });
    }
  }, [prevRequestComment, requestComment]);

  useEffect(() => {
    if (isRequestError(prevRequestDemographics, requestDemographics)) {
      setLocalValues({
        isLoading: false,
        isError: true,
      });
    }
  }, [prevRequestDemographics, requestDemographics]);

  useEffect(() => {
    if (isRequestError(prevRequestLanguage, requestLanguage)) {
      setLocalValues({
        isLoading: false,
        isError: true,
      });
    }
  }, [prevRequestLanguage, requestLanguage]);

  const hasSubQuestions = get(current, 'question.isAnswered') && current.subQuestion;
  const showDemographicsPanel = !values.isError && !current.isCompleted && showDemographics;
  const showLanguage = !values.isError
    && !showDemographics
    && !current.isCompleted
    && language === LANGUAGE_NONE;
  const showPanel = !values.isError
    && !showDemographicsPanel
    && !showLanguage
    && !current.isCompleted
    && (current.question || current.showComments);

  // TODO: This component will be obsolete with the new integration of questionnaires V2
  if (type === 'FLEX') {
    return (
      <div className="questionnaire-modal questionnaire-modal--flex">
        <div className="questionnaire-modal__flex">
          <QuestionnairesFlex
            initialData={questionnaire}
            onClose={closeHandler}
            onFinished={() => getPendingQuestionnaires({ orgId })}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="questionnaire-modal questionnaire-modal--old">
      <div className="questionnaire-modal__container" style={{ background: appearance }}>
        <Segment
          className={cn('questionnaire-modal__segment', {
            'questionnaire-modal__segment--is-loading': values.isLoading,
            'questionnaire-modal__segment--is-error': values.isError,
          })}
          loading={values.isLoading}
          loadingType="takepart"
        >
          {!values.isLoading && (
            <div className="questionnaire-modal__close">
              <Icon
                className="questionnaire-modal__close-icon"
                icon="close"
                onClick={handleClose}
                size="small"
              />
            </div>
          )}
          {!values.isLoading && (
            <div className="questionnaire-modal">
              {showDemographicsPanel && (
                <Demographics
                  appearance={appearance}
                  demographicsInputs={demographicsInput}
                  handleSubmitDemographics={postDemographics}
                  language={current.language}
                />
              )}
              {showLanguage && (
                <QuestionnaireLanguage onClick={putLanguange} language={current.language} />
              )}
              {showPanel && (
                <QuestionnairePanel
                  currentCategory={current.categoryName}
                  currentCategoryId={current.categoryId}
                  currentQuestion={current.questionText}
                  currentQuestionId={current.questionId}
                  currentSubQuestionId={current.subQuestionId}
                  handleSubQuestionAnswer={postQuestion}
                  handleSubmitAnswer={postQuestion}
                  handleSubmitComments={postComment}
                  hasSubQuestions={hasSubQuestions}
                  index={current.questionKey}
                  language={current.language}
                  questionItems={current.questionItems}
                  showComments={current.showComments}
                  subQuestionsItems={current.subQuestionsItems}
                  title={title}
                  totalQuestions={totalQuestions}
                />
              )}
              {current.isCompleted && !current.showComments && (
                <QuestionnaireFinished
                  closeQuestionnaire={() => {
                    handleClose();
                  }}
                  language={current.language}
                  title={title}
                  totalQuestions={totalQuestions}
                />
              )}
              {values.isError && (
                <QuestionnaireError
                  errorTexts={getErrorTexts()}
                  onClick={isNotFoundOrNotRights ? closeOnError : getQuestById}
                />
              )}
            </div>
          )}
        </Segment>
      </div>
    </div>
  );
}

QuestionnaireModal.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  addToastMessage: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getPendingQuestionnaires: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isQuestionnaireFinished: PropTypes.bool,
  onClose: PropTypes.func,
  orgId: PropTypes.number.isRequired,
  questionnaireId: PropTypes.number.isRequired,
  requestComment: PropTypes.object.isRequired,
  requestDemographics: PropTypes.object.isRequired,
  requestLanguage: PropTypes.object.isRequired,
  requestQuest: PropTypes.object.isRequired,
  requestQuestion: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const questionnaireId = get(ownProps, 'questionnaireId');
  return {
    isQuestionnaireFinished: selectResultProp(state, REQUEST_VOTE_QUESTION, 'data.finished'),
    requestComment: selectRequest(state, getRequestIdQuestComment(questionnaireId)),
    requestDemographics: selectRequest(state, getRequestIdQuestDemographics(questionnaireId)),
    requestLanguage: selectRequest(state, getRequestIdQuestLanguage(questionnaireId)),
    requestQuest: selectRequest(state, getRequestIdQuestById(questionnaireId)),
    requestQuestion: selectRequest(state, getRequestIdQuestQuestion(questionnaireId)),
    userLanguage: selectLanguageCode(state),
  };
};

const modalStyles = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  left: '0px',
  top: '0px',
  width: '100%',
};

const withIntl = injectIntl(QuestionnaireModal);
const withConnect = withRouter(
  connect(mapStateToProps, {
    ...actions,
    actionRequestDestroy,
    actionRequestInit,
    addToastMessage,
  })(withIntl),
);

export default RootModal(
  withConnect,
  OPEN_QUESTIONNAIRE_PANEL,
  modalStyles,
  'questionnaire-modal__modal',
);
