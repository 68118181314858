export function getCommunityCounters(payload) {
  return {
    type: 'GET_COMMUNITY_COUNTERS',
    payload,
  };
}

export function getCommunityCountersSuccess(payload) {
  return {
    type: 'GET_COMMUNITY_COUNTERS_SUCCESS',
    payload,
  };
}

export function getCommunityCountersError(payload) {
  return {
    type: 'GET_COMMUNITY_COUNTERS_ERROR',
    payload,
  };
}
