// @packages
import React, { useState, useRef } from 'react';
import Icon from 'smu-ui-components/Icon';
import ImageGallery from 'react-image-gallery';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import CardMedia from 'components/Bethere/CardMedia';

// @own
import './styles.scss';

function CardGallery({ alwaysShowMedia, className, href, files, overlay, style, ...rest }) {
  const [slideIndex, setSlideIndex] = useState(0);
  const galleryRef = useRef(null);
  const Component = href ? Link : 'div';

  function handleClickNav(e, onClick, disabled) {
    e.stopPropagation();
    if (!disabled) {
      onClick(e);
    }
  }

  function handleClickBullet(e, index) {
    e.stopPropagation();
    setSlideIndex(index);
    if (galleryRef.current) {
      galleryRef.current.slideToIndex(index);
    }
  }

  function renderNavPosition(position) {
    return function renderNav(onClick, disabled) {
      return (
        <button
          className={cn(`bt-card-gallery__nav-${position}`, {
            [`bt-card-gallery__nav-${position}--disabled`]: disabled,
          })}
          onClick={e => handleClickNav(e, onClick, disabled)}
          type="button"
        >
          <Icon color="white" icon={`arrow-${position}`} />
        </button>
      );
    };
  }

  return (
    <Component
      className={cn(
        'bt-card-gallery',
        {
          'bt-card-gallery--link': href,
          'bt-card-gallery--border': overlay?.color,
        },
        className,
      )}
      href={href}
      style={{
        borderColor: overlay?.color,
        ...style,
      }}
      {...rest}
    >
      {overlay && (
        <div
          className="bt-card-gallery__overlay"
          style={{
            backgroundImage: `url(${overlay.image})`,
          }}
        />
      )}
      {files.length > 1 ? (
        <>
          <div className="bt-card-gallery__count">{`${slideIndex + 1}/${files.length}`}</div>
          <ImageGallery
            infinite={false}
            items={files.map((file, index) => ({
              thumbnail: file.thumbnail,
              original: file.image,
              renderItem: () => <CardMedia active={slideIndex === index} {...file} />,
            }))}
            onSlide={index => setSlideIndex(index)}
            preventDefaultTouchmoveEvent
            ref={galleryRef}
            renderLeftNav={renderNavPosition('left')}
            renderRightNav={renderNavPosition('right')}
            showBullets={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={false}
            startIndex={slideIndex}
            stopPropagation
          />
          <div className="bt-card-gallery__bullets">
            {files.map((_, key) => (
              <div
                className={cn('bt-card-gallery__bullet', {
                  'bt-card-gallery__bullet--active': slideIndex === key,
                })}
                key={`bullet-${key}`}
                onClick={e => handleClickBullet(e, key)}
              />
            ))}
          </div>
        </>
      ) : (
        !!files.length && (
          <CardMedia
            alwaysShowMedia={alwaysShowMedia}
            {...files[0]}
          />
        )
      )}
    </Component>
  );
}

CardGallery.propTypes = {
  alwaysShowMedia: PropTypes.bool,
  files: PropTypes.array.isRequired,
  href: PropTypes.string,
  overlay: PropTypes.shape({
    image: PropTypes.string,
    color: PropTypes.string,
  }),
};

export default CardGallery;
