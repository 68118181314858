// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';
import Card from '../ImageEvent/Card';
import Container from '../ImageEvent/Container';
import Header from '../ImageEvent/Header';
import Messages from './messages';

function ImageEvent({ intl: { formatMessage }, isLoading, posts }) {
  return (
    <Card>
      <Header>
        <h5 className="image-event__header image-event__header--bold">
          {formatMessage(Messages.title)}
        </h5>
      </Header>
      <Container isLoading={isLoading} data={posts} />
    </Card>
  );
}

ImageEvent.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(ImageEvent);
