import React from 'react';
import PropTypes from 'prop-types';
import { map, drop, take } from 'lodash';
import cn from 'classnames';
import Icon from 'smu-ui-components/Icon';

const Stereoscope = ({
  items, onLeft, onRight, offset = 0, size, className = 'Stereoscope',
}) => {
  const arrow = (leftOrRight, onClick) => {
    const localProps = {
      className: [className, '__', leftOrRight].join(''),
      onClick,
    };
    return <div role="button" className={localProps.className} onClick={localProps.onClick}><Icon icon="chevron-left" size="small" /></div>;
  };
  const renderItem = (item) => {
    const ItemClassName = [className, '__item'].join('');
    return (
      <div key={item.key} className={cn(ItemClassName, { 'Stereoscope__item--multiple': items && (items.length > 1) })}>
        {item.node}
      </div>
    );
  };
  const shrinkedItems = drop(items, offset);
  return (
    <div className={className}>
      {offset > 0
        && arrow('left', onLeft)}
      {map(take(shrinkedItems, size), renderItem)}
      {shrinkedItems.length > size
        && arrow('right', onRight)}
    </div>
  );
};

Stereoscope.propTypes = {
  offset: PropTypes.number,
  size: PropTypes.number,
  onLeft: PropTypes.func,
  onRight: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.any.isRequired,
    node: PropTypes.node.isRequired,
  })).isRequired,
  className: PropTypes.string,
};

export default Stereoscope;
