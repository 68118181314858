// @app
import { paths } from 'utils/routes';

// @own
import ExternalLayout from './ExternalLayout';

export default {
  component: ExternalLayout,
  path: paths.external(),
};
