import { takeEvery, put, call } from 'redux-saga/effects';
import {
  setProfileBio, setProfileBioSuccess, setProfileBioFail,
} from './actions';
import { setProfileBio as setProfileApi } from './api';


function* setProfileBioWorker({ payload }) {
  try {
    const data = yield call(setProfileApi, payload);
    if (data.error) {
      yield put(setProfileBioFail());
    } else {
      yield put(setProfileBioSuccess(payload));
    }
  } catch (e) {
    yield put(setProfileBioFail());
  }
}

export function* setProfileBioWatcher() {
  yield takeEvery(setProfileBio().type, setProfileBioWorker);
}

export default {
  setProfileBioWatcher,
};
