// @packages
import React, { useEffect, useState, useMemo } from 'react';
import BreadcrumbHeader from 'smu-ui-components/BreadcrumbHeader';
import Lottie from 'smu-ui-components/Lottie';
import PropTypes from 'prop-types';
import useTheme from 'smu-custom-hooks/useTheme';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { withRouter } from 'react-router';

// @app
import FeedV2 from 'containers/FeedV2';
import InteractionModal from 'components/InteractionModal';
import RecognitionExpanded from 'components/OS/Event/RecognitionContent/RecognitionExpanded';

// @own
import './styles.scss';
import messages from './messages';

const TimelineHashtags = ({
  intl: { formatMessage },
  router: {
    params: { hashtag },
  },
}) => {
  const [crumbs, setCrumbs] = useState([
    formatMessage(messages.timelineHashtags),
  ]);
  const fullHashTag = useMemo(() => `#${hashtag}`, [hashtag]);
  const [isLoaded, setIsLoaded] = useState(false);
  const theme = useTheme();
  const hashtagConfig = theme.getHashtagConfig(fullHashTag);

  const handleLoaded = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    setCrumbs([fullHashTag]);
  }, []);

  useEffect(() => {
    const newCrumbs = [fullHashTag];
    if (!isEqual(crumbs, newCrumbs)) {
      setCrumbs(newCrumbs);
    }
  }, [fullHashTag]);

  return (
    <div className="timeline-hashtags">
      <BreadcrumbHeader className="timeline-hashtags__header" crumbs={crumbs} />
      <div className="timeline-hashtags__container">
        {isLoaded && hashtagConfig?.hashtagsFeedAnimationFileUrl && (
          <Lottie
            className="w-full h-[calc(100vh-143px)] absolute z-0 left-0 top-0"
            delayToStart={500}
            path={hashtagConfig.hashtagsFeedAnimationFileUrl}
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
          />
        )}
        <div className="timeline-hashtags__feed">
          <FeedV2
            filters={{
              hashtag,
            }}
            onLoaded={handleLoaded}
            showFeedOrderByToggle={false}
            showFilters={false}
            showTabs={false}
          />
        </div>
      </div>
      <RecognitionExpanded />
      <InteractionModal classNames="fullscreen-modal--custom-interacion" />
    </div>
  );
};

TimelineHashtags.propTypes = {
  intl: PropTypes.object.isRequired,
  router: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

export default connect(mapStateToProps)(
  injectIntl(withRouter(TimelineHashtags)),
);
