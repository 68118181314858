export const DEFAULT_STAR_COLOR = '#7c8089';
export const DEFAULT_STAR = {
  backgroundColor: DEFAULT_STAR_COLOR,
  isKudos: false,
  minimum: 1,
  maximum: 1,
};
export const HASHTAG = 'HASHTAG';
export const MAX_COMMENT_CHARACTERS = 500;
export const MIN_CHARACTERS_TO_SEARCH = 2;
export const ORIGIN = {
  LIFT_STAR: 'LIFT_STAR',
  SEND_STAR_FLOW: 'SEND_STAR_FLOW',
  SURVEYS: 'SURVEYS',
  YIR: 'YIR',
};
export const STATUS = {
  NONE: 'NONE',
  INITIAL: 'INITIAL',
  SENDING: 'SENDING',
  WAITING: 'WAITING',
  LAST: 'LAST',
};
export const USER = 'USER';
export const YOURSELF_BG_COLOR = '#006bb8';
export const getRequestIdSuggestionsHashtags = () => 'smu-suggestions-hashtags';
export const getRequestIdSuggestionsUsers = () => 'smu-suggestions-users';
