import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';

const ShareButtons = ({ buttons, className }) => (
  <div className={cn('w-full flex items-center space-x-8', className)}>
    {buttons?.map((button) => (
      <div
        className={cn('flex flex-col items-center', {
          'opacity-50': button?.disabled,
        })}
      >
        <Button
          className={cn(
            'flex items-center justify-center rounded-full h-10 w-10 p-0 !text-white',
            {
              '!bg-gray-400': button?.disabled,
            },
          )}
          disabled={button?.disabled}
          key={button?.name}
          onClick={button?.onClick}
          style={{
            backgroundColor: button?.color,
          }}
        >
          <Icon icon={button?.icon} />
        </Button>
        {button?.name && (
          <Typography className="mt-2 font-openSans">{button.name}</Typography>
        )}
      </div>
    ))}
  </div>
);

ShareButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      icon: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default ShareButtons;
