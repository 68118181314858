// @packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigateToLoginV2 } from 'smu-utils/globalRequestHelper';

const LogoutLayout = ({ router }) => {
  useEffect(() => {
    router.replace('/home');
    navigateToLoginV2();
  }, []);

  return null;
};

LogoutLayout.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default LogoutLayout;
