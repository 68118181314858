import { defineMessages } from 'react-intl';

export default defineMessages({
  overallActivity: {
    id: 'leaderboard.overallActivity',
    defaultMessage: 'OVERALL ACTIVITY',
  },
  activeUsers: {
    id: 'leaderboard.activeUsers',
    defaultMessage: 'Active Users',
  },
  sentStars: {
    id: 'leaderboard.sentStars',
    defaultMessage: 'Sent Stars',
  },
});
