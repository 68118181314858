// @packages
import React, { useEffect, useRef } from 'react';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

function MenuPopUp({ className, children, open, onClose, options, inverted }) {
  const refMenu = useRef(null);

  function handleClickOption(callback) {
    onClose();
    if (callback) callback();
  }

  function getStylesOptions() {
    const styles = {};

    if (refMenu && refMenu.current) {
      styles.top = `${refMenu.current.clientHeight + 7}px`;
    }

    return styles;
  }

  function getStylesArrow() {
    const styles = {};

    if (refMenu && refMenu.current) {
      styles.right = `${refMenu.current.clientWidth / 2 - 7}px`;
    }

    return styles;
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (open && refMenu.current && !refMenu.current.contains(event.target)) {
        onClose();
      }
    }

    if (open) document.addEventListener('mousedown', handleClickOutside);
    return () =>
      open && document.removeEventListener('mousedown', handleClickOutside);
  }, [refMenu, open]);

  return (
    <div
      className={cn(
        'menu-popup',
        {
          'menu-popup--open': open,
          'menu-popup--inverted': inverted,
        },
        className,
      )}
      ref={refMenu}
    >
      {children}
      <ul className="menu-popup__options" style={getStylesOptions()}>
        {open && <div className="menu-popup__arrow" style={getStylesArrow()} />}
        {options?.map((option, key) => (
          <li
            className="menu-popup__option"
            key={`menu-option-key-${key}`}
            onClick={() => handleClickOption(option?.onClick)}
          >
            {option?.iconLeft && (
              <Icon
                className={cn(
                  'menu-popup__option-icon menu-popup__option-icon--left',
                )}
                icon={option?.iconLeft}
              />
            )}
            {option?.children}
          </li>
        ))}
      </ul>
    </div>
  );
}

MenuPopUp.defaultProps = {
  inverted: false,
  open: false,
};

MenuPopUp.propTypes = {
  children: PropTypes.node.isRequired,
  inverted: PropTypes.bool,
  open: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
      iconLeft: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
    }),
  ),
};

export default MenuPopUp;
