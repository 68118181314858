import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, vo } from './../../api';

export function moderateComment(comment) {
  return apiInstance({
    url: addParams(`/v2/stellar/comments/${comment.id}/${comment.enabled ? 'hide' : 'show'}`, { ...vo }),
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(({ data: token }) => token)
    .catch(errorHandler);
}
