import { defaultApi, apiInstance, bethereApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from 'api';

export const uploadPostApi = ({ query, data }) => {
  const { orgId } = query;
  data.append('tagged_users', JSON.stringify(query?.tagged_users));
  data.append('post_mentions', JSON.stringify(query?.mentions));

  return bethereApiInstance({
    method: 'POST',
    url: `/organization/${orgId}/posts`,
    params: {
      post_description: query?.post_description,
      post_type: query?.post_type,
      sponsored: query?.sponsored,
      location: JSON.stringify(query?.location),
    },
    data,
    timeout: 0,
  })
    .then(({ data }) => data)
    .catch(error => errorHandler(error, undefined, false));
};

export const uploadPostApi2 = ({ query, data }) => {
  data.append('tagged_users', JSON.stringify(query?.tagged_users));
  const { orgId } = query;
  data.append('post_mentions', JSON.stringify(query?.mentions));
  return defaultApi({
    method: 'POST',
    url: `/bethere/organization/${orgId}/posts`,
    params: {
      post_description: query?.post_description,
      post_type: query?.post_type,
      sponsored: query?.sponsored,
      location: JSON.stringify(query?.location),
    },
    data,
    timeout: 0,
  })
    .then(({ data }) => data)
    .catch(error => errorHandler(error, undefined, false));
};

export function searchUsers(query = '') {
  return apiInstance({
    url: addParams('/v2/stellar/locate', {
      ...vo,
      ...times(),
      searchString: query,
      filterType: 'USER',
      size: 10,
    }),
    method: 'get',
  })
    .then(({ data: result }) => result.result)
    .catch(errorHandler);
}
