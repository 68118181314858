import { defineMessages } from 'react-intl';

export default defineMessages({
  congratulationBirthdayCount: {
    id: 'Congratulation.Birthday.Count',
    defaultMessage: '{xxx} colleagues have wished {xxxx} a happy birthday',
  },
  congratulationBirthdayCountOwner: {
    id: 'Congratulation.Birthday.CountOwner',
    defaultMessage: '{xxx} colleagues have wished you a happy birthday',
  },
  congratulationBirthdayTitle: {
    id: 'Congratulation.Birthday.Title',
    defaultMessage: "It's {xxx}'s Birthday!",
  },
  congratulationBirthdayDescription: {
    id: 'Congratulation.Birthday.Description',
    defaultMessage: 'Leave a comment to join the celebration',
  },
  congratulationBirthdayUserDescriptionRewards: {
    id: 'Congratulation.Birthday.UserDescriptionRewards',
    defaultMessage: 'To celebrate your birthday, we want to give you a gift.',
  },
  congratulationBirthdayButton: {
    id: 'Congratulation.Birthday.Button',
    defaultMessage: 'Say Happy Birthday!',
  },
  congratulationBirthdayButtonRewards: {
    id: 'Congratulation.Birthday.ButtonRewards',
    defaultMessage: 'Get my reward',
  },
  congratulationAnniversaryTitle: {
    id: 'Congratulation.Anniversary.Title',
    defaultMessage: "It's {xxx}'s Anniversary!",
  },
  congratulationAnniversaryDescription: {
    id: 'Congratulation.Anniversary.Description',
    defaultMessage: 'Leave a comment to join the celebration',
  },
  congratulationAnniversaryUserDescriptionRewards: {
    id: 'Congratulation.Anniversary.UserDescriptionRewards',
    defaultMessage: 'To celebrate your day and to thank you for being part of {xxx}, we want to give you a gift.',
  },
  congratulationAnniversaryButton: {
    id: 'Congratulation.Anniversary.Button',
    defaultMessage: 'Say Happy Anniversary!',
  },
  congratulationAnniversaryButtonRewards: {
    id: 'Congratulation.Anniversary.ButtonRewards',
    defaultMessage: 'Get my reward',
  },
});
