// @packages
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { setProfileImage } from 'smu-unified-profile/services/profile/actions';

// @app
import { DEFAULT_TOAST_TIMEOUT } from 'utils/constants';
import { feedSetFields } from 'containers/withFeed/actions';

// @own
import {
  UPLOAD_USER_IMAGE,
  UPLOAD_USER_IMAGE_SUCCESS,
  UPLOAD_USER_IMAGE_FAIL,
  SET_OSFEED_PREFERENCE,
  SET_OSFEED_PREFERENCE_FAIL,
  SET_OSFEED_PREFERENCE_SUCCESS,
} from './actionTypes';
import { uploadImage, changeProfilePicture, registerOsFeedPreference } from './api';

function* changeProfilePictureWorker({ payload }) {
  try {
    yield put(addToastMessage({
      message: payload?.texts?.loading,
      timeout: 3000,
    }));
    const response = yield call(uploadImage, { image: payload.file });
    if (response?.status === 'FAIL') {
      yield put(addToastMessage({
        message: payload?.texts?.error,
        timeout: DEFAULT_TOAST_TIMEOUT,
      }));
      yield put({ type: UPLOAD_USER_IMAGE_FAIL });
    } else {
      yield call(changeProfilePicture, {
        imageCode: response?.result?.id,
        id: payload?.id,
      });
      yield put({
        type: UPLOAD_USER_IMAGE_SUCCESS,
        payload: { code: response?.result?.code, id: response?.result?.id },
      });
      yield put(setProfileImage({
        file: response?.result?.code,
      }));
      if (payload?.texts && payload?.texts?.success) {
        yield put(addToastMessage({
          message: payload?.texts?.success,
          timeout: DEFAULT_TOAST_TIMEOUT,
        }));
      }
    }
  } catch (e) {
    yield put({ type: UPLOAD_USER_IMAGE_FAIL });
    yield put(addToastMessage({
      message: e.message,
      timeout: DEFAULT_TOAST_TIMEOUT,
    }));
  }
}

function* changeProfilePictureWatcher() {
  yield takeEvery(UPLOAD_USER_IMAGE, changeProfilePictureWorker);
}

function* setOsFeedPreferenceWorker({ payload }) {
  try {
    const { osfeed, userUid } = payload;
    const data = yield call(registerOsFeedPreference, { osfeed, userUid });
    if (data.error) {
      yield put({ type: SET_OSFEED_PREFERENCE_FAIL });
    } else {
      yield put(feedSetFields({
        options: { filterBy: '' },
        updateFeed: true,
      }));
      yield put({
        type: SET_OSFEED_PREFERENCE_SUCCESS,
        payload: { osFeed: osfeed },
      });
    }
  } catch (e) {
    yield put({ type: SET_OSFEED_PREFERENCE_FAIL });
  }
}

function* setOsFeedPreferenceWatcher() {
  yield takeEvery(SET_OSFEED_PREFERENCE, setOsFeedPreferenceWorker);
}

export default {
  changeProfilePictureWatcher,
  setOsFeedPreferenceWatcher,
};
