import {
  FILTERS_INITIAL,
  REQUEST_FILTERS_UPDATE,
  UPDATE_FILTERS,
} from './actionTypes';

export function filtersInitial(payload) {
  return {
    type: FILTERS_INITIAL,
    payload,
  };
}

export function requestFiltersUpdate(payload) {
  return {
    type: REQUEST_FILTERS_UPDATE,
    payload,
  };
}

export function updateFilters(payload) {
  return {
    type: UPDATE_FILTERS,
    payload,
  };
}
