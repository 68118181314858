import { errorHandler } from 'utils/errorHandler';
import { smuInstance } from 'utils/requestHelper';
import paramsBuilder from 'utils/paramsBuilder';
import { COOKIES_NAME, LOCALE_RESOURCES_COOKIES } from './constants';

const cookiesServiceHeader = token => ({
  accept: 'application/json',
  Authorization: `Bearer ${token}`,
});

export function getLocaleResources(token, language) {
  const params = {
    origin: 'SMU_WEB_v3',
    resourceName: LOCALE_RESOURCES_COOKIES,
  };
  const headers = { ...cookiesServiceHeader(token), language };

  return smuInstance({
    url: `/os-member/api/v1/community/member/locales?${paramsBuilder(params)}`,
    data: {},
    headers,
    method: 'get',
  })
    .then(data => data)
    .catch(errorHandler);
}

export function getCommunityPreferences(token) {
  return smuInstance({
    url: '/os-member/api/v1/community/preferences?name=cookies',
    data: {},
    headers: cookiesServiceHeader(token),
    method: 'get',
  })
    .then(data => data)
    .catch(errorHandler);
}

export function getUserPreferences(token) {
  return smuInstance({
    url: '/os-member/api/v1/community/member/preferences?name=cookies&name=unifiedProfile',
    data: {},
    headers: cookiesServiceHeader(token),
    method: 'get',
  })
    .then(data => data)
    .catch(errorHandler);
}

export function storeUserPreferences(data, token) {
  const headers = cookiesServiceHeader(token);
  const value = JSON.stringify(data);

  return smuInstance({
    url: '/os-member/api/v1/community/member/preferences?name=cookies',
    data: {
      name: COOKIES_NAME,
      value,
    },
    headers,
    method: 'post',
  })
    .then(data => data)
    .catch(errorHandler);
}
