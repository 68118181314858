export const getBoxPositionOnWindowCenter = (width, height) => ({
  left:
    window.outerWidth / 2 +
    (window.screenX || window.screenLeft || 0) -
    width / 2,
  top:
    window.outerHeight / 2 +
    (window.screenY || window.screenTop || 0) -
    height / 2,
});

export const getBoxPositionOnScreenCenter = (width, height) => ({
  top: (window.screen.height - height) / 2,
  left: (window.screen.width - width) / 2,
});

export function windowOpen(url, { height, width, ...configRest }, onClose) {
  const config = {
    centerscreen: 'yes',
    chrome: 'yes',
    directories: 'no',
    height,
    location: 'no',
    menubar: 'no',
    resizable: 'no',
    scrollbars: 'yes',
    status: 'no',
    toolbar: 'no',
    width,
    ...configRest,
  };

  const shareDialog = window.open(
    url,
    '',
    Object.keys(config)
      .map((key) => `${key}=${config[key]}`)
      .join(', '),
  );

  if (onClose) {
    const interval = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(interval);
          onClose(shareDialog);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }, 1000);
  }

  return shareDialog;
}
