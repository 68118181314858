import { getEnv } from 'smu-utils/storage';
import { firebaseQa, firebaseProd } from '../configs/firebase';

const devEnv = ['dev', 'qa', 'uat'];

const env = getEnv();

export function getConfig() {
  if (devEnv.includes(env)) {
    return firebaseQa;
  } else {
    return firebaseProd;
  }
}
