import {
  REQUEST_COMMUNITY_MEMBER,
  REQUEST_COMMUNITY_MEMBER_SUCCESS,
  REQUEST_COMMUNITY_MEMBER_FAIL,
} from './actionTypes';

export function actionCommunityMember(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_COMMUNITY_MEMBER,
  };
}

export function actionCommunityMemberSuccess(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_COMMUNITY_MEMBER_SUCCESS,
  };
}

export function actionCommunityMemberFail(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_COMMUNITY_MEMBER_FAIL,
  };
}
