import {
  ADD_ITEM_OS_FEED,
  ADD_UNVISITED_OS_FEED_ITEM,
  ADD_VISITED_OS_FEED_ITEM,
  CLEAN_OS_FEED,
  CLEAN_OS_FEED_PARAMS,
  CLEAN_UNVISITED_OS_FEED_ITEMS,
  GET_OS_FEED,
  GET_OS_FEED_FAIL,
  GET_OS_FEED_HIGHLIGHTS,
  GET_OS_FEED_HIGHLIGHTS_FAIL,
  GET_OS_FEED_HIGHLIGHTS_SUCCESS,
  GET_OS_FEED_PREFERENCE,
  GET_OS_FEED_PREFERENCE_FAIL,
  GET_OS_FEED_PREFERENCE_SUCCESS,
  GET_OS_FEED_SUCCESS,
  POST_OS_FEED_COMMENT,
  POST_OS_FEED_COMMENT_FAIL,
  POST_OS_FEED_COMMENT_SUCCESS,
  POST_OS_FEED_LIFT_STAR_SUCCESS,
  POST_OS_FEED_LIKE,
  POST_OS_FEED_LIKE_FAIL,
  POST_OS_FEED_LIKE_SUCCESS,
  POST_OS_FEED_LOAD_IMAGE,
  POST_OS_FEED_LOAD_IMAGE_FAIL,
  POST_OS_FEED_LOAD_IMAGE_SUCCESS,
  POST_OS_FEED_MODERATE_COMMENT,
  POST_OS_FEED_MODERATE_COMMENT_FAIL,
  POST_OS_FEED_MODERATE_COMMENT_SUCCESS,
  POST_OS_FEED_PREFERENCE,
  POST_OS_FEED_PREFERENCE_FAIL,
  POST_OS_FEED_PREFERENCE_SUCCESS,
  POST_OS_FEED_REMOVE_LIKE,
  POST_OS_FEED_REMOVE_LIKE_FAIL,
  POST_OS_FEED_REMOVE_LIKE_SUCCESS,
  POST_OS_FEED_TURN_TOGGLE,
  POST_OS_FEED_TURN_TOGGLE_SUCCESS,
  POST_OS_FEED_UPDATE_POST,
  POST_OS_FEED_VISITED_POSTS,
  POST_OS_FEED_VISITED_POSTS_FAIL,
  SET_OS_FEED_PARAMS,
} from './actionTypes';

function getOsFeed({ params, showOsFeed }) {
  return {
    type: GET_OS_FEED,
    payload: {
      params,
      showOsFeed,
    },
  };
}

function getOsFeedFail(payload) {
  return {
    type: GET_OS_FEED_FAIL,
    payload,
  };
}

function getOsFeedSuccess({ feed, hasMore, totalElements }) {
  return {
    type: GET_OS_FEED_SUCCESS,
    payload: {
      feed,
      hasMore,
      totalElements,
    },
  };
}

function addOsFeedLiftStar(payload) {
  return {
    type: POST_OS_FEED_LIFT_STAR_SUCCESS,
    payload,
  };
}

function getOsFeedHighlights({ api, params, showOsFeed }) {
  return {
    type: GET_OS_FEED_HIGHLIGHTS,
    payload: {
      api,
      params,
      showOsFeed,
    },
  };
}

function getOsFeedHighlightsFail({ errorMessage }) {
  return {
    type: GET_OS_FEED_HIGHLIGHTS_FAIL,
    payload: {
      errorMessage,
    },
  };
}

function getOsFeedHighlightsSuccess({ feed, totalElements }) {
  return {
    type: GET_OS_FEED_HIGHLIGHTS_SUCCESS,
    payload: {
      feed,
      totalElements,
    },
  };
}

function postOsFeedComment({ api, comments, id }) {
  return {
    type: POST_OS_FEED_COMMENT,
    payload: {
      api,
      comments,
      id,
    },
  };
}

function postOsFeedCommentFail({ errorMessage }) {
  return {
    type: POST_OS_FEED_COMMENT_FAIL,
    payload: {
      errorMessage,
    },
  };
}

function postOsFeedCommentSuccess({ comment }) {
  return {
    type: POST_OS_FEED_COMMENT_SUCCESS,
    payload: {
      comment,
    },
  };
}

function postOsFeedLike({ api, id }) {
  return {
    type: POST_OS_FEED_LIKE,
    payload: {
      api,
      id,
    },
  };
}

function postOsFeedLikeFail({ errorMessage }) {
  return {
    type: POST_OS_FEED_LIKE_FAIL,
    payload: {
      errorMessage,
    },
  };
}

function postOsFeedLikeSuccess({ like }) {
  return {
    type: POST_OS_FEED_LIKE_SUCCESS,
    payload: {
      like,
    },
  };
}

function postOsFeedRemoveLike({ api, id, likeId }) {
  return {
    type: POST_OS_FEED_REMOVE_LIKE,
    payload: {
      api,
      id,
      likeId,
    },
  };
}

function postOsFeedRemoveLikeFail({ errorMessage }) {
  return {
    type: POST_OS_FEED_REMOVE_LIKE_FAIL,
    payload: {
      errorMessage,
    },
  };
}

function postOsFeedRemoveLikeSuccess({ like }) {
  return {
    type: POST_OS_FEED_REMOVE_LIKE_SUCCESS,
    payload: {
      like,
    },
  };
}

function postOsFeedTurnToggle({ osFeed }) {
  return {
    type: POST_OS_FEED_TURN_TOGGLE,
    payload: {
      osFeed,
    },
  };
}

function postOsFeedTurnToggleSuccess({ api, osFeed, tab }) {
  return {
    type: POST_OS_FEED_TURN_TOGGLE_SUCCESS,
    payload: {
      api,
      osFeed,
      tab,
    },
  };
}


function postOsFeedPreferenceV2({ osFeed, tab }) {
  return {
    type: POST_OS_FEED_PREFERENCE,
    payload: {
      osFeed,
      tab,
    },
  };
}

function postOsFeedPreferenceV2Fail({ errorMessage }) {
  return {
    type: POST_OS_FEED_PREFERENCE_FAIL,
    payload: {
      errorMessage,
    },
  };
}

function postOsFeedPreferenceV2Success({ osFeed }) {
  return {
    type: POST_OS_FEED_PREFERENCE_SUCCESS,
    payload: {
      osFeed,
    },
  };
}

function postOsFeedLoadImage({ api, file, id }) {
  return {
    type: POST_OS_FEED_LOAD_IMAGE,
    payload: {
      api,
      file,
      id,
    },
  };
}

function postOsFeedLoadImageFail({ errorMessage }) {
  return {
    type: POST_OS_FEED_LOAD_IMAGE_FAIL,
    payload: {
      errorMessage,
    },
  };
}

function postOsFeedLoadImageSuccess({ image }) {
  return {
    type: POST_OS_FEED_LOAD_IMAGE_SUCCESS,
    payload: {
      image,
    },
  };
}

function postOsFeedModerateComment({
  api,
  starId,
  id,
  status,
}) {
  return {
    type: POST_OS_FEED_MODERATE_COMMENT,
    payload: {
      api,
      id,
      status,
      starId,
    },
  };
}

function postOsFeedModerateCommentFail({ errorMessage }) {
  return {
    type: POST_OS_FEED_MODERATE_COMMENT_FAIL,
    payload: {
      errorMessage,
    },
  };
}

function postOsFeedModerateCommentSuccess({ comment }) {
  return {
    type: POST_OS_FEED_MODERATE_COMMENT_SUCCESS,
    payload: {
      comment,
    },
  };
}

function cleanOsFeed() {
  return {
    type: CLEAN_OS_FEED,
  };
}

function cleanOsFeedParams() {
  return {
    type: CLEAN_OS_FEED_PARAMS,
  };
}

function setOsFeedParams({ options }) {
  return {
    type: SET_OS_FEED_PARAMS,
    payload: {
      params: options,
    },
  };
}

function getOsFeedPreferenceV2() {
  return {
    type: GET_OS_FEED_PREFERENCE,
  };
}

function getOsFeedPreferenceV2Fail({ errorMessage }) {
  return {
    type: GET_OS_FEED_PREFERENCE_FAIL,
    payload: {
      errorMessage,
    },
  };
}

function getOsFeedPreferenceV2Success({ osFeed }) {
  return {
    type: GET_OS_FEED_PREFERENCE_SUCCESS,
    payload: {
      osFeed,
    },
  };
}

function addItemOsToFeed(item) {
  return {
    type: ADD_ITEM_OS_FEED,
    payload: item,
  };
}

function addUnvisitedOsFeedItem(payload) {
  return {
    type: ADD_UNVISITED_OS_FEED_ITEM,
    payload: [payload],
  };
}

function cleanUnvisitedOsFeedItems() {
  return {
    type: CLEAN_UNVISITED_OS_FEED_ITEMS,
  };
}

function postOsFeedVisitedPosts(payload) {
  return {
    type: POST_OS_FEED_VISITED_POSTS,
    payload,
  };
}

function postOsFeedVisitedPostsFail(payload) {
  return {
    type: POST_OS_FEED_VISITED_POSTS_FAIL,
    payload,
  };
}

function addOsFeedVisitedItem(payload) {
  return {
    type: ADD_VISITED_OS_FEED_ITEM,
    payload,
  };
}

function actionOsFeedUpdatePost({ id, data }) {
  return {
    type: POST_OS_FEED_UPDATE_POST,
    payload: {
      id,
      data,
    },
  };
}

export {
  actionOsFeedUpdatePost,
  addItemOsToFeed,
  addOsFeedLiftStar,
  addOsFeedVisitedItem,
  addUnvisitedOsFeedItem,
  cleanOsFeed,
  cleanOsFeedParams,
  cleanUnvisitedOsFeedItems,
  getOsFeed,
  getOsFeedFail,
  getOsFeedHighlights,
  getOsFeedHighlightsFail,
  getOsFeedHighlightsSuccess,
  getOsFeedPreferenceV2,
  getOsFeedPreferenceV2Fail,
  getOsFeedPreferenceV2Success,
  getOsFeedSuccess,
  postOsFeedComment,
  postOsFeedCommentFail,
  postOsFeedCommentSuccess,
  postOsFeedLike,
  postOsFeedLikeFail,
  postOsFeedLikeSuccess,
  postOsFeedLoadImage,
  postOsFeedLoadImageFail,
  postOsFeedLoadImageSuccess,
  postOsFeedModerateComment,
  postOsFeedModerateCommentFail,
  postOsFeedModerateCommentSuccess,
  postOsFeedPreferenceV2,
  postOsFeedPreferenceV2Fail,
  postOsFeedPreferenceV2Success,
  postOsFeedRemoveLike,
  postOsFeedRemoveLikeFail,
  postOsFeedRemoveLikeSuccess,
  postOsFeedTurnToggle,
  postOsFeedTurnToggleSuccess,
  postOsFeedVisitedPosts,
  postOsFeedVisitedPostsFail,
  setOsFeedParams,
};
