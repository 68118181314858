// @packages
import React from 'react';
import Icon from 'smu-ui-components/IconV2';
import map from 'lodash/map';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @app
import Stereoscope from 'components/Stereoscope';

// @own
import './styles.scss';

function SelectedEntities({
  allowEditing,
  entitiesToShow,
  offset,
  onLeft,
  onRemoveEntity,
  onRight,
}) {
  const buildEntityItem = (entity) => {
    const { id, name, profileImageCode } = entity;

    return ({
      key: id,
      node: (
        <div className="selected-entities__user">
          <UserAvatar
            height={32}
            profileImageCode={profileImageCode}
            width={32}
          />
          <p
            className="selected-entities__user-name"
            style={{ cursor: 'pointer' }}
          >
            {name}
          </p>
          {allowEditing &&
            <Icon
              className="selected-entities__user-icon"
              icon="close"
              onClick={() => {
                onRemoveEntity(id);
                onLeft();
              }}
              size="small"
            />
          }
        </div>
      ),
    }
    );
  };

  return (
    <div className="selected-entities">
      <Stereoscope
        className="selected-entities__stereoscope"
        items={map(entitiesToShow, buildEntityItem)}
        offset={offset}
        onLeft={onLeft}
        onRight={onRight}
        size={3}
      />
    </div>
  );
}

export default SelectedEntities;
