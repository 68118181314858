// @App
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/Icon';
import { connect } from 'react-redux';

// @Project
import Star from 'assets/icons/lift-star-done.svg';
import Image from 'components/Image';
import { actions as routeActions } from 'utils/routes';

// @Own
import './styles.scss';

const Activity = ({
  imageCode,
  job,
  name,
  profileId,
  showProfile,
  type,
}) => (
  <div className="activity">
    <div className="activity__avatar">
      <a
        href={`/profile/${profileId}`}
        onClick={(e) => {
          e.preventDefault();
          showProfile(profileId);
        }}
      >
        <Image
          alt=""
          className="activity__avatar-image"
          code={imageCode}
          height={40}
          scale={1}
          width={40}
        />
      </a>
      <div className="activity__avatar-action">
        {type === 'LIFT_STAR' ? (
          <img
            src={Star}
            className="activity__avatar-star"
            alt=""
          />) : (
            <Icon
              color="red"
              icon="like-full"
              size="micro"
              className="activity__avatar-hearth"
            />
          )}
      </div>
    </div>
    <div className="activity__body">
      <a
        href={`/profile/${profileId}`}
        onClick={(e) => {
          e.preventDefault();
          showProfile(profileId);
        }}
      >
        <h4 className="activity__body-name">{name}</h4>
      </a>
      <p className="activity__body-job">{job}</p>
    </div>
  </div>
);

Activity.propTypes = {
  imageCode: PropTypes.string,
  job: PropTypes.string,
  name: PropTypes.string,
  profileId: PropTypes.string,
  showProfile: PropTypes.func,
  type: PropTypes.string,
};

export default connect(
  () => ({}),
  { showProfile: (profileId) => routeActions.profile({ profileId })},
)(Activity);
