// @packages
import React, { useEffect, useState, useRef } from 'react';
import Card from 'smu-ui-components/Card';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import Tabs from 'smu-ui-components/Tabs';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';
import moment from 'moment';
import { buildSurveyUrl } from 'smu-app-components/SurveysFeed/helpers';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isEmpty, map, omit } from 'lodash';
import { openModal } from 'smu-app-components/RootModal/actions';
import { withRouter } from 'react-router';

// @app
import InfiniteScroll from 'components/InfiniteScroll';
import Survey from 'components/Survey';
import SurveysWidgetSkeleton from 'components/Skeletons/SurveysWidget';
import emptyAvailableSrc from 'assets/img/surveys-empty-available.svg';
import emptyResultsSrc from 'assets/img/surveys-empty-results.svg';
import imageSrc from 'assets/img/surveys.svg';
import { BETHERE_CONFIRMATION_MODAL } from 'components/Bethere/ModalConfirmation/constants';
import { ENABLED_TAKEPART_SURVEY } from 'services/communityConfigs/constants';
import { actionRequestDestroy, actionRequestInit, actionRequestUpdateResult } from 'smu-utils/reduxRequests/actions';
import { castStringToBoolean, getInitials } from 'utils/formatString';
import { getCommunityConfigValue } from 'services/communityConfigs/selectors';
import { selectCommunityId, selectLanguageCode } from 'containers/Authorization/selectors';
import { selectRequesting, selectResultProp, selectResult } from 'smu-utils/reduxRequests/selectors';
import { trackEvent } from 'utils/gtm';

// @own
import EmptySurvey from './empty';
import messages from './messages';
import { apiGetQuestionaries, apiDismissQuestionaries } from './api';
import {
  AVAILABLE_SURVEYS,
  DISMISS_SURVEYS,
  STATUS_ARCHIVED,
  STATUS_AVAILABLED_VIEWED,
  STATUS_CLOSED,
  STATUS_OPEN,
  STATUS_RESULT_VIEWED,
  SURVEYS_INITIAL_PAGE,
  SURVEYS_RESULTS,
} from './constants';
import './styles.scss';
function SurveysWidget({
  actionRequestDestroy,
  actionRequestInit,
  actionRequestUpdateResult,
  availableSurveys,
  availableSurveysPage,
  availableSurveysTotal,
  availableSurveysUnseen,
  className,
  communityId,
  enabledTakePartSurvey,
  intl: { formatMessage },
  lang,
  openModal,
  requestingAvailableSurveys,
  requestingSurveysResults,
  resultsSurveyUnseen,
  router,
  surveysAvailableUpdate,
  surveysResults,
  surveysResultsPage,
  surveysResultsTotal,
  surveysResultsUpdate,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const surveyListRef = useRef(null);

  const handleTabChange = (tab) => {
    if (surveyListRef?.current) {
      surveyListRef.current.scrollTop = 0;
    }
    setActiveTab(tab);
    trackEvent({
      action: 'click_on_tab',
      category: 'home_surveys',
      label: tab ? 'surveys_results' : 'surveys_available',
    });
  };

  const buildRedDot = (props, message, validation) => (
    <div {...props}>
      <span>{message}</span>
      {validation && <span className="surveys-widget__red-dot" />}
    </div>
  );

  const hasUnseenAvailable = (status) =>
    status?.some(s => s === STATUS_OPEN) &&
    !status?.some(s => s === STATUS_AVAILABLED_VIEWED);

  const hasUnseenResults = (status) =>
    status?.some(s => s === STATUS_CLOSED) &&
    !status?.some(s => s === STATUS_RESULT_VIEWED) &&
    !status?.some(s => s === STATUS_ARCHIVED);

  const isUnseen = (status) =>
    hasUnseenAvailable(status) ||
    hasUnseenResults(status);

  const tabs = [{
    name: formatMessage(messages.surveysWidgetAvailableTabs),
    onClick: () => handleTabChange(0),
    renderTab: (props) =>
      buildRedDot(
        props,
        formatMessage(messages.surveysWidgetAvailableTabs),
        availableSurveysUnseen > 0,
      ),
  }, {
    name: formatMessage(messages.surveysWidgetResultsTabs),
    onClick: () => handleTabChange(1),
    renderTab: (props) =>
      buildRedDot(
        props,
        formatMessage(messages.surveysWidgetResultsTabs),
        resultsSurveyUnseen > 0,
      ),
  }];

  const interceptResponse = ({ id, response }) => {
    const getData = {
      AVAILABLE_SURVEYS: availableSurveys,
      SURVEYS_RESULTS: surveysResults,
    };

    return ({
      ...response,
      questionnaires: [...getData[id], ...response.questionnaires],
    });
  };

  const getSurvey = (id, status, page) => {
    actionRequestInit({
      api: apiGetQuestionaries,
      id,
      interceptResponse: response => interceptResponse({ id, response }),
      onError: () => firstLoad && setFirstLoad(false),
      onSuccess: () => firstLoad && setFirstLoad(false),
      params: { page, status, lang },
    });
  };

  const handleMarkAvailableAsViewed = (id) => {
    const elementToUpdate = surveysAvailableUpdate?.questionnaires?.find(el => el?.data?.id === id);
    const indexOfElement = surveysAvailableUpdate?.questionnaires?.indexOf(elementToUpdate);
    if (surveysAvailableUpdate?.questionnaires[indexOfElement]?.data) {
      surveysAvailableUpdate.questionnaires[indexOfElement]
        .data.userStatusOfSurvey.push(STATUS_AVAILABLED_VIEWED);
    }

    actionRequestUpdateResult(
      AVAILABLE_SURVEYS,
      {
        ...surveysAvailableUpdate,
        questionnaires: surveysAvailableUpdate.questionnaires,
      },
    );
  };

  const handleSurveyClick = (id, type) => {
    const getDataByType = {
      AVAILABLE_SURVEYS: {
        category: 'home_surveys_available',
        data: availableSurveys,
        url: buildSurveyUrl(id),
      },
      SURVEYS_RESULTS: {
        category: 'home_surveys_results',
        data: surveysResults,
        url: buildSurveyUrl(id, true),
      },
    };
    const { category, data, url } = getDataByType[type];
    const [survey] = data.filter(item => item?.data?.id === id);
    trackEvent({
      action: 'surveys_click',
      category,
      label: id,
      q_name: survey?.data?.title,
      q_date: moment(survey?.openedDate).format('MM-DD-YYYY'),
    });
    if (type === AVAILABLE_SURVEYS) {
      handleMarkAvailableAsViewed(id);
    }
    router.push(url);
  };

  const handleUpdateDismiss = (id) => {
    actionRequestUpdateResult(
      SURVEYS_RESULTS,
      {
        ...surveysResultsUpdate,
        questionnaires: surveysResultsUpdate?.questionnaires?.filter(el => el?.data?.id !== id),
      },
    );
  };

  const handleDismiss = (type, id, communityId, title) => {
    openModal({
      modalType: BETHERE_CONFIRMATION_MODAL,
      modalProps: {
        description: <FormattedMessage
          values={{ xxx: <b>&quot;{title}&quot;</b> }}
          {...messages.surveysWidgetModalDescription}
        />,
        onAccept: () => {
          actionRequestInit({
            api: apiDismissQuestionaries,
            params: {
              communityId,
              id,
              status: STATUS_ARCHIVED,
            },
            id: DISMISS_SURVEYS,
            onSuccess: () => handleUpdateDismiss(id),
          });
        },
        title: formatMessage(messages.surveysWidgetModalTitle),
      },
    });
  };

  const loadMore = ({id, page, status, requesting}) => {
    if (!requesting) {
      const nextPage = page + 1;
      getSurvey(id, status, nextPage);
    }
  };

  const getDataProps = (type) => {
    const dataProps = {
      0: {
        data: availableSurveys,
        emptyStateProps: {
          imageSrc: emptyAvailableSrc,
          text: formatMessage(messages.surveysWidgetEmptyAvailable),
        },
        type: AVAILABLE_SURVEYS,
        infiniteScrollProps: {
          dataLength: availableSurveys.length,
          hasMore: availableSurveys.length < availableSurveysTotal,
          next: () => loadMore({
            id: AVAILABLE_SURVEYS,
            page: availableSurveysPage,
            requesting: requestingAvailableSurveys,
            status: STATUS_OPEN,
          }),
        },
        isRequesting: !availableSurveys.length && requestingAvailableSurveys,
        onClick: id => handleSurveyClick(id, AVAILABLE_SURVEYS),
      },
      1: {
        data: surveysResults?.filter(result =>
          !result?.data?.userStatusOfSurvey?.some(s => s === STATUS_ARCHIVED)),
        emptyStateProps: {
          imageSrc: emptyResultsSrc,
          text: formatMessage(messages.surveysWidgetEmptyResults),
        },
        type: SURVEYS_RESULTS,
        infiniteScrollProps: {
          dataLength: surveysResults.length,
          hasMore: surveysResults.length < surveysResultsTotal,
          next: () => loadMore({
            id: SURVEYS_RESULTS,
            page: surveysResultsPage,
            requesting: requestingSurveysResults,
            status: STATUS_CLOSED,
          }),
        },
        isRequesting: !surveysResults.length && requestingSurveysResults,
        onClick: id => handleSurveyClick(id, SURVEYS_RESULTS),
      },
    };
    return dataProps[type];
  };

  const getSurveyComponent = ({
    closedDate,
    data,
    index,
    onClick,
    openedDate,
    type,
  }) => {
    const surveyProps = {
      appearance: data?.appearance,
      badge: getInitials(data?.title),
      className: 'mb-2 last:mb-0',
      date: type === AVAILABLE_SURVEYS ? openedDate : closedDate,
      displayDismiss: type === SURVEYS_RESULTS,
      key: `${data?.id}-${index}`,
      onClick: () => onClick(data?.id),
      onDismiss: (event) => {
        event.stopPropagation();
        handleDismiss(type, data?.id, communityId, data?.title);
      },
      ongoingSurveyMessage: formatMessage(messages.surveysWidgetOngoingSurvey),
      questionnaireId: data?.id,
      title: data?.title,
      type,
      unseen: isUnseen(data?.userStatusOfSurvey),
    };
    return (<Survey {...surveyProps} />);
  };

  const renderSurveys = (tab) => {
    const {
      data,
      emptyStateProps,
      isRequesting,
      onClick,
      type,
    } = getDataProps(tab);

    if (isRequesting) {
      return <LottieSpinner loadingType="starmeup" />;
    }

    return isEmpty(data)
      ? <EmptySurvey {...emptyStateProps} />
      : map(data, (item, index) => getSurveyComponent({index, onClick, type, tab, ...omit(item, 'type')}));
  };

  useEffect(() => {
    if (enabledTakePartSurvey) {
      getSurvey(AVAILABLE_SURVEYS, STATUS_OPEN);
      getSurvey(SURVEYS_RESULTS, STATUS_CLOSED);
    }
    return () => {
      actionRequestDestroy(AVAILABLE_SURVEYS);
      actionRequestDestroy(SURVEYS_RESULTS);
    };
  }, [enabledTakePartSurvey]);

  const { infiniteScrollProps } = getDataProps(activeTab);

  if (firstLoad) {
    return <SurveysWidgetSkeleton className="shadow-base" />;
  }

  return (
    <Card className={cn('surveys-widget shadow-base', className)}>
      <header className="px-3 py-4 flex items-center justify-between">
        <Typography className="text-blue text-sm" variant="title1">
          {formatMessage(messages.surveysWidgetTitle)}
        </Typography>
        <img className="w-14" src={imageSrc} alt="Surveys" />
      </header>
      <Tabs
        active={activeTab}
        extraTabClassName="surveys-widget__tab"
        tabs={tabs}
      />
      <div
        className="p-3 h-52 overflow-x-hidden overflow-y-auto"
        id="scrollableDiv"
        ref={surveyListRef}
      >
        <InfiniteScroll
          loadingType="starmeup"
          scrollableTarget="scrollableDiv"
          {...infiniteScrollProps}
        >
          {renderSurveys(activeTab)}
        </InfiniteScroll>
      </div>
    </Card>
  );
}

SurveysWidget.defaultProps = {
  enabledTakePartSurvey: false,
};

SurveysWidget.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  availableSurveys: PropTypes.array,
  availableSurveysPage: PropTypes.number,
  availableSurveysTotal: PropTypes.number,
  enabledTakePartSurvey: PropTypes.bool,
  intl: PropTypes.object,
  requestingAvailableSurveys: PropTypes.bool,
  requestingSurveysResults: PropTypes.bool,
  surveysResults: PropTypes.array,
  surveysResultsPage: PropTypes.number,
  surveysResultsTotal: PropTypes.number,
};

const mapStateToProps = state => ({
  availableSurveys: selectResultProp(state, AVAILABLE_SURVEYS, 'questionnaires', []),
  availableSurveysPage: selectResultProp(state, AVAILABLE_SURVEYS, 'page', SURVEYS_INITIAL_PAGE),
  availableSurveysTotal: selectResultProp(state, AVAILABLE_SURVEYS, 'totalItems', 0),
  availableSurveysUnseen: selectResultProp(state, AVAILABLE_SURVEYS, 'unseenCount', 0),
  communityId: selectCommunityId(state),
  enabledTakePartSurvey: castStringToBoolean(
    getCommunityConfigValue(state, ENABLED_TAKEPART_SURVEY),
  ),
  lang: selectLanguageCode(state),
  requestingAvailableSurveys: selectRequesting(state, AVAILABLE_SURVEYS),
  requestingSurveysResults: selectRequesting(state, SURVEYS_RESULTS),
  resultsSurveyUnseen: selectResultProp(state, SURVEYS_RESULTS, 'unseenCount', 0),
  surveysAvailableUpdate: selectResult(state, AVAILABLE_SURVEYS),
  surveysResults: selectResultProp(state, SURVEYS_RESULTS, 'questionnaires', []),
  surveysResultsPage: selectResultProp(state, SURVEYS_RESULTS, 'page', SURVEYS_INITIAL_PAGE),
  surveysResultsTotal: selectResultProp(state, SURVEYS_RESULTS, 'totalItems', 0),
  surveysResultsUpdate: selectResult(state, SURVEYS_RESULTS),
});

export default connect(mapStateToProps, {
  actionRequestDestroy,
  actionRequestInit,
  actionRequestUpdateResult,
  openModal,
})(withRouter(injectIntl(SurveysWidget)));
