import {
  REQUEST_ADVANCED_USER_FILTERS,
  REQUEST_ADVANCED_USER_FILTERS_SUCCESS,
  REQUEST_ADVANCED_USER_FILTERS_FAIL,
} from './actionTypes';

export function requestAdvancedUserFilters(payload) {
  return {
    type: REQUEST_ADVANCED_USER_FILTERS,
    payload,
  };
}

export function requestAdvancedUserFiltersSuccess(payload) {
  return {
    type: REQUEST_ADVANCED_USER_FILTERS_SUCCESS,
    payload,
  };
}

export function requestAdvancedUserFiltersFail(payload) {
  return {
    type: REQUEST_ADVANCED_USER_FILTERS_FAIL,
    payload,
  };
}
