// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';
import messages from '../messages';

function QuestionnaireFinished({ intl: { formatMessage } }) {
  return (
    <div className="questionnaires-flex-finished">
      <img
        alt="thanks"
        className="questionnaires-flex-finished__image"
        src="/assets/img/done_illustration.svg"
      />
      <div className="questionnaires-flex-finished__title">
        {formatMessage(messages.QuestionnairesFlexFinishedTitle)}
      </div>
    </div>
  );
}

QuestionnaireFinished.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(QuestionnaireFinished);
