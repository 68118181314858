// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import ActionCommentContainer from 'containers/Cards/components/ActionComment';

const StarActionCommentContainer = ({
  isModerator,
  messages,
  onClickIcon,
  ...rest
}) => {
  const count = messages?.filter(
    (c) => isModerator || c.enabled,
  )?.length;

  return (
    <ActionCommentContainer
      count={count}
      onClickIcon={onClickIcon}
      {...rest}
    />
  );
};

StarActionCommentContainer.propTypes = {
  isModerator: PropTypes.bool,
  messages: PropTypes.array,
  onClickIcon: PropTypes.func,
};

export default StarActionCommentContainer;
