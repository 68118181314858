// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import AdvancedUserSearchComponent from 'components/AdvancedUserSearch';
import {
  getAdvancedUserSearchPage,
  getAdvancedUserSearchRequesting,
  getAdvancedUserSearchResult,
  getAdvancedUserSearchTotal,
} from 'services/advancedUserSearch/selectors';
import { getAdvancedUserFiltersRequesting } from 'services/advancedUserFilters/selectors';
import { requestAdvancedUserSearch } from 'services/advancedUserSearch/actions';
import { DEFAULT_SIZE } from 'services/advancedUserSearch/constants';

// @own
import messages from './messages';

function AdvancedUserSearchContainer({ filters, intl }) {
  const dispatch = useDispatch();
  const page = useSelector(getAdvancedUserSearchPage);
  const requesting = useSelector(getAdvancedUserSearchRequesting);
  const requestingFilters = useSelector(getAdvancedUserFiltersRequesting);
  const result = useSelector(getAdvancedUserSearchResult);
  const total = useSelector(getAdvancedUserSearchTotal);
  const isLoading = requesting || requestingFilters;
  const isEmpty = !isLoading && !result?.content?.length;

  function fetchUsers(page) {
    dispatch(
      requestAdvancedUserSearch({
        data: { page, size: DEFAULT_SIZE, ...filters },
      }),
    );
  }

  function handleNext() {
    fetchUsers(page + 1);
  }

  return (
    <AdvancedUserSearchComponent
      emptyMessage={intl.formatMessage(messages.noMatch)}
      hasMore={!requesting && result?.content.length < total}
      isEmpty={isEmpty}
      isLoading={isLoading}
      onNext={handleNext}
      users={result?.content}
    />
  );
}

AdvancedUserSearchContainer.defaultProps = {
  filters: {},
};

AdvancedUserSearchContainer.propTypes = {
  filters: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AdvancedUserSearchContainer);
