import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getFiltersByUsesFail,
  getFiltersByUsesSuccess,
  getFiltersValuesByUses,
  getFiltersValuesByUsesFail,
  getFiltersValuesByUsesSuccess,
} from './actions';
import { OS_GET_FILTERS_BY_USES, OS_GET_FILTERS_VALUES_BY_USES } from './actionTypes';
import { getFiltersByUses, getFiltersValues } from './api';
import { FILTER_TYPES } from './constants';

function* getFiltersByUsesWorker({ payload }) {
  try {
    const result = yield call(getFiltersByUses, payload);
    let data;
    if (result) {
      let find = result.find(r => r.use === payload.use);
      if (find) {
        data = find.filters;
      }
    }
    if (data) {
      yield put(getFiltersValuesByUses({
        fields: data
          .reduce((acc, cur) => {
            if (cur.type === FILTER_TYPES.LIST) {
              acc.push({
                field: cur.field,
                use: payload.use,
              });
            }
            return acc;
          }, []),
        use: payload.use,
      }));
    }
    yield put(getFiltersByUsesSuccess({ use: payload.use, data }));
  } catch (e) {
    yield put(getFiltersByUsesFail(e));
  }
}

function* getFiltersByUsesWatcher() {
  yield takeLatest(OS_GET_FILTERS_BY_USES, getFiltersByUsesWorker);
}

function* getFiltersValuesWorker({ payload }) {
  try {
    const data = yield call(getFiltersValues, payload.fields);
    yield put(getFiltersValuesByUsesSuccess({ use: payload.use, data }));
  } catch (e) {
    yield put(getFiltersValuesByUsesFail(e));
  }
}

export function* getFiltersValuesWatcher() {
  yield takeEvery(OS_GET_FILTERS_VALUES_BY_USES, getFiltersValuesWorker);
}

export default {
  getFiltersByUsesWatcher,
  getFiltersValuesWatcher,
};
