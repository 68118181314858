// @packages
import React, { Component } from 'react';
import BannerCookiesConsent from 'smu-app-components/BannerCookiesConsent/New';
import ChangePasswordMandatory from 'smu-app-components/ChangePasswordMandatory';
import FullscreenModalPost from 'components/FullscreenModalPost';
import ImageModal from 'smu-ui-components/Image/Deprecated/ImageModal';
import PropTypes from 'prop-types';
import ToastNotifications from 'smu-app-components/ToastNotifications';
import cn from 'classnames';
import { connect } from 'react-redux';
import { detectWebView } from 'smu-utils/device';
import { getToken, getPathHost } from 'smu-utils/storage';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import { openModal } from 'smu-app-components/RootModalV2/actions';
import { withRouter } from 'react-router';

// @app
// import Confetti from 'components/Confetti';
// import EmptyState from 'containers/ExternalWidget/EmptyState';
// import { ENABLED_PUSH_NOTIFICATIONS } from 'services/communityConfigs/constants';
// import { WEB_PUSH } from 'services/userConfigs/constants';
// import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
// import { selectUserConfig } from 'services/userConfigs/selectors';
import AppNavbar from 'components/AppNavbar';
import Authorization from 'containers/Authorization';
import BethereModalConfirmation from 'components/Bethere/ModalConfirmation';
import CulturalLevels from 'containers/CulturalLevels';
import EulaAgreement from 'components/EulaAgreement';
import FooterExperience from 'components/FooterExperience';
import GenericModal from 'containers/GenericModal';
import GoogleTagManager from 'containers/GoogleTagManager';
import InsightDetailsModal from 'containers/InsightDetailsModal';
import KudosExpanded from 'containers/KudosExpanded';
import LivingValues from 'containers/LivingValues';
import ModalPriority from 'containers/ModalPriority';
import NavMenu from 'containers/NavMenu';
import OnboardingModal from 'containers/OnboardingModal';
import PushNotifications from 'components/PushNotifications';
import QueryModals from 'containers/QueryModals';
import SendStarFlow from 'containers/SendStarFlow';
import TCAndPPAgreement from 'components/TCAndPPAgreement';
import WhatsNewModal from 'containers/WhatsNewModal';
import { actionBethereProperties } from 'services/bethereProperties/actions';
import { actionGetCommunityPreferences } from 'containers/CookiesPolicyPage/actions';
import { closesuggestSendStar } from 'services/sendStarFlow/actions';
import { getCommunityAllowCookies } from 'containers/CookiesPolicyPage/selector';
import { requestCommunityConfigs } from 'services/communityConfigs/actions';
import { selectAuthWidgetFail, selectCommunityId } from 'containers/Authorization/selectors';

// @own
import './styles.scss';

class App extends Component {
  componentDidMount() {
    const {
      actionBethereProperties,
      actionGetCommunityPreferences,
      communityId,
      location,
    } = this.props;

    const token = getToken();
    actionGetCommunityPreferences({ token });
    actionBethereProperties(communityId);

    if (
      !detectWebView() &&
      location?.pathname === '/onboarding' &&
      location?.query?.performance === 'true'
    ) {
      this.redirectToOnboardingBME();
    }
  }

  redirectToOnboardingBME() {
    window.location.href = `${getWebUrl(
      'betterme',
      getPathHost(),
    )}/myprofile?modal=onboarding&performance=true`;
  }

  render() {
    const {
      // authWidgetFail,
      children,
      closesuggestSendStar,
      // communityId,
      communityAllowsCookies,
      enabledPushNotifications,
      openMenu,
      routing,
      session,
      // showConfetti,
      suggestSendStar,
      worldCupEnabled,
    } = this.props;

    const isWebView = detectWebView();

    // TODO: Deprecated?
    // const openModalWindow = () => {
    //   window.open(
    //     `${getWebUrl('login')}/?origin=SMU_WEB_v3&logout=true&widget=true`,
    //     'login-window',
    //     'height=400, left=50%, top=50%, width=500',
    //   );
    //   const lastToken = getToken();
    //   setInterval(() => {
    //     let currentToken = getToken();
    //     if (currentToken && currentToken !== lastToken) {
    //       window.location.reload();
    //     }
    //   }, 1000);
    // };

    if (!session?.user) {
      return null;
    }

    return (
      <Authorization communityAllowsCookies={communityAllowsCookies}>
        <div
          id="app"
          className={cn('smu-app', {
            worldcup: worldCupEnabled,
            'smu-app--open-menu': openMenu,
            'smu-app--webview': isWebView,
          })}
        >
          {/* {
            !isWebView && communityId === 2 && (
              <div
                className={cn(
                  'fixed top-[64px] h-[calc(100%-64px)] -z-10 opacity-80 bg-cover',
                  {
                    'w-[calc(100%-70px)] left-[70px]': !openMenu,
                    'w-[calc(100%-270px)] left-[270px]': openMenu,
                  },
                )}
                style={{
                  backgroundImage: "url('/assets/img/bg-globant.svg')",
                }}
              />
            )
          } */}
          <FullscreenModalPost />
          <TCAndPPAgreement />
          <EulaAgreement />
          <ChangePasswordMandatory />
          <SendStarFlow />
          <KudosExpanded />
          {!isWebView && communityAllowsCookies && (
            <>
              <BannerCookiesConsent />
              <GoogleTagManager />
            </>
          )}
          <LivingValues />
          {!isWebView && (
            <>
              <AppNavbar session={session} routing={routing} />
              <NavMenu />
            </>
          )}
          {suggestSendStar && (
            <div
              className="curtain"
              onClick={() => closesuggestSendStar()}
            />
          )}
          {children}
          <ImageModal />
          <ToastNotifications position="bottom-left" color="black" />
        </div>
        <BethereModalConfirmation />
        <InsightDetailsModal />
        <OnboardingModal />
        <GenericModal />
        {!isWebView && enabledPushNotifications && <PushNotifications />}
        <QueryModals />
        <ModalPriority />
        <WhatsNewModal />
        <FooterExperience />
        <CulturalLevels />
      </Authorization>
    );
  }
}

App.propTypes = {
  actionBethereProperties: PropTypes.func,
  actionGetCommunityPreferences: PropTypes.func,
  authWidgetFail: PropTypes.bool,
  children: PropTypes.node,
  closesuggestSendStar: PropTypes.func,
  communityAllowsCookies: PropTypes.bool,
  communityId: PropTypes.number,
  location: PropTypes.object.isRequired,
  openMenu: PropTypes.bool,
  openModal: PropTypes.func,
  requestCommunityConfigs: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  routing: PropTypes.object,
  session: PropTypes.object,
  showConfetti: PropTypes.bool,
  suggestSendStar: PropTypes.bool,
  userCookiesPreference: PropTypes.array,
  worldCupEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  authWidgetFail: selectAuthWidgetFail(state),
  communityAllowsCookies: getCommunityAllowCookies(state),
  // enabledPushNotifications: Boolean(selectUserConfig(state, WEB_PUSH))
  //   || getCommunityConfigValueBool(state, ENABLED_PUSH_NOTIFICATIONS),
  communityId: selectCommunityId(state),
  openMenu: state?.mainHeader?.openMenu,
  session: state.session,
  showConfetti: state.starFlow.showConfetti,
  suggestSendStar: state.starFlow.suggestSendStar,
  worldCupEnabled: Boolean(
    state.session.communityConfigurations &&
      state.session.communityConfigurations.worldCupCoreValueId,
  ),
});

const actions = {
  actionBethereProperties,
  actionGetCommunityPreferences,
  closesuggestSendStar,
  openModal,
  requestCommunityConfigs,
};

export default connect(mapStateToProps, actions)(withRouter(App));
