// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from 'smu-ui-components/Icon';

// @own
import messages from '../messages';

function BackToInsights({
  router: { push },
}) {
  return (
    <div className="insight-details__back-to">
      <span className="insight-details__back-to--grey" onClick={() => push('/insights')}>
        <Icon
          className="insight-details__back-to--icon"
          color="black"
          icon="back"
          size="extra-small"
        />
        <FormattedMessage {...messages.insightBack} />
      </span>
    </div>
  );
}

BackToInsights.propTypes = {
  eventData: PropTypes.object,
};

export default withRouter(injectIntl(BackToInsights));
