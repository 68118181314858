import { buildImageSMU } from 'utils/image';
import { getSummaryColors } from 'utils/DNA';
import { orderBy } from 'lodash';

export const helperOrganizationLogo = (imageId, negativeImageCode) => {
  const code = imageId ?? negativeImageCode;

  return code ? buildImageSMU({ code, width: 160, height: 160 }) : undefined;
};

export const helperUser = (member, blobUrl) => ({
  imageUrl: blobUrl.data,
  job: member.selectPropertyJob(),
  name: `${member.selectFirstName()} ${member.selectLastName()}`,
});

export const helperDNA = (member) => {
  const coreValuesSummary = member.selectCoreValuesSummary();
  const ordered = orderBy(coreValuesSummary, 'percentageStars', 'desc')?.filter(
    (q) => q?.totalStars > 0,
  );
  return getSummaryColors(ordered);
};

export const helperQualifiers = (member) => {
  const { total, data } = member.selectCoreValuesSummary()?.reduce(
    (acc, cur) => {
      acc.total += cur.totalStars;
      acc.data.push({
        color: cur?.star?.backgroundColor,
        value: cur?.totalStars,
        text: cur?.name,
      });
      return acc;
    },
    { total: 0, data: [] },
  );

  return {
    data: orderBy(data, 'value', 'desc'),
    total,
  };
};
