// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useMember } from 'smu-services/os-member/api/v1/community/member';
import { useSelector } from 'react-redux';

// @app
import ShareDNAComponent from 'components/Share/DNA';
import useBlobUrl from 'hooks/useBlobUrl';
import { ENABLED_SHARE_DNA } from 'services/communityConfigs/constants';
import { buildImageSMU } from 'utils/image';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import {
  selectOrganizationImageId,
  selectOrganizationName,
  selectOrganizationNegativeImageCode,
} from 'containers/Authorization/selectors';

// @own
import messages from './messages';
import {
  helperDNA,
  helperOrganizationLogo,
  helperQualifiers,
  helperUser,
} from './helpers';

function ShareDNAContainer({
  intl: { formatMessage },
  onReady,
  onError,
}) {
  const member = useMember({ coreValues: true });
  const organizationName = useSelector(selectOrganizationName);
  const enabledShareDNA = useSelector((state) =>
    getCommunityConfigValueBool(state, ENABLED_SHARE_DNA));
  const negativeImageCode = useSelector((state) =>
    selectOrganizationNegativeImageCode(state));
  const imageCode = useSelector((state) => selectOrganizationImageId(state));
  const profileImage = buildImageSMU({
    code: member.selectImageCode(),
    height: 160,
    width: 160,
  });
  const blobUrl = useBlobUrl(profileImage);
  const isLoading = blobUrl.isLoading || member.isLoading;
  const isError = !enabledShareDNA || blobUrl.error || member.error;
  const isSuccess = blobUrl.data && member.data;
  const user = helperUser(member, blobUrl);
  const organizationLogo = helperOrganizationLogo(imageCode, negativeImageCode);
  const qualifiers = helperQualifiers(member);
  const dna = helperDNA(member);

  useEffect(() => {
    if (isError) {
      onError(isError);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      onReady();
    }
  }, [isSuccess]);

  return (
    <ShareDNAComponent
      description={formatMessage(messages.shareDNADescription, {
        xxx: organizationName,
      })}
      dna={dna}
      isError={isError}
      isLoading={isLoading}
      organizationLogo={organizationLogo}
      qualifiers={qualifiers}
      title={formatMessage(messages.shareDNATitle, { xxx: organizationName })}
      user={user}
    />
  );
}

ShareDNAContainer.propTypes = {
  intl: PropTypes.object.isRequired,
  onReady: PropTypes.func,
  onError: PropTypes.func,
};

ShareDNAContainer.defaultProps = {
  onReady: () => undefined,
  onError: () => undefined,
};

export default injectIntl(ShareDNAContainer);
