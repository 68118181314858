// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { apiGetCommunityMembers } from './api';
import { REQUEST_COMMUNITY_MEMBERS } from './actionTypes';
import {
  actionCommunityMembersFail,
  actionCommunityMembersSuccess,
} from './actions';

function* getCommunityMembersWorker({ payload }) {
  try {
    const { data } = yield call(apiGetCommunityMembers, payload);
    yield put(actionCommunityMembersSuccess({ result: data?.result }));
  } catch (error) {
    yield put(actionCommunityMembersFail({ error }));
  }
}

export function* getCommunityMembersWatcher() {
  yield takeEvery(REQUEST_COMMUNITY_MEMBERS, getCommunityMembersWorker);
}

export default {
  getCommunityMembersWatcher,
};
