import { isNull, defaults } from 'lodash';
import { takeEvery, call, put } from 'redux-saga/effects';

import { apiUrl } from 'utils/apiUrl';
import addToast from 'utils/defaultToastAdd';
import { mapObject } from 'utils/object';
import facebookApiKey from 'utils/facebookApiKey';
import { addParams } from './../../api';

import {
  getShareCode as getShareCodeAPI,
  registerShared as registerSharedAPI,
} from './api';

const SOCIAL_MEDIA_SHARE = 'SOCIAL_MEDIA_SHARE';

export const socialMediaShare = payload => ({
  type: SOCIAL_MEDIA_SHARE,
  payload: defaults(payload, {
    description: '',
    title: '',
  }),
});

function* socialMediaShareWorker({ payload }) {
  const { windowRef } = payload;
  const { result: shareCode, ...codeResult } = yield call(getShareCodeAPI, payload);
  if (codeResult.status === 'FAIL') {
    windowRef.close();
    yield put(addToast({
      message: codeResult.message,
    }));
  } else {
    const longUrl = `${apiUrl}/v2/share/page/${shareCode}`;
    let popUpUrl = {};
    switch (payload.type) {
      case 'TWITTER':
        popUpUrl = {
          baseUrl: 'https://twitter.com/intent/tweet',
          options: {
            url: longUrl,
            text: `#${payload.description}`,
          },
        };
        break;
      case 'FACEBOOK':
        popUpUrl = {
          baseUrl: 'https://www.facebook.com/dialog/feed',
          options: {
            app_id: facebookApiKey,
            display: 'popup',
            link: longUrl,
            title: payload.title,
            description: payload.description,
          },
        };
        break;
      case 'LINKEDIN':
        popUpUrl = {
          baseUrl: 'https://www.linkedin.com/shareArticle',
          options: {
            mini: 'true',
            url: longUrl,
          },
        };
        break;
      case 'WORKPLACE':
        popUpUrl = {
          baseUrl: `https://${payload.enabled}.facebook.com/dialog/feed`,
          options: {
            app_id: facebookApiKey,
            display: 'popup',
            link: longUrl,
            title: payload.title,
            description: payload.description,
          },
        };
        break;
      case 'GOOGLE+':
        popUpUrl = {
          baseUrl: 'https://plus.google.com/share',
          options: {
            url: longUrl,
          },
        };
        break;
      default:
        popUpUrl = {};
        break;
    }

    popUpUrl = addParams(
      popUpUrl.baseUrl,
      mapObject(popUpUrl.options, option => encodeURIComponent(option)),
    );

    windowRef.location = popUpUrl;

    const timer = setInterval(() => {
      if (isNull(windowRef) || windowRef.closed) {
        clearInterval(timer);
        registerSharedAPI({
          shareCode,
          type: payload.type,
        });
      }
    }, 1000);
  }
}

export function* socialMediaShareWatcher() {
  yield takeEvery(SOCIAL_MEDIA_SHARE, socialMediaShareWorker);
}

export default {
  socialMediaShareWatcher,
};
