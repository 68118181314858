// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';
import messages from '../../messages';

const MissSomebody = ({
  actionUrl,
  intl: { formatMessage },
}) => (
  <a
    className="insights-content-miss-somebody"
    href={actionUrl}
    target="_blank"
  >
    {formatMessage(messages.MissSomebody)}
  </a>
);

MissSomebody.propTypes = {
  actionUrl: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(MissSomebody);
