export const selectUsersByKudosStarId = (state) => state?.usersByKudosStarId;
export const selectUsersByKudosStarIdError = (state) =>
  state?.usersByKudosStarId?.error;
export const selectUsersByKudosStarIdRequesting = (state) =>
  state?.usersByKudosStarId?.requesting;
export const selectUsersByKudosStarIdResult = (state) =>
  state?.usersByKudosStarId?.result || [];
export const selectUsersByKudosStarIdPage = (state) =>
  state?.usersByKudosStarId?.page;
export const selectUsersByKudosStarIdHasMore = (state) =>
  state?.usersByKudosStarId?.hasMore;
