import { OSMemberApiInstance } from 'utils/requestHelper';

export function apiUsefulLinks({ languageCode }) {
  return OSMemberApiInstance({
    headers: { 'Accept-Language': languageCode },
    url: 'api/v1/community/communications/links',
  })
    .then(({ data }) => data?.result)
    .catch(err => err);
}
