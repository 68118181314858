export const USES = {
  ADVANCE_SEARCH: 'ADVANCE_SERACH',
  LEADERBOARD: 'LEADERBOARD',
  PROFILE: 'PROFILE',
  PROFILE_SENT: 'PROFILE_SENT',
};

export const FILTER_TYPES = {
  STRING: 'STRING',
  LIST: 'LIST',
};

export const MUST_EXCLUDE = [
  'email',
  'fullName',
  'period',
  'phoneNumber',
  'starName',
  'starPath',
];
