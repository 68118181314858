/* eslint max-len:0 */
import { partial } from 'lodash';
import { all, fork } from 'redux-saga/effects';

import toastNotificationsSaga from 'smu-app-components/ToastNotifications/sagas';
import suggestionsSaga from 'smu-app-components/Suggestions/sagas';
import * as intlSagas from 'containers/IntlProvider/sagas';
import searchSaga from 'smu-app-components/Search/sagas';
import * as searchActions from 'smu-app-components/Search/actions';
import topStarsSaga from 'containers/TopStarsPanel/sagas';
import * as authSaga from 'containers/Authorization/sagas';
import profileSaga from 'containers/withProfile/sagas';
import feed from 'containers/withFeed/sagas';
import stars from 'containers/Feed/sagas';
import filters from 'containers/Filters/sagas';
import filtersV2 from 'containers/FiltersV2/sagas';
import feedProfile from 'containers/FeedProfile/sagas';
import badges from 'components/BadgesWidget/sagas';
import leaderboard from 'containers/Leaderboard/sagas';
import leaderboardV2 from 'containers/LeaderboardV2/sagas';
import trendsSaga from 'containers/Trends/sagas';
import * as notificationsSaga from 'smu-app-components/NotificationsV2/sagas';
import * as starFlowSaga from 'services/sendStarFlow/sagas';
import addNamespace from 'containers/withNamespace';
import moderateComment from 'containers/Message/sagas';
import * as notificationsHistory from 'containers/NotificationsHistory/sagas';
import advancedSearchSaga from 'containers/AdvancedSearch/sagas';
import communityCountersSaga from 'containers/CommunityCounters/sagas';
import culturalCategory from 'components/CulturalCategory/sagas';
import culturalIndicator from 'components/CulturalIndicator/sagas';
import tellExperience from 'components/FooterExperience/sagas';
import SuiteSwitcher from 'smu-app-components/Switcher/sagas';
import userBio from 'containers/ProfileBoard/ProfileBio/sagas';
import accountSettings from 'containers/AccountSettings/sagas';
import eula from 'components/EulaAgreement/sagas';
import tcAndpp from 'components/TCAndPPAgreement/sagas';
import userInfo from 'containers/UserInfo/sagas';
import vote from 'containers/VotePanel/sagas';
import CookiesConsent from 'containers/CookiesConsent/sagas';
import unsubscribeSaga from 'containers/UnsubscribePage/sagas';
import livingValuesSaga from 'containers/LivingValues/sagas';
import WidgetBeThereSaga from 'containers/WidgetBeThere/sagas';
import DNAWidgetSaga from 'components/DNAWidget/sagas';
import highlightedStarSaga from 'containers/HighlightedStar/sagas';
import interestsSaga from 'components/Interests/sagas';
import topRankSaga from 'components/TopStarWidget/sagas';
import interactionTimelineSaga from 'containers/InteractionChart/sagas';
import interactionsSaga from 'components/InteractionWidget/sagas';
import cookiesPageSaga from 'containers/CookiesPolicyPage/sagas';
import connectionsSaga from 'components/ConnectionGraph/sagas';
import mutualConnectionSafa from 'components/MutualConnections/sagas';
import osFeed from 'containers/OSFeed/sagas';
import osFilters from 'components/OS/Filters/sagas';
import unifiedProfile from 'smu-unified-profile/sagas';
import userConfigs from 'services/userConfigs/sagas';
import sendStarProfile from 'services/sendStarProfile/sagas';
import questionnaires from 'components/Questionnaires/sagas';
import communityConfigs from 'services/communityConfigs/sagas';
import advancedUserSearch from 'services/advancedUserSearch/sagas';
import advancedUserFilters from 'services/advancedUserFilters/sagas';
import categoryValues from 'services/categoryValues/sagas';
import starsSummary from 'services/starsSummary/sagas';
import communityMember from 'services/communityMember/sagas';
import communityMemberMetrics from 'services/communityMemberMetrics/sagas';
import communityMembers from 'services/communityMembers/sagas';
import organizationValues from 'services/organizationValues/sagas';
import uploadShareImage from 'services/uploadShareImage/sagas';
import appLoader from 'services/appLoader/sagas';
import usersByKudosStarId from 'services/usersByKudosStarId/sagas';
import bethereProperties from 'services/bethereProperties/sagas';
import bethereInteractions from 'services/bethereInteractions/sagas';
import interactionsPosts from 'services/interactionsPosts/sagas';

// temporal name
// it's commented because we are working with a different version in react-redux
// import osFeed2 from 'components/OS/Feed/sagas';

import socialMediaSaga from './../routes/Activity/sagas';

export default function* rootSaga() {
  yield all([
    ...Object.values(topStarsSaga),
    ...Object.values(intlSagas),
    ...Object.values(feed),
    ...Object.values(stars),
    ...Object.values(filters),
    ...Object.values(filtersV2),
    ...Object.values(feedProfile),
    ...Object.values(culturalCategory),
    ...Object.values(culturalIndicator),
    ...Object.values(badges),
    ...Object.values(leaderboard),
    ...Object.values(leaderboardV2),
    ...Object.values(authSaga),
    suggestionsSaga,
    partial(searchSaga, searchActions),
    partial(searchSaga, addNamespace(searchActions, 'star_flow')),
    ...Object.values(profileSaga),
    trendsSaga,
    ...Object.values(notificationsSaga),
    ...Object.values(starFlowSaga),
    ...Object.values(advancedSearchSaga),
    ...Object.values(communityCountersSaga),
    ...Object.values(socialMediaSaga),
    ...Object.values(SuiteSwitcher),
    moderateComment,
    ...Object.values(notificationsHistory),
    tellExperience,
    ...Object.values(userBio),
    toastNotificationsSaga,
    ...Object.values(accountSettings),
    ...Object.values(eula),
    tcAndpp,
    ...Object.values(userInfo),
    ...Object.values(vote),
    CookiesConsent,
    ...Object.values(unsubscribeSaga),
    ...Object.values(livingValuesSaga),
    ...Object.values(WidgetBeThereSaga),
    ...Object.values(DNAWidgetSaga),
    ...Object.values(highlightedStarSaga),
    ...Object.values(interestsSaga),
    ...Object.values(topRankSaga),
    ...Object.values(interactionTimelineSaga),
    ...Object.values(interactionsSaga),
    ...Object.values(cookiesPageSaga),
    ...Object.values(connectionsSaga),
    ...Object.values(mutualConnectionSafa),
    ...Object.values(osFeed),
    ...Object.values(osFilters),
    ...Object.values(unifiedProfile),
    ...Object.values(userConfigs),
    ...Object.values(sendStarProfile),
    ...Object.values(questionnaires),
    // ...Object.values(osFeed2),
    ...Object.values(communityConfigs),
    ...Object.values(advancedUserSearch),
    ...Object.values(advancedUserFilters),
    ...Object.values(categoryValues),
    ...Object.values(starsSummary),
    ...Object.values(communityMember),
    ...Object.values(communityMemberMetrics),
    ...Object.values(communityMembers),
    ...Object.values(organizationValues),
    ...Object.values(uploadShareImage),
    ...Object.values(appLoader),
    ...Object.values(usersByKudosStarId),
    ...Object.values(bethereProperties),
    ...Object.values(bethereInteractions),
    ...Object.values(interactionsPosts),
  ].map(fork));
}
