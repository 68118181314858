// @own
import messages from './messages';

export const SORT_OPTIONS = {
  ALL: {
    icon: 'all-feed-sorting',
    modalHeaderText: messages.sortOptions.ALL.modalHeader,
    modalToggleText: messages.sortOptions.ALL.modalToggle,
    popupContentText: messages.sortOptions.ALL.popupContent,
  },
  SMART: {
    icon: 'smart-light',
    modalHeaderText: messages.sortOptions.SMART.modalHeader,
    modalToggleText: messages.sortOptions.SMART.modalToggle,
    popupContentText: messages.sortOptions.SMART.popupContent,
  },
};
