// @packages
import ModalCulturalLevels from 'smu-app-components/CulturalLevels/Explanation/Modal';
import ModalNewLevel from 'smu-app-components/CulturalLevels/NewLevel/Modal';

// @app
import BadgesCongratulations from 'containers/Modals/BadgesCongratulations';
import ModalBeHealthyEnroll from 'containers/Modals/BeHealthyEnroll';
import ModalCongratulationContainer from 'containers/Modals/Congratulation';
import ModalOneOnOneFormContainer from 'containers/Modals/OneOnOneForm';
import ModalOneOnOneRequestContainer from 'containers/Modals/OneOnOneRequest';
import ModalPostDetailsContainer from 'containers/Modals/PostDetails';
import ModalShareBadge from 'containers/Modals/ShareBadge';
import ModalShareContainer from 'containers/Modals/Share';
import ModalShareStar from 'containers/ModalShareStar';
import ModalStarDetailsContainer from 'containers/Modals/StarDetails';
import ModalYIRContainer from 'containers/Modals/YIR';
import SendStar from 'containers/NewSendStar';
import UploadMediaModal from 'containers/UploadMediaModal';

// @own
import YIR from './modals/YIR';
import badgesCongratulations from './modals/badgesCongratulations';
import beHealthyEnroll from './modals/beHealthyEnroll';
import congratulation from './modals/congratulation';
import culturalLevels from './modals/culturalLevels';
import insightDetails from './modals/insightDetails';
import livingValues from './modals/livingValues';
import newLevel from './modals/newLevel';
import onboarding from './modals/onboarding';
import oneOnOneCreate from './modals/oneOnOneCreate';
import oneOnOneRequest from './modals/oneOnOneRequest';
import postDetails from './modals/postDetails';
import sendStar from './modals/sendStar';
import share from './modals/share';
import shareBadge from './modals/shareBadge';
import shareDNA from './modals/shareDNA';
import shareStar from './modals/shareStar';
import starDetails from './modals/starDetails';
import uploadMedia from './modals/uploadMedia';

export const FULLSCREEN_MODAL_POST = 'FULLSCREEN_MODAL_POST';
export const MODAL_ACTIVATE_ENROLL = 'activate';
export const MODAL_BADGES_CONGRATULATIONS = 'badge';
export const MODAL_BEHEALTHY_ENROLL = 'behealthy';
export const MODAL_CONGRATULATION = 'congratulation';
export const MODAL_CULTURAL_LEVELS = 'cultural-levels';
export const MODAL_GIVE_STAR = 'givestar';
export const MODAL_INSIGHT_DETAILS = 'insight-details';
export const MODAL_LIVING_VALUES = 'initiatives';
export const MODAL_NEW_LEVEL = 'new-level';
export const MODAL_ONBOARDING = 'onboarding';
export const MODAL_ONE_ON_ONE_CREATE = 'create-1on1';
export const MODAL_ONE_ON_ONE_REQUEST = 'request-1on1';
export const MODAL_POST_DETAILS = 'post';
export const MODAL_SEND_STAR = 'send-star';
export const MODAL_SHARE = 'share';
export const MODAL_SHARE_BADGE = 'share-badge';
export const MODAL_SHARE_DNA = 'share-dna';
export const MODAL_SHARE_STAR = 'share-star';
export const MODAL_STAR_DETAILS = 'star';
export const MODAL_SURVEYS = 'surveys';
export const MODAL_UPLOAD_MEDIA = 'upload-post';
export const MODAL_YEAR_IN_REVIEW = 'year-in-review';

export const MODAL_HANDLERS = {
  [MODAL_ACTIVATE_ENROLL]: beHealthyEnroll,
  [MODAL_BADGES_CONGRATULATIONS]: badgesCongratulations,
  [MODAL_BADGES_CONGRATULATIONS]: badgesCongratulations,
  [MODAL_BEHEALTHY_ENROLL]: beHealthyEnroll,
  [MODAL_CONGRATULATION]: congratulation,
  [MODAL_CULTURAL_LEVELS]: culturalLevels,
  [MODAL_GIVE_STAR]: sendStar,
  [MODAL_INSIGHT_DETAILS]: insightDetails,
  [MODAL_LIVING_VALUES]: livingValues,
  [MODAL_NEW_LEVEL]: newLevel,
  [MODAL_ONBOARDING]: onboarding,
  [MODAL_ONE_ON_ONE_CREATE]: oneOnOneCreate,
  [MODAL_ONE_ON_ONE_REQUEST]: oneOnOneRequest,
  [MODAL_POST_DETAILS]: postDetails,
  [MODAL_SEND_STAR]: sendStar,
  [MODAL_SHARE]: share,
  [MODAL_SHARE_BADGE]: shareBadge,
  [MODAL_SHARE_DNA]: shareDNA,
  [MODAL_SHARE_STAR]: shareStar,
  [MODAL_STAR_DETAILS]: starDetails,
  [MODAL_UPLOAD_MEDIA]: uploadMedia,
  [MODAL_YEAR_IN_REVIEW]: YIR,
};

export const MODAL_COMPONENTS = {
  [MODAL_ACTIVATE_ENROLL]: ModalBeHealthyEnroll,
  [MODAL_BADGES_CONGRATULATIONS]: BadgesCongratulations,
  [MODAL_BEHEALTHY_ENROLL]: ModalBeHealthyEnroll,
  [MODAL_CONGRATULATION]: ModalCongratulationContainer,
  [MODAL_CULTURAL_LEVELS]: ModalCulturalLevels,
  [MODAL_GIVE_STAR]: SendStar,
  [MODAL_NEW_LEVEL]: ModalNewLevel,
  [MODAL_ONE_ON_ONE_CREATE]: ModalOneOnOneFormContainer,
  [MODAL_ONE_ON_ONE_REQUEST]: ModalOneOnOneRequestContainer,
  [MODAL_POST_DETAILS]: ModalPostDetailsContainer,
  [MODAL_SEND_STAR]: SendStar,
  [MODAL_SHARE]: ModalShareContainer,
  [MODAL_SHARE_BADGE]: ModalShareBadge,
  [MODAL_SHARE_STAR]: ModalShareStar,
  [MODAL_STAR_DETAILS]: ModalStarDetailsContainer,
  [MODAL_UPLOAD_MEDIA]: UploadMediaModal,
  [MODAL_YEAR_IN_REVIEW]: ModalYIRContainer,
};
