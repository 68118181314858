// @packages
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import merge from 'lodash/merge';
import keys from 'lodash/keys';
import moment from 'moment';
import useLocalStorage from 'smu-custom-hooks/useLocalStorage';

// @app
import InfiniteScroll from 'containers/InfiniteScroll';

// @own
import User from '../User';
import './styles.scss';

const CongratulationsUsers = ({
  className,
  messagePlaceholder,
  messageViewCard,
  messageYouCongratulated,
  onClickUser,
  onComment,
  onViewCard,
  pageSize,
  referredYear,
  showInteractions,
  sourceType,
  users,
}) => {
  const [commentedUsers, setCommentedUsers] = useLocalStorage(
    'congratulations-commented',
  );
  const [loadedUsers, setLoadedUsers] = useState([]);
  const today = useMemo(() => moment().startOf('day').valueOf(), []);
  const prevFiveDays = useMemo(
    () => moment().startOf('day').subtract(5, 'days').valueOf(),
    [],
  );

  const handleNext = () => {
    setLoadedUsers((prevUsers) => {
      const nextUsers = users?.slice(
        prevUsers?.length,
        prevUsers?.length + pageSize,
      );
      return [...prevUsers, ...nextUsers];
    });
  };

  const handleCommentedSuccess = (user) => {
    setCommentedUsers(
      merge({}, commentedUsers, {
        [user?.uid]: {
          date: today,
          [sourceType]: true,
        },
      }),
    );
  };

  useEffect(() => {
    setLoadedUsers(users?.slice(0, pageSize));
  }, [sourceType]);

  useEffect(() => {
    if (commentedUsers) {
      const users = keys(commentedUsers);

      // Remove users after 5 days
      if (users?.length) {
        const filteredUsers = users.reduce((acc, uid) => {
          const user = commentedUsers?.[uid];
          const isValid = user?.date >= prevFiveDays;
          if (isValid) acc[uid] = user;
          return acc;
        }, {});

        setCommentedUsers(filteredUsers);
      }
    }
  }, []);

  return (
    <div className={cn('smu-congratulations-users', className)}>
      <InfiniteScroll
        dataLength={loadedUsers?.length}
        hasMore={loadedUsers?.length < users?.length}
        next={handleNext}
        scrollableTarget={{
          current: document.getElementById('app'),
        }}
      >
        {loadedUsers?.map((user) => (
          <User
            className="smu-congratulations-users__user"
            identification={user.uid}
            isCommented={commentedUsers?.[user.uid]?.[sourceType]}
            key={`${sourceType}_${user.uid}`}
            messagePlaceholder={messagePlaceholder}
            messageViewCard={messageViewCard}
            messageYouCongratulated={messageYouCongratulated}
            noHash
            onClickUser={onClickUser}
            onComment={onComment}
            onCommentedSuccess={() => handleCommentedSuccess(user)}
            onViewCard={onViewCard}
            referredYear={referredYear}
            showInteractions={showInteractions}
            sourceType={sourceType}
            user={user}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

CongratulationsUsers.propTypes = {
  className: PropTypes.string,
  messagePlaceholder: PropTypes.string,
  messageViewCard: PropTypes.string,
  messageYouCongratulated: PropTypes.string,
  onClickUser: PropTypes.func,
  onViewCard: PropTypes.func,
  pageSize: PropTypes.number,
  referredYear: PropTypes.string,
  showInteractions: PropTypes.bool,
  sourceType: PropTypes.string,
  type: PropTypes.string,
  users: PropTypes.array,
};

CongratulationsUsers.defaultProps = {
  users: [],
  pageSize: 20,
};

export default CongratulationsUsers;
