// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import Icon from 'smu-ui-components/IconV2';
import Image from 'smu-ui-components/Image';
import Lottie from 'smu-ui-components/Lottie';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import { buildImageUrl } from 'smu-utils/image';
import { injectIntl } from 'react-intl';
import { useGroupsById } from 'smu-services/os-member/api/v1/community/groups/[id]';
import { withRouter } from 'react-router';

// @app
import Modal from 'components/Modal';

// @own
import messages from './messages';

const BadgesCongratulations = ({ id, intl: { formatMessage }, onClose, open, router }) => {
  const { error, isLoading, isValidating, mutate, selectGroup } = useGroupsById({ id });
  const badge = selectGroup();

  const lottieProps = {
    className: 'h-40 w-40',
    delayToStart: 1000,
    loop: true,
    path: '/json/lottiefiles/celebration.json',
  };

  const texts = {
    button: formatMessage(messages.button),
    description: formatMessage(messages.description),
    title: formatMessage(messages.title),
  };

  const handleButtonClick = () => {
    router.push('/me?modal=badges-profile');
  };

  return (
    <Modal open={open}>
      <div className="relative">
        <Icon
          className="absolute right-3 top-3 z-10 cursor-pointer text-blue"
          icon="linear-close"
          onClick={onClose}
          size="large"
        />
        <Paper className="w-128 min-h-48 h-full flex items-center justify-center">
          {(isLoading || isValidating) && <LottieSpinner loadingType="starmeup" />}
          {(error && !isValidating) && (
            <GenericErrorState action={{ onClick: mutate }} image="clock" layout="horizontal" />
          )}
          {!isLoading && !isValidating && !error && (
            <div className="w-full px-10 py-6 flex items-center space-x-10">
              <div className="relative w-28 h-28 flex-none">
                <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 z-0">
                  <Lottie {...lottieProps} />
                </div>
                <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 z-0 rotate-180">
                  <Lottie {...lottieProps} />
                </div>
                <Image
                  className="z-10 relative"
                  height={112}
                  legacy={false}
                  src={buildImageUrl({ code: badge.imageCode, height: 112, width: 112 })}
                  width={112}
                />
              </div>
              <div className="space-y-6">
                <div className="flex space-y-2 flex-col">
                  <span className="font-rubik font-medium text-blue text-lg">{texts.title}</span>
                  <span className="font-open text-base">
                    <span className="text-shark">{texts.description}</span>
                    {' '}
                    <span className="font-semibold text-blue">{badge.name}</span>
                  </span>
                </div>
                <Button
                  color="unifyBlue"
                  enabledUnification
                  onClick={handleButtonClick}
                  size="medium"
                >
                  {texts.button}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </Modal>
  );
};

BadgesCongratulations.propTypes = {
  id: PropTypes.string,
  intl: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  router: PropTypes.object,
};

export default withRouter(injectIntl(BadgesCongratulations));
