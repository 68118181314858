// @packages
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatMessages } from 'smu-utils/intl';
import { injectIntl } from 'react-intl';

// @app
import { StarContext } from 'containers/OSFeed/OSFeedItem';

// @own
import Group from './Group';
import Single from './Single';
import messages from './messages';

const Badge = ({ intl: { formatMessage } }) => {
  const { data, ownId } = useContext(StarContext);
  const badge = data?.badge;
  const cardId = data?.id;
  const isGroup = data?.users?.length > 1;
  const totalUsers = data?.totalUsers;
  const users = isGroup ? data?.users : data?.users?.[0];

  const texts = useMemo(
    () => formatMessages({
      formatMessage,
      messages: isGroup ? messages.group : messages.single,
      values: { xxx: data?.users?.[0]?.firstName },
    }),
    [formatMessage, isGroup, messages],
  );

  return (
    isGroup ? (
      <Group
        badge={badge}
        cardId={cardId}
        ownId={ownId}
        texts={texts}
        totalUsers={totalUsers}
        users={users}
      />
    ) : (
      <Single
        badge={badge}
        ownId={ownId}
        texts={texts}
        user={users}
      />
    )
  );
};

Badge.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(Badge);
