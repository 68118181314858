// @packages
import { defineMessages } from 'react-intl';

export default defineMessages({
  privacyDisclaimer: {
    id: 'contentTypeBeHealthyInsight.privacyDisclaimer',
    defaultMessage: 'No one will see this in the feed unless you share it',
  },
  button: {
    id: 'contentTypeBeHealthyInsight.button',
    defaultMessage: 'Share as Moment',
  },
  shareAsMomentComment: {
    id: 'contentTypeBeHealthyInsight.shareAsMomentComment',
    defaultMessage: 'I have achieved a new milestone in #Activate! I continue on a path of constant work for better physical well-being.',
  },
});
