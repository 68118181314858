import { apiCollaborationInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { trackEvent } from 'utils/gtm';

export const apiPostAddCalendar = (data) =>
  apiCollaborationInstance({
    data,
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: '/v1/segments/members/me',
  })
    .then(({ data }) => data)
    .catch((err) => {
      trackEvent({
        action: 'discover',
        category: 'Error',
        label: err?.response?.data?.message,
      });
      errorHandler(err);
    });
