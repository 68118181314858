// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/Icon';

// @app
import chillCup from 'assets/icons/chill-cup.svg';

// @own
import './styles.scss';

function Header({ onClickShowPreferences }) {
  return (
    <div className="chill-out-events__header">
      <img className="chill-out-events__header-img" src={chillCup} alt="Chill Out" />
      <h2 className="chill-out-events__header-title">UPCOMING EVENTS!</h2>
      <Icon
        className="chill-out-events__header-icon"
        icon="setting"
        color="white"
        onClick={onClickShowPreferences}
      />
    </div>
  );
}

Header.defaultProps = {
  onClickShowPreferences: () => {},
};

Header.propTypes = {
  onClickShowPreferences: PropTypes.func,
};

export default Header;
