// @packages
import React from 'react';
import PropTypes from 'prop-types';
import withRootModal from 'smu-app-components/RootModalV2/withRootModal';
import { connect } from 'react-redux';
import { detectWebView } from 'smu-utils/device';
import { injectIntl } from 'react-intl';
import { selectResultProp } from 'smu-utils/reduxRequests/selectors';

// @app
import SurveyAnswerDetail from 'components/SurveyAnswerDetail';
import SurveyAnswerUsersModal from 'containers/SurveyAnswerUsersModal';
import { QUESTIONNAIRE_RESULTS } from 'containers/QuestionnaireResults/constants';
import { SMU_GENERIC_MODAL } from 'containers/GenericModal/constants';
import { TYPE_IMAGE } from 'components/QuestionnaireResults/constants';
import { actions as routeActions } from 'utils/routes';
import { trackEvent } from 'utils/gtm';

// @own
import messages from './messages';
import { MAX_DISPLAYED_USERS } from './constants';
function SurveyAnswerDetailContainer({
  answer,
  className,
  closeModal,
  chartNumber,
  intl: { formatMessage },
  isOpen,
  onClose,
  openModal,
  option,
  optionId,
  questionId,
  questionText,
  questionnaireId,
  showProfile,
  totalUsers,
  type,
  users = [],
}) {
  const texts = {
    answer: formatMessage(messages.surveyAnswerDetailAnswerTitle),
    option: formatMessage(messages.surveyAnswerDetailOptionTitle),
    users: formatMessage(messages.surveyAnswerDetailUsersTitle),
  };

  const hiddenUsersQuantity = users.length > MAX_DISPLAYED_USERS ? totalUsers - users.length : 0;
  const isImage = type === TYPE_IMAGE;

  const onAvatarClick = ({ uid }) => {
    closeModal();
    const isWebView = detectWebView();
    if (isWebView) {
      window.location = `/profile/${uid}`;
    } else {
      showProfile(uid);
    }
  };

  const onHiddenClick = () => {
    trackEvent({
      action: 'results_chart_all_answers',
      category: 'questionnaires',
      label: `${chartNumber}. ${type}`,
      q_id: questionnaireId,
      q_name: questionText,
    });
    openModal({
      modalType: SMU_GENERIC_MODAL,
      modalProps: {
        className: 'survey-answer-detail__modal',
        optionId,
        questionId,
        questionnaireId,
        showFooter: false,
        renderModal: (props) => (
          <SurveyAnswerUsersModal
            {...props}
            onAvatarClick={onAvatarClick}
          />
        ),
        title: isImage
          ? (<img alt="option" className="survey-answer-detail__image" src={option} />)
          : option,
      },
    });
  };

  return isOpen ? (
    <SurveyAnswerDetail
      answer={answer}
      className={className}
      hiddenUsersQuantity={hiddenUsersQuantity}
      isImage={isImage}
      isOpen={isOpen}
      maxDisplay={MAX_DISPLAYED_USERS}
      onAvatarClick={onAvatarClick}
      onClose={onClose}
      onHiddenClick={onHiddenClick}
      option={option}
      texts={texts}
      users={users}
    />
  ) : null;
}

SurveyAnswerDetailContainer.propTypes = {
  answer: PropTypes.number,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  chartNumber: PropTypes.string,
  intl: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  openModal: PropTypes.func,
  optionId: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  questionText: PropTypes.string,
  questionnaireId: PropTypes.number.isRequired,
  showProfile: PropTypes.func.isRequired,
  totalUsers: PropTypes.number,
  type: PropTypes.string,
  users: PropTypes.array,
};

const mapStateToProps = state => ({
  questionnaireId: selectResultProp(state, QUESTIONNAIRE_RESULTS, 'result.id', undefined),
});


const withModal = withRootModal(injectIntl(SurveyAnswerDetailContainer));

export default connect(mapStateToProps, {
  showProfile: profileId => routeActions.profile({ profileId }),
})(withModal);
