// @packages
import React, { useState } from 'react';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import cn from 'classnames';
import trim from 'lodash/trim';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

// @app
import Input from 'components/Input';
import { selectHasMentionsEnabled } from 'containers/Authorization/selectors';

// @own
import messages from './messages';

const HeaderComments = ({
  bethereInteractions,
  className,
  intl: { formatMessage },
  isLoading,
  maxCharacters,
  mediaUploadEnabled,
  mentionsEnabled,
  onAddComment,
  onUploadImage,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputMentions, setInputMentions] = useState([]);
  const mentionsEnabledConfig = useSelector(selectHasMentionsEnabled);

  function handleInputChange({ comment, mentions }) {
    setInputValue(comment);
    setInputMentions(mentions);
  }

  function handleAddComment() {
    const comment = trim(inputValue);
    if (onAddComment && comment && !isLoading) {
      onAddComment(comment, inputMentions);
      setInputValue('');
      setInputMentions([]);
    }
  }

  return (
    <div className={cn('relative flex items-center w-full', className)}>
      {isLoading ? (
        <LottieSpinner
          className="m-auto"
          loadingType="starmeup"
          height={40}
          width={40}
        />
      ) : (
        <Input
          bethereInteractions={bethereInteractions}
          handleIconClick={handleAddComment}
          maxCharacters={maxCharacters}
          mediaUploadEnabled={mediaUploadEnabled}
          mentionsEnabled={mentionsEnabled && mentionsEnabledConfig}
          onInputChange={handleInputChange}
          onUploadImage={onUploadImage}
          placeholder={formatMessage(messages.addComment)}
        />
      )}
    </div>
  );
};

HeaderComments.propTypes = {
  bethereInteractions: PropTypes.bool,
  isLoading: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  maxCharacters: PropTypes.number,
  mediaUploadEnabled: PropTypes.bool,
  mentionsEnabled: PropTypes.bool,
  onAddComment: PropTypes.func,
  onInputChange: PropTypes.func,
  onUploadImage: PropTypes.func,
};

HeaderComments.defaultProps = {
  bethereInteractions: false,
  maxCharacters: 400,
  mediaUploadEnabled: false,
  mentionsEnabled: true,
};

export default injectIntl(HeaderComments);
