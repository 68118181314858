import { buildClosePath } from '../helpers';

const uploadMedia = ({ hookModal, onClose }) => {
  const closePath = buildClosePath(['comment', 'id', 'type']);

  hookModal.open({
    onClose: () => onClose(closePath),
  });
};

export default uploadMedia;
