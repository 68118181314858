// @packages
import React, { useEffect } from 'react';
import Container from 'smu-layout-components/Container';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { trackPageview, trackEvent } from 'smu-utils/gtm';

// @app
import Congratulations from 'containers/Congratulations';
import { getStartOfTimestamp } from 'utils/date';
import { getCommunityConfigValueBool as getConfig } from 'services/communityConfigs/selectors';
import {
  ENABLED_ANNIVERSARIES,
  ENABLED_BIRTHDAYS,
} from 'services/communityConfigs/constants';

// @own
import { SECTIONS } from './constants';

function CongratulationsLayout({
  enabledAnniversaries,
  enabledBirthdays,
  firstUserUID,
  router,
  timestamp,
  section,
}) {
  const handleChangeDate = (date) => {
    const timestamp = getStartOfTimestamp(date);
    const querySection = section ? `&section=${section}` : '';

    trackEvent({
      action: 'click',
      category: 'congrats_landing',
      label: 'filter_by_date',
      type: SECTIONS[section],
    });

    router.push(`/congratulations?date=${timestamp}${querySection}`);
  };

  useEffect(() => {
    trackPageview({
      pageName: '/congratulations',
    });

    if (!enabledBirthdays && !enabledAnniversaries) {
      router.replace('/home');
    }
  }, []);

  return (
    <div className="pt-16 flex flex-col min-h-screen">
      <Container centered compact>
        <div className="py-5">
          <Congratulations
            date={timestamp}
            enabledAnniversaries={enabledAnniversaries}
            enabledBirthdays={enabledBirthdays}
            firstUserUID={firstUserUID}
            onChangeDate={handleChangeDate}
            section={section}
          />
        </div>
      </Container>
    </div>
  );
}

CongratulationsLayout.propTypes = {
  enabledAnniversaries: PropTypes.bool,
  enabledBirthdays: PropTypes.bool,
  firstUserUID: PropTypes.string,
  section: PropTypes.string,
  timestamp: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  const date = ownProps?.location?.query?.date;
  const firstUserUID = ownProps?.location?.query?.firstUserUID;
  const timestamp = date ? Number(date) : getStartOfTimestamp();
  const section = ownProps?.location?.query?.section;

  return {
    enabledAnniversaries: getConfig(state, ENABLED_ANNIVERSARIES),
    enabledBirthdays: getConfig(state, ENABLED_BIRTHDAYS),
    firstUserUID,
    section,
    timestamp,
  };
};

export default connect(mapStateToProps)(withRouter(CongratulationsLayout));
