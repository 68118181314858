// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

function EventHeader({ title, subtitle }) {
  return (
    <header className="event-header">
      <h4 className="event-header__title">
        {title}
      </h4>
      <h4 className="event-header__subtitle">
        {subtitle}
      </h4>
    </header>
  );
}

EventHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default EventHeader;

