import { getItem } from 'smu-utils/storage';

export const apiUrl = (() => {
  let apiUrlTemp = getItem('apiUrl');
  if (!(apiUrlTemp && apiUrlTemp.length)) {
    const env = window.location.host.split('.')[0];
    const url = {
      development: 'https://qa.starmeup.com/',
      production: `https://${env}.starmeup.com/`,
    };
    apiUrlTemp = `${url[process.env.NODE_ENV]}starmeup-api`;
  }
  return `${apiUrlTemp.replace(/\/$/, '')}/v2`;
})();

export const baseUrl = (() => {
  let baseUrl = getItem('baseUrl');
  if (!(baseUrl && baseUrl.length)) {
    const env = window.location.host.split('.')[0];
    const url = {
      development: 'https://qa.starmeup.com/',
      production: `https://${env}.starmeup.com/`,
    };
    baseUrl = url[process.env.NODE_ENV];
  }
  return baseUrl;
})();
