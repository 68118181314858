// @packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';

// @own
import './styles.scss';

const CardPhoto = ({
  aspectRatio,
  className,
  imageSrc,
  maxAspectRatio,
  onClick,
  onLoadThumb,
  style,
  thumbnailBlurUrl,
  title,
  ...rest
}) => {
  const { ref, inView } = useInView({
    rootMargin: '0px 0px 100px 0px',
    threshold: 0,
    triggerOnce: true,
  });

  const styles = useMemo(() => {
    const styles = { ...style };
    if (aspectRatio) {
      styles.paddingTop = `${
        aspectRatio > maxAspectRatio ? maxAspectRatio : aspectRatio
      }%`;
    }
    return styles;
  }, [style, aspectRatio]);

  return (
    <div
      className={cn('smu-card-photo', className, {
        'smu-card-photo--pointer': onClick,
      })}
      onClick={onClick}
      ref={ref}
      style={styles}
      {...rest}
    >
      {inView && (
        <>
          {thumbnailBlurUrl && (
            <img
              alt="thumbnailBlurUrl"
              className="smu-card-photo__blur"
              onLoad={onLoadThumb}
              src={thumbnailBlurUrl}
            />
          )}
          <img alt={title} className="smu-card-photo__img" src={imageSrc} />
        </>
      )}
    </div>
  );
};

CardPhoto.defaultProps = {
  aspectRatio: 0,
  imageSrc: 'assets/notAvailable.png',
  maxAspectRatio: 0,
};

CardPhoto.propTypes = {
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  maxAspectRatio: PropTypes.number,
  onClick: PropTypes.func,
  onLoadThumb: PropTypes.func,
  style: PropTypes.object,
  thumbnailBlurUrl: PropTypes.string,
  title: PropTypes.string,
};

export default CardPhoto;
