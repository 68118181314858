import { defineMessages } from 'react-intl';

export default defineMessages({
  noResults: {
    id: 'feed.noResults',
    defaultMessage: 'No available results',
  },
  badges: {
    id: 'profile.badges',
    defaultMessage: 'BADGES',
  },
  received: {
    id: 'profile.received',
    defaultMessage: 'RECEIVED',
  },
  sent: {
    id: 'profile.sent',
    defaultMessage: 'SENT',
  },
  twitterBadgeDescription: {
    id: 'profile.twitterBadgeDescription',
    defaultMessage: 'I’d unlocked a {name} badge in #Starmeup #{hashtag}',
  },

  facebookBadgeDescription: {
    id: 'profile.facebookBadgeDescription',
    defaultMessage: 'StarmeUp helps organizations grow a healthy culture leveraging peer-to-peer recognition, reinforcing organizational values, bringing transparency and meritocracy to everyday´s actions.',
  },
  facebookBadgeTitle: {
    id: 'profile.facebookBadgeTitle',
    defaultMessage: 'Discover those that make your organization unique',
  },
  feedProfileEmptyStateButton: {
    id: 'feedProfile.EmptyStateButton',
    defaultMessage: 'Send Star',
  },
  feedProfileEmptyStateReceivedText: {
    id: 'feedProfile.EmptyStateReceivedText',
    defaultMessage: 'THE FIRST STAR IS ON IT´S WAY. WE CAN FEEL IT!',
  },
  feedProfileEmptyStateReceivedTextOther: {
    id: 'feedProfile.EmptyStateReceivedTextOther',
    defaultMessage: 'THE FIRST STAR IS ON IT´S WAY. WE CAN FEEL IT!',
  },
  feedProfileEmptyStateReceivedSubtextOther: {
    id: 'feedProfile.EmptyStateReceivedSubtextOther',
    defaultMessage: "Be the first to brighten your colleague's day and send a star",
  },
  feedProfileEmptyStateSentText: {
    id: 'feedProfile.EmptyStateSentText',
    defaultMessage: "YOU HAVEN'T SENT ANY STARS YET",
  },
  feedProfileEmptyStateSentSubtext: {
    id: 'feedProfile.EmptyStateSentSubtext',
    defaultMessage: 'Use this moment to brighten the day of a colleague and send your first star',
  },
  feedProfileEmptyStateSentTextOther: {
    id: 'feedProfile.EmptyStateSentTextOther',
    defaultMessage: '{firstName} HAS NOT SENT ANY STARS YET',
  },
});
