// @app
import { errorHandler } from 'utils/errorHandler';
import { apiInstance } from 'utils/requestHelper';

export function getOrganizationValues(params) {
  return apiInstance({
    url: '/v2/stellar/organizationvalues/1',
    data: {},
    params,
  })
    .then((data) => data)
    .catch(errorHandler);
}
