import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'smu-ui-components/UserAvatar';
import parseDate from 'utils/parseDate';
import cn from 'classnames';
import Icon from 'smu-ui-components/Icon';
import TextWithMentions from 'components/TextWithMentions';
import { connect } from 'react-redux';
import { FormattedRelative } from 'react-intl';
import Image from 'components/Image';
import { actions as routeActions } from 'utils/routes';
import withFullScreenModal from 'smu-app-components/FullScreenModalV2/withFullScreenModal';
import liftStarIcon from 'assets/icons/lifted-star.svg';

import { moderateComment } from './actions';

import './styles.scss';

const Message = ({
  author,
  canModerateMessages,
  creationTime,
  description,
  enabled,
  id,
  imageCode,
  listHashtags,
  listMentions,
  moderateComment,
  openFullscreenModal,
  position,
  showProfile,
  type,
  feedSetFields,
  location,
  goToHome,
  trackHashtag,
}) => {
  const mapType = {
    COMMENT: 'COMMENT',
    LIKE: 'LIKE',
    IMAGE: 'IMAGE',
    LIFT_STAR: 'LIFT_STAR',
  };

  const renderDescription = () => (
    <div
      className={cn('message-item__text', {
        'message-item__text--moderator-rights': canModerateMessages,
        'message-item__text--moderated': !enabled,
      })}
    >
      <TextWithMentions
        text={description}
        listMentions={listMentions}
        listHashtags={listHashtags}
        feedSetFields={feedSetFields}
        location={location}
        goToHome={goToHome}
        trackHashtag={trackHashtag}
      />
    </div>
  );

  const renderTimeStamp = () =>
    (mapType[type] !== 'LIKE' && mapType[type] !== 'LIFT_STAR') && (
      <small className="message-item__indicator message-item__indicator--right">
        <FormattedRelative value={parseDate(creationTime).toDate()} />
      </small>
    );

  const renderActionIndicator = () => {
    let actionIcon;
    if (mapType[type] === 'LIKE') {
      actionIcon = (
        <small className="message-item__indicator message-item__indicator--right">
          <Icon icon="like-full" color="red" />
        </small>
      );
    }
    if (mapType[type] === 'LIFT_STAR') {
      actionIcon = (
        <small className="message-item__indicator message-item__indicator--right">
          <img
            height={18}
            src={liftStarIcon}
            alt="lift star icon"
          />
        </small>
      );
    }
    return actionIcon;
  };

  const renderModerateText = () => (
    <div
      className="message-item__moderate-comments"
      onClick={() => moderateComment({ id, enabled })}
    >
      {enabled ? 'Hide comment' : 'Show comment'}
    </div>
  );

  const renderImage = () => (
    <div
      className="message-item__image-container"
      onClick={(e) => {
        e.stopPropagation();
        openFullscreenModal({
          modalType: 'FEED_ITEM_MAIN_PHOTO',
          modalProps: {
            code: imageCode,
          },
        });
      }}
    >
      <Image className="message-item__image" code={imageCode} width={150} height={150} />
    </div>
  );

  return enabled || canModerateMessages ? (
    <div className="message-item">
      <div
        className={cn('message-item__header', {
          'message-item__header--like': mapType[type] === 'LIKE',
        })}
      >
        <div className={`message-item__avatar--${position}`}>
          {author && (
            <div>
              <UserAvatar
                onClick={() => showProfile(author.uid)}
                profileImageCode={author.profileImageCode}
                width={28}
                height={28}
                className="message-item__author-avatar"
              />
              <span onClick={() => showProfile(author.uid)} className="message-item__author-name">
                {`${author.firstName} ${author.lastName}`}
              </span>
              {renderTimeStamp()}
              {renderActionIndicator()}
            </div>
          )}
        </div>
      </div>
      {mapType[type] === 'IMAGE' && renderImage()}
      {mapType[type] === 'COMMENT' && renderDescription()}
      {canModerateMessages && mapType[type] === 'COMMENT' && renderModerateText()}
    </div>
  ) : null;
};

Message.propTypes = {
  author: PropTypes.object,
  canModerateMessages: PropTypes.bool,
  creationTime: PropTypes.string,
  description: PropTypes.string,
  enabled: PropTypes.bool,
  id: PropTypes.number,
  imageCode: PropTypes.string,
  listHashtags: PropTypes.array,
  listMentions: PropTypes.array,
  moderateComment: PropTypes.func,
  openFullscreenModal: PropTypes.func.isRequired,
  position: PropTypes.string,
  showProfile: PropTypes.func,
  type: PropTypes.oneOf(['COMMENT', 'LIKE', 'IMAGE', 'LIFT_STAR']),
  feedSetFields: PropTypes.func,
  location: PropTypes.string,
  goToHome: PropTypes.func,
  trackHashtag: PropTypes.func,
};

const actions = {
  moderateComment,
  showProfile: profileId => routeActions.profile({ profileId }),
};

const mapStateToProps = ({ message }) => ({ message });

export default connect(mapStateToProps, actions)(withFullScreenModal(Message));
