import {
  CREATE_UNSUBSCRIBE_USER,
  CREATE_UNSUBSCRIBE_USER_FAIL,
  CREATE_UNSUBSCRIBE_USER_SUCCESS,
  GET_UNSUBSCRIBE_DATA,
  GET_UNSUBSCRIBE_DATA_FAIL,
  GET_UNSUBSCRIBE_DATA_SUCCESS,
} from './actionTypes';

export function createUnsubscribeUser({
  apiUrl,
  category,
  reasonCode,
  reasonText,
  token,
}) {
  return {
    type: CREATE_UNSUBSCRIBE_USER,
    payload: {
      apiUrl,
      category,
      reasonCode,
      reasonText,
      token,
    },
  };
}

export function createUnsubscribeUserSuccess() {
  return {
    type: CREATE_UNSUBSCRIBE_USER_SUCCESS,
  };
}

export function createUnsubscribeUserFail(error) {
  return {
    type: CREATE_UNSUBSCRIBE_USER_FAIL,
    error,
  };
}

export function getUnsubscribeData({ apiUrl, token }) {
  return {
    type: GET_UNSUBSCRIBE_DATA,
    payload: { apiUrl, token },
  };
}

export function getUnsubscribeDataSuccess(messages) {
  return {
    type: GET_UNSUBSCRIBE_DATA_SUCCESS,
    messages,
  };
}

export function getUnsubscribeDataFail(error) {
  return {
    type: GET_UNSUBSCRIBE_DATA_FAIL,
    error,
  };
}
