// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useModal from 'smu-custom-hooks/useModal';
import { detectWebView } from 'smu-utils/device';
import ModalYIRContainer from 'containers/Modals/YIR';

// @app
import YIR from 'containers/YIR';

const YearInReviewLayout = ({ router }) => {
  const isWebView = detectWebView();
  const modal = useModal();

  const handleClose = () => {
    router.push('/home');
  };

  useEffect(() => {
    if (!isWebView) {
      modal.open();
    }
  }, [isWebView]);

  return isWebView
    ? <YIR arrowNavigation={false} height="100vh" width="100vw" />
    : <ModalYIRContainer onClose={handleClose} open />;
};

YearInReviewLayout.propTypes = {
  router: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default YearInReviewLayout;
