// @packages
import { apiGetCommunityMember } from 'smu-services/os-member/api/v1/community/member/api';
import { apiGetCommunityMembersById } from 'smu-services/os-member/api/v2/community/members/[id]/api';
import { apiOrigin, apiVersion } from 'smu-utils/env';

// @app
import { defaultApi } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export async function apiGetInteractionById({ interactionId }) {
  const params = {
    fields: 'user,reactions',
  };

  return defaultApi({
    data: {},
    headers: { 'Content-Type': 'application/json' },
    params,
    url: `interactions/api/v1/community/members/activities/${interactionId}`,
  })
    .then(({ data }) => data?.result)
    .catch(errorHandler);
}

export function apiGetLastActivity({ userId, types, referredYear }) {
  return defaultApi({
    data: {},
    headers: { 'Content-Type': 'application/json' },
    params: {
      referredYear,
      types,
    },
    url: `interactions/api/v1/community/members/${userId}/activities`,
  })
    .then(({ data }) => {
      const result = data?.result || [];
      return result.find((r) => r.type === types);
    })
    .catch(errorHandler);
}

export async function apiGetByUserId({ isOwner, referredYear, types, userId }) {
  const activity = await apiGetLastActivity({
    referredYear,
    types,
    userId,
  });

  if (activity?.id) {
    const interaction = await apiGetInteractionById({
      interactionId: activity?.id,
    });

    return interaction;
  } else {
    const memberApiConfig = {
      origin: apiOrigin,
      userUID: !isOwner && userId,
      version: apiVersion,
    };

    const user = isOwner
      ? await apiGetCommunityMember(memberApiConfig)
      : await apiGetCommunityMembersById(memberApiConfig);

    return {
      user: user.result,
    };
  }
}

export function apiGetInteractionsById({
  interactionId,
  page = 0,
  size = 50,
  types,
}) {
  const params = {
    application: 'Interaction',
    fields: 'user',
    page,
    size,
    sort: 'id,DESC',
    types,
  };

  return defaultApi({
    data: {},
    headers: { 'Content-Type': 'application/json' },
    params,
    url: `interactions/api/v1/community/members/activities/${interactionId}/reactions`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiAddLike({ type, userId, activityId }) {
  const data = {
    application: 'Interaction',
    data: { toUserId: userId },
    sourceId: activityId,
    sourceType: type,
    type: 'LIKE',
  };

  return defaultApi({
    data,
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: 'interactions/api/v1/community/members/activities/reactions',
  }).catch(errorHandler);
}

export function apiUnlike({ activityId }) {
  const application = 'Interaction';

  return defaultApi({
    data: {},
    headers: { 'Content-Type': 'application/json' },
    method: 'delete',
    url: `interactions/api/v1/community/members/activities/${activityId}/reactions/LIKE?application=${application}`,
  }).catch(errorHandler);
}

export function apiAddComment({ comment, sourceId, sourceType, toUserId }) {
  const data = {
    application: 'Interaction',
    data: { comment, toUserId },
    sourceId,
    sourceType,
    type: 'COMMENT',
  };

  return defaultApi({
    data,
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: 'interactions/api/v1/community/members/activities/reactions',
  }).catch(errorHandler);
}
