// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';

// @app
import {
  BH_CARD_MILESTONE_1ST_DAY,
  BH_CARD_MILESTONE_1ST_MONTH,
  BH_CARD_MILESTONE_3_RINGS,
  TYPES,
} from 'containers/Share/types/BeHealthyInsight/constants';

// @own
import backgroundImage from './assets/background.svg';
import personJumpingImage from './assets/person-jumping.svg';
import personRunningImage from './assets/person-running.svg';
import personWalkingImage from './assets/person-walking.svg';

function ShareBeHealthyInsight({ className, iconImageUrl, text, type }) {
  const personImagePerType = {
    [BH_CARD_MILESTONE_1ST_DAY]: personJumpingImage,
    [BH_CARD_MILESTONE_1ST_MONTH]: personRunningImage,
    [BH_CARD_MILESTONE_3_RINGS]: personWalkingImage,
  };

  if (!TYPES.includes(type)) {
    return null;
  }

  return (
    <div
      className={cn('flex items-center justify-between bg-cover bg-no-repeat py-2', className)}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: '375px',
        width: '750px',
      }}
    >
      <div className="h-full w-3/10">
        <img
          alt="person"
          className="mx-auto"
          height={360}
          src={personImagePerType[type]}
          style={{ marginTop: '35%' }}
          width={225}
        />
      </div>
      <Typography
        className="w-2/5 font-rubik font-semibold text-3xl text-gray-dune text-center relative"
        style={{ top: '-14px' }}
      >
        {text}
      </Typography>
      <div className="w-3/10">
        <img alt="icon" className="h-28 w-28 mx-auto" src={iconImageUrl} />
      </div>
    </div>
  );
}

ShareBeHealthyInsight.propTypes = {
  className: PropTypes.string,
  iconImageUrl: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf([
    BH_CARD_MILESTONE_1ST_DAY,
    BH_CARD_MILESTONE_1ST_MONTH,
    BH_CARD_MILESTONE_3_RINGS,
  ]),
};

export default ShareBeHealthyInsight;
