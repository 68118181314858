import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function getTopRank() {
  return apiInstance({
    url: '/v2/stellar/interactions/toprank',
    method: 'get',
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}
