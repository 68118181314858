// @packages
import React, { forwardRef } from 'react';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';

// @app
import CoreValueButton from 'components/CoreValueButton';
import { isCoreValueDisabled } from 'containers/SendStar/helpers';
import { CONTROL_LEFT, CONTROL_RIGHT } from 'containers/CoreValuesPanel/constants';
// @own
import './styles.scss';

const CoreValuesPanel = forwardRef((
  {
    isScrollable,
    onClick,
    onControlClick,
    onDisabledClick,
    remainingStars,
    showLeftControl,
    showRightControl,
    stars,
  },
  ref,
) => {
  const buildCoreValue = (star) => {
    const { isKudos = false } = star;
    const disabled = isCoreValueDisabled(remainingStars, isKudos);
    return (
      <CoreValueButton
        disabled={disabled}
        handleClick={() => onClick(star)}
        handleDisabledClick={() => onDisabledClick(isKudos)}
        key={star.id}
        {...star}
      />
    );
  };

  return (
    <div
      className={cn('core-values-panel', {
        'core-values-panel--with-controls': isScrollable,
      })}
    >
      {showLeftControl && (
        <div className="core-values-panel__control core-values-panel__control--left" onClick={() => onControlClick(CONTROL_LEFT)} >
          <Icon size="medium" icon="arrow-left" />
        </div>
      )}
      <div className="core-values-panel__values" ref={ref}>
        {stars.map(buildCoreValue)}
      </div>
      {showRightControl && (
        <div className="core-values-panel__control core-values-panel__control--right" onClick={() => onControlClick(CONTROL_RIGHT)}>
          <Icon size="medium" icon="arrow-right" />
        </div>
      )}
    </div>
  );
});

export default CoreValuesPanel;
