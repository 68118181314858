// @packages
import React from 'react';
import AnniversaryCardTemplate from 'smu-ui-components/Cards/templates/Anniversary';
import BirthdayCardTemplate from 'smu-ui-components/Cards/templates/Birthday';
import Button from 'smu-ui-components/ButtonV2';
import CardDescription from 'smu-ui-components/Cards/components/Description';
import CardTitle from 'smu-ui-components/Cards/components/Title';
import CulturalLevelsLabel from 'smu-app-components/CulturalLevels/Label';
import DateTime from 'smu-ui-components/Cards/components/DateTime';
import PropTypes from 'prop-types';
import UserDescription from 'smu-ui-components/Cards/components/UserDescription';
import UserName from 'smu-ui-components/Cards/components/UserName';
import cn from 'classnames';
import { buildImageUrl } from 'smu-utils/image';

// @app
import ActionCommentContainer from 'containers/Cards/components/ActionComment';
import ActionLikeContainer from 'containers/Cards/components/ActionLike';
import AvatarLink from 'components/AvatarLink';
import CommentsContainer from 'containers/Comments';
import HeaderComments from 'components/Comments/Header';
import PostMenu from 'containers/PostMenu';
import SkeletonFeedItem from 'components/Skeletons/FeedItem';
import UserInfoPopup from 'containers/UserInfoPopup';
import withCongratulation from 'containers/withCongratulation';
import { INTERACTION_BIRTHDAY } from 'containers/OSFeed/constants';

// @own
import './styles.scss';
import { getCommentListProps } from './helpers';

function CongratulationEvent({
  comments,
  commentsCount,
  commentsToggle,
  detailUrl,
  isLoading,
  isLoadingComments,
  isLoadingLike,
  isOwner,
  liked,
  likesCount,
  onButtonClick,
  onComment,
  onCommentsToggle,
  onLike,
  onLikeText,
  referredYear,
  showButton,
  texts,
  type,
  user,
}) {
  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const profileImageCode = user?.profileImageCode;
  const profileUrl = user?.uid ? `/profile/${user?.uid}` : undefined;
  const culturalLevelNumber = user?.properties?.culturalLevelNumber;
  let commentsComponent;

  const handleActionLikeClickCount = () => {
    if (likesCount && onLikeText) {
      onLikeText();
    }
  };

  if (isLoading) {
    return <SkeletonFeedItem showComments />;
  }

  if (commentsToggle) {
    commentsComponent = (
      <CommentsContainer
        className="rounded-b-lg"
        header={
          <HeaderComments
            className={cn({
              'mb-4': comments?.length,
            })}
            onAddComment={onComment}
            isLoading={isLoadingComments}
          />
        }
        {...getCommentListProps({ comments })}
      />
    );
  }

  const Component =
    type === INTERACTION_BIRTHDAY
      ? BirthdayCardTemplate
      : AnniversaryCardTemplate;

  return (
    <>
      <Component
        actionComment={
          <ActionCommentContainer
            count={commentsCount}
            onClickIcon={onCommentsToggle}
          />
        }
        actionLike={
          <ActionLikeContainer
            active={liked}
            count={likesCount}
            isLoading={isLoadingLike}
            onClickCount={handleActionLikeClickCount}
            onClickIcon={onLike}
          />
        }
        button={showButton
          ? (
            <Button
              color="unifyBlue"
              enabledUnification
              onClick={onButtonClick}
              size="medium"
              variant="outline"
            >
              {texts.button}
            </Button>
          ) : undefined
        }
        className="shadow-base"
        comments={commentsComponent}
        description={<CardDescription>{texts.description}</CardDescription>}
        menu={
          <PostMenu
            className="congratulation-event__post-menu"
            showCopyUrl
            url={detailUrl}
          />
        }
        title={<CardTitle className="text-blue">{texts.title}</CardTitle>}
        userAvatar={
          <UserInfoPopup
            trigger={
              <AvatarLink
                height={72}
                href={profileUrl}
                src={buildImageUrl({
                  code: profileImageCode,
                  height: 72,
                  width: 72,
                })}
                width={72}
              />
            }
            userId={user?.id}
            userUID={user?.uid}
          />
        }
        userBadge={
          <CulturalLevelsLabel
            level={culturalLevelNumber}
            popupProps={{ owner: isOwner, userName: firstName }}
            showName={false}
          />
        }
        userDescription={
          texts.userDescription
            ? <UserDescription>{texts.userDescription}</UserDescription>
            : undefined
        }
        userName={
          <UserInfoPopup
            trigger={
              <UserName href={profileUrl}>
                {`${firstName} ${lastName}`}
              </UserName>
            }
            userId={user?.id}
            userUID={user?.uid}
          />
        }
        year={referredYear ? <DateTime>{referredYear}</DateTime> : undefined}
      />
    </>
  );
}

CongratulationEvent.defaultProps = {
  comments: [],
  commentsCount: 0,
  isLoading: false,
  isLoadingComments: false,
  likesCount: 0,
  onComment: () => undefined,
  onCommentsToggle: () => undefined,
  onLike: () => undefined,
  onLikeText: () => undefined,
  showButton: true,
  texts: {
    count: '',
    description: '',
    title: '',
    userDescription: '',
  },
};

CongratulationEvent.propTypes = {
  comments: PropTypes.array,
  commentsCount: PropTypes.number,
  detailUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  isLoadingComments: PropTypes.bool,
  isLoadingLike: PropTypes.bool,
  isOwner: PropTypes.bool,
  likesCount: PropTypes.number,
  mentionsEnabled: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onComment: PropTypes.func,
  onCommentsToggle: PropTypes.func,
  onLike: PropTypes.func,
  onLikeText: PropTypes.func,
  referredYear: PropTypes.number,
  showButton: PropTypes.bool,
  texts: PropTypes.object,
  type: PropTypes.string,
  user: PropTypes.object,
};

export default withCongratulation(CongratulationEvent);
