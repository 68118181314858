// @packages
import axios from 'axios';

// @own
import { bethereApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

const { CancelToken } = axios;

let fetchApiGetFeed = null;
export function apiGetFeed({ orgId, ...params }) {
  if (fetchApiGetFeed) {
    fetchApiGetFeed.cancel();
  }

  fetchApiGetFeed = CancelToken.source();

  return new Promise((resolve, reject) => {
    bethereApiInstance({
      cancelToken: fetchApiGetFeed.token,
      params: { ...params },
      url: `organization/${orgId}/posts`,
    })
      .then(({ data }) => {
        fetchApiGetFeed = null;
        resolve(data);
      })
      .catch((thrown) => !axios.isCancel(thrown) && reject(errorHandler(thrown)));
  });
}

export function apiGetTaggedFeed({ orgId, userId }) {
  if (fetchApiGetFeed) {
    fetchApiGetFeed.cancel();
  }

  fetchApiGetFeed = CancelToken.source();

  return new Promise((resolve, reject) => {
    bethereApiInstance({
      cancelToken: fetchApiGetFeed.token,
      url: `organization/${orgId}/posts/taggedusers/${userId}/`,
    })
      .then(({ data }) => {
        fetchApiGetFeed = null;
        return resolve(data);
      })
      .catch((thrown) => !axios.isCancel(thrown) && reject(errorHandler(thrown)));
  });
}
