export const DEFAULT_END_TIME = '23:30';
export const DEFAULT_START_TIME = '00:00';
export const DISCOVER_CREATE_CHILLOUT = 'DISCOVER_CREATE_CHILLOUT';
export const FORMAT_DATE = 'DD/MM/YYYY';
export const FORMAT_GOOGLE_DATE = 'YYYYMMDDTHHmmSSZ';
export const FORMAT_TIME = 'HH:mm';
export const FORMAT_UTC = 'YYYY-MM-DDTHH:mm:SS.SSSZZ';
export const INTERVAL_TIME = 30;
export const MAX_DESCRIPTION_LENGTH = 500;
export const MAX_TITLE_LENGTH = 70;
export const DEBOUNCE_TIME = 1000;
