// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/IconV2';
import Image from 'smu-ui-components/Image';

// @own
import './styles.scss';

const User = ({ actionUrl, user }) => (
  <a className="insights-content-user" href={actionUrl} target="_blank">
    <Image
      className="insights-content-user__image"
      code={user.imageCode}
      height={100}
      width={100}
    />
    <div className="insights-content-user__details">
      <span className="insights-content-user__details-name">
        {`${user.firstName} ${user.lastName}`}
      </span>
      <span className="insights-content-user__details-job">
        {user?.properties?.job}
      </span>
    </div>
    <Icon
      className="insights-content-user__arrow"
      color="insights-purple"
      icon="arrow-right"
      size={20}
    />
  </a>
);

User.propTypes = {
  actionUrl: PropTypes.string,
  user: PropTypes.object,
};

export default User;
