// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { injectIntl } from 'react-intl';

// @app
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';
import { optionsConfig } from './constants';
import messages from '../messages';

function GraphicColumn({
  areaName,
  areaType,
  eventData,
  graph,
  intl: { formatMessage },
  isManagerInsight,
  isWebView,
  type,
}) {
  function normalizeData() {
    const series = graph?.values;
    const colors = ['#26277e', '#0ba8a8'];
    const managersLabels = [areaName, formatMessage(messages.ManagersAverage, {area: areaType})];
    const newSeries = series?.map((s, key) => ({
      color: colors[key],
      data: [
        Number(s?.value.toFixed(2)),
      ],
      name: isManagerInsight ? managersLabels[key] : messages[`${s?.name}`] ? formatMessage(messages[`${s?.name}`]) : '',
    }));
    return newSeries;
  }

  const maxValue = graph?.values?.map(v => v?.value);

  const config = {
    ...optionsConfig,
    chart: {
      ...optionsConfig.chart,
      type: 'column',
      width: isWebView ? 300 : 400,
    },
    yAxis: {
      ...optionsConfig.yAxis,
      plotLines: [{
        value: graph?.recommended,
        width: 2,
        zIndex: 10,
        label: {
          text: formatMessage(messages.recommended),
          x: -30,
        },
      }],
      max: maxValue >= 100 ? 100 : null,
    },
    xAxis: {
      ...optionsConfig.xAxis,
    },
    series: normalizeData(),
    plotOptions: {
      ...optionsConfig.plotOptions,
      series: {
        ...optionsConfig.plotOptions.series,
        cursor: 'pointer',
      },
    },
  };

  return (
    <div
      className="insights-content__graph"
      onClick={() => trackEvent({
        action: `Interaction_${type}`,
        category: 'Insights',
        ...eventData,
      })}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={config}
      />
    </div>
  );
}

GraphicColumn.propTypes = {
  graph: PropTypes.object,
};

export default injectIntl(GraphicColumn);
