// @packages
import React from 'react';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';

// @app
import SkeletonTopStar from 'components/Skeletons/TopStar';

// @own
import './styles.scss';

function SkeletonLeaderboard({ className, items }) {
  return (
    <Paper className={cn('skeleton-leaderboard', className)}>
      {items?.map((index) => (
        <div className="skeleton-leaderboard__item">
          <div className="skeleton-leaderboard__item-position">
            <Skeleton
              animation="wave"
              className="skeleton-leaderboard__item-counter"
              height={20}
              width={20}
            />
          </div>
          <div className="skeleton-leaderboard__item-user">
            <SkeletonTopStar key={index} />
          </div>
        </div>
      ))}
    </Paper>
  );
}

SkeletonLeaderboard.defaultProps = {
  items: Array.from(Array(10).keys()),
};

SkeletonLeaderboard.propTypes = {
  items: PropTypes.array,
};

export default SkeletonLeaderboard;
