// @own
import {
  TYPE_ENABLED_RENDER,
  TYPE_IMAGE_LOADED,
  TYPE_SHARE_END,
  TYPE_SHARE_ERROR,
  TYPE_SHARE_IMAGE,
  TYPE_SHARE_START,
} from './constants';

export const initialState = {
  error: null,
  image: null,
  loading: false,
  render: true,
  success: false,
};

const shareReducer = (state, action) => {
  switch (action.type) {
    case TYPE_ENABLED_RENDER:
      return {
        ...state,
        render: action.status,
      };
    case TYPE_SHARE_START:
      return {
        ...state,
        image: null,
        loading: true,
      };
    case TYPE_SHARE_END:
      return {
        ...state,
        html: action?.html,
        success: true,
      };
    case TYPE_SHARE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case TYPE_SHARE_IMAGE:
      return {
        ...state,
        image: action.image,
        loading: false,
      };
    case TYPE_IMAGE_LOADED:
      return {
        ...state,
        loading: false,
      };
    default:
      throw new Error();
  }
};

export default shareReducer;
