import {
  GET_PENDING_QUESTIONNAIRES,
  GET_PENDING_QUESTIONNAIRES_FAIL,
  GET_PENDING_QUESTIONNAIRES_SUCCESS,
  GET_QUESTIONNAIRE_BY_ID,
  GET_QUESTIONNAIRE_BY_ID_FAIL,
  GET_QUESTIONNAIRE_BY_ID_SUCCESS,
  POST_ANSWER_BY_QUESTION_ID,
  POST_ANSWER_BY_QUESTION_ID_FAIL,
  POST_ANSWER_BY_QUESTION_ID_SUCCESS,
  POST_COMMENTS_BY_QUESTIONNAIRE,
  POST_COMMENTS_BY_QUESTIONNAIRE_FAIL,
  POST_COMMENTS_BY_QUESTIONNAIRE_SUCCESS,
  POST_DEMOGRAPHICS_BY_QUESTIONNAIRE,
  POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_FAIL,
  POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_SUCCESS,
  PUT_LANGUAGE_BY_QUESTIONNAIRE_ID,
  PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_FAIL,
  PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_SUCCESS,
  RESET_QUESTIONNAIRE,
  SET_LANGUAGE,
} from './actionTypes';

function getPendingQuestionnaires({ orgId }) {
  return {
    type: GET_PENDING_QUESTIONNAIRES,
    payload: {
      orgId,
    },
  };
}

function getPendingQuestionnairesFail(errorMessage) {
  return {
    type: GET_PENDING_QUESTIONNAIRES_FAIL,
    payload: errorMessage,
  };
}

function getPendingQuestionnairesSuccess(payload) {
  return {
    type: GET_PENDING_QUESTIONNAIRES_SUCCESS,
    payload,
  };
}

function getQuestionnaireById(orgId, questionnaireId, closeModal, errorMessage) {
  return {
    type: GET_QUESTIONNAIRE_BY_ID,
    payload: {
      closeModal,
      errorMessage,
      orgId,
      questionnaireId,
    },
  };
}

function getQuestionnaireByIdFail(errorMessage) {
  return {
    type: GET_QUESTIONNAIRE_BY_ID_FAIL,
    payload: errorMessage,
  };
}

function getQuestionnaireByIdSuccess(quetionnaire) {
  return {
    type: GET_QUESTIONNAIRE_BY_ID_SUCCESS,
    payload: quetionnaire,
  };
}

function putLanguageByQuestionnaireId({
  errorMessage,
  language,
  orgId,
  questionnaireId,
}) {
  return {
    type: PUT_LANGUAGE_BY_QUESTIONNAIRE_ID,
    payload: {
      errorMessage,
      language,
      orgId,
      questionnaireId,
    },
  };
}

function putLanguageByQuestionnaireIdFail(erroeMessage) {
  return {
    type: PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_FAIL,
    payload: erroeMessage,
  };
}

function putLanguageByQuestionnaireIdSuccess(payload) {
  return {
    type: PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_SUCCESS,
    payload,
  };
}

function postDemographicsByQuestionnaire({
  data,
  errorMessage,
  orgId,
  questionnaireId,
}) {
  return {
    type: POST_DEMOGRAPHICS_BY_QUESTIONNAIRE,
    payload: {
      data,
      errorMessage,
      orgId,
      questionnaireId,
    },
  };
}

function postDemographicsByQuestionnaireFail(errorMessage) {
  return {
    type: POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_FAIL,
    payload: errorMessage,
  };
}

function postDemographicsByQuestionnaireSuccess(payload) {
  return {
    type: POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_SUCCESS,
    payload,
  };
}

function postAnswerByQuestionId({
  data,
  errorMessage,
  orgId,
  questionId,
  questionnaireId,
}) {
  return {
    type: POST_ANSWER_BY_QUESTION_ID,
    payload: {
      data,
      errorMessage,
      orgId,
      questionId,
      questionnaireId,
    },
  };
}

function postAnswerByQuestionIdFail(errorMessage) {
  return {
    type: POST_ANSWER_BY_QUESTION_ID_FAIL,
    payload: errorMessage,
  };
}

function postAnswerByQuestionIdSuccess(payload) {
  return {
    type: POST_ANSWER_BY_QUESTION_ID_SUCCESS,
    payload,
  };
}

function postCommentsByQuestionnaire({
  categoryId,
  comment,
  errorMessage,
  orgId,
  questionnaireId,
}) {
  return {
    type: POST_COMMENTS_BY_QUESTIONNAIRE,
    payload: {
      categoryId,
      comment,
      errorMessage,
      orgId,
      questionnaireId,
    },
  };
}

function postCommentsByQuestionnaireFail(errorMessage) {
  return {
    type: POST_COMMENTS_BY_QUESTIONNAIRE_FAIL,
    payload: errorMessage,
  };
}

function postCommentsByQuestionnaireSuccess(payload) {
  return {
    type: POST_COMMENTS_BY_QUESTIONNAIRE_SUCCESS,
    payload,
  };
}

function resetQuestionnaire() {
  return {
    type: RESET_QUESTIONNAIRE,
  };
}

function setLanguage(language) {
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
}

export {
  getPendingQuestionnaires,
  getPendingQuestionnairesFail,
  getPendingQuestionnairesSuccess,
  getQuestionnaireById,
  getQuestionnaireByIdFail,
  getQuestionnaireByIdSuccess,
  postAnswerByQuestionId,
  postAnswerByQuestionIdFail,
  postAnswerByQuestionIdSuccess,
  postCommentsByQuestionnaire,
  postCommentsByQuestionnaireFail,
  postCommentsByQuestionnaireSuccess,
  postDemographicsByQuestionnaire,
  postDemographicsByQuestionnaireFail,
  postDemographicsByQuestionnaireSuccess,
  putLanguageByQuestionnaireId,
  putLanguageByQuestionnaireIdFail,
  putLanguageByQuestionnaireIdSuccess,
  resetQuestionnaire,
  setLanguage,
};
