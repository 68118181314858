// @packages
import React, { useContext } from 'react';

// @app
import FeedInsightCard from 'components/FeedInsightCard';
import { StarContext } from 'containers/OSFeed/OSFeedItem';

function Insight() {
  const { data } = useContext(StarContext);

  return <FeedInsightCard insight={data} />;
}

export default Insight;
