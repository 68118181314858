// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from 'smu-ui-components/Button';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';

function QuestionnaireError({
  errorTexts,
  onClick,
}) {
  const {
    messageError,
    buttonRetry,
  } = errorTexts;

  return (
    <div className="questionnaire-error">
      <div className="questionnaire-error__container">
        <Icon className="questionnaire-error__icon" icon="no-connection-icon" color="white" size={100} />
        <h4 className="questionnaire-error__text">
          {messageError}
        </h4>
      </div>
      <div className="questionnaire-error__button-container">
        <Button
          className="questionnaire-error__button"
          noMargin
          noPadding
          onClick={onClick}
        >
          {buttonRetry}
        </Button>
      </div>
    </div>
  );
}

QuestionnaireError.propTypes = {
  intl: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default injectIntl(QuestionnaireError);
