import React from 'react';
import PropTypes from 'prop-types';
import { apiUrl } from 'utils/apiUrl';
import './styles.scss';

const CoreValueItem = ({
  background,
  className,
  height,
  starValueImgCode,
  width,
}) => {
  let styles = {
    height: `${height}px`,
    minWidth: `${width}px`,
    width: `${width}px`,
  };
  if (background) {
    styles.backgroundColor = background;
  }

  return (
    <img
      src={`${apiUrl}/image/${starValueImgCode}/100/100/0`}
      style={styles}
      alt=""
      className={className}
    />
  );
};

CoreValueItem.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  starValueImgCode: PropTypes.string,
  width: PropTypes.number,
};

export default CoreValueItem;
