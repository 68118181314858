import {
  GET_PENDING_QUESTIONNAIRES,
  GET_PENDING_QUESTIONNAIRES_FAIL,
  GET_PENDING_QUESTIONNAIRES_SUCCESS,
  GET_QUESTIONNAIRE_BY_ID,
  GET_QUESTIONNAIRE_BY_ID_FAIL,
  GET_QUESTIONNAIRE_BY_ID_SUCCESS,
  POST_ANSWER_BY_QUESTION_ID,
  POST_ANSWER_BY_QUESTION_ID_FAIL,
  POST_ANSWER_BY_QUESTION_ID_SUCCESS,
  POST_COMMENTS_BY_QUESTIONNAIRE,
  POST_COMMENTS_BY_QUESTIONNAIRE_FAIL,
  POST_COMMENTS_BY_QUESTIONNAIRE_SUCCESS,
  POST_DEMOGRAPHICS_BY_QUESTIONNAIRE,
  POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_FAIL,
  POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_SUCCESS,
  PUT_LANGUAGE_BY_QUESTIONNAIRE_ID,
  PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_FAIL,
  PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_SUCCESS,
  RESET_QUESTIONNAIRE,
  SET_LANGUAGE,
} from './actionTypes';

const defaultState = {
  errorMessage: null,
  fetching: false,
  pendingQuestionnaires: [],
  postingDemographics: false,
  postingLanguage: false,
  questionnaire: {},
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_PENDING_QUESTIONNAIRES:
      return {
        ...state,
        fetching: true,
      };
    case GET_PENDING_QUESTIONNAIRES_FAIL:
      return {
        ...state,
        errorMessage: payload,
        fetching: false,
      };
    case GET_PENDING_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        fetching: false,
        pendingQuestionnaires: payload,
      };
    case PUT_LANGUAGE_BY_QUESTIONNAIRE_ID:
      return {
        ...state,
        postingLanguage: true,
      };
    case PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_FAIL:
      return {
        ...state,
        errorMessage: payload,
        postingLanguage: false,
      };
    case PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_SUCCESS:
      return {
        ...state,
        postingLanguage: false,
        questionnaire: {
          ...state.questionnaire,
          language: payload.language,
        },
        errorMessage: null,
      };
    case POST_ANSWER_BY_QUESTION_ID:
      return {
        ...state,
      };
    case POST_ANSWER_BY_QUESTION_ID_FAIL:
      return {
        ...state,
        errorMessage: payload,
      };
    case POST_ANSWER_BY_QUESTION_ID_SUCCESS:
      return {
        ...state,
        errorMessage: null,
      };
    case POST_COMMENTS_BY_QUESTIONNAIRE:
      return {
        ...state,
      };
    case POST_COMMENTS_BY_QUESTIONNAIRE_FAIL:
      return {
        ...state,
        errorMessage: payload,
      };
    case POST_COMMENTS_BY_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        errorMessage: null,
      };
    case POST_DEMOGRAPHICS_BY_QUESTIONNAIRE:
      return {
        ...state,
        postingDemographics: true,
      };
    case POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_FAIL:
      return {
        ...state,
        errorMessage: payload,
        postingDemographics: false,
      };
    case POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        postingDemographics: false,
        questionnaire: {
          ...state.questionnaire,
          showDemographics: false,
        },
      };
    case RESET_QUESTIONNAIRE:
      return {
        ...state,
        questionnaire: {},
      };
    case GET_QUESTIONNAIRE_BY_ID:
      return {
        ...state,
        errorMessage: null,
        fetching: true,
      };
    case GET_QUESTIONNAIRE_BY_ID_FAIL:
      return {
        ...state,
        errorMessage: payload,
        fetching: false,
      };
    case GET_QUESTIONNAIRE_BY_ID_SUCCESS:
      return {
        ...state,
        questionnaire: {
          ...payload,
        },
        fetching: false,
        errorMessage: null,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          language: payload,
        },
      };
    default:
      return state;
  }
};
