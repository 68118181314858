import {
  REQUEST_COMMUNITY_MEMBER_METRICS,
  REQUEST_COMMUNITY_MEMBER_METRICS_SUCCESS,
  REQUEST_COMMUNITY_MEMBER_METRICS_FAIL,
} from './actionTypes';

export function actionCommunityMemberMetrics(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_COMMUNITY_MEMBER_METRICS,
  };
}

export function actionCommunityMemberMetricsSuccess(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_COMMUNITY_MEMBER_METRICS_SUCCESS,
  };
}

export function actionCommunityMemberMetricsFail(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_COMMUNITY_MEMBER_METRICS_FAIL,
  };
}
