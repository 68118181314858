// @app
import { INSIGHT_DETAILS_MODAL } from 'containers/InsightDetailsModal/constants';

function openInsightDetailsModal({
  closeModal,
  dispatch,
  openModal,
}) {
  return dispatch(
    openModal({
      modalType: INSIGHT_DETAILS_MODAL,
      modalProps: {
        closeModal: () => dispatch(closeModal()),
        closeOnDimmerClick: true,
      },
    }),
  );
}

export default openInsightDetailsModal;
