import { categoryState } from './reducer';

export const getCategoryValuesRequesting = (state, id) =>
  state?.categoryValues?.[id]?.requesting || categoryState?.requesting;
export const getCategoryValuesResult = (state, id) =>
  state?.categoryValues?.[id]?.result || categoryState?.result;
export const getCategoryValuesError = (state, id) =>
  state?.categoryValues?.[id]?.error || categoryState?.error;
export const getCategoryValuesSuccess = (state, id) =>
  state?.categoryValues?.[id]?.success || categoryState?.success;
export const getCategoriesValues = (ids = []) => (state) =>
  ids?.reduce((acc, cur) => {
    acc[cur] = state?.categoryValues?.[cur] || categoryState;
    return acc;
  }, {});
