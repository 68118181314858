// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// @app
import OSFeed from 'containers/OSFeed';
import ErrorState from 'components/ErrorState';
import { actions as routeActions } from 'utils/routes';
import { apiInstance } from 'utils/requestHelper';
import {
  selectSessionUserRights,
  selectSessionOsFeedEnabled,
} from 'containers/Authorization/selectors';
import { getCommunityConfigFetching } from 'services/communityConfigs/selectors';

// @own
import { COMMUNICATION } from './constants';
import messages from './messages';

function CommunicationsFeed({
  communityConfigFetching,
  intl: { formatMessage },
  router,
  showProfile,
  userRights,
}) {
  if (communityConfigFetching) {
    return null;
  }

  const handleReturnHome = () => {
    router.push('/home');
  };

  const getEmptyState = () => (
    <ErrorState
      descriptionMessage={formatMessage(messages.communicationsEmptyStateDescription)}
      onHomeClick={handleReturnHome}
      returnHomeMessage={formatMessage(messages.communicationsErrorStateReturnHome)}
      titleMessage={formatMessage(messages.communicationsEmptyStateTitle)}
      errorImage={'assets/img/empty-state.png'}
    />
  );

  return (
    <OSFeed
      api={apiInstance}
      app={COMMUNICATION}
      filters={{
        size: 10,
      }}
      getEmptyStateCustom={getEmptyState}
      isMedia
      showProfile={showProfile}
      userRights={userRights}
    />
  );
}

CommunicationsFeed.propTypes = {
  communityConfigFetching: PropTypes.bool,
  communityOsFeedEnabled: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  showProfile: PropTypes.func.isRequired,
  userRights: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const communityOsFeedEnabled = selectSessionOsFeedEnabled(state);
  const userRights = selectSessionUserRights(state);
  const communityConfigFetching = getCommunityConfigFetching(state);

  return {
    communityConfigFetching,
    communityOsFeedEnabled,
    userRights,
  };
};

const actions = {
  showProfile: profileId => routeActions.profile({ profileId }),
};

export default withRouter(connect(mapStateToProps, actions)(injectIntl(CommunicationsFeed)));
