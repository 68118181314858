// @app
import { smuInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { times } from 'api';

export function apiCategoryValues({ data = {}, params = {}, type = 'GET' }) {
  return smuInstance({
    data,
    params: { ...times(), ...params },
    type,
    url: '/starmeup-api/v2/stellar/categoryValues',
  })
    .then((data) => data)
    .catch(errorHandler);
}
