import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animated: true,
    };
    this.applyAnimation = this.applyAnimation.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { question } = this.props;
    const { question: prevQuestion } = prevProps;
    if (prevQuestion !== question) {
      this.applyAnimation();
    }
  }

  applyAnimation() {
    this.setState({
      animated: true,
    });
  }

  render() {
    const { question } = this.props;
    const { animated } = this.state;

    return (
      <h1 className={cn('question', { 'question--animation': animated })} onAnimationEnd={() => this.setState({ animated: false })}>
        {question}
      </h1>
    );
  }
}

Questions.propTypes = {
  question: PropTypes.any.isRequired,
};

export default Questions;
