// @packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';
import { useCongratulations } from 'smu-services/data-service/api/v1/community/members/congratulations';

// @app
import Congratulations from 'components/Congratulations';
import { capitalize } from 'utils/formatString';
import { selectMentionEnabled } from 'containers/withCongratulation/selectors';

// @own
import {
  CONFIG,
  EMPTY_IMAGES,
  SOURCE_TYPES,
  TYPES,
  TYPE_ANNIVERSARIES,
  TYPE_ANNIVERSARY,
  TYPE_BIRTHDAY,
  TYPE_BIRTHDAYS,
  VALID_TYPES,
} from './constants';
import messages from './messages';

function CongratulationsContainer({
  date,
  enabledAnniversaries,
  enabledBirthdays,
  firstUserUID,
  intl: { formatMessage: fm },
  mentionsEnabled,
  onChangeDate,
  section,
  ...rest
}) {
  const momentDate = date?.length === 10 ? moment.unix(date) : moment(date);
  const minDate = moment().startOf('year').subtract(1, 'year');
  const maxDate = moment().endOf('year').add(1, 'year');
  const minDateInteractions = moment().startOf('day').subtract(5, 'days');
  const maxDateInteractions = moment().startOf('day').add(1, 'day');
  const month = momentDate.format('MM');
  const day = momentDate.format('DD');
  const showInteractions = momentDate.isBetween(
    minDateInteractions,
    maxDateInteractions,
  );
  const validType = VALID_TYPES[section] || TYPE_BIRTHDAY;
  const { error, isLoading, isValidating, selectResult, mutate } =
    useCongratulations({
      day,
      firstUserUID,
      month,
      page: 0,
      pageSize: 300,
      type: validType,
    });
  const users = selectResult();

  const handleEvent = (event) => {
    trackEvent({
      category: 'congrats_landing',
      ...event,
    });
  };

  const tabs = useMemo(() => {
    const tabs = [];

    if (enabledBirthdays) {
      tabs.push({
        active: validType === TYPE_BIRTHDAY,
        children: fm(messages.congratulationsBirthdayTabTitle),
        href: `/congratulations?date=${date}&section=${TYPE_BIRTHDAYS}`,
        key: TYPE_BIRTHDAY,
        onClick: () => handleEvent({
          action: 'click',
          label: 'click_tab',
          type: TYPE_BIRTHDAY,
        }),
      });
    }

    if (enabledAnniversaries) {
      tabs.push({
        active: validType === TYPE_ANNIVERSARY,
        children: fm(messages.congratulationsAnniversaryTabTitle),
        href: `/congratulations?date=${date}&section=${TYPE_ANNIVERSARIES}`,
        key: TYPE_ANNIVERSARY,
        onClick: () => handleEvent({
          action: 'click',
          label: 'click_tab',
          type: TYPE_ANNIVERSARY,
        }),
      });
    }
    return tabs;
  }, [enabledAnniversaries, enabledBirthdays, validType, date]);

  const congratulationConfig = useMemo(() => {
    const cType = capitalize(validType);
    const referredYear = momentDate.format('YYYY');

    return {
      ...CONFIG[validType],
      messagePlaceholder: fm(messages[`congratulations${cType}Placeholder`]),
      messageTabTitle: fm(messages[`congratulations${cType}TabTitle`]),
      messageViewCard: fm(messages.congratulationsViewCard),
      messageYouCongratulated: fm(messages.congratulationsYouCongratulated),
      onClickUser: () => handleEvent({
        action: 'click',
        label: 'click_user',
        type: validType,
      }),
      onViewCard: () => handleEvent({
        action: 'click',
        label: 'view_card',
        type: validType,
      }),
      referredYear,
      sourceType: SOURCE_TYPES[validType],
    };
  }, [validType, date]);

  const emptyState = {
    description: fm(messages.congratulationsEmptyStateSubtitle),
    image: EMPTY_IMAGES[validType],
    title: fm(messages.congratulationsEmptyStateTitle),
  };

  return (
    <Congratulations
      datepickerProps={{
        isOutsideRange: (day) => day.isBefore(minDate) || day.isAfter(maxDate),
        onChange: onChangeDate,
        value: momentDate,
        yearsList: [
          minDate.format('YYYY'),
          moment().format('YYYY'),
          maxDate.format('YYYY'),
        ],
        minDate,
        maxDate,
      }}
      emptyState={emptyState}
      isError={!!error}
      isLoading={isLoading || isValidating}
      key={date}
      mentionsEnabled={mentionsEnabled}
      messageHeaderSubtitle={fm(messages.congratulationsHeaderSubtitle)}
      messageHeaderTitle={fm(messages.congratulationsHeaderTitle)}
      onRetry={mutate}
      showInteractions={showInteractions}
      tabs={tabs}
      users={users}
      {...congratulationConfig}
      {...rest}
    />
  );
}

const mapStateToProps = (state) => ({
  mentionsEnabled: selectMentionEnabled(state),
});

CongratulationsContainer.propTypes = {
  date: PropTypes.string.isRequired,
  firstUserUID: PropTypes.string,
  intl: PropTypes.object.isRequired,
  mentionsEnabled: PropTypes.bool,
  onChangeDate: PropTypes.func,
  section: PropTypes.oneOf(TYPES),
};

CongratulationsContainer.defaultProps = {
  firstUserUID: '',
  mentionsEnabled: false,
  section: TYPE_BIRTHDAYS,
};

export default connect(mapStateToProps)(injectIntl(CongratulationsContainer));
