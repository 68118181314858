import { defineMessages } from 'react-intl';

export default defineMessages({
  sendKudos: {
    id: 'starSelector.sendKudos',
    defaultMessage: 'Send a',
  },
  sendStar: {
    id: 'starSelector.sendStar',
    defaultMessage: 'Send star',
  },
  noKudosAvailable: {
    id: 'starSelector.noKudosAvailable',
    defaultMessage: 'You have already sent all your available group recognition for the month! Try sending a different type of star.',
  },
  noStarAvailable: {
    id: 'starSelector.noStarAvailable',
    defaultMessage: 'Hooray! You already sent all your stars this month. Next month you can continue recognizing your colleagues work!',
  },
});
