// @packages
import React, { forwardRef } from 'react';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @App
import QuestionnaireResultsListContainer from 'containers/QuestionnaireResultsListContainer';

// @own
import './styles.scss';
import Graphics from './Graphics';
import Header from './Header';
import Error from './Error';
import {
  TYPE_EMOJI,
  TYPE_IMAGE,
  TYPE_SIMPLE,
  TYPE_TEXT,
  TYPE_LIKERT,
} from './constants';

function QuestionnaireResults(
  {
    className,
    enabledMobile,
    errorProps,
    headerProps,
    invalidTypeText,
    isError,
    listTexts,
    questionnaireId,
    questions,
    showExportButton,
    summaryTexts,
    tooltipsTexts,
  },
  ref,
) {
  function buildQuestion(question, key) {
    const type = question?.options?.[0]?.type;

    switch (type) {
      case TYPE_EMOJI:
      case TYPE_IMAGE:
      case TYPE_SIMPLE:
      case TYPE_LIKERT:
        return (
          <Graphics
            className="smu-questionnaire-results__question"
            enabledMobile={enabledMobile}
            key={key}
            questionnaireId={questionnaireId}
            summaryTexts={summaryTexts}
            tooltipsTexts={tooltipsTexts}
            type={type}
            {...question}
          />
        );
      case TYPE_TEXT:
        return (
          <QuestionnaireResultsListContainer
            className="smu-questionnaire-results__question"
            enabledMobile={enabledMobile}
            key={key}
            listTexts={listTexts}
            questionnaireId={questionnaireId}
            summaryTexts={summaryTexts}
            {...question}
          />
        );
      default:
        return <div key={key}>{`${invalidTypeText}: ${type}`}</div>;
    }
  }

  return (
    <div
      className={cn(
        'smu-questionnaire-results',
        {
          'smu-questionnaire-results--mobile': enabledMobile,
        },
        className,
      )}
    >
      <div className="smu-questionnaire-results__wrapper" ref={ref}>
        {isError && <Error {...errorProps} />}
        {(!isError && questions?.[0]) && (
          <>
            <Header
              className="smu-questionnaire-results__header"
              enabledMobile={enabledMobile}
              showExportButton={showExportButton}
              {...headerProps}
            />
            <Panel className="smu-questionnaire-results__questions" noPadding>
              {questions?.map(buildQuestion)}
            </Panel>
          </>
        )}
      </div>
    </div>
  );
}

QuestionnaireResults.propTypes = {
  className: PropTypes.string,
  enabledMobile: PropTypes.bool,
  errorProps: PropTypes.object,
  headerProps: PropTypes.object,
  invalidTypeText: PropTypes.string,
  isError: PropTypes.bool,
  listTexts: PropTypes.object,
  questions: PropTypes.array,
  showExportButton: PropTypes.bool,
  summaryTexts: PropTypes.object,
  tooltipsTexts: PropTypes.object,
};

QuestionnaireResults.defaultProps = {
  className: '',
  enabledMobile: true,
  invalidTypeText: 'Invalid questionnaire type',
  questions: [],
  showExportButton: true,
};

export default forwardRef(QuestionnaireResults);
