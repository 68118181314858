import {
  GET_INTERACTIONS,
  GET_INTERACTIONS_SUCCESS,
  GET_INTERACTIONS_FAIL,
} from './actionTypes';

const initialState = {
  fetching: false,
  interactions: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_INTERACTIONS:
      return {
        ...state,
        fetching: true,
      };
    case GET_INTERACTIONS_SUCCESS:
      return {
        ...state,
        interactions: payload.data,
        fetching: false,
      };
    case GET_INTERACTIONS_FAIL:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
