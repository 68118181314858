// @packages
import React, { useState } from 'react';
import IconButton from 'smu-ui-components/IconButton';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @app
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import MenuPopUp from 'components/MenuPopUp';
import copyToClipboard from 'utils/copyToClipboard';

// @own
import messages from './messages';
import './styles.scss';
import {
  eventOnCloseMenu,
  eventOnCopyUrl,
  eventOnDelete,
  eventOnEdit,
  eventOnModerate,
  eventOnNotModerate,
  eventOnOpenMenu,
  eventOnTaggedUsers,
} from './analytics';

const PostMenu = ({
  className,
  intl: { formatMessage },
  inverted,
  isModerate,
  isModerateComment,
  onCopyUrl,
  onDelete,
  onEdit,
  onModerate,
  onModerateComment,
  onTagguedUsers,
  showModerateComment,
  showCopyUrl,
  showDelete,
  showEdit,
  showModerate,
  showTaggedUsers,
  url,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const options = [];

  const handleOpen = () => {
    eventOnOpenMenu();
    setOpen(true);
  };

  const handleClose = () => {
    eventOnCloseMenu();
    setOpen(false);
  };

  const handleToggle = () => {
    if (open) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const handleCopyUrl = () => {
    copyToClipboard(url);
    eventOnCopyUrl();
    if (onCopyUrl) onCopyUrl();
  };

  const handleDelete = () => {
    eventOnDelete();
    if (onDelete) onDelete();
  };

  const handleEdit = () => {
    eventOnEdit();
    if (onEdit) onEdit();
  };

  const handleModerate = () => {
    if (isModerate) {
      eventOnNotModerate();
    } else {
      eventOnModerate();
    }
    if (onModerate) onModerate();
  };

  const handleTagguedUsers = () => {
    eventOnTaggedUsers();
    if (onTagguedUsers) onTagguedUsers();
  };

  if (showCopyUrl && url) {
    options.push({
      children: formatMessage(messages.copyUrl),
      iconLeft: 'copy',
      onClick: handleCopyUrl,
    });
  }

  if (showDelete) {
    options.push({
      children: formatMessage(messages.delete),
      iconLeft: 'trash',
      onClick: handleDelete,
    });
  }

  if (showEdit) {
    options.push({
      children: formatMessage(messages.edit),
      iconLeft: 'edit',
      onClick: handleEdit,
    });
  }

  if (showTaggedUsers) {
    options.push({
      children: formatMessage(messages.taggedUsers),
      iconLeft: 'tag',
      onClick: handleTagguedUsers,
    });
  }

  if (showModerate) {
    options.push({
      children: formatMessage(
        messages?.[`${isModerate ? 'show' : 'hide'}Description`],
      ),
      iconLeft: 'eye',
      onClick: handleModerate,
    });
  }

  if (showModerateComment) {
    options.push({
      children: formatMessage(
        messages?.[`${isModerateComment ? 'show' : 'hide'}Comment`],
      ),
      iconLeft: 'eye',
      onClick: handleModerate,
    });
  }

  return (
    <MenuPopUp
      className={cn(
        'post-menu',
        { 'post-menu--inverted': inverted },
        className,
      )}
      inverted={inverted}
      onClose={handleClose}
      open={open}
      options={options}
      {...rest}
    >
      <IconButton
        className="p-2"
        icon="horizontal-dots"
        onClick={handleToggle}
        size="small"
      />
    </MenuPopUp>
  );
};

PostMenu.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  isModerate: PropTypes.bool,
  isModerateComment: PropTypes.bool,
  onCopyUrl: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onModerate: PropTypes.func,
  onModerateComment: PropTypes.func,
  onTagguedUsers: PropTypes.func,
  showCopyUrl: PropTypes.bool,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  showModerate: PropTypes.bool,
  showModerateComment: PropTypes.bool,
  showTaggedUsers: PropTypes.bool,
  url: PropTypes.string,
};

PostMenu.defaultProps = {
  isModerate: false,
  isModerateComment: false,
  showCopyUrl: false,
  showDelete: false,
  showEdit: false,
  showModerate: false,
  showModerateComment: false,
  showTaggedUsers: false,
};

export default injectIntl(PostMenu);
