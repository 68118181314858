// @packages
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Avatar from 'smu-ui-components/Avatar';
import StarTitle from 'smu-ui-components/Cards/components/StarTitle';
import Typography from 'smu-ui-components/Typography';

// @app
import TextParserContainer from 'containers/TextParser';
import { apiUrl } from 'utils/apiUrl';

// @own
import { getDescriptionProps } from './helpers';

function ShareStar({ className, organizationLogo, star, title, user }, ref) {
  return (
    <div className={cn('font-rubik', className)} style={{ width: '564px' }}>
      <div
        className="flex w-full flex-col"
        style={{
          backgroundColor: '#F7F7FD',
          minHeight: '350px',
          padding: '18px 28px',
        }}
      >
        <div
          className="flex justify-between"
          style={{
            marginBottom: '20px',
          }}
        >
          <Typography
            className="text-blue text-base"
            style={{ width: '330px' }}
            variant="title3"
          >
            {title}
          </Typography>
          <div
            style={{
              height: '35px',
              width: '89px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <img
              alt="organization-logo"
              className="rounded-md"
              style={{ maxHeight: '35px' }}
              src={organizationLogo}
            />
          </div>
        </div>
        <div
          className="flex justify-between"
          style={{ margin: 'auto 0', minHeight: '180px' }}
        >
          <div className="text-center w-36 flex flex-col items-center">
            <Avatar
              className="mx-auto"
              height={64}
              src={user?.imageUrl}
              width={64}
            />
            <Typography className="text-blue text-sm" variant="subtitle2">
              {user?.name}
            </Typography>
            <Typography className="text-blue text-sm mt-1" variant="subtitle1">
              {user?.job}
            </Typography>
          </div>
          <div
            className="inline-block rounded-lg"
            style={{
              width: '363px',
              padding: '12px 24px',
              backgroundColor: 'white',
            }}
          >
            <StarTitle
              color={star?.backgroundColor}
              imageSrc={`${apiUrl}/image/${star?.imageCode}/100/100/0`}
              text={star?.name}
            />
            <div ref={ref}>
              <TextParserContainer
                {...getDescriptionProps(star?.content)}
                className="font-openSans text-sm mt-2 mb-3"
                style={{ whiteSpace: 'pre-line' }}
              />
            </div>
          </div>
        </div>
        <div
          className="flex justify-end items-center"
          style={{
            marginTop: '20px',
          }}
        >
          <span
            className="text-blue font-montserrat mb-2"
            style={{ fontSize: '7px' }}
          >
            powered by
          </span>
          <img
            alt="share-logo"
            style={{ width: '58px', height: '12px', marginLeft: '4px' }}
            className="inline-block"
            src="/assets/img/logo_smu.png"
          />
        </div>
      </div>
    </div>
  );
}

ShareStar.propTypes = {
  className: PropTypes.string,
  star: PropTypes.object,
  title: PropTypes.string,
  user: PropTypes.object,
};

ShareStar.defaultProps = {
  star: {},
  title: '',
  user: {},
};

export default forwardRef(ShareStar);
