// @app
import { CONNECTIONS } from 'components/ProfileLayout/constants';
import culturalIndicatorImage from 'assets/img/nf_cultural-indicator.jpg';
import interactionTimelineImage from 'assets/img/nf_interaction-timeline.jpg';
// @own
import messages from './messages';

export const FEATURES = [
  {
    button: {
      text: messages.newFeaturesCallToAction,
      link: '/me',
      tab: CONNECTIONS,
    },
    date: 'Sep 29, 2020 00:00:00 AM',
    description: messages.newFeaturesCulturalIndicatorDescription,
    imageSrc: culturalIndicatorImage,
    title: messages.newFeaturesCulturalIndicatorTitle,
  },
  {
    button: {
      text: messages.newFeaturesCallToAction,
      link: '/me',
      tab: CONNECTIONS,
    },
    date: 'Aug 17, 2020 00:00:00 AM',
    description: messages.newFeaturesInteractionTimelineDescription,
    imageSrc: interactionTimelineImage,
    title: messages.newFeaturesInteractionTimelineTitle,
  },
];
