import {
  GET_INTERACTION_TIMELINE,
  GET_INTERACTION_TIMELINE_SUCCESS,
  GET_INTERACTION_TIMELINE_FAILED,
} from './actionTypes';

export function getTimeline({ interactionType, groupBy, period }) {
  return {
    type: GET_INTERACTION_TIMELINE,
    payload: { interactionType, groupBy, period },
  };
}

export function getTimelineSuccess({ community, periods, member }) {
  return {
    type: GET_INTERACTION_TIMELINE_SUCCESS,
    payload: { community, periods, member },
  };
}

export function getTimelineFailed({ message }) {
  return {
    type: GET_INTERACTION_TIMELINE_FAILED,
    payload: { message },
  };
}
