import { defineMessages } from 'react-intl';

export default defineMessages({
  timelineProfile: {
    id: 'timeline.profile',
    defaultMessage: 'PROFILE',
  },
  timelineRecognitions: {
    id: 'timeline.recognitions',
    defaultMessage: 'RECOGNITIONS',
  },
});
