// @packages
import React from 'react';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import UserProfileOther from 'smu-app-components/UserProfile/Other';
import cn from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  selectCommunityAllowsAddToMyTeam,
  selectOwnBmeProfile,
  selectUserInfo,
} from 'smu-unified-profile/services/betterMeConfig/selectors';
import { trackEventSendStarFlowOpen } from 'smu-app-components/SendStar/analytics';
import { useMembers } from 'smu-services/os-member/api/v2/community/members/[id]';
import { withRouter } from 'react-router';

// @app
import Link from 'components/Link';
import {
  ENABLED_ANNIVERSARIES,
  ENABLED_BETHERE_FEED,
  ENABLED_BETTERME,
  ENABLED_BIRTHDAYS,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_REQUEST,
  ENABLED_STARMEUP,
  ENABLED_SURVEYS_ONLY,
} from 'services/communityConfigs/constants';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';

// @own
import { helperActionsProps } from './helpers';

const UserProfileOtherContainer = ({
  bmeAllowsAddToMyTeam,
  bmeOtherUser,
  bmeUser,
  className,
  enabledAnniversaries,
  enabledBethere,
  enabledBetterme,
  enabledBirthdays,
  enabledOneOnOne,
  enabledOneOnOneRequest,
  enabledStarMeUp,
  enabledSurveysOnly,
  intl: { formatMessage },
  router,
  showPanel,
  showStars,
  userUID,
  ...rest
}) => {
  const member = useMembers({ userUID });

  const onSendStar = () => {
    trackEventSendStarFlowOpen({
      int_type: 'side_bar',
      label: 'give_a_star',
    });
    const user = member.selectMember();

    router.push(buildSendStarModalUrl({
      params: {
        users: [user],
      },
    }));
  };

  const actionsProps = helperActionsProps({
    bmeAllowsAddToMyTeam,
    bmeOtherUser,
    bmeUser,
    enabledBethere,
    enabledBetterme,
    enabledOneOnOne,
    enabledOneOnOneRequest,
    enabledStarMeUp,
    formatMessage,
    member,
    onSendStar,
    owner: false,
  });

  const children = (
    <UserProfileOther
      components={{
        link: Link,
      }}
      actionsProps={actionsProps}
      enabledAnniversaries={enabledAnniversaries}
      enabledBethere={enabledBethere}
      enabledBetterme={enabledBetterme}
      enabledBirthdays={enabledBirthdays}
      enabledOneOnOne={enabledOneOnOne}
      enabledOneOnOneRequest={enabledOneOnOneRequest}
      enabledStarmeup={enabledStarMeUp}
      enabledSurveysOnly={enabledSurveysOnly}
      showCulturalLevels={enabledStarMeUp}
      showQualifiers={false}
      showStars={false}
      starmeupUrl=""
      userUID={userUID}
      {...rest}
    />
  );

  if (showPanel) {
    return (
      <Paper className={cn('py-4 px-3 w-full shadow-base', className)}>{children}</Paper>
    );
  }

  return children;
};

UserProfileOtherContainer.defaultProps = {
  bmeAllowsAddToMyTeam: false,
  enabledAnniversaries: false,
  enabledBethere: false,
  enabledBetterme: false,
  enabledBirthdays: false,
  enabledOneOnOne: false,
  enabledOneOnOneRequest: false,
  enabledStarMeUp: false,
  enabledSurveysOnly: false,
  showPanel: false,
};

UserProfileOtherContainer.propTypes = {
  bmeAllowsAddToMyTeam: PropTypes.bool,
  bmeOtherUser: PropTypes.object,
  bmeUser: PropTypes.object,
  className: PropTypes.string,
  enabledAnniversaries: PropTypes.bool,
  enabledBethere: PropTypes.bool,
  enabledBetterme: PropTypes.bool,
  enabledBirthdays: PropTypes.bool,
  enabledOneOnOne: PropTypes.bool,
  enabledOneOnOneRequest: PropTypes.bool,
  enabledStarMeUp: PropTypes.bool,
  enabledSurveysOnly: PropTypes.bool,
  intl: PropTypes.object,
  router: PropTypes.object,
  showPanel: PropTypes.bool,
  userUID: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  bmeAllowsAddToMyTeam: selectCommunityAllowsAddToMyTeam(state),
  bmeOtherUser: selectUserInfo(false)(state),
  bmeUser: selectOwnBmeProfile(state),
  enabledAnniversaries: getCommunityConfigValueBool(state, ENABLED_ANNIVERSARIES),
  enabledBethere: getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED),
  enabledBetterme: getCommunityConfigValueBool(state, ENABLED_BETTERME),
  enabledBirthdays: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
  enabledOneOnOne: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
  enabledOneOnOneRequest: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE_REQUEST),
  enabledStarMeUp: getCommunityConfigValueBool(state, ENABLED_STARMEUP),
  enabledSurveysOnly: getCommunityConfigValueBool(state, ENABLED_SURVEYS_ONLY),
});

export default connect(mapStateToProps)(
  injectIntl(withRouter(UserProfileOtherContainer)),
);
