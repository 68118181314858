// @packages
import moment from 'moment';

// @own
import { FORMAT_DAY, FORMAT_MONTH, FORMAT_TIME } from './constants';

export function utcToLocal(date) {
  return moment.utc(date).local();
}

export function normaliceEvents(events, currentDate, comingSoon, joinEnabled) {
  return events
    .filter(({ endDate }) => utcToLocal(endDate).isAfter(currentDate))
    .map((event) => {
      const {
        endDate,
        matched,
        startDate,
        title,
        url,
      } = event;
      const start = utcToLocal(startDate);
      const end = utcToLocal(endDate);
      const month = start.format(FORMAT_MONTH);
      const day = start.format(FORMAT_DAY);
      const startTime = start.format(FORMAT_TIME);
      const endTime = end.format(FORMAT_TIME);
      const isNow = currentDate.isBetween(moment(start).subtract(1, 'minutes'), end);
      const isComingSoon = currentDate.isBetween(moment(start).subtract(comingSoon, 'minutes'), start);
      const isJoinEnabled = currentDate.isBetween(moment(start).subtract(joinEnabled, 'minutes'), end);
      const minutesComingSoon = isComingSoon ? start.diff(currentDate, 'minutes') + 1 : 0;

      return {
        day,
        endTime,
        isComingSoon,
        isJoinEnabled,
        isNow,
        matched,
        minutesComingSoon,
        month,
        startTime,
        title,
        url,
      };
    });
}
