import { takepartApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
// @own
import {
  SURVEYS_PAGE_SIZE,
} from './constants';

export const apiGetQuestionaries = ({ page, status, lang }) =>
  takepartApiInstance({
    headers: {
      'Accept-Language': lang,
    },
    params: { page, size: SURVEYS_PAGE_SIZE, status},
    url: '/api/v2/community/me/questionnaires',
  })
    .then(({ data }) => data)
    .catch(errorHandler);

export const apiDismissQuestionaries = ({ id, communityId, ...params}) =>
  takepartApiInstance.patch(`/api/v2/community/me/${communityId}/questionnaires/${id}`, params)
    .then(({ data }) => data)
    .catch(errorHandler);

