// @packages
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { DEFAULT_TOAST_TIMEOUT } from 'utils/constants';

const useToast = () => {
  const dispatch = useDispatch();

  const showMessage = useCallback(({ message, timeout }) => {
    dispatch(
      addToastMessage({
        message,
        timeout: timeout || DEFAULT_TOAST_TIMEOUT,
      }),
    );
  }, []);

  return showMessage;
};

export default useToast;
