import {
  REQUEST_COMMUNITY_MEMBERS,
  REQUEST_COMMUNITY_MEMBERS_CLEAN,
  REQUEST_COMMUNITY_MEMBERS_FAIL,
  REQUEST_COMMUNITY_MEMBERS_SUCCESS,
} from './actionTypes';

export function actionCommunityMembers(payload) {
  return {
    payload,
    type: REQUEST_COMMUNITY_MEMBERS,
  };
}

export function actionCommunityMembersClean() {
  return {
    type: REQUEST_COMMUNITY_MEMBERS_CLEAN,
  };
}

export function actionCommunityMembersFail(payload) {
  return {
    payload,
    type: REQUEST_COMMUNITY_MEMBERS_FAIL,
  };
}

export function actionCommunityMembersSuccess(payload) {
  return {
    payload,
    type: REQUEST_COMMUNITY_MEMBERS_SUCCESS,
  };
}
