// @packages
import React, { useContext, useState } from 'react';
import CardDateTime from 'smu-ui-components/Cards/components/DateTime';
import CardDescription from 'smu-ui-components/Cards/components/Description';
import CardSendBy from 'smu-ui-components/Cards/components/SendBy';
import CardStarTitle from 'smu-ui-components/Cards/components/StarTitle';
import Image from 'smu-ui-components/Image';
import KudosCardTemplate from 'smu-ui-components/Cards/templates/Kudos';
import TextParserContainer from 'containers/TextParser';
import UserAvatarList from 'smu-ui-components/UserAvatarList';
import UsersByKudosModal from 'smu-app-components/UsersByKudosModal';
import useModal from 'smu-custom-hooks/useModal';
import useTheme from 'smu-custom-hooks/useTheme';
import { StarContext } from 'containers/OSFeed/OSFeedItem';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import ModalInteractions from 'containers/Modals/Interactions';
import PostMenu from 'containers/PostMenu';
import StarActionCommentContainer from 'containers/Cards/Star/ActionComment';
import StarActionLikeContainer from 'containers/Cards/Star/ActionLike';
import UserInfoPopup from 'containers/UserInfoPopup';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import {
  getDateTimeProps,
  getDescriptionProps,
  getMainPhoto,
  getMessages,
} from 'components/Star/SimpleStar/helpers';
import {
  normalizeStarsInteractions,
  TYPES_STARS_INTERACTIONS,
} from 'utils/interactions';

// @own
import MainPhoto from '../MainPhoto';
import Messages from '../Messages/index';
import withStarConext from '../StarContext';
import {
  getMenuProps,
  getModalKudosProps,
  getSendByProps,
  getStarTitleProps,
  getUserAvatarListProps,
} from './helpers';

const KudosStar = (props) => {
  const context = useContext(StarContext);
  const [expanded, setExpanded] = useState(context?.expanded);
  const modal = useModal();
  const modalKudos = useModal();
  const { isModerator, router } = props;
  const theme = useTheme();
  const comments = context?.data?.comments;
  const date = context?.data?.date;
  const enabled = context?.data?.enabled;
  const id = context?.data?.id;
  const content = context?.data?.notes;
  const star = context?.data?.starMeUpOrganizationStar;
  const messages = getMessages(comments);
  const onEditStar = context?.onEditStar;
  const showDescription = enabled || isModerator;
  const mainPhoto = getMainPhoto(comments);
  const userFrom = context?.data?.from;
  const themeHashtag = theme.getHashtagConfigByText(content);
  let description;
  let media;
  let commentsComponent;
  let themeImage;

  const handleExpandedComments = () => {
    setExpanded(!expanded);

    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: content,
      id,
      label: 'comment',
      type: star?.name,
    });
  };

  const handleLike = () => {
    trackEvent({
      action: 'like',
      category: 'card_activity',
      description: content,
      id,
      type: star?.name,
    });
  };

  const handleUnlike = () => {
    trackEvent({
      action: 'unlike',
      category: 'card_activity',
      description: content,
      id,
      type: star?.name,
    });
  };

  const handleAddComment = () => {
    trackEvent({
      action: 'comment_success',
      category: 'card_activity',
      description: content,
      id,
      type: star?.name,
    });
  };

  const handleAddCommentImage = () => {
    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: content,
      id,
      label: 'comment_add_picture',
      type: star?.name,
    });
  };

  const handleClickHiddenUsers = (e) => {
    e.stopPropagation();
    modalKudos.open({ ...getModalKudosProps(context) });
  };

  const handleOpenInteractions = (type) => {
    const interactions = normalizeStarsInteractions(comments);

    modal.open({
      contentDescription: content,
      contentId: id,
      contentType: star?.name,
      defaultType: TYPES_STARS_INTERACTIONS[type],
      interactions,
    });

    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: content,
      id,
      label: 'view_likes',
      type: star?.name,
    });
  };

  const handleEditStar = () => {
    router.push(
      buildSendStarModalUrl({
        params: { editId: id },
      }),
    );
    if (onEditStar) onEditStar();
  };

  if (showDescription) {
    description = (
      <CardDescription>
        <TextParserContainer {...getDescriptionProps(context)} />
      </CardDescription>
    );
  }

  if (mainPhoto) {
    media = <MainPhoto mainPhoto={mainPhoto} />;
  }

  if (expanded) {
    commentsComponent = (
      <Messages
        className="rounded-b-lg"
        expanded
        messages={messages}
        onAddComment={handleAddComment}
        onAddCommentImage={handleAddCommentImage}
      />
    );
  }

  if (themeHashtag?.starsTopLeftCornerImageCode) {
    themeImage = (
      <Image
        className="absolute left-0 top-0"
        legacy={false}
        src={themeHashtag?.starsTopLeftCornerImageCode}
      />
    );
  }

  return (
    <>
      <KudosCardTemplate
        actionComment={
          <StarActionCommentContainer
            isModerator={isModerator}
            messages={messages}
            onClickIcon={handleExpandedComments}
          />
        }
        actionLike={
          <StarActionLikeContainer
            activeImage={themeHashtag?.likeSelectedImageCode}
            comments={comments}
            inactiveImage={themeHashtag?.likeDefaultImageCode}
            onClickCount={() => handleOpenInteractions('LIKE')}
            onLike={handleLike}
            onUnlike={handleUnlike}
            starId={id}
          />
        }
        comments={commentsComponent}
        className="shadow-base"
        media={media}
        starMenu={
          <PostMenu onEdit={handleEditStar} {...getMenuProps(props, context)} />
        }
        sendBy={
          <UserInfoPopup
            trigger={<CardSendBy {...getSendByProps(props, context)} />}
            userId={userFrom?.id}
            userUID={userFrom?.uid}
          />
        }
        starDateTime={<CardDateTime {...getDateTimeProps(date)} />}
        starDescription={description}
        starTitle={<CardStarTitle {...getStarTitleProps(context)} />}
        themeImage={themeImage}
        userAvatarList={
          <UserAvatarList
            {...getUserAvatarListProps(props, context, themeHashtag)}
            onClickMore={handleClickHiddenUsers}
          />
        }
      />
      {modal.isOpen && (
        <ModalInteractions
          defaultType={modal?.data?.defaultType}
          interactions={modal?.data?.interactions}
          onClose={modal.close}
          open={modal.isOpen}
        />
      )}
      {modalKudos.isOpen && (
        <UsersByKudosModal
          onClose={modalKudos.close}
          open
          {...modalKudos.data}
        />
      )}
    </>
  );
};

export default withStarConext(withRouter(KudosStar), 'KUDOS');
