// @app
import { errorHandler } from 'utils/errorHandler';
import { defaultApi } from 'utils/requestHelper';

export function getCommunityMemberMetrics({ userId, params }) {
  return defaultApi({
    url: `/data-service/api/v1/community/members/${userId}/metrics`,
    data: {},
    params,
  })
    .then((data) => data)
    .catch(errorHandler);
}
