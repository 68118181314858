import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_UNSUBSCRIBE_USER,
  GET_UNSUBSCRIBE_DATA,
} from './actionTypes';

import { getUnsubscribeData, createUnsubscribeUser } from './api';

import {
  createUnsubscribeUserFail,
  createUnsubscribeUserSuccess,
  getUnsubscribeDataFail,
  getUnsubscribeDataSuccess,
} from './actions';

function* getUnsubscribeDataWorker({ payload }) {
  try {
    const { apiUrl, token } = payload;
    const messages = yield call(getUnsubscribeData, { apiUrl, token });
    yield put(getUnsubscribeDataSuccess(messages));
  } catch (e) {
    yield put(getUnsubscribeDataFail(e));
  }
}

function* getUnsubscribeDataWatcher() {
  yield takeLatest(GET_UNSUBSCRIBE_DATA, getUnsubscribeDataWorker);
}

function* createUnsubscribeUserWorker({ payload }) {
  try {
    const data = yield call(createUnsubscribeUser, payload);
    yield put(createUnsubscribeUserSuccess(data));
  } catch (e) {
    yield put(createUnsubscribeUserFail(e));
  }
}

function* createUnsubscribeUserWatcher() {
  yield takeLatest(CREATE_UNSUBSCRIBE_USER, createUnsubscribeUserWorker);
}

export default { getUnsubscribeDataWatcher, createUnsubscribeUserWatcher };
