/**
 * Configure store to run sagas and async reducers
 */

import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import { browserHistory } from 'react-router';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const isDevelopment = process.env.NODE_ENV !== 'production';
const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}) {
  // Create the store with two middlewares
  // 1. routerMiddleware: Syncs the location/URL path to the state
  // NOTE: set sagas middleware here
  const middlewares = [routerMiddleware(browserHistory), sagaMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    isDevelopment && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers));

  if (isDevelopment && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer.js', () => {
      const nextReducer = require('./rootReducer').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  sagaMiddleware.run(rootSaga);

  return { store, history: syncHistoryWithStore(browserHistory, store) };
}
