import { apiCollaborationInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { getLabelFilters } from 'utils/discover';
import { trackEvent } from 'utils/gtm';

export const apiGetSegmentsFilters = () =>
  apiCollaborationInstance({
    data: {},
    headers: { 'Content-Type': 'application/json' },
    url: '/v1/segments/members/filters',
  })
    .then(({ data }) => data)
    .catch(errorHandler);

export const apiPostSegmentsFilters = ({ data, params }) =>
  apiCollaborationInstance({
    data,
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    params,
    url: '/v1/segments/search/members/me',
  })
    .then(({ data }) => ({
      ...data,
      result: data?.result?.map((r) => ({ ...r, profileImageCode: r.imageCode })),
    }))
    .catch((err) => {
      if (err.response.status === 406) {
        trackEvent({
          action: 'filters_reset',
          category: 'refine',
          label: getLabelFilters(data?.segmentFilters),
        });
        errorHandler(err, undefined, false);
      } else {
        errorHandler(err);
      }
    });
