export const ratios = {
  '1/1.2': {
    height: 240,
    width: 200,
  },
  '1/1': {
    height: 200,
    width: 200,
  },
};

export default function aspectRatio({ height, width }) {
  return width && height ? (height / width) * 100 : 0;
}
