// @ packages
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/Icon';
import { injectIntl } from 'react-intl';

// @ Own
import messages from './messages';
import './styles.scss';

const RemainingStarsCounter = ({
  intl: { formatMessage },
  remainingstars,
  totalStars,
}) => (
  <div className="remaining-stars-counter">
    <div className="remaining-stars-counter__title">
      {formatMessage(messages.remainingStarsCounterTitle)}
    </div>
    <div className="remaining-stars-counter__values-wrapper">
      <div className="remaining-stars-counter__remaining">
        {remainingstars}
      </div>
      <span className="remaining-stars-counter__separator">&#47;</span>
      <div className="remaining-stars-counter__total">
        {totalStars}
      </div>
      <Icon
        className="remaining-stars-counter__icon"
        color="white"
        icon="remaining-stars"
      />
    </div>
  </div>
);


RemainingStarsCounter.propTypes = {
  intl: PropTypes.object,
  remainingstars: PropTypes.number,
  totalStars: PropTypes.number,
};

export default injectIntl(RemainingStarsCounter);
