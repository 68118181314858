// @Packages
import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
// @App
import storeRef from 'utils/store';
// @Own
import {
  GET_INTERACTION_TIMELINE,
} from './actionTypes';
import { getInteractionTimelineData } from './api';
import { getTimelineSuccess, getTimelineFailed } from './actions';
import messages from './messages';
import { MONTHS } from './constants';

function* getInteractionWorker({ payload }) {
  try {
    const { interactionType, groupBy, period } = payload;
    const response = yield call(getInteractionTimelineData, { interactionType, groupBy, period });
    const interactions = response.reduce((interactions, interaction) => {
      const period = moment(interaction.period.value, 'YYYY-MM').month();
      const { i18n: { messages: storeMessages } } = storeRef().getState();
      const month = MONTHS[period + 1];
      const monthText = storeMessages[month.id] || messages[month.key].defaultMessage;
      const monthShortText = monthText.slice(0, 3).toUpperCase();
      interactions.periods = [...interactions.periods || [], monthShortText];
      interactions.member = [
        ...interactions.member || [],
        interaction['member.starmeup.*.sent.count'],
      ];
      interactions.community = [
        ...interactions.community || [],
        interaction['community.starmeup.*.sent.avg'],
      ];
      return interactions;
    }, {});
    const { community, periods, member } = interactions;
    yield put(getTimelineSuccess({ community, periods, member }));
  } catch (error) {
    yield put(getTimelineFailed({ message: error.message }));
  }
}

function* getInteractionWatcher() {
  yield takeLatest(GET_INTERACTION_TIMELINE, getInteractionWorker);
}

export default {
  getInteractionWatcher,
};
