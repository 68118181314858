// @packages
import { buildUrlWithParams } from 'smu-utils/url';

// @app
import { buildQueryParams } from 'utils/base64';

/**
 * Builds the query string for sending stars to users.
 *
 * @param {Array} users - The array of user objects.
 * @returns {string} - The built query string.
 */
export const buildSendStarQueryUsers = (users = []) => {
  const normalizedUsers = users?.map((user) => ({
    email: user.email,
    firstName: user.firstName,
    id: user.id,
    identification: user.identification,
    imageCode: user.imageCode,
    lastName: user.lastName,
  }));

  if (normalizedUsers?.length) {
    return buildQueryParams(normalizedUsers);
  }

  return '';
};

/**
 * Builds the URL for the send star modal with the given configuration.
 *
 * @param {Object} config - The configuration object.
 * @param {Object} config.params - The parameters to be included in the URL.
 * @param {string} config.pathname - The pathname for the URL.
 * @returns {string} The URL for the send star modal with the specified configuration.
 */
export const buildSendStarModalUrl = (config) => {
  const params = config?.params || {};
  const pathname = config?.pathname;

  if (params?.users?.length) {
    params.users = buildSendStarQueryUsers(params.users);
  }

  return buildUrlWithParams({ modal: 'send-star', ...params }, pathname);
};
