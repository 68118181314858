/* eslint-disable react/no-danger */
// @packages
import React, { useEffect } from 'react';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';
import moment from 'moment';
import { FormattedRelative } from 'react-intl';
import xss from 'xss';

// @app
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';
function Survey({
  appearance,
  badge,
  className,
  date,
  displayDismiss,
  onClick,
  onDismiss,
  ongoingSurveyMessage,
  questionnaireId,
  title,
  type,
  unseen,
}) {
  const getEventCategory = {
    AVAILABLE_SURVEYS: 'home_surveys_available',
    SURVEYS_RESULTS: 'home_surveys_results',
  };

  useEffect(() => {
    trackEvent({
      action: 'impression',
      category: getEventCategory[type],
      label: questionnaireId,
      q_date: moment(date).format('DD-MM-YYYY'),
      q_name: title,
    });
  }, []);

  return (
    <div
      className={cn(
        'survey rounded-md hover:bg-lavander hover:bg-opacity-40',
        'p-2 flex items-start cursor-pointer',
        { 'bg-lavander bg-opacity-20': unseen },
        className,
      )}
      onClick={onClick}
    >
      <div
        className="survey__badge flex-none"
        style={{ backgroundColor: appearance }}
      >
        {badge}
      </div>
      <div className="w-full">
        <Typography className="text-xs text-jumbo openSans font-semibold block">
          {date ? <FormattedRelative value={date} /> : ongoingSurveyMessage}
        </Typography>
        <Typography className="text-blue text-sm font-rubik font-medium block leading-4">
          <div
            dangerouslySetInnerHTML={{ __html: xss(title) }}
          />
        </Typography>
      </div>
      {displayDismiss && (
        <div className="survey__dismiss flex-none">
          <Icon
            className="survey__dismiss--icon"
            icon="close"
            onClick={onDismiss}
            size={12}
          />
        </div>
      )}
    </div>
  );
}

Survey.propTypes = {
  appearance: PropTypes.string,
  badge: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.string,
  displayDismiss: PropTypes.bool,
  onClick: PropTypes.func,
  questionnaireId: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default Survey;
