// Small mobiles
export const SCREEN_MOBILE_MIN = 576;
// Small tablets (portrait view)
export const SCREEN_TABLET_MIN = 768;
// Tablets and small desktops
export const SCREEN_DESKTOP_MIN = 992;
// Large tablets and desktops
export const SCREEN_DESKTOP_LARGE_MIN = 1280;
// Large desktops
export const SCREEN_DESKTOP_WIDE_MIN = 1440;
