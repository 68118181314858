import { defineMessages } from 'react-intl';

export default defineMessages({
  trendsEmptyState: {
    id: 'widgets.trendsEmptyState',
    defaultMessage: 'There are no trends',
  },
  trendsUses: {
    id: 'widgets.trendsUses',
    defaultMessage: '{count} uses',
  },
  trendingSponsored: {
    id: 'widget.trendingSponsored',
    defaultMessage: 'Sponsored',
  },
});
