// @packages
import { trackEvent } from 'smu-utils/gtm';

const sendEvent = (data) =>
  trackEvent({
    category: 'send_star',
    action: 'click',
    ...data,
  });

export const getEvents = (starName) => {
  const onClickConfirmationActionNo = () =>
    sendEvent({
      int_type: 'no',
      label: 'GPT_regenerate_modal',
      type: starName,
    });
  const onClickConfirmationActionYes = () =>
    sendEvent({
      label: 'GPT_regenerate_modal',
      int_type: 'yes',
      type: starName,
    });
  const onClickFirstStepAction = () =>
    sendEvent({ label: 'GPT_generate', type: starName });
  const onClickFirstStepText = () =>
    sendEvent({ label: 'GPT_text_box', type: starName });
  const onClickMainButton = () =>
    sendEvent({ label: 'GPT_icon', type: starName });
  const onClickSecondStepActionDiscard = () =>
    sendEvent({ label: 'GPT_discard', type: starName });
  const onClickSecondStepActionSetting = (s, o) =>
    sendEvent({
      label: 'GPT_filters',
      filter: `${s?.value} - ${o?.selected}`,
      type: starName,
    });
  const onClickSecondStepActionSettings = () =>
    sendEvent({ label: 'GPT_adjust', type: starName });
  const onClickSecondStepActionInsert = () =>
    sendEvent({ label: 'GPT_insert', type: starName });
  const onClickSecondStepActionSave = () =>
    sendEvent({ label: 'GPT_filters_update', type: starName });
  const onClickSecondStepActionUpdate = () =>
    sendEvent({ label: 'GPT_regenerate', type: starName });

  return {
    onClickConfirmationActionNo,
    onClickConfirmationActionYes,
    onClickFirstStepAction,
    onClickFirstStepText,
    onClickMainButton,
    onClickSecondStepActionDiscard,
    onClickSecondStepActionInsert,
    onClickSecondStepActionSave,
    onClickSecondStepActionSetting,
    onClickSecondStepActionSettings,
    onClickSecondStepActionUpdate,
  };
};
