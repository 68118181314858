import React, { useContext, createContext, useReducer } from 'react';

const TYPE_RESET_EXCLUDE_USERS = 'RESET_EXCLUDE_USERS';
const TYPE_SET_EXCLUDE_USERS = 'SET_EXCLUDE_USERS';
const TYPE_SET_FILTERS = 'SET_FILTERS';
const TYPE_SET_LARGE_RESULTS = 'SET_LARGE_RESULTS';
const discoverContext = createContext('discover');

const initialState = {
  excludeUsers: [],
  filters: [],
  largeResults: false,
  totalUsersSelected: 0,
};

const discoverReducer = (state, action) => {
  switch (action.type) {
    case TYPE_RESET_EXCLUDE_USERS:
      return {
        ...state,
        excludeUsers: [],
        totalUsersSelected: action.totalUsersSelected || 0,
      };
    case TYPE_SET_EXCLUDE_USERS:
      return {
        ...state,
        excludeUsers: action.excludeUsers,
        totalUsersSelected: action.totalUsersSelected,
      };
    case TYPE_SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case TYPE_SET_LARGE_RESULTS:
      return {
        ...state,
        largeResults: !!action.status,
      };
    default:
      throw new Error();
  }
};

function useProviderDiscover() {
  const [state, dispatch] = useReducer(discoverReducer, initialState);

  function handleResetExcludeUsers({ totalUsersSelected }) {
    dispatch({ type: TYPE_RESET_EXCLUDE_USERS, totalUsersSelected });
  }

  function handleSetExcludeUsers({ excludeUsers, totalUsersSelected }) {
    dispatch({ type: TYPE_SET_EXCLUDE_USERS, excludeUsers, totalUsersSelected });
  }

  function handleSetFilters(filters) {
    dispatch({ type: TYPE_SET_FILTERS, filters });
  }

  function handleSetLargeResults(status) {
    dispatch({ type: TYPE_SET_LARGE_RESULTS, status });
  }

  return {
    resetExcludeUsers: handleResetExcludeUsers,
    setExcludeUsers: handleSetExcludeUsers,
    setFilters: handleSetFilters,
    setLargeResults: handleSetLargeResults,
    ...state,
  };
}

export function ProvideDiscover({ children }) {
  const value = useProviderDiscover();
  return <discoverContext.Provider value={value}>{children}</discoverContext.Provider>;
}

export default function useDiscover() {
  return useContext(discoverContext);
}
