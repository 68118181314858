import {
  GET_FILTERS_BY_USES,
  GET_FILTERS_BY_USES_FAIL,
  GET_FILTERS_BY_USES_SUCCESS,
  UPDATE_FILTERS_VALUES,
} from './actionTypes';

export function getFiltersByUses(payload) {
  return {
    type: GET_FILTERS_BY_USES,
    payload,
  };
}

export function getFiltersByUsesFail(payload) {
  return {
    type: GET_FILTERS_BY_USES_FAIL,
    payload,
  };
}

export function getFiltersByUsesSuccess(payload) {
  return {
    type: GET_FILTERS_BY_USES_SUCCESS,
    payload,
  };
}

export function updateFiltersValues(payload) {
  return {
    type: UPDATE_FILTERS_VALUES,
    payload,
  };
}
