import { defineMessages } from 'react-intl';

export default defineMessages({
  congratulationFeedHeaderPrevious: {
    id: 'Congratulation.Feed.Header.Previous',
    defaultMessage: 'Profile',
  },
  congratulationFeedHeaderCurrent: {
    id: 'Congratulation.Feed.Header.Current',
    defaultMessage: 'Congratulations Cards',
  },
});
