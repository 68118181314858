// @packages
import React from 'react';
import { trackEvent } from 'smu-utils/gtm';

// @app
import ShareModal from 'containers/ShareModal';
import messages from 'containers/ShareModal/messages';
import { SMU_GENERIC_MODAL } from 'containers/GenericModal/constants';
import { TYPE_DNA } from 'containers/Share/constants';

function openShareDNA({ dispatch, formatMessage, onClose, openModal, query }) {
  return dispatch(
    openModal({
      modalType: SMU_GENERIC_MODAL,
      modalProps: {
        title: formatMessage(messages.shareModalTitleDNA),
        onClose,
        renderModal: (props) => (
          <ShareModal
            onShare={() =>
              trackEvent({
                action: 'share_success',
                category: 'dna',
                label: query?.opener,
              })
            }
            type={TYPE_DNA}
            {...props}
          />
        ),
      },
    }),
  );
}

export default openShareDNA;
