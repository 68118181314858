// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { getOrganizationValues } from './api';
import { REQUEST_ORGANIZATION_VALUES } from './actionTypes';
import { actionOrganizationValuesFail, actionOrganizationValuesSuccess } from './actions';

function* getStarsSummaryWorker({ id, payload }) {
  try {
    const { data } = yield call(getOrganizationValues, payload);
    yield put(actionOrganizationValuesSuccess(id, { result: data?.result }));
  } catch (error) {
    yield put(actionOrganizationValuesFail(id, { error }));
  }
}

export function* getStarsSummaryWatcher() {
  yield takeEvery(REQUEST_ORGANIZATION_VALUES, getStarsSummaryWorker);
}

export default {
  getStarsSummaryWatcher,
};
