import {
  GET_INTERACTION_TIMELINE_FAILED,
  GET_INTERACTION_TIMELINE_SUCCESS,
  GET_INTERACTION_TIMELINE,
  GET_INTERACTION_TIMELINE_NO_INTERACTION,
} from './actionTypes';

const initialState = {
  community: [],
  fetching: false,
  periods: [],
  shown: true,
  member: [],
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_INTERACTION_TIMELINE:
      return {
        ...state,
        fetching: true,
      };
    case GET_INTERACTION_TIMELINE_SUCCESS:
      return {
        ...state,
        fetching: false,
        community: payload.community,
        member: payload.member,
        periods: payload.periods,
      };
    case GET_INTERACTION_TIMELINE_FAILED:
      return {
        ...state,
        fetching: false,
        message: payload.message,
      };
    case GET_INTERACTION_TIMELINE_NO_INTERACTION:
      return {
        ...state,
        fetching: false,
        shown: false,
      };
    default:
      return state;
  }
}

export default reducer;
