// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'smu-ui-components/Panel';

// @own
import './styles.scss';

function ChillOutError({ subTitle, title }) {
  return (
    <Panel
      className="suite-widget-chillout-error"
      subtitle={subTitle}
      title={title}
    />
  );
}

ChillOutError.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ChillOutError;
