import { defineMessages } from 'react-intl';

export default defineMessages({
  noResults: {
    id: 'feed.noResults',
    defaultMessage: 'No available results',
  },
  personalizedFeedToggle: {
    id: 'feed.personalizedFeedToggle',
    defaultMessage: 'Sort by connections',
  },
  issueActivityFeed: {
    id: 'feed.issueActivityFeed',
    defaultMessage: 'Sorry, we are not able to load the activity feed.',
  },
  refreshActivityFeedLater: {
    id: 'feed.refreshActivityFeedLater',
    defaultMessage: 'Please try to refresh the page later.',
  },
});
