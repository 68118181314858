// @packages
import React, { useEffect } from 'react';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import UserProfile from 'smu-app-components/UserProfile';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { openModal } from 'smu-app-components/RootModalV2/actions';
import { trackEventSendStarFlowOpen } from 'smu-app-components/SendStar/analytics';
import { useMember } from 'smu-services/os-member/api/v1/community/member';
import { withRouter } from 'react-router';

// @app
import Link from 'components/Link';
import UploadProfileImageModal from 'containers/UploadProfileImageModal';
import {
  ENABLED_ANNIVERSARIES,
  ENABLED_BETHERE_FEED,
  ENABLED_BETTERME,
  ENABLED_BIRTHDAYS,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_REQUEST,
  ENABLED_SHARE_DNA,
  ENABLED_STARMEUP,
  ENABLED_SURVEYS_ONLY,
} from 'services/communityConfigs/constants';
import { SMU_GENERIC_MODAL } from 'containers/GenericModal/constants';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import {
  getCommunityConfigurationValue,
  selectSessionUserImageCode,
} from 'containers/Authorization/selectors';
import { selectProfileSentS, selectProfileStarsR } from 'containers/withProfile/selectors';
import { selectRemainingStarsCounter } from 'services/sendStarFlow/selectors';

// @own
import { helperActionsProps } from './helpers';

const UserProfileContainer = ({
  enabledAnniversaries,
  enabledBethere,
  enabledBetterme,
  enabledBirthdays,
  enabledOneOnOne,
  enabledOneOnOneRequest,
  enabledShareDNA,
  enabledStarMeUp,
  enabledSurveysOnly,
  enabledUploadPhoto,
  intl: { formatMessage },
  openModal,
  router,
  showPanel,
  showStars,
  userImageCode,
  ...rest
}) => {
  const member = useMember();
  const memberImageCode = member.selectImageCode();

  const handleChangePhoto = (e) => {
    const file = e?.target?.files?.[0];

    if (file) {
      openModal({
        modalType: SMU_GENERIC_MODAL,
        modalProps: {
          renderModal: (props) => <UploadProfileImageModal {...props} />,
          file,
        },
      });
    }
  };

  const onSendStar = () => {
    trackEventSendStarFlowOpen({
      int_type: 'side_bar',
      label: 'give_a_star',
    });

    router.push(buildSendStarModalUrl());
  };

  useEffect(() => {
    if (userImageCode && memberImageCode && userImageCode !== memberImageCode) {
      member.mutate();
    }
  }, [userImageCode, memberImageCode]);

  const actionsProps = helperActionsProps({
    enabledBethere,
    enabledBetterme,
    enabledOneOnOne,
    enabledOneOnOneRequest,
    enabledStarMeUp,
    formatMessage,
    member,
    onSendStar,
    owner: true,
  });

  const children = (
    <UserProfile
      actionsProps={actionsProps}
      components={{
        link: Link,
      }}
      enabledAnniversaries={enabledAnniversaries}
      enabledBethere={enabledBethere}
      enabledBetterme={enabledBetterme}
      enabledBirthdays={enabledBirthdays}
      enabledOneOnOne={enabledOneOnOne}
      enabledOneOnOneRequest={enabledOneOnOneRequest}
      enabledShareDNA={enabledShareDNA}
      enabledStarmeup={enabledStarMeUp}
      enabledSurveysOnly={enabledSurveysOnly}
      enabledUploadPhoto={enabledUploadPhoto}
      onChangePhoto={handleChangePhoto}
      showCulturalLevels={enabledStarMeUp}
      showQualifiers={false}
      showStars={enabledStarMeUp && showStars}
      starmeupUrl=""
      {...rest}
    />
  );

  if (showPanel) {
    return (
      <Paper className="py-4 px-3 w-full shadow-base smu-containers-user-profile">
        {children}
      </Paper>
    );
  }

  return children;
};

UserProfileContainer.defaultProps = {
  enabledAnniversaries: false,
  enabledBethere: false,
  enabledBetterme: false,
  enabledBirthdays: false,
  enabledOneOnOne: false,
  enabledOneOnOneRequest: false,
  enabledShareDNA: false,
  enabledStarMeUp: false,
  enabledSurveysOnly: false,
  enabledUploadPhoto: false,
  showPanel: false,
  useSendStarHandler: false,
};

UserProfileContainer.propTypes = {
  enabledAnniversaries: PropTypes.bool,
  enabledBethere: PropTypes.bool,
  enabledBetterme: PropTypes.bool,
  enabledBirthdays: PropTypes.bool,
  enabledOneOnOne: PropTypes.bool,
  enabledOneOnOneRequest: PropTypes.bool,
  enabledShareDNA: PropTypes.bool,
  enabledStarMeUp: PropTypes.bool,
  enabledSurveysOnly: PropTypes.bool,
  enabledUploadPhoto: PropTypes.bool,
  intl: PropTypes.object,
  openModal: PropTypes.func,
  router: PropTypes.object,
  showPanel: PropTypes.bool,
  useSendStarHandler: PropTypes.bool,
  userImageCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  enabledAnniversaries: getCommunityConfigValueBool(state, ENABLED_ANNIVERSARIES),
  enabledBethere: getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED),
  enabledBetterme: getCommunityConfigValueBool(state, ENABLED_BETTERME),
  enabledBirthdays: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
  enabledOneOnOne: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
  enabledOneOnOneRequest: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE_REQUEST),
  enabledShareDNA: getCommunityConfigValueBool(state, ENABLED_SHARE_DNA)
    && getCommunityConfigurationValue(state, 'shareOn'),
  enabledUploadPhoto: getCommunityConfigurationValue(state, 'changeProfilePicture'),
  enabledStarMeUp: getCommunityConfigValueBool(state, ENABLED_STARMEUP),
  enabledSurveysOnly: getCommunityConfigValueBool(state, ENABLED_SURVEYS_ONLY),
  starCounters: {
    received: selectProfileStarsR(state),
    remaining: selectRemainingStarsCounter(state),
    sent: selectProfileSentS(state),
  },
  userImageCode: selectSessionUserImageCode(state),
});

export default connect(mapStateToProps, {
  openModal,
})(withRouter(injectIntl(UserProfileContainer)));
