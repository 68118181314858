// @packages
import React from 'react';
import ModalOneOnOneForm from 'smu-app-components/OneOnOne/Form/Modal';
import UtilsProvider from 'smu-app-components/UtilsProvider';
import { useSelector } from 'react-redux';

// @app
import {
  CALENDAR_PROVIDER,
  ENABLED_ONE_ON_ONE,
} from 'services/communityConfigs/constants';
import {
  getCommunityConfigValue,
  getCommunityConfigValueBool,
} from 'services/communityConfigs/selectors';

const ModalOneOnOneFormContainer = props => {
  const [enabledOneOnOne, calendarProvider] = useSelector(
    state => [
      getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
      getCommunityConfigValue(state, CALENDAR_PROVIDER),
    ],
  );

  const utils = {
    calendarProvider,
    enableSyncWithCalendar: true,
    mainFeatureIsEnabled: enabledOneOnOne,
  };

  return (
    <UtilsProvider utils={utils}>
      <ModalOneOnOneForm {...props} />
    </UtilsProvider>
  );
};

export default ModalOneOnOneFormContainer;
