// @packages
import React, { Component } from 'react';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';
import Segment from 'smu-ui-components/Segment';
import cn from 'classnames';
import find from 'lodash/find';
import keys from 'lodash/keys';
import some from 'lodash/some';
import times from 'lodash/times';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { withRouter } from 'react-router';

// @app
import { formatBoldText } from 'utils/boldText';
import { formatHashtags, interpolateVariables } from 'utils/formatString';
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';
import * as actions from './actions';
import LivingValueView from './LivingValueView';
import LivingValuesSimpleView from './LivingValueSimpleView';
import PickUser from './PickUser';
import SendStarView from './SendStarView';
import { LIVING_VALUES_MODAL } from './constants';

class LivingValues extends Component {
  constructor(props) {
    super(props);
    this.closeButton = this.closeButton.bind(this);
    this.handleCloseButton = this.handleCloseButton.bind(this);
    this.handleDismissButton = this.handleDismissButton.bind(this);
    this.handleNextButton = this.handleNextButton.bind(this);
    this.handleOnCardClick = this.handleOnCardClick.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleOnStartGame = this.handleOnStartGame.bind(this);
    this.handleRemindLaterButton = this.handleRemindLaterButton.bind(this);
    this.handleSendStarButton = this.handleSendStarButton.bind(this);
  }

  componentDidMount() {
    const {
      closeLivingValues,
      getLivingValuesTexts,
      livingValues: { texts },
      livingValuesEnabled,
      onClose,
      router,
      startLivingValues,
    } = this.props;
    const hasLivingValuesTexts = !!keys(texts).length;
    getLivingValuesTexts({
      languageCode: getUserLanguage(),
      name: 'living_values',
    });
    startLivingValues(router);
    if (!hasLivingValuesTexts || !livingValuesEnabled) {
      onClose();
      closeLivingValues();
    }
  }

  closeButton() {
    const handleClose = () => {
      const {
        onClose,
        closeLivingValues,
        livingValues: { currentView },
      } = this.props;
      if (currentView !== 'pickUser') {
        onClose();
        closeLivingValues();
      }
    };
    return (
      <div className="living-values-modal__close-wrapper">
        <Icon
          className="living-values-modal__close-icon"
          icon="close"
          size="small"
          color="white"
          onClick={handleClose}
        />
      </div>
    );
  }

  handleOnCardClick(star) {
    const {
      goToView,
      livingValues: { usersSelected },
      pickLivingValue,
      removeSelectedUser,
    } = this.props;
    const {
      backgroundColor,
      id,
      imageCode,
      nameShowed,
    } = star;
    const oldUserPicked = find(usersSelected, { starId: id });
    if (oldUserPicked) {
      removeSelectedUser(id);
    }
    pickLivingValue(id, nameShowed, backgroundColor, imageCode);
    goToView('pickUser');
  }

  handleOnClose() {
    const { goToView } = this.props;
    goToView('pickValue');
  }

  handleOnStartGame() {
    const { goToView } = this.props;
    trackEvent({
      category: 'LivingValue',
      action: 'LivingValue_Start',
    });
    goToView('pickValue');
  }

  handleRemindLaterButton() {
    const { onClose, remindLivingValues } = this.props;
    trackEvent({
      category: 'LivingValue',
      action: 'LivingValue_RemindLater',
    });
    onClose();
    remindLivingValues();
  }

  handleDismissButton() {
    const { onClose, dismissLivingValues } = this.props;
    trackEvent({
      category: 'LivingValue',
      action: 'LivingValue_Dismiss',
    });
    onClose();
    dismissLivingValues();
  }

  handleNextButton() {
    const { goToView } = this.props;
    trackEvent({
      category: 'LivingValue',
      action: 'LivingValue_Next',
    });
    goToView('sendStars');
  }

  handleSendStarButton() {
    const {
      livingValues: { usersSelected },
      sendLivingValues,
    } = this.props;
    trackEvent({
      category: 'LivingValue',
      action: 'LivingValue_SendStars',
    });
    sendLivingValues(usersSelected);
  }

  handleCloseButton() {
    const { onClose, closeLivingValues } = this.props;
    trackEvent({
      category: 'LivingValue',
      action: 'LivingValue_CloseFinal',
    });
    onClose();
    closeLivingValues();
  }

  render() {
    const {
      changeStarMessage,
      coreValues,
      defaultProfileImageCode,
      goToView,
      isLivingValuesDescriptionMandatory,
      livingValues,
      livingValuesEnabled,
      pickUser,
    } = this.props;
    const {
      currentView,
      fetching,
      fetchingValues,
      texts,
      usersSelected,
      valueSelected,
      valuesDone,
    } = livingValues;

    const isLoading = fetching || fetchingValues;
    let disabledSendButton = false;

    if (currentView === 'sendStars' && isLivingValuesDescriptionMandatory) {
      disabledSendButton = some(usersSelected, { customMessage: '' });
    }

    return livingValuesEnabled && (
      <div className="living-values-modal">
        <Segment
          className={cn('living-values-modal__segment', {
            'living-values-modal__segment--loading': isLoading,
          })}
          loading={isLoading}
          loadingType="starmeup"
          withChildren={false}
        >
          {currentView === 'start' && (
            <LivingValuesSimpleView
              buttonText={texts.startButton}
              description={texts.homeDescription}
              dismissText={texts.dismissButton}
              onButtonClick={this.handleOnStartGame}
              onDismissClick={this.handleDismissButton}
              onRemindClick={this.handleRemindLaterButton}
              rememberText={texts.remindButton}
              title={texts.menuTitle}
            />
          )}
          {currentView === 'pickValue' &&
            <LivingValueView
              buttonText={texts.continueButton}
              closeButton={this.closeButton}
              defaultProfileImageCode={defaultProfileImageCode}
              description={texts.selectedValuesScreenDescription}
              onButtonClick={this.handleNextButton}
              onCardClick={this.handleOnCardClick}
              stars={coreValues}
              title={texts.menuTitle}
              usersSelected={usersSelected}
              valuesDone={valuesDone}
            />
          }
          {currentView === 'pickUser' &&
            <PickUser
              coreValue={valueSelected}
              defaultStarMessage={formatHashtags(texts.defaultStarMessage || '')}
              goToView={goToView}
              onClose={this.handleOnClose}
              onUserClick={this.handlePickUser}
              pickUser={pickUser}
            />
          }
          {currentView === 'sendStars' &&
            <SendStarView
              buttonDisabled={disabledSendButton}
              buttonText={
                usersSelected.length > 1
                  ? (texts.sendStarsButton &&
                    interpolateVariables(
                      texts.sendStarsButton,
                      { N: usersSelected.length },
                    ))
                  : (texts.sendStarButton)
              }
              changeStarMessage={changeStarMessage}
              closeButton={this.closeButton}
              coreValues={coreValues}
              description={texts.sendStarsScreenDescription}
              onButtonClick={this.handleSendStarButton}
              placeholder={texts.hint}
              title={texts.menuTitle}
              usersSelected={usersSelected}
            />
          }
          {currentView === 'end' && (
            <LivingValuesSimpleView
              buttonText={texts.closeButton && texts.closeButton.toUpperCase()}
              description={formatBoldText(texts.successScreenDescription)}
              onButtonClick={this.handleCloseButton}
              title={texts.successScreenTitle}
            />
          )}
          <div className="stars">
            {times(100, () => (
              <div className="container">
                <div className="star" />
              </div>
            ))}
          </div>
        </Segment>
      </div>
    );
  }
}

LivingValues.defaultProps = {
  isLivingValuesDescriptionMandatory: true,
  onClose: () => {},
};

LivingValues.propTypes = {
  changeStarMessage: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  closeLivingValues: PropTypes.func.isRequired,
  coreValues: PropTypes.array.isRequired,
  defaultProfileImageCode: PropTypes.string,
  dismissLivingValues: PropTypes.func.isRequired,
  getLivingValuesTexts: PropTypes.func.isRequired,
  goToView: PropTypes.func.isRequired,
  isLivingValuesDescriptionMandatory: PropTypes.bool,
  livingValues: PropTypes.object,
  livingValuesEnabled: PropTypes.bool,
  onClose: PropTypes.func,
  pickLivingValue: PropTypes.func.isRequired,
  pickUser: PropTypes.func.isRequired,
  remindLivingValues: PropTypes.func.isRequired,
  removeSelectedUser: PropTypes.func.isRequired,
  sendLivingValues: PropTypes.func.isRequired,
  valuesDone: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    livingValues,
    session: {
      communityConfigurations: {
        defaultProfileImageCode,
        isLivingValuesDescriptionMandatory,
        livingValuesEnabled,
      },
      stars,
    },
  } = state;
  const coreValues = stars.filter(star => !star.isKudos);
  return {
    coreValues,
    defaultProfileImageCode,
    isLivingValuesDescriptionMandatory,
    livingValues,
    livingValuesEnabled,
  };
};

const connectedAndIntl = connect(mapStateToProps, actions)(withRouter(LivingValues));

export default RootModal(connectedAndIntl, LIVING_VALUES_MODAL, { width: '415px' }, 'living-values-modal__modal');
