// @own
import {
  REQUEST_ADVANCED_USER_SEARCH,
  REQUEST_ADVANCED_USER_SEARCH_SUCCESS,
  REQUEST_ADVANCED_USER_SEARCH_FAIL,
} from './actionTypes';

const initialState = {
  error: null,
  filters: {},
  requesting: false,
  result: undefined,
  success: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_ADVANCED_USER_SEARCH:
      return {
        ...state,
        filters: payload?.data,
        requesting: true,
        result: payload?.data?.page ? state.result : undefined,
      };
    case REQUEST_ADVANCED_USER_SEARCH_SUCCESS: {
      return {
        ...state,
        requesting: false,
        result: {
          ...payload?.result,
          content: [
            ...(state?.result?.content || []),
            ...(payload?.result?.content || []),
          ],
        },
        success: true,
      };
    }
    case REQUEST_ADVANCED_USER_SEARCH_FAIL:
      return {
        ...state,
        error: payload?.error,
        requesting: false,
        success: false,
      };
    default:
      return state;
  }
}
