import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'modalInteractions.title',
    defaultMessage: 'Interactions',
  },
  all: {
    id: 'modalInteractions.all',
    defaultMessage: 'All',
  },
});
