import { defaultApi } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function apiAddComment({
  comment,
  sourceId,
  sourceType,
  toUserId,
}) {
  const data = {
    application: 'Interaction',
    data: { comment, toUserId },
    sourceId,
    sourceType,
    type: 'COMMENT',
  };

  return defaultApi({
    data,
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    url: 'interactions/api/v1/community/members/activities/reactions',
  }).catch(errorHandler);
}
