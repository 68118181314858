import React from 'react';

export const formatBoldText = (text) => {
  const splittedText = text.split(/(\*\*)/g);
  let openTag = true;
  const formattedText = splittedText.map((string) => {
    const tag = openTag ? '<b>' : '</b>';

    if (string === '**') {
      openTag = !openTag;
      return tag;
    }

    return string;
  }, '');
  const markup = { __html: formattedText.join('') };
  return <span dangerouslySetInnerHTML={markup} />; //eslint-disable-line
};
