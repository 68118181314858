// @Packages
import moment from 'moment';
// @App
import { dataServiceInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams } from './../../api';
// @Own
import { INTERACTION_CHART_PAGE, INTERACTION_CHART_PAGE_SIZE } from './constants';

export function getInteractionTimelineData({ interactionType, groupBy, period }) {
  return dataServiceInstance({
    url: addParams('/v1/community/member/connections', {
      interactionType,
      from: moment(Date.now()).subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
      groupBy,
      page: INTERACTION_CHART_PAGE,
      pageSize: INTERACTION_CHART_PAGE_SIZE,
      period,
      to: moment(Date.now()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    }),
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}
