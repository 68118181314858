// @packages
import React from 'react';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';

// @app
import SkeletonCommentInput from 'components/Skeletons/CommentInput';
import SkeletonCommentList from 'components/Skeletons/CommentList';

// @own
import './styles.scss';

function SkeletonMediaItem({ showComments, className }) {
  return (
    <Panel
      className={cn('skeleton-media-item rounded-lg border-none overflow-hidden shadow-base', className, {
        'skeleton-media-item--show-comments': showComments,
      })}
      noPadding
    >
      <header className="skeleton-media-item__header">
        <Skeleton
          className="skeleton-media-item__date"
          animation="wave"
          height={10}
          width={60}
        />
      </header>
      <div className="skeleton-media-item__media">
        <Skeleton animation="wave" height="100%" variant="rect" className="rounded-lg" />
      </div>
      <div className="skeleton-media-item__description">
        <Skeleton animation="wave" height={10} width="85%" />
      </div>
      <footer className="skeleton-media-item__footer">
        <Skeleton
          animation="wave"
          className="skeleton-media-item__avatar"
          height={32}
          variant="circle"
          width={32}
        />
        <Skeleton
          animation="wave"
          className="skeleton-media-item__username"
          height={10}
          width={170}
        />
        <div className="skeleton-media-item__actions">
          <Skeleton animation="wave" height={18} width={20} />
          <Skeleton animation="wave" height={18} width={20} />
        </div>
      </footer>
      {showComments && (
        <div className="skeleton-media-item__comments">
          <SkeletonCommentInput />
          <SkeletonCommentList total={2} />
        </div>
      )}
    </Panel>
  );
}

SkeletonMediaItem.defaultProps = {
  showComments: false,
};

SkeletonMediaItem.propTypes = {
  showComments: PropTypes.bool,
};

export default SkeletonMediaItem;
