import { defineMessages } from 'react-intl';

export default defineMessages({
  interactionHighchartYText: {
    id: 'interactionHighchart.ytext',
    defaultMessage: 'Stars, Comments, Likes',
  },
  interactionHighchartMainLine: {
    id: 'interactionHighchart.mainLine',
    defaultMessage: 'Me',
  },
  interactionHighchartShadowLine: {
    id: 'interactionHighchart.shadowLine',
    defaultMessage: 'Average community interactions',
  },
  interactionHighchartJanuary: {
    id: 'interactionHighchart.january',
    defaultMessage: 'January',
  },
  interactionHighchartFebruary: {
    id: 'interactionHighchart.february',
    defaultMessage: 'February',
  },
  interactionHighchartMarch: {
    id: 'interactionHighchart.march',
    defaultMessage: 'March',
  },
  interactionHighchartApril: {
    id: 'interactionHighchart.april',
    defaultMessage: 'April',
  },
  interactionHighchartMay: {
    id: 'interactionHighchart.may',
    defaultMessage: 'May',
  },
  interactionHighchartJune: {
    id: 'interactionHighchart.june',
    defaultMessage: 'June',
  },
  interactionHighchartJuly: {
    id: 'interactionHighchart.july',
    defaultMessage: 'July',
  },
  interactionHighchartAugust: {
    id: 'interactionHighchart.august',
    defaultMessage: 'August',
  },
  interactionHighchartSeptember: {
    id: 'interactionHighchart.september',
    defaultMessage: 'September',
  },
  interactionHighchartOctober: {
    id: 'interactionHighchart.october',
    defaultMessage: 'October',
  },
  interactionHighchartNovember: {
    id: 'interactionHighchart.november',
    defaultMessage: 'November',
  },
  interactionHighchartDecember: {
    id: 'interactionHighchart.december',
    defaultMessage: 'December',
  },
  interactionHighchartTitle: {
    id: 'interactionHighchart.title',
    defaultMessage: 'Interactions',
  },
});
