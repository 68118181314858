import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from './../../api';

export function getBadges({ userId }) {
  const baseUrl = `/v2/achievements/badges/${userId}`;
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times() }),
    method: 'get',
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}
