import { takepartApiInstance } from 'utils/requestHelper';
import errorHandler from 'components/Questionnaires/utils/errorHandler';

export function apiVoteQuestion({
  data,
  lang,
  orgId,
  questionId,
  questionnaireId,
}) {
  return takepartApiInstance({
    data,
    headers: {
      'Accept-Language': lang,
    },
    method: 'post',
    url: `organization/v2/${orgId}/questionnaires/${questionnaireId}/questions/${questionId}`,
  }).then(({ data }) => data);
}

export function apiGetQuestionById({ orgId, questionnaireId, lang, questionId }) {
  return takepartApiInstance({
    headers: {
      'Accept-Language': lang,
    },
    url: `organization/v2/${orgId}/questionnaires/${questionnaireId}`,
    params: {
      questionId,
    },
  }).then(({ data }) => ({
    ...data,
    data: {
      ...data.data,
      finished: questionId === data?.data?.question.prevQuestionId,
    },
  }));
}

export async function apiVote({ error, questionId, lang, ...rest }) {
  try {
    const voteData = await apiVoteQuestion({ questionId, lang, ...rest });
    const questionData = await apiGetQuestionById({
      ...rest,
      lang,
      questionId: voteData.nextQuestionId,
    });

    const isLastQuestion = questionId === voteData.nextQuestionId;
    const isSubQuestion = questionData?.data?.question?.isSubQuestion;

    return {
      ...questionData,
      data: {
        ...questionData?.data,
        finished: voteData?.finished,
        question: {
          ...questionData?.data?.question,
          prevQuestionId: isLastQuestion && !isSubQuestion
            ? questionId
            : questionData?.data?.question?.prevQuestionId,
        },
      },
    };
  } catch (err) {
    return errorHandler(err);
  }
}
