// @packages
import React, { useEffect } from 'react';
import ExternalUrl from 'smu-app-components/ExternalUrl';
import PropTypes from 'prop-types';
import { trackPageview } from 'smu-utils/gtm';

function RedirectLayout({
  location: {
    query: { link },
    pathname,
  },
}) {
  useEffect(() => {
    trackPageview({
      pageName: pathname,
    });
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center">
      <ExternalUrl className="max-w-lg w-full" continueUrl={link} />
    </div>
  );
}

RedirectLayout.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      link: PropTypes.string,
    }),
  }).isRequired,
  router: PropTypes.object.isRequired,
};

export default RedirectLayout;
