// @Packages
import React from 'react';
import PropTypes from 'prop-types';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Button from 'smu-ui-components/Button';
import { closeModal } from 'smu-app-components/RootModalV2/actions';
import cn from 'classnames';
import { connect } from 'react-redux';
import Icon from 'smu-ui-components/Icon';
import iconAlert from 'smu-app-components/ConfirmationModalV2/iconAlert.png';
import RootModal from 'smu-app-components/RootModalV2';

const ErrorModal = ({
  closeModal,
  description,
  footerText,
  title,
  users,
}) => {
  const modalFooter = () => (
    <div className="send-star-error__footer">
      <img src={iconAlert} className="send-star-error__footer-message-icon" alt="iconAlert" />
      <span className="send-star-error__footer-message">
        {footerText}
      </span>
    </div>
  );

  const modalHeader = () => (
    <div className="send-star-error__header">
      <div className="send-star-error__header-title">
        {title}
      </div>
      <Button
        className="send-star-error__header-icon-wrapper"
        icon
        noMargin
        noPadding
        onClick={() => closeModal()}
        role="button"
        transparent
      >
        <Icon
          className="send-star-error__header-icon"
          color="black"
          icon="close"
          size="semi-medium"
        />
      </Button>
    </div>
  );

  return (
    <div className="send-star-error">
      {modalHeader()}
      <div className="send-star-error__content">
        <div className="send-star-error__content-description">{description}</div>
        <div className={cn({ 'send-star-error__content-user-list': users.length > 0 })} >
          {users.map((value, index) => (
            <AvatarMedia
              className="send-star-error__content-user"
              firstName={value.identification}
              job="-"
              key={value.identification + index}
              noHash
              onClick={() => {}}
              profileImageCode={value.imageCode}
            />))}
        </div>
      </div>
      {footerText && modalFooter()}
    </div>
  );
};

ErrorModal.propTypes = {
  closeModal: PropTypes.func,
  description: PropTypes.string,
  footerText: PropTypes.string,
  title: PropTypes.string,
  users: PropTypes.array,
};

const mapDispatchToProps = {
  closeModal,
};

const ConnectedErrorModal = connect(null, mapDispatchToProps)(ErrorModal);

export default RootModal(ConnectedErrorModal, 'SENT_STAR_ERROR');
