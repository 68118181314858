import keys from 'lodash/keys';

export const normaliceFilters = (filters) => {
  const filterIds = keys(filters);
  const normalicedFilters = filterIds.map(key => ({
    id: key,
    ...filters[key],
  }));
  return normalicedFilters;
};
