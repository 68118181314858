// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Icon from 'smu-ui-components/Icon';

// @own
import './styles.scss';
import {
  CULTURAL_INDICATOR_UP,
  CULTURAL_INDICATOR_SAME,
  CULTURAL_INDICATOR_DOWN,
} from './constants';
import messages from '../messages';

function CulturalIndicator({
  culturalIndicator,
  culturalEvolution,
  intl: { formatMessage },
}) {
  const culturalIndicatorEvolution =
    <div className="insights-content__cultural-indicator">
      {culturalEvolution?.value === CULTURAL_INDICATOR_UP && (
        <Icon
          className="insights-content__cultural-indicator--arrow"
          color="teal"
          icon="arrow-up"
          size="jumbo"
        />
      )}
      {culturalEvolution?.value === CULTURAL_INDICATOR_SAME && (
        <Icon
          className="insights-content__cultural-indicator--arrow"
          color="black"
          icon="minus"
          size="jumbo"
        />
      )}
      {culturalEvolution?.value === CULTURAL_INDICATOR_DOWN && (
        <Icon
          className="insights-content__cultural-indicator--arrow"
          color="red"
          icon="arrow-down"
          size="jumbo"
        />
      )}
      <div className="insights-content__cultural-indicator--description">
        {messages[`${culturalEvolution?.description}`]
            ? formatMessage(messages[`${culturalEvolution?.description}`])
            : culturalEvolution?.description}
      </div>
    </div>;

  return (
    <div className="insights-content__cultural">
      <div className="insights-content__cultural-title">
        {messages[`${culturalIndicator?.value}`]
          ? formatMessage(messages[`${culturalIndicator?.value}`])
          : culturalIndicator?.value}
      </div>
      <div className="insights-content__cultural-description">
        {messages[`${culturalIndicator?.description}`]
        ? formatMessage(messages[`${culturalIndicator?.description}`])
        : culturalIndicator?.description}
      </div>
      {culturalEvolution && culturalIndicatorEvolution}
    </div>
  );
}

CulturalIndicator.propTypes = {
  culturalIndicator: PropTypes.object,
};

export default injectIntl(CulturalIndicator);
