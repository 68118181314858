import {
  CHANGE_PROFILE_VIEW,
} from './actionTypes';

export function goToProfileView(view) {
  return {
    type: CHANGE_PROFILE_VIEW,
    payload: { view },
  };
}

