import { cloneDeep, set } from 'lodash';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { browserHistory } from 'react-router';
import addToast from 'utils/defaultToastAdd';
import messages from 'components/FeedItem/messages';
import storeRef from 'utils/store';
import { selectIsInteractionEnabled } from './selectors';
import {
  getProfile as getProfileAPI,
  getReceivedStars as getReceivedStarsAPI,
  getSentStars as getSentStarsAPI,
  getReceivedStarsDetail as getReceivedStarsDetailAPI,
  getEarnedBadges as getEarnedBadgesAPI,
  getUsersInteraction as getUsersInteractionAPI,
} from './api';
import {
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  REQUEST_EARNED_BADGES_SUCCESS,
  REQUEST_RECEIVED_STARS_DETAIL_SUCCESS,
  REQUEST_RECEIVED_STARS_SUCCESS,
  REQUEST_SENT_STARS,
  REQUEST_SENT_STARS_FAILED,
  REQUEST_SENT_STARS_SUCCESS,
  REQUEST_USERS_INTERACTION_SUCCESS,
} from './actionTypes';
import {
  getReceivedStars,
  getSentStars,
  getEarnedBadges,
  getUsersInteraction,
} from './actions';

function* fetchProfileWorker({ payload }) {
  try {
    const {
      uid, isProfile, feedSetFields,
    } = payload;
    const data = yield call(getProfileAPI, uid);
    if (!data.enabled) {
      const { i18n: { messages: storeMessages } } = storeRef().getState();
      yield put(addToast({
        message: storeMessages['profile.inactiveUserError'] || messages.inactiveUser.defaultMessage,
      }));
      browserHistory.push('/');
      throw new Error('User is inactive');
    }

    yield put({ type: GET_PROFILE_SUCCESS, payload: data });

    const { id } = data;
    yield put(getReceivedStars());
    const receivedStars = yield call(getReceivedStarsAPI, id);
    yield put({ type: REQUEST_RECEIVED_STARS_SUCCESS, payload: receivedStars });

    yield put(getSentStars({ id }));

    if (isProfile) {
      const interactionEnabled = yield select(selectIsInteractionEnabled);
      if (interactionEnabled) {
        yield put(getUsersInteraction(id));
        const usersInteraction = yield call(getUsersInteractionAPI, id);
        yield put({ type: REQUEST_USERS_INTERACTION_SUCCESS, payload: usersInteraction });
      }
    }
    const receivedStarsDetail = yield call(getReceivedStarsDetailAPI, uid);
    yield put({ type: REQUEST_RECEIVED_STARS_DETAIL_SUCCESS, payload: receivedStarsDetail });

    yield put(getEarnedBadges());
    const badges = yield call(getEarnedBadgesAPI, id);
    yield put({ type: REQUEST_EARNED_BADGES_SUCCESS, payload: badges });

    if (feedSetFields) {
      yield put(set(cloneDeep(feedSetFields), 'payload.options.userId', id));
    }
  } catch (e) {
    yield put({ type: GET_PROFILE_FAIL, payload: e });
  }
}

function* fetchSendStarsWorker() {
  try {
    const userId = yield select(state => state.session.user.id);
    const sentStars = yield call(getSentStarsAPI, userId);
    yield put({ type: REQUEST_SENT_STARS_SUCCESS, payload: sentStars });
  } catch (error) {
    yield put({ type: REQUEST_SENT_STARS_FAILED });
  }
}

function* fetchSendStarsWatcher() {
  yield takeEvery(REQUEST_SENT_STARS, fetchSendStarsWorker);
}

function* fetchProfileWatcher() {
  yield takeEvery(GET_PROFILE, fetchProfileWorker);
}

export default {
  fetchProfileWatcher,
  fetchSendStarsWatcher,
};
