// @app
import { paths } from 'utils/routes';

// @own
import ShareLayout from './ShareLayout';

export default () => ({
  component: ShareLayout,
  path: paths.shareType(),
});
