import { defineMessages } from 'react-intl';

export default defineMessages({
  FeedbackNotificationsMessageToast: {
    id: 'sendStar.successfulySent',
    defaultMessage: 'STAR SUCCESSFULLY SENT!',
  },
  NotificationsEmptyList: {
    id: 'navbar.emptyNotifications',
    defaultMessage: "You don't have notifications yet.",
  },
  NotificationsEmptyMessage: {
    id: 'navbar.emptyMessage',
    defaultMessage: 'It seems calm over here, you could start by recognizing someone.',
  },
  NotificationsNoMoreItems: {
    id: 'navbar.noMoreNotifications',
    defaultMessage: 'No more notifications to show',
  },
  NotificationHistoryButton: {
    id: 'navbar.notificationHistoryButton',
    defaultMessage: 'View All',
  },
  NotificationHistoryOlder: {
    id: 'navbar.notificationHistoryOlder',
    defaultMessage: 'OLDER NOTIFICATIONS',
  },
  NotificationsTitle: {
    id: 'navbar.notifications',
    defaultMessage: 'NOTIFICATIONS',
  },
  NotificationsUnseen: {
    id: 'navbar.notificationUnseen',
    defaultMessage: 'You have {counter} unseen notifications',
  },
});
