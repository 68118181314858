// @packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getWebUrl } from 'smu-utils/globalRequestHelper';

// @app
import { externalLinkRedirect } from 'utils/external';

const Layout = ({ location }) => {
  useEffect(() => {
    externalLinkRedirect(
      `${getWebUrl('login')}${location?.pathname}${location?.search}`,
    );
  }, []);

  return null;
};

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

export default Layout;
