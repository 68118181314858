export const b64EncodeUnicode = (str) => window.btoa(encodeURIComponent(str));

export const b64DecodeUnicode = (str) => window.atob(decodeURIComponent(str));

export const getB64EncodeUnicode = (str) => {
  try {
    return b64EncodeUnicode(str);
  } catch {
    return undefined;
  }
};

export const getB64DecodeUnicode = (str) => {
  try {
    return b64DecodeUnicode(str);
  } catch {
    return undefined;
  }
};

export const buildQueryParams = (param) => {
  const paramString = JSON.stringify(param);
  const base64param = window.btoa(paramString);
  return base64param;
};

export const decodeQueryParams = (param) => {
  const base64param = window.atob(param);
  const paramString = JSON.parse(base64param);
  return paramString;
};
