// @packages
import Axios from 'axios';

// @app
import { createInstance } from 'smu-utils/globalRequestHelper';
import { getItem, getToken, setItem, getEnv } from 'smu-utils/storage';
import { getUserLanguage } from 'smu-utils/language';

// @own
import { errorSanitizer } from 'utils/errorSanitizer';
import { FAIL } from 'utils/constants';
import { vo } from 'api';

// wrapper api instance
const instanceWrapper = instance => (...rest) =>
  new Promise((resolve, reject) => {
    instance(...rest)
      .then((result) => {
        const data = result?.data;
        if (data.status === FAIL) {
          const errorInfo = errorSanitizer(data);
          reject(errorInfo);
        } else {
          resolve(result);
        }
      })
      .catch(err => reject(err));
  });

// Constants
const { o: origin, v: version } = vo;
const ENV = window.location.host.split('.')[0];

// Beta force cookie
if (ENV === 'labs') {
  const apiUrl = getItem('apiUrl', { domain: 'starmeup.com' });
  if (apiUrl !== 'https://testing-ecs-smu-api.starmeup.com/starmeup-api') {
    setItem('apiUrl', 'https://testing-ecs-smu-api.starmeup.com/starmeup-api', { domain: 'starmeup.com' });
    window.location.reload();
  }
}

const ADMIN_URL = {
  qa: 'https://os-admin-qa.starmeup.com/',
  uat: 'https://os-admin-uat.starmeup.com/',
  prod: 'https://os-admin.starmeup.com/',
};

const HOST = ENV.includes('localhost')
  ? 'dev' : ENV.includes('qa')
    ? 'qa' : ENV.includes('uat')
      ? 'uat' : 'prod';

const SMU_URL = {
  dev: 'https://qa.starmeup.com',
  qa: 'https://qa.starmeup.com',
  uat: 'https://uat.starmeup.com',
  prod: 'https://www.starmeup.com',
};

const baseUrl = getItem('baseUrl') || SMU_URL[HOST];

// Instances
const apiInstanceNonWrapped = createInstance('SMU', origin, version, 2);
const language = getUserLanguage();
if (apiInstanceNonWrapped && apiInstanceNonWrapped.defaults) {
  apiInstanceNonWrapped.defaults.headers.common.language = language;
}
const apiInstance = instanceWrapper(apiInstanceNonWrapped);
const apiShareInstance = (() => {
  const instance = Axios.create({
    baseURL: 'https://www.googleapis.com/urlshortener/v1/url',
  });
  return instance;
})();
const authInstance = instanceWrapper(createInstance('AUTH'));
const bethereApiInstance = instanceWrapper(createInstance('BTH', origin, version));
const osInstance = instanceWrapper(createInstance('OS', origin, version));
const dataServiceInstanceNonWrapped = createInstance('DATA_SERVICE', origin, version);
const dataServiceInstance = instanceWrapper(dataServiceInstanceNonWrapped);
const takepartApiInstance = createInstance('TP', origin, version);
const notificationsApiInstance = instanceWrapper(createInstance('NOTIFICATIONS', origin, version));

const mockMemberApiInstance = (() => {
  const token = getToken();
  const SMU_URL_API = baseUrl || SMU_URL[HOST];

  const instance = Axios.create({
    baseURL: `${SMU_URL_API}/os-member/api`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      language,
      // o: 'SMU_WEB_v3',
      // v: '3',
    },
  });
  return instance;
})();

const FEED_URL = {
  dev: 'https://feed-service-qa.starmeup.com',
  qa: 'https://feed-service-qa.starmeup.com',
  uat: 'https://feed-service-uat.starmeup.com',
  prod: 'https://feed-service.starmeup.com',
};

const feedApiInstance = (() => {
  const token = getToken();
  const FEED_URL_API = FEED_URL[HOST];
  const instance = Axios.create({
    baseURL: `${FEED_URL_API}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      language: 'en',
    },
  });
  return instance;
})();

const smuInstance = createInstance('SMU_CUSTOM', origin, version, 2);

// Functions
const navigateToAdmin = (section) => {
  const env = getEnv();
  if (env === 'qa' || env === 'uat') {
    return `${ADMIN_URL[env]}${section}`;
  }
  return `${ADMIN_URL.prod}${section}`;
};

const OSMemberApiInstance = createInstance('OSMEMBER', 'OS_ADMIN', 2);
const adminApiInstance = createInstance('OS', 'OS_ADMIN', 2, 2);
const apiCollaborationInstance = createInstance('COLLABORATION_SERVICE', origin, version, 2);
const defaultApi = createInstance('', origin, version, 2);

export {
  OSMemberApiInstance,
  adminApiInstance,
  apiCollaborationInstance,
  apiInstance,
  apiInstanceNonWrapped,
  apiShareInstance,
  authInstance,
  bethereApiInstance,
  dataServiceInstance,
  dataServiceInstanceNonWrapped,
  defaultApi,
  feedApiInstance,
  mockMemberApiInstance,
  navigateToAdmin,
  notificationsApiInstance,
  osInstance,
  smuInstance,
  takepartApiInstance,
};
