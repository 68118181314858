import {
  ADD_ITEM_OS_FEED,
  ADD_UNVISITED_OS_FEED_ITEM,
  ADD_VISITED_OS_FEED_ITEM,
  CLEAN_OS_FEED,
  CLEAN_OS_FEED_PARAMS,
  CLEAN_UNVISITED_OS_FEED_ITEMS,
  GET_OS_FEED,
  GET_OS_FEED_FAIL,
  GET_OS_FEED_HIGHLIGHTS,
  GET_OS_FEED_HIGHLIGHTS_FAIL,
  GET_OS_FEED_HIGHLIGHTS_SUCCESS,
  GET_OS_FEED_PREFERENCE,
  GET_OS_FEED_PREFERENCE_FAIL,
  GET_OS_FEED_PREFERENCE_SUCCESS,
  GET_OS_FEED_SUCCESS,
  POST_OS_FEED_COMMENT,
  POST_OS_FEED_COMMENT_FAIL,
  POST_OS_FEED_COMMENT_SUCCESS,
  POST_OS_FEED_LIFT_STAR_SUCCESS,
  POST_OS_FEED_LIKE,
  POST_OS_FEED_LIKE_FAIL,
  POST_OS_FEED_LIKE_SUCCESS,
  POST_OS_FEED_LOAD_IMAGE,
  POST_OS_FEED_LOAD_IMAGE_FAIL,
  POST_OS_FEED_LOAD_IMAGE_SUCCESS,
  POST_OS_FEED_MODERATE_COMMENT,
  POST_OS_FEED_MODERATE_COMMENT_FAIL,
  POST_OS_FEED_MODERATE_COMMENT_SUCCESS,
  POST_OS_FEED_PREFERENCE,
  POST_OS_FEED_PREFERENCE_FAIL,
  POST_OS_FEED_PREFERENCE_SUCCESS,
  POST_OS_FEED_REMOVE_LIKE,
  POST_OS_FEED_REMOVE_LIKE_FAIL,
  POST_OS_FEED_REMOVE_LIKE_SUCCESS,
  POST_OS_FEED_TURN_TOGGLE,
  POST_OS_FEED_TURN_TOGGLE_SUCCESS,
  POST_OS_FEED_UPDATE_POST,
  SET_OS_FEED_PARAMS,
} from './actionTypes';

import {
  CORE,
  CORE_VALUE,
  KUDOS,
  OS_FEED,
} from './constants';

const defaultState = {
  commentCount: 0,
  errorMessage: null,
  fetching: false,
  fetchingToggle: false,
  filters: {},
  likeCount: 0,
  loadingId: null,
  osFeedEnabled: false,
  page: 0,
  params: [],
  results: [],
  sendingComment: false,
  sendingLike: false,
  showSendStarValues: false,
  showSendStarWidget: false,
  starForMyself: false,
  totalElements: 0,
  unvisitedItems: [],
  visitedItems: [],
  hasMore: false,
};

function manageResults(results, payload) {
  return results.map((star) => {
    if (star.data.id === payload.starId) {
      return {
        ...star,
        data: {
          ...star.data,
          comments: [payload, ...star.data.comments],
        },
      };
    }
    return star;
  });
}

function removeLike(state, payload) {
  const commentId = payload?.like?.likeId;
  const postId = payload?.like?.id;
  const results = state?.results?.map(post => {
    if (post?.data?.id === postId) {
      const comments = post?.data?.comments;
      const filtered = comments?.filter((c) => c?.id !== commentId);
      return {
        ...post,
        data: {
          ...post?.data,
          comments: filtered,
        },
      };
    }
    return post;
  });

  return { results };
}

function manageComments(results, comment) {
  const { result, starId } = comment;
  return results.map(star => (star.id === starId
    ? {
      ...star,
      data: {
        ...star.data,
        comments: star.data.comments.map(comment => (comment.id === result.id ? (
          {
            ...comment,
            enabled: result.enabled,
          }
        ) : comment)),
      },
    } : star));
}

function manageParams(params, payload) {
  const payloadFormatted = { hashtag: payload.hashtagText };
  const isExist = params
    .some(param => Object.keys(param)[0] === Object.keys(payloadFormatted)[0]);

  return isExist
    ? params.map(param =>
      (Object.keys(param)[0] === Object.keys(payloadFormatted)[0]
        ? payloadFormatted
        : param))
    : [...params, payloadFormatted];
}

function manageAddItem(results, payload) {
  const newOsFeedItem = {
    id: payload.id,
    type: payload.typeActivity === CORE ? CORE_VALUE : KUDOS,
    application: 'StarMeUp',
    data: { ...payload },
  };

  return [newOsFeedItem, ...results];
}

function getNewValues(value, action) {
  let newLikeCount;
  let hasOwnLike;
  if (action === 'sum1') {
    hasOwnLike = true;
    newLikeCount = value + 1;
  } else {
    hasOwnLike = false;
    newLikeCount = value - 1;
  }

  return { hasOwnLike, newLikeCount };
}

function updateValues(results, id, action) {
  const newValue = results.map((result) => {
    if (result.data.id === id) {
      const { data: { likeCount } } = result;
      const { hasOwnLike, newLikeCount } = getNewValues(likeCount, action);
      return {
        ...result,
        data: {
          ...result.data,
          likeCount: newLikeCount,
          hasOwnLike,
        },
      };
    }
    return result;
  });

  return newValue;
}

function addLiftStar(results, liftStar) {
  liftStar.result.date = 'undefined';
  const newValues = results.map((result) => {
    if (result.data.id === liftStar.id) {
      const {
        comments,
        usersLift,
        quantityUsersLift,
        liftState,
      } = result.data;
      return {
        ...result,
        data: {
          ...result.data,
          comments: [...comments, liftStar.result],
          liftState: liftState + 1,
          quantityUsersLift: quantityUsersLift + 1,
          usersLift: [...usersLift, liftStar.result.user],
        },
      };
    }
    return result;
  });

  return newValues;
}

function removeDuplicate(state, payload) {
  const payloadFiltered = payload.reduce((acc, actualItem) => {
    if (
      !state.some(item => item.id === actualItem.id)
      && !acc.some(item => item.id === actualItem.id)
    ) {
      acc.push(actualItem);
    }
    return acc;
  }, []);
  return [...state, ...payloadFiltered];
}

function getCleanOsFeedState(state) {
  return {
    ...state,
    commentCount: 0,
    likeCount: 0,
    page: 0,
    results: [],
    sendingComment: false,
    sendingLike: false,
    showSendStarValues: false,
    showSendStarWidget: false,
    starForMyself: false,
    totalElements: 0,
  };
}

function updatePostById(state, payload) {
  const results = state?.results?.map(post => {
    if (post?.data?.id === payload?.id) {
      return {
        ...post,
        data: {
          ...post?.data,
          ...payload?.data,
        },
      };
    }
    return post;
  });
  return { results };
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_OS_FEED:
      return {
        ...(payload?.params?.page ? state : getCleanOsFeedState(state)),
        errorMessage: null,
        fetching: true,
        filters: payload?.params,
        hasMore: true,
      };
    case GET_OS_FEED_FAIL:
      return {
        ...state,
        errorMessage: payload,
        fetching: false,
        hasMore: false,
      };
    case GET_OS_FEED_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        fetching: false,
        hasMore: payload?.hasMore,
        results: removeDuplicate(state.results, payload.feed),
        totalElements: payload.totalElements,
      };
    case POST_OS_FEED_TURN_TOGGLE:
      return {
        ...state,
        fetchingToggle: true,
      };
    case POST_OS_FEED_TURN_TOGGLE_SUCCESS:
      return {
        ...state,
        fetchingToggle: false,
        osFeedEnabled: payload.osFeed,
      };
    case POST_OS_FEED_COMMENT:
      return {
        ...state,
        loadingId: payload.id,
        sendingComment: true,
      };
    case POST_OS_FEED_COMMENT_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        loadingId: null,
        sendingComment: false,
      };
    case POST_OS_FEED_COMMENT_SUCCESS:
      return {
        ...state,
        loadingId: null,
        results: manageResults(state.results, payload.comment),
        sendingComment: false,
      };
    case POST_OS_FEED_LIFT_STAR_SUCCESS:
      return {
        ...state,
        results: addLiftStar(state.results, payload),
      };
    case POST_OS_FEED_LIKE:
      return {
        ...state,
        sendingLike: true,
        results: updateValues(state.results, payload.id, 'sum1'),
      };
    case POST_OS_FEED_LIKE_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        results: updateValues(state.results, payload.id, 'subt1'),
        sendingComment: false,
      };
    case POST_OS_FEED_LIKE_SUCCESS:
      return {
        ...state,
        results: manageResults(state.results, payload.like),
        sendingLike: false,
      };
    case POST_OS_FEED_REMOVE_LIKE:
      return {
        ...state,
        removingLike: true,
      };
    case POST_OS_FEED_REMOVE_LIKE_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        removingLike: false,
      };
    case POST_OS_FEED_REMOVE_LIKE_SUCCESS:
      return {
        ...state,
        ...removeLike(state, payload),
        removingLike: false,

      };
    case POST_OS_FEED_PREFERENCE:
      return {
        ...state,
        fetching: true,
      };
    case POST_OS_FEED_PREFERENCE_FAIL:
      return {
        ...state,
        fetching: false,
        errorMessage: payload.errorMessage,
      };
    case POST_OS_FEED_PREFERENCE_SUCCESS:
      return {
        ...state,
        osFeedEnabled: payload.osFeed,
        fetching: false,
      };
    case POST_OS_FEED_LOAD_IMAGE:
    case POST_OS_FEED_LOAD_IMAGE_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    case POST_OS_FEED_LOAD_IMAGE_SUCCESS:
      return {
        ...state,
        results: manageResults(state.results, payload.image),
      };
    case POST_OS_FEED_MODERATE_COMMENT:
    case POST_OS_FEED_MODERATE_COMMENT_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    case POST_OS_FEED_MODERATE_COMMENT_SUCCESS:
      return {
        ...state,
        results: manageComments(state.results, payload.comment),
      };
    case CLEAN_OS_FEED:
      return getCleanOsFeedState(state);
    case CLEAN_OS_FEED_PARAMS: {
      return {
        ...state,
        params: [],
      };
    }
    case GET_OS_FEED_HIGHLIGHTS:
      return {
        ...state,
        fetching: true,
        osFeedEnabled: payload.showOsFeed || state.osFeedEnabled,
      };
    case GET_OS_FEED_HIGHLIGHTS_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        fetching: false,
      };
    case GET_OS_FEED_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        fetching: false,
        results: payload.feed,
        totalElements: payload.feed.length,
      };
    case SET_OS_FEED_PARAMS:
      return {
        ...state,
        params: manageParams(state.params, payload.params),
      };
    case GET_OS_FEED_PREFERENCE:
      return {
        ...state,
      };
    case GET_OS_FEED_PREFERENCE_FAIL:
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    case GET_OS_FEED_PREFERENCE_SUCCESS:
      return {
        ...state,
        osFeedEnabled: payload.osFeed
          .find(item => item.property === OS_FEED)
          .propertyValue === 'true',
      };
    case ADD_ITEM_OS_FEED:
      return {
        ...state,
        results: manageAddItem(state.results, payload),
      };
    case ADD_UNVISITED_OS_FEED_ITEM:
      return {
        ...state,
        unvisitedItems: removeDuplicate(state.unvisitedItems, payload),
      };
    case ADD_VISITED_OS_FEED_ITEM:
      return {
        ...state,
        visitedItems: removeDuplicate(state.unvisitedItems, payload),
      };
    case CLEAN_UNVISITED_OS_FEED_ITEMS:
      return {
        ...state,
        unvisitedItems: [],
      };
    case POST_OS_FEED_UPDATE_POST:
      return {
        ...state,
        ...updatePostById(state, payload),
      };
    default:
      return state;
  }
};
