// @app
import { paths } from 'utils/routes';

// @own
import YearInReviewLayout from './Layout';

export default () => ({
  component: YearInReviewLayout,
  path: paths.yir(),
});
