// @packages
import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';

// @own
import './styles.scss';
import MissSomebody from './MissSomebody';
import User from './User';
import {
  ACTION_URL_PER_INSIGHT_TYPE,
  GRID_WIDTHS_PER_COLUMNS,
  SEND_STAR_COMMENT_PER_INSIGHT_TYPE,
} from './constants';

const UserGrid = ({
  insightType,
  organizationValueId,
  users,
}) => {
  const chunkedUsers = chunk(users, 4).slice(0, 3);
  const totalColumns = chunkedUsers.length;

  const missSomebodyActionUrl = ACTION_URL_PER_INSIGHT_TYPE?.[insightType]
    ?.MISS_SOMEBODY({
      comment: SEND_STAR_COMMENT_PER_INSIGHT_TYPE[insightType],
      valueId: organizationValueId,
    });

  return (
    <div
      className="insights-content-user-grid"
      style={{ width: GRID_WIDTHS_PER_COLUMNS[totalColumns].GRID}}
    >
      {chunkedUsers.map((chunk, index) => (
        <div
          className="insights-content-user-grid__column"
          key={index}
          style={{ width: GRID_WIDTHS_PER_COLUMNS[totalColumns].COLUMN}}
        >
          {chunk.map(user => (
            <User
              actionUrl={ACTION_URL_PER_INSIGHT_TYPE?.[insightType]?.USER({
                comment: SEND_STAR_COMMENT_PER_INSIGHT_TYPE[insightType],
                userIds: [user.id],
                valueId: organizationValueId,
              })}
              key={user.id}
              user={user}
            />
          ))}
          {((totalColumns - 1) === index && missSomebodyActionUrl) && (
            <MissSomebody actionUrl={missSomebodyActionUrl} />
          )}
        </div>
      ))}
    </div>
  );
};

UserGrid.propTypes = {
  insightType: PropTypes.string,
  organizationValueId: PropTypes.number,
  users: PropTypes.array,
};

export default UserGrid;
