// @pckages
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';

const SkeletonCongratulationsUsers = ({ className, totalUsers }) => {
  const users = Array.from({ length: totalUsers }, (_, i) => i);

  return (
    <div className={cn('flex flex-col', className)}>
      {users.map((key) => (
        <div
          className={cn(
            'flex items-center space-x-3 border-b border-gray-iron last-of-type:border-transparent',
            'py-4 px-5',
          )}
          key={key}
        >
          <div className="w-[260px] flex items-center space-x-3 flex-none">
            <Skeleton
              animation="wave"
              className="flex-none"
              height={40}
              variant="circle"
              width={40}
            />
            <div className="flex flex-col w-full">
              <Skeleton animation="wave" className="w-3/4" height={14} />
              <Skeleton animation="wave" className="w-2/4 mt-1" height={14} />
            </div>
          </div>
          <Skeleton animation="wave" className="w-full" height={30} />
          <Skeleton
            animation="wave"
            className="flex-none"
            height={30}
            variant="circle"
            width={30}
          />
        </div>
      ))}
    </div>
  );
};

SkeletonCongratulationsUsers.propTypes = {
  className: PropTypes.string,
};

SkeletonCongratulationsUsers.defaultProps = {
  className: '',
};

export default SkeletonCongratulationsUsers;
