// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash/get';

// @own
import './styles.scss';

function Image({ disabled, onClick, option }) {
  const active = get(option, 'selected.data.value');
  const image = get(option, 'data.path');

  return (
    <div
      className={cn('questionnaires-flex-type-image', {
        'questionnaires-flex-type-image--active': active,
        'questionnaires-flex-type-image--disabled': disabled,
      })}
      onClick={onClick}
      style={{ backgroundImage: `url("${image}")` }}
    />
  );
}

Image.defaultProps = {
  disabled: false,
};

Image.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};

export default Image;
