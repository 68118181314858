// @packages
import trim from 'lodash/trim';

// @app
import { smuInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { times } from 'api';

export function apiPostAdvancedUserSearch({ data = {}, params = {} }) {
  const sanityData = Object.keys(data).reduce((acc, cur) => {
    if (trim(data[cur])) {
      acc[cur] = data[cur];
    }
    return acc;
  }, {});

  return smuInstance({
    data: sanityData,
    method: 'post',
    params: { ...params, ...times() },
    url: '/starmeup-api/v2/stellar/advancedUserSearch',
  })
    .then((data) => data)
    .catch(errorHandler);
}
