// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';
import random from 'lodash/random';

// @own
import './styles.scss';

function SkeletonAvatarMedia({ className }) {
  const [size] = useState({
    titleWidth: random(180, 220),
    descriptionWidth: random(140, 180),
  });

  return (
    <div className={cn('skeleton-avatar-media', className)}>
      <Skeleton
        animation="wave"
        className="skeleton-avatar-media__avatar"
        height={40}
        variant="circle"
        width={40}
      />
      <div className="skeleton-avatar-media__info">
        <Skeleton
          animation="wave"
          className="skeleton-avatar-media__title"
          height={16}
          width={size.titleWidth}
        />
        <Skeleton
          animation="wave"
          className="skeleton-avatar-media__description"
          height={12}
          width={size.descriptionWidth}
        />
      </div>
    </div>
  );
}

SkeletonAvatarMedia.propTypes = {
  className: PropTypes.string,
};

export default SkeletonAvatarMedia;
