export function isInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function isVideoOutViewport(el) {
  const rect = el.getBoundingClientRect();
  const half = rect?.height / 2;
  return (
    (rect.top + half) <= 0 ||
    ((rect.top + half) >=
    (window.innerHeight || document.documentElement.clientHeight))
  );
}
