// @packages
import React from 'react';
import Card from 'smu-ui-components/Card';
import Skeleton from 'smu-ui-components/Skeleton';

const SurveysWidgetSkeleton = (props) => (
  <Card {...props}>
    <div className="px-3 py-4 flex items-center justify-between">
      <Skeleton animation="wave" height={23} variant="text" width="35%" />
      <Skeleton animation="wave" height={23} variant="text" width="30%" />
    </div>
    <div className="px-3 pt-4 flex items-center justify-between">
      <Skeleton animation="wave" height={19} variant="text" width="45%" />
      <Skeleton animation="wave" height={19} variant="text" width="45%" />
    </div>
    <div className="p-3 h-52 overflow-x-hidden overflow-y-auto">
      <Skeleton
        animation="wave"
        className="h-14"
        variant="text mb-2"
        width="100%"
      />
      <Skeleton
        animation="wave"
        className="h-14"
        variant="text mb-2"
        width="100%"
      />
      <Skeleton animation="wave" className="h-14" variant="text" width="100%" />
    </div>
  </Card>
);

export default SurveysWidgetSkeleton;
