import { defineMessages } from 'react-intl';

export default defineMessages({
  createChilloutButtonCancel: {
    id: 'createChillout.buttonCancel',
    defaultMessage: 'Cancel',
  },
  createChilloutButtonSave: {
    id: 'createChillout.buttonSave',
    defaultMessage: 'Save',
  },
  createChilloutDateLabel: {
    id: 'createChillout.dateLabel',
    defaultMessage: 'Select a date',
  },
  createChilloutDescriptionLabel: {
    id: 'createChillout.descriptionLabel',
    defaultMessage: 'Description',
  },
  createChilloutDescriptionPlaceholder: {
    id: 'createChillout.descriptionPlaceholder',
    defaultMessage: 'Add a description of your Get-Together',
  },
  createChilloutDiscardModalNoButton: {
    id: 'createChillout.discardModalNoButton',
    defaultMessage: 'No',
  },
  createChilloutDiscardModalText: {
    id: 'createChillout.discardModalText',
    defaultMessage: 'Are you sure you want to discard your Get-Together',
  },
  createChilloutDiscardModalTitle: {
    id: 'createChillout.discardModalTitle',
    defaultMessage: 'DISCARD GET-TOGETHER',
  },
  createChilloutDiscardModalYesButton: {
    id: 'createChillout.discardModalYesButton',
    defaultMessage: 'Yes',
  },
  createChilloutHourFrom: {
    id: 'createChillout.hourFrom',
    defaultMessage: 'From',
  },
  createChilloutHourFromPlaceholder: {
    id: 'createChillout.hourFromPlaceholder',
    defaultMessage: 'Select',
  },
  createChilloutHourTo: {
    id: 'createChillout.hourTo',
    defaultMessage: 'To',
  },
  createChilloutHourToPlaceholder: {
    id: 'createChillout.hourToPlaceholder',
    defaultMessage: 'Select',
  },
  createChilloutTitle: {
    id: 'createChillout.title',
    defaultMessage: 'CREATE GET-TOGETHER',
  },
  createChilloutTitleLabel: {
    id: 'createChillout.titleLabel',
    defaultMessage: 'Title',
  },
  createChilloutTitlePlaceholder: {
    id: 'createChillout.titlePlaceholder',
    defaultMessage: 'Add the title of your Get-Together',
  },
  createChilloutWhen: {
    id: 'createChillout.when',
    defaultMessage: 'When?',
  },
  createChilloutWho: {
    id: 'createChillout.who',
    defaultMessage: 'Who?',
  },
  createChilloutWhy: {
    id: 'createChillout.why',
    defaultMessage: 'Why?',
  },
});
