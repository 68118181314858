// @packages
import React, { useEffect, useRef } from 'react';
import Sticky from 'react-stickynode';
import { connect } from 'react-redux';
import { selectOpenMenu } from 'smu-app-components/MainHeader/selectors';

function StickyWrapper({
  children,
  openMenu,
  ...rest
}) {
  const stickyRef = useRef(null);

  const updateSticky = () => {
    const stickyInstance = stickyRef.current;
    if (stickyInstance === null) {
      return;
    }
    stickyInstance.updateInitialDimension();
    stickyInstance.update();
  };

  useEffect(() => {
    updateSticky();
  }, [openMenu]);

  return (
    <Sticky ref={stickyRef} {...rest}>
      {children}
    </Sticky>
  );
}

const mapStateToProps = (state) => ({
  openMenu: selectOpenMenu(state),
});

export default connect(mapStateToProps)(StickyWrapper);
