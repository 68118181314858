import {
  REQUEST_ORGANIZATION_VALUES,
  REQUEST_ORGANIZATION_VALUES_SUCCESS,
  REQUEST_ORGANIZATION_VALUES_FAIL,
} from './actionTypes';

export function actionOrganizationValues(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_ORGANIZATION_VALUES,
  };
}

export function actionOrganizationValuesSuccess(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_ORGANIZATION_VALUES_SUCCESS,
  };
}

export function actionOrganizationValuesFail(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_ORGANIZATION_VALUES_FAIL,
  };
}
