// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withRootModal from 'smu-app-components/RootModalV2/withRootModal';
// @app
import usePrevious from 'hooks/usePrevious';
import QuestionnaireModal from 'components/Questionnaires/QuestionnaireModal';
import { OPEN_QUESTIONNAIRE_PANEL } from 'components/Questionnaires/constants';
import { selectOrganizationId as selectOrgId } from 'containers/Authorization/selectors';

const WrapperQuestionnaireModal = ({
  closeModal,
  id,
  location,
  onClose,
  openModal,
  orgId,
  router,
}) => {
  const prevId = usePrevious(id);

  const handleToggleModal = () => {
    openModal({
      modalType: OPEN_QUESTIONNAIRE_PANEL,
      modalProps: {
        closeModal: () => {
          router.push(location.pathname);
        },
        orgId,
        questionnaireId: Number(id),
      },
    });
  };

  const handleChangeCardId = () => {
    if (prevId !== id) {
      if (id) {
        handleToggleModal();
      } else {
        closeModal();
      }
    }
  };

  useEffect(handleChangeCardId, [id]);

  return <QuestionnaireModal onClose={onClose} />;
};

WrapperQuestionnaireModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  openModal: PropTypes.func.isRequired,
  orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  orgId: selectOrgId(state),
});

export default withRouter(withRootModal(connect(mapStateToProps, null)(WrapperQuestionnaireModal)));
