// @packages
import React from 'react';
import Avatar from 'smu-ui-components/Avatar';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';

// @app
import { buildImageSMU } from 'utils/image';
import { trackEvent } from 'utils/gtm';

const onClickGtm = () => {
  trackEvent({
    category: 'Top Stars',
    action: 'Click',
  });
};

const TopStarComponent = ({
  amount,
  firstName,
  id,
  job,
  lastName,
  profileImageCode,
  uid,
  ...rest
}) => {
  const linkProps = {
    href: uid ? `/profile/${uid}` : undefined,
    legacy: uid ? false : undefined,
    underline: uid ? 'hover' : undefined,
    component: uid ? Link : undefined,
  };

  return (
    <div className="flex justify-between items-center w-full" {...rest}>
      <div className="flex flex-row">
        <Avatar
          onClick={onClickGtm}
          src={buildImageSMU({
            code: profileImageCode,
            height: 30,
            width: 30,
          })}
          height={30}
          width={30}
          {...linkProps}
        />
        <div className="flex flex-col ml-3">
          <Typography
            className={cn('text-shark text-sm', {
              'hover:text-violet': uid,
            })}
            variant="subtitle2"
            {...linkProps}
          >
            {firstName} {lastName}
          </Typography>
          <Typography className="text-xs text-raven" variant="subtitle1">
            {job}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col pl-3">
        <div className="relative text-center">
          <Typography className="font-bold text-blue font-rubik text-sm">
            {amount}
          </Typography>
        </div>
      </div>
    </div>
  );
};

TopStarComponent.propTypes = {
  amount: PropTypes.number,
  firstName: PropTypes.string,
  id: PropTypes.number,
  job: PropTypes.string,
  lastName: PropTypes.string,
  profileImageCode: PropTypes.string,
  uid: PropTypes.string,
};

export default TopStarComponent;
