// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

function EventCard({ children, className }) {
  return (
    <section className={`event-card ${className}`}>
      <div className={`event-card__container ${className}__container`}>
        {children}
      </div>
    </section>
  );
}

EventCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default EventCard;
