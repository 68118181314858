// @packages
import flatten from 'flat';

// @own
import {
  CHANGE_LANGUAGE,
  CHANGE_LANGUAGE_FAILED,
  CHANGE_LANGUAGE_SUCCESS,
  REQUEST_LANGUAGES_SUCCESS,
  REQUEST_LOCALES,
  REQUEST_LOCALES_FAILED,
  REQUEST_LOCALES_SUCCESS,
} from './actionTypes';

const initialState = {
  error: null,
  languages: [],
  locale: 'en',
  messages: undefined,
  requesting: false,
  success: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_LOCALES:
      return {
        ...state,
        error: initialState.error,
        requesting: true,
      };
    case REQUEST_LOCALES_FAILED:
      return {
        ...state,
        error: payload,
        requesting: false,
        success: false,
      };
    case REQUEST_LOCALES_SUCCESS:
      return {
        ...state,
        locale: payload.locale,
        messages: flatten(payload.messages),
        requesting: false,
        success: true,
      };
    case REQUEST_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: payload,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        requesting: true,
      };
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        locale: payload.locale,
        requesting: false,
      };
    case CHANGE_LANGUAGE_FAILED:
      return {
        ...state,
        requesting: false,
      };
    default:
      return state;
  }
}
