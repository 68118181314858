import { fullName } from 'utils/user';

export const normalizeLikes = (interactions) =>
  interactions
    ?.filter((i) => i?.pi_type === 'LIKE')
    ?.map(({ user }) => ({
      fullName: fullName(user, 'user_name', 'user_lastname'),
      id: user?.user_id,
      job: user?.user_job,
      profileImageCode: user?.user_image,
      uid: user?.uid,
    }));

export const buildInteractions = (isLoading, interactions) => {
  const likes = isLoading ? [] : normalizeLikes(interactions);
  return {
    likes: {
      isLoading,
      total: likes?.length,
      users: likes,
    },
  };
};
