import { FILTER_TYPES } from 'containers/FiltersV2/constants';
import { first, groupBy, includes, keys, merge, omit } from 'lodash';

import { MUST_EXCLUDE } from './constants';

export const getDefaultValues = (values) =>
  keys(values).reduce((defaultValues, value) => {
    if (
      values[value].defaultValue &&
      values[value].type !== FILTER_TYPES.STRING
    ) {
      defaultValues[value] = values[value].defaultValue.id;
    }
    return defaultValues;
  }, {});

export const getFiltersValuesParams = ({ availableFilters, use }) => {
  const filterKeys = keys(availableFilters).filter(
    (item) => !includes(MUST_EXCLUDE, item),
  );

  const params = filterKeys.reduce((params, filter) => {
    let item = {
      field: filter,
      use,
    };
    params.push(item);
    return params;
  }, []);

  return params;
};

export const normalizeFiltersValues = ({
  availableFilters,
  categoryValues,
}) => {
  const filterKeys = keys(availableFilters).filter(
    (item) => !includes(MUST_EXCLUDE, item),
  );

  let childrens = filterKeys.reduce((childrens, filter) => {
    let parent = availableFilters[filter].parentField;
    if (parent) {
      const actualChildren = childrens[parent]
        ? childrens[parent].children
        : [];
      childrens[parent] = {
        children: [...actualChildren, filter],
      };
    }
    return childrens;
  }, {});

  const values = categoryValues.reduce((values, filter) => {
    const { field } = filter;
    let fields = {
      ALL: omit(filter, ['field']).values,
    };
    let groupedFields = {};
    const defaultValue =
      first(fields.ALL.filter((item) => item.isDefault)) || {};

    if (availableFilters[field].parentId) {
      groupedFields = groupBy(fields.ALL, 'parentValueId');
    }

    if (fields.ALL.length) {
      values[field] = {
        values: merge(fields, groupedFields),
        defaultValue,
      };
    }
    return values;
  }, {});

  const filters = merge(availableFilters, merge(values, childrens));

  return filters;
};
