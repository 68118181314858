// @packages
import React from 'react';
import IconV2 from 'smu-ui-components/IconV2';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import SelectV2 from 'smu-ui-components/SelectV2';
import cn from 'classnames';

// @app
import TextField from 'components/Lab/TextField';

// @own
import './styles.scss';

function AdvancedUserFilters({
  className,
  filters,
  isLoading,
  title,
  typeList,
}) {
  function buildFilter({ type, ...rest }) {
    switch (type) {
      case typeList:
        return (
          <SelectV2
            className="advanced-user-filters__filter"
            fullWidth
            variant="outlined"
            {...rest}
          />
        );
      default:
        return (
          <TextField
            className="advanced-user-filters__filter"
            endAdornment={
              <div className="advanced-user-filters__adornment">
                <IconV2
                  className="advanced-user-filters__adornment-icon"
                  icon="search"
                />
              </div>
            }
            fullWidth
            variant="outlined"
            {...rest}
          />
        );
    }
  }

  return (
    <div className={cn('advanced-user-filters', className)}>
      {isLoading ? (
        <LottieSpinner
          className="advanced-user-filters__loader"
          height={40}
          loadingType="starmeup"
          width={40}
        />
      ) : (
        <>
          <h4 className="advanced-user-filters__title">{title}</h4>
          <div className="advanced-user-filters__items">
            {filters &&
              filters.map((filter) => (
                <div
                  className="advanced-user-filters__item"
                  key={`filter-${filter.name}`}
                >
                  {buildFilter(filter)}
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

AdvancedUserFilters.defaultProps = {
  className: '',
  filters: [],
  isLoading: false,
  title: "Let's find your collegue",
  typeList: 'LIST',
};

AdvancedUserFilters.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  typeList: PropTypes.string,
};

export default AdvancedUserFilters;
