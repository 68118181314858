import { defineMessages } from 'react-intl';

export default defineMessages({
  uploadPostHeaderTitle: {
    id: 'uploadPost.newPost',
    defaultMessage: 'New Post',
  },
  uploadPostActionstUpload: {
    id: 'uploadPost.actions.uploadPost',
    defaultMessage: 'Upload Post',
  },
  uploadPostActionstCancel: {
    id: 'uploadPost.actions.cancel',
    defaultMessage: 'Cancel',
  },
  uploadPostDndUploadYourFile: {
    id: 'uploadPost.dnd.uploadYourFile',
    defaultMessage: 'Drag and Drop or browse to upload a file',
  },
  uploadPostDndDragAndDropFile: {
    id: 'uploadPost.dnd.dragAndDropFile',
    defaultMessage: 'Supported formats: png, jpg and mp4. Maximum file size is 50 MB.',
  },
  uploadPostDndBrowse: {
    id: 'uploadPost.dnd.browse',
    defaultMessage: 'Browse',
  },
  uploadPostDetailsDescriptionTitle: {
    id: 'uploadPost.details.DescriptionTitle',
    defaultMessage: 'Description',
  },
  uploadPostDetailsCommentPlaceholder: {
    id: 'uploadPost.details.commentPlaceholder',
    defaultMessage: 'Add a description to your post',
  },
  uploadPostErrorSizeLimit: {
    id: 'uploadPost.error.sizeLimit',
    defaultMessage: 'The selected file exceeds the maximun file size limit.',
  },
  uploadPostErrorIncorrectFormat: {
    id: 'uploadPost.error.inconrrectFormat',
    defaultMessage: 'The selected file format is not supported. Try again selecting a file with a compatible format.',
  },
  uploadPostErrorStateTitle: {
    id: 'uploadPost.error.stateTitle',
    defaultMessage: 'Oops, something went wrong',
  },
  uploadPostErrorStateSubtitle: {
    id: 'uploadPost.error.stateSubtitle',
    defaultMessage: 'Please try again in a few seconds.',
  },
  uploadPostUploadingPost: {
    id: 'uploadPost.UploadingPost',
    defaultMessage: 'Uploading Post',
  },
  uploadPostSuccessfullyCreated: {
    id: 'uploadPost.successfullyCreated',
    defaultMessage: 'Your post was successfully created',
  },
  uploadPostLocation: {
    id: 'uploadPost.location',
    defaultMessage: 'Location',
  },
  uploadPostLocationDisabled: {
    id: 'uploadPost.locationDisabled',
    defaultMessage: 'Location disabled.',
  },
  uploadPostLocationNotSupported: {
    id: 'uploadPost.locationNotSupported',
    defaultMessage: 'Location is not supported.',
  },
  uploadPostLocationUnavailable: {
    id: 'uploadPost.locationUnavailable',
    defaultMessage: 'Location unavailable.',
  },
  uploadPostLocationUnavailableTooltip: {
    id: 'uploadPost.locationUnavailableTooltip',
    defaultMessage: 'You need to allow your browser to access your location.',
  },
  uploadPostAddTags: {
    id: 'uploadPost.addTags',
    defaultMessage: 'Click anywhere inside the cropped area to add tags',
  },
  uploadPostSearchColleagues: {
    id: 'uploadPost.searchColleagues',
    defaultMessage: 'Search Colleagues',
  },
  uploadPostVeryLongDescription: {
    id: 'uploadPost.veryLongDescription',
    defaultMessage: 'Description cannot be longer than {xxx} characters.',
  },
});
