// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import Tabs from 'smu-ui-components/Tabs';
import cn from 'classnames';

// @app
import Avatar from 'components/Avatar';
import AvatarInfo from 'components/AvatarInfo';
import AvatarInteraction from 'components/AvatarInteraction';
import AvatarMedia from 'components/AvatarMedia';
import InfiniteScroll from 'components/InfiniteScroll';
import Modal from 'components/Modal';
import SkeletonAvatarMediaList from 'components/Skeletons/AvatarMediaList';

// @own
import './styles.scss';

const ModalInteractions = ({
  className,
  onClose,
  showTabs,
  tabs,
  title,
  ...rest
}) => {
  const activeIndex = tabs?.findIndex((t) => t.active);
  const activeTab = tabs?.[activeIndex];
  const customTabs = tabs?.map((tab) => ({
    disabled: tab?.disabled,
    name: (
      <span className="smu-modal-interactions__tab">
        {tab?.icon && (
          <Icon className="smu-modal-interactions__tab-icon" icon={tab.icon} />
        )}
        {tab?.image && (
          <img
            alt={tab?.name}
            className="smu-modal-interactions__tab-image"
            src={tab?.image}
          />
        )}
        {tab?.name}
      </span>
    ),
    onClick: tab?.onClick,
  }));

  const handleAvatarClick = (onClick) => {
    if (onClick) onClick();
    if (onClose) onClose();
  };

  return (
    <Modal
      className={cn('smu-modal-interactions', className)}
      onClick={onClose}
      {...rest}
    >
      <div className="smu-modal-interactions__content">
        <Button
          className="smu-modal-interactions__close"
          onClick={onClose}
          variant="text"
        >
          <Icon className="smu-modal-interactions__close-icon" icon="close" />
        </Button>
        <header className="smu-modal-interactions__header">
          {title && <h4 className="smu-modal-interactions__title">{title}</h4>}
        </header>
        <div className="smu-modal-interactions__body">
          {!!tabs?.length && (
            <>
              {showTabs && (
                <Tabs
                  active={activeIndex}
                  className="smu-modal-interactions__tabs"
                  tabs={customTabs}
                  extraTabClassName="smu-modal-interactions__tab-item"
                />
              )}
              <InfiniteScroll
                className="smu-modal-interactions__users"
                dataLength={activeTab?.interactions?.length || 0}
                hasMore={activeTab?.hasMore}
                height="322px"
                loader={null}
                next={activeTab?.onLoadMore}
              >
                <div
                  className="smu-modal-interactions__tabs-content"
                  key={`interaction-tab-${activeTab?.name}`}
                >
                  {activeTab?.interactions?.map((interaction, index) => (
                    <div
                      className="smu-modal-interactions__user"
                      key={`${activeTab?.name}-${interaction?.uid}-${index}`}
                    >
                      <AvatarMedia
                        avatar={
                          <Avatar
                            href={interaction?.href}
                            onClick={() =>
                              handleAvatarClick(interaction?.onClick)
                            }
                            src={interaction?.src}
                          />
                        }
                        interaction={
                          <AvatarInteraction
                            interaction={interaction?.interaction}
                          />
                        }
                        info={
                          <AvatarInfo
                            description={interaction?.description}
                            href={interaction?.href}
                            onClickTitle={() =>
                              handleAvatarClick(interaction?.onClick)
                            }
                            title={interaction?.title}
                          />
                        }
                      />
                    </div>
                  ))}
                  {activeTab.isLoading && (
                    <SkeletonAvatarMediaList
                      className="smu-modal-kudos__loader"
                      total={activeTab?.interactions?.length ? 3 : 5}
                    />
                  )}
                </div>
              </InfiniteScroll>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

ModalInteractions.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  showTabs: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      icon: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  title: PropTypes.string,
};

ModalInteractions.defaultProps = {
  showTabs: true,
  title: 'Interactions',
};

export default ModalInteractions;
