import { defaults, merge } from 'lodash';
import {
  CLEAN_LEADERBOARD,
  GET_NEW_LEADERBOARD,
  GET_NEW_LEADERBOARD_FAIL,
  GET_NEW_LEADERBOARD_SUCCESS,
  PERSIST_LEADERBOARD_FIELDS,
} from './actionTypes';

const initialState = {
  error: '',
  fetching: false,
  fields: {},
  filtersApplied: false,
  results: [],
};

const addFields = fields =>
  defaults(fields, {
    typeLeaderboard: 'RECEIVER',
    periodTime: 'thisMonth',
  });

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CLEAN_LEADERBOARD:
      return initialState;
    case GET_NEW_LEADERBOARD:
      return {
        ...state,
        fetching: true,
        fields: addFields(merge(payload.persistedFields || {}, payload.fields || state.fields)),
        filtersApplied:
          payload.filtersApplied !== undefined ? payload.filtersApplied : state.filtersApplied,
      };
    case GET_NEW_LEADERBOARD_FAIL:
      return {
        ...state,
        error: payload,
        fetching: false,
      };
    case GET_NEW_LEADERBOARD_SUCCESS:
      return {
        ...state,
        fetching: false,
        results: payload,
      };
    case PERSIST_LEADERBOARD_FIELDS:
      return {
        ...state,
        fields: payload.fields,
      };
    default:
      return state;
  }
}
