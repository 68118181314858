// @packages
import React from 'react';
import PropTypes from 'prop-types';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import { map } from 'lodash';
import cn from 'classnames';

// @own
import './styles.scss';

function UsersList({
  className,
  onAvatarClick,
  users,
}) {
  return (
    <div className={cn('users-list', className)}>
      {map(users, (user, index) => (
        <div className="users-list__user" key={`user-${index}`}>
          <div className="users-list__user-avatar">
            <AvatarMedia
              {...user}
              className="cursor-pointer"
              onClick={() => onAvatarClick(user)}
              useLink={false}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

UsersList.defaultProps = {
  className: '',
  onAvatarClick: undefined,
};

UsersList.propTypes = {
  className: PropTypes.string,
  onAvatarClick: PropTypes.func,
  users: PropTypes.array,
};

export default UsersList;
