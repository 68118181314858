import { reduce, merge } from 'lodash';

const typeToKey = type =>
  type.toLowerCase().replace(/_([a-z]?)/g, (_, q) => q.toUpperCase());

const types = [
  'GET_LEADERBOARD',
  'GET_LEADERBOARD_SUCCESS',
  'GET_LEADERBOARD_FAILED',
  'UPDATE_LEADERBOARD_FIELDS',
  'CLEAN_LEADERBOARD',
];

export const actionTypes = reduce(
  types,
  (ats, type) => merge(ats, { [typeToKey(type)]: type }),
  {},
);

export const actions = reduce(
  types,
  (acts, type) => {
    const action = payload => ({
      type,
      payload,
    });
    return merge(acts, { [typeToKey(type)]: action });
  },
  {},
);
