import { AUTH_SUCCESS, AUTH_WIDGET_FAIL } from './actionTypes';
import {
  SET_OSFEED_PREFERENCE,
  SET_OSFEED_PREFERENCE_FAIL,
  SET_OSFEED_PREFERENCE_SUCCESS,
  UPLOAD_USER_IMAGE_SUCCESS,
} from '../UserInfo/actionTypes';

const initialState = {
  authWidgetFail: false,
  communityConfigurations: null,
  stars: null,
  starsDisabled: null,
  user: null,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        user: payload.user,
        communityConfigurations: payload.communityConfigurations,
        stars: payload.stars,
        starsDisabled: payload.starsDisabled,
      };
    case AUTH_WIDGET_FAIL:
      return {
        ...state,
        authWidgetFail: true,
      };
    case UPLOAD_USER_IMAGE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, profileImageId: payload.id, profileImageCode: payload.code },
      };
    case SET_OSFEED_PREFERENCE:
      return {
        ...state,
        user: { ...state.user, osFeedFetching: true },
      };
    case SET_OSFEED_PREFERENCE_FAIL:
      return {
        ...state,
        user: { ...state.user, osFeedFetching: false },
      };
    case SET_OSFEED_PREFERENCE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, osFeed: payload.osFeed, osFeedFetching: false },
      };
    default:
      return state;
  }
}
