import TextSanitize from 'utils/inputSanitize';
import axios from 'axios';
import { apiInstance } from 'utils/requestHelper';
import { defaultsDeep, omit } from 'lodash';
import { errorHandler } from 'utils/errorHandler';
import { stringify } from 'querystring';
import { addParams, formData, times, vo } from './../../api';

let feedRequest = null;
export function getFeed(_config) {
  const { filterBy, firstStarId, useActivityUrl, useProfileUrl, userId } =
    _config;

  if (feedRequest) {
    feedRequest.cancel();
  }

  feedRequest = axios.CancelToken.source();

  let config = defaultsDeep(_config, {
    filterBy: 'RECEIVED',
    firstStarId: 0,
    orderBy: 'DESC',
    page: 0,
    size: 20,
    starsize: 10,
    app: 'StarMeUp',
  });
  config.optionalFields = JSON.stringify(config.optionalFields);
  config = useActivityUrl ? omit(config, ['userId', 'useActivityUrl']) : config;
  const feedUrl = useProfileUrl
    ? `/v2/stellar/feed/user/${userId}`
    : filterBy === 'MOSTLIKED'
    ? '/v2/stellar/highlights'
    : '/v2/stellar/feed';
  const baseUrl = [
    feedUrl,
    useActivityUrl ? `/${firstStarId}/user/${userId}` : '',
  ].join('');

  return new Promise((resolve, reject) => {
    apiInstance({
      cancelToken: feedRequest.token,
      params: {
        ...vo,
        ...times(),
        ...config,
      },
      url: baseUrl,
    })
      .then(({ data }) => {
        feedRequest = null;
        resolve(
          filterBy === 'MOSTLIKED' ? data.result.starsOnFire : data.result,
        );
      })
      .catch(
        (thrown) => !axios.isCancel(thrown) && reject(errorHandler(thrown)),
      );
  });
}

export function sendComment({ comment, id }) {
  const baseUrl = `/v2/stellar/stars/${id}/comments`;
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times() }),
    data: stringify({
      comments: TextSanitize(comment),
    }),
    ...formData,
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

export function sendLike({ id }) {
  const baseUrl = `/v2/stellar/stars/${id}/likes`;
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times() }),
    ...formData,
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

export function removeLike({ likeId }) {
  const baseUrl = `/v2/stellar/likes/${likeId}/remove`;
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times() }),
    ...formData,
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

export function toggleMainComment({ activityId, action }) {
  const baseUrl = `/v2/stellar/stars/${activityId}/${action}`;
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times() }),
    ...formData,
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

export function commentImage({ imageCode, id }) {
  const baseUrl = `/v2/stellar/stars/${id}/image/${imageCode}`;
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times() }),
    ...formData,
  })
    .then(({ data }) => (data))
    .catch(errorHandler);
}
