// @Packages
import React from 'react';
import Accordion from 'smu-ui-components/Accordion';
import Divider from 'smu-ui-components/Divider';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @App
import Input from 'components/Input';
import Message from 'containers/Message';
import { sortMessages } from 'utils/sorting';

// @Own
import formattedMessages from './messages';
import './styles.scss';

const Messages = ({
  canModerateMessages,
  emojisStyle,
  expanded,
  feedSetFields,
  goToHome,
  handleIconClick,
  intl,
  isWidget,
  listHashtags,
  loading,
  location,
  mediaUploadEnabled,
  mentionsEnabled,
  messages,
  onInputChange,
  onUploadImage,
  placeholder,
  showDivider,
  showShadowHeader,
  sortMessagesEnabled,
  trackHashtag,
}) => {
  const getSortedMessages = () => {
    let tempMessages = messages;

    if (sortMessagesEnabled) {
      tempMessages = sortMessages(messages);
    }

    return tempMessages;
  };

  const filterOnlyComments = messages => messages.filter(message => message.type === 'COMMENT' || message.type === 'IMAGE');

  return (
    <div
      className={cn('star-messages', {
        'star-messages--is-loading': loading,
      })}
    >
      <Accordion
        canBeExpanded={false}
        enabledAnimations={false}
        extraClass="star-messages__accordion"
        isExpanded={messages && expanded}
      >
        { showDivider && <Divider nomargin /> }
        <div
          className={cn('star-messages__accordion-header', {
            'star-messages__accordion-header--shadow': showShadowHeader,
          })}
        >
          {!loading ? (
            <Input
              emojisStyle={emojisStyle}
              handleIconClick={handleIconClick}
              isWidget={isWidget}
              maxCharacters={400}
              mediaUploadEnabled={mediaUploadEnabled}
              mentionsEnabled={mentionsEnabled}
              onInputChange={onInputChange}
              onUploadImage={onUploadImage}
              placeholder={
                placeholder ||
                intl.formatMessage(
                  formattedMessages.MessagesCommentsInputPlaceholder,
                )
              }
            />
          ) : (
            <LottieSpinner
              className="star-messages__loader infinite-scroll__loader"
              loadingType="starmeup"
              height={40}
              width={40}
            />
          )}
        </div>
        <div className="star-messages__comments">
          {
            filterOnlyComments(getSortedMessages()).map((message, key) =>
              (<Message
                key={key}
                id={message.id}
                position="left"
                description={message.comments}
                listMentions={message.listMentions}
                author={message.user}
                creationTime={message.dateComment}
                type={message.type}
                enabled={message.enabled}
                canModerateMessages={canModerateMessages}
                imageCode={message.imageCode}
                listHashtags={listHashtags}
                feedSetFields={feedSetFields}
                location={location}
                goToHome={goToHome}
                trackHashtag={trackHashtag}
              />))
          }
        </div>
      </Accordion>
    </div>
  );
};

Messages.defaultProps = {
  emojisStyle: {},
  showDivider: true,
  showShadowHeader: true,
  sortMessagesEnabled: true,
};

Messages.propTypes = {
  canModerateMessages: PropTypes.bool,
  emojisStyle: PropTypes.object,
  expanded: PropTypes.bool,
  feedSetFields: PropTypes.func,
  goToHome: PropTypes.func,
  handleIconClick: PropTypes.func,
  intl: PropTypes.object,
  isWidget: PropTypes.bool,
  listHashtags: PropTypes.array,
  loading: PropTypes.bool,
  location: PropTypes.string,
  mediaUploadEnabled: PropTypes.bool,
  mentionsEnabled: PropTypes.bool,
  messages: PropTypes.array,
  onInputChange: PropTypes.func,
  onUploadImage: PropTypes.func,
  placeholder: PropTypes.string,
  showDivider: PropTypes.bool,
  showShadowHeader: PropTypes.bool,
  sortMessagesEnabled: PropTypes.bool,
  trackHashtag: PropTypes.func,
};

export default injectIntl(Messages);
