import { takeEvery, call, put } from 'redux-saga/effects';
import {
  GET_POSTS,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  GET_BETHERE_PROPERTIES,
  GET_BETHERE_PROPERTIES_SUCCESS,
  GET_BETHERE_PROPERTIES_FAIL,
} from './actionTypes';

import { getPostsAPI, getPropertyValueAPI } from './api';

function* fetchGetPostBethereWorker({ payload }) {
  try {
    const data = yield call(getPostsAPI, payload);
    yield put({ type: GET_POSTS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_POSTS_FAIL, payload: e });
  }
}

function* fetchGetPropertiesWorker({ payload }) {
  try {
    const data = yield call(getPropertyValueAPI, payload);
    yield put({ type: GET_BETHERE_PROPERTIES_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_BETHERE_PROPERTIES_FAIL, payload: e });
  }
}


function* fetchGetPostBethereWatcher() {
  yield takeEvery(GET_POSTS, fetchGetPostBethereWorker);
}

function* fetchGetPropertiesWatcher() {
  yield takeEvery(GET_BETHERE_PROPERTIES, fetchGetPropertiesWorker);
}

export default { fetchGetPostBethereWatcher, fetchGetPropertiesWatcher };
