import { defineMessages } from 'react-intl';

export default defineMessages({
  advancedSearch: {
    id: 'navbar.advancedSearch',
    defaultMessage: 'ADVANCED SEARCH',
  },
  basicNoResults: {
    id: 'search.basicNoResults',
    defaultMessage: 'User not found',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'CANCEL',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'CLOSE',
  },
  changeStar: {
    id: 'sendStar.changeStar',
    defaultMessage: 'Change',
  },
  chooseRecognition: {
    id: 'sendStar.chooseRecognition',
    defaultMessage: 'Choose a recognition to send!',
  },
  confirm: {
    id: 'sendStar.confirm',
    defaultMessage: 'SEND STAR!',
  },
  confirmMultiplePlural: {
    id: 'sendStar.confirmMultiplePlural',
    defaultMessage: 'SEND {n} STARS!',
  },
  confirmMultipleSingular: {
    id: 'sendStar.confirmMultipleSingular',
    defaultMessage: 'SEND 1 STAR!',
  },
  confirmEdit: {
    id: 'sendStar.confirmEdit',
    defaultMessage: 'EDIT STAR',
  },
  confirmEditKudos: {
    id: 'sendStar.confirmEditKudos',
    defaultMessage: 'EDIT KUDOS',
  },
  confirmKudos: {
    id: 'sendStar.confirmKudos',
    defaultMessage: 'SEND KUDOS!',
  },
  editStar: {
    id: 'sendStar.editStar',
    defaultMessage: 'EDITING STAR',
  },
  kudosSuccessModalDescription: {
    id: 'sendStar.kudosSuccessModalDescription',
    defaultMessage: 'Your {starName} was sent succesfully!',
  },
  kudosSuccessModalDescriptionEdit: {
    id: 'sendStar.kudosSuccessModalDescriptionEdit',
    defaultMessage: 'Your {starName} was edited succesfully!',
  },
  minimum: {
    id: 'sendStar.minimum',
    defaultMessage: 'Select {minimum} or more people',
  },
  multipleUsersClarification: {
    id: 'sendStar.multipleUsersClarification',
    defaultMessage: 'You will send one star to each of your colleagues',
  },
  noResults: {
    id: 'search.noResults',
    defaultMessage: 'User not found, did you tried our advanced search?',
  },
  noRemainingStars: {
    id: 'sendStar.noRemainingStars',
    defaultMessage: "It seems you don't have enough stars to select this! Try selecting fewer colleagues.",
  },
  noRemainingStarsKudos: {
    id: 'sendStar.noRemainingStarsKudos',
    defaultMessage: "It seems you don't have enough stars to add another colleague! Try selecting fewer people.",
  },
  sameStarInAPeriodDescription: {
    id: 'sendStar.sameStarInAPeriodDescription',
    defaultMessage: 'You already assigned the star **{starName}** during this period to:',
  },
  sameStarInAPeriodFooter: {
    id: 'sendStar.sameStarInAPeriodFooter',
    defaultMessage: 'Please try again by changing the value.',
  },
  sameStarInAPeriodTitle: {
    id: 'sendStar.sameStarInAPeriodTitle',
    defaultMessage: 'OOPS, YOU CANNOT SENT THIS VALUE',
  },
  searchPlaceholder: {
    id: 'sendStar.searchPlaceholder',
    defaultMessage: 'Find colleagues by their name or email...',
  },
  searchPlaceholderCarbonCopy: {
    id: 'sendStar.searchPlaceholderCarbonCopy',
    defaultMessage: 'You could tell others about this star!',
  },
  searchPlaceholderKudos: {
    id: 'sendStar.searchPlaceholderKudos',
    defaultMessage: 'Find colleagues by their name, email or paste a list of emails.',
  },
  sendStars: {
    id: 'sendStar.confirm',
    defaultMessage: 'Send Stars',
  },
  sendStarsServerError: {
    id: 'sendStar.serverError',
    defaultMessage: 'OOPS! SOMETHING WENT WRONG',
  },
  sendStarsServerErrorHelper: {
    id: 'sendStar.serverErrorHelper',
    defaultMessage: 'Try again in a few minutes',
  },
  sendingStar: {
    id: 'sendStar.sending',
    defaultMessage: 'SENDING STAR...',
  },
  sendingStarMultiple: {
    id: 'sendStar.sendingMultiple',
    defaultMessage: 'SENDING {n} STARS...',
  },
  successfulySent: {
    id: 'sendStar.successfulySent',
    defaultMessage: 'STAR SUCCESSFULLY SENT!',
  },
  successfulySentMultipleSingular: {
    id: 'sendStar.successfulySentMultipleSingular',
    defaultMessage: 'Your star was sent successfully',
  },
  successfulySentMultiple: {
    id: 'sendStar.successfulySentMultiple',
    defaultMessage: 'Your stars were sent successfully',
  },
  successModalDescriptionEdit: {
    id: 'sendStar.successModalDescriptionEdit',
    defaultMessage: 'Your star to {user} was edited successfully',
  },
  successModalDescription: {
    id: 'sendStar.successModalDescription',
    defaultMessage: 'Your star to {user} was sent successfully',
  },
  successModalNote: {
    id: 'sendStar.successModalNote',
    defaultMessage: 'Keep sending your stars to rise to the top of the leaderboard!',
  },
  successModalTitleGol: {
    id: 'sendStar.successModalTitleGol',
    defaultMessage: 'Goal!',
  },
  successModalTopTen: {
    id: 'sendStar.successModalTopTen',
    defaultMessage: 'TOP TEN GIVERS',
  },
  tellReason: {
    id: 'sendStar.tellReason',
    defaultMessage: 'Please tell us the reason you are recognizing your colleague',
  },
  uploadImageError: {
    id: 'sendStar.uploadImageError',
    defaultMessage: 'The photo could not be loaded.',
  },
  usersNotFoundModalErrorTitle: {
    id: 'usersNotFound.modalErrorTitle',
    defaultMessage: 'USERS NOT FOUND',
  },
  usersNotFoundModalErrorDescription: {
    id: 'usersNotFound.modalErrorDescription',
    defaultMessage: 'The following users could not found in StarMeUp, Try removing them from the receivers to send the recognition.',
  },
  what: {
    id: 'sendStar.what',
    defaultMessage: 'what?',
  },
  who: {
    id: 'sendStar.who',
    defaultMessage: 'Who?',
  },
  why: {
    id: 'sendStar.why',
    defaultMessage: 'why?',
  },
  noStarAvailable: {
    id: 'sendStar.noStarAvailable',
    defaultMessage: 'Hooray! You already sent all your stars this month. Next month you can continue recognizing your colleagues work!',
  },
});
