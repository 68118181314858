import { defineMessages } from 'react-intl';

export default defineMessages({
  mainSearchActionAdvanced: {
    id: 'mainSearch.actionAdvanced',
    defaultMessage: 'Advanced Search',
  },
  mainSearchActionDiscover: {
    id: 'mainSearch.actionDiscover',
    defaultMessage: 'Discover Colleagues',
  },
  mainSearchColleagues: {
    id: 'mainSearch.colleagues',
    defaultMessage: 'Colleagues',
  },
  mainSearchEmptyStateSubtitle: {
    id: 'mainSearch.emptyStateSubtitle',
    defaultMessage: 'Try the advanced search',
  },
  mainSearchEmptyStateTitle: {
    id: 'mainSearch.emptyStateTitle',
    defaultMessage: 'Do you need to be more specific?',
  },
  mainSearchHashtags: {
    id: 'mainSearch.hashtags',
    defaultMessage: 'Hashtags',
  },
  mainSearchPlaceholder: {
    id: 'mainSearch.placeholder',
    defaultMessage: 'Search Colleagues',
  },
});
