// @packages
import { selectResult } from 'smu-utils/reduxRequests/selectors';

// @app
import { selectItemById } from 'services/items/selectors';

// @own
import { REQUEST_ID_FEED } from './constants';

export const selectFeedPosts = (state) => {
  const posts = selectResult(state, REQUEST_ID_FEED)?.posts;
  const filteredPosts = posts?.reduce((acc, cur) => {
    const deleted = selectItemById(state, cur?.post_id)?.deleted;
    const exist = acc.some((i) => i?.post_id === cur?.post_id);
    if (!deleted && !exist) acc.push(cur);
    return acc;
  }, []);

  return filteredPosts;
};
