import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import {
  addParams,
  formData,
  times,
  vo,
} from './../../api';

export function saveTCAndPPAgreement() {
  return apiInstance({
    url: addParams('/v2/stellar/users/tcpp/accept', { ...vo, ...times() }),
    ...formData,
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
