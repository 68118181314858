import {
  ACTION_CONGRATULATION_ADD_COMMENT,
  ACTION_CONGRATULATION_ADD_ITEM,
  ACTION_CONGRATULATION_ADD_LIKE,
  ACTION_CONGRATULATION_REMOVE_LIKE,
  ACTION_CONGRATULATION_UPDATE_COMMENTS,
} from './actionTypes';

export function actionAddComment(id) {
  return {
    type: ACTION_CONGRATULATION_ADD_COMMENT,
    payload: {
      id,
    },
  };
}

export function actionAddItem(id, data) {
  return {
    type: ACTION_CONGRATULATION_ADD_ITEM,
    payload: {
      data,
      id,
    },
  };
}

export function actionAddLike(id) {
  return {
    type: ACTION_CONGRATULATION_ADD_LIKE,
    payload: {
      id,
    },
  };
}

export function actionRemoveLike(id) {
  return {
    type: ACTION_CONGRATULATION_REMOVE_LIKE,
    payload: {
      id,
    },
  };
}

export function actionUpdateComments(id, comments) {
  return {
    type: ACTION_CONGRATULATION_UPDATE_COMMENTS,
    payload: {
      data: {
        comments,
      },
      id,
    },
  };
}
