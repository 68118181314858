const copyToClipboard = value => {
  const input = document.createElement('input');
  input.value = value;
  input.style.position = 'absolute';
  input.style.left = '-9999px';
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
};

export default copyToClipboard;
