// @packages
import React, { useState, useEffect } from 'react';
import Button from 'smu-ui-components/Button';
import Divider from 'smu-ui-components/Divider';
import Icon from 'smu-ui-components/Icon';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import cn from 'classnames';
import moment from 'moment';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  selectError,
  selectRequesting,
  selectResult,
  selectResultProp,
} from 'smu-utils/reduxRequests/selectors';

// @app
import ChillOutConfig from 'components/Widgets/ChillOutConfig';

// @own
import {
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  DEFAULT_TOAST_TIMEOUT,
  FORMAT_DATE,
  FORMAT_TIME,
  REQUEST_ID_GET_CHILLOUT_CONFIG,
  REQUEST_ID_PUT_CHILLOUT_CONFIG,
  WIDGET_CHILLOUT_MODAL,
} from './constants';
import './styles.scss';
import { apiGetChilloutConfig, apiPutChilloutConfig } from './api';

function ChillOutModal({
  actionRequestInit,
  addToastMessage,
  close,
  days,
  error,
  errorPut,
  hobbies,
  onCancel,
  onClose,
  onSave,
  onSubmitSuccess,
  preferences,
  requesting,
  requestingPut,
  result,
  resultPut,
  savedPreferences,
}) {
  const [showConfig, setShowConfig] = useState(false);
  const isLoading = requesting || requestingPut || !showConfig;
  const prevRequesting = usePrevious(requesting);
  const prevRequestingPut = usePrevious(requestingPut);
  const getDayByTime = (day, time) => {
    const weekday = moment().weekday(day);
    const newDayFormat = moment(weekday).format(FORMAT_DATE);
    const newDayTime = moment(`${newDayFormat} ${time}`, `${FORMAT_DATE} ${FORMAT_TIME}`);
    return moment(newDayTime)
      .add(moment().utcOffset(), 'minutes')
      .day();
  };

  const normaliceValues = () => {
    const startTime = get(days, '0.startTime');
    const endTime = get(days, '0.endTime');

    return {
      days: savedPreferences
        ? days.map(({ startDay, startTime }) => getDayByTime(startDay, startTime))
        : Array.from(Array(5), (_, i) => i + 1),
      startTime: startTime
        ? moment(startTime, FORMAT_TIME)
          .add(moment().utcOffset(), 'minutes')
          .format(FORMAT_TIME)
        : DEFAULT_START_TIME,
      endTime: endTime
        ? moment(endTime, FORMAT_TIME)
          .add(moment().utcOffset(), 'minutes')
          .format(FORMAT_TIME)
        : DEFAULT_END_TIME,
      hobbies: hobbies.map(hobbie => ({
        ...hobbie,
        value: savedPreferences ? hobbie.value : true,
      })),
      preferences: preferences.map(preference => ({
        ...preference,
        value: savedPreferences ? preference.value : true,
      })),
    };
  };

  const getDayProps = (day, startTime, endTime) => {
    const selectedDay = moment().weekday(day);
    const selectedFormatDay = selectedDay.format(FORMAT_DATE);
    const startDateTime = moment(
      `${selectedFormatDay} ${startTime}`,
      `${FORMAT_DATE} ${FORMAT_TIME}`,
    );
    const endDateTime = moment(`${selectedFormatDay} ${endTime}`, `${FORMAT_DATE} ${FORMAT_TIME}`);

    return {
      startDay: moment(startDateTime)
        .utc()
        .day(),
      startTime: moment(startDateTime)
        .utc()
        .format(FORMAT_TIME),
      endDay: moment(endDateTime)
        .utc()
        .day(),
      endTime: moment(endDateTime)
        .utc()
        .format(FORMAT_TIME),
    };
  };

  const [values, setValues] = useState({});

  const onSubmit = () => {
    const customValues = {
      days: values.days.map(day => getDayProps(day, values.startTime, values.endTime)),
      hobbies: values.hobbies,
      preferences: values.preferences,
    };

    onSave();
    setShowConfig(false);
    actionRequestInit({
      api: apiPutChilloutConfig,
      id: REQUEST_ID_PUT_CHILLOUT_CONFIG,
      params: customValues,
    });
  };

  const handleClose = () => {
    onClose();
    close();
  };

  const handleCancel = () => {
    onCancel();
    close();
  };

  useEffect(() => {
    actionRequestInit({
      api: apiGetChilloutConfig,
      id: REQUEST_ID_GET_CHILLOUT_CONFIG,
    });
  }, []);

  useEffect(() => {
    const endRequesting = prevRequesting && !requesting;
    const endRequestingPut = prevRequestingPut && !requestingPut;

    if (!requesting) {
      setValues(normaliceValues());
    }

    if (endRequesting) {
      if (!error && result) {
        setShowConfig(true);
      } else {
        close();
      }
    }

    if (endRequestingPut) {
      if (!errorPut && resultPut) {
        onSubmitSuccess();
        addToastMessage({
          message: 'Your preferences have been set correctly',
          timeout: DEFAULT_TOAST_TIMEOUT,
        });
        close();
      } else {
        setShowConfig(true);
      }
    }
  }, [requesting, requestingPut]);

  return (
    <div className="suite-widget-chillout-modal-wrapper">
      <div
        className={cn('suite-widget-chillout-modal', {
          'suite-widget-chillout-modal--loading': isLoading,
        })}
      >
        <header className="suite-widget-chillout-modal__header">
          <h4 className="suite-widget-chillout-modal__title">WELCOME TO YOUR VIRTUAL CHILL-OUTS</h4>
          <label className="suite-widget-chillout-modal__subtitle">
            How much time would you like to share with your colleagues?
          </label>
          <label className="suite-widget-chillout-modal__text">
            Set your preferences, availability, interests and hobbies. If there is matching with
            existing events, you’ll see them in StarMeUp homepage.
          </label>
          <Icon
            className="suite-widget-chillout-modal__icon-close"
            color="black"
            icon="close"
            onClick={handleClose}
            size="jumbo"
            type="base"
          />
        </header>
        <div className="suite-widget-chillout-modal__body">
          {isLoading && (
            <div className="suite-widget-chillout-modal__loading">
              <LottieSpinner height={50} width={50} loadingType="starmeup" />
            </div>
          )}
          <ChillOutConfig
            className="suite-widget-chillout-modal__config"
            onChange={(name, value) => setValues({ ...values, [name]: value })}
            values={values}
          />
        </div>
        <Divider className="suite-widget-chillout-modal__divider" />
        <footer className="suite-widget-chillout-modal__footer">
          <Button
            active
            color="black"
            disabled={isLoading}
            onClick={handleCancel}
            secondary
            size="large"
          >
            CANCEL
          </Button>
          <Button
            active
            color="black"
            disabled={isLoading}
            onClick={onSubmit}
            primary
            size="large"
          >
            SAVE
          </Button>
        </footer>
      </div>
    </div>
  );
}

ChillOutModal.defaultProps = {
  onCancel: () => {},
  onClose: () => {},
  onSave: () => {},
  onSubmitSuccess: () => {},
};

ChillOutModal.propTypes = {
  actionRequestInit: PropTypes.func.isRequired,
  addToastMessage: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  days: PropTypes.array,
  error: PropTypes.object,
  errorPut: PropTypes.object,
  hobbies: PropTypes.array,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  preferences: PropTypes.array,
  requesting: PropTypes.bool,
  requestingPut: PropTypes.bool,
  result: PropTypes.object,
  resultPut: PropTypes.object,
  savedPreferences: PropTypes.bool,
};

const mapStateToProps = state => ({
  days: selectResultProp(state, REQUEST_ID_GET_CHILLOUT_CONFIG, 'days', []),
  error: selectError(state, REQUEST_ID_GET_CHILLOUT_CONFIG),
  hobbies: selectResultProp(state, REQUEST_ID_GET_CHILLOUT_CONFIG, 'hobbies', []),
  preferences: selectResultProp(state, REQUEST_ID_GET_CHILLOUT_CONFIG, 'preferences', []),
  requesting:
    selectRequesting(state, REQUEST_ID_GET_CHILLOUT_CONFIG),
  requestingPut: selectRequesting(state, REQUEST_ID_PUT_CHILLOUT_CONFIG),
  resultPut: selectResult(state, REQUEST_ID_PUT_CHILLOUT_CONFIG),
  result: selectResult(state, REQUEST_ID_GET_CHILLOUT_CONFIG),
  savedPreferences: selectResultProp(
    state,
    REQUEST_ID_GET_CHILLOUT_CONFIG,
    'savedPreferences',
    false,
  ),
});

export default RootModal(
  connect(mapStateToProps, { actionRequestInit, addToastMessage })(ChillOutModal),
  WIDGET_CHILLOUT_MODAL,
  {
    background: 'transparent',
    maxWidth: '1120px',
    width: '100%',
  },
  'suite-widget-chillout-modal-wrapper',
);
