// @packages
import { apiInstance, OSMemberApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

// @app
import { times, vo } from './../../api';

export function getLocales({ languageCode, organizationToken }) {
  const baseUrl = '/v2/stellar/resources/locale';
  return new Promise((resolve) => {
    apiInstance({
      url: baseUrl,
      method: 'get',
      params: {
        ...vo,
        ...times(),
        languageCode,
        organizationToken,
      },
    })
      .then(({ data }) => resolve(data))
      .catch(() => resolve({}));
  });
}

export function getLanguages() {
  const baseUrl = '/v2/stellar/languages';
  return apiInstance({
    url: baseUrl,
    method: 'get',
    params: {
      ...vo,
      ...times(),
    },
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}

export function changeLanguage(data) {
  return OSMemberApiInstance({
    url: 'api/v1/community/member/language',
    method: 'put',
    data,
    params: {
      ...vo,
      ...times(),
    },
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}
