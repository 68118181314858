// @packages
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { isEmpty } from 'lodash';
// @app
import Option from 'components/QuestionnaireResults/Option';
import QuestionHeader from 'components/QuestionnaireResults/QuestionHeader';
import SurveyAnswerDetailContainer from 'containers/SurveyAnswerDetailContainer';
import { trackEvent } from 'utils/gtm';
// @own
import './styles.scss';
import optionsConfig from './options';

function QuestionnaireResultsGraphics({
  className,
  description,
  id,
  enabledMobile,
  options,
  questionnaireId,
  summary,
  summaryTexts,
  title,
  tooltipsTexts,
  type,
}) {
  const [details, setDetails] = useState({
    answer: '',
    isOpen: false,
    option: '',
    users: [],
  });
  const chartRef = useRef();
  const graphicsConfig = optionsConfig(options, tooltipsTexts);
  function getName({ background, text, title }) {
    const str = title || text;
    const url = background?.small?.path;
    return url || str;
  }

  function normaliceOptions() {
    return options
      .sort((a, b) => a.weight - b.weight)
      .map((option) => ({
        name: getName(option),
        optionsId: option?.id,
        totalUsers: option?.quantity,
        users: option?.memberIds,
        y: option?.percentage,
      }));
  }

  const trackAnalytics = ({action}) => {
    trackEvent({
      action,
      category: 'questionnaires',
      label: `${title}. ${type}`,
      q_id: questionnaireId,
      q_name: description,
    });
  };

  const handleAnswerClick = (data) => {
    setDetails({
      chartNumber: title,
      isOpen: true,
      onClose: () => setDetails({ isOpen: false }),
      questionId: id,
      questionText: description,
      type,
      ...data,
    });
  };

  const config = {
    ...graphicsConfig,
    chart: {
      ...graphicsConfig.chart,
      height: options.length * 40 + 20,
    },
    yAxis: {
      ...graphicsConfig.yAxis,
    },
    series: [
      {
        ...graphicsConfig.series[0],
        data: normaliceOptions(),
      },
    ],
    plotOptions: {
      ...graphicsConfig.plotOptions,
      series: {
        ...graphicsConfig.plotOptions.series,
        cursor: 'pointer',
        point: {
          events: {
            click: (e) => {
              const users = e.point?.users;
              if (!isEmpty(users)) {
                handleAnswerClick({
                  answer: e.point?.y,
                  option: e.point?.name,
                  optionId: e.point?.optionsId,
                  totalUsers: e.point?.totalUsers || 0,
                  users,
                });
              }
              trackAnalytics({ action: 'results_chart_click' });
            },
            mouseOver: () => trackAnalytics({ action: 'results_chart_hover' }),
          },
        },
      },
    },
  };

  useEffect(() => {
    const chart = chartRef?.current?.chart;
    if (chart) {
      chart.reflow();
    }
  }, [enabledMobile]);

  return (
    <div
      className={cn(
        'questionnaire-results-graphics',
        {
          [`questionnaire-results-graphics--${type?.toLowerCase()}`]: type,
          'questionnaire-results-graphics--mobile': enabledMobile,
        },
        className,
      )}
    >
      <QuestionHeader
        className="questionnaire-results-graphics__header"
        description={description}
        title={title}
      />
      <div className="questionnaire-results-graphics__body">
        <div className="questionnaire-results-graphics__chart-wrapper">
          <HighchartsReact
            containerProps={{
              className: 'questionnaire-results-graphics__chart',
            }}
            highcharts={Highcharts}
            options={config}
            ref={chartRef}
          />
        </div>
        <div className="questionnaire-results-graphics__info">
          <label className="questionnaire-results-graphics__info-title">
            {summaryTexts?.title}
          </label>
          <div className="questionnaire-results-graphics__info-summary">
            <Option
              enabledMobile={enabledMobile}
              label={summaryTexts?.answered}
              value={summary?.numberOfAnsweredPeople || 0}
            />
            {(summary?.average === 0 || summary?.average) && (
              <Option
                enabledMobile={enabledMobile}
                label={summaryTexts?.average}
                value={summary?.average}
              />
            )}
          </div>
          <SurveyAnswerDetailContainer className="questionnaire-results-graphics__detail" {...details} />
        </div>
      </div>
      <SurveyAnswerDetailContainer className="questionnaire-results-graphics__detail" {...details} />
    </div>
  );
}

QuestionnaireResultsGraphics.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  enabledMobile: PropTypes.bool,
  id: PropTypes.number,
  options: PropTypes.array,
  summary: PropTypes.object,
  summaryTexts: PropTypes.object,
  title: PropTypes.string.isRequired,
  tooltipsTexts: PropTypes.object,
  type: PropTypes.string,
};

QuestionnaireResultsGraphics.defaultProps = {
  className: '',
  description: '',
  enabledMobile: true,
  options: [],
  summaryTexts: {
    answered: 'Answered',
    average: 'Average',
    title: 'Question Summary',
  },
  tooltipsTexts: {
    answers: 'Answers',
  },
  type: '',
};

export default QuestionnaireResultsGraphics;
