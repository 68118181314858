import { defineMessages } from 'react-intl';

export default defineMessages({
  recognitionCustomers: {
    id: 'recognition.customers',
    defaultMessage: 'Customers',
  },
  recognitionColleagues: {
    id: 'recognition.colleagues',
    defaultMessage: 'Colleagues',
  },
  recognitionStarReceivedFrom: {
    id: 'recognition.starReceivedFrom',
    defaultMessage: 'Stars received from:',
  },
  newProfileTabReceived: {
    id: 'newProfile.tabReceived',
    defaultMessage: 'Received',
  },
  newProfileTabSent: {
    id: 'newProfile.tabSent',
    defaultMessage: 'Sent',
  },
  recognitionClearButton: {
    id: 'recognition.clearButton',
    defaultMessage: 'Clear',
  },
  recognitionApplyButton: {
    id: 'recognition.applyButton',
    defaultMessage: 'Apply',
  },
});
