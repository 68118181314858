import { stringify } from 'querystring';
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, formData, times, vo } from './../../api';

export function voteUserApi(payload) {
  const parameters = {
    identification: payload.userIdentification,
  };

  return apiInstance({
    url: addParams(`/v2/stellar/assignvote/${payload.voteId}`, { ...vo, ...times() }),
    method: 'post',
    ...formData,
    data: stringify(parameters),
  })
    .then(({ data: result }) => result)
    .catch(errorHandler);
}
