import omit from 'lodash/omit';
import { isModerator } from 'utils/checkRights';
import { selectItemById } from 'services/items/selectors';

export const feedResults = (state) => {
  const moderator = isModerator(state?.session);
  const results = state?.feed?.results;

  return results?.reduce((acc, cur) => {
    let item = selectItemById(state, cur?.id);
    let el = item || cur;

    if (!el.enabled && !moderator) {
      el = omit(el, 'notes');
    }

    if (el) {
      acc.push(el);
    }
    return acc;
  }, []);
};
