// @packages
import { defineMessages } from 'react-intl';

export default defineMessages({
  single: {
    title: {
      id: 'contentTypeBadge.single.title',
      defaultMessage: '{xxx} has earned a new badge!',
    },
    subtitle: {
      id: 'contentTypeBadge.single.subtitle',
      defaultMessage: 'Send a star with your congratulations!',
    },
    button: {
      sendStar: {
        id: 'contentTypeBadge.single.button.sendStar',
        defaultMessage: 'Send star',
      },
      share: {
        id: 'contentTypeBadge.single.button.share',
        defaultMessage: 'Share',
      },
    },
    buttonMenu: {
      shareAsMoment: {
        id: 'contentTypeBadge.single.buttonMenu.shareAsMoment',
        defaultMessage: 'Share as Moment',
      },
      shareOnSocialMedia: {
        id: 'contentTypeBadge.single.buttonMenu.shareOnSocialMedia',
        defaultMessage: 'Share on social media',
      },
    },
    shareAsMomentComment: {
      id: 'contentTypeBadge.single.shareAsMomentComment',
      defaultMessage: 'I have received this new badge! I celebrate this new milestone with you all, thank you for the support💪',
    },
  },
  group: {
    title: {
      id: 'contentTypeBadge.group.title',
      defaultMessage: '{xxx} and others have earned a badge!',
    },
    subtitle: {
      id: 'contentTypeBadge.group.subtitle',
      defaultMessage: 'See the full list and congratulate them by sending a star!',
    },
    button: {
      id: 'contentTypeBadge.group.button',
      defaultMessage: 'Check it out',
    },
    modalButton: {
      id: 'contentTypeBadge.group.modalButton',
      defaultMessage: 'Send star',
    },
    userPopupButton: {
      id: 'contentTypeBadge.group.userPopupButton',
      defaultMessage: 'View profile',
    },
  },
});
