// @app
import { paths } from 'utils/routes';

// @own
import Layout from './Layout';

export default {
  path: paths.settings(),
  component: Layout,
};
