// @packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const ActivityLayout = ({ params: { activityId }, router }) => {
  useEffect(() => {
    if (activityId) {
      router.replace(`/home?modal=star&id=${activityId}`);
    } else {
      router.replace('/404');
    }
  }, [activityId]);

  return null;
};

ActivityLayout.propTypes = {
  params: PropTypes.shape({
    activityId: PropTypes.string,
  }),
  router: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default ActivityLayout;
