// @packages
import React, { useContext, useEffect, useMemo, useState } from 'react';
import CardDateTime from 'smu-ui-components/Cards/components/DateTime';
import CardDescription from 'smu-ui-components/Cards/components/Description';
import CardSendBy from 'smu-ui-components/Cards/components/SendBy';
import CardStarTemplate from 'smu-ui-components/Cards/templates/Star';
import CardStarTitle from 'smu-ui-components/Cards/components/StarTitle';
import CardUserName from 'smu-ui-components/Cards/components/UserName';
import CulturalLevelsLabel from 'smu-app-components/CulturalLevels/Label';
import SendStarSuccessModal from 'smu-app-components/SendStar/SendSuccess/Modal';
import sendStarMessages from 'smu-app-components/SendStar/messages';
import useModal from 'smu-custom-hooks/useModal';
import useTheme from 'smu-custom-hooks/useTheme';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';
import { useBrightenStar } from 'smu-services/starmeup-api/v2/stellar/star/[id]/lift/get';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

// @app
import AvatarLink from 'components/AvatarLink';
import ModalInteractions from 'containers/Modals/Interactions';
import PostMenu from 'containers/PostMenu';
import StarActionBrightenContainer from 'containers/Cards/Star/ActionBrighten';
import StarActionCommentContainer from 'containers/Cards/Star/ActionComment';
import StarActionLikeContainer from 'containers/Cards/Star/ActionLike';
import StarActionShareContainer from 'containers/Cards/Star/ActionShare';
import TextParserContainer from 'containers/TextParser';
import UserInfoPopup from 'containers/UserInfoPopup';
import { ENABLED_SHARE_STAR } from 'services/communityConfigs/constants';
import { MODAL_SHARE_STAR } from 'containers/QueryModals/constants';
import { StarContext } from 'containers/OSFeed/OSFeedItem';
import { TYPES_STARS_INTERACTIONS, normalizeStarsInteractions } from 'utils/interactions';
import { actionItemsUpdate } from 'services/items/actions';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { getRemainingStars } from 'services/sendStarFlow/actions';
import { goToQueryModal } from 'containers/QueryModals/helpers';

// @own
import './styles.scss';
import MainPhoto from '../MainPhoto';
import Messages from '../Messages/index';
import withStarConext from '../StarContext';
import {
  getActionBrightenProps,
  getDateTimeProps,
  getDescriptionProps,
  getFullName,
  getMainPhoto,
  getMenuProps,
  getMessages,
  getProfileUrl,
  getSendByProps,
  getStarTitleProps,
  getTemplateProps,
  getUserAvatarProps,
  getUserBadgeProps,
} from './helpers';

const SimpleStar = (props) => {
  const { intl: { formatMessage }, isModerator, ownId, router } = props;
  const context = useContext(StarContext);
  const [expanded, setExpanded] = useState(context?.expanded);
  const [shouldBrightenStar, setShouldBrightenStar] = useState(false);
  const dispatch = useDispatch();
  const modalInteractions = useModal();
  const modalSendStarSuccess = useModal();
  const theme = useTheme();
  const comments = context?.data?.comments;
  const content = context?.data?.notes;
  const date = context?.data?.date;
  const enabled = context?.data?.enabled;
  const id = context?.data?.id;
  const isOwnProfile = context?.isOwnProfile;
  const notes = context?.data?.notes;
  const onEditStar = context?.onEditStar;
  const onLiftStar = context?.onLiftStar;
  const star = context?.data?.starMeUpOrganizationStar;
  const userFrom = context?.data?.from;
  const userTo = context?.data?.to;
  const isStarReceiver = ownId === userTo?.id;
  const showDescription = enabled || isModerator;
  const useBrightenStarHook = useBrightenStar({
    shouldFetch: shouldBrightenStar,
    starId: id,
  });
  const brightenedStar = useBrightenStarHook.selectResult();
  const isBrightening = useBrightenStarHook.isLoading
    || useBrightenStarHook.isValidating;
  const brightenStarError = useBrightenStarHook.error;
  const profileUrl = getProfileUrl(userTo);
  const fullName = getFullName(userTo);
  const mainPhoto = getMainPhoto(comments);
  const messages = getMessages(comments);
  const enabledShareStar = useSelector((state) =>
    getCommunityConfigValueBool(state, ENABLED_SHARE_STAR));

  const sendStarSuccessTexts = useMemo(() => ({
    culturalLevels: {
      title: formatMessage(sendStarMessages.sendSuccess.culturalLevels.title),
      titleMaxLevel: formatMessage(
        sendStarMessages.sendSuccess.culturalLevels.titleMaxLevel,
      ),
    },
    subtitle: formatMessage(
      sendStarMessages.sendSuccess.subtitle.brighten,
      { xxx1: userTo?.firstName },
    ),
    text: formatMessage(sendStarMessages.sendSuccess.text),
    title: formatMessage(sendStarMessages.sendSuccess.title),
  }), [userTo]);

  const themeHashtag = theme.getHashtagConfigByText(notes);

  let description;
  let bigImage;
  let commentsComponent;
  let userNameComponent;
  let userAvatarComponent;

  const handleEditStar = () => {
    router.push(buildSendStarModalUrl({
      params: { editId: id },
    }));

    if (onEditStar) onEditStar();
  };

  const handleBrightenStar = () => {
    setShouldBrightenStar(true);
    modalSendStarSuccess.open();
  };

  const handleBrightenStarSuccess = star => {
    dispatch(getRemainingStars());
    dispatch(actionItemsUpdate(id, star));
    if (onLiftStar) onLiftStar();

    trackEvent({
      action: 'brighten',
      category: 'card_activity',
      description: content,
      id,
      type: star?.name,
    });
  };

  const handleBrightenStarErrorAction = () => {
    useBrightenStarHook.mutate();
  };

  const handleExpandedComments = () => {
    setExpanded(!expanded);

    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: content,
      id,
      label: 'comment',
      type: star?.name,
    });
  };

  const handleLike = () => {
    trackEvent({
      action: 'like',
      category: 'card_activity',
      description: content,
      id,
      type: star?.name,
    });
  };

  const handleUnlike = () => {
    trackEvent({
      action: 'unlike',
      category: 'card_activity',
      description: content,
      id,
      type: star?.name,
    });
  };

  const handleAddComment = () => {
    trackEvent({
      action: 'comment_success',
      category: 'card_activity',
      description: content,
      id,
      type: star?.name,
    });
  };

  const handleAddCommentImage = () => {
    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: content,
      id,
      label: 'comment_add_picture',
      type: star?.name,
    });
  };

  const handleOpenInteractions = (type) => {
    const interactions = normalizeStarsInteractions(comments);

    modalInteractions.open({
      contentDescription: content,
      contentId: id,
      contentType: star?.name,
      defaultType: TYPES_STARS_INTERACTIONS[type],
      interactions,
    });

    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: content,
      id,
      label: type === 'LIKE' ? 'view_likes' : 'view_brighten',
      type: star?.name,
    });
  };

  const handleSendStarSuccessClose = () => {
    modalSendStarSuccess.close();
    setShouldBrightenStar(false);
  };

  const handleShare = () => {
    goToQueryModal(MODAL_SHARE_STAR, router, { id });

    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: content,
      id,
      label: 'share',
      type: star?.name,
    });
  };

  if (showDescription) {
    description = (
      <CardDescription>
        <TextParserContainer {...getDescriptionProps(context)} />
      </CardDescription>
    );
  }

  if (mainPhoto) {
    bigImage = <MainPhoto mainPhoto={mainPhoto} />;
  }

  if (expanded) {
    commentsComponent = (
      <Messages
        className="rounded-b-lg"
        expanded
        messages={messages}
        onAddComment={handleAddComment}
        onAddCommentImage={handleAddCommentImage}
      />
    );
  }

  if (fullName) {
    userNameComponent = (
      <UserInfoPopup
        trigger={<CardUserName href={profileUrl}>{fullName}</CardUserName>}
        userId={userTo?.id}
        userUID={userTo?.uid}
      />
    );
  }

  if (userTo && !isOwnProfile) {
    userAvatarComponent = (
      <UserInfoPopup
        trigger={<AvatarLink {...getUserAvatarProps(userTo, themeHashtag)} />}
        userId={userTo?.id}
        userUID={userTo?.uid}
      />
    );
  }

  useEffect(() => {
    if (brightenedStar) {
      handleBrightenStarSuccess(brightenedStar);
    }
  }, [brightenedStar]);

  return (
    <>
      <CardStarTemplate
        actionBrighten={
          <StarActionBrightenContainer
            onClickCount={() => handleOpenInteractions('LIFT_STAR')}
            onClickIcon={handleBrightenStar}
            {...getActionBrightenProps(context)}
          />
        }
        actionComment={
          <StarActionCommentContainer
            isModerator={isModerator}
            messages={messages}
            onClickIcon={handleExpandedComments}
          />
        }
        actionLike={
          <StarActionLikeContainer
            activeImage={themeHashtag?.likeSelectedImageCode}
            comments={comments}
            inactiveImage={themeHashtag?.likeDefaultImageCode}
            onClickCount={() => handleOpenInteractions('LIKE')}
            onLike={handleLike}
            onUnlike={handleUnlike}
            starId={id}
          />
        }
        actionShare={(isStarReceiver && enabledShareStar) ?
          <StarActionShareContainer
            messages={messages}
            onClickIcon={handleShare}
          /> : null
        }
        bigImage={bigImage}
        className="shadow-base"
        comments={commentsComponent}
        dateTime={<CardDateTime {...getDateTimeProps(date)} />}
        description={description}
        menu={<PostMenu onEdit={handleEditStar} {...getMenuProps(props)} />}
        sendBy={
          <UserInfoPopup
            trigger={<CardSendBy {...getSendByProps(props, context)} />}
            userId={userFrom?.id}
            userUID={userFrom?.uid}
          />
        }
        title={<CardStarTitle {...getStarTitleProps(context)} />}
        userAvatar={userAvatarComponent}
        userBadge={<CulturalLevelsLabel {...getUserBadgeProps(userTo, isStarReceiver)} />}
        userName={userNameComponent}
        {...getTemplateProps(themeHashtag)}
      />
      {modalInteractions.isOpen && (
        <ModalInteractions
          onClose={modalInteractions.close}
          open={modalInteractions.isOpen}
          {...modalInteractions.data}
        />
      )}
      <SendStarSuccessModal
        className="w-144"
        isLoading={isBrightening}
        onClose={handleSendStarSuccessClose}
        onErrorActionClick={handleBrightenStarErrorAction}
        open={modalSendStarSuccess.isOpen}
        showError={!!brightenStarError}
        texts={sendStarSuccessTexts}
      />
    </>
  );
};

export default withStarConext(withRouter(injectIntl(SimpleStar)), 'SIMPLE_STAR');
