import TimelineHashtags from 'components/TimelineHashtags';
import { paths } from 'utils/routes';

export default () => ({
  component: TimelineHashtags,
  onEnter: ({ params: { hashtag } }, replace) => {
    if (/activate/i.test(hashtag)) {
      replace('/me/be-kind');
    } else {
      window.scrollTo(0, 0);
    }
  },
  path: paths.hashtags(),
});
