// @packages
import React from 'react';
import Panel from 'smu-ui-components/Panel';

// @app
import Title from 'components/Title';

// @own
import './styles.scss';

function QuestionnaireResultsError({ imgSrc, subtitle, title }) {
  return (
    <Panel className="smu-questionnaire-results-error">
      <img
        alt="error"
        className="smu-questionnaire-results-error__image"
        src={imgSrc}
      />
      <Title className="smu-questionnaire-results-error__title">{title}</Title>
      <span className="smu-questionnaire-results-error__subtitle">
        {subtitle}
      </span>
    </Panel>
  );
}

QuestionnaireResultsError.defaultProps = {
  imgSrc: '/assets/img/error-state-results.svg',
  susbtitle: 'Please try to refresh the page later',
  title: 'The survey results cannot be displayed',
};

export default QuestionnaireResultsError;
