// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { postUploadShareImage } from './api';
import { REQUEST_UPLOAD_SHARE_IMAGE } from './actionTypes';
import { actionUploadShareImageFail, actionUploadShareImageSuccess } from './actions';

function* getUploadShareImageWorker({ id, payload }) {
  try {
    const { data } = yield call(postUploadShareImage, payload);
    yield put(actionUploadShareImageSuccess({ result: data?.result }, id));
  } catch (error) {
    yield put(actionUploadShareImageFail({ error }, id));
  }
}

export function* getUploadShareImageWatcher() {
  yield takeEvery(REQUEST_UPLOAD_SHARE_IMAGE, getUploadShareImageWorker);
}

export default {
  getUploadShareImageWatcher,
};
