// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

// @app
import SendStarConfirmation from 'containers/SendStarConfirmation';
import SendStarContainer from 'containers/SendStar';
import UserSearch from 'containers/UserSearch';
import sendStarMessages from 'containers/CoreValuesPanel/messages';
import { ORIGIN } from 'services/sendStarFlow/constants';
import { normalizeUsers } from 'containers/SendStar/helpers';
import { selectHasStarsToSend, selectRemainingStarsSuccess } from 'services/sendStarFlow/selectors';
import { setInitial, setNone } from 'services/sendStarFlow/actions';

// @own
import './styles.scss';
import { STEPS } from './constants';

function SendStar({
  appearance,
  intl: { formatMessage },
  onChange,
  option,
  requesting,
}) {
  const dispatch = useDispatch();
  const [step, setStep] = useState(STEPS.PICK_USER);
  const hasStarsToSend = useSelector(selectHasStarsToSend);
  const remainingStarsSuccess = useSelector(selectRemainingStarsSuccess);

  const handleUserSelect = user => {
    if (hasStarsToSend && remainingStarsSuccess) {
      dispatch(setInitial({
        comment: option?.data?.starComment,
        coreValueId: option?.data?.coreValueId,
        entities: normalizeUsers([user]),
        hideModal: true,
        origin: ORIGIN.SURVEYS,
      }));

      setStep(STEPS.SEND_STAR);
    } else {
      dispatch(addToastMessage({
        message: formatMessage(sendStarMessages.noStarAvailable),
        timeout: 10000,
      }));
    }
  };

  const handleSend = result => {
    onChange(
      Array.isArray(result)
        ? result.map(star => star.starId).join()
        : result.id,
    );

    setStep(STEPS.STAR_SENT);
  };

  const handleCancelOrClose = () => {
    setStep(STEPS.PICK_USER);
    dispatch(setNone());
  };

  useEffect(() => {
    if (requesting && step === STEPS.STAR_SENT) {
      setStep(STEPS.PICK_USER);
      dispatch(setNone());
    }
  }, [requesting]);

  return (
    <div className="questionnaires-flex-type-send-star">
      {step === STEPS.PICK_USER && (
        <UserSearch
          className="questionnaires-flex-type-send-star__user-search"
          onUserSelect={handleUserSelect}
        />
      )}
      {step === STEPS.SEND_STAR && (
        <SendStarContainer
          buttonColor={appearance}
          headerColor={appearance}
          onCancel={handleCancelOrClose}
          onClose={handleCancelOrClose}
          onSend={handleSend}
          origin={ORIGIN.SURVEYS}
        />
      )}
      {step === STEPS.STAR_SENT && (
        <SendStarConfirmation horizontalRecipients origin={ORIGIN.SURVEYS} />
      )}
    </div>
  );
}

SendStar.propTypes = {
  appearance: PropTypes.string,
  intl: PropTypes.object,
  onChange: PropTypes.func,
  option: PropTypes.object,
  requesting: PropTypes.bool,
};

export default injectIntl(SendStar);
