export const ERROR_CONFIG = 'CONFIG';
export const ERROR_MENU = 'MENU';
export const ERROR_THEME = 'THEME';
export const ERROR_TOKEN = 'TOKEN';
export const ERROR_TRANSLATIONS = 'TRANSLATIONS';

export const PATH_CONFIG =
  '/os-member/api/v1/community/preferences/configurations';
export const PATH_MENU = '/os-member/api/v2/community/member/menu';
export const PATH_THEME = '/notifications/v3/i18n/resources';
export const PATH_TOKEN = '/starmeup-api/v2/sec/userwithtoken';
export const PATH_TRANSLATIONS = '/starmeup-api/v2/stellar/resources/locale';

export const ERRORS_BY_PATH = {
  [PATH_CONFIG]: ERROR_CONFIG,
  [PATH_MENU]: ERROR_MENU,
  [PATH_THEME]: ERROR_THEME,
  [PATH_TOKEN]: ERROR_TOKEN,
  [PATH_TRANSLATIONS]: ERROR_TRANSLATIONS,
};

export const PATHS = [
  PATH_CONFIG,
  PATH_MENU,
  PATH_THEME,
  PATH_TOKEN,
  PATH_TRANSLATIONS,
];
