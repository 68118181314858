// @app
import { castStringToBoolean } from 'utils/formatString';

export const getCommunityConfigError = (state) =>
  state?.communityConfigs?.error;
export const getCommunityConfigFetching = (state) =>
  state?.communityConfigs?.fetching;
export const getCommunityConfigResult = (state) =>
  state?.communityConfigs?.result;
export const getCommunityConfigHasResult = (state) =>
  !!state?.communityConfigs?.result;
export const getCommunityConfigValue = (state, value) =>
  state?.communityConfigs?.result?.[value];
export const getCommunityConfigValueBool = (state, value) =>
  castStringToBoolean(getCommunityConfigValue(state, value));
