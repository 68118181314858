// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import cn from 'classnames';
import './styles.scss';

function QuestionnaireProgressBar({ maxQuestions, currentQuestion }) {
  const barsText = new Array(maxQuestions)
    .fill(1)
    .map((blankQuestion, questionIndex) => (
      <span
        className={cn(
          'questionnaire-progressbar__question',
          { 'questionnaire-progressbar__question--active': questionIndex === currentQuestion },
        )}
        key={`question-${questionIndex}`}
      />
    ));
  return (
    barsText.length > 0
    && <div className="questionnaire-progressbar">{barsText}</div>
  );
}
QuestionnaireProgressBar.defaultProps = {
  currentQuestion: 0,
  maxQuestions: 0,
};

QuestionnaireProgressBar.propTypes = {
  currentQuestion: PropTypes.number,
  maxQuestions: PropTypes.number,
};

export default QuestionnaireProgressBar;
