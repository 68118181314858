// @packages
import React from 'react';
import FullScreenModal from 'smu-app-components/FullScreenModalV2';

// @own
import { FULLSCREEN_MODAL_POST } from './constants';
import './styles.scss';

const FullScreenModalPost = ({ children }) => (
  <div className="fullscreen-modal-post">
    {children}
  </div>
);

export default FullScreenModal(FullScreenModalPost, FULLSCREEN_MODAL_POST);
