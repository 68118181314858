// @packages
import React from 'react';
import PropTypes from 'prop-types';
import YIRStories from 'smu-app-components/YIR';
import { detectWebView } from 'smu-utils/device';
import { useDispatch, useSelector } from 'react-redux';

// @app
import { DEFAULT_PARAMS } from 'containers/OSFeed/constants';
import {
  ENABLED_BETHERE_FEED,
  ENABLED_STAR_SUGGESTION_TEXT,
  ENABLED_YIR,
} from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import {
  getCommunityConfigurationValue,
  selectCommunityName,
  selectSessionStars,
} from 'containers/Authorization/selectors';
import { getOsFeed } from 'containers/OSFeed/actions';
import { getRemainingStars } from 'services/sendStarFlow/actions';
import { getSentStars } from 'containers/withProfile/actions';
import { isBeta } from 'utils/env';
import {
  selectHasStarsToSend,
  selectRemainingStarsSuccess,
} from 'services/sendStarFlow/selectors';

// @own
import Share from './Share';

const YIR = ({ closeModal, ...rest }) => {
  const dispatch = useDispatch();
  const [
    enabledBeThere,
    enabledMentions,
    enabledWriteForMe,
    enabledYIR,
  ] = useSelector((state) => ([
    getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED),
    getCommunityConfigurationValue(state, 'mentionsEnabled'),
    getCommunityConfigValueBool(state, ENABLED_STAR_SUGGESTION_TEXT),
    getCommunityConfigValueBool(state, ENABLED_YIR),
  ]));
  const communityName = useSelector(selectCommunityName);
  const coreValues = useSelector(selectSessionStars);
  const hasStarsToSend = useSelector(selectHasStarsToSend);
  const remainingStarsSuccess = useSelector(selectRemainingStarsSuccess);
  const userCanSendStars = hasStarsToSend && remainingStarsSuccess;
  const isWebView = detectWebView();

  const handleSendStarSuccess = () => {
    if (!isWebView) {
      dispatch(getSentStars());
      dispatch(getRemainingStars());

      if (window.location.pathname.includes('/home')) {
        dispatch(getOsFeed({ params: DEFAULT_PARAMS }));
      }
    }
  };

  if (!enabledYIR && !isBeta()) {
    if (closeModal) {
      closeModal();
    }

    return null;
  }

  return (
    <YIRStories
      communityName={communityName}
      components={{ Share }}
      coreValues={coreValues}
      enabledBeThere={enabledBeThere}
      enabledMentions={enabledMentions}
      enabledWriteForMe={enabledWriteForMe}
      isWebView={isWebView}
      onSendStarSuccess={handleSendStarSuccess}
      rounded
      userCanSendStars={userCanSendStars}
      year={2024}
      {...rest}
    />
  );
};

YIR.propTypes = {
  closeModal: PropTypes.func,
};

export default YIR;
