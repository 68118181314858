import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UserAvatar from 'smu-ui-components/UserAvatar';
import lightenDarkenColor from 'utils/lightenDarkenColor';
import Image from 'components/Image';
import astronaut from 'assets/astronaut.png';

import './styles.scss';

const StarCard = ({
  className,
  color,
  defaultProfileImageCode,
  disabled,
  imageCode,
  name,
  onCardClick,
  profileImageCode,
  userName,
}) => (
  <div
    className={cn('star-card', className, {
      'star-card--disable': disabled,
    })}
    onClick={!disabled ? onCardClick : undefined}
    style={!disabled
      ? { background: `linear-gradient(${lightenDarkenColor(color, 50)}, ${color})` }
      : { background: '#c2c7cc' }}
  >
    <div className={cn('star-card__avatar', {
      'star-card__avatar--disable': disabled,
    })}
    >
      {profileImageCode && profileImageCode === defaultProfileImageCode ?
        <div className="star-card__avatar--no-image">
          <img
            alt="user"
            height={60}
            src={astronaut}
            width={60}
          />
          <div className="star-card__avatar-name">{userName}</div>
        </div>
        :
        <Fragment>
          {profileImageCode ?
            <UserAvatar
              className="star-card__avatar-image"
              width={120}
              height={120}
              profileImageCode={profileImageCode}
            /> :
            <img
              alt="user"
              height={120}
              src={astronaut}
              width={120}
            />
          }
        </Fragment>
      }
    </div>
    <div className={cn('star-card__footer', {
      'star-card__footer--disable': disabled,
    })}
    >
      {imageCode &&
        <div className="star-card__footer-image">
          <Image
            code={imageCode}
            width={15}
            height={15}
          />
        </div>
      }
      <div className="star-card__footer-name">
        {name}
      </div>
    </div>
  </div>
);


StarCard.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  defaultProfileImageCode: PropTypes.string,
  disabled: PropTypes.bool,
  imageCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onCardClick: PropTypes.func,
  profileImageCode: PropTypes.string,
  userName: PropTypes.string,
};


export default StarCard;
