// @packages
import React from 'react';
import InputArea from 'smu-ui-components/InputArea';
import PropTypes from 'prop-types';
import get from 'lodash/get';

// @own
import './styles.scss';

function TextField({ onChange, option }) {
  const hint = get(option, 'data.hint');
  const max = get(option, 'data.maximumCharacters', 1200);
  const value = get(option, 'selected.data.value', '');

  return (
    <InputArea
      className="questionnaires-flex-type-textfield"
      maxLength={max}
      onChange={e => onChange(e.target.value)}
      placeholder={hint}
      value={value}
    />
  );
}

TextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};

export default TextField;
