// @packages
import React from 'react';
import Icon from 'smu-ui-components/IconV2';
import Link from 'smu-ui-components/Link';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';

function CongratulationsWidget({ className, linkProps, text }) {
  return (
    <Link
      className={cn(
        'block group bg-white shadow-base rounded-md overflow-hidden smu-components-congratulations-widget',
        className,
      )}
      legacy={false}
      underline="none"
      {...linkProps}
    >
      <Paper className="flex items-center py-4 px-3 w-full group-hover:bg-lavander group-hover:bg-opacity-40">
        <img
          alt="congratulations"
          className="h-8 mr-2 w-8"
          src="/assets/img/congratulations.svg"
        />
        <Typography
          className="text-sm text-blue"
          variant="subtitle2"
        >
          {text}
        </Typography>
        <Icon
          className="ml-auto text-sm text-blue"
          icon="arrow-alt-right"
        />
      </Paper>
    </Link>
  );
}

CongratulationsWidget.defaultProps = {
  linkProps: {
    href: '/congratulations',
  },
  text: "Check today's special dates",
};

CongratulationsWidget.propTypes = {
  className: PropTypes.string,
  linkProps: PropTypes.object,
  text: PropTypes.string,
};

export default CongratulationsWidget;
