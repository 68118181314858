import { GET_EULA, SAVE_EULA } from './actionTypes';

export function getEula() {
  return {
    type: GET_EULA,
  };
}

export function saveEulaAgreement() {
  return {
    type: SAVE_EULA,
  };
}
