// @packages
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// @app
import ShareButtons from 'components/ShareButtons';
import { getSocialMedias } from 'containers/Authorization';

// @own
import config from './config';

const ShareButtonsContainer = ({ shareUrl, ...rest }) => {
  const socialMedias = useSelector((state) => getSocialMedias(state.session));
  const buttons = socialMedias
    ?.filter((s) => s?.enabled)
    ?.map((social) => ({
      color: config?.[social?.type]?.color,
      disabled: !shareUrl,
      icon: social?.icon,
      name: config?.[social?.type]?.name,
      onClick: () => config?.[social?.type]?.onClick(shareUrl),
    }));

  return <ShareButtons buttons={buttons} {...rest} />;
};

ShareButtonsContainer.propTypes = {
  shareUrl: PropTypes.string,
};

export default ShareButtonsContainer;
