// @packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';

function ActivityCommentsLayout({ params: { starId }, router }) {
  useEffect(() => {
    if (starId) {
      router.push(`/me/activity/${starId}`);
    } else {
      router.push('/home');
    }
  }, []);

  return null;
}

ActivityCommentsLayout.propTypes = {
  params: PropTypes.shape({
    cardId: PropTypes.string,
  }),
  router: PropTypes.object.isRequired,
};

export default ActivityCommentsLayout;
