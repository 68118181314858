// @own
import { sendStar } from './urls';
import { INSIGHT_TYPES } from '../constants';

export const GRID_WIDTHS_PER_COLUMNS = {
  1: { GRID: '30%', COLUMN: '100%' },
  2: { GRID: '60%', COLUMN: '48%' },
  3: { GRID: '100%', COLUMN: '32%' },
};

export const ACTION_URL_PER_INSIGHT_TYPE = {
  [INSIGHT_TYPES.WHO_REPRESENTS]: {
    MISS_SOMEBODY: sendStar,
    USER: sendStar,
  },
};

export const SEND_STAR_COMMENT_PER_INSIGHT_TYPE = {
  [INSIGHT_TYPES.WHO_REPRESENTS]: 'You are an invaluable person, someone who really represents this value. #YouRock',
};
