// @packages
import React, { useEffect } from 'react';
import Modal from 'smu-app-components/WhatsNew/components/Modal';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';
import { SMU_APP_MODAL_WHATS_NEW } from 'smu-app-components/WhatsNew/components/Modal/constants';
import { closeModal, openModal } from 'smu-app-components/RootModalV2/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// @app
import { STAR_SELECTOR_VERSION } from 'services/communityConfigs/constants';
import { getCommunityConfigValue } from 'services/communityConfigs/selectors';
import { selectCommunityId } from 'containers/Authorization/selectors';

// @own
import { MODAL_QUERY_WHATS_NEW } from './constants';

const WhatsNewModal = RootModal(Modal, SMU_APP_MODAL_WHATS_NEW, {}, '');

function WhatsNewModalContainer({
  closeModal,
  communityId,
  location,
  openModal,
  router,
  starSelectorVersion,
}) {
  const modal = location?.query?.modal;
  const isWhatsNew = modal === MODAL_QUERY_WHATS_NEW;
  const isGBL = communityId === 2;
  const showModal = isGBL && starSelectorVersion === 'V2';

  const handleCloseModal = () => {
    router.push(location.pathname);
    closeModal();
  };

  const handleOpenModal = () =>
    openModal({
      modalType: SMU_APP_MODAL_WHATS_NEW,
      modalProps: {
        onClose: handleCloseModal,
        version: '6',
      },
    });

  useEffect(() => {
    if (isWhatsNew && showModal) {
      handleOpenModal();
    }
  }, [isWhatsNew, showModal]);

  return <WhatsNewModal />;
}

WhatsNewModalContainer.propTypes = {
  closeModal: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.shape({
      modal: PropTypes.string,
    }),
  }),
  communityId: PropTypes.number,
  openModal: PropTypes.func,
  router: PropTypes.object,
  starSelectorVersion: PropTypes.string,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  starSelectorVersion: getCommunityConfigValue(
    state,
    STAR_SELECTOR_VERSION,
  ),
});

export default connect(mapStateToProps, { closeModal, openModal })(
  withRouter(WhatsNewModalContainer),
);
