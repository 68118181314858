// @ Packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'smu-ui-components/ButtonV2';
import Divider from 'smu-ui-components/Divider';
import Icon from 'smu-ui-components/IconV2';
import Panel from 'smu-ui-components/Panel';
import Tabs from 'smu-ui-components/Tabs';
import cn from 'classnames';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// @ App
import FilterSelect from 'components/FilterSelect';
import SkeletonLeaderboard from 'components/Skeletons/Leaderboard';
import TopStar from 'components/TopStar';
import messages from 'containers/LeaderboardV2/messages';
import { FILTER_TYPES } from 'containers/FiltersV2/constants';
import {
  LEADERBOARD_TYPE_SENDER,
  LEADERBOARD_TYPE_RECEIVER,
} from 'containers/LeaderboardV2/constants';
import { getDefaultValues } from 'containers/FiltersV2/functions';
import {
  getNewLeaderboard,
  persistLeaderboardFields,
} from 'containers/LeaderboardV2/actions';
import { handleSelection } from 'components/Select';

// @ Own
import './styles.scss';

export class LeaderboardV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersPanelOpen: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleApplyFilters = this.handleApplyFilters.bind(this);
    this.handleCancelFilters = this.handleCancelFilters.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toogleFilters = this.toogleFilters.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchingFilters && !this.props.fetchingFilters) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, isLoading, defaultValues } = this.props;
    if (!isLoading) {
      dispatch(
        getNewLeaderboard({
          filtersApplied: false,
          persistedFields: defaultValues,
          updateLeaderboard: true,
        }),
      );
    }
  }

  handleApplyFilters() {
    const { applyFilters, dispatch, defaultValues } = this.props;
    const applyFields = applyFilters?.payload?.fields;
    const fields = omit(applyFields, 'typeLeaderboard');

    this.toogleFilters();
    if (!isEqual(fields, defaultValues)) {
      dispatch(applyFilters);
    }
  }

  handleCancelFilters() {
    const { cancelFilters, dispatch } = this.props;
    this.toogleFilters();
    dispatch(cancelFilters);
  }

  toogleFilters() {
    this.setState((prevState) => ({
      filtersPanelOpen: !prevState.filtersPanelOpen,
    }));
  }

  handleInputChange(fields, filterId, value) {
    const { dispatch } = this.props;
    let selection = {};
    selection[filterId] = value;
    dispatch(
      persistLeaderboardFields({
        fields: merge(fields, selection),
      }),
    );
  }

  renderSelect(filterProps) {
    const { availableFilters, field, fields, filterId, label, options, value } =
      filterProps;
    const { dispatch } = this.props;
    if (options && keys(options).length > 0) {
      const parentValue = parseInt(
        field.parentField ? fields[field.parentField] : 0,
        10,
      );
      const allOption = options.ALL.find((item) => item.id === '0') || {};
      const optionsToRender = parentValue
        ? [allOption, ...options[parentValue]]
        : options.ALL;
      return (
        <div className="leaderboard__filter-select-wrapper">
          <span className="leaderboard__filter-select-label">{label}</span>
          <FilterSelect
            avoidTranslation
            className="leaderboard__filter-select"
            key={filterId}
            field={filterId}
            onChange={(selected) =>
              dispatch(
                persistLeaderboardFields({
                  fields: handleSelection(
                    availableFilters,
                    field,
                    fields,
                    filterId,
                    selected,
                  ),
                }),
              )
            }
            options={optionsToRender}
            orderByLabel
            value={value}
          />
        </div>
      );
    }
    return null;
  }

  renderInput(filterProps) {
    const { fields, key, label, placeholder, value } = filterProps;

    return (
      <div className="leaderboard__filter-input-wrapper" key={key}>
        <span className="leaderboard__filter-input-label">{label}</span>
        <input
          className="leaderboard__filter-input"
          onChange={(e) => this.handleInputChange(fields, key, e.target.value)}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      </div>
    );
  }

  renderFiltersPanel() {
    const {
      intl: { formatMessage },
      leaderboardFilters,
    } = this.props;

    return (
      <Panel noPadding className="ui-components suite-filters leaderboard !border-0 rounded-lg shadow-base">
        <div className="suite-filters__header">
          <p className="suite-filters__header-text">
            {formatMessage(messages.setupFilters)}
          </p>
          <Button
            className="suite-filters__button"
            onClick={this.handleCancelFilters}
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button
            className="suite-filters__button suite-filters__button--cta"
            onClick={this.handleApplyFilters}
          >
            {formatMessage(messages.apply)}
          </Button>
        </div>
        <Divider nomargin />
        <div className="leaderboard__filters-wrapper">
          {map(leaderboardFilters, (filterProps) => {
            const { type } = filterProps;
            let filter;
            if (type === 'STRING') {
              filter = this.renderInput(filterProps);
            } else {
              filter = this.renderSelect(filterProps);
            }
            return filter;
          })}
        </div>
      </Panel>
    );
  }

  renderTabsPanel() {
    const {
      activeTab,
      dispatch,
      filtersApplied,
      getReceiver,
      getSender,
      intl: { formatMessage },
    } = this.props;

    const tabs = [
      {
        name: formatMessage(messages.receiver),
        onClick: () => dispatch(getReceiver),
      },
      {
        name: formatMessage(messages.sender),
        onClick: () => dispatch(getSender),
      },
    ];

    return (
      <Panel noPadding className="leaderboard__filter-tabs !border-0 rounded-lg shadow-base">
        <Tabs tabs={tabs} active={activeTab} />
        <Button
          onClick={this.toogleFilters}
          variant={filtersApplied ? undefined : 'outline'}
          className="leaderboard__filter-button"
        >
          <Icon icon="filters" size="small" />
        </Button>
      </Panel>
    );
  }

  render() {
    const { filtersPanelOpen } = this.state;
    const {
      isLoading,
      intl: { formatMessage },
      location: { pathname },
      noUsers,
      topUsers,
    } = this.props;
    const isWidget = pathname.indexOf('/widget') !== -1;
    return (
      <div>
        {filtersPanelOpen ? this.renderFiltersPanel() : this.renderTabsPanel()}
        {isLoading ? (
          <SkeletonLeaderboard className="rounded-lg shadow-base" />
        ) : (
          <>
            {!noUsers && (
              <Panel className="!border-0 rounded-lg shadow-base overflow-hidden" noPadding>
                <ul className="leaderboard-list">
                  {topUsers?.map((value) => {
                    const className = cn('leaderboard-list__item', {
                      'leaderboard-list__item--highlighted': value.current,
                    });
                    return (
                      <div className={className} key={value.id}>
                        <span className="leaderboard-list__item-position">
                          {value.position > 99 ? '+99' : value.position}
                        </span>
                        <TopStar
                          target={isWidget ? '_blank' : '_self'}
                          {...value}
                        />
                      </div>
                    );
                  })}
                </ul>
              </Panel>
            )}
            {noUsers && !isLoading && (
              <Panel className="!border-0 rounded-lg shadow-base">{formatMessage(messages.noResults)}</Panel>
            )}
          </>
        )}
      </div>
    );
  }
}

LeaderboardV2.defaultProps = {
  topUsers: [],
};

LeaderboardV2.propTypes = {
  activeTab: PropTypes.number.isRequired,
  applyFilters: PropTypes.object.isRequired,
  cancelFilters: PropTypes.object.isRequired,
  defaultValues: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  fetchingFilters: PropTypes.bool,
  fields: PropTypes.object,
  filters: PropTypes.object,
  filtersApplied: PropTypes.bool,
  getReceiver: PropTypes.object.isRequired,
  getSender: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  noUsers: PropTypes.bool,
  leaderboardFilters: PropTypes.array.isRequired,
  session: PropTypes.object,
  topUsers: PropTypes.array,
};

const mapStateToProps = ({ filtersV2, leaderboardsV2, session }) => {
  const { user } = session;
  const { fetching, fields, filtersApplied, results } = leaderboardsV2;

  const { LEADERBOARD, fetching: fetchingFilters } = filtersV2;

  const filterKeys = keys(LEADERBOARD) || [];

  const defaultValues = getDefaultValues(LEADERBOARD);

  const leaderboardFilters = filterKeys.reduce((filters, filter) => {
    if (LEADERBOARD[filter].type === FILTER_TYPES.STRING) {
      const input = {
        field: LEADERBOARD[filter],
        fields,
        key: filter,
        label: LEADERBOARD[filter].label,
        type: LEADERBOARD[filter].type,
        value: fields[filter] || '',
      };
      filters.push(input);
    } else {
      const select = {
        availableFilters: LEADERBOARD,
        field: LEADERBOARD[filter],
        fields,
        filterId: filter,
        label: LEADERBOARD[filter].label,
        options: LEADERBOARD[filter].values,
        type: LEADERBOARD[filter].type,
        value: fields[filter],
      };
      filters.push(select);
    }
    return filters;
  }, []);

  const { typeLeaderboard } = fields;
  const topUsers = map(results, ([userToShow, amount, position]) => ({
    ...pick(userToShow, [
      'id',
      'profileImageCode',
      'firstName',
      'lastName',
      'job',
      'uid',
    ]),
    amount,
    identification: userToShow.uid,
    current: userToShow.uid === user.uid,
    position,
  }));

  return {
    activeTab: typeLeaderboard === LEADERBOARD_TYPE_SENDER ? 1 : 0,
    applyFilters: getNewLeaderboard({
      fields,
      filtersApplied: true,
      updateLeaderboard: true,
    }),
    cancelFilters: getNewLeaderboard({
      fields: { typeLeaderboard },
      filtersApplied: false,
      persistedFields: defaultValues,
      updateLeaderboard: true,
    }),
    fetching,
    fetchingFilters,
    fields,
    filters: LEADERBOARD,
    filtersApplied,
    getReceiver: getNewLeaderboard({
      fields: merge({}, fields, { typeLeaderboard: LEADERBOARD_TYPE_RECEIVER }),
      updateLeaderboard: true,
    }),
    getSender: getNewLeaderboard({
      fields: merge({}, fields, { typeLeaderboard: LEADERBOARD_TYPE_SENDER }),
      updateLeaderboard: true,
    }),
    noUsers: topUsers && topUsers.length === 0,
    defaultValues,
    leaderboardFilters,
    topUsers,
    isLoading: fetchingFilters || fetching,
  };
};

export default withRouter(connect(mapStateToProps)(injectIntl(LeaderboardV2)));
