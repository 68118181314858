// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';

// @app
import AvatarSMU from 'components/AvatarSMU';
import Link from 'components/Link';
import MentionUserSkeleton from 'components/Skeletons/MentionUser';

// @own
import './styles.scss';

const MentionsPopupContent = ({
  email,
  fullName,
  imageCode,
  isLoading,
  messageViewProfile,
  onClickProfile,
  profileUrl,
}) => (
  <div className="user-popup">
    {isLoading ? (
      <MentionUserSkeleton className="user-popup__skeleton" />
    ) : (
      <>
        <AvatarSMU
          className="user-popup__image"
          height={50}
          href={profileUrl}
          imageCode={imageCode}
          imageHeight={50}
          imageWidth={50}
          onClick={onClickProfile}
          width={50}
        />
        <div className="user-popup__info">
          <Link href={profileUrl} onClick={onClickProfile}>
            <h4 className="user-popup__name">{fullName}</h4>
          </Link>
          <p className="user-popup__email">{email}</p>
          <Link href={profileUrl} onClick={onClickProfile}>
            <Button
              className="user-popup__profile-button"
              size="small"
              variant="outline"
            >
              <span className="user-popup__button--text">
                {messageViewProfile}
              </span>
            </Button>
          </Link>
        </div>
      </>
    )}
  </div>
);

export default MentionsPopupContent;
