// @packages
import uniqBy from 'lodash/uniqBy';

// @app
import { fullName } from 'utils/user';

export const helperNormalizeUsers = (users) => {
  const normalized =
    users?.map((user) => ({
      description: user?.user_job,
      id: user?.user_id,
      src: user?.user_image_code,
      title: fullName(user, 'user_name', 'user_lastname'),
    })) || [];

  return uniqBy(normalized, (u) => u?.id);
};
