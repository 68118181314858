// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import CardPhoto from 'components/CardPhoto';
import CardVideoContainer from 'containers/CardVideo';
import ImageModal from 'smu-ui-components/ImageModal';
import useModal from 'smu-custom-hooks/useModal';
import { trackEvent } from 'utils/gtm';

// @own
import { PHOTO, VIDEO, MAX_ASPECT_RATIO } from './constants';

function CardMedia({
  aspectRatio,
  className,
  id,
  image,
  maxAspectRatio,
  text,
  thumbnail,
  type,
  video,
}) {
  const modal = useModal();

  function handleClickImage() {
    trackEvent({
      action: 'Click on photo',
      category: 'Card Photo',
    });
    modal.open();
  }

  function buildPhoto() {
    return (
      <>
        <CardPhoto
          aspectRatio={aspectRatio}
          imageSrc={image}
          maxAspectRatio={maxAspectRatio}
          onClick={handleClickImage}
          thumbnailBlurUrl={thumbnail}
          title={text}
        />
        {modal.isOpen && (
          <ImageModal
            className="bt-card-media__modal"
            onClick={modal.close}
            onClose={modal.close}
            open
            src={image}
          />
        )}
      </>
    );
  }

  function buildVideo() {
    return (
      <CardVideoContainer
        aspectRatio={aspectRatio}
        maxAspectRatio={maxAspectRatio}
        thumbnailBlurUrl={thumbnail}
        playerProps={{
          url: video,
        }}
        videoId={id}
      />
    );
  }

  function buildComponent() {
    switch (type) {
      case PHOTO:
        return buildPhoto();
      case VIDEO:
        return buildVideo();
      default:
        return null;
    }
  }

  return (
    <div className={cn('bt-card-media', className)}>
      {buildComponent()}
    </div>
  );
}

CardMedia.defaultProps = {
  aspectRatio: 0,
  className: '',
  image: '',
  maxAspectRatio: MAX_ASPECT_RATIO,
  text: '',
  thumbnail: '',
  type: '',
  video: '',
};

CardMedia.propTypes = {
  active: PropTypes.bool,
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.string,
  maxAspectRatio: PropTypes.number,
  text: PropTypes.string,
  thumbnail: PropTypes.string,
  type: PropTypes.oneOf([PHOTO, VIDEO]),
  video: PropTypes.string,
};

export default CardMedia;
