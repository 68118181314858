import {
  GET_MUTUAL_CONNECTIONS,
  GET_MUTUAL_CONNECTIONS_SUCCESS,
  GET_MUTUAL_CONNECTIONS_FAIL,
} from './actionTypes';

const initialState = {
  fetching: false,
  members: [],
  totalConnections: 0,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_MUTUAL_CONNECTIONS:
      return {
        ...state,
        fetching: true,
        members: [],
        totalConnections: 0,
      };
    case GET_MUTUAL_CONNECTIONS_SUCCESS:
      return {
        members: [...payload.members],
        totalConnections: payload.totalConnections,
        fetching: false,
      };
    case GET_MUTUAL_CONNECTIONS_FAIL:
      return {
        ...state,
        fetching: false,
        members: [],
        totalConnections: 0,
      };
    default:
      return state;
  }
}
