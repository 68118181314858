// @packages
import { get } from 'lodash';

// @own
import {
  EN,
  ENGLISH,
  ES,
  LANGUAGE_NONE,
} from '../constants';

function getLanguage(language) {
  return !language || language === LANGUAGE_NONE ? ENGLISH : language.toUpperCase();
}

export function getCurrent(questionnaire) {
  const categories = get(questionnaire, 'category', []);
  const language = getLanguage(get(questionnaire, 'language'));
  const lang = language === ENGLISH ? EN : ES;

  let data = {
    category: undefined,
    categoryId: undefined,
    categoryKey: undefined,
    isCompleted: false,
    lang,
    language: getLanguage(get(questionnaire, 'language')),
    question: undefined,
    questionId: undefined,
    questionKey: undefined,
    showComments: false,
    subQuestion: undefined,
    subQuestionId: undefined,
    subQuestionKey: undefined,
  };

  function getSubquestionNotAnswered(question) {
    const subQuestions = get(question, 'subQuestions', []);

    return subQuestions.reduce(
      (acc, cur, key) => {
        if (!acc.data && !cur.isAnswered && !cur.isSkipped) {
          acc.data = cur;
          acc.key = key;
        }

        return acc;
      },
      {
        data: undefined,
        key: undefined,
      },
    );
  }

  categories.forEach((category, categoryKey) => {
    const questions = get(category, 'questions', []);
    const hasComment = get(category, 'hasComment', false);
    const isCommentAnswered = get(category, 'isCommentAnswered', false);

    questions.forEach((question, keyQuestion) => {
      const subQuestion = getSubquestionNotAnswered(question);

      if (!data.category && (subQuestion.data || !question.isAnswered)) {
        data = {
          ...data,
          category,
          categoryId: category.categoryId,
          categoryKey,
          categoryName: category.categoryName,
          question,
          questionId: question.id,
          questionItems: get(question, `alternative.${lang}`),
          questionKey: keyQuestion,
          questionText: get(question, `questionText.${lang}`),
          subQuestion: subQuestion.data,
          subQuestionId: get(subQuestion, 'data.id'),
          subQuestionKey: subQuestion.key,
          subQuestionsItems: get(subQuestion, `data.alternative.${lang}`, []),
        };
      }
    });

    if (!data.category && hasComment && !isCommentAnswered) {
      data = {
        ...data,
        category,
        categoryId: category.categoryId,
        categoryKey,
        categoryName: category.categoryName,
        showComments: true,
      };
    }
  });

  if (questionnaire && !data.question && !data.subQuestion && !data.showComments) {
    data.isCompleted = true;
  }

  return data;
}
