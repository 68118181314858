// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { selectRequest } from 'smu-utils/reduxRequests/selectors';
import { trackEvent } from 'smu-utils/gtm';

// @app
import Messages from 'components/Messages';
import { selectMentionEnabled } from 'containers/withCongratulation/selectors';

// @own
import { getRequestId } from './utils';
import { apiAddComment } from './api';

function CongratulationComment({
  actionRequestInit,
  mentionsEnabled,
  onCommentedSuccess,
  request,
  sourceType,
  type,
  user,
  ...rest
}) {
  const [commentValue, setCommentValue] = useState('');

  function addComment(comment) {
    trackEvent({
      action: 'comment_success',
      category: 'card_activity',
      type,
    });
    actionRequestInit({
      api: apiAddComment,
      id: getRequestId(sourceType, user?.uid),
      onSuccess: onCommentedSuccess,
      params: {
        comment,
        sourceType,
        toUserId: user?.id,
      },
    });
  }

  function handleAddComment() {
    const validComment =
      commentValue?.comment && String(commentValue?.comment).trim();
    if (!request.requesting && validComment) {
      addComment(commentValue?.comment);
    }
  }

  return (
    <div className="congratulation-comment">
      <Messages
        emojisStyle={{
          paddingBottom: '60px',
          right: '70px',
          top: '39px',
          transform: 'none',
        }}
        handleIconClick={handleAddComment}
        loading={request.requesting}
        mentionsEnabled={mentionsEnabled}
        onInputChange={setCommentValue}
        {...rest}
      />
    </div>
  );
}

CongratulationComment.propTypes = {
  actionRequestInit: PropTypes.func.isRequired,
  mentionsEnabled: PropTypes.bool,
  onCommentedSuccess: PropTypes.func,
  request: PropTypes.object.isRequired,
  type: PropTypes.string,
};

CongratulationComment.defaultProps = {
  onCommentedSuccess: () => undefined,
};

const mapStateToProps = (state, ownProps) => ({
  mentionsEnabled: selectMentionEnabled(state),
  request: selectRequest(
    state,
    getRequestId(ownProps?.sourceType, ownProps?.user?.uid),
  ),
});

export default connect(mapStateToProps, {
  actionRequestInit,
})(CongratulationComment);
