// @packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const BethereLayout = ({ params: { cardId }, router }) => {
  useEffect(() => {
    if (cardId) {
      router.replace(`/home?modal=post&id=${cardId}`);
    } else {
      router.replace('/404');
    }
  }, [cardId]);

  return null;
};

BethereLayout.propTypes = {
  params: PropTypes.shape({
    cardId: PropTypes.string,
  }),
  router: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default BethereLayout;
