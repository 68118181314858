// @own
import {
  REQUEST_BETHERE_PROPERTIES,
  REQUEST_BETHERE_PROPERTIES_SUCCESS,
  REQUEST_BETHERE_PROPERTIES_FAIL,
} from './actionTypes';

const initialState = {
  error: undefined,
  requesting: false,
  result: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_BETHERE_PROPERTIES:
      return {
        ...initialState,
        requesting: true,
      };
    case REQUEST_BETHERE_PROPERTIES_SUCCESS:
      return {
        ...state,
        requesting: false,
        result: payload?.result,
      };
    case REQUEST_BETHERE_PROPERTIES_FAIL:
      return {
        ...state,
        error: payload?.error,
        requesting: false,
      };
    default:
      return state;
  }
}
