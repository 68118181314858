import moment from 'moment';
import {
  ANOTHER,
  TODAY,
  YESTERDAY,
} from './constants';

const getDiffBetweenDates = (date) => {
  const inputDate = moment(date);
  const currentDate = moment();
  return moment.duration(currentDate.diff(inputDate));
};

const getSentDay = (date, utc = false) => {
  const today = utc ? moment().utc() : moment();
  const yesterday = utc ? moment().subtract(1, 'day') : moment().subtract(1, 'day');
  let sentDay;

  if (date.isSame(today, 'day')) {
    sentDay = TODAY;
  } else if (date.isSame(yesterday, 'day')) {
    sentDay = YESTERDAY;
  } else {
    sentDay = ANOTHER;
  }

  return sentDay;
};

const getDay = (date, locale) => {
  const format = (locale && locale !== 'en') ? ('DD MMM, YYYY') : ('MMM DD, YYYY');
  return date.format(format).replace('.', '');
};

export {
  getDay,
  getDiffBetweenDates,
  getSentDay,
};
