import { defineMessages } from 'react-intl';

export default defineMessages({
  topStarsTitle: {
    id: 'widgets.topStarsTitle',
    defaultMessage: 'TOP STARS THIS MONTH',
  },
  topStarsTitleChampions: {
    id: 'widgets.topStarsTitleChampions',
    defaultMessage: 'TOP PLAYERS THIS MONTH',
  },
});
