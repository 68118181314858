import { reduce, merge } from 'lodash';

const addNamespace = (actions, namespace) => (
  reduce(
    actions,
    (acts, f, k) => merge(
      acts,
      {
        [k]: (...args) => {
          const action = f(...args);
          return {
            ...action,
            type: `@@${namespace}/${action.type}`,
          };
        },
      },
    ),
    {},
  )
);

export default addNamespace;
