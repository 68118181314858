import {
  GET_BADGES,
  GET_BADGES_SUCCESS,
  GET_BADGES_FAIL,
  CLEAN_BADGES,
} from './actionTypes';

const initialState = {
  results: [],
  fetching: true,
  error: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_BADGES:
      return {
        ...state,
        fetching: true,
      };
    case GET_BADGES_SUCCESS:
      return {
        ...state,
        results: payload,
        fetching: false,
        error: '',
        active: true,
      };
    case GET_BADGES_FAIL:
      return {
        ...state,
        results: [],
        fetching: false,
        error: payload,
        active: true,
      };
    case CLEAN_BADGES:
      return initialState;
    default:
      return state;
  }
}
