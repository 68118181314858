// @packages
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useStarById } from 'smu-services/starmeup-api/v2/stellar/feed/[id]';
import { withRouter } from 'react-router';

// @app
import ShareStarComponent from 'components/Share/Star';
import useBlobUrl from 'hooks/useBlobUrl';
import { buildImageSMU } from 'utils/image';
import {
  selectOrganizationImageId,
  selectOrganizationNegativeImageCode,
  selectOrganizationName,
} from 'containers/Authorization/selectors';
import useContentRendered from 'hooks/useContentRendered';

// @own
import messages from './messages';
import {
  helperOrganizationLogo,
} from './helpers';

function ShareStarContainer({
  intl: { formatMessage },
  router: {
    location,
  },
  onReady,
  onError,
}) {
  const starContentRef = useRef(null);
  const [hasRendered] = useContentRendered(starContentRef, { childList: true, subtree: true });
  const starId = location?.query?.id;
  const { selectResult, isLoading: loadingStar } = useStarById({ starId });
  const data = selectResult();
  const profileImageCode = data?.to?.profileImageCode;
  const job = data?.to?.job;
  const fullName = data?.to?.fullName;
  const negativeImageCode = useSelector((state) =>
    selectOrganizationNegativeImageCode(state));
  const imageCode = useSelector((state) => selectOrganizationImageId(state));
  const profileImage = buildImageSMU({
    code: profileImageCode,
    height: 160,
    width: 160,
  });
  const blobUrl = useBlobUrl(profileImage);
  const isLoading = blobUrl.isLoading || loadingStar;
  const isError = blobUrl.error;
  const isSuccess = blobUrl.data;
  const user =
    {
      imageUrl: blobUrl.data,
      job,
      name: fullName,
    };
  const organizationLogo = helperOrganizationLogo(imageCode, negativeImageCode);
  const star = {
    backgroundColor: data?.starMeUpOrganizationStar?.backgroundColor,
    content: data,
    imageCode: data?.starMeUpOrganizationStar?.imageCode,
    name: data?.starMeUpOrganizationStar?.name,
  };
  const organizationName = useSelector((state) => selectOrganizationName(state));
  const title = formatMessage(messages.shareStarTitle, { company: organizationName });

  useEffect(() => {
    if (isError) {
      onError(isError);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && hasRendered) {
      onReady();
    }
  }, [isSuccess, hasRendered]);

  return (
    <ShareStarComponent
      isError={isError}
      isLoading={isLoading}
      organizationLogo={organizationLogo}
      ref={starContentRef}
      star={star}
      title={title}
      user={user}
    />
  );
}

ShareStarContainer.propTypes = {
  intl: PropTypes.object.isRequired,
  onReady: PropTypes.func,
  onError: PropTypes.func,
};

ShareStarContainer.defaultProps = {
  onReady: () => undefined,
  onError: () => undefined,
};

export default withRouter(injectIntl(ShareStarContainer));
