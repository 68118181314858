export const buildFile = (files) => {
  const formData = new FormData();
  files.forEach(({ file, name }) => {
    if (file instanceof File || file instanceof Blob) {
      formData.append(name, file);
    } else {
      formData.append(name, file);
    }
  });
  return formData;
};

/**
 * Normalizes the description by replacing mentions with a specific format.
 * Mentions are identified using the pattern @[ID], where ID is a number.
 * This function replaces the mentions with the format @ID:ID.
 *
 * @param {string} description - The description to be normalized.
 * @returns {string} The normalized description.
 */
export const normalizeDescription = (description = '') => {
  const mentionsRegex = /@\[(\d+)\]/g;

  const newDescription = description.replace(mentionsRegex, '@ID:$1');

  return newDescription;
};
