// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { getStarsSummary } from './api';
import { REQUEST_STARS_SUMMARY } from './actionTypes';
import { actionStarsSummaryFail, actionStarsSummarySuccess } from './actions';

function* getStarsSummaryWorker({ id, payload }) {
  try {
    const { data } = yield call(getStarsSummary, payload);
    yield put(actionStarsSummarySuccess(id, { result: data?.result }));
  } catch (error) {
    yield put(actionStarsSummaryFail(id, { error }));
  }
}

export function* getStarsSummaryWatcher() {
  yield takeEvery(REQUEST_STARS_SUMMARY, getStarsSummaryWorker);
}

export default {
  getStarsSummaryWatcher,
};
