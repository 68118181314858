import {
  GET_TOP_RANK_USER,
  GET_TOP_RANK_USER_SUCCESS,
  GET_TOP_RANK_USER_FAIL,
} from './actionTypes';

const initialState = {
  topUsers: [],
  fetching: true,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TOP_RANK_USER:
      return {
        ...state,
        fetching: true,
      };
    case GET_TOP_RANK_USER_SUCCESS:
      return {
        ...state,
        topUsers: payload,
        fetching: false,
      };
    case GET_TOP_RANK_USER_FAIL:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
