import { VOTE_USER, VOTE_USER_SUCCESS, VOTE_USER_ERROR, VOTE_USER_CLEAR } from './actionTypes';

const initialState = {
  fetching: undefined,
  result: undefined,
  error: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case VOTE_USER:
      return {
        ...state,
        fetching: true,
        result: undefined,
        error: undefined,
      };
    case VOTE_USER_SUCCESS:
      return {
        ...state,
        fetching: false,
        result: payload,
        error: undefined,
      };
    case VOTE_USER_ERROR:
      return {
        ...state,
        fetching: false,
        result: undefined,
        error: payload,
      };
    case VOTE_USER_CLEAR:
      return {
        ...state,
        fetching: undefined,
        result: undefined,
        error: undefined,
      };
    default:
      return state;
  }
}
