// @packages
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import replace from 'lodash/replace';
import { push } from 'react-router-redux';

export const urls = {
  activity: '/me/activity/:activityId',
  activityComments: '/me/comments/:starId',
  advancedSearch: '/advancedSearch',
  beKind: 'be-kind/results',
  bethere: '/posts/:cardId',
  communications: '/communications',
  communityGuidelines: '/communityGuidelines',
  component: '/component/:name',
  congratulation: '/congratulation/:id',
  congratulationFeed: '/congratulation/feed/:userId',
  congratulationUser: '/congratulation/user/:userId',
  congratulations: '/congratulations',
  cookiesPolicy: '/cookies',
  defaultHome: '/',
  discover: '/discover',
  external: '/externalLink',
  givestar: '/givestar/:uid/:coreValueId',
  hashtags: '/hashtags/:hashtag',
  home: '/home(/questionnaire)(/:questionnaireId)',
  initiatives: '/initiatives(/:campaingnName)',
  insightDetails: '/insights/:id',
  insights: '/insights',
  leaderboard: '/leaderboard',
  logout: '/logout',
  myProfile: '/me(/sendStar)(/about-me)(/metrics)(/social-activity)(/communications)(/teams)(/sendStar)(/:sendStarProfile)',
  myTeam: '/myteam/dashboard',
  networkError: '/error503',
  newFeatures: '/updates',
  notificationsHistory: '/notifications',
  onboarding: '/onboarding',
  oneOnOneDetail: '/1on1/detail/:id',
  oneOnOneHome: '/1on1',
  profile: '/profile/:profileId(/sendStar)(/about-me)(/metrics)(/social-activity)(/communications)(/teams)(/sendStar)(/:sendStarProfile)',
  questionnaires: '/questionnaires',
  questionnaire: '/questionnaire/:questionnaireId',
  questionnaireResults: '/questionnaire/:questionnaireId/old-results',
  redirect: '/redirect',
  resetPassword: '/resetpassword',
  screenshot: '/screenshot',
  settings: '/settings/:tab',
  share: '/share',
  shareType: '/share/:type',
  surveysResults: '/questionnaire/:questionnaireId/results',
  test: '/test',
  timeline: '/timeline/:profileId/:direction',
  unsubscribe: '/unsubscribe',
  whatsnew: '/whats-new',
  widgetDNA: '/widgets/dna',
  widgetFeed: '/widgets/feed',
  widgetLeaderboard: '/widgets/leaderboard',
  widgetTopStars: '/widgets/top-stars',
  widgetTrends: '/widgets/trends',
  yir: '/yearInReview',
  notFound: '*',
};

const toPath = (url, params) => {
  const parsedUrl = url.replace(/ *\([^)]*\) */g, '');
  return reduce(params, (parsedUrl, v, param) => replace(parsedUrl, `:${param}`, String(v)), parsedUrl);
};

export const paths = reduce(
  urls,
  (memo, url, k) => {
    const f = params => toPath(url, params || {});
    return merge(memo, { [k]: f });
  },
  {},
);

export const actions = reduce(
  urls,
  (memo, url, k) => {
    const f = (params = {}, state) => push({
      pathname: toPath(url, params),
      state,
    });
    return merge(memo, { [k]: f });
  },
  {},
);
