// @packages
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { trackEvent } from 'smu-utils/gtm';

// @app
import { buildImageSMU } from 'utils/image';

// @own
import messages from './messages';
import { INTERACTIONS_TYPES, IMAGES, TYPE_ALL } from './constants';

const getPathProfile = (user, ownerUid) => {
  if (user?.uid === ownerUid) {
    return '/me';
  }
  return user?.uid ? `/profile/${user?.uid}` : undefined;
};

export const buildInteractions = (interactions, type, ownerUid) => {
  const users = interactions?.[type]?.users;
  const normalized =
    users?.map((user) => ({
      description: user?.job,
      href: getPathProfile(user, ownerUid),
      interaction: INTERACTIONS_TYPES[type],
      position: user?.position,
      src: buildImageSMU({
        code: user?.profileImageCode,
        height: 40,
        width: 40,
      }),
      title: user?.fullName,
      uid: user?.uid,
    })) || [];
  return uniqBy(normalized, (u) => u?.uid);
};

export const buildTab = ({
  active,
  contentDescription,
  contentId,
  contentType,
  formatMessage,
  interactions: allInteractions,
  ownerUid,
  setActive,
  type,
}) => {
  const interactions = buildInteractions(allInteractions, type, ownerUid);
  let name = allInteractions?.[type]?.total || interactions?.length || 0;

  if (type === TYPE_ALL) {
    name = formatMessage(messages.all);
  }

  return {
    active: active === type,
    image: IMAGES[type],
    interactions,
    isLoading: allInteractions?.[type]?.isLoading,
    name,
    onClick: () => {
      trackEvent({
        action: 'click',
        category: 'card_activity',
        description: contentDescription,
        id: contentId,
        label: `modal_${type}`,
        type: contentType,
      });

      setActive(type);
    },
    type,
  };
};

export const filterTabs = (tabs) => {
  let allInteractions = tabs?.[0]?.interactions;

  if (!allInteractions?.length) {
    allInteractions = tabs?.reduce((acc, cur) => {
      if (cur?.interactions?.length) {
        acc = acc.concat(cur?.interactions);
      }
      return acc;
    }, []);
  }

  if (allInteractions?.length) {
    tabs[0].interactions = sortBy(allInteractions, ['position']);
  }

  let filtered = tabs?.filter(
    (tab) => tab?.isLoading || tab?.interactions?.length,
  );

  if (filtered.length === 2 && filtered?.[0]?.type === TYPE_ALL) {
    filtered = [filtered[1]];
  }

  return filtered?.map(({ type, ...rest }) => ({ ...rest }));
};
