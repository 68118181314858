// @packages
import React, { forwardRef } from 'react';
import { createPortal } from 'react-dom';

// @app
import usePortal from 'hooks/usePortal';

// @own
import './styles.scss';

function SharePortal({ children, id }, ref) {
  const target = usePortal(id);

  return createPortal(
    <div className="share-portal">
      <div className="share-portal__content" ref={ref}>
        {children}
      </div>
    </div>,
    target,
  );
}

export default forwardRef(SharePortal);
