// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ImageGallery from 'smu-ui-components/ImageGallery';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import { withRouter } from 'react-router';
import { trackEvent } from 'smu-utils/gtm';

// @app
import MentionsParser from 'components/MentionsParser';
import Image from '../Image';
import messages from '../../messages';
import { formatHashtags } from '../../../common/utils/formatString';

// @own
import './styles.scss';

function formatImages(data) {
  return data.map((item) => ({
    alt: item.type,
    description: item?.description ? (
      <MentionsParser mentions={item?.mentions} text={item?.description}/>
    ) : (
      ''
    ),
    id: item.id,
    src: item.originalUrl,
  }));
}

function formatImagesToGallery(data) {
  return data.map(item => ({
    ...item,
    path: item.src,
    thumbnail: item.src,
  }));
}

function Container({ data, intl: { formatMessage }, isLoading, router }) {
  const [openGallery, setOpenGallery] = useState(false);
  const [idImage, setIdImage] = useState(null);
  const images = formatImages(data);
  const imagesFormatted = formatImagesToGallery(images);
  const current = data.find(d => d.id === idImage);
  const title = formatHashtags(current?.title || '', { tag: 'strong' });

  function handleOpenGallery(id) {
    const clickedImage = data.find(d => d.id === id);
    const position = data.map(d => d.id).indexOf(id);
    trackEvent({
      action: 'moments',
      category: 'click',
      label: `${clickedImage?.type} - ${position}`,
      description: clickedImage?.description ? clickedImage?.description : 'no_description',
    });
    setIdImage(id);
    setOpenGallery(true);
  }

  function handleOpenGalleryFromBanner() {
    trackEvent({
      action: 'moments_view_more',
      category: 'click',
    });
    router.push(`${router.location.pathname}?tab=bethere-feed`);
  }

  function handleOnCarouselSelect({ newSelectedImage }) {
    setIdImage(newSelectedImage.id);
  }

  useEffect(() => {
    trackEvent({
      action: 'moments',
      category: 'impression',
    });
  }, []);

  return (
    <div className="image-event-container">
      {isLoading ? (
        <LottieSpinner
          className="image-event-container__loader"
          height={40}
          loadingType="starmeup"
          width={40}
        />
      ) : (
        <>
          {images.slice(0, 4).map((item) => (
            <Image
              alt={item.alt}
              className="image-event-container__item"
              id={item.id}
              onClick={handleOpenGallery}
              src={item.src}
            />
          ))}
          <div
            className="image-event-container__last-item"
            onClick={handleOpenGalleryFromBanner}
          >
            <h5 className="image-event-container__text">
              {formatMessage(messages.ContentTypeTextBethere)}
            </h5>
          </div>
          {openGallery && (
            <ImageGallery
              defaultSelectedId={idImage}
              floatingGallery
              images={imagesFormatted}
              onBackImage={handleOnCarouselSelect}
              onCarouselSelect={handleOnCarouselSelect}
              onClose={() => setOpenGallery(false)}
              onNextImage={handleOnCarouselSelect}
              title={title}
              user={current?.user}
            />
          )}
        </>
      )}
    </div>
  );
}

Container.defaultProps = {
  data: [],
  isLoading: false,
};

Container.propTypes = {
  data: PropTypes.array,
  intl: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default withRouter(injectIntl(Container));
