// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';
import PropTypes from 'prop-types';

// @app
import AdvancedUserFilters from 'containers/AdvancedUserFilters';
import AdvancedUserSearch from 'containers/AdvancedUserSearch';

// @own
import './styles.scss';

function AdvancedUserSearchLayout({ router }) {
  const filters = router?.location?.query;

  return (
    <div className="smu-advanced-user-search-layout">
      <div className="smu-advanced-user-search-layout__filters">
        <Container centered>
          <AdvancedUserFilters filters={filters} />
        </Container>
      </div>
      <div className="smu-advanced-user-search-layout__users">
        <Container centered>
          <AdvancedUserSearch filters={filters} />
        </Container>
      </div>
    </div>
  );
}

AdvancedUserSearchLayout.propTypes = {
  params: PropTypes.object,
};

export default AdvancedUserSearchLayout;
