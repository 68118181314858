// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectRequest } from 'smu-utils/reduxRequests/selectors';

// @app
import DiscoverOptions from 'containers/DiscoverOptions';
import DiscoverUsers from 'components/DiscoverUsers';
import useDiscover from 'hooks/useDiscover';
import { SEGMENTS_USERS_LIST } from 'containers/DiscoverFilters/constants';
import { getLabelFilters } from 'utils/discover';
import { trackEvent } from 'utils/gtm';

// @own
import messages from './messajes';

function DiscoverUsersContainer({
  dispatch,
  intl: { formatMessage },
  requestUsers,
  ...rest
}) {
  const result = requestUsers?.result?.result;
  const users = result || [];
  const totalElements = requestUsers?.result?.page?.totalElements || 0;

  const {
    excludeUsers,
    filters,
    largeResults,
    resetExcludeUsers,
    setExcludeUsers,
  } = useDiscover();

  function handleCheckedUser(id) {
    let excluded = [...excludeUsers];
    const excludeIndex = excludeUsers.findIndex((u) => u === id);

    if (excludeIndex > -1) {
      trackEvent({
        action: 'reselect',
        category: 'discover',
        label: getLabelFilters(filters),
      });
      excluded.splice(excludeIndex, 1);
    } else {
      trackEvent({
        action: 'unselect',
        category: 'discover',
        label: getLabelFilters(filters),
      });
      excluded.push(id);
    }

    setExcludeUsers({
      excludeUsers: excluded,
      totalUsersSelected: users.length - excluded.length,
    });
  }

  function handleSelectAll() {
    trackEvent({
      action: 'select_all',
      category: 'discover',
      label: getLabelFilters(filters),
    });
    resetExcludeUsers({
      totalUsersSelected: users.length,
    });
  }

  function handleUnselectAll() {
    trackEvent({
      action: 'unselect_all',
      category: 'discover',
      label: getLabelFilters(filters),
    });
    setExcludeUsers({
      excludeUsers: users.map((u) => u.id),
      totalUsersSelected: 0,
    });
  }

  function handleClickUser() {
    trackEvent({
      action: 'click',
      category: 'discover',
      label: 'user',
    });
  }

  function getUsers() {
    return users?.map((u) => ({
      ...u,
      checked: !excludeUsers?.includes(u?.id),
    }));
  }

  function getEmptyText(key) {
    return formatMessage(
      messages[`discoverUsers${largeResults ? 'LargeResults' : 'Empty'}${key}`],
    );
  }

  const customUsers = getUsers();
  const selectedUsers = customUsers.filter((u) => u.checked);
  const selectedUsersLength = selectedUsers.length;
  const hasEnoughSelectedUsers = selectedUsersLength >= 1;
  const isLoading = requestUsers.requesting;
  const isEmpty = Array.isArray(result) && !result.length;
  const hasAllUsersSelected = selectedUsersLength === users.length;
  const texts = {
    disclaimer: formatMessage(messages.discoverUsersDisclaimer),
    emptyTitle: getEmptyText('Title'),
    emptySubtitle: getEmptyText('Subtitle'),
    option1: formatMessage(messages.discoverUsersOption1),
    option2: formatMessage(messages.discoverUsersOption2),
    option3: formatMessage(messages.discoverUsersOption3),
    selectAll:
      hasAllUsersSelected && users.length
        ? formatMessage(messages.discoverUsersUnselectAll)
        : formatMessage(messages.discoverUsersSelectAll),
    selectedUsers: formatMessage(messages.discoverUsersSelectedUsers),
    title: formatMessage(messages.discoverUsersTitle),
  };

  return (
    <DiscoverUsers
      enableOptions={hasEnoughSelectedUsers}
      isEmpty={isEmpty}
      isLoading={isLoading}
      onCheckedUser={handleCheckedUser}
      onClickUser={handleClickUser}
      onSelectAll={hasAllUsersSelected ? handleUnselectAll : handleSelectAll}
      options={DiscoverOptions}
      selectedUsers={selectedUsers}
      texts={texts}
      totalElements={totalElements}
      totalSelectedUsers={selectedUsersLength}
      users={customUsers}
      {...rest}
    />
  );
}

DiscoverUsersContainer.propTypes = {
  intl: PropTypes.object.isRequired,
  requestUsers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requestUsers: selectRequest(state, SEGMENTS_USERS_LIST),
});

export default connect(mapStateToProps)(injectIntl(DiscoverUsersContainer));
