// @app
import { paths } from 'utils/routes';

// @own
import Questionnaires from './Layout';

export default () => ({
  component: Questionnaires,
  path: paths.questionnaires(),
});
