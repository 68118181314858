import { takeEvery, call, put } from 'redux-saga/effects';
import {
  GET_TRENDS,
  GET_TRENDS_SUCCESS,
  GET_TRENDS_FAIL,
} from './actionTypes';

import { getTrends } from './api';

function* fetchProfileWorker({ payload }) {
  try {
    const data = yield call(getTrends, payload);
    yield put({ type: GET_TRENDS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_TRENDS_FAIL, payload: e });
  }
}

export default function* fetchProfileWatcher() {
  yield takeEvery(GET_TRENDS, fetchProfileWorker);
}
