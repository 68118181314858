// @packages
import React from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';
import messages from './messages';

const SuggestedUsersList = ({
  className,
  headerMessage,
  intl: { formatMessage },
  isLoading,
  loadingSize,
  onUserSelect,
  size,
  users,
}) => {
  const handleUserClick = user => {
    if (typeof onUserSelect === 'function') {
      onUserSelect(user);
    }
  };

  const AvatarMediaSkeleton = () => (
    <div className="suggested-users-list__avatar-media-skeleton">
      <Skeleton
        animation="wave"
        className="suggested-users-list__avatar-media-skeleton-avatar"
        height="40px"
        variant="circle"
        width="40px"
      />
      <div className="suggested-users-list__avatar-media-skeleton-info">
        <Skeleton
          animation="wave"
          className="suggested-users-list__avatar-media-skeleton-name"
          height="16px"
          width="60%"
        />
        <Skeleton
          animation="wave"
          className="suggested-users-list__avatar-media-skeleton-job"
          height="14px"
          width="40%"
        />
      </div>
    </div>
  );

  return (
    <div className={cn('suggested-users-list', className)}>
      <span className="suggested-users-list__header">
        {headerMessage || formatMessage(messages.header)}
      </span>
      <div className="suggested-users-list__list">
        {isLoading
          ? [...new Array(loadingSize)].map((_, index) => (
            <AvatarMediaSkeleton key={index} />
          ))
          : users.slice(0, size).map(user => (
            <div className="suggested-users-list__list-item" key={user.id}>
              <AvatarMedia
                firstName={user.firstName}
                job={user.job}
                lastName={user.lastName}
                onClick={() => handleUserClick(user)}
                profileImageCode={user.profileImageCode}
                useLink={false}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
};

SuggestedUsersList.defaultProps = {
  size: 10,
};

SuggestedUsersList.propTypes = {
  className: PropTypes.string,
  headerMessage: PropTypes.string,
  intl: PropTypes.object,
  isLoading: PropTypes.bool,
  loadingSize: PropTypes.number,
  onUserSelect: PropTypes.func,
  size: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.number,
    job: PropTypes.string,
    lastName: PropTypes.string,
    profileImageCode: PropTypes.string,
  })),
};

export default injectIntl(SuggestedUsersList);
