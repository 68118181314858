// @app
import addToastMessage from 'utils/defaultToastAdd';
import setLog from 'utils/logs';
import storeRef from 'utils/store';
import {
  DEFAULT_TOAST_TIMEOUT,
  UNAUTHORIZED_ERROR,
  UNAUTHORIZED_ERROR_MESSAGE,
} from 'utils/constants';
import { navigateToLoginV2 } from 'smu-utils/globalRequestHelper';

// @own
import messages from '../api/messages';

export const errorMessageHandler = (status = undefined, defaultMessage) => {
  const {
    i18n: { messages: storeMessages },
  } = storeRef().getState();

  if (status === UNAUTHORIZED_ERROR) {
    storeRef().dispatch(
      addToastMessage({
        message:
          storeMessages?.errorHandler?.unAuthorize ||
          messages?.errorHandlerUnAuthorize?.defaultMessage,
        timeout: DEFAULT_TOAST_TIMEOUT,
      }),
    );
  } else {
    storeRef().dispatch(
      addToastMessage({
        message:
          defaultMessage ||
          storeMessages?.errorHandler?.anyError ||
          messages?.errorHandlerAnyError?.defaultMessage,
        timeout: DEFAULT_TOAST_TIMEOUT,
      }),
    );
  }
};
export const errorStatus = (error) =>
  error?.response?.status || error?.request?.status;

export const isUnauthorized = (error) => {
  const status = errorStatus(error);
  const message = error?.message;
  const errorMessage = error?.errorMessage;

  return (
    status === UNAUTHORIZED_ERROR ||
    message === UNAUTHORIZED_ERROR_MESSAGE ||
    errorMessage === UNAUTHORIZED_ERROR_MESSAGE
  );
};

export const errorHandler = (
  error = {},
  defaultMessage,
  showMessage = false,
) => {
  const status = errorStatus(error);
  const unauthorized = isUnauthorized(error);
  const message = error?.message || defaultMessage;

  if (showMessage) {
    if (error?.response) {
      errorMessageHandler(status, message);
    } else if (unauthorized) {
      errorMessageHandler(UNAUTHORIZED_ERROR);
    } else {
      errorMessageHandler(undefined, message);
    }
  }

  if (unauthorized) {
    setLog('UNAUTHORIZED_ERROR', error?.config?.url);
    navigateToLoginV2('SMU_WEB_v3');
  }

  throw error;
};
