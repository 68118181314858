// @packages
import React from 'react';
import Avatar from 'smu-ui-components/Avatar';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';

const SelectOptionSearchUser = ({ avatar, fullName, job }) => (
  <div className="flex items-center space-x-2">
    <Avatar
      className="bg-gray-100"
      height={avatar.height || 40}
      src={avatar.src}
      width={avatar.width || 40}
    />
    <div className="flex flex-col">
      <Typography className="font-bold text-shark">{fullName}</Typography>
      {job && <Typography className="leading-none text-sm">{job}</Typography>}
    </div>
  </div>
);

SelectOptionSearchUser.propTypes = {
  avatar: PropTypes.shape({
    height: PropTypes.number,
    src: PropTypes.string,
    width: PropTypes.number,
  }),
  fullName: PropTypes.string,
  job: PropTypes.string,
};

export default SelectOptionSearchUser;
