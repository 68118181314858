import {
  CLEAN_LEADERBOARD,
  GET_NEW_LEADERBOARD,
  GET_NEW_LEADERBOARD_FAIL,
  GET_NEW_LEADERBOARD_SUCCESS,
  PERSIST_LEADERBOARD_FIELDS,
} from './actionTypes';

export function cleanLeaderboard(payload) {
  return {
    type: CLEAN_LEADERBOARD,
    payload,
  };
}

export function getNewLeaderboard(payload) {
  return {
    type: GET_NEW_LEADERBOARD,
    payload,
  };
}

export function getNewLeaderboardFail(payload) {
  return {
    type: GET_NEW_LEADERBOARD_FAIL,
    payload,
  };
}

export function getNewLeaderboardSuccess(payload) {
  return {
    type: GET_NEW_LEADERBOARD_SUCCESS,
    payload,
  };
}

export function persistLeaderboardFields(payload) {
  return {
    type: PERSIST_LEADERBOARD_FIELDS,
    payload,
  };
}
