// @packages
import { getWebUrl } from 'smu-utils/globalRequestHelper';

// @app
import paramsBuilder from 'utils/paramsBuilder';

export const sendStar = ({ comment, userIds, valueId }) => {
  const params = {
    comment: comment || '',
    criteria: userIds?.length
      ? window.btoa(JSON.stringify({ to: userIds }))
      : '',
    valueId: valueId || '',
  };

  return `${getWebUrl()}/me/sendStar?${paramsBuilder(params)}`;
};
