// @packages
import React from 'react';
import PropTypes from 'prop-types';
import LinkParser from 'smu-app-components/LinkParser';
import { withRouter } from 'react-router';

// @app
import Link from 'components/Link';
import MentionsPopup from 'components/MentionsPopup';
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';

const TextWithMentions = ({
  listMentions,
  text,
  trackHashtag,
}) => {
  const handleOnHashtagClick = (hashtag) => {
    if (trackHashtag) {
      trackHashtag();
    }

    trackEvent({
      action: 'Click',
      category: 'Hashtags',
      label: hashtag,
    });
  };

  const mentionsRegex = new RegExp('(@[[0-9a-zA-Z]*])');
  const regexMatchHashtag = /(\#[A-z-0-9ÑñÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÂÊÎÔÛâêîôûÜü]+)/g;
  const bothRegex = new RegExp(
    '(@[[0-9a-zA-Z]*]|#[0-9a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)',
  );
  const commentArray = text.split(bothRegex);
  let indexOfMention = 0;
  const tempText = commentArray.map((text, index) => {
    if (mentionsRegex.test(text)) {
      let mention = listMentions[indexOfMention];
      if (mention) {
        indexOfMention++;
        return (
          <MentionsPopup
            key={index}
            mention={
              <span className="text-with-mentions__mentions">
                {mention.fullName}&nbsp;
              </span>
            }
            userId={mention?.id}
          />
        );
      }
    }

    if (regexMatchHashtag.test(text)) {
      let hashtag = text.substring(1);

      return (
        <Link
          className="text-with-mentions__hashtags"
          href={`/hashtags/${hashtag}`}
          key={`hastag-${index}-${hashtag}`}
          onClick={() => handleOnHashtagClick(hashtag)}
        >
          {text}
        </Link>
      );
    }

    return text;
  });

  return tempText?.length ? (
    <LinkParser className="link--custom">{tempText}</LinkParser>
  ) : (
    ''
  );
};

TextWithMentions.propTypes = {
  listMentions: PropTypes.array,
  router: PropTypes.object,
  text: PropTypes.string.isRequired,
  trackHashtag: PropTypes.func,
};

export default withRouter(TextWithMentions);
