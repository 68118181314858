// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  selectError,
  selectRequesting,
  selectResult,
} from 'smu-utils/reduxRequests/selectors';
import {
  actionRequestDestroy,
  actionRequestInit,
} from 'smu-utils/reduxRequests/actions';

// @app
import { selectCommunityId } from 'containers/OSFeed/selectors';
import ImageEvent from 'components/OS/Event/ImageEvent';

// @own
import { REQUEST_ID_BETHERE_MOMENTS } from './constants';
import { getImages } from './api';
import { normalice } from './utils';

function OSBethereMoments({
  actionRequestDestroy,
  actionRequestInit,
  communityId,
  isError,
  isLoading,
  result,
  ...rest
}) {
  const posts = result?.posts || [];
  const properties = result?.properties || [];

  function getData() {
    actionRequestInit({
      api: getImages,
      id: REQUEST_ID_BETHERE_MOMENTS,
      params: {
        communityId,
      },
    });
  }

  useEffect(() => {
    getData();
  }, []);

  const customPosts = normalice(posts, properties);
  const showEvent = !isError && (customPosts.length || isLoading);

  return showEvent ? (
    <ImageEvent {...rest} isLoading={isLoading} posts={customPosts} />
  ) : null;
}

OSBethereMoments.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  communityId: PropTypes.number.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  result: PropTypes.shape({
    posts: PropTypes.array,
    properties: PropTypes.array,
  }),
};

function mapStateToProps(state) {
  return {
    communityId: selectCommunityId(state),
    isError: selectError(state, REQUEST_ID_BETHERE_MOMENTS),
    isLoading: selectRequesting(state, REQUEST_ID_BETHERE_MOMENTS),
    result: selectResult(state, REQUEST_ID_BETHERE_MOMENTS),
  };
}

export default connect(mapStateToProps, {
  actionRequestDestroy,
  actionRequestInit,
})(OSBethereMoments);
