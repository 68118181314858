import { defineMessages } from 'react-intl';

export default defineMessages({
  sentBy: {
    id: 'star.sentBy',
    defaultMessage: 'Sent by',
  },
  me: {
    id: 'star.me',
    defaultMessage: 'me',
  },
  recognitionStarName: {
    id: 'recognition.starName',
    defaultMessage: 'SUPERNOVA',
  },
  liftStarDiscovery: {
    id: 'liftStar.discovery',
    defaultMessage: 'If you like a star you see, brighten it! it will count as a sent star.',
  },
  inactiveUser: {
    id: 'profile.inactiveUserError',
    defaultMessage: 'The profile you are trying to access is no longer available',
  },
  editStar: {
    id: 'star.editStar',
    defaultMessage: 'Edit Star',
  },
  moderateHideStar: {
    id: 'star.moderate.hide',
    defaultMessage: 'Hide Star Description',
  },
  moderateShowStar: {
    id: 'star.moderate.show',
    defaultMessage: 'Show Star Description',
  },
});
