// @packages
import React, { useState } from 'react';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import UserAvatar from 'smu-app-components/UserAvatar';
import cn from 'classnames';

// @app
import CongratulationComment from 'containers/CongratulationComment';
import { buildModalUrl } from 'containers/QueryModals/helpers';
import { fullName } from 'utils/user';

// @own
import './styles.scss';

function CongratulationsUser({
  className,
  isCommented,
  messagePlaceholder,
  messageViewCard,
  messageYouCongratulated,
  onClickUser,
  onCommentedSuccess,
  onViewCard,
  referredYear,
  showInteractions,
  sourceType,
  user,
}) {
  return (
    <div
      className={cn(
        'smu-congratulations-user',
        {
          'smu-congratulations-user--is-commented': isCommented,
          'smu-congratulations-user--hide-interactions': !showInteractions,
        },
        className,
      )}
    >
      <UserAvatar
        descriptionEllipsis={false}
        href={`/profile/${user?.uid}`}
        imageCode={user?.profileImageCode}
        legacy={false}
        name={fullName(user, 'name', 'fullName')}
        nameEllipsis={false}
        onClick={() => onClickUser(user)}
      />
      {showInteractions && (
        <div className="smu-congratulations-user__messages">
          {isCommented ? (
            <span className="smu-congratulations-user__messages-success">
              {messageYouCongratulated}{' '}
              <strong className="smu-congratulations-user__messages-strong">
                {fullName(user, 'name', 'fullName')}.
              </strong>{' '}
              <Link
                className="smu-congratulations-user__messages-link"
                onClick={onViewCard}
                href={buildModalUrl({
                  modal: 'congratulation',
                  userId: user?.uid,
                  type: sourceType,
                })}
                legacy={false}
              >
                {messageViewCard}
              </Link>
            </span>
          ) : (
            <CongratulationComment
              expanded
              mediaUploadEnabled={false}
              mentionsEnabled={false}
              messages={[]}
              onCommentedSuccess={onCommentedSuccess}
              placeholder={messagePlaceholder}
              referredYear={referredYear}
              showDivider={false}
              showShadowHeader={false}
              sourceType={sourceType}
              user={user}
            />
          )}
        </div>
      )}
    </div>
  );
}

CongratulationsUser.propTypes = {
  isCommented: PropTypes.bool,
  messagePlaceholder: PropTypes.string,
  messageViewCard: PropTypes.string,
  messageYouCongratulated: PropTypes.string,
  onClickUser: PropTypes.func,
  onComment: PropTypes.func,
  onCommentedSuccess: PropTypes.func,
  onViewCard: PropTypes.func,
  referredYear: PropTypes.string,
  showInteractions: PropTypes.bool,
  sourceType: PropTypes.string,
  user: PropTypes.object.isRequired,
};

CongratulationsUser.defaultProps = {
  isCommented: false,
  messagePlaceholder: '',
  messageViewCard: 'View congratulations card',
  messageYouCongratulated: 'You congratulated',
  onClickUser: () => undefined,
  onComment: () => undefined,
  onViewCard: () => undefined,
};

export default CongratulationsUser;
