import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmationActionNo: {
    id: 'WriteForMe.SendStar.ConfirmationActionNo',
    defaultMessage: 'No',
  },
  confirmationActionYes: {
    id: 'WriteForMe.SendStar.ConfirmationActionYes',
    defaultMessage: 'Yes',
  },
  confirmationTitle: {
    id: 'WriteForMe.SendStar.ConfirmationTitle',
    defaultMessage:
      'Regenerating your response will create a new text and overwrite the previous suggestion. Are you sure you want to continue?',
  },
  firstStepAction: {
    id: 'WriteForMe.SendStar.FirstStepAction',
    defaultMessage: 'Generate',
  },
  firstStepActionLoading: {
    id: 'WriteForMe.SendStar.FirstStepActionLoading',
    defaultMessage: 'Generating',
  },
  firstStepPlaceholder: {
    id: 'WriteForMe.SendStar.FirstStepPlaceholder',
    defaultMessage: 'Write your ideas for recognition here.',
  },
  firstStepTitle: {
    id: 'WriteForMe.SendStar.FirstStepTitle',
    defaultMessage: 'Ready to write a meaningful recognition?',
  },
  firstStepDescription: {
    id: 'WriteForMe.SendStar.FirstStepDescription',
    defaultMessage:
      'Please share the keywords and concepts that you believe are essential in creating this {xxx} recognition.',
  },
  mainButtonTooltip: {
    id: 'WriteForMe.SendStar.MainButtonTooltip',
    defaultMessage:
      'This new feature will suggest multiple ways to express your recognition. Make it truly special with a personalized, motivating, and inspiring message that stands out. Try it now!',
  },
  secondStepActionInsert: {
    id: 'WriteForMe.SendStar.SecondStepActionInsert',
    defaultMessage: 'Insert',
  },
  secondStepActionSave: {
    id: 'WriteForMe.SendStar.SecondStepActionSave',
    defaultMessage: 'Update',
  },
  secondStepActionSettings: {
    id: 'WriteForMe.SendStar.SecondStepActionSettings',
    defaultMessage: 'Adjust',
  },
  secondStepActionUpdate: {
    id: 'WriteForMe.SendStar.SecondStepActionUpdate',
    defaultMessage: 'Re-generate',
  },
  secondStepActionUpdateLoading: {
    id: 'WriteForMe.SendStar.SecondStepActionUpdateLoading',
    defaultMessage: 'Re-generating',
  },
  secondStepDescription: {
    id: 'WriteForMe.SendStar.SecondStepDescription',
    defaultMessage:
      'Here is the suggested recognition. You can personalize the message by changing the language, length, and tone. You can also add or delete information.',
  },
  secondStepTitle: {
    id: 'WriteForMe.SendStar.SecondStepTitle',
    defaultMessage: 'Ready to write a meaningful recognition?',
  },
  secondStepActionDiscard: {
    id: 'WriteForMe.SendStar.SecondStepActionDiscard',
    defaultMessage: 'Discard',
  },
  error: {
    id: 'WriteForMe.SendStar.Error',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
});
