// @packages
import React from 'react';
import Panel from 'smu-ui-components/Panel';
import ButtonV2 from 'smu-ui-components/ButtonV2';

// @own
import './styles.scss';

const SendStarError = ({ errorMessage, actionMessage, onActionClick }) => (
  <div className="smu-send-stars-modal-error">
    <div className="smu-send-stars-modal-error__wrapper">
      <Panel>{errorMessage}</Panel>
      <ButtonV2 onClick={onActionClick}>{actionMessage}</ButtonV2>
    </div>
  </div>
);

export default SendStarError;
