// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// @app
import ModalInteractions from 'containers/Modals/Interactions';
import useRequest from 'hooks/useRequest';
import { apiGetPostById } from 'containers/withCard/api';
import { selectCommunityId } from 'containers/Authorization/selectors';
import { TYPE_LIKES } from 'containers/Modals/Interactions/constants';

// @own
import { buildInteractions } from './helpers';

const ModalPostInteractions = ({ id, ...rest }) => {
  const orgId = useSelector(selectCommunityId);
  const request = useRequest({
    api: apiGetPostById,
    id: `POST_INTERACTIONS_${id}`,
  });

  const interactions = buildInteractions(
    request?.isRequesting,
    request?.result?.postInteractions,
  );

  useEffect(() => {
    request.fetch({ params: { cardId: id, orgId } });
  }, []);

  return (
    <ModalInteractions
      defaultType={TYPE_LIKES}
      interactions={interactions}
      showTabs={!request?.isRequesting}
      {...rest}
    />
  );
};

ModalPostInteractions.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ModalPostInteractions;
