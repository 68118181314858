// @packages
import React, { useState } from 'react';
import Checkbox from 'smu-ui-components/Checkbox';
import IconV2 from 'smu-ui-components/IconV2';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';
import { useSelector, connect } from 'react-redux';

// @app
import GenericModal from 'components/GenericModal';
import MediaButton from 'components/MediaButton';
import Share from 'containers/Share';
import { getCommunityConfigurationValue } from 'containers/Authorization/selectors';
import {
  SHARE_TYPE_FACEBOOK,
  SHARE_TYPE_LINKEDIN,
  SHARE_TYPE_TWITTER,
  SHARE_TYPE_WORKPLACE,
  SOCIAL_MEDIAS_ENABLE,
  facebookLink,
  linkedinLink,
  openShareDialog,
  twitterLink,
  workplaceLink,
} from 'utils/share';
import { selectLocale } from 'containers/IntlProvider/selectors';
import { TYPE_DNA, TYPE_STAR, TYPE_BADGE } from 'containers/Share/constants';

// own
import './styles.scss';
import messages from './messages';

function ShareModal({
  addToastMessage,
  children,
  content,
  intl: { formatMessage },
  locale,
  navConfig,
  onClose,
  onShare,
  onShareError,
  onShareSuccess,
  query,
  socialSkeleton,
  star,
  title,
  type,
  ...rest
}) {
  const [shareUrl, setShareUrl] = useState();
  const [acceptedPolicy, setAcceptedPolice] = useState(false);
  const { starContent, id, starName } = useSelector(
    (state) => state.routing.locationBeforeTransitions.query,
  );
  const shareStarPolicyLink = `/communityGuidelines?languageCode=${locale}`;
  const hasCommunityGuidelines = navConfig?.some(item => item?.url?.includes('communityGuidelines'));
  const disabledMediaButtons = (type === TYPE_STAR || type === TYPE_BADGE)
    ? !acceptedPolicy
    : false;

  function handleShare(socialMediaType) {
    if (type === TYPE_STAR) {
      trackEvent({
        action: 'share_success',
        category: 'card_activity',
        description: starContent,
        id,
        label: socialMediaType,
        type: starName,
      });
    }

    if (!shareUrl) {
      return;
    }

    switch (socialMediaType) {
      case SHARE_TYPE_FACEBOOK:
        openShareDialog({
          quote: 'https://www.starmeup.com',
          link: facebookLink(shareUrl, {}),
        });
        break;
      case SHARE_TYPE_WORKPLACE:
        openShareDialog({
          quote: 'https://www.starmeup.com',
          link: workplaceLink(shareUrl, {}),
        });
        break;
      case SHARE_TYPE_LINKEDIN:
        openShareDialog({
          windowWidth: 750,
          windowHeight: 750,
          title: 'https://www.starmeup.com',
          link: linkedinLink(shareUrl, {}),
        });
        break;
      case SHARE_TYPE_TWITTER:
        openShareDialog({
          title: 'https://www.starmeup.com',
          link: twitterLink(shareUrl, {}),
        });
        break;
      default:
        addToastMessage({
          message: formatMessage(messages.shareModalErrorInvalidShare),
          timeout: 5000,
        });
    }

    onShare(socialMediaType);
    onClose();
  }

  function handleShareSuccess(image) {
    onShareSuccess(image);
    setShareUrl(image);
  }

  function handleShareError(error) {
    onShareError(error);
  }

  function clickInfoHandler() {
    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: starContent,
      id,
      label: 'modal_share_policy',
      type: starName,
    });
  }

  function clickAgreeHandler() {
    setAcceptedPolice(!acceptedPolicy);
    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: starContent,
      id,
      label: 'modal_share_agree',
      type: starName,
    });
  }

  function onCloseHandler() {
    trackEvent({
      action: 'click',
      category: 'card_activity',
      description: starContent,
      id,
      label: 'modal_share_close',
      type: starName,
    });
    onClose();
  }

  function renderFooterSkeleton() {
    return (
      <div className="share-modal__footer">
        <Skeleton className="share-modal__footer-title" height={14} width={46} animation="wave" />
        <div className="share-modal__footer-socials">
          {socialSkeleton.map((s) => (
            <div className="share-modal__social-btn" key={s}>
              <Skeleton animation="wave" height={40} variant="circle" width={40} />
              <Skeleton
                animation="wave"
                className="share-modal__social-name"
                height={14}
                width={70}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderFooter() {
    return (
      <footer className="p-7">
        {(type === TYPE_STAR || type === TYPE_BADGE) &&
          <div>
            <div className="flex items-center justify-between ">
              <Typography variant="subtitle2" className="text-blue text-xs">
                {formatMessage(messages.shareModalSubtitleStar)}
              </Typography>
              {hasCommunityGuidelines && (
                <Link
                  className="text-blue-info font-rubik text-xs flex items-center"
                  href={shareStarPolicyLink}
                  onClick={clickInfoHandler}
                  target="_blank"
                >
                  <IconV2
                    className="text-blue-info mr-1"
                    icon="info"
                    size="micro"
                  />
                  {formatMessage(messages.shareModalPolicyStar)}
                </Link>
              )}
            </div>
            <div className="mb-5 mt-4 flex">
              <Checkbox
                checked={acceptedPolicy}
                color="violet"
                onClick={clickAgreeHandler}
              />
              <Typography className="text-gray ml-3 text-xs w-[466px]">
                {hasCommunityGuidelines
                  ? formatMessage(messages.shareModalPolicyAgreeStar)
                  : formatMessage(messages.shareModalPolicyAgreeStarNotGuidelines)
                }
              </Typography>
            </div>
          </div>
        }
        {type === TYPE_DNA &&
          <span className="share-modal__footer-title block">
            {formatMessage(messages.shareModalShare)}
          </span>
        }
        <div className="share-modal__footer-socials">
          {SOCIAL_MEDIAS_ENABLE?.map((item) => (
            <div className="share-modal__social-btn mr-11" key={item.type}>
              <MediaButton
                media={item.icon}
                onClick={() => handleShare(item.type)}
                disabled={disabledMediaButtons}
                size={32}
              />
              <span className="capitalize text-xs mt-1">{item.type?.toLowerCase()}</span>
            </div>
          ))}
        </div>
      </footer>
    );
  }

  function renderBody() {
    return (
      <div className="share-modal__body p-0">
        <Share
          onError={handleShareError}
          onSuccess={handleShareSuccess}
          query={query}
          type={type}
        />
      </div>
    );
  }

  function renderHeader() {
    return (
      <Typography className="px-7 py-6 text-sm font-rubik font-medium leading-5">
        {title}
      </Typography>
    );
  }

  return (
    <GenericModal
      className={cn('share-modal', {
        'share-modal--loading': !shareUrl,
      })}
      onClose={onCloseHandler}
      renderBody={renderBody}
      renderHeader={renderHeader}
      renderFooter={shareUrl ? renderFooter : renderFooterSkeleton}
      {...rest}
    />
  );
}

ShareModal.defaultProps = {
  onClose: () => { },
  onShare: () => { },
  onShareError: () => { },
  onShareSuccess: () => { },
  content: '',
  socialMedias: [],
  socialSkeleton: Array.from(Array(3).keys()),
  star: {},
};

ShareModal.propTypes = {
  addToastMessage: PropTypes.func.isRequired,
  content: PropTypes.string,
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onShare: PropTypes.func,
  onShareError: PropTypes.func,
  onShareSuccess: PropTypes.func,
  query: PropTypes.object,
  socialMedias: PropTypes.array,
  socialSkeleton: PropTypes.array,
  star: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: selectLocale(state),
    navConfig: getCommunityConfigurationValue(state, 'navConfig'),
  };
}

export default connect(mapStateToProps, { addToastMessage })(injectIntl(ShareModal));
