// @own
import {
  REQUEST_USER_CONFIGS,
  REQUEST_USER_CONFIGS_SUCCESS,
  REQUEST_USER_CONFIGS_FAIL,
} from './actionTypes';

const initialState = {
  fetching: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_USER_CONFIGS:
      return {
        ...state,
        fetching: true,
      };
    case REQUEST_USER_CONFIGS_SUCCESS:
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case REQUEST_USER_CONFIGS_FAIL:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
