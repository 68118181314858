import { get } from 'lodash';

export const selectUser = state =>
  get(state, 'profile.result');

export const selectFetchingUser = state =>
  get(state, 'profile.fetching');

export const selectIsInteractionEnabled = state =>
  state.session.communityConfigurations.interactionBetweenUsers;

export const selectProfileFirstName = state =>
  get(state, 'profile.result.firstName');

export const selectProfileStarsR = state =>
  get(state, 'profile.receivedS');

export const selectUserProfileId = state =>
  get(state, 'profile.result.id');

export const selectHistoricalSent = state =>
  get(state, 'profile.receivedStarsDetail.historicalSent');

export const selectHistoricalReceived = state =>
  get(state, 'profile.receivedStarsDetail.historicalReceived');

export const selectProfileLastName = state =>
  state?.profile?.result?.lastName;

export const selectProfileJob = state =>
  state?.profile?.result?.job;

export const selectProfileFetchingUser = (state) => get(state, 'profile.fetching');

export const selectProfileCoreValuesSummary = (state) =>
  state?.profile?.receivedStarsDetail?.coreValuesSummary;

export const selectProfileFetchingRecS = (state) =>
state?.profile?.fetchingRecS;

export const selectProfileSentS = state => state?.profile?.sentS || 0;

export const selectProfileRemainingS = state => state?.profile?.remainingS || 0;

export const selectProfileEarnedB = state => state?.profile?.earnedB || 0;
