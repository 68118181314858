import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ButtonGroup from 'smu-ui-components/ButtonGroup';
import html2pdf from 'html2pdf.js';
import {
  actionRequestInit,
  actionRequestDestroy,
} from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectError, selectRequest } from 'smu-utils/reduxRequests/selectors';
import { trackPageview } from 'smu-utils/gtm';
import usePrevious from 'smu-custom-hooks/usePrevious';

// @app
import QuestionnaireResults from 'components/QuestionnaireResults';
import { trackEvent } from 'utils/gtm';

// @own
import messages from './messages';
import {
  CLOSED,
  OPEN,
  QUESTIONNAIRE_DOWNLOAD,
  QUESTIONNAIRE_RESULTS,
  REPORT_TYPE,
} from './constants';
import { apiGetCSV, apiGetResultById } from './api';

function QuestionnaireResultsContainer({
  actionRequestDestroy,
  actionRequestInit,
  enableExportPDF,
  error,
  exportingCSV,
  exportingPDF,
  intl: { formatMessage },
  onExportCSV,
  onExportPDF,
  questionnaireId,
  request,
  ...rest
}) {
  const result = request?.result?.result;
  const dueDate = moment(result?.closedDate).format('DD/MM/YYYY');
  const questionnaireTitle = result?.title;
  const questions = result?.questions || [];
  const responses = result?.totalAnswered || 0;
  const completed = result?.completedPercentage || 0;
  const status = result?.status;
  const totalQuestionsAnswered = result?.totalQuestionsAnswered || 0;
  const users = result?.totalUsers || 0;
  const isOpenWithNoAnswers = !totalQuestionsAnswered && status === OPEN;
  const isClosedWithNoAnswers = !totalQuestionsAnswered && status === CLOSED;
  const isError = !!error;
  const prevRequest = usePrevious(request);
  const rootRef = useRef();

  function isRequestSuccess(prev, current) {
    return prev && prev.requesting && !current.requesting && !current.error;
  }

  function getResults() {
    actionRequestInit({
      api: apiGetResultById,
      id: QUESTIONNAIRE_RESULTS,
      params: {
        questionnaireId,
        reportType: REPORT_TYPE,
      },
    });
  }

  function trackDownload(format) {
    trackEvent({
      action: `results_download - ${format}`,
      category: 'questionnaires',
      label: questionnaireId,
      q_name: questionnaireTitle,
    });
  }

  function buildCSV() {
    trackDownload('CSV');
    onExportCSV(true);
    actionRequestInit({
      api: apiGetCSV,
      id: QUESTIONNAIRE_DOWNLOAD,
      onError: () => onExportCSV(false),
      onSuccess: () => onExportCSV(false),
      params: {
        questionnaireId,
      },
    });
  }

  function buildPDF() {
    trackDownload('PDF');
    onExportPDF(true);
  }

  const headerProps = {
    options: [
      {
        label: formatMessage(messages.questionnaireResultsHeaderDueDate),
        value: dueDate,
      },
      {
        label: formatMessage(messages.questionnaireResultsHeaderQuestions),
        value: questions.length,
      },
      {
        label: formatMessage(messages.questionnaireResultsHeaderUsers),
        value: users,
      },
      {
        label: formatMessage(messages.questionnaireResultsHeaderResponses),
        value: responses,
      },
      {
        label: formatMessage(messages.questionnaireResultsHeaderCompleted),
        value: `${completed}%`,
      },
    ],
  };
  const exportProps = {
    content: formatMessage(messages.questionnaireResultsHeaderExport),
    children: (
      <>
        {enableExportPDF && <ButtonGroup.Item onClick={buildPDF}>PDF</ButtonGroup.Item>}
        <ButtonGroup.Item onClick={buildCSV}>CSV</ButtonGroup.Item>
      </>
    ),
  };
  const summaryTexts = {
    answered: formatMessage(messages.questionnaireResultsSummaryAnswered),
    average: formatMessage(messages.questionnaireResultsSummaryAverage),
    skipped: formatMessage(messages.questionnaireResultsSummarySkipped),
    title: formatMessage(messages.questionnaireResultsSummaryTitle),
  };
  const tooltipsTexts = {
    answers: formatMessage(messages.questionnaireResultsTooltipAnswers),
  };
  const listTexts = {
    answers: formatMessage(messages.questionnaireResultsListAnswers),
    date: formatMessage(messages.questionnaireResultsListDate),
    first: formatMessage(messages.questionnaireResultsListFirst),
    last: formatMessage(messages.questionnaireResultsListLast),
    user: formatMessage(messages.questionnaireResultsListUser),
  };

  function getErrorProps() {
    let errorProps = {
      imgSrc: '/assets/img/error-state-results.svg',
      subtitle: formatMessage(messages.questionnaireResultsErrorSubtitle),
      title: formatMessage(messages.questionnaireResultsErrorTitle),
    };

    if (isOpenWithNoAnswers) {
      errorProps = {
        imgSrc: '/assets/img/error-state-results-open.svg',
        subtitle: formatMessage(messages.questionnaireResultsOpenWithNoAnswerSubtitle),
        title: formatMessage(messages.questionnaireResultsOpenWithNoAnswerTitle),
      };
    }

    if (isClosedWithNoAnswers) {
      errorProps = {
        imgSrc: '/assets/img/error-state-results-closed.svg',
        subtitle: formatMessage(messages.questionnaireResultsClosedWithNoAnswerSubtitle),
        title: formatMessage(messages.questionnaireResultsClosedWithNoAnswerTitle),
      };
    }

    return errorProps;
  }


  function normaliceResult(result) {
    if (result?.questions) {
      return {
        ...result,
        questions: result.questions.map((question, key) => ({
          ...question,
          title: `${formatMessage(messages.questionnaireResultsQuestionTitle)} ${key + 1}`,
          description: question.title,
        })),
      };
    }
    return result;
  }

  function getSegments(segments) {
    return segments?.map(seg => seg?.title).join();
  }

  useEffect(() => {
    if (isRequestSuccess(prevRequest, request)) {
      trackPageview({
        pageName: `/questionnaire/${questionnaireId}/results`,
        segment: getSegments(result?.segments),
        q_name: questionnaireTitle,
        q_id: questionnaireId,
        q_date: moment(result?.closedDate).format('DD-MM-YYYY'),
        q_nr: result?.questions?.length,
        q_users: result?.totalUsers,
        q_responses: result?.totalAnswered,
        q_completed: result?.completedPercentage,
      });
    }
  }, [prevRequest, request]);

  useEffect(() => {
    getResults();

    return () => {
      actionRequestDestroy(QUESTIONNAIRE_RESULTS);
      actionRequestDestroy(QUESTIONNAIRE_DOWNLOAD);
    };
  }, []);

  useEffect(() => {
    let timer;

    if (exportingPDF) {
      timer = setTimeout(() => {
        html2pdf(rootRef.current, {
          filename: `${result?.title}.pdf`,
          html2canvas: {
            dpi: 192,
            letterRendering: true,
            scale: 4,
            useCORS: true,
          },
          image: { type: 'jpeg', quality: 1 },
          jsPDF: {
            format: 'a4',
          },
          pagebreak: { avoid: '.smu-questionnaire-results__question' },
        })
          .then(() => onExportPDF(false))
          .catch(() => onExportPDF(false));
      }, 1000);
    }

    return () => timer && clearTimeout(timer);
  }, [exportingPDF, onExportPDF]);

  return (
    <QuestionnaireResults
      errorProps={getErrorProps()}
      headerProps={{ ...headerProps, exportProps }}
      isError={isError || isOpenWithNoAnswers || isClosedWithNoAnswers}
      listTexts={listTexts}
      questionnaireId={questionnaireId}
      summaryTexts={summaryTexts}
      tooltipsTexts={tooltipsTexts}
      ref={rootRef}
      enabledMobile={!exportingPDF}
      showExportButton={!exportingPDF}
      {...normaliceResult(result)}
      {...rest}
    />
  );
}

QuestionnaireResultsContainer.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  enableExportPDF: PropTypes.bool,
  error: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onExportPDF: PropTypes.func,
  questionnaireId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  request: PropTypes.object.isRequired,
};

QuestionnaireResultsContainer.defaultProps = {
  enableExportPDF: true,
  onExportPDF: () => undefined,
};

function mapStateToProps(state) {
  return {
    error: selectError(state, QUESTIONNAIRE_RESULTS),
    request: selectRequest(state, QUESTIONNAIRE_RESULTS),
  };
}

export default connect(mapStateToProps, {
  actionRequestDestroy,
  actionRequestInit,
})(injectIntl(QuestionnaireResultsContainer));
