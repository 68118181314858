import { defineMessages } from 'react-intl';

export default defineMessages({
  discoverOptionsCopyToClipboard: {
    defaultMessage: 'Copy to clipboard',
    id: 'discoverOptions.copyToClipboard',
  },
  discoverOptionsCreateChillout: {
    defaultMessage: 'Create Get-Together ',
    id: 'discoverOptions.createChillout',
  },
  discoverOptionsSendStar: {
    defaultMessage: 'Send a star',
    id: 'discoverOptions.sendStar',
  },
  discoverOptionsSendStarNotEnough: {
    defaultMessage: "You don't have enough stars to send to all your colleagues, try selecting fewer colleagues.",
    id: 'discoverOptions.sendStarNotEnough',
  },
  discoverOptionsSendStarUnabled: {
    defaultMessage: 'Hooray! You already sent all your stars this month. Next month you can continue recognizing your colleagues work!',
    id: 'discoverOptions.sendStarUnabled',
  },
  discoveryOptionsCopiedToClipboard: {
    defaultMessage: 'User emails copied to clipboard',
    id: 'discoveryOptions.copiedToClipboard',
  },
  discoveryOptionsGotIt: {
    defaultMessage: 'GOT IT!',
    id: 'discoveryOptions.gotIt',
  },
});
