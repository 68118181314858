import { defineMessages } from 'react-intl';

export default defineMessages({
  communicationsHeaderTitle: {
    id: 'communicationsHeader.title',
    defaultMessage: 'Communications',
  },
  communicationsHeaderCommunity: {
    id: 'communicationsHeader.community',
    defaultMessage: 'Community',
  },
  communicationsHeaderLink: {
    id: 'communicationsHeader.link',
    defaultMessage: 'Learn more about us here.',
  },
  communicationsCustomizeHeaderButton: {
    id: 'communicationsHeader.customizeButton',
    defaultMessage: 'Customize',
  },
});
