// @app
import { smuInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

// @own
import { USER_PREFERENCES } from './constants';

export function getUserPreferences() {
  const params = Object.values(USER_PREFERENCES)
    .map(p => `name=${p}`)
    .join('&');

  return smuInstance({
    url: `/os-member/api/v1/community/member/preferences?${params}`,
    data: {},
    method: 'get',
  })
    .then(data => data)
    .catch(errorHandler);
}

export function getUserProperties() {
  return smuInstance({
    url: '/os-member/api/v1/community/members/properties',
    data: {},
    method: 'get',
  })
    .then(data => data)
    .catch(errorHandler);
}
