// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePrevious from 'smu-custom-hooks/usePrevious';
import withRootModal from 'smu-app-components/RootModalV2/withRootModal';
import { actionRequestDestroy, actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEmpty, isEqual } from 'lodash';
import { selectRequest } from 'smu-utils/reduxRequests/selectors';
import { trackPageview } from 'smu-utils/gtm';

// @app
import DiscoverFilters from 'components/DiscoverFilters';
import useDiscover from 'hooks/useDiscover';
import { SMU_GENERIC_MODAL } from 'containers/GenericModal/constants';
import { getLabelFilters } from 'utils/discover';
import { trackEvent } from 'utils/gtm';

// @own
import messages from './messages';
import {
  FILTERS_PARAMS,
  SEGMENTS_USERS_FILTERS,
  SEGMENTS_USERS_LIST,
  SELECT_PROPS,
} from './constants';
import { apiGetSegmentsFilters, apiPostSegmentsFilters } from './api';

function DiscoverFiltersContainer({
  actionRequestDestroy,
  actionRequestInit,
  closeModal,
  intl: { formatMessage },
  openModal,
  requestFilters,
  requestUsers,
  ...rest
}) {
  const discover = useDiscover();
  const result = requestFilters?.result?.result;
  const [filters, setFilters] = useState(result);
  const [prevSubmitFilters, setPrevSubmitFilters] = useState([]);
  const prevRequestFilters = usePrevious(requestFilters);
  const prevRequestUsers = usePrevious(requestUsers);
  const emptyFilters = !filters?.filter((f) => f?.value?.length).length;
  const actionsDisabled =
    emptyFilters || requestFilters?.requesting || requestUsers?.requesting;

  function isRequestSuccess(prev, current) {
    return prev && prev.requesting && !current.requesting && !current.error;
  }

  function search(filters, str) {
    if (str) {
      const regx = new RegExp(str, 'i');
      return filters.filter((f) => regx.test(f.value));
    }

    return filters;
  }

  function exclude(filters, value) {
    return filters.filter(
      (f) => !value.find((v) => v === f?.value || v === f?.key) && !isEmpty(f.value),
    );
  }

  function optionsSelected(values, value) {
    return value?.map((v1) => {
      const el = values.find((v2) => v2?.value === v1 || v2?.key === v1);
      return {
        label: el?.value,
        value: el?.key,
      };
    });
  }

  function getOptions(filters, total) {
    return filters.slice(0, total).map((value) => ({
      label: value?.value,
      value: value?.key,
    }));
  }

  function getSegmentsFilters(filters) {
    return filters.reduce((acc, cur) => {
      if (cur?.value?.length) {
        acc.push({
          name: cur.name,
          values: cur.value,
        });
      }
      return acc;
    }, []);
  }

  function handleChangeFilter(update, index) {
    const copyFilters = [...filters];
    copyFilters[index] = {
      ...copyFilters[index],
      ...update,
    };
    setFilters(copyFilters);
  }

  function handleResetFilters() {
    const resetFilters = filters.map((filter) => ({ ...filter, value: [] }));
    trackEvent({
      action: 'filters_reset',
      category: 'discover',
      label: getLabelFilters(getSegmentsFilters(filters)),
    });
    setFilters(resetFilters);
  }

  function handleRemoveValue(removeKey, index, value) {
    const newValue = [...value.filter((v, k) => k !== removeKey)];
    trackEvent({
      action: 'filters_remove',
      category: 'discover',
      label: `${filters[index]?.name}:${value}`,
    });
    handleChangeFilter({ value: newValue }, index);
  }

  function handleSearchChange(searchValue, index) {
    handleChangeFilter({ searchValue, page: 1 }, index);
  }

  function handleChange(change, index, value) {
    trackEvent({
      action: 'filters_select',
      category: 'discover',
      label: `${filters[index]?.name}:${change}`,
    });
    handleChangeFilter({ value: [...value, change] }, index);
  }

  function handlePostFilters(segmentFilters) {
    trackEvent({
      action: 'filters_see_results',
      category: 'discover',
      label: getLabelFilters(segmentFilters),
    });
    discover.setFilters(segmentFilters);
    actionRequestDestroy(SEGMENTS_USERS_LIST);
    actionRequestInit({
      api: apiPostSegmentsFilters,
      id: SEGMENTS_USERS_LIST,
      params: {
        data: {
          segmentFilters,
        },
        params: FILTERS_PARAMS,
      },
      onSuccess: () => {
        discover.setExcludeUsers([]);
        discover.setFilters(segmentFilters);
      },
      onError: (e) => {
        if (e.response.status === 406) {
          discover.setLargeResults(true);
        }
      },
    });
    setPrevSubmitFilters(segmentFilters);
  }

  function handleShowConfirmation(segmentFilters) {
    openModal({
      modalType: SMU_GENERIC_MODAL,
      modalProps: {
        actions: [
          {
            children: formatMessage(messages.discoverFiltersConfirmationNo),
            onClick: () => {
              trackEvent({
                action: 'discard_no',
                category: 'discover',
                label: getLabelFilters(segmentFilters),
                list_count: discover.totalUsersSelected,
              });
              closeModal();
            },
            variant: 'outline',
          },
          {
            children: formatMessage(messages.discoverFiltersConfirmationYes),
            onClick: () => {
              trackEvent({
                action: 'discard_yes',
                category: 'discover',
                label: getLabelFilters(segmentFilters),
                list_count: discover.totalUsersSelected,
              });
              handlePostFilters(segmentFilters);
              closeModal();
            },
          },
        ],
        children: formatMessage(messages.discoverFiltersConfirmationDescription),
        title: formatMessage(messages.discoverFiltersConfirmationTitle),
        onClose: () => {
          trackEvent({
            action: 'discard_no',
            category: 'discover',
            label: getLabelFilters(segmentFilters),
            list_count: discover.totalUsersSelected,
          });
        },
      },
    });
  }

  function handleSubmitFilters() {
    const segmentFilters = getSegmentsFilters(filters);
    const users = requestUsers?.result?.result || [];

    if (users.length && prevSubmitFilters.length && !isEqual(prevSubmitFilters, segmentFilters)) {
      handleShowConfirmation(segmentFilters);
    } else {
      handlePostFilters(segmentFilters);
    }
  }

  function normalice(filter, index) {
    const values = filter?.values || [];
    const value = filter?.value || [];
    const searchValue = filter?.searchValue;
    const searchValues = search(values, searchValue);
    const excludeValues = exclude(searchValues, value);
    const page = filter?.page || 1;
    const size = 10;
    const total = page * size;

    return {
      ...SELECT_PROPS,
      closeMenuOnSelect: false,
      disabled: requestUsers?.requesting,
      noOptionsMessage: formatMessage(messages.discoverFiltersNoOptions),
      onChange: (change) => handleChange(change, index, value),
      onMenuClose: () => handleSearchChange('', index),
      onRemoveValue: (_, key) => handleRemoveValue(key, index, value),
      onScrollEnd: () => handleChangeFilter({ page: page + 1 }, index),
      onSearchChange: (search) => handleSearchChange(search, index),
      options: getOptions(excludeValues, total),
      optionsSelected: optionsSelected(values, value),
      page,
      placeholder: filter.label,
      scrollEndOffset: 50,
    };
  }

  useEffect(() => {
    const prevFilters = prevRequestFilters?.result?.result;
    const filters = requestFilters?.result?.result;

    if (!prevFilters && filters) {
      setFilters(filters);
    }
  }, [prevRequestFilters, requestFilters]);

  useEffect(() => {
    if (!requestFilters?.requesting && !filters) {
      actionRequestInit({
        api: apiGetSegmentsFilters,
        id: SEGMENTS_USERS_FILTERS,
      });
    }

    return () => actionRequestDestroy(SEGMENTS_USERS_LIST);
  }, []);

  useEffect(
    () => () => {
      actionRequestDestroy(SEGMENTS_USERS_LIST);
    },
    [],
  );

  useEffect(() => {
    const users = requestUsers?.result?.result || [];
    const totalElements = requestUsers?.result?.page?.totalElements || 0;
    if (isRequestSuccess(prevRequestUsers, requestUsers)) {
      if (!users.length) {
        trackPageview({
          pageName: '/discover/no-results',
        });
        trackEvent({
          action: 'no_results',
          category: 'discover',
          label: getLabelFilters(getSegmentsFilters(filters)),
        });
      } else {
        trackPageview({
          pageName: '/discover/results',
        });
        trackEvent({
          action: 'results_success',
          category: 'discover',
          label: getLabelFilters(getSegmentsFilters(filters)),
          list_count: users.length,
        });
        if (totalElements > 200) {
          trackEvent({
            action: 'results_success_limit',
            category: 'discover',
            label: getLabelFilters(getSegmentsFilters(filters)),
            list_count: users.length,
            type: 'limit_200',
          });
        }
      }
      discover.resetExcludeUsers({
        totalUsersSelected: users.length,
      });
      if (discover.largeResults) {
        discover.setLargeResults(false);
      }
    }
  }, [prevRequestUsers, requestUsers]);

  return (
    <DiscoverFilters
      filters={filters?.map(normalice)}
      isLoading={requestFilters?.requesting}
      onReset={handleResetFilters}
      onSubmit={handleSubmitFilters}
      resetDisabled={actionsDisabled}
      resetText={formatMessage(messages.discoverFiltersReset)}
      submitDisabled={actionsDisabled}
      submitText={formatMessage(messages.discoverFiltersSeeResults)}
      titleText={formatMessage(messages.discoverFiltersTitle)}
      {...rest}
    />
  );
}

DiscoverFiltersContainer.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  requestFilters: PropTypes.object.isRequired,
  requestUsers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requestFilters: selectRequest(state, SEGMENTS_USERS_FILTERS),
  requestUsers: selectRequest(state, SEGMENTS_USERS_LIST),
});

export default connect(mapStateToProps, {
  actionRequestDestroy,
  actionRequestInit,
})(injectIntl(withRootModal(DiscoverFiltersContainer)));
