// @packages
import React from 'react';
import ButtonV2 from 'smu-ui-components/ButtonV2';
import IconV2 from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import Title from 'components/Title';

// @own
import './styles.scss';

function GenericModal({
  actions,
  children,
  className,
  closeIconProps,
  onClose,
  renderBody,
  renderFooter,
  renderHeader,
  showBody,
  showClose,
  showFooter,
  showHeader,
  title,
}) {
  return (
    <div className={cn('smu-generic-modal', className)}>
      {showHeader &&
        (renderHeader ? (
          renderHeader()
        ) : (
          <header className="smu-generic-modal__header">
            {title && <Title className="smu-generic-modal__title">{title}</Title>}
          </header>
        ))}
      {showClose && (
        <IconV2
          icon="linear-close"
          onClick={onClose}
          size="medium"
          {...closeIconProps}
          className={cn('smu-generic-modal__close', closeIconProps?.className)}
        />
      )}
      {showBody &&
        (renderBody ? renderBody() : <div className="smu-generic-modal__body">{children}</div>)}
      {showFooter &&
        (renderFooter ? (
          renderFooter()
        ) : (
          <footer className="smu-generic-modal__footer">
            {actions.map((action, key) => (
              <ButtonV2
                className="smu-generic-modal__action"
                key={`modal-action-${key}`}
                {...action}
              />
            ))}
          </footer>
        ))}
    </div>
  );
}

GenericModal.propTypes = {
  actions: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  close: PropTypes.func,
  closeIconProps: PropTypes.object,
  onClose: PropTypes.func,
  renderBody: PropTypes.func,
  renderFooter: PropTypes.func,
  renderHeader: PropTypes.func,
  showBody: PropTypes.bool,
  showClose: PropTypes.bool,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  title: PropTypes.string,
};

GenericModal.defaultProps = {
  actions: [],
  className: '',
  closeIconProps: {},
  onClose: () => undefined,
  showBody: true,
  showClose: true,
  showFooter: true,
  showHeader: true,
  title: '',
};

export default GenericModal;
