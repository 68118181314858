export const GET_OS_FEED = 'GET_OS_FEED';
export const GET_OS_FEED_FAIL = 'GET_OS_FEED_FAIL';
export const GET_OS_FEED_SUCCESS = 'GET_OS_FEED_SUCCESS';

export const GET_OS_FEED_HIGHLIGHTS = 'GET_OS_FEED_HIGHLIGHTS';
export const GET_OS_FEED_HIGHLIGHTS_FAIL = 'GET_OS_FEED_HIGHLIGHTS_FAIL';
export const GET_OS_FEED_HIGHLIGHTS_SUCCESS = 'GET_OS_FEED_HIGHLIGHTS_SUCCESS';

export const POST_OS_FEED_LIFT_STAR_SUCCESS = 'POST_OS_FEED_LIFT_STAR_SUCCESS';

export const POST_OS_FEED_COMMENT = 'POST_OS_FEED_COMMENT';
export const POST_OS_FEED_COMMENT_FAIL = 'POST_OS_FEED_COMMENT_FAIL';
export const POST_OS_FEED_COMMENT_SUCCESS = 'POST_OS_FEED_COMMENT_SUCCESS';

export const POST_OS_FEED_LIKE = 'POST_OS_FEED_LIKE';
export const POST_OS_FEED_LIKE_FAIL = 'POST_OS_FEED_LIKE_FAIL';
export const POST_OS_FEED_LIKE_SUCCESS = 'POST_OS_FEED_LIKE_SUCCESS';

export const POST_OS_FEED_REMOVE_LIKE = 'POST_OS_FEED_REMOVE_LIKE';
export const POST_OS_FEED_REMOVE_LIKE_FAIL = 'POST_OS_FEED_REMOVE_LIKE_FAIL';
export const POST_OS_FEED_REMOVE_LIKE_SUCCESS = 'POST_OS_FEED_REMOVE_LIKE_SUCCESS';

export const POST_OS_FEED_PREFERENCE = 'POST_OS_FEED_PREFERENCE';
export const POST_OS_FEED_PREFERENCE_FAIL = 'POST_OS_FEED_PREFERENCE_FAIL';
export const POST_OS_FEED_PREFERENCE_SUCCESS = 'POST_OS_FEED_PREFERENCE_SUCCESS';

export const POST_OS_FEED_LOAD_IMAGE = 'POST_OS_FEED_LOAD_IMAGE';
export const POST_OS_FEED_LOAD_IMAGE_FAIL = 'POST_OS_FEED_LOAD_IMAGE_FAIL';
export const POST_OS_FEED_LOAD_IMAGE_SUCCESS = 'POST_OS_FEED_LOAD_IMAGE_SUCCESS';

export const POST_OS_FEED_MODERATE_COMMENT = 'POST_OS_FEED_MODERATE_COMMENT';
export const POST_OS_FEED_MODERATE_COMMENT_FAIL = 'POST_OS_FEED_MODERATE_COMMENT_FAIL';
export const POST_OS_FEED_MODERATE_COMMENT_SUCCESS = 'POST_OS_FEED_MODERATE_COMMENT_SUCCESS';
export const POST_OS_FEED_TURN_TOGGLE = 'POST_OS_FEED_TURN_TOGGLE';
export const POST_OS_FEED_TURN_TOGGLE_SUCCESS = 'POST_OS_FEED_TURN_TOGGLE_SUCCESS';

export const CLEAN_OS_FEED = 'CLEAN_OS_FEED';
export const CLEAN_OS_FEED_PARAMS = 'CLEAN_OS_FEED_PARAMS';

export const SET_OS_FEED_PARAMS = 'SET_OS_FEED_PARAMS';

export const GET_OS_FEED_PREFERENCE = 'GET_OS_FEED_PREFERENCE';
export const GET_OS_FEED_PREFERENCE_FAIL = 'GET_OS_FEED_PREFERENCE_FAIL';
export const GET_OS_FEED_PREFERENCE_SUCCESS = 'GET_OS_FEED_PREFERENCE_SUCCESS';

export const ADD_ITEM_OS_FEED = 'ADD_ITEM_OS_FEED';
export const ADD_UNVISITED_OS_FEED_ITEM = 'ADD_UNVISITED_OS_FEED_ITEM';
export const CLEAN_UNVISITED_OS_FEED_ITEMS = 'CLEAN_UNVISITED_OS_FEED_ITEMS';
export const POST_OS_FEED_VISITED_POSTS = 'POST_OS_FEED_VISITED_POSTS';
export const POST_OS_FEED_VISITED_POSTS_FAIL = 'POST_OS_FEED_VISITED_POSTS_FAIL';
export const ADD_VISITED_OS_FEED_ITEM = 'SET_VISITED_OS_FEED_ITEM';

export const POST_OS_FEED_UPDATE_POST = 'POST_OS_FEED_UPDATE_POST';
