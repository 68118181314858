// @packages
import React, { useRef, useState, useEffect } from 'react';
import Icon from 'smu-ui-components/IconV2';
import Popup from 'smu-ui-components/Popup';
import PropTypes from 'prop-types';
import Switch from 'smu-ui-components/Switch';
import UploadMediaContainer from 'smu-app-components/UploadMediaContainer';
import UploadMediaModal from 'smu-ui-components/UploadMedia/Modal';
import cn from 'classnames';
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { convertFromRaw, EditorState } from 'draft-js';
import { getFileType, TYPE_IMAGE } from 'smu-app-components/UploadMediaContainer/utils';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import PostComment from 'components/PostComment';
import Share from 'containers/Share';
import { ENABLED_BETHERE_FEED, ENABLED_FEED_FILTERS } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { getGeocoding } from 'utils/google';
import { selectOrganizationId } from 'containers/Authorization/selectors';

// @own
import messages from './messages';
import { MAX_LENGTH, UPLOAD_PHOTOS_AND_VIDEOS } from './constants';
import { buildFile, normalizeDescription } from './utils';
import { searchUsers, uploadPostApi } from './api';

function UploadMediaModalContainer({
  actionRequestDestroy,
  actionRequestInit,
  addToastMessage,
  className,
  enabledBeThere,
  enabledFeedFilters,
  intl: { formatMessage },
  isYir,
  location: { query },
  onClose,
  onSuccesPost,
  open,
  orgId,
  router,
}) {
  const [comment, setComment] = useState('');
  const [emoji, setEmoji] = useState('');
  const [file, setFile] = useState(null);
  const [initialFile, setInitialFile] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [shareIsLoading, setShareIsLoading] = useState(query?.type);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const mediaRef = useRef(null);

  const [location, setLocation] = useState({
    disabled: true,
    hasLocation: false,
    text: formatMessage(messages.uploadPostLocationDisabled),
  });

  const tab = enabledFeedFilters ? '' : '?tab=bethere-feed';

  const descriptionLength = comment?.editorState
    ?.getCurrentContent()
    ?.getPlainText()?.length;

  const errorDescription = descriptionLength > MAX_LENGTH;

  const errorMessage = errorDescription
    ? formatMessage(messages.uploadPostVeryLongDescription, {
        xxx: MAX_LENGTH,
      })
    : '';

  let editorState = EditorState.createEmpty();
  const disabledSubmit = !file || isRequesting || errorDescription;

  if (isYir || query?.comment) {
    const rawContent = {
      blocks: [
        {
          entityRanges: [],
          text: isYir ? '#YearInReview2021' : query?.comment,
          type: 'unstyled',
        },
      ],
      entityMap: [],
    };
    const blocks = convertFromRaw(rawContent);
    editorState = EditorState.createWithContent(blocks);
  }

  const getContentType = file => {
    if (file) {
      const contentType = getFileType(file) === TYPE_IMAGE
        ? 'content_type: photo'
        : 'content_type: video';

      return contentType;
    }

    return '';
  };

  const handleOnCancel = () => {
    trackEvent({
      action: 'cancel_post',
      category: 'bethere',
      type: getContentType(file),
    });

    onClose();
  };

  const handleOnSuccess = (post) => {
    if (onSuccesPost) {
      onSuccesPost(post);
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!disabledSubmit) {
      setIsRequesting(true);
      const { uploadMedia } = mediaRef?.current;
      const contentType = getContentType(file);
      const { uploadedFile, uploadedThumbnail } = await uploadMedia();

      trackEvent({
        action: 'upload_post',
        category: 'bethere',
        type: contentType,
      });

      const params = {
        query: {
          mentions: comment?.mentions,
          orgId,
          post_description: normalizeDescription(comment?.comment),
          post_type: getFileType(file),
          sponsored: false,
          tagged_users: taggedUsers,
        },
        data: buildFile([
          { name: 'post-image', file: uploadedFile },
          { name: 'thumbnail', file: uploadedThumbnail },
        ]),
      };

      const withLocation = location?.hasLocation && !location?.disabled;

      if (withLocation) {
        params.query.location = { id: location?.text, name: location?.text };
      }

      actionRequestInit({
        api: uploadPostApi,
        id: UPLOAD_PHOTOS_AND_VIDEOS,
        onError: () => {
          addToastMessage({
            message: `${formatMessage(messages.uploadPostErrorStateTitle)}. ${formatMessage(messages.uploadPostErrorStateSubtitle)}`,
            timeout: 5000,
          });

          setIsRequesting(false);
        },
        onSuccess: post => {
          onClose();
          handleOnSuccess(post);

          addToastMessage({
            message: formatMessage(messages.uploadPostSuccessfullyCreated),
            timeout: 5000,
          });

          trackEvent({
            action: 'upload_success',
            category: 'bethere',
            description: comment?.comment ? comment?.comment : 'no_description',
            location: withLocation ? location?.text : '',
            tag: taggedUsers.length ? 'yes' : 'no',
            type: contentType,
          });

          setIsRequesting(false);
          router.push(`/home${tab}`);
        },
        params,
      });
    }
  };

  const onAllowLocation = async (position) => {
    if (position) {
      const location = await getGeocoding(position?.coords?.latitude, position?.coords?.longitude);

      if (location) {
        setLocation({
          disabled: false,
          hasLocation: true,
          text: location,
        });
      } else {
        setLocation({
          disabled: true,
          hasLocation: false,
          text: formatMessage(messages.uploadPostLocationUnavailable),
        });
      }
    }
  };

  const getLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(onAllowLocation);
    } else {
      setLocation({
        hasLocation: false,
        text: formatMessage(messages.uploadPostLocationNotSupported),
      });
    }
  };

  const handleLocationSwitchChange = () => {
    trackEvent({
      action: location.disabled ? 'location_enable' : 'location_disable',
      category: 'bethere',
      type: getContentType(file),
    });

    setLocation({
      ...location,
      disabled: !location.disabled,
    });
  };

  const handleOnBrowse = () => {
    trackEvent({
      action: 'browse',
      category: 'bethere',
    });
  };

  const handleOnChangeFile = file => {
    setFile(file);
  };

  const handleRemoveFile = () => {
    trackEvent({
      action: 'delete_content',
      category: 'bethere',
      type: getContentType(file),
    });

    setFile(null);
  };

  const handleOnUserClick = () => {
    trackEvent({
      action: 'tag_added',
      category: 'bethere',
      type: getContentType(file),
    });
  };

  const handleOnTag = taggedUsers => {
    setTaggedUsers(taggedUsers);
  };

  const handleShareError = () => {
    setShareIsLoading(false);
  };

  const handleShareSuccess = file => {
    setShareIsLoading(false);
    setInitialFile(file);
  };

  useEffect(() => {
    getLocation();

    if (!enabledBeThere) {
      onClose();
    }

    return () => {
      actionRequestDestroy(UPLOAD_PHOTOS_AND_VIDEOS);
    };
  }, []);

  const renderLocation = () => (
    <div>
      <div
        className={cn(
          'text-blue font-rubik text-sm font-medium mb-3 capitalize',
          { 'text-gray': location?.disabled },
        )}
      >
        {formatMessage(messages.uploadPostLocation)}
      </div>
      <div
        className={cn(
          'flex h-10 relative items-center border border-gray-iron rounded-lg px-4 py-3',
          { 'border-loblolly': location?.disabled },
        )}
      >
        <div className="mr-2">
          <Icon className="text-loblolly" icon={location?.hasLocation ? 'location' : 'location-disabled'} />
        </div>
        <div className="text-raven text-sm font-openSans mx-2 truncate">
          {location?.text}
        </div>
        <div>
          <Switch
            checked={location?.hasLocation && !location?.disabled}
            disabled={!location?.hasLocation}
            idName="location-switch"
            onChange={location?.hasLocation ? handleLocationSwitchChange : undefined}
          />
        </div>
      </div>
    </div>
  );

  return enabledBeThere ? (
    <UploadMediaModal
      cancelButtonText={formatMessage(messages.uploadPostActionstCancel)}
      className={cn('flex', className)}
      disabled={disabledSubmit}
      isLoading={isRequesting}
      isOpen={open}
      onCancel={handleOnCancel}
      onClose={onClose}
      onSubmit={handleOnSubmit}
      submitButtonText={formatMessage(messages.uploadPostActionstUpload)}
      title={formatMessage(messages.uploadPostHeaderTitle)}
      wrapperClassname="w-4/5 max-w-6xl"
    >
      <div className="flex w-full">
        {query?.type && (
          <Share
            enableUploadFile={false}
            onError={handleShareError}
            onSuccess={handleShareSuccess}
            type={query.type}
          />
        )}
        <div className="w-7/10 h-[350px] overflow-hidden">
          <UploadMediaContainer
            addToastMessage={addToastMessage}
            browseButtonText={formatMessage(messages.uploadPostDndBrowse)}
            browseDescription={formatMessage(messages.uploadPostDndDragAndDropFile)}
            browseTitle={formatMessage(messages.uploadPostDndUploadYourFile)}
            cropperProps={{ style: { height: '100%', width: '100%' } }}
            enableTags
            initialFile={initialFile}
            isLoading={shareIsLoading}
            onBrowse={handleOnBrowse}
            onChangeFile={handleOnChangeFile}
            onRemoveFile={handleRemoveFile}
            onTag={handleOnTag}
            onUserClick={handleOnUserClick}
            ref={mediaRef}
            searchProps={{
              apiSearch: searchUsers,
              placeholder: formatMessage(messages.uploadPostSearchColleagues),
              title: formatMessage(messages.uploadPostSearchColleagues),
            }}
            tagsProps={{ helper: formatMessage(messages.uploadPostAddTags) }}
            uploadMediaErrorIncorrectFormat={formatMessage(messages.uploadPostErrorIncorrectFormat)}
            uploadMediaErrorSizeLimit={formatMessage(messages.uploadPostErrorSizeLimit)}
          />
        </div>
        <div className="flex flex-col ml-10 w-3/10">
          {location?.hasLocation ? (
            renderLocation()
          ) : (
            <Popup
              content={formatMessage(messages.uploadPostLocationUnavailableTooltip)}
              position="bottom center"
              suiteInverted
              trigger={renderLocation()}
            />
          )}
          <PostComment
            className="w-full m-0 mt-8"
            comment={comment}
            createEditorState={editorState}
            emoji={emoji}
            errorMessage={errorMessage}
            labelClassname="text-blue font-rubik text-sm font-medium mb-3 capitalize"
            maxLength={MAX_LENGTH}
            mentionsEnabled
            orgId={orgId}
            placeholder={formatMessage(messages.uploadPostDetailsCommentPlaceholder)}
            setComment={setComment}
            setEmoji={setEmoji}
            style={{
              bottom: '10px',
              left: '20px',
              position: 'absolute',
              width: '280px',
            }}
            textAreaClassName="w-full h-44 border border-gray-iron rounded-lg text-raven text-sm font-openSans"
            title={formatMessage(messages.uploadPostDetailsDescriptionTitle)}
          />
        </div>
      </div>
    </UploadMediaModal>
  ) : null;
}

UploadMediaModalContainer.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func,
  addToastMessage: PropTypes.func.isRequired,
  className: PropTypes.string,
  enabledBeThere: PropTypes.bool,
  enabledFeedFilters: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  isYir: PropTypes.bool,
  location: PropTypes.object,
  onClose: PropTypes.func,
  onSuccesPost: PropTypes.func,
  open: PropTypes.bool,
  orgId: PropTypes.number,
  router: PropTypes.object,
};

const mapStateToProps = (state) => ({
  enabledBeThere: getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED),
  enabledFeedFilters: getCommunityConfigValueBool(state, ENABLED_FEED_FILTERS),
  orgId: selectOrganizationId(state),
});

const mapDispatchToProps = {
  actionRequestDestroy,
  actionRequestInit,
  addToastMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(injectIntl(UploadMediaModalContainer)),
);
