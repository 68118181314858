import { partial } from 'lodash';
import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';

import requests from 'smu-utils/reduxRequests/reducer';
import toastr from 'smu-app-components/ToastNotifications/reducer';
import topStars from 'containers/TopStarsPanel/reducer';
import i18n from 'containers/IntlProvider/reducer';
import suggestions from 'smu-app-components/Suggestions/reducer';
import search from 'smu-app-components/Search/reducer';
import * as searchActions from 'smu-app-components/Search/actions';
import session from 'containers/Authorization/reducer';
import profile from 'containers/withProfile/reducer';
import feed from 'containers/withFeed/reducer';
import stars from 'containers/Feed/reducer';
import filters from 'containers/Filters/reducer';
import filtersV2 from 'containers/FiltersV2/reducer';
import badges from 'components/BadgesWidget/reducer';
import leaderboards from 'containers/Leaderboard/reducer';
import leaderboardsV2 from 'containers/LeaderboardV2/reducer';
import trends from 'containers/Trends/reducer';
import notifications from 'smu-app-components/NotificationsV2/reducer';
import modal from 'smu-app-components/RootModalV2/reducer';
import fullscreenModal from 'smu-app-components/FullScreenModalV2/reducer';
import starFlow from 'services/sendStarFlow/reducer';
import culturalCategory from 'components/CulturalCategory/reducer';
import addNamespace from 'containers/withNamespace';
import notificationsHistory from 'containers/NotificationsHistory/reducer';
import advancedSearch from 'containers/AdvancedSearch/reducer';
import communityCounters from 'containers/CommunityCounters/reducer';
import culturalIndicator from 'components/CulturalIndicator/reducer';
import tellExperience from 'components/FooterExperience/reducer';
import accountSettings from 'containers/AccountSettings/reducer';
import eula from 'components/EulaAgreement/reducer';
import tcAndpp from 'components/TCAndPPAgreement/reducer';
import vote from 'containers/VotePanel/reducer';
import userBio from 'containers/ProfileBoard/ProfileBio/reducer';
import unsubscribe from 'containers/UnsubscribePage/reducer';
import livingValues from 'containers/LivingValues/reducer';
import profileContent from 'components/ProfileLayout/reducer';
import bethere from 'containers/WidgetBeThere/reducer';
import dnaWidget from 'components/DNAWidget/reducer';
import highlightedStar from 'containers/HighlightedStar/reducer';
import interests from 'components/Interests/reducer';
import topRankUsers from 'components/TopStarWidget/reducer';
import interactionChartTimeline from 'containers/InteractionChart/reducer';
import interactions from 'components/InteractionWidget/reducer';
import cookiesPageReducer from 'containers/CookiesPolicyPage/reducer';
import connectionsGraph from 'components/ConnectionGraph/reducer';
import mutualConnections from 'components/MutualConnections/reducer';
import osFeed from 'containers/OSFeed/reducer';
import osFilters from 'components/OS/Filters/reducer';
import recognition from 'containers/Recognition/reducer';
import switcher from 'smu-app-components/Switcher/reducer';
import unifiedProfile from 'smu-unified-profile/reducer';
import userConfigs from 'services/userConfigs/reducer';
import sendStarProfile from 'services/sendStarProfile/reducer';
import questionnaires from 'components/Questionnaires/reducer';
import communityConfigs from 'services/communityConfigs/reducer';
import appLoader from 'services/appLoader/reducer';
import advancedUserSearch from 'services/advancedUserSearch/reducer';
import advancedUserFilters from 'services/advancedUserFilters/reducer';
import categoryValues from 'services/categoryValues/reducer';
import starsSummary from 'services/starsSummary/reducer';
import communityMember from 'services/communityMember/reducer';
import communityMemberMetrics from 'services/communityMemberMetrics/reducer';
import communityMembers from 'services/communityMembers/reducer';
import organizationValues from 'services/organizationValues/reducer';
import uploadShareImage from 'services/uploadShareImage/reducer';
import mainHeader from 'smu-app-components/MainHeader/reducer';
import mainSearch from 'smu-app-components/MainSearchApi/reducer';
import usersByKudosStarId from 'services/usersByKudosStarId/reducer';
import bethereProperties from 'services/bethereProperties/reducer';
import bethereInteractions from 'services/bethereInteractions/reducer';
import interactionsPosts from 'services/interactionsPosts/reducer';
import items from 'services/items/reducer';
import congratulations from 'services/congratulations/reducer';

// it's commented because we are working with a different version in react-redux
// import osFeed2 from 'components/OS/Feed/reducer';

const appReducer = combineReducers({
  accountSettings,
  advancedSearch,
  advancedUserFilters,
  advancedUserSearch,
  appLoader,
  badges,
  bethere,
  categoryValues,
  communityCounters,
  connectionsGraph,
  cookiesPageReducer,
  culturalCategory,
  culturalIndicator,
  dnaWidget,
  eula,
  feed,
  filters,
  filtersV2,
  fullscreenModal,
  highlightedStar,
  i18n,
  interactionChartTimeline,
  interactions,
  interests,
  leaderboards,
  leaderboardsV2,
  livingValues,
  mainHeader,
  mainSearch,
  modal,
  mutualConnections,
  notifications,
  notificationsHistory,
  profile,
  profileContent,
  recognition,
  requests,
  routing,
  search: partial(search, searchActions),
  session,
  starFlow,
  starFlowSearch: partial(search, addNamespace(searchActions, 'star_flow')),
  stars,
  suggestions,
  switcher,
  tcAndpp,
  tellExperience,
  toastr,
  topStars,
  topRankUsers,
  trends,
  unsubscribe,
  userBio,
  vote,
  osFeed,
  osFilters,
  ...unifiedProfile,
  userConfigs,
  sendStarProfile,
  questionnaires,
  communityConfigs,
  starsSummary,
  communityMember,
  communityMemberMetrics,
  communityMembers,
  organizationValues,
  uploadShareImage,
  usersByKudosStarId,
  bethereProperties,
  bethereInteractions,
  interactionsPosts,
  items,
  congratulations,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    const { routing: _routeState } = state;
    state = { routing: _routeState }; // eslint-disable-line no-param-reassign
  }

  return appReducer(state, action);
};

export default rootReducer;
