import { isValidURL } from 'utils/url';

export default function options(options, tooltipsTexts) {
  return {
    chart: {
      spacingBottom: 3,
      spacingLeft: 3,
      spacingRight: 3,
      spacingTop: 3,
      type: 'bar',
    },
    title: {
      text: null,
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      labels: {
        formatter: function formatter() {
          return `<div align="right" class="questionnaire-results-graphics__chart-x" title="${
            this.value
          }">
          ${isValidURL(this.value) ? `<img alt="media" src="${this.value}" />` : this.value}
        </div>`;
        },
        style: {
          width: '220px',
        },
        step: 1,
        useHTML: true,
      },
      type: 'category',
    },
    yAxis: {
      labels: {
        autoRotation: false,
        formatter() {
          return `<div class="questionnaire-results-graphics__chart-y" title="${this.value}">${this.value}%</div>`;
        },
        style: {
          textOverflow: 'none',
        },
        useHTML: true,
      },
      max: 100,
      min: 0,
      tickInterval: 10,
      title: {
        text: null,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        pointWidth: 20,
      },
    },
    tooltip: {
      backgroundColor: '#18181a',
      borderColor: '#18181a',
      borderRadius: 7,
      formatter() {
        return `
        <div style="padding: 3px 10px;">
          <span>${tooltipsTexts.answers}</span>
          </br>
          <span>${options?.[this.x]?.quantity} / <strong>${this.y}%</strong></span>
        </div>`;
      },
      hideDelay: 0,
      outside: true,
      padding: 0,
      shadow: false,
      style: {
        color: 'white',
        fontFamily: 'Roboto',
        fontSize: '11px',
        textAlign: 'center',
      },
      useHTML: true,
    },
    series: [
      {
        colorByPoint: true,
        colors: ['#afd547', '#70ce68', '#68cec9', '#49a6f8', '#4a7bec'],
      },
    ],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 320,
        },
        chartOptions: {
          xAxis: {
            labels: {
              style: {
                width: '120px',
              },
            },
          },
        },
      }],
    },
  };
}
