import { trackEvent } from 'smu-utils/gtm';

const event = (props) => {
  trackEvent({
    category: 'feed',
    action: 'sort_by',
    ...props,
  });
};

export const eventOnClickTab = (tab = 'all') =>
  event({
    label: tab,
  });

export const eventOnClickConnections = () =>
  event({
    label: 'click_connections',
  });

export const eventOnClickModalToggle = (status) =>
  event({
    label: status ? 'modal_latest' : 'modal_colleagues',
  });

export const eventOnClickModalClose = () =>
  event({
    label: 'modal_close',
  });
