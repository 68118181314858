import {
  GET_CULTURAL_CATEGORY,
  GET_CULTURAL_CATEGORY_SUCCESS,
  GET_CULTURAL_CATEGORY_FAIL,
} from './actionTypes';

export function getCategory(userId) {
  return {
    type: GET_CULTURAL_CATEGORY,
    payload: userId,
  };
}

export function getCategorySuccess(payload) {
  return {
    type: GET_CULTURAL_CATEGORY_SUCCESS,
    payload,
  };
}

export function getCategoryFail(error) {
  return {
    type: GET_CULTURAL_CATEGORY_FAIL,
    payload: error,
  };
}
