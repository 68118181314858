// @packages
import React from 'react';
import Skeleton from 'smu-ui-components/Skeleton';

// @own
import './styles.scss';

const SendStarSkeleton = () => (
  <div className="smu-send-star-sekeleton">
    <div className="smu-send-star-sekeleton__content">
      <div className="smu-send-star-sekeleton__header">
        <Skeleton
          animation="wave"
          className="smu-send-star-sekeleton__header-title"
          height={20}
          width={124}
        />
        <div>
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__header-remaining"
            height={13}
            width={98}
          />
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__header-count"
            height={18}
            width={70}
          />
        </div>
      </div>
      <div className="smu-send-star-sekeleton__body">
        <div className="smu-send-star-sekeleton__who">
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__who-title"
            height={20}
            width={70}
          />
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__who-input"
            height={60}
          />
        </div>
        <div className="smu-send-star-sekeleton__what">
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__what-title"
            height={20}
            width={80}
          />
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__what-input"
            height={75}
          />
        </div>
        <div className="smu-send-star-sekeleton__why">
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__why-title"
            height={20}
            width={60}
          />
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__why-input"
            height={200}
          />
        </div>
        <div className="smu-send-star-sekeleton__actions">
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__actions-cancel"
            height={40}
            width={125}
          />
          <Skeleton
            animation="wave"
            className="smu-send-star-sekeleton__actions-send"
            height={40}
            width={150}
          />
        </div>
      </div>
    </div>
  </div>
);

export default SendStarSkeleton;
