import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from 'smu-ui-components/Button';
import Icon from 'smu-ui-components/Icon';

import './styles.scss';

const MediaButton = ({
  disabled,
  media,
  onClick,
  size,
}) => {
  const iconSize = size / 2.5;
  return (
    <Button
      className={cn('rounded-full border-0 flex justify-center items-center',
        disabled ? 'bg-gray-iron' : `button--media button--media_${media}`)}
      disabled={disabled}
      icon
      noMargin
      noPadding
      onClick={disabled ? undefined : onClick}
      style={{ height: size, lineHeight: `${size}px`, width: size }}
    >
      <Icon icon={media} color={disabled ? 'jumbo' : 'white'} style={{ fontSize: iconSize }} />
    </Button >
  );
};

MediaButton.defaultProps = {
  disabled: false,
  size: 40,
};

MediaButton.propTypes = {
  disabled: PropTypes.bool,
  media: PropTypes.oneOf([
    'facebook', 'facebook-workplace', 'google-plus', 'linkedin', 'twitter',
  ]).isRequired,
  onClick: PropTypes.func,
  size: PropTypes.number,
};

export default MediaButton;
