// @packages
import React from 'react';
import Button from 'smu-ui-components/Button';
import PropTypes from 'prop-types';

// @own
import './styles.scss';
import SendStarCard from './SendStarCard';

const SendStarView = ({
  buttonDisabled,
  buttonText,
  changeStarMessage,
  closeButton,
  coreValues,
  description,
  onButtonClick,
  placeholder,
  title,
  usersSelected,
}) => (
  <div className="send-star-view">
    <div className="send-star-view__header">
      {closeButton && closeButton()}
      <div className="send-star-view__title">{title}</div>
      <div className="send-star-view__description">{description}</div>
    </div>
    <div className="send-star-view__values">
      {usersSelected.map((user, index) => (
        <SendStarCard
          changeStarMessage={changeStarMessage}
          comment={user.defaultMessage}
          key={user.starId}
          placeholder={placeholder}
          profileImageCode={user.user.profileImageCode}
          shouldFocus={index === 0}
          star={coreValues.find((star) => star.id === user.starId)}
          userName={`${user.user.firstName} ${user.user.lastName}`}
        />
      ))}
    </div>
    <div className="send-star-view__button">
      <Button
        color={buttonDisabled ? 'gray' : 'pink'}
        disabled={buttonDisabled}
        fluid
        onClick={onButtonClick}
        noMargin
      >
        {buttonText}
      </Button>
    </div>
  </div>
);

SendStarView.defaultProps = {
  buttonDisabled: false,
};

SendStarView.propTypes = {
  buttonDisabled: PropTypes.bool,
  buttonText: PropTypes.string,
  changeStarMessage: PropTypes.func.isRequired,
  closeButton: PropTypes.node,
  coreValues: PropTypes.array,
  description: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  usersSelected: PropTypes.array,
};

export default SendStarView;
