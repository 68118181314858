// @app
import { errorHandler } from 'utils/errorHandler';
import { defaultApi } from 'utils/requestHelper';

export function postUploadShareImage({ data }) {
  return defaultApi({
    data,
    method: 'POST',
    timeout: 0,
    url: '/briefme/api/v1/resources/shareable/files',
  })
    .then((data) => data)
    .catch(errorHandler);
}
