import { get } from 'lodash';
import { selectItemById } from 'services/items/selectors';
import { getFeedResults } from './helpers';
import { BADGES } from './constants';

export const selectCommunityId = ({ session }) => get(session, 'communityConfigurations.organization.id');
export const selectFilters = state => state?.osFeed?.filters;
export const selectOsFeed = ({ osFeed }) => osFeed;
export const selectOsFeedCommentCount = ({ osFeed }) => get(osFeed, 'commentCount');
export const selectOsFeedCommentLoadingId = ({ osFeed }) => get(osFeed, 'loadingId');
export const selectOsFeedEnabled = ({ osFeed }) => get(osFeed, 'osFeedEnabled', false);
export const selectOsFeedError = state => state?.osFeed?.errorMessage;
export const selectOsFeedFetching = ({ osFeed }) => get(osFeed, 'fetching');
export const selectOsFeedHasMore = ({ osFeed }) => get(osFeed, 'hasMore');
export const selectOsFeedLikeCount = ({ osFeed }) => get(osFeed, 'likeCount');
export const selectOsFeedParams = ({ osFeed }) => get(osFeed, 'params');
export const selectOsFeedRemovingLike = ({ osFeed }) => get(osFeed, 'removingLike');
export const selectOsFeedResult = ({ osFeed }) => get(osFeed, 'results');
export const selectOsFeedSendingComment = ({ osFeed }) => get(osFeed, 'sendingComment');
export const selectOsFeedSendingLike = ({ osFeed }) => get(osFeed, 'sendingLike');
export const selectOsFeedToggleFetching = ({ osFeed }) => get(osFeed, 'fetchingToggle');
export const selectOsFeedTotalElements = ({ osFeed }) => get(osFeed, 'totalElements');
export const selectUnvisitedItem = state => state?.osFeed?.unvisitedItems;
export const selectVisitedItem = state => state?.osFeed?.visitedItems;
export const selecti18nLocale = state => state.i18n.locale;
export const selectOsFeedCustomResult = (state, rights) => {
  const results = getFeedResults(selectOsFeedResult(state), rights);
  const items = results?.reduce((acc, cur) => {
    const id = cur?.type === BADGES ? cur?.id : cur?.data?.id;
    let data = selectItemById(state, id);

    if (data) {
      acc.push({
        ...cur,
        data,
      });
    } else {
      acc.push(cur);
    }

    return acc;
  }, []);
  return items;
};
