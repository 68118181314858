import { defineMessages } from 'react-intl';

export default defineMessages({
  sendingStar: {
    id: 'sendStar.sending',
    defaultMessage: 'SENDING STAR...',
  },
  successfulySent: {
    id: 'sendStar.successfulySent',
    defaultMessage: 'STAR SUCCESSFULLY SENT!',
  },
  suggestSendStar: {
    id: 'sendStar.suggestText',
    defaultMessage: 'Choose a value to send the star',
  },
  noKudosAvailable: {
    id: 'sendStar.noKudosAvailable',
    defaultMessage: 'You have already sent all your available group recognition for the month! Try sending a different type of star.',
  },
  noKudosAvailableDisabled: {
    id: 'sendStar.noKudosAvailabledisabled',
    defaultMessage: "It seems you don't have enough stars to select this! Try selecting fewer colleagues.",
  },
  noStarAvailable: {
    id: 'sendStar.noStarAvailable',
    defaultMessage: 'Hooray! You already sent all your stars this month. Next month you can continue recognizing your colleagues work!',
  },
  noStarAvailableDisabled: {
    id: 'sendStar.noStarAvailableDisabled',
    defaultMessage: "It seems you don't have enough stars to select this! Try selecting a group recognition instead or fewer colleagues.",
  },
});
