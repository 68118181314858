import {
  REQUEST_CATEGORY_VALUES,
  REQUEST_CATEGORY_VALUES_SUCCESS,
  REQUEST_CATEGORY_VALUES_FAIL,
} from './actionTypes';

export function requestCategoryValues(payload) {
  return {
    type: REQUEST_CATEGORY_VALUES,
    payload,
  };
}

export function requestCategoryValuesSuccess(payload) {
  return {
    type: REQUEST_CATEGORY_VALUES_SUCCESS,
    payload,
  };
}

export function requestCategoryValuesFail(payload) {
  return {
    type: REQUEST_CATEGORY_VALUES_FAIL,
    payload,
  };
}
