import { apiCollaborationInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export const apiGetChillOut = params =>
  apiCollaborationInstance({
    params,
    url: '/v1/chillout/meetings',
  }).then(({ data }) => data);

export const apiGetChillOutById = ({ id }) =>
  apiCollaborationInstance({
    url: `/v1/chillout/meetings/${id}`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
