import { paths } from 'utils/routes';
import CongratulationLayout from './CongratulationLayout';

export default () => ({
  component: CongratulationLayout,
  onEnter: () => {
    window.scrollTo(0, 0);
  },
  path: paths.congratulation(),
});
