// @app
import { errorHandler } from 'utils/errorHandler';
import { bethereApiInstance } from 'utils/requestHelper';

export function apiGetInteractionsPosts(communityId, postId) {
  return bethereApiInstance({
    url: `/organization/${communityId}/posts/${postId}/interactions`,
    data: {},
  })
    .then((data) => data)
    .catch(errorHandler);
}
