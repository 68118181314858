// @packages
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

// @own
import messages from './messages';

function TrendItem({ className, gtmOnclick, hashtag, sponsored, uses }) {
  const linkProps = {
    href: hashtag ? `/hashtags/${hashtag}` : undefined,
    component: hashtag ? Link : undefined,
    legacy: hashtag ? false : undefined,
    underline: hashtag ? 'hover' : undefined,
  };

  return (
    <div
      className={cn('flex justify-between', className)}
    >
      <div className="flex flex-col">
        <Typography
          className="text-shark text-sm hover:text-violet"
          variant="subtitle2"
          onClick={() => {
            if (gtmOnclick) {
              gtmOnclick(hashtag);
            }
          }}
          {...linkProps}
        >
          #{hashtag}
        </Typography>
        <Typography className="text-xs text-raven" variant="subtitle1">
          <FormattedMessage {...messages.trendsUses} values={{ count: uses }} />
        </Typography>
      </div>
      {sponsored && (
        <Typography className="text-xs text-raven" variant="subtitle1">
          <FormattedMessage {...messages.trendingSponsored} />
        </Typography>
      )}
    </div>
  );
}

TrendItem.propTypes = {
  className: PropTypes.string,
  gtmOnclick: PropTypes.func,
  hashtag: PropTypes.string.isRequired,
  osFeedEnabled: PropTypes.bool,
  sponsored: PropTypes.bool,
  uses: PropTypes.number.isRequired,
};

export default TrendItem;
