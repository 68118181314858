export const DEFAULT_TIMER_MESSAGE = 5000;
export const SORT_DATE = 'pi_creation_date';
export const TYPE_COMMENT = 'COMMENT';
export const TYPE_LIKE = 'LIKE';
export const TYPE_PHOTO = 'PHOTO';
export const TYPE_VIDEO = 'VIDEO';
export const getRequestIdCard = cardId => `bt-card-${cardId}`;
export const getRequestIdCardComment = cardId => `bt-card-${cardId}-comment`;
export const getRequestIdCardInteractions = cardId => `bt-card-${cardId}-interactions`;
export const getRequestIdCardLike = cardId => `bt-card-${cardId}-like`;
export const getRequestIdCardLikeRemove = cardId => `bt-card-${cardId}-like-remove`;
export const getRequestIdPostDelete = postId => `bt-card-${postId}-delete`;
export const getRequestIdSponsoredPostReactions = postId => `bt-card-${postId}-sponsored-reactions`;
export const getRequestIdSuggestionsHashtags = () => 'bt-suggestions-hashtags';
export const getRequestIdSuggestionsUsers = () => 'bt-suggestions-users';
