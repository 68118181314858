// @packages
import React from 'react';
import ActionComment from 'smu-ui-components/Cards/components/ActionComment';
import { injectIntl } from 'react-intl';

// @own
import messages from './messages';

const ActionCommentContainer = ({ intl, ...rest }) => (
  <ActionComment popupText={intl.formatMessage(messages.tooltip)} {...rest} />
);

export default injectIntl(ActionCommentContainer);
