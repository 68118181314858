// @app
import AppLoader from 'containers/AppLoader';
import EmptyAppLoader from 'containers/EmptyAppLoader';

// @own
import DefaultLayout from './Default/DefaultLayout';
import activityCommentsRoute from './ActivityComments';
import activityRoute from './Activity';
import advancedUserSearch from './AdvancedUserSearch';
import beKind from './BeKind';
import bethere from './Bethere';
import communications from './Communications';
import communityGuidelines from './CommunityGuidelines';
import componentsPage from './Components';
import congratulation from './Congratulation';
import congratulationFeed from './CongratulationFeed';
import congratulationUser from './CongratulationUser';
import congratulations from './Congratulations';
import cookiesPolicy from './cookiesPolicy';
import discover from './Discover';
import external from './External';
import giveStar from './GiveStarModal';
import hashtags from './Hashtags';
import homeRoute from './Home';
import initiatives from './Initiatives';
import insightDetailsPage from './InsightDetails';
import insightsPage from './Insights';
import leaderboardRoute from './Leaderboard';
import logout from './Logout';
import myTeam from './MyTeam';
import newFeaturesRoute from './NewFeatures';
import notFound from './notFound';
import notificationsHistoryRoute from './NotificationsHistory';
import onboardingPage from './OnboardingPage';
import oneOnOne from './OneOnOne/Home';
import oneOnOneDetail from './OneOnOne/Detail';
import otherProfileRoute from './OtherProfile';
import profileRoute from './Profile';
import questionnaire from './Questionnaire';
import questionnaireResults from './QuestionnaireResults';
import questionnaires from './Questionnaires';
import redirect from './Redirect';
import resetPassword from './ResetPassword';
import settingsRoute from './Settings';
import share from './Share';
import shareType from './ShareType';
import surveysResult from './SurveysResult';
import test from './Test';
import timeline from './Timeline';
import whatsNew from './WhatsNew';
import yearInReview from './YearInReview';

export default (store) => [
  {
    component: DefaultLayout,
    path: '/',
  },
  {
    path: '/',
    component: AppLoader,
    childRoutes: [
      activityCommentsRoute(store),
      advancedUserSearch(store),
      beKind(store),
      communications(store),
      componentsPage(store),
      congratulation(store),
      congratulationFeed(store),
      congratulationUser(store),
      congratulations(store),
      discover(store),
      giveStar(store),
      hashtags(store),
      homeRoute(store),
      initiatives(store),
      insightDetailsPage(store),
      insightsPage(store),
      leaderboardRoute(store),
      myTeam(store),
      myTeam(store),
      newFeaturesRoute(store),
      notificationsHistoryRoute(store),
      onboardingPage(store),
      oneOnOne(store),
      oneOnOneDetail(store),
      otherProfileRoute(store),
      profileRoute(store),
      questionnaire(store),
      questionnaireResults(store),
      questionnaires(store),
      settingsRoute,
      share(store),
      shareType(store),
      surveysResult(store),
      test(store),
      timeline(store),
      whatsNew(store),
      yearInReview(store),
    ],
  },
  {
    path: '/',
    component: EmptyAppLoader,
    childRoutes: [
      communityGuidelines(store),
    ],
  },
  activityRoute,
  bethere,
  cookiesPolicy,
  external,
  logout,
  redirect,
  resetPassword,
  notFound,
];
