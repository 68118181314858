// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import GenericModal from 'components/GenericModal';
import HeaderUsersAvatars from 'components/HeaderUsersAvatars';
import IconText from 'components/IconText';

// @own
import messages from './messages';
import './styles.scss';

function AddCalendarModalContainer({
  calendar,
  intl: { formatMessage },
  onClose,
  onClick,
  users,
  ...rest
}) {
  const [type, setType] = useState('SUCCESS');
  const getType = (type) => {
    const modalProps = {
      SUCCESS: {
        actions: [
          {
            children: formatMessage(messages.addCalendarAddToCalendar),
            onClick,
          },
        ],
        children: (<IconText icon="info-square" text={formatMessage(messages.addCalendarDescription)} />),
        closeIconProps: ({className: 'add-calendar-modal__close'}),
        renderHeader: () => (
          <HeaderUsersAvatars
            description={formatMessage(messages.addCalendarHeaderDescription)}
            title={formatMessage(messages.addCalendarHeaderTitle)}
            users={users}
          />
        ),
        onClose: () => setType('DISCARD'),
      },
      DISCARD: {
        actions: [
          {
            children: formatMessage(messages.addCalendarNoButton),
            onClick: () => setType('SUCCESS'),
            variant: 'outline',
          },
          {
            children: formatMessage(messages.addCalendarYesButton),
            onClick: onClose,
          },
        ],
        children: formatMessage(messages.addCalendarDiscardDescription),
        title: formatMessage(messages.addCalendarDiscardTitle),
      },
    };
    return modalProps[type];
  };

  return (
    <GenericModal
      {...getType(type)}
      {...rest}
    />
  );
}

AddCalendarModalContainer.propTypes = {
  calendar: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  users: PropTypes.array,
};

export default injectIntl(AddCalendarModalContainer);
