import { defineMessages } from 'react-intl';

export default defineMessages({
  addCalendarAddToCalendar: {
    defaultMessage: 'Add to calendar',
    id: 'addCalendar.addToCalendar',
  },
  addCalendarDescription: {
    defaultMessage: 'Remember to add the link to the video call in your calendar event!',
    id: 'addCalendar.description',
  },
  addCalendarDiscardDescription: {
    defaultMessage: 'Are you sure you want to leave without saving the Get-Together in your calendar? All data will be lost.',
    id: 'addCalendar.discardDescription',
  },
  addCalendarDiscardTitle: {
    defaultMessage: 'DISCARD GET-TOGETHER',
    id: 'addCalendar.discardTitle',
  },
  addCalendarHeaderDescription: {
    defaultMessage: 'You have created a Get-Together successfully!',
    id: 'addCalendar.headerDescription',
  },
  addCalendarHeaderTitle: {
    defaultMessage: 'Hooray',
    id: 'addCalendar.headerTitle',
  },
  addCalendarNoButton: {
    defaultMessage: 'No',
    id: 'addCalendar.noButton',
  },
  addCalendarYesButton: {
    defaultMessage: 'Yes',
    id: 'addCalendar.yesButton',
  },
});
