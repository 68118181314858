import React from 'react';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';

function PhotoWidget({
  className,
  disabled,
  image,
  onChange,
}) {
  return (
    <div className={cn('photo-widget', className)}>
      <Icon
        className={cn(
          'photo-widget__upload-icon',
          { 'photo-widget__upload-icon--black': image && image?.code },
        )}
        color={image && image.code ? 'black' : 'gray'}
        icon="image"
        size="small"
      />
      <input
        accept="image/*"
        className="photo-widget__upload-input"
        disabled={disabled}
        onChange={onChange}
        type="file"
      />
    </div>
  );
}

export default PhotoWidget;
