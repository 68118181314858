export const TYPE_BADGE = 'badge';
export const TYPE_BEHEALTHY_INSIGHT = 'bh-insight';
export const TYPE_DNA = 'dna';
export const TYPE_ENABLED_RENDER = 'TYPE_ENABLED_RENDER';
export const TYPE_IMAGE_LOADED = 'TYPE_IMAGE_LOADED';
export const TYPE_SHARE_END = 'TYPE_SHARE_END';
export const TYPE_SHARE_ERROR = 'TYPE_SHARE_ERROR';
export const TYPE_SHARE_IMAGE = 'TYPE_SHARE_IMAGE';
export const TYPE_SHARE_START = 'TYPE_SHARE_START';
export const TYPE_STAR = 'star';
export const TYPE_YEAR_IN_REVIEW = 'year-in-review';
