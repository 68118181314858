import { defineMessages } from 'react-intl';

export default defineMessages({
  recent: {
    id: 'feed.recent',
    defaultMessage: 'RECENT',
  },
  mostLiked: {
    id: 'feed.mostLiked',
    defaultMessage: 'HIGHLIGHTS',
  },
  bethere: {
    id: 'feed.bethere',
    defaultMessage: 'Photos and videos',
  },
});
