// @Packages
import { takeEvery, call, put } from 'redux-saga/effects';
import { closeModal } from 'smu-app-components/RootModalV2/actions';
// @App
import addToast from 'utils/defaultToastAdd';
import storeRef from 'utils/store';
import { MODAL_LIVING_VALUES } from 'containers/QueryModals/constants';
// @Own
import {
  dismissLivingValues,
  getLivingValues,
  getLivingValuesTexts,
  postponeLivingValues,
  sendLivingValues,
} from './api';
import {
  DISMISS_LIVING_VALUES,
  GET_LIVING_VALUES_TEXTS,
  REMINDER_LIVING_VALUES,
  SEND_LIVING_VALUES,
  START_LIVING_VALUES,
} from './actionTypes';
import {
  dismissLivingValuesFail,
  dismissLivingValuesSuccess,
  getLivingValuesTextsFail,
  getLivingValuesTextsSuccess,
  goToView,
  remindLivingValuesFail,
  remindLivingValuesSuccess,
  sendLivingValuesFail,
  sendLivingValuesSuccess,
  setValuesDone,
  startLivingValuesFail,
  startLivingValuesSuccess,
} from './actions';

import messages from './messages';
import { goToQueryModal } from '../QueryModals/helpers';

function* dismissLivingValuesWorker() {
  try {
    yield call(dismissLivingValues);
    yield put(dismissLivingValuesSuccess({ isDismissed: true }));
    yield put(closeModal());
  } catch (e) {
    yield put(dismissLivingValuesFail());
    yield put(closeModal());
  }
}

function* dismissLivingValuesWatcher() {
  yield takeEvery(DISMISS_LIVING_VALUES, dismissLivingValuesWorker);
}

function* getLivingValuesTextsWorker({ payload }) {
  const { languageCode, name } = payload;
  try {
    const data = yield call(getLivingValuesTexts, { languageCode, name });
    const { status } = data;
    if (status === 'FAIL') {
      const { i18n: { messages: storeMessages } } = storeRef().getState();
      yield put(addToast({
        message: storeMessages['livingValues.error'] || messages.livingValuesError.defaultMessage,
      }));
      yield put(dismissLivingValuesFail());
      yield put(getLivingValuesTextsFail());
    } else {
      yield put(getLivingValuesTextsSuccess(data));
    }
  } catch (e) {
    yield put(dismissLivingValuesFail());
    yield put(closeModal());
    yield put(getLivingValuesTextsFail());
  }
}

function* getLivingValuesTextsWatcher() {
  yield takeEvery(GET_LIVING_VALUES_TEXTS, getLivingValuesTextsWorker);
}

function* sendLivingValuesWorker({ payload }) {
  try {
    const { livingValues } = payload;
    const stars = livingValues.map((value) => {
      const defaultMessage = value.defaultMessage.replace(/[*]/g, '');
      if (value.customMessage && value.customMessage !== '') {
        const message = `${defaultMessage} ${value.customMessage}`;
        return {
          ...value,
          message,
        };
      }
      return {
        ...value,
        message: defaultMessage,
      };
    });
    const starsReady = stars.map(value => ({
      toIdentification: value.user.identification,
      valueId: value.starId,
      notes: value.message,
    }));
    const data = yield call(sendLivingValues, starsReady);
    const { status } = data;
    if (status === 'FAIL') {
      yield put(sendLivingValuesFail());
      const { i18n: { messages: storeMessages } } = storeRef().getState();
      yield put(addToast({
        message: storeMessages['livingValues.error'] || messages.livingValuesError.defaultMessage,
      }));
    } else {
      yield put(sendLivingValuesSuccess(data));
      yield put(goToView('end'));
    }
  } catch (e) {
    yield put(sendLivingValuesFail());
  }
}

function* sendLivingValuesWatcher() {
  yield takeEvery(SEND_LIVING_VALUES, sendLivingValuesWorker);
}

function* startLivingValuesWorker({ payload }) {
  try {
    const { completed, values } = yield call(getLivingValues);
    if (completed) {
      yield put(goToView('end'));
    } else {
      const valuesDone = values || [];
      yield put(setValuesDone(valuesDone));
    }
    yield put(startLivingValuesSuccess());
    goToQueryModal(MODAL_LIVING_VALUES, payload?.router);
  } catch (e) {
    yield put(startLivingValuesFail());
    yield put(addToast({
      message: 'We are experiencing some temporary issues. Our apologies, please try again later',
    }));
  }
}

function* startLivingValuesWatcher() {
  yield takeEvery(START_LIVING_VALUES, startLivingValuesWorker);
}

function* remindLivingValuesWorker() {
  try {
    yield call(postponeLivingValues);
    yield put(remindLivingValuesSuccess());
    yield put(closeModal());
  } catch (e) {
    yield put(remindLivingValuesFail());
    yield put(closeModal());
  }
}

function* remindLivingValuesWatcher() {
  yield takeEvery(REMINDER_LIVING_VALUES, remindLivingValuesWorker);
}

export default {
  dismissLivingValuesWatcher,
  getLivingValuesTextsWatcher,
  remindLivingValuesWatcher,
  sendLivingValuesWatcher,
  startLivingValuesWatcher,
};
