// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Backdrop from 'smu-ui-components/Backdrop';
import { createPortal } from 'react-dom';

// @app
import usePortal from 'hooks/usePortal';

const Modal = ({ className, open, id, ...rest }) => {
  const target = usePortal(id);

  return createPortal(
    <Backdrop
      className={cn('smu-modal', className)}
      centered
      open={open}
      {...rest}
    />,
    target,
  );
};

Modal.defaultProps = {
  id: 'app',
  open: false,
};

Modal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  open: PropTypes.bool,
};

export default Modal;
