// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import xss from 'xss';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { selectResult, selectRequesting } from 'smu-utils/reduxRequests/selectors';
import { withRouter } from 'react-router';

// @own
import './styles.scss';
import { GET_COMMUNITY_GUIDELINES_ID } from './constants';
import { apiGetCommunityGuidelines } from './api';

const CommunityGuidelines = ({
  actionRequestInit,
  loading,
  location: { query },
  result,
}) => {
  const getCommunityGuidelines = () => {
    actionRequestInit({
      api: apiGetCommunityGuidelines,
      id: GET_COMMUNITY_GUIDELINES_ID,
      params: {
        languageCode: query?.languageCode || getUserLanguage(),
        name: 'community_guidelines',
      },
    });
  };

  useEffect(() => {
    getCommunityGuidelines();
  }, []);

  return (
    <div className="community-guidelines">
      <div className="community-guidelines__header">
        <div className="community-guidelines__header-content">
          <img src="./assets/img/logo.svg" alt="logo StarMeUp"/>
        </div>
      </div>
      <div className="community-guidelines__content">
        <Segment loading={loading} loadingType="starmeup">
          {/* eslint-disable-next-line react/no-danger */}
          <section className="community-guidelines__result" dangerouslySetInnerHTML={{__html: xss(result)}} />
        </Segment>
      </div>
      <div className="community-guidelines__footer">
        <div className="community-guidelines__footer-content">
          <img src="./assets/img/logo-smu-white.png" alt="logo smu footer" />
        </div>
      </div>
    </div>
  );
};

CommunityGuidelines.propTypes = {
  actionRequestInit: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object,
  result: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loading: selectRequesting(state, GET_COMMUNITY_GUIDELINES_ID),
  result: selectResult(state, GET_COMMUNITY_GUIDELINES_ID),
});

const mapDispatchToProps = {
  actionRequestInit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CommunityGuidelines));
