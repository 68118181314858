// @packages
import cloneDeep from 'lodash/cloneDeep';

// @own
import {
  REQUEST_COMMUNITY_MEMBER_METRICS,
  REQUEST_COMMUNITY_MEMBER_METRICS_SUCCESS,
  REQUEST_COMMUNITY_MEMBER_METRICS_FAIL,
} from './actionTypes';

const initialState = {};

const idState = {
  error: undefined,
  requesting: false,
  result: undefined,
};

function merge(state, id, update) {
  const prevState = cloneDeep(state);
  return {
    ...prevState,
    [id]: {
      ...idState,
      ...prevState[id],
      ...update,
    },
  };
}

export default function reducer(state = initialState, { type, payload, id }) {
  switch (type) {
    case REQUEST_COMMUNITY_MEMBER_METRICS:
      return merge(state, id, { requesting: true });
    case REQUEST_COMMUNITY_MEMBER_METRICS_SUCCESS:
      return merge(state, id, { result: payload?.result, requesting: false });
    case REQUEST_COMMUNITY_MEMBER_METRICS_FAIL:
      return merge(state, id, { ...payload, requesting: false });
    default:
      return state;
  }
}
