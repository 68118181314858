// @own
import {
  REQUEST_CATEGORY_VALUES,
  REQUEST_CATEGORY_VALUES_SUCCESS,
  REQUEST_CATEGORY_VALUES_FAIL,
} from './actionTypes';

export const categoryState = {
  error: null,
  requesting: false,
  result: undefined,
  success: false,
};

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_CATEGORY_VALUES:
      return {
        ...state,
        [payload?.id]: {
          ...categoryState,
          requesting: true,
          success: false,
        },
      };
    case REQUEST_CATEGORY_VALUES_SUCCESS:
      return {
        ...state,
        [payload?.id]: {
          ...categoryState,
          requesting: false,
          result: payload?.result,
          success: true,
        },
      };
    case REQUEST_CATEGORY_VALUES_FAIL:
      return {
        ...state,
        [payload?.id]: {
          ...categoryState,
          error: payload?.error,
          requesting: false,
        },
      };
    default:
      return state;
  }
}
