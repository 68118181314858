import {
  get, merge, mergeWith, toPairs, map, reduce, pick, isUndefined, some, truncate,
} from 'lodash';
import { takeEvery, select, call, put } from 'redux-saga/effects';

import { filterObject } from 'utils/object';

import { requestFiltersUpdate, updateFilters } from './actions';
import { categoryValues as categoryValuesAPI } from './api';

const getAll = s => s.filters;

export const getFilterValues = ({ filters, fields, currentField }) => {
  const filter = filters[currentField] || {};
  const zeroOffice = filter.parentField === 'city' && get(fields, filter.parentField, '') === '0';
  const storeKey = !filter.parentField
    ? 'fields'
    : fields[filter.parentField] && !zeroOffice
      ? `fields.${fields[filter.parentField]}`
      : 'fields._ALL_';
  return map(get(filter, storeKey, []), props => ({
    ...props,
    label: truncate(props.label, { length: 50, separator: ' ...' }),
  }));
};

export const fixedFilters = [
  'country', 'city', 'office', 'organizationValue', 'periodTime', 'typeLeaderboard',
];

function* requestFiltersUpdateWorker({ payload }) {
  const { availableFilters, fields, dispatch } = payload;

  let filters = mergeWith(yield select(getAll), reduce(
    availableFilters,
    (memo, f) => merge(memo, { [f.field]: pick(f, ['label', 'parentField']) }),
    {},
  ), (a, b) => merge(a, b));
  const filtersNeeded = reduce(
    availableFilters,
    (memo, f) => merge(memo, { [f.field]: fields[f.field] }),
    {},
  );

  for (const name in filtersNeeded) {
    if (Object.prototype.hasOwnProperty.call(filtersNeeded, name)) {
      const filter = filters[name];
      const { parentField } = filter;
      const parentValue = filtersNeeded[parentField];

      const zeroOffice = parentField === 'city' && parentValue === '0';
      const hasCachedResult = !parentField
        ? !!filter.fields
        : !!get(filter.fields, zeroOffice ? '_ALL_' : parentValue || '_ALL_');
      if (!hasCachedResult) {
        const result = map(
          toPairs(yield call(categoryValuesAPI, {
            category: name,
            parentId: zeroOffice ? undefined : parentValue,
          })),
          ([k, v]) => ({
            label: v,
            value: String(k),
          }),
        );
        const storeKey = zeroOffice ? '_ALL_' : parentValue || '_ALL_';
        filters = merge(filters, {
          [name]: merge({}, filter, {
            fields: parentField
              ? { ...filter.fields, [storeKey]: result }
              : result,
          }),
        });
      }
    }
  }

  yield put(updateFilters(filters));

  const cleanFields = reduce(
    filterObject(filtersNeeded, f => !isUndefined(f)),
    (memo, v, k) => {
      const filter = filters[k];
      const noSelectedCity = filter.parentField === 'city' && filtersNeeded[filter.parentField] === '0';
      let hasFields;
      const parent = filter.parentField && filtersNeeded[filter.parentField];
      if (parent && !noSelectedCity) {
        const properParent = some(filter.fields[parent], ({ value }) => value === v);
        hasFields = properParent;
      } else {
        hasFields = filter.fields;
      }
      return merge(memo, hasFields ? { [k]: v } : {});
    },
    {},
  );

  if (dispatch) {
    const { type, payload } = dispatch;
    yield put({
      type,
      payload: merge({}, payload, { fields: cleanFields }),
    });
  }
}

export function* requestFiltersUpdateWatcher() {
  yield takeEvery(requestFiltersUpdate().type, requestFiltersUpdateWorker);
}

export default {
  requestFiltersUpdateWatcher,
};
