// @packages
import React from 'react';
import { useMembersById } from 'smu-services/os-member/api/v1/community/members/[id]';

// @app
import UserPopupComponent from 'components/UserPopup';

const UserPopupOther = ({ userId, ...rest }) => {
  const member = useMembersById({ userId });
  const memerUID = member.selectPropertyUID();
  const profileUrl = `/profile/${memerUID}`;
  const firstName = member.selectFirstName();
  const lastName = member.selectLastName();
  const imageCode = member.selectImageCode();
  const fullName = `${firstName} ${lastName}`;
  const email = member?.data?.result?.email;

  return (
    <UserPopupComponent
      email={email}
      fullName={fullName}
      imageCode={imageCode}
      isLoading={member.isLoading}
      profileUrl={profileUrl}
      {...rest}
    />
  );
};

export default UserPopupOther;
