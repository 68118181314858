import {
  APP_LOADER_ENABLED,
  APP_LOADER_FAIL,
  APP_LOADER_INIT,
  APP_LOADER_RESTART,
  APP_LOADER_SUCCESS,
} from './actionTypes';

const initialState = {
  enabled: false,
  error: null,
  errorCode: null,
  errorType: null,
  success: false,
};

export default function reducer(
  state = { ...initialState },
  { type, payload },
) {
  switch (type) {
    case APP_LOADER_ENABLED:
      return {
        ...state,
        enabled: payload,
      };
    case APP_LOADER_INIT:
      return {
        ...state,
        ...initialState,
        enabled: true,
      };
    case APP_LOADER_FAIL:
      return {
        ...state,
        enabled: false,
        error: payload?.error,
        errorType: payload?.errorType,
        errorCode: payload?.error?.status || payload?.error?.response?.status,
        success: false,
      };
    case APP_LOADER_SUCCESS:
      return {
        ...state,
        enabled: false,
        error: null,
        errorType: null,
        success: true,
      };
    case APP_LOADER_RESTART:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
