// @packages
import React, { useEffect } from 'react';
import Backdrop from 'smu-ui-components/Backdrop';
import PropTypes from 'prop-types';
import useScrollLock from 'smu-custom-hooks/useScrollLock';
import cn from 'classnames';

// @app
import FeedSortToggle from 'components/FeedSortToggle';

// @own
import './styles.scss';

const FeedSortModal = ({
  className,
  contentStyle,
  headerIcon,
  headerText,
  onBackdropClick,
  onToggle,
  open,
  toggleText,
}) => {
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [open]);

  return (
    <Backdrop
      className={cn('feed-sort-modal', className)}
      onClick={onBackdropClick}
      open={open}
    >
      {open && (
        <div
          className="feed-sort-modal__content"
          style={contentStyle}
        >
          <FeedSortToggle
            headerIcon={headerIcon}
            headerText={headerText}
            onToggle={onToggle}
            toggleText={toggleText}
          />
        </div>
      )}
    </Backdrop>
  );
};

FeedSortModal.propTypes = {
  className: PropTypes.string,
  contentStyle: PropTypes.object,
  headerIcon: PropTypes.string,
  headerText: PropTypes.string,
  onBackdropClick: PropTypes.func,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  toggleText: PropTypes.string,
};

export default FeedSortModal;
