// @ Packages
import moment from 'moment';
// @ App
import paramsBuilder from 'smu-utils/paramsBuilder';

export const vo = { v: 3, o: 'SMU_WEB_v3' };
export const times = () => ({
  timezone: moment().utcOffset(),
  timestamp: moment().valueOf(),
});
export const addParams = (url, params) => [url, '?', paramsBuilder(params)].join('');
export const formData = {
  method: 'post',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
};
