// @own
import { buildClosePath } from '../helpers';

const sendStar = ({ hookModal, onClose }) => {
  const closePath = buildClosePath([
    'comment',
    'coreValueId',
    'criteria',
    'editId',
    'id',
    'uid',
    'users',
    'valueId',
  ]);

  hookModal.open({
    onClose: () => onClose(closePath),
  });
};

export default sendStar;
