/* eslint-disable react/no-danger */
// @packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import xss from 'xss';

// @own
import './styles.scss';

const HTML = ({ className, html }) => {
  const sanityHtml = useMemo(() => {
    const htmlReplace = html?.replace?.(/(?:\r\n|\r|\n)/g, '<br>');
    return xss(htmlReplace);
  }, [html]);

  return (
    <div
      className={cn('smu-html', className)}
      dangerouslySetInnerHTML={{
        __html: xss(sanityHtml),
      }}
    />
  );
};

HTML.defaultProps = {
  html: '',
};

HTML.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string,
};

export default HTML;
