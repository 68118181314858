import { call, put, takeEvery } from 'redux-saga/effects';
import {
  SEND_EXPERIENCE,
  SEND_EXPERIENCE_SUCCESS,
  SEND_EXPERIENCE_FAILED,
} from './actionTypes';

import { sendExperience } from './api';

function* sendExperienceWorker({ payload }) {
  try {
    const data = yield call(sendExperience, payload);
    yield put({ type: SEND_EXPERIENCE_SUCCESS, payload: data.result });
  } catch (e) {
    yield put({ type: SEND_EXPERIENCE_FAILED, payload: e });
  }
}

export default function* sendExperienceWatcher() {
  yield takeEvery(SEND_EXPERIENCE, sendExperienceWorker);
}
