import {
  SET_PROFILE_BIO,
  SET_PROFILE_BIO_SUCCESS,
  SET_PROFILE_BIO_FAIL,
} from './actionTypes';

const initialState = {
  error: false,
  setting: false,
};

// eslint-disable-next-line no-unused-vars
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PROFILE_BIO:
      return {
        ...state,
        error: false,
        setting: true,
      };
    case SET_PROFILE_BIO_SUCCESS:
      return {
        ...state,
        setting: false,
      };
    case SET_PROFILE_BIO_FAIL:
      return {
        ...state,
        setting: false,
        error: true,
      };
    default:
      return state;
  }
}
