import useSWR from 'swr';
import { toDataURL } from 'utils/image';

function useBlobUrl(url) {
  const { data, error } = useSWR(url || null, toDataURL, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    data,
    error,
    isLoading: !error && !data,
  };
}

export default useBlobUrl;
