import { defaults, merge } from 'lodash';

import { actionTypes } from './actions';

const {
  getLeaderboard,
  getLeaderboardSuccess,
  getLeaderboardFailed,
  updateLeaderboardFields,
  cleanLeaderboard,
} = actionTypes;

const initialState = {
  results: [],
  fetching: true,
  error: '',
  fields: {},
  filters: null,
  filtersShown: false,
  filtersApplied: false,
};

const addFields = fields =>
  defaults(fields, {
    typeLeaderboard: 'RECEIVER',
    periodTime: 'thisMonth',
  });

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case getLeaderboard:
      return {
        ...state,
        fetching: payload.updateLeaderboard || state.fetching,
        fields: addFields(merge(payload.persistedFields || {}, payload.fields || state.fields)),
        filters: state.filters || payload.filters,
        filtersShown:
          payload.filtersShown !== undefined ? payload.filtersShown : state.filtersShown,
        filtersApplied:
          payload.filtersApplied !== undefined ? payload.filtersApplied : state.filtersApplied,
      };
    case getLeaderboardSuccess:
      return {
        ...state,
        results: payload,
        fetching: false,
        error: '',
      };
    case getLeaderboardFailed:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case updateLeaderboardFields:
      return {
        ...state,
        fields: payload.fields,
      };
    case cleanLeaderboard:
      return initialState;
    default:
      return state;
  }
}
