export const isProd = () => {
  const prodEnvironments = ['www', 'alpha', 'beta', 'app'];
  const hostPrefix = window.location.host.split('.')[0];
  return prodEnvironments.includes(hostPrefix);
};

export const isBeta = () => {
  const betaEnvironments = ['beta'];
  const hostPrefix = window.location.host.split('.')[0];
  return betaEnvironments.includes(hostPrefix);
};
