// @packages
import React, { useEffect, useState } from 'react';
import Divider from 'smu-ui-components/Divider';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import RichInputText from 'containers/RichInputText';
import { EditorState } from 'draft-js';

// @app
import { buildImageSMU } from 'utils/image';
import { formatBoldText } from 'utils/boldText';
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';

function onCustomTextClick() {
  trackEvent({
    category: 'LivingValue',
    action: 'LivingValue_CustomText',
  });
}

const SendStarCard = ({
  changeStarMessage,
  comment,
  placeholder,
  profileImageCode,
  shouldFocus,
  star,
  userName,
}) => {
  const { backgroundColor, id, imageCode, nameShowed } = star;
  const [value, setValue] = useState();
  const image = buildImageSMU({
    code: profileImageCode,
    width: 90,
    height: 90,
  });

  useEffect(() => {
    let timer;
    if (value !== undefined) {
      timer = setTimeout(() => {
        setValue(value);
        changeStarMessage(id, value);
      }, 500);
    }
    return () => timer && clearTimeout(timer);
  }, [value]);

  return (
    <div className="send-card">
      <div className="send-card__content">
        <div
          className="send-card__content-image"
          style={{ backgroundImage: `url("${image}")` }}
        />
        <div className="send-card__content-info">
          <div className="send-card__content-name">{userName}</div>
          <div
            className="send-card__content-value"
            style={{ color: `${backgroundColor}` }}
          >
            <div
              className="send-card__content-value-image"
              style={{ backgroundColor: `${backgroundColor}` }}
            >
              <Image
                className="send-card__content-value-image-img"
                code={imageCode}
                height={17}
                width={17}
              />
            </div>
            <div className="send-card__content-value-name">{nameShowed}</div>
          </div>
          <div className="send-card__content-default">
            {formatBoldText(comment)}
          </div>
          <div onClick={() => onCustomTextClick()}>
            <RichInputText
              className="send-card__content-text"
              comment={comment}
              editorState={EditorState.createEmpty()}
              maxCharacters={500}
              mentionsEnabled
              onInputChange={({ comment }) => setValue(comment)}
              placeholder={placeholder}
              shouldFocus={shouldFocus}
            />
          </div>
        </div>
      </div>
      <Divider nomargin />
    </div>
  );
};

SendStarCard.defaultProps = {
  shouldFocus: false,
};

SendStarCard.propTypes = {
  changeStarMessage: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  profileImageCode: PropTypes.string,
  shouldFocus: PropTypes.bool,
  star: PropTypes.object,
  userName: PropTypes.string,
};

export default SendStarCard;
