// @packages
import React from 'react';
import Skeleton from 'smu-ui-components/Skeleton';

// @own
import { EMPTY_DATA } from './constants';
import './styles.scss';

const SkeleteonUsefulLinks = () => (
  <div className="skeleton-useful-links">
    <div className="skeleton-useful-links__title">
      <Skeleton width={157} height={20}/>
    </div>
    <div className="skeleton-useful-links__links">
      {EMPTY_DATA?.map((idx) => (
        <div
          className="skeleton-useful-links__link-container"
          key={idx}
        >
          <div className="skeleton-useful-links__link-title">
            <Skeleton width={100} height={20}/>
          </div>
          <div className="skeleton-useful-links__link-description">
            <Skeleton width={220} height={35}/>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default SkeleteonUsefulLinks;
