import { merge } from 'lodash';
import { put, takeEvery } from 'redux-saga/effects';
import { initializeFeed, starsUpdate, applyFilters } from './actions';

function* initializeFeedWorker({ payload }) {
  const { dispatch } = payload;
  if (dispatch) {
    yield put(dispatch);
  }
}

function* initializeFeedWatcher() {
  yield takeEvery(initializeFeed().type, initializeFeedWorker);
}

function* starsUpdateWorker({ payload }) {
  const { dispatch } = payload;
  if (dispatch) {
    yield put({
      type: dispatch.type,
      payload: merge(dispatch.payload, { fields: payload.fields }),
    });
  }
}

function* starsUpdateWatcher() {
  yield takeEvery(starsUpdate().type, starsUpdateWorker);
}

function* applyFiltersWorker({ payload: p }) {
  const { dispatch } = p || {};
  if (dispatch) {
    const { type, payload } = dispatch;
    yield put({
      type,
      payload: merge(payload, { fields: p.fields }),
    });
  }
}

function* applyFiltersWatcher() {
  yield takeEvery(applyFilters().type, applyFiltersWorker);
}

export default {
  initializeFeedWatcher,
  starsUpdateWatcher,
  applyFiltersWatcher,
};
