// @app
import { paths } from 'utils/routes';

// @own
import AdvancedUserSearchLayout from './Layout';

export default () => ({
  component: AdvancedUserSearchLayout,
  path: paths.advancedSearch(),
});
