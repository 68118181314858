// @packages
import Axios from 'axios';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { base64Decode } from 'smu-utils/base64';

const FRIST_ORDER = 'administrative_area_level_1';
const SECOND_ORDER = 'administrative_area_level_2';
const GEO_CODING_KEY = 'QUl6YVN5QnBjTjJLUC1GMHI2NkpjWFo4dC1QbHdoak5IOUpkVGRF';

const findOrder = (addressComponents, order) => {
  const orderFound = find(
    addressComponents,
    item => includes(item.types, order),
  );

  if (orderFound?.long_name) {
    return orderFound.long_name;
  }

  return '';
};

export const buildCalendarUrl = ({
  description,
  endDateTime,
  guests,
  startDateTime,
  title,
}) => {
  const action = 'action=TEMPLATE';
  const basicUrl = 'https://calendar.google.com/calendar/render?';
  const date = `dates=${startDateTime}/${endDateTime}`;
  const details = `details=${description}`;
  const guestsList = `add=${guests.join()}`;
  const text = `text=${title}`;
  return `${basicUrl}${action}&${text}&${date}&${details}&${guestsList}`;
};

export const getGeocoding = (lat, lng) => (
  Axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${base64Decode(GEO_CODING_KEY)}`, {
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        delete headers.common.token;
        return data;
      },
    ],
  })
    .then(({ data }) => {
      if (data?.results) {
        const addressComponents = data?.results?.[0].address_components;
        const firstOrder = findOrder(addressComponents, FRIST_ORDER);
        const secondOrder = findOrder(addressComponents, SECOND_ORDER);
        return `${secondOrder} ${firstOrder}.`;
      }
      return '';
    })
    .catch((error) => error)
);
