// @packages
import React, { useEffect } from 'react';
import Container from 'smu-layout-components/Container';
import OneOnOneHomeAPP from 'smu-app-components/OneOnOne/Home';
import UtilsProvider from 'smu-app-components/UtilsProvider';
import { useSelector } from 'react-redux';

// @app
import {
  CALENDAR_PROVIDER,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_REQUEST,
} from 'services/communityConfigs/constants';
import { getCommunityConfigValue, getCommunityConfigValueBool } from 'services/communityConfigs/selectors';

const OneOnOneHome = () => {
  const [enabledOneOnOne, enabledOneOnOneRequest, calendarProvider] = useSelector(
    state => [
      getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
      getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE_REQUEST),
      getCommunityConfigValue(state, CALENDAR_PROVIDER),
    ],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const utils = {
    calendarProvider,
    defaultExcludeFormer: true,
    enableRequestFlow: enabledOneOnOneRequest,
    enableSyncWithCalendar: true,
    excludeFormerMenu: true,
    mainFeatureIsEnabled: enabledOneOnOne,
  };

  return (
    <div className="pt-16">
      <Container centered>
        <div className="py-8">
          <UtilsProvider utils={utils}>
            <OneOnOneHomeAPP />
          </UtilsProvider>
        </div>
      </Container>
    </div>
  );
};

export default OneOnOneHome;
