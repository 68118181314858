import { takeEvery, call, put } from 'redux-saga/effects';

import { getCategorySuccess, getCategoryFail } from './actions';
import { getCategory as getCategoryAPI } from './api';
import { GET_CULTURAL_CATEGORY } from './actionTypes';

function* fetchCategoryWorker({ payload }) {
  try {
    const data = yield call(getCategoryAPI, { userId: payload });
    yield put(getCategorySuccess(data));
  } catch (e) {
    yield put(getCategoryFail(e));
  }
}

export function* fetchCategoryWatcher() {
  yield takeEvery(GET_CULTURAL_CATEGORY, fetchCategoryWorker);
}

export default {
  fetchCategoryWatcher,
};
