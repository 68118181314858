// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import Modal from 'components/Modal';

// @own
import './styles.scss';

const ModalConfirmation = ({
  actions,
  children,
  className,
  messageAccept,
  messageCancel,
  onAccept,
  onCancel,
  onClose,
  title,
  ...rest
}) => (
  <Modal
    className={cn('smu-modal-confirmation', className)}
    onClick={onClose}
    {...rest}
  >
    <div className="smu-modal-confirmation__content">
      <Button
        className="smu-modal-confirmation__close"
        onClick={onClose}
        variant="text"
      >
        <Icon className="smu-modal-confirmation__close-icon" icon="close" />
      </Button>
      <header className="smu-modal-confirmation__header">
        {title && <h4 className="smu-modal-confirmation__title">{title}</h4>}
      </header>
      <div className="smu-modal-confirmation__body">{children}</div>
      <footer className="smu-modal-confirmation__footer">
        {actions?.[0] ? (
          actions?.map((action, key) => (
            <Button
              className="smu-modal-confirmation__footer-action"
              key={`action-${key}`}
              {...action}
            />
          ))
        ) : (
          <>
            <Button
              className="smu-modal-confirmation__footer-action"
              enabledUnification
              onClick={onCancel}
              variant="outline"
            >
              {messageCancel}
            </Button>
            <Button
              className="smu-modal-confirmation__footer-action"
              enabledUnification
              onClick={onAccept}
            >
              {messageAccept}
            </Button>
          </>
        )}
      </footer>
    </div>
  </Modal>
);

ModalConfirmation.propTypes = {
  actions: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  className: PropTypes.string,
  messageAccept: PropTypes.string,
  messageCancel: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default ModalConfirmation;
