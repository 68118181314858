import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';
import Agreement from 'containers/Agreement';
import { injectIntl } from 'react-intl';
import * as actions from './actions';

import messages from './messages';

class EulaAgreement extends Component {
  componentDidMount() {
    const { getEula } = this.props;
    getEula();
  }

  render() {
    const {
      eula,
      intl,
      fetching,
      saveEulaAgreement,
    } = this.props;
    return (
      <Agreement
        agreement={eula}
        checkboxText={intl.formatMessage(messages.checkboxText)}
        eventCategory="EULA"
        fetching={fetching}
        lblCancelBtn={intl.formatMessage(messages.cancel)}
        lblContinueBtn={intl.formatMessage(messages.continue)}
        saveAgreement={saveEulaAgreement}
        title={intl.formatMessage(messages.title)}
      />
    );
  }
}

EulaAgreement.propTypes = {
  eula: PropTypes.string,
  intl: PropTypes.object,
  fetching: PropTypes.bool,
  getEula: PropTypes.func,
  saveEulaAgreement: PropTypes.func,
};

const mapStateToProps = ({ eula }) => ({
  eula: eula.eula,
  feetchingSave: eula.feetchingSave,
  fetching: eula.fetching,
});


const connectedEula = connect(mapStateToProps, actions)(injectIntl(EulaAgreement));

export default RootModal(connectedEula, 'EULA_MODAL');
