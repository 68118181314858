export const trackEvent = ({ category, action, label, ...rest }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'trackEvent',
    eventCategory: category || '',
    eventAction: action || '',
    eventLabel: label || '',
    ...rest,
  });
};

export const trackPageView = ({ path, ...rest }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'pageview',
    page: {
      path,
      ...rest,
    },
  });
};
