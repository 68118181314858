import { takepartApiInstance } from 'utils/requestHelper';
import errorHandler from './utils/errorHandler';

export function getPendingQuestionnaires(orgId, lang) {
  return takepartApiInstance({
    headers: {
      'Accept-Language': lang,
    },
    url: `organization/v2/${orgId}/questionnaires/pending`,
  })
    .then(({ data }) => data.questionnaires)
    .catch(errorHandler);
}

export function apiGetQuestById({ orgId, questionnaireId, lang }) {
  return takepartApiInstance({
    headers: {
      'Accept-Language': lang,
    },
    url: `organization/v2/${orgId}/questionnaires/${questionnaireId}`,
  }).then(({ data }) => data);
}

export function apiPostDemographics({ orgId, questionnaireId, data }) {
  return takepartApiInstance({
    url: `organization/${orgId}/questionnaires/${questionnaireId}/demographics`,
    method: 'post',
    data,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiPutLanguage({ orgId, questionnaireId, language }) {
  return takepartApiInstance({
    url: `organization/${orgId}/questionnaires/${questionnaireId}/language`,
    method: 'put',
    params: {
      language,
    },
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiPostQuestion({
  data,
  lang,
  orgId,
  questionId,
  questionnaireId,
}) {
  return takepartApiInstance({
    data,
    headers: {
      'Accept-Language': lang,
    },
    method: 'post',
    url: `organization/${orgId}/questionnaires/${questionnaireId}/questions/${questionId}`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apoPostComments({
  categoryId,
  comment,
  orgId,
  questionnaireId,
}) {
  return takepartApiInstance({
    url: `organization/${orgId}/questionnaires/${questionnaireId}/questions/${categoryId}/category`,
    method: 'post',
    params: {
      comment,
    },
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
