// @packages
import React, { useContext, useCallback } from 'react';
import Button from 'smu-ui-components/Button';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { closeModal, openModal } from 'smu-app-components/RootModalV2/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// @app
import generalMessages from 'utils/generalMessages';
import { StarContext } from 'containers/OSFeed/OSFeedItem';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { getDay, getSentDay } from 'utils/formatDate';
import { hasStarsToSend } from 'containers/SendStar/helpers';
import { parseDateUTC } from 'utils/parseDate';
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';
import EventCard from '../EventCard';
import EventHeader from '../EventHeader';
import RecognitionContent from '../RecognitionContent';
import messages from '../messages';

function RecognitionEvent({
  closeModal,
  intl: { formatMessage },
  locale,
  openModal,
  remainingStars,
  router,
}) {
  const { data, date, showProfile } = useContext(StarContext);
  const { users } = data;
  const dateFormatted = parseDateUTC(date, locale);
  const dateMoment = getSentDay(dateFormatted, 'utc');

  const getCardText = () => {
    const contentTypes = {
      TODAY: {
        title: formatMessage(messages.ContentTypeTitleTeamRecognition),
        subtitle: formatMessage(messages.ContentTypeSubtitleTeamRecognition),
      },
      YESTERDAY: {
        title: formatMessage(
          messages.ContentTypeTitleOtherTeamRecognition,
          { value: getDay(dateFormatted, locale) },
        ),
        subtitle: formatMessage(messages.ContentTypeSubtitleTeamRecognition),
      },
      ANOTHER: {
        title: formatMessage(
          messages.ContentTypeTitleOtherTeamRecognition,
          { value: getDay(dateFormatted, locale) },
        ),
        subtitle: formatMessage(messages.ContentTypeSubtitleTeamRecognition),
      },
    };
    return contentTypes[dateMoment];
  };

  const { title, subtitle } = getCardText();

  const buttonHandler = () => {
    router.push(buildSendStarModalUrl({
      params: { users },
    }));

    trackEvent({
      category: 'Team Reconition CT',
      action: 'TeamRecognition_SendStars',
    });
  };

  const hiddenButtonHandler = () => {
    const modalMessages = {
      title: formatMessage(messages.ContentTypeModalTitleTeamRecognition),
      paginator: {
        first: formatMessage(generalMessages.generalMessagesFirst),
        last: formatMessage(generalMessages.generalMessagesLast),
      },
    };
    openModal({
      modalType: 'RECOGNITION_TEAM_MODAL',
      modalProps: {
        messages: modalMessages,
        data: users,
        closeModal,
      },
    });
  };

  const handleShowProfile = (user) => {
    trackEvent({
      category: 'Team Recognition Card',
      action: 'Click on user',
    });
    showProfile(user.uid);
  };

  const hasStars = hasStarsToSend(remainingStars);

  const ContentType = useCallback(() => (
    <EventCard className="recognition-event">
      <div className="recognition-event__content">
        <EventHeader
          title={title}
          subtitle={subtitle}
        />
        <RecognitionContent
          data={data}
          onClick={handleShowProfile}
          onHiddenAvatarClick={hiddenButtonHandler}
        />
      </div>
      <div className="recognition-event__button-wrapper">
        <Button
          active
          className="recognition-event__button"
          color="black"
          onClick={buttonHandler}
          primary
          size="medium"
        >
          {formatMessage(messages.ContentTypeButtonTeamRecognition)}
        </Button>
      </div>
    </EventCard>
  ), []);

  return hasStars
    ? <ContentType />
    : null;
}

RecognitionEvent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  intl: PropTypes.object,
  locale: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  remainingStars: PropTypes.object,
  router: PropTypes.object,
};

const mapStateToProps = ({ i18n, starFlow }) => ({
  remainingStars: get(starFlow, 'remainingStars'),
  locale: i18n.locale,
});

const actions = {
  closeModal,
  openModal,
};

export default injectIntl(connect(mapStateToProps, actions)(withRouter(RecognitionEvent)));
