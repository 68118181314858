// @packages
import React from 'react';
import Skeleton from 'smu-ui-components/Skeleton';

// @own
import './styles.scss';

function SkeletonTopStar({
  key,
}) {
  return (
    <div className="skeleton-top-star" key={key}>
      <Skeleton
        animation="wave"
        className="skeleton-top-star__avatar-media"
        height={40}
        variant="circle"
        width={40}
      />
      <div className="skeleton-top-star__avatar-text-wrapper">
        <Skeleton
          animation="wave"
          className="skeleton-top-star__avatar-name"
          height={14}
          width={100}
        />
        <Skeleton
          className="skeleton-top-star__avatar-job"
          animation="wave"
          height={12}
          width={50}
        />
      </div>
      <Skeleton
        animation="wave"
        className="skeleton-top-star__icon"
        height={36}
        width={36}
      />
    </div>
  );
}

export default SkeletonTopStar;
