import { call, put, takeLatest } from 'redux-saga/effects';
import { closeModal } from 'smu-app-components/RootModalV2/actions';

import {
  SAVE_TCANDPP,
  SAVE_TCANDPP_SUCCESS,
  SAVE_TCANDPP_ERROR,
} from './actionTypes';

import { saveTCAndPPAgreement } from './api';

function* saveTCAndPPWorker() {
  try {
    const data = yield call(saveTCAndPPAgreement);
    yield put({ type: SAVE_TCANDPP_SUCCESS, payload: data.result });
    yield put(closeModal());
  } catch (e) {
    yield put({ type: SAVE_TCANDPP_ERROR, payload: e });
  }
}

export default function* saveTCAndPPWatcher() {
  yield takeLatest(SAVE_TCANDPP, saveTCAndPPWorker);
}
