// @packages
import { stringify } from 'querystring';
import { getToken } from 'smu-utils/storage';
import xss from 'xss';

// @app
import { errorHandler } from 'utils/errorHandler';
import { apiInstance } from 'utils/requestHelper';

export function apiAddLike({ starId }) {
  return apiInstance({
    url: `/v2/stellar/stars/${starId}/likes`,
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiRemoveLike({ likeId }) {
  return apiInstance({
    url: `/v2/stellar/likes/${likeId}/remove`,
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiGetStarById({ userId, starId }) {
  return apiInstance({
    url: `/v2/stellar/feed/${starId}/user/${userId}`,
    method: 'get',
    params: {
      filterBy: 'RECEIVED',
      firstStarId: starId,
      orderBy: 'DESC',
      page: 0,
      size: 1,
    },
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiAddComment({ comment, starId }) {
  return apiInstance({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    data: stringify({
      comments: xss(comment),
    }),
    method: 'post',
    url: `/v2/stellar/stars/${starId}/comments`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiLoadImage({ imageCode, starId }) {
  return apiInstance({
    url: `/v2/stellar/stars/${starId}/image/${imageCode}`,
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiUploadImage({ file }) {
  const formData = new FormData();
  formData.append('token', getToken());
  formData.append('file', file);
  formData.append('fileName', file.name);

  return apiInstance({
    url: '/v2/image/insert/image',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export async function apiAddCommentImage({ file, starId }) {
  try {
    const uploadImage = await apiUploadImage({ file });
    const loadImage = await apiLoadImage({
      imageCode: uploadImage?.result?.code,
      starId,
    });
    return {
      starId,
      ...loadImage.result,
    };
  } catch (error) {
    return errorHandler(error);
  }
}
