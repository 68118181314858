// @packages
import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import flattenDeep from 'lodash/flattenDeep';
import isUndefined from 'lodash/isUndefined';
import join from 'lodash/join';
import reverse from 'lodash/reverse';
import toArray from 'lodash/toArray';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Panel from 'smu-ui-components/Panel';
// @app
import intersperse from 'utils/intersperse';
import SkeletonOverallActivity from 'components/Skeletons/OverallActivity';

// @own
import { getCommunityCounters } from './actions';
import './styles.scss';
import messages from './messages';

class CommunityCounters extends React.Component {
  componentDidMount() {
    const {
      getCommunityCounters, starsSent, activeUsers, fetching,
    } = this.props;
    if (isUndefined(starsSent) || isUndefined(activeUsers) || !fetching) {
      getCommunityCounters();
    }
  }

  render() {
    const {
      starsSent, activeUsers, fetching, error, intl,
    } = this.props;

    const hasOverallActivity = activeUsers && starsSent;

    if (fetching) {
      return <SkeletonOverallActivity />;
    }

    return (
      <Panel title={intl.formatMessage(messages.overallActivity)} className="community-counters">
        {hasOverallActivity && (
          <>
            <div className="community-counters__line">
              <p className="community-counters__description">
                {intl.formatMessage(messages.activeUsers)}
              </p>
              <p className="community-counters__stat">{activeUsers}</p>
            </div>
            <div className="community-counters__line">
              <p className="community-counters__description">
                {intl.formatMessage(messages.sentStars)}
              </p>
              <p className="community-counters__stat">{starsSent}</p>
            </div>
          </>
        )}
        {error && <span>Error retrieving trends</span>}
      </Panel>
    );
  }
}

CommunityCounters.propTypes = {
  starsSent: PropTypes.string,
  activeUsers: PropTypes.string,
  fetching: PropTypes.bool,
  error: PropTypes.string.isRequired,
  getCommunityCounters: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

const mapStateToProps = ({ communityCounters }) => {
  const { starsSent, activeUsers } = communityCounters;
  const withDots = string =>
    join(reverse(flattenDeep(intersperse(chunk(reverse(toArray(string)), 3), [['.']]))), '');
  return {
    starsSent: starsSent ? withDots(starsSent) : communityCounters.starsSent,
    activeUsers: activeUsers ? withDots(activeUsers) : communityCounters.activeUsers,
    fetching: communityCounters.fetching,
    error: communityCounters.error,
  };
};

export default connect(mapStateToProps, { getCommunityCounters })(injectIntl(CommunityCounters));
