export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const CLEAN_PROFILE = 'CLEAN_PROFILE';

export const REQUEST_RECEIVED_STARS = 'REQUEST_RECEIVED_STARS';
export const REQUEST_RECEIVED_STARS_SUCCESS = 'REQUEST_RECEIVED_STARS_SUCCESS';
export const REQUEST_RECEIVED_STARS_FAILED = 'REQUEST_RECEIVED_STARS_FAILED';

export const REQUEST_SENT_STARS = 'REQUEST_SENT_STARS';
export const REQUEST_SENT_STARS_SUCCESS = 'REQUEST_SENT_STARS_SUCCESS';
export const REQUEST_SENT_STARS_FAILED = 'REQUEST_SENT_STARS_FAILED';

export const REQUEST_EARNED_BADGES = 'REQUEST_EARNED_BADGES';
export const REQUEST_EARNED_BADGES_SUCCESS = 'REQUEST_EARNED_BADGES_SUCCESS';
export const REQUEST_EARNED_BADGES_FAILED = 'REQUEST_EARNED_BADGES_FAILED';

export const REQUEST_RECEIVED_STARS_DETAIL = 'REQUEST_RECEIVED_STARS_DETAIL';
export const REQUEST_RECEIVED_STARS_DETAIL_SUCCESS = 'REQUEST_RECEIVED_STARS_DETAIL_SUCCESS';
export const REQUEST_RECEIVED_STARS_DETAIL_SUCCESS_FAILED = 'REQUEST_RECEIVED_STARS_DETAIL_SUCCESS_FAILED';

export const REQUEST_USERS_INTERACTION = 'REQUEST_USERS_INTERACTION';
export const REQUEST_USERS_INTERACTION_SUCCESS = 'REQUEST_USERS_INTERACTION_SUCCESS';
export const REQUEST_USERS_INTERACTION_FAIL = 'REQUEST_USERS_INTERACTION_FAIL';

export const GET_STARS_SUMMARY = 'GET_STARS_SUMMARY';
export const GET_STARS_SUMMARY_SUCCESS = 'GET_STARS_SUMMARY_SUCCESS';
export const GET_STARS_SUMMARY_FAIL = 'GET_STARS_SUMMARY_FAIL';
