export const CHOOSE_LANGUAGE_EN = 'CHOOSE LANGUAGE';
export const CHOOSE_LANGUAGE_ES = 'ELIGE EL IDIOMA';
export const CLOSED = 'CLOSED';
export const DRAFT = 'DRAFT';
export const EN = 'en';
export const ENGLISH = 'ENGLISH';
export const ES = 'es';
export const ESPANOL = 'ESPAÑOL';
export const OPEN = 'OPEN';
export const OPEN_QUESTIONNAIRE_PANEL = 'OPEN_QUESTIONNAIRE_PANEL';
export const QUESTIONNAIRE_SHORTNAME_LENGTH = 3;
export const QUESTIONNARE_LENGTH_PER_SLIDE = 3;
export const RETRY = 'RETRY';
export const SPANISH = 'SPANISH';
export const LANGUAGE_NONE = 'none';
