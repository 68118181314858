// @packages
import { useEffect } from 'react';
import { connect } from 'react-redux';

// @app
import { selectSessionUserId } from 'containers/Authorization/selectors';
import usePushNotifications from 'hooks/usePushNotifications';
import { appToken } from '../../configs/firebase';

const PushNotifications = ({ userId }) => {
  const { onSubscribe, onUnsubscribe } = usePushNotifications();

  useEffect(() => {
    onSubscribe(appToken, userId);
    return () => onUnsubscribe(appToken, userId);
  }, []);

  return null;
};

const mapStateToProps = (state) => ({
  userId: selectSessionUserId(state),
});

export default connect(mapStateToProps, null)(PushNotifications);
