// @app
import { errorHandler } from 'utils/errorHandler';
import { apiInstance } from 'utils/requestHelper';
import { times } from 'api';

export function getStarsSummary({ params }) {
  return apiInstance({
    url: '/v2/stellar/stars/summary',
    data: {},
    params: {
      ...times(),
      ...params,
    },
  })
    .then((data) => data)
    .catch(errorHandler);
}
