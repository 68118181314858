export const VALUE_START_TIME = 'startTime';
export const VALUE_END_TIME = 'endTime';
export const VALUE_PREFERENCES = 'preferences';
export const VALUE_DAYS = 'days';
export const CHIPS_AVAILABLE_DAYS = [
  { children: 'Monday', day: 1 },
  { children: 'Tuesday', day: 2 },
  { children: 'Wednesday', day: 3 },
  { children: 'Thursday', day: 4 },
  { children: 'Friday', day: 5 },
];
