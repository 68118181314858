// @packages
import React, { useContext, useEffect, useRef } from 'react';
import CardAnniversariesTemplate from 'smu-ui-components/Cards/templates/Anniversaries';
import CardTitle from 'smu-ui-components/Cards/components/Title';
import CardDescription from 'smu-ui-components/Cards/components/Description';
import UserCarousel from 'smu-ui-components/UserCarousel';
import CardBirthdaysTemplate from 'smu-ui-components/Cards/templates/Birthdays';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import anniversariesIcon from 'assets/icons/anniversary-icon.svg';
import birthdayIcon from 'assets/icons/birthday-icon.svg';
import { StarContext } from 'containers/OSFeed/OSFeedItem';
import { getDay, getSentDay } from 'utils/formatDate';
import { isInViewport } from 'utils/viewport';
import { parseDateUTC as parseDate } from 'utils/parseDate';

// @own
import './styles.scss';
import messages from './messages';
import { selecti18nLocale } from '../../selectors';
import { getUserCarouselProps } from './helpers';

function ContentType({ intl: { formatMessage }, locale }) {
  const { data, date, type } = useContext(StarContext);
  const containerRef = useRef(null);
  const dateFormatted = parseDate(date, locale);
  const dateMoment = getSentDay(dateFormatted, 'utc');

  function getAnotherValue() {
    const anotherValue = {
      BIRTHDAYS: formatMessage(messages.ContentTypeTitleCustomBirthdays, {
        value: getDay(dateFormatted, locale),
      }),
      ANNIVERSARIES: formatMessage(
        messages.ContentTypeTitleCustomAnniversaries,
        { value: getDay(dateFormatted, locale) },
      ),
    };
    return anotherValue[type];
  }

  function getTitle() {
    const contentTypes = {
      TODAY: {
        BIRTHDAYS: formatMessage(messages.ContentTypeTitleTodayBirthdays),
        ANNIVERSARIES: formatMessage(
          messages.ContentTypeTitleTodayAnniversaries,
        ),
      },
      YESTERDAY: {
        BIRTHDAYS: formatMessage(messages.ContentTypeTitleYesterdayBirthdays),
        ANNIVERSARIES: formatMessage(
          messages.ContentTypeTitleYesterdayAnniversaries,
        ),
      },
      ANOTHER: {
        BIRTHDAYS: getAnotherValue(),
        ANNIVERSARIES: getAnotherValue(),
      },
    };

    return contentTypes[dateMoment][type];
  }

  const getConfig = (type) =>
    ({
      BIRTHDAYS: {
        icon: birthdayIcon,
        title: getTitle(),
        subtitle: formatMessage(messages.ContentTypeSubtitleBirthdays),
      },
      ANNIVERSARIES: {
        icon: anniversariesIcon,
        title: getTitle(),
        subtitle: formatMessage(messages.ContentTypeSubtitleAnniversaries),
      },
    }[type]);

  const config = getConfig(type);

  function analyticsImpression(type, action) {
    const date = moment().format('MM-DD-YYYY');
    const types = {
      BIRTHDAYS: 'birthday',
      ANNIVERSARIES: 'anniversary',
    };
    const actions = {
      impression: `impression ${date}`,
    };

    trackEvent({
      action: types[type],
      category: 'home_recent',
      label: actions[action],
    });
  }

  useEffect(() => {
    let isVisible = false;
    function validImpression() {
      if (containerRef?.current) {
        if (!isVisible && isInViewport(containerRef?.current)) {
          isVisible = true;
          analyticsImpression(type, 'impression');
        } else if (isVisible && !isInViewport(containerRef?.current)) {
          isVisible = false;
        }
      }
    }
    document.addEventListener('scroll', validImpression, { passive: true });
    return () => document.removeEventListener('scroll', validImpression);
  }, []);

  const components = {
    BIRTHDAYS: CardBirthdaysTemplate,
    ANNIVERSARIES: CardAnniversariesTemplate,
  };

  const Component = components[type];

  if (!Component) {
    return null;
  }

  return (
    <Component
      className="shadow-base"
      title={<CardTitle>{config?.title}</CardTitle>}
      description={<CardDescription>{config?.subtitle}</CardDescription>}
      users={
        <UserCarousel
          {...getUserCarouselProps(data?.users, type, formatMessage)}
        />
      }
    />
  );
}

ContentType.propTypes = {
  intl: PropTypes.object.isRequired,
  locale: PropTypes.string,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  locale: selecti18nLocale(state),
});

export default withRouter(connect(mapStateToProps)(injectIntl(ContentType)));
