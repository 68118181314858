import cloneDeep from 'lodash/cloneDeep';

import {
  ACTION_ITEMS_ADD,
  ACTION_ITEMS_REMOVE,
  ACTION_ITEMS_UPDATE,
  ACTION_ITEMS_UPDATE_MULTIPLE,
} from './actionTypes';

const initialState = {};

const merge = (state, payload) => {
  const id = payload?.id;
  const prevState = cloneDeep(state);
  const prevData = cloneDeep(state?.[id]);
  const data = cloneDeep(payload?.data);

  return {
    ...prevState,
    [id]: {
      ...prevData,
      ...data,
    },
  };
};

const mergeMultiple = (state, payload) => {
  const prevState = cloneDeep(state);
  const newData = payload?.data?.reduce((acc, cur) => {
    if (cur?.id) {
      acc[cur.id] = {
        ...prevState?.[cur.id],
        ...cur?.data,
      };
    }
    return acc;
  }, {});

  return {
    ...prevState,
    ...newData,
  };
};

const remove = (state, payload) => {
  const cloneState = cloneDeep(state);
  delete cloneState[payload?.id];

  return {
    ...state,
    ...cloneState,
  };
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_ITEMS_ADD:
      return {
        ...merge(state, payload),
      };
    case ACTION_ITEMS_REMOVE:
      return {
        ...remove(state, payload),
      };
    case ACTION_ITEMS_UPDATE:
      return {
        ...state,
        ...merge(state, payload),
      };
    case ACTION_ITEMS_UPDATE_MULTIPLE:
      return {
        ...state,
        ...mergeMultiple(state, payload),
      };
    default:
      return state;
  }
}
