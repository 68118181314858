import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { includes } from 'lodash';

import { fixedFilters } from 'containers/Filters/sagas';
import Select from 'components/Select';

import messages from './messages';

const FilterSelect = props => (
  <Select {...props} />
);

FilterSelect.propTypes = {
  field: PropTypes.string.isRequired,
};

const mapStateToProps = (state, {
  avoidTranslation,
  field,
  intl,
  placeholder,
}) => {
  const { formatMessage } = intl;
  // Note: periodTime gets its translation from the api call.
  const shouldTranslate = includes(fixedFilters, field) && field !== 'periodTime' && !avoidTranslation;
  return {
    placeholder: shouldTranslate
      ? formatMessage(messages[field])
      : placeholder,
  };
};


export default injectIntl(connect(mapStateToProps)(FilterSelect));
