import { apiCollaborationInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function apiGetChilloutConfig() {
  return apiCollaborationInstance({
    url: 'v1/chillout/preferences',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiPutChilloutConfig(data) {
  return apiCollaborationInstance({
    data,
    method: 'put',
    url: 'v1/chillout/preferences',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
