export const ADD_CONGRATULATIONS_COMMENT = 'addCongratulationsComment';
export const GET_CONGRATULATIONS = 'getCongratulations';
export const TYPE_ANNIVERSARY = 'anniversary';
export const TYPE_BIRTHDAY = 'birthday';
export const TYPE_ANNIVERSARIES = 'anniversaries';
export const TYPE_BIRTHDAYS = 'birthdays';
export const TYPES = [TYPE_BIRTHDAYS, TYPE_ANNIVERSARIES];

export const SOURCE_TYPES = {
  [TYPE_ANNIVERSARY]: 'INTERACTION_ANNIVERSARY',
  [TYPE_BIRTHDAY]: 'INTERACTION_BIRTHDAY',
};

export const VALID_TYPES = {
  [TYPE_ANNIVERSARIES]: TYPE_ANNIVERSARY,
  [TYPE_BIRTHDAYS]: TYPE_BIRTHDAY,
};

export const CONFIG = {
  [TYPE_BIRTHDAY]: {
    color: 'rose',
    icon: 'cake',
    type: TYPE_BIRTHDAY,
  },
  [TYPE_ANNIVERSARY]: {
    color: 'violet',
    icon: 'anniversary',
    type: TYPE_ANNIVERSARY,
  },
};

export const EMPTY_IMAGES = {
  [TYPE_BIRTHDAY]: 'birthday',
  [TYPE_ANNIVERSARY]: 'celebration',
};
