import {
  CREATE_UNSUBSCRIBE_USER,
  CREATE_UNSUBSCRIBE_USER_FAIL,
  CREATE_UNSUBSCRIBE_USER_SUCCESS,
  GET_UNSUBSCRIBE_DATA,
  GET_UNSUBSCRIBE_DATA_FAIL,
  GET_UNSUBSCRIBE_DATA_SUCCESS,
} from './actionTypes';

const initialState = {
  error: false,
  fetching: true,
  messages: undefined,
  unsubscribed: false,
};

export default function reducer(state = initialState, { type, messages }) {
  switch (type) {
    case CREATE_UNSUBSCRIBE_USER:
      return {
        ...state,
        fetching: true,
      };
    case CREATE_UNSUBSCRIBE_USER_FAIL:
      return {
        ...state,
        error: true,
        fetching: false,
      };
    case CREATE_UNSUBSCRIBE_USER_SUCCESS:
      return {
        ...state,
        fetching: false,
        unsubscribed: true,
      };
    case GET_UNSUBSCRIBE_DATA:
      return {
        ...state,
        fetching: true,
      };
    case GET_UNSUBSCRIBE_DATA_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case GET_UNSUBSCRIBE_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        messages,
      };
    default:
      return state;
  }
}
