export const CALENDAR_PROVIDER = 'calendar.provider';
export const CREATE_CHILLOUT_VIRTUAL = 'createChillOutVirtual';
export const DISABLED_FEED_ORDERBY_TOGGLE = 'community.feed.orderby.connection.toggle.disabled';
export const ENABLED_ANNIVERSARIES = 'feature.anniversaries.enabled';
export const ENABLED_BADGES = 'feature.badges.enabled';
export const ENABLED_BADGES_ASSIGNED_BY_COLLABORATOR = 'feature.badges.assignedByCollaborator';
export const ENABLED_BADGES_ASSIGNED_BY_LEADER = 'feature.badges.assignedByLeader';
export const ENABLED_BEHEALTHY = 'feature.behealthy.enabled';
export const ENABLED_BEKIND = 'feature.beKindSurvey.enabled';
export const ENABLED_BEKIND_RESULTS = 'feature.beKindSurvey.enabled';
export const ENABLED_BEKIND_RESULTS_HOME = 'feature.beKindSurveyHome.enabled';
export const ENABLED_BETHERE_FEED = 'enabledBeThereFeed';
export const ENABLED_BETTERME = 'module.performance.enabled';
export const ENABLED_BIRTHDAYS = 'feature.birthdays.enabled';
export const ENABLED_COMMUNICATIONS = 'product.os.configuration.hasCommunication.enabled';
export const ENABLED_CONGRATULATIONS = 'product.os.congratulations.configuration.enabled';
export const ENABLED_DISCOVERY_PEOPLE = 'enabledDiscoveryPeople';
export const ENABLED_DISENGAGEMENT_INDICATOR = 'feature.disengagementIndicator.enabled';
export const ENABLED_FEED_FILTERS = 'feature.feed.filters.enabled';
export const ENABLED_INSIGHTS = 'abmWorkflowsInsights';
export const ENABLED_INTERCOM = 'feature.intercom.enabled';
export const ENABLED_MYTEAM_DASHBOARD = 'module.myTeam.enabled';
export const ENABLED_MYTEAM_INSIGHTS = 'feature.insights.enabled';
export const ENABLED_NOTIFICATIONS_SETTINGS = 'enabledNotificationsSettings';
export const ENABLED_ONE_ON_ONE = 'module.oneOnOne.enabled';
export const ENABLED_ONE_ON_ONE_REQUEST = 'module.oneOnOne.request.enabled';
export const ENABLED_ONE_ON_ONE_TP_AI_SUGGESTIONS = 'feature.talkingPointsSuggestions.enabled';
export const ENABLED_PENDING_TASKS = 'feature.pendingTasks.enabled';
export const ENABLED_PENDING_TASKS_HOME = 'BME_SHOW_PENDING_TASK_HOME';
export const ENABLED_PUSH_NOTIFICATIONS = 'enabledPushNotifications';
export const ENABLED_QUICK_ACCESS = 'product.os.configuration.hasQuickAccess.enabled';
export const ENABLED_REWARDS = 'module.rewards.enabled';
export const ENABLED_SHARE_BADGE = 'feature.shareBadges.enabled';
export const ENABLED_SHARE_DNA = 'product.smu.share.dna.configuration.enabled';
export const ENABLED_SHARE_STAR = 'feature.smuShareStars.enabled';
export const ENABLED_STARMEUP = 'module.engagement.enabled';
export const ENABLED_STAR_SUGGESTION_TEXT = 'feature.smu.starSuggestionText';
export const ENABLED_SURVEYS_ONLY = 'product.survey.surveyOnly';
export const ENABLED_TAKEPART_SURVEY = 'enabledTakePartSurvey';
export const ENABLED_TRENDING_HASHTAGS = 'feature.smu.trendingTopics';
export const ENABLED_USEFUL_LINKS = 'product.os.configuration.hasUsefulLinks.enabled';
export const ENABLED_YIR = 'feature.yearInReview.enabled';
export const FIRST_LOGIN_MEMBER = 'firstLoginMember';
export const MAIN_MENU = 'MAIN_MENU';
export const NEW_NOTIFICATION_SETTINGS = 'newNotificationSettings';
export const ONBOARDING_ENABLED = 'product.smu.onboarding.configuration.enabled';
export const STAR_SELECTOR_VERSION = 'starmeup.starSelector.version';
export const SURVEYS_THRESHOLD_NUMBER = 'SURVEY_RESULTS_THRESHOLD';
export const THEME_NAME = 'feature.theme.name';

export const CONFIGURATIONS = [
  CALENDAR_PROVIDER,
  CREATE_CHILLOUT_VIRTUAL,
  DISABLED_FEED_ORDERBY_TOGGLE,
  ENABLED_ANNIVERSARIES,
  ENABLED_BADGES,
  ENABLED_BADGES_ASSIGNED_BY_COLLABORATOR,
  ENABLED_BADGES_ASSIGNED_BY_LEADER,
  ENABLED_BEHEALTHY,
  ENABLED_BEKIND,
  ENABLED_BEKIND_RESULTS,
  ENABLED_BEKIND_RESULTS_HOME,
  ENABLED_BETHERE_FEED,
  ENABLED_BETTERME,
  ENABLED_BIRTHDAYS,
  ENABLED_COMMUNICATIONS,
  ENABLED_CONGRATULATIONS,
  ENABLED_DISCOVERY_PEOPLE,
  ENABLED_DISENGAGEMENT_INDICATOR,
  ENABLED_FEED_FILTERS,
  ENABLED_INSIGHTS,
  ENABLED_INTERCOM,
  ENABLED_MYTEAM_DASHBOARD,
  ENABLED_MYTEAM_INSIGHTS,
  ENABLED_NOTIFICATIONS_SETTINGS,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_REQUEST,
  ENABLED_ONE_ON_ONE_TP_AI_SUGGESTIONS,
  ENABLED_PENDING_TASKS,
  ENABLED_PENDING_TASKS_HOME,
  ENABLED_PUSH_NOTIFICATIONS,
  ENABLED_QUICK_ACCESS,
  ENABLED_REWARDS,
  ENABLED_SHARE_BADGE,
  ENABLED_SHARE_DNA,
  ENABLED_SHARE_STAR,
  ENABLED_STARMEUP,
  ENABLED_STAR_SUGGESTION_TEXT,
  ENABLED_SURVEYS_ONLY,
  ENABLED_TAKEPART_SURVEY,
  ENABLED_TRENDING_HASHTAGS,
  ENABLED_USEFUL_LINKS,
  ENABLED_YIR,
  FIRST_LOGIN_MEMBER,
  MAIN_MENU,
  NEW_NOTIFICATION_SETTINGS,
  ONBOARDING_ENABLED,
  STAR_SELECTOR_VERSION,
  SURVEYS_THRESHOLD_NUMBER,
  THEME_NAME,
];
