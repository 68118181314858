// @packages
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { FEEDBACK_CONFIRMATION } from 'smu-app-components/RootModalV2/modalTypes';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { openFullscreenModal } from 'smu-app-components/FullScreenModalV2/actions';
import { openModal } from 'smu-app-components/RootModalV2/actions';

// @app
import addToast from 'utils/defaultToastAdd';
import messages from 'containers/CoreValuesPanel/messages';
import { SEND_STAR_FLOW_MAIN } from 'containers/SendStarModal';
import { actionItemsUpdate } from 'services/items/actions';
import { actions as leaderboardActions } from 'containers/Leaderboard/actions';
import { actions as mainSearchActions } from 'containers/SendStarFlow/MainSearch';
import { addLiftStar } from 'containers/withFeed/actions';
import { emailRegex, HTTP_SERVER_ERROR } from 'utils/constants';
import { entitiesAmount } from 'containers/SendStar/helpers';
import { addOsFeedLiftStar } from 'containers/OSFeed/actions';
import { getSentStars } from 'containers/withProfile/actions';
import { helperGetEntities, helperGetMainPhoto, helperGetStar } from 'utils/star';
import { selectSessionUserId, selectSessionStars } from 'containers/Authorization/selectors';
import { trackEvent } from 'utils/gtm';

// @own
import {
  selectHasStarsToSend,
  selectRemainingStarsSuccess,
  selectStarFlowQuery,
} from './selectors';
import {
  GET_REMAINING_STARS,
  LIFT_STAR,
  LIFT_STAR_FAIL,
  LIFT_STAR_SUCCESS,
  STAR_FLOW_EDIT,
  STAR_FLOW_ERROR,
  STAR_FLOW_GET_MENTION,
  STAR_FLOW_GET_MENTION_ERROR,
  STAR_FLOW_GET_MENTION_SUCCESS,
  STAR_FLOW_GET_STAR_BY_ID,
  STAR_FLOW_INITIAL,
  STAR_FLOW_SEND,
  STAR_FLOW_SENT_WAITING,
  STAR_FLOW_UPLOAD_IMAGE,
  STAR_FLOW_UPLOAD_IMAGE_FAILED,
  STAR_FLOW_UPLOAD_IMAGE_SUCCESS,
} from './actionTypes';
import {
  actionGetStarById,
  actionGetStarByIdFail,
  actionGetStarByIdSuccess,
  addEntity,
  addLiftStarEntity,
  getRemainingStars,
  getRemainingStarsFail,
  getRemainingStarsSuccess,
  sendStarError,
  sendStarServerError,
  sendStarSuccess,
  setComment,
} from './actions';
import {
  apiGetStarById,
  assignImage,
  editStar,
  getRemainingStars as getRemainingStarsAPI,
  liftStar,
  searchEntities,
  sendKudos,
  sendMultipleStar,
  sendStars,
  uploadImage,
} from './api';

const { getLeaderboard } = leaderboardActions;

function* editStarWorker({ payload }) {
  try {
    const {
      comment,
      editId,
      imageCode,
      onSuccess,
      star,
    } = payload;
    const idworldCupCoreValueId = yield select(state =>
      state.session.communityConfigurations.worldCupCoreValueId);
    const showConfetti = idworldCupCoreValueId === star.id;
    const response = yield call(editStar, { comment, editId, imageCode });
    const { errorMessage, message } = find(response, response.errorMessage);
    if (errorMessage) {
      yield put(sendStarError(message));
    } else {
      yield put(actionItemsUpdate(Number(editId), response?.result));
      yield put(sendStarSuccess(showConfetti));
      yield put(mainSearchActions.cleanUserList());
      if (onSuccess) yield onSuccess();
    }
  } catch (e) {
    yield put(sendStarError(e));
  }
}

export function* editStarWatcher() {
  yield takeEvery(STAR_FLOW_EDIT, editStarWorker);
}

function* getMentionedWorker({ payload }) {
  try {
    const data = yield call(searchEntities, payload);
    yield put({ type: STAR_FLOW_GET_MENTION_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: STAR_FLOW_GET_MENTION_ERROR, payload: e });
  }
}

export function* getMentionedWatcher() {
  yield takeEvery(STAR_FLOW_GET_MENTION, getMentionedWorker);
}

function* getRemainingStarsWorker() {
  try {
    const data = yield call(getRemainingStarsAPI);
    yield put(getRemainingStarsSuccess(data));
  } catch (e) {
    yield put(getRemainingStarsFail(e));
  }
}

export function* getRemainingStarsWatcher() {
  yield takeEvery(GET_REMAINING_STARS, getRemainingStarsWorker);
}

function* kudosByEmailsWorker({ payload }) {
  const { list } = payload;
  const { validUsers, invalidUsers } = list;
  if (validUsers && validUsers.length) {
    const users = validUsers.map(user => ({
      name: `${user.firstName} ${user.lastName}`,
      ...user,
    }));
    yield put({ type: '@@star_flow/SEARCH_UNFOCUS' });
    yield all(users.map(user => put(addEntity(user))));
  } else if (!list.length) {
    yield put({ type: '@@star_flow/SEARCH_FOCUS' });
  }
  if (invalidUsers && invalidUsers.length) {
    trackEvent({
      category: 'Give a Star',
      label: 'Kudos Email',
      action: 'Paste Invalid Emails',
    });
  }
}

export function* kudosByEmailsWatcher() {
  yield takeEvery('@@star_flow/FETCH_USER_LIST', kudosByEmailsWorker);
}

function* liftStarWorker({ payload }) {
  try {
    const { starId } = payload;
    const data = yield call(liftStar, starId);
    const { result } = data;
    yield put(addLiftStarEntity(result.id));
    yield put({
      type: LIFT_STAR_SUCCESS,
      payload: { toUser: result.to },
    });
    yield put(openModal({ modalType: FEEDBACK_CONFIRMATION }));
    yield put(addLiftStar({
      id: starId,
      result: result.comments.find(comment => comment.type === 'LIFT_STAR'),
    }));
    yield put(addOsFeedLiftStar({
      id: starId,
      result: result.comments.find(comment => comment.type === 'LIFT_STAR'),
    }));
    yield put(actionItemsUpdate(Number(starId), result));
    yield put(getRemainingStars());
  } catch (error) {
    yield put({
      type: LIFT_STAR_FAIL,
      error,
    });
  }
}

export function* liftStarWatcher() {
  yield takeEvery(LIFT_STAR, liftStarWorker);
}

function* searchFocusWorker() {
  const query = yield select(selectStarFlowQuery);
  if (query.match(emailRegex)) {
    yield put({ type: '@@star_flow/SEARCH_UNFOCUS' });
  }
}

export function* searchFocusWatcher() {
  yield takeEvery('@@star_flow/SEARCH_FOCUS', searchFocusWorker);
}

function* sendStarErrorWorker({ payload = undefined }) {
  if (payload) {
    yield put(addToast({
      message: payload,
      timeout: 15000,
    }));
  }
}

export function* sendStarErrorWatcher() {
  yield takeEvery(sendStarError().type, sendStarErrorWorker);
}

function* sendStarWorker({ payload }) {
  try {
    const {
      comment,
      entities,
      entitiesCC,
      leaderboardFields,
      onSuccess,
      star,
    } = payload;
    const API = star.isKudos ?
      sendKudos :
      (entitiesAmount(entities) >= 2 ? sendMultipleStar : sendStars);
    let CCUsers = [];
    if (!star.isKudos && entitiesCC && entitiesCC.length) {
      CCUsers = map(entitiesCC, 'id');
    }
    const starFlow = yield select(state => state.starFlow);
    const isMultipleStar = !star.isKudos && entitiesAmount(entities) >= 2;
    const hasImage = starFlow && starFlow.image && starFlow.image.code;
    const pathname = yield select(state => state.routing.locationBeforeTransitions.pathname);
    const idworldCupCoreValueId = yield select(state =>
      state.session.communityConfigurations.worldCupCoreValueId);
    const showConfetti = idworldCupCoreValueId === star.id;
    const showSendStarWidget = yield select(state => state.feed.showSendStarWidget);
    const starForMyself = yield select(state => state.feed.starForMyself);
    let trackEventJson = {
      category: 'Give a Star',
      label: 'Send Star',
    };
    if (showSendStarWidget) {
      trackEventJson.action = starForMyself ? 'Received Star Detail' : 'Received Star Detail Other Users';
    } else {
      trackEventJson.action = pathname === '/home' ? 'Home' : 'Profile';
    }
    trackEvent(trackEventJson);

    const serviceParams = {
      entities,
      comment,
      star,
    };

    if (isMultipleStar && hasImage) {
      serviceParams.imageCode = starFlow.image.code;
    }

    if (!isMultipleStar) {
      serviceParams.CCUsers = CCUsers;
    }

    const response = yield call(API, serviceParams);
    yield call(delay, 3000);

    if (isMultipleStar) {
      const { result, status } = response || {};
      if (status === 'FAIL') {
        const { errorCode, message, result = {} } = response;
        switch (errorCode) {
          case 500:
            yield put(sendStarServerError(errorCode));
            yield put({ type: STAR_FLOW_ERROR });
            break;
          default:
            if (isEmpty(result)) {
              yield put(sendStarError(message));
            } else {
              yield put(openModal({
                modalProps: {
                  users: result,
                },
                modalType: 'SENT_STAR_ERROR',
              }));
              yield put({ type: STAR_FLOW_ERROR });
            }
            break;
        }
      } else {
        yield put({ type: STAR_FLOW_SENT_WAITING });
        yield put(getLeaderboard({
          fields: {},
          updateLeaderboard: true,
          persistedFields: leaderboardFields,
        }));
        yield put(getSentStars());
        yield put(setComment(comment));
        yield call(delay, 1500);
        yield put(sendStarSuccess(showConfetti));
        yield put(getRemainingStars());
        yield put(mainSearchActions.cleanUserList());
        if (onSuccess) yield onSuccess(result);
      }
    } else {
      // KUDOS OR SINGLE STAR
      const errorResponse = find(response, r => r.errorMessage) || {};
      if (errorResponse.errorMessage) {
        yield put(sendStarError(errorResponse.message));
      } else {
        // eslint-disable-next-line no-unused-vars
        let imageData = null;
        if (hasImage) {
          const { result: imageResult } =
            yield call(assignImage, {
              imageCode: starFlow.image.code,
              starId: response[0].result.id,
            });
          imageData = imageResult;
        }
        yield put({ type: STAR_FLOW_SENT_WAITING });
        yield put(getLeaderboard({
          fields: {},
          updateLeaderboard: true,
          persistedFields: leaderboardFields,
        }));
        yield put(getSentStars());
        yield put(setComment(comment));
        yield call(delay, 1500);
        yield put(sendStarSuccess(showConfetti));
        yield put(getRemainingStars());
        yield put(mainSearchActions.cleanUserList());
        if (onSuccess) yield onSuccess(response?.[0]?.result);
      }
    }
  } catch (e) {
    if (e.message === HTTP_SERVER_ERROR) {
      yield put(sendStarServerError(500));
    } else {
      yield put(sendStarError(e));
    }
  }
}

export function* sendStarWatcher() {
  yield takeEvery(STAR_FLOW_SEND, sendStarWorker);
}

function* setInitialWorker({ payload }) {
  const i18n = yield select(state => state.i18n.messages);
  const hasStarsToSend = yield select(selectHasStarsToSend);
  const remainingSuccess = yield select(selectRemainingStarsSuccess);
  const showError = !payload?.editId && remainingSuccess && !hasStarsToSend;

  if (payload?.editId) {
    yield put(actionGetStarById({ starId: payload.editId }));
  }

  if (showError) {
    yield put(
      addToast({
        message: i18n?.['sendStar.noStarAvailable'] || messages?.noStarAvailable?.defaultMessage,
        timeout: 15000,
      }),
    );
  } else if (!payload?.hideModal) {
    yield put(openFullscreenModal({
      modalType: SEND_STAR_FLOW_MAIN,
    }));
  }
}

export function* setInitialWatcher() {
  yield takeEvery(STAR_FLOW_INITIAL, setInitialWorker);
}

function* uploadImageWorker({ payload }) {
  try {
    const response = yield call(uploadImage, { image: payload.file });
    const pathname = yield select(state => state.routing.locationBeforeTransitions.pathname);
    const starForMyself = yield select(state => state.feed.starForMyself);
    const showSendStarWidget = yield select(state => state.feed.showSendStarWidget);
    let trackEventJson = {
      category: 'Give a Star',
      label: 'Upload Image',
    };
    if (showSendStarWidget) {
      trackEventJson.action = starForMyself ? 'Received Star Detail' : 'Received Star Detail Other Users';
    } else {
      trackEventJson.action = pathname === '/home' ? 'Home' : 'Profile';
    }
    trackEvent(trackEventJson);
    const { status } = response;
    if (status === 'FAIL') {
      yield put(addToast({
        message: payload.errorMessage,
        timeout: 15000,
      }));
      yield put({ type: STAR_FLOW_UPLOAD_IMAGE_FAILED });
    } else {
      yield put({ type: STAR_FLOW_UPLOAD_IMAGE_SUCCESS, payload: response.result });
    }
  } catch (e) {
    yield put({ type: STAR_FLOW_UPLOAD_IMAGE_FAILED });
  }
}

export function* uploadImageWatcher() {
  yield takeEvery(STAR_FLOW_UPLOAD_IMAGE, uploadImageWorker);
}

function* getStarByIdWorker({ payload }) {
  try {
    const stars = yield select(selectSessionStars);
    const userId = yield select(selectSessionUserId);
    const starData = yield call(apiGetStarById, {
      starId: payload?.starId,
      userId,
    });
    const entities = helperGetEntities(starData);
    const star = helperGetStar(stars, starData);
    const imageCode = helperGetMainPhoto(starData?.comments);

    yield put(actionGetStarByIdSuccess({
      comment: starData?.notes,
      entities,
      entitiesQuantity: starData?.quantityUsersKudos,
      imageCode,
      listMentions: starData?.listMentions,
      star,
    }));
  } catch (error) {
    yield put(actionGetStarByIdFail({ error }));
  }
}

export function* getStarByIdWatcher() {
  yield takeLatest(STAR_FLOW_GET_STAR_BY_ID, getStarByIdWorker);
}
