// @packages
import React, { useEffect, useState } from 'react';
import I18nProvider from 'smu-i18n/Provider';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { IntlProvider, addLocaleData } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

// ========================================================
// Internationalization Intl
// ========================================================
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import he from 'react-intl/locale-data/he';
import hi from 'react-intl/locale-data/hi';
import it from 'react-intl/locale-data/it';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';

// ========================================================
// Internationalization MomentJS
// ========================================================
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/he';
import 'moment/locale/hi';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/pt';

// @own
import { requestLanguages } from './actions';
import { selectLocale, selectMessages } from './selectors';

addLocaleData([...de, ...en, ...es, ...fr, ...he, ...hi, ...it, ...pl, ...pt]);

const ConnectedIntlProvider = ({ children }) => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const messages = useSelector(selectMessages);
  const [currentLocale, setCurrentLocale] = useState();

  useEffect(() => {
    moment.locale(locale);
    momentTz.defineLocale(locale, moment.localeData()._config);
    momentTz.locale(locale);
    setCurrentLocale(locale);
  }, [locale]);

  useEffect(() => {
    dispatch(requestLanguages());
  }, []);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <I18nProvider>
        {currentLocale ? children : <div />}
      </I18nProvider>
    </IntlProvider>
  );
};

export default ConnectedIntlProvider;
