import { call, put, takeLatest } from 'redux-saga/effects';
import { getInteractions } from './api';
import { GET_INTERACTIONS, GET_INTERACTIONS_FAIL, GET_INTERACTIONS_SUCCESS } from './actionTypes';

function* getInteractionsWorker({ payload }) {
  try {
    const { uid } = payload;
    const data = yield call(getInteractions, { uid });
    yield put({ type: GET_INTERACTIONS_SUCCESS, payload: { data } });
  } catch (e) {
    yield put({ type: GET_INTERACTIONS_FAIL });
  }
}

function* getInteractionsWatcher() {
  yield takeLatest(GET_INTERACTIONS, getInteractionsWorker);
}

export default {
  getInteractionsWatcher,
};
