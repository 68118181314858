// @packages
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect } from 'react';

// @app
import { shouldRenderLivingValues } from 'containers/LivingValues/helpers';
import { MODAL_LIVING_VALUES } from 'containers/QueryModals/constants';
function InitiativesLayout({ router, showLivingValues }) {
  useEffect(() => {
    router.push(`/home?modal=${MODAL_LIVING_VALUES}`);
  }, [showLivingValues]);

  return null;
}

InitiativesLayout.propTypes = {
  router: PropTypes.object.isRequired,
  showLivingValues: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  showLivingValues: shouldRenderLivingValues(state),
});

export default connect(mapStateToProps)(InitiativesLayout);
