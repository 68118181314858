import parse from 'url-parse';

export const isValidURL = (value) => {
  const base =
    '^(https?:\\/\\/)?((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+[a-zA-Z]{2,13})';
  const ipV4 = '|((\\d{1,3}\\.){3}\\d{1,3})';
  const localhost = '|localhost)';
  const port = '(\\:\\d{1,5})?';
  const path = '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*';
  const query = '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?';
  const fragment = '(\\#[-a-zA-Z&\\d_]*)?$';
  return new RegExp(
    base + ipV4 + localhost + port + path + query + fragment,
  ).test(value);
};

export function objectToGetParams(object) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    );

  return params?.length ? `?${params.join('&')}` : '';
}

export function getRootURL(context = window.location) {
  const { host, protocol } = context;

  return `${protocol}//${host}`.toLowerCase();
}

export function isCurrentDomain(url) {
  const rootUrl = getRootURL();
  const parser = document.createElement('a');
  parser.href = url;
  return rootUrl === getRootURL(parser);
}

export function getFullPathname(href = '') {
  const path = href.split('/').slice(3).join('/');
  return `/${path}`;
}

export function getPathnameByHash(href = window.location.href) {
  const parseUrl = parse(href);
  const includeHash = ['#logout'];

  if (/\//g.test(parseUrl.hash) || includeHash.includes(parseUrl.hash)) {
    return `/${parseUrl.hash.replace(/^#/, '').replace(/^\//, '')}`;
  }

  return null;
}

/**
 * Return the redirect URL of a questionnaire if it exists.
 * @param {string} href - The current URL.
 * @returns {string|null} The questionnaire redirect URL or null if it doesn't exist.
 */
export function getQuestionnaireRedirectUrl(href, isSurveysOnly) {
  const url = new URL(href);
  const questionnaire = url.searchParams.get('questionnaire');

  if (questionnaire) {
    url.searchParams.delete('questionnaire');
    url.pathname = `/questionnaire/${questionnaire}`;
    return url.href;
  }

  if (url.pathname.indexOf('home/questionnaire') !== -1) {
    url.pathname = url.pathname.replace('home/questionnaire', 'questionnaire');
    return url.href;
  }

  if (isSurveysOnly && url.pathname === '/home') {
    url.pathname = '/questionnaires';
    return url.href;
  }

  return null;
}

/**
 * Get the redirect URL from the current URL.
 * @param {string} href - The current URL.
 * @returns {string|null} The redirect URL or null if it doesn't exist.
 */
export function getRedirectUrl({ href, isSurveysOnly }) {
  const pathnameHash = getPathnameByHash(href);
  const questionnaireRedirectUrl = getQuestionnaireRedirectUrl(
    href,
    isSurveysOnly,
  );

  return pathnameHash || questionnaireRedirectUrl;
}

/**
 * Replaces the domain of a given URL with a new domain.
 *
 * @param {string} url - The original URL.
 * @param {string} newDomain - The new domain to replace with.
 * @returns {string|null} The URL with the replaced domain or null if the original URL is invalid.
 *
 * @example
 * replaceDomain("https://www.example.com/path?query=value#anchor", "https://www.newdomain.com");
 * // => "https://www.newdomain.com/path?query=value#anchor"
 */
export function replaceDomain(url, newDomain) {
  try {
    let urlObj = new URL(url);
    let path = urlObj.pathname + urlObj.search + urlObj.hash;
    return newDomain + path;
  } catch {
    return null;
  }
}
