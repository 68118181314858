import React from 'react';
import Container from 'smu-layout-components/Container';
import NotificationsHistory from 'containers/NotificationsHistory';


import './styles.scss';

const Home = () => (
  <div>
    <Container centered mini className="notificationsHistory-layout">
      <NotificationsHistory />
    </Container>
  </div>
);

export default Home;
