import {
  REQUEST_ADVANCED_USER_SEARCH,
  REQUEST_ADVANCED_USER_SEARCH_SUCCESS,
  REQUEST_ADVANCED_USER_SEARCH_FAIL,
} from './actionTypes';

export function requestAdvancedUserSearch(payload) {
  return {
    type: REQUEST_ADVANCED_USER_SEARCH,
    payload,
  };
}

export function requestAdvancedUserSearchSuccess(payload) {
  return {
    type: REQUEST_ADVANCED_USER_SEARCH_SUCCESS,
    payload,
  };
}

export function requestAdvancedUserSearchFail(payload) {
  return {
    type: REQUEST_ADVANCED_USER_SEARCH_FAIL,
    payload,
  };
}
