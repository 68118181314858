// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedRelative } from 'react-intl';

// @app
import parseDate from '../../../common/utils/parseDate';

// @own
import './styles.scss';

function Header({ children, date }) {
  const dateFormatted = parseDate(date);
  return (
    <div className="image-event-header">
      {children}
      {date && (
        <div className="image-event-header__timestamp">
          <FormattedRelative value={dateFormatted.toDate()} />
        </div>
      )}
    </div>
  );
}

Header.defaultProps = {
  date: '',
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  date: PropTypes.string,
};

export default Header;
