// @packages
import { browserHistory } from 'react-router';

// @app
import CongratulationLayout from 'routes/Congratulation/CongratulationLayout';
import {
  INTERACTION_ANNIVERSARY,
  INTERACTION_BIRTHDAY,
} from 'utils/congratulations';
import { paths } from 'utils/routes';

export default () => ({
  component: CongratulationLayout,
  onEnter: ({ location: { query } }) => {
    if (
      query.type !== INTERACTION_ANNIVERSARY &&
      query.type !== INTERACTION_BIRTHDAY
    ) {
      browserHistory.replace('/home');
    }
    window.scrollTo(0, 0);
  },
  path: paths.congratulationUser(),
});
