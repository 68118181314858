// @packages
import React, { useEffect, useMemo } from 'react';
import DownloadApp from 'smu-app-components/DownloadApp';
import LottieSpiner from 'smu-ui-components/LottieSpinner';
import { ProvideTheme } from 'smu-custom-hooks/useTheme';
import { detectWebView } from 'smu-utils/device';
import { useDispatch, useSelector } from 'react-redux';

// @app
import App from 'containers/App';
import IntlProvider from 'containers/IntlProvider';
import { ENABLED_SURVEYS_ONLY } from 'services/communityConfigs/constants';
import { SCREEN_TABLET_MIN } from 'utils/breakpoints';
import { appLoaderInit } from 'services/appLoader/actions';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { getRedirectUrl } from 'utils/url';
import {
  selectorAppLoaderEnabled,
  selectorAppLoaderSuccess,
} from 'services/appLoader/selectors';

// @own
import './styles.scss';

function AppLoader({ children, router, showApp }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectorAppLoaderEnabled);
  const isSuccess = useSelector(selectorAppLoaderSuccess);
  const isWebView = detectWebView();
  const isMobile = window.innerWidth < SCREEN_TABLET_MIN;
  const isSurveysOnly = useSelector((state) =>
    getCommunityConfigValueBool(state, ENABLED_SURVEYS_ONLY));
  const redirectUrl = useMemo(
    () => getRedirectUrl({ href: window.location.href, isSurveysOnly }),
    [window.location.href, isSurveysOnly],
  );

  useEffect(() => {
    dispatch(appLoaderInit());
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      router.replace(redirectUrl);
    }
  }, [redirectUrl]);

  if (isLoading) {
    return <LottieSpiner className="smu-app-loader" />;
  }

  if (isMobile && !isWebView) {
    return (
      <IntlProvider>
        <DownloadApp />
      </IntlProvider>
    );
  }

  if (!redirectUrl && isSuccess && children) {
    return showApp ? (
      <IntlProvider>
        <ProvideTheme>
          <App>{children}</App>
        </ProvideTheme>
      </IntlProvider>
    ) : (
      children
    );
  }

  return null;
}

AppLoader.defaultProps = {
  showApp: true,
};

export default AppLoader;
