// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import { detectWebView } from 'smu-utils/device';
import { injectIntl } from 'react-intl';

// @app
import ModalShare from 'components/Modals/Share';
import ShareContainer from 'containers/Share';
import ShareButtonsContainer from 'containers/ShareButtons';
import commonMessages from 'common/messages';
import useToast from 'hooks/useToast';
import { onErrorWebviewShareUrl, onSuccessWebviewShareUrl } from './helpers';

// @own
import messages from './messages';

const ModalShareContainer = ({ onClose, type, step, intl, ...rest }) => {
  const [shareUrl, setShareUrl] = useState();
  const showMessage = useToast();
  const camelCaseType = camelCase(type);
  const titleId = messages?.[`${camelCaseType}Title`] || messages.title;
  const title = intl.formatMessage(titleId);
  const isWebView = detectWebView();

  const handleError = (error) => {
    showMessage({
      message: intl.formatMessage(commonMessages.smuCommonError),
    });
    if (onClose) {
      if (isWebView) {
        onErrorWebviewShareUrl(error);
      } else {
        onClose();
      }
    }
  };

  const handleSuccess = (shareUrl) => {
    if (isWebView) {
      onSuccessWebviewShareUrl(shareUrl);
    } else {
      setShareUrl(shareUrl);
    }
  };

  return (
    <ModalShare
      footer={
        <ShareButtonsContainer className="justify-center" shareUrl={shareUrl} />
      }
      onClose={onClose}
      title={title}
      {...rest}
    >
      <ShareContainer
        type={type}
        step={step}
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </ModalShare>
  );
};

ModalShareContainer.propTypes = {
  onClose: PropTypes.func,
  step: PropTypes.string,
  type: PropTypes.string,
};

export default injectIntl(ModalShareContainer);
