import { defineMessages } from 'react-intl';

export default defineMessages({
  delete: {
    id: 'postMenu.delete',
    defaultMessage: 'Delete',
  },
  edit: {
    id: 'postMenu.edit',
    defaultMessage: 'Edit',
  },
  copyUrl: {
    id: 'postMenu.copyUrl',
    defaultMessage: 'Copy url',
  },
  taggedUsers: {
    id: 'postMenu.taggedUsers',
    defaultMessage: 'Tagged users',
  },
  hideDescription: {
    id: 'postMenu.hideDescription',
    defaultMessage: 'Hide description',
  },
  showDescription: {
    id: 'postMenu.showDescription',
    defaultMessage: 'Show description',
  },
  hideComment: {
    id: 'postMenu.hideComment',
    defaultMessage: 'Hide comment',
  },
  showComment: {
    id: 'postMenu.showComment',
    defaultMessage: 'Show comment',
  },
});
