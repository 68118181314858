// @app
import React from 'react';
import Comments from 'smu-ui-components/Comments';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import { selectIsModerator } from 'containers/Authorization/selectors';
import { useSelector } from 'react-redux';

// @app
import PostMenu from 'containers/PostMenu';

// @own
import messages from './messages';

const CommentsContainer = ({
  className,
  comments,
  intl,
  moderationEnabled,
  onModerate,
  ...rest
}) => {
  const isModerator = useSelector(selectIsModerator);

  const handleModerate = ({ id, enabled }) => {
    if (onModerate) onModerate({ id, enabled });
  };

  const normaliceComments = comments?.reduce((acc, cur) => {
    const { id, enabled, message, mentions, ...comment } = cur;
    const labelMessage = enabled ? '' : intl.formatMessage(messages.moderate);

    if (isModerator && moderationEnabled) {
      acc.push({
        ...comment,
        message,
        labelMessage,
        menu: (
          <PostMenu
            isModerateComment={!enabled}
            onModerate={() => handleModerate({ id, enabled })}
            showModerateComment
          />
        ),
      });
    } else if (enabled) {
      acc.push({
        ...comment,
        message,
      });
    }
    return acc;
  }, []);

  return (
    <Comments
      className={cn('bg-white px-3 py-4', className)}
      comments={normaliceComments}
      {...rest}
    />
  );
};

CommentsContainer.defaultProps = {
  comments: [],
  moderationEnabled: false,
};

CommentsContainer.propTypes = {
  className: PropTypes.string,
  comments: PropTypes.array,
  intl: PropTypes.object,
  moderationEnabled: PropTypes.bool,
  onModerate: PropTypes.func,
};

export default injectIntl(CommentsContainer);
