// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// @app
import ModalInteractions from 'components/Modals/Interactions';
import { selectSessionUserUid } from 'containers/Authorization/selectors';

// @own
import messages from './messages';
import { TYPE_ALL, TABS } from './constants';
import { buildTab, filterTabs } from './helpers';
import { eventOnOpenModal, eventOnCloseModal } from './analytics';

const ModalInteractionsContainer = ({
  contentDescription,
  contentId,
  contentType,
  defaultType,
  interactions,
  intl: { formatMessage },
  ...rest
}) => {
  const [active, setActive] = useState(defaultType);
  const ownerUid = useSelector(selectSessionUserUid);

  const tabs = TABS.map((tab) =>
    buildTab({
      active,
      contentDescription,
      contentId,
      contentType,
      formatMessage,
      interactions,
      ownerUid,
      setActive,
      type: tab,
    }));

  const filteredTabs = filterTabs(tabs);

  useEffect(() => {
    eventOnOpenModal();
    return () => eventOnCloseModal();
  }, []);

  return (
    <ModalInteractions
      tabs={filteredTabs}
      title={formatMessage(messages.title)}
      {...rest}
    />
  );
};

ModalInteractionsContainer.propTypes = {
  contentDescription: PropTypes.string,
  contentId: PropTypes.number,
  contentType: PropTypes.string,
  defaultType: PropTypes.oneOf(TABS),
  interactions: PropTypes.shape({
    all: PropTypes.shape({
      total: PropTypes.number,
      isLoading: PropTypes.bool,
    }),
    brightens: PropTypes.shape({
      isLoading: PropTypes.bool,
      total: PropTypes.number,
      users: PropTypes.array,
    }),
    likes: PropTypes.shape({
      isLoading: PropTypes.bool,
      total: PropTypes.number,
      users: PropTypes.array,
    }),
  }),
};

ModalInteractionsContainer.defaultProps = {
  defaultType: TYPE_ALL,
};

export default injectIntl(ModalInteractionsContainer);
