export const DEFAULT_TIMER_MESSAGE = 5000;
export const OTHER_PROFILE = 'OTHER_PROFILE';
export const POSTS = 'POSTS';
export const PROFILE = 'PROFILE';
export const REQUEST_ID_FEED = 'bt-feed';
export const BT_OWN_FEED = 'BT_OWN_FEED';
export const BT_TAGGED_FEED = 'BT_TAGGED_FEED';
export const TAGGED = 'TAGGED';
export const UPLOAD_PHOTOS_AND_VIDEOS = 'UPLOAD_PHOTOS_AND_VIDEOS';

export const EMPTY_STATE_PROPS = {
  [PROFILE]: {
    [TAGGED]: {
      src: '/assets/img/empty_tagged.png',
      text: 'bethereFeedEmptyProfileTaggedText',
    },
    [POSTS]: {
      src: '/assets/img/empty_posts.png',
      subtext: 'bethereFeedEmptyProfilePostSubtext',
      text: 'bethereFeedEmptyProfilePostText',
    },
  },
  [OTHER_PROFILE]: {
    [TAGGED]: {
      src: '/assets/img/empty_tagged.png',
      text: 'bethereFeedEmptyOtherProfileTaggedText',
    },
    [POSTS]: {
      src: '/assets/img/empty_posts.png',
      text: 'bethereFeedEmptyOtherProfilePostText',
    },
  },
};
