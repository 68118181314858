export const getEntities = state => state.starFlow.entities;
export const getRemainingStars = state => state.starFlow.remainingStars;
export const getSelectedStar = state => state.starFlow.star;
export const getStarsValues = state => state.session.stars;
export const selectComment = state => state.starFlow.comment;
export const selectFetchingStarById = state => state.starFlow.fetchingStarById;
export const selectHasStarsToSend = state => state?.starFlow?.hasStarsToSend;
export const selectLiftStarEntities = state => state?.starFlow?.liftStarEntities || [];
export const selectRemainingStarsCounter = state => state?.starFlow?.remainingCounter || 0;
export const selectRemainingStarsSuccess = state => state?.starFlow?.remainingStarsRequest?.success;
export const selectStarFlow = state => state?.starFlow;
export const selectStarFlowOrigin = state => state.starFlow.origin;
export const selectStarFlowQuery = state => state.starFlowSearch.query;
export const selectStarFlowStatus = state => state.starFlow.status;
export const selectToastInfo = state => state.toastr.list;
