import {
  REQUEST_UPLOAD_SHARE_IMAGE,
  REQUEST_UPLOAD_SHARE_IMAGE_DESTROY,
  REQUEST_UPLOAD_SHARE_IMAGE_FAIL,
  REQUEST_UPLOAD_SHARE_IMAGE_SUCCESS,
} from './actionTypes';

export function actionUploadShareImage(payload, id) {
  return {
    id,
    payload,
    type: REQUEST_UPLOAD_SHARE_IMAGE,
  };
}

export function actionUploadShareImageSuccess(payload, id) {
  return {
    id,
    payload,
    type: REQUEST_UPLOAD_SHARE_IMAGE_SUCCESS,
  };
}

export function actionUploadShareImageFail(payload, id) {
  return {
    id,
    payload,
    type: REQUEST_UPLOAD_SHARE_IMAGE_FAIL,
  };
}

export function actionUploadShareImageDestroy(id) {
  return {
    id,
    type: REQUEST_UPLOAD_SHARE_IMAGE_DESTROY,
  };
}
