// @packages
import React from 'react';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import cn from 'classnames';

const CongratulationsChip = ({ className, active, ...rest }) => (
  <Link
    className={cn(
      'transition-colors text-xs py-1.5 px-4 rounded-full hover:bg-lavander hover:text-blue',
      {
        'bg-blue text-white': active,
        'bg-lavander-light text-blue': !active,
      },
      className,
    )}
    legacy={false}
    underline="none"
    {...rest}
  />
);

CongratulationsChip.propTypes = {
  className: PropTypes.string,
};

CongratulationsChip.defaultProps = {
  className: '',
};

export default CongratulationsChip;
