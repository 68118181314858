// @packages
import { browserHistory } from 'react-router';
import { trackEvent } from 'utils/gtm';

// @app
import { buildModalUrl } from 'containers/QueryModals/helpers';

export const INTERACTION_ANNIVERSARY = 'INTERACTION_ANNIVERSARY';
export const INTERACTION_BIRTHDAY = 'INTERACTION_BIRTHDAY';

export function redirectToCogratulation(id) {
  browserHistory.push(buildModalUrl({ modal: 'congratulation', id }));
}

export function redirectToCogratulationAnniversary(userId) {
  browserHistory.push(
    buildModalUrl({
      modal: 'congratulation',
      userId,
      type: INTERACTION_ANNIVERSARY,
    }),
  );
}

export function redirectToCogratulationBirthday(userId) {
  browserHistory.push(
    buildModalUrl({
      modal: 'congratulation',
      userId,
      type: INTERACTION_BIRTHDAY,
    }),
  );
}

export function redirectToCogratulationFeed(userId) {
  browserHistory.push(`/congratulation/feed/${userId}`);
}

export function profileRedirectToCogratulationAnniversary(userId) {
  trackEvent({
    action: 'anniversary',
    category: 'xprofile_congrats',
    label: 'click_icon',
  });
  redirectToCogratulationAnniversary(userId);
}

export function profileRedirectToCogratulationBirthday(userId) {
  trackEvent({
    action: 'birthday',
    category: 'xprofile_congrats',
    label: 'click_icon',
  });
  redirectToCogratulationBirthday(userId);
}
