// @packages
import React from 'react';
import Avatar from 'smu-ui-components/Avatar';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import cn from 'classnames';

const AvatarLink = ({ borderColor, className, href, imageIcon, ...rest }) => {
  const linkProps = {
    href,
    legacy: href ? false : undefined,
    component: href ? Link : undefined,
  };

  return (
    <div className={cn('relative', className)}>
      <Avatar
        className={cn('bg-white', {
          'hover:brightness-110 transition-all': href,
          'border-2': borderColor,
        })}
        style={{
          borderColor,
        }}
        {...linkProps}
        {...rest}
      />
      {imageIcon && (
        <Avatar
          className="!absolute -right-1 bottom-0"
          height={28}
          src={imageIcon}
          width={28}
        />
      )}
    </div>
  );
};

AvatarLink.propTypes = {
  borderColor: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  imageIcon: PropTypes.string,
};

export default AvatarLink;
