// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

const DiscoverOptions = ({ options }) => (
  <div className="discover-options">
    {options?.map(({ enabled, icon, key, onClick, tooltip }) => (
      <Button
        className="discover-options__button"
        color="unifyBlue"
        disabled={!enabled}
        enabledUnification
        key={key}
        onClick={onClick}
        variant="outline"
      >
        <span className="discover-options__text">{tooltip}</span>
        <Icon className="discover-options__icon" color="black" icon={icon} />
      </Button>
    ))}
  </div>
);

DiscoverOptions.propTypes = {
  options: PropTypes.array,
};

export default DiscoverOptions;
