import { objectToGetParams } from './url';
import {
  getBoxPositionOnWindowCenter,
  getBoxPositionOnScreenCenter,
  windowOpen,
} from './window';

export const SHARE_TYPE_FACEBOOK = 'FACEBOOK';
export const SHARE_TYPE_WORKPLACE = 'WORKPLACE';
export const SHARE_TYPE_LINKEDIN = 'LINKEDIN';
export const SHARE_TYPE_TWITTER = 'TWITTER';
export const SOCIAL_MEDIAS_ENABLE = [
  {
      type: SHARE_TYPE_FACEBOOK,
      icon: 'facebook',
  },
  {
      type: SHARE_TYPE_LINKEDIN,
      icon: 'linkedin',
  },
];

export function openShareDialog(props = {}) {
  const {
    link,
    onShareWindowClose,
    windowHeight = 400,
    windowPosition = 'windowCenter',
    windowWidth = 550,
  } = props;

  const windowConfig = {
    height: windowHeight,
    width: windowWidth,
    ...(windowPosition === 'windowCenter'
      ? getBoxPositionOnWindowCenter(windowWidth, windowHeight)
      : getBoxPositionOnScreenCenter(windowWidth, windowHeight)),
  };

  return windowOpen(link, windowConfig, onShareWindowClose);
}

export function facebookLink(url, { quote, hashtag }) {
  return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
    hashtag,
    quote,
    u: url,
  })}`;
}

export function workplaceLink(url, { quote, hashtag }) {
  return `https://work.facebook.com/sharer.php${objectToGetParams({
    hashtag,
    quote,
    u: url,
  })}`;
}

export function linkedinLink(url, { title, summary, source }) {
  return `https://linkedin.com/shareArticle${objectToGetParams({
    mini: 'true',
    source,
    summary,
    title,
    url,
  })}`;
}

export function twitterLink(url, { title, via, hashtags = [], related = [] }) {
  return `https://twitter.com/share${objectToGetParams({
    hashtags: hashtags?.length > 0 ? hashtags.join(',') : undefined,
    related: related?.length > 0 ? related.join(',') : undefined,
    text: title,
    url,
    via,
  })}`;
}
