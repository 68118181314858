// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import Icon from 'smu-ui-components/Icon';
import Button from 'smu-ui-components/Button';

// @own
import './styles.scss';
import SelectField from './SelectField';
// remove when the app will support device language
import messages from '../messages'; // eslint-disable-line

function Demographics({
  appearance,
  demographicsInputs,
  handleSubmitDemographics,
  // remove when the app will support device language
  intl: { formatMessage }, // eslint-disable-line
  language,
}) {
  const [demographicsSelected, setDemographicsSelected] = useState([]);

  const text = () => ({
    ENGLISH: {
      title: 'Attention please!',
      titleInfo: 'You are about to enter incognito mode',
      subtitleInfo: 'It is important that you know your data is completely anonymous.',
      headerInfo: 'To continue please provide the following information:',
      buttonNext: 'Next',
    },
    SPANISH: {
      title: '¡Atención por favor!',
      titleInfo: 'Estás a punto de entrar en el modo incógnito',
      subtitleInfo: 'Es importante que sepa que sus datos son totalmente anónimos.',
      headerInfo: 'Para continuar por favor suministrar los siguientes datos:',
      buttonNext: 'Siguiente',
    },
  })[language];

  const {
    title,
    titleInfo,
    subtitleInfo,
    headerInfo,
    buttonNext,
  } = text();

  function handleChange(event, id) {
    const { value } = event.target;
    const selected = { id, answer: value };

    if (demographicsSelected.length === 0) {
      setDemographicsSelected([selected]);
    } else {
      const isNewValue = !(demographicsSelected.find(input => input.id === id));
      let newValues;

      if (isNewValue) {
        const copyOfDemographicsSelected = demographicsSelected;
        newValues = copyOfDemographicsSelected.concat(selected);
      } else {
        newValues = demographicsSelected.map(item => (item.id === id ? ({
          ...selected,
        }) : ({
          ...item,
        })));
      }

      setDemographicsSelected(newValues);
    }
  }

  const demographicsItemsLength = demographicsInputs && demographicsInputs.length;

  return (
    <div className="questionnaire-demographics" style={{ background: appearance }}>
      <div className="questionnaire-demographics__title">
        {title}
      </div>
      <div className="questionnaire-demographics__icon-container">
        <Icon icon="incognito" color="white" size={50} />
      </div>
      <div className="questionnaire-demographics__info">
        <div className="questionnaire-demographics__info-title">
          {titleInfo}
        </div>
        <div className="questionnaire-demographics__info-subtitle">
          {subtitleInfo}
        </div>
        <div className="questionnaire-demographics__info-header-form">
          {headerInfo}
        </div>
      </div>
      <div className="questionnaire-demographics__form">
        <div className="questionnaire-demographics__form-content">
          {demographicsInputs && demographicsInputs.map((input, index) => (
            <SelectField
              fieldName={input.name}
              id={input.id}
              index={index}
              isValid={demographicsSelected.some(option => option.id === input.id)}
              key={index}
              onChange={handleChange}
              placeholder={input.name}
              values={input.items}
            />
          ))}
        </div>
        <div className="questionnaire-demographics__button-container">
          <Button
            className={cn(
              'questionnaire-demographics__button',
              { 'questionnaire-demographics__button--disabled': demographicsSelected.length !== demographicsItemsLength },
            )}
            disabled={!demographicsSelected}
            noMargin
            noPadding
            onClick={() => handleSubmitDemographics(demographicsSelected)}
          >
            {buttonNext}
          </Button>
        </div>
      </div>
    </div>
  );
}

Demographics.defaultProps = {
  demographicsInputs: [],
};

Demographics.propTypes = {
  appearance: PropTypes.string.isRequired,
  demographicsInputs: PropTypes.array,
  handleSubmitDemographics: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
};

export default injectIntl(Demographics);
