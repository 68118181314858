// @packages
import React from 'react';
import PropTypes from 'prop-types';
import WhatsNewContainer from 'smu-app-components/WhatsNew';
import cn from 'classnames';
import { connect } from 'react-redux';
import { detectWebView } from 'smu-utils/device';

// @app
import {
  ENABLED_BETTERME,
  ENABLED_MYTEAM_DASHBOARD,
  ENABLED_ONE_ON_ONE,
} from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';

// @own
import './styles.scss';

const WhatsNewLayout = ({
  bettermeEnabled,
  isLeader,
  location,
  myTeamDashboardEnabled,
  oneToOneEnabled,
}) => (
  <div
    className={cn('smu-whats-new-layout', {
      'smu-whats-new-layout--webview': detectWebView(),
    })}
  >
    <WhatsNewContainer
      bettermeEnabled={bettermeEnabled}
      dashboardEnabled={isLeader && myTeamDashboardEnabled}
      starmeupEnabled
      oneToOneEnabled={oneToOneEnabled}
      version={location?.query?.version}
    />
  </div>
);

WhatsNewLayout.propTypes = {
  bettermeEnabled: PropTypes.bool,
  isLeader: PropTypes.bool,
  location: PropTypes.shape({
    query: PropTypes.shape({
      version: PropTypes.string,
    }),
  }),
  myTeamDashboardEnabled: PropTypes.bool,
  oneToOneEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  bettermeEnabled: getCommunityConfigValueBool(state, ENABLED_BETTERME),
  isLeader: getCommunityConfigValueBool(state, 'isLeader'),
  myTeamDashboardEnabled: getCommunityConfigValueBool(
    state,
    ENABLED_MYTEAM_DASHBOARD,
  ),
  oneToOneEnabled: getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
});

export default connect(mapStateToProps)(WhatsNewLayout);
