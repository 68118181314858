// @app
import { isValidURL } from 'utils/url';

// @own
import { CLOUDFRONT } from './constants';

export function getCloudFront(properties) {
  return properties
    ? properties.find((p) => p.key === CLOUDFRONT)?.value
    : undefined;
}

export function getImagePath(url) {
  const arr = url ? url.split('/') : [];
  arr.pop();
  return arr.join('/');
}

export function getImage(post, type, cloudFrontValue) {
  const image = post?.post_image_info?.info;
  const originalPath = image?.original?.path;
  const name = `${image[type]?.name}`;
  const path = isValidURL(originalPath)
    ? getImagePath(originalPath)
    : `https://${cloudFrontValue}`;
  return `${path}/${name}`;
}

export function normalice(posts, properties) {
  const cloudFrontValue = getCloudFront(properties);
  return posts.map((post) => {
    const type = post?.post_type;
    const image = post?.post_image_info?.info;
    const originalUrl = getImage(post, 'original', cloudFrontValue);
    const height = image?.original?.size?.height;
    const width = image?.original?.size?.width;
    const id = post?.post_id;
    const thumbnailUrl = getImage(post, 'small', cloudFrontValue);
    const description = post?.post_description;
    const mentions = post?.mentions;
    const u = post?.user;
    const user = {
      firstName: u?.user_name,
      identification: u?.user_identification,
      job: u?.user_job,
      lastName: u?.user_lastname,
      profileImageCode: u?.user_image,
      useLink: false,
    };

    return {
      description,
      height,
      id,
      mentions,
      originalUrl,
      thumbnailUrl,
      type,
      user,
      width,
    };
  });
}
