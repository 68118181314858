// @packages
import React, { useState, useEffect } from 'react';
import FullScreenModal from 'smu-app-components/FullScreenModalV2';
import PropTypes from 'prop-types';
import moment from 'moment';
import usePrevious from 'smu-custom-hooks/usePrevious';
import withRootModal from 'smu-app-components/RootModalV2/withRootModal';
import { actionRequestDestroy, actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { closeFullscreenModal } from 'smu-app-components/FullScreenModalV2/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { selectRequest } from 'smu-utils/reduxRequests/selectors';
import { trackPageview } from 'smu-utils/gtm';

// @app
import AddCalendarModal from 'containers/AddCalendarModal';
import CreateChillout from 'components/CreateChillout';
import UsersList from 'components/UsersList';
import useDebounce from 'hooks/useDebounce';
import useDiscover from 'hooks/useDiscover';
import { SMU_GENERIC_MODAL } from 'containers/GenericModal/constants';
import { buildCalendarUrl } from 'utils/google';
import { getLabelFilters } from 'utils/discover';
import { getTimesByInterval } from 'utils/times';
import { trackEvent } from 'utils/gtm';

// @own
import {
  DEBOUNCE_TIME,
  DISCOVER_CREATE_CHILLOUT,
  FORMAT_DATE,
  FORMAT_GOOGLE_DATE,
  FORMAT_TIME,
  FORMAT_UTC,
} from './constants';
import messages from './messages';
import { apiPostAddCalendar } from './api';

function ChilloutModal({
  actionRequestDestroy,
  actionRequestInit,
  closeFullscreenModal,
  closeModal,
  intl: { formatMessage },
  openModal,
  requestCreate,
  users,
}) {
  const [state, setState] = useState({
    date: moment(),
    description: '',
    from: undefined,
    title: '',
    to: undefined,
  });

  const discover = useDiscover();
  const intervalTimes = getTimesByInterval();
  const blacklistedMembers = discover.excludeUsers.map((id) => ({ id }));
  const whitelistedUsers = users.reduce((acc, cur) => {
    if (!discover.excludeUsers.includes(cur.id)) {
      acc.push(cur);
    }
    return acc;
  }, []);
  const whitelistedMembers = whitelistedUsers.map(({ id }) => ({ id }));
  const times = intervalTimes.map((t) => ({ label: t, value: t }));
  const getStartTimes = (state) => {
    if (moment().format(FORMAT_DATE) === moment(state.date).format(FORMAT_DATE)) {
      return times.filter((time) => {
        const value = moment(time.value, FORMAT_TIME);
        return value.isAfter(moment());
      });
    }
    return times;
  };

  const getEndTimes = (state) =>
    times.filter((time) => moment(time.value, FORMAT_TIME) > moment(state.from, FORMAT_TIME));

  const startTimes = getStartTimes(state);

  const endTimes = getEndTimes(state);

  const isValidToDate = (state) =>
    state.from && state.to && moment(state.from, FORMAT_TIME) < moment(state.to, FORMAT_TIME);

  const isValidFromTime = (state) =>
    !state.from || getStartTimes(state).find((s) => s.value === state.from);

  const handleChange = (update) => {
    const mergeState = {
      ...state,
      ...update,
    };

    if (!isValidFromTime(mergeState)) {
      mergeState.from = undefined;
    }

    if (!isValidToDate(mergeState)) {
      mergeState.to = undefined;
    }

    setState(mergeState);
  };

  const utcDateTime = (time) => {
    const d = moment().format(FORMAT_DATE);
    const f = moment(`${d} ${time}`, `${FORMAT_DATE} ${FORMAT_TIME}`);
    return f.format(FORMAT_UTC);
  };

  const googleDateTime = (date, time) => {
    const d = moment(date).format(FORMAT_DATE);
    const f = moment(`${d} ${time}`, `${FORMAT_DATE} ${FORMAT_TIME}`);
    return f.format(FORMAT_GOOGLE_DATE);
  };

  const sendEvent = (label) => {
    trackEvent({
      action: 'click',
      category: 'chillout',
      label,
    });
  };

  const closeModals = () => {
    closeModal();
    closeFullscreenModal();
  };

  const handleAddToCalendar = () => {
    const {
      date,
      description,
      from,
      title,
      to,
    } = state;
    const filteredGuests = whitelistedUsers.filter((user) => user.email);
    const guests = filteredGuests.reduce((guests, item) => {
      guests.push(item.email);
      return guests;
    }, []);
    const googleCalendarUrl = buildCalendarUrl({
      description,
      endDateTime: googleDateTime(date, to),
      guests,
      startDateTime: googleDateTime(date, from),
      title,
    });
    trackEvent({
      action: 'success_modal',
      category: 'chillout',
      label: 'add_to_calendar',
    });
    trackEvent({
      action: 'success_modal',
      category: 'chillout',
      label: 'calendar:google',
    });
    window.open(googleCalendarUrl, '_blank');
    closeModal();
  };

  const handleSaveSuccess = () => {
    trackPageview({
      pageName: '/chillout/success',
    });
    trackEvent({
      action: 'success',
      category: 'chillout',
      label: getLabelFilters(discover.filters),
      list_count: discover.totalUsersSelected,
    });
    closeModals();
    openModal({
      modalType: SMU_GENERIC_MODAL,
      modalProps: {
        renderModal: (props) =>
          <AddCalendarModal {...props} onClick={handleAddToCalendar}/>,
        users: whitelistedUsers,
      },
    });
  };

  const showDiscardModal = () => {
    if (
      isEmpty(state.title) &&
      isEmpty(state.description) &&
      isEmpty(state.from) &&
      isEmpty(state.to)
    ) {
      closeModals();
    } else {
      openModal({
        modalType: SMU_GENERIC_MODAL,
        modalProps: {
          actions: [
            {
              children: formatMessage(messages.createChilloutDiscardModalNoButton),
              onClick: () => {
                trackEvent({
                  action: 'discard_no',
                  category: 'chillout',
                  label: getLabelFilters(discover.filters),
                  list_count: discover.totalUsersSelected,
                });
                closeModal();
              },
              variant: 'outline',
            },
            {
              children: formatMessage(messages.createChilloutDiscardModalYesButton),
              onClick: () => {
                trackEvent({
                  action: 'discard_yes',
                  category: 'chillout',
                  label: getLabelFilters(discover.filters),
                  list_count: discover.totalUsersSelected,
                });
                closeModals();
              },
            },
          ],
          children: formatMessage(messages.createChilloutDiscardModalText),
          title: formatMessage(messages.createChilloutDiscardModalTitle),
        },
      });
    }
  };

  const showUsersExpanded = () => {
    sendEvent('who');
    openModal({
      modalType: SMU_GENERIC_MODAL,
      modalProps: {
        children: <UsersList users={whitelistedUsers.map((u) => ({ ...u, job: u.email }))} />,
        showFooter: false,
        title: formatMessage(messages.createChilloutWho),
      },
    });
  };

  const debouncedTitle = useDebounce(state.title, DEBOUNCE_TIME);
  const debouncedDescription = useDebounce(state.description, DEBOUNCE_TIME);
  const isValidWhen = !isEmpty(state.from) && !isEmpty(state.to);
  const isValidWhy = !isEmpty(debouncedTitle) && !isEmpty(debouncedDescription);
  const prevIsValidWhen = usePrevious(isValidWhen);
  const prevIsValidWhy = usePrevious(isValidWhy);
  const disableSave =
    isEmpty(state.title) || isEmpty(state.description) || isEmpty(state.from) || isEmpty(state.to);

  const postAddCalendar = () => {
    actionRequestInit({
      api: apiPostAddCalendar,
      id: DISCOVER_CREATE_CHILLOUT,
      params: {
        who: {
          blacklistedMembers,
          segmentFilters: discover.filters,
          whitelistedMembers,
        },
        what: {
          chillout: {
            frequency: 'doesNotRepeat',
            fromDate: utcDateTime(state.from),
            toDate: utcDateTime(state.to),
          },
        },
        why: {
          description: state.description,
          name: state.title,
        },
      },
      onSuccess: handleSaveSuccess,
    });
  };

  const handleCancel = () => {
    sendEvent('cancel');
    showDiscardModal();
  };

  const handleClose = () => {
    sendEvent('cross');
    showDiscardModal();
  };

  const handleSave = () => {
    sendEvent('save');
    postAddCalendar();
  };

  useEffect(() => {
    if (!prevIsValidWhen && isValidWhen) {
      trackEvent({
        action: 'when_success',
        category: 'chillout',
        label: `${state.date} hour: ${state.from} - ${state.to}`,
      });
    }
  }, [prevIsValidWhen, isValidWhen]);

  useEffect(() => {
    if (!prevIsValidWhy && isValidWhy) {
      trackEvent({
        action: 'why_success',
        category: 'chillout',
        label: `${state.title} - ${state.description}`,
      });
    }
  }, [prevIsValidWhy, isValidWhy]);

  useEffect(() => {
    trackPageview({
      pageName: '/chillout/create',
    });
    return () => actionRequestDestroy(DISCOVER_CREATE_CHILLOUT);
  }, []);

  const texts = {
    cancel: formatMessage(messages.createChilloutButtonCancel),
    dateLabel: formatMessage(messages.createChilloutDateLabel),
    descriptionLabel: formatMessage(messages.createChilloutDescriptionLabel),
    descriptionPlaceholder: formatMessage(messages.createChilloutDescriptionPlaceholder),
    fromLabel: formatMessage(messages.createChilloutHourFrom),
    fromPlaceholder: formatMessage(messages.createChilloutHourFromPlaceholder),
    modalTitle: formatMessage(messages.createChilloutTitle),
    save: formatMessage(messages.createChilloutButtonSave),
    titleLabel: formatMessage(messages.createChilloutTitleLabel),
    titlePlaceholder: formatMessage(messages.createChilloutTitlePlaceholder),
    toLabel: formatMessage(messages.createChilloutHourTo),
    toPlaceholder: formatMessage(messages.createChilloutHourToPlaceholder),
    when: formatMessage(messages.createChilloutWhen),
    who: formatMessage(messages.createChilloutWho),
    why: formatMessage(messages.createChilloutWhy),
  };

  return (
    <CreateChillout
      date={state.date}
      description={state.description}
      disableSave={disableSave}
      endTimes={endTimes}
      from={state.from}
      isLoading={requestCreate?.requesting}
      onCancel={handleCancel}
      onChangeDate={(date) => handleChange({ date })}
      onClose={handleClose}
      onHiddenClick={showUsersExpanded}
      onSave={handleSave}
      setDescription={(description) => handleChange({ description })}
      setFrom={(from) => handleChange({ from })}
      setTitle={(title) => handleChange({ title })}
      setTo={(to) => handleChange({ to })}
      startTimes={startTimes}
      texts={texts}
      title={state.title}
      to={state.to}
      users={whitelistedUsers}
    />
  );
}

ChilloutModal.propTypes = {
  closeFullscreenModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  editorState: PropTypes.object,
  intl: PropTypes.object,
  openModal: PropTypes.func,
  requestCreate: PropTypes.object.isRequired,
  users: PropTypes.array,
};

const withFullScreenModal = FullScreenModal(ChilloutModal, DISCOVER_CREATE_CHILLOUT);

const mapStateToProps = (state) => ({
  requestCreate: selectRequest(state, DISCOVER_CREATE_CHILLOUT),
});

export default connect(mapStateToProps, {
  actionRequestDestroy,
  actionRequestInit,
  closeFullscreenModal,
})(withRootModal(injectIntl(withFullScreenModal)));
