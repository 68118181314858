// @packages
import React from 'react';
import Avatar from 'smu-ui-components/Avatar';
import UserPopup from 'smu-app-components/UserPopup';
import cn from 'classnames';
import { buildImageUrl } from 'smu-utils/image';

// @app
import messages from 'components/Star/messages';
import { getFullName } from 'components/Star/SimpleStar/helpers';

export const getIsCustomerStar = (starType) => starType === 'R' || starType === 'M';

export const getMenuProps = (props, context) => {
  const isCustomerStar = getIsCustomerStar(context?.data?.typeStar);
  return ({
    isModerate: props?.isModerate,
    onModerate: props?.onModerateStar,
    showCopyUrl: true,
    showEdit: props?.canEdit && !isCustomerStar,
    showModerate: props?.isModerator && !isCustomerStar,
    url: props?.starUrl,
  });
};

export const getModalKudosProps = (context) => {
  const title = context?.data?.starMeUpOrganizationStar?.name;
  const date = context?.data?.date;
  const starId = context?.data?.id;
  return {
    date,
    starId,
    title,
  };
};

export const getSendByProps = (props, context) => {
  const { formatMessage, ownId } = props;
  const userFrom = context?.data?.from;
  const isCustomerStar = getIsCustomerStar(context?.data?.typeStar);
  const uid = userFrom?.uid;
  const href = !isCustomerStar && uid ? `/profile/${uid}` : undefined;
  const userName = userFrom.id === ownId
    ? formatMessage(messages.me)
    : getFullName(userFrom, 'firstName', 'lastName');

  return {
    href,
    imageSrc: buildImageUrl({
      code: userFrom?.profileImageCode,
      height: 27,
      width: 27,
    }),
    text: formatMessage(messages.by),
    userName,
  };
};

export const getStarTitleProps = (context) => {
  const star = context?.data?.starMeUpOrganizationStar;

  return {
    color: star?.backgroundColor,
    imageSrc: buildImageUrl({
      code: star?.imageCode,
      width: 36,
      height: 36,
    }),
    text: star?.name,
  };
};

export const getUserAvatarListProps = (props, context, themeHashtag) => {
  const {
    listUsersKudos,
    quantityUsersKudos,
    quantityNotShowed,
  } = context?.data;
  const { ownId } = props;
  const usersToShow = quantityUsersKudos - quantityNotShowed;
  const users = listUsersKudos.slice(0, usersToShow).map(user => ({
    ...user,
    src: buildImageUrl({
      code: user?.profileImageCode,
      height: 56,
      width: 56,
    }),
  }));

  const AvatarPopup = (props) => {
    const isOwner = String(ownId) === String(props?.id);
    const profileUrl = `/profile/${props?.uid}`;
    const popupUser = {
      avatarUrl: buildImageUrl({
        code: props?.profileImageCode,
        height: 56,
        width: 56,
      }),
      job: props?.job,
      name: props?.fullname,
    };

    return (
      <UserPopup
        href={profileUrl}
        owner={isOwner}
        trigger={
          <Avatar
            className={cn('bg-white', {
              'border-2': themeHashtag?.color,
            })}
            height={56}
            src={popupUser.avatarUrl}
            style={{
              borderColor: themeHashtag?.color,
              ...(props?.style || {}),
            }}
            width={56}
          >
            {props.children}
          </Avatar>
        }
        user={popupUser}
        userId={props?.id}
      />
    );
  };

  return {
    component: AvatarPopup,
    legacy: false,
    total: quantityUsersKudos,
    users,
    width: 56,
  };
};
