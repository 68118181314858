import {
  GET_HIGHLIGHTED_STAR,
  GET_HIGHLIGHTED_STAR_SUCCESS,
  GET_HIGHLIGHTED_STAR_FAIL,
} from './actionTypes';

const initialState = {
  highlightedStar: undefined,
  fetching: false,
  error: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_HIGHLIGHTED_STAR:
      return {
        ...state,
        fetching: true,
      };
    case GET_HIGHLIGHTED_STAR_SUCCESS:
      return {
        ...state,
        fetching: false,
        highlightedStar: payload,
      };
    case GET_HIGHLIGHTED_STAR_FAIL:
      return {
        ...state,
        fetching: false,
        highlightedStar: undefined,
      };
    default:
      return state;
  }
}
