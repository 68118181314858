// @ packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @ own
import './styles.scss';

const handleClick = (ev, close, onClick) => {
  close();
  if (onClick) {
    onClick(ev);
  }
};

const ToastNotificationsAction = ({
  children,
  className,
  close,
  onClick,
}) => (
  <span
    className={cn('toast-notifications-actions', className)}
    onClick={ev => handleClick(ev, close, onClick)}
  >
    {children}
  </span>
);

ToastNotificationsAction.defaultProps = {
  className: '',
};

ToastNotificationsAction.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default ToastNotificationsAction;
