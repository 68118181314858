import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import escapeHtml from 'utils/escapeHtml';
import { addParams, times } from 'api';

export function searchApi(params) {
  const { searchString } = params;
  const escapedQuery = escapeHtml(searchString);
  const withHashtag = /\s*#(.*)/;
  const filterType = escapedQuery.match(withHashtag) ? 'HASHTAG' : params?.filterType;
  const stringToSearch = escapedQuery.match(withHashtag)
    ? escapedQuery.match(withHashtag)[1]
    : escapedQuery;
  return apiInstance({
    url: addParams('/v2/stellar/locate', {
      ...params,
      ...times(),
      filterType,
      searchString: stringToSearch,
    }),
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}
