// @package
import React from 'react';
import PropTypes from 'prop-types';
import MainSearchApi from 'smu-app-components/MainSearchApi';
import trim from 'lodash/trim';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import { ENABLED_DISCOVERY_PEOPLE } from 'services/communityConfigs/constants';
import { actions as routeActions, urls } from 'utils/routes';
import { castStringToBoolean } from 'utils/formatString';
import { getCommunityConfigValue } from 'services/communityConfigs/selectors';
import { searchApi } from './api';

// @own
import messages from './messages';
import { CATEGORY_SEARCH, MAIN_SEARCH_ID } from './constants';

function MainSearch({
  enabledDiscoveryPeople,
  intl: { formatMessage },
  showDiscoveryPeople,
  router: { push },
  searchValue,
  showProfile,
}) {
  const handleDiscoverPeopleClick = () => {
    trackEvent({
      action: 'discover',
      category: CATEGORY_SEARCH,
      label: 'click',
    });
    showDiscoveryPeople();
  };

  const handleAdvanceSearchclick = () => {
    trackEvent({
      action: 'advanced',
      category: CATEGORY_SEARCH,
      label: 'click',
    });

    let q = '';
    if (searchValue && trim(searchValue)) {
      q = `?fullName=${trim(searchValue)}`;
    }
    push(`${urls.advancedSearch}${q}`);
  };

  const handleOnItemClick = ({ hashtag, uid }) => {
    if (hashtag) {
      trackEvent({
        action: 'click_hashtag',
        category: CATEGORY_SEARCH,
      });
      push(`/hashtags/${hashtag}`);
    } else {
      trackEvent({
        action: 'click_colleague',
        category: CATEGORY_SEARCH,
      });
      showProfile(uid);
    }
  };

  const getFilters = (defaultFilters) => ({
    filterType: 'ALL',
    page: defaultFilters?.page,
    searchString: defaultFilters?.searchValue,
    size: defaultFilters?.limit,
  });

  const getActions = () => {
    let actions = [];
    if (enabledDiscoveryPeople) {
      actions.push({
        onClick: handleDiscoverPeopleClick,
        size: 'medium',
        text: formatMessage(messages.mainSearchActionDiscover),
        variant: 'outline',
      });
    }
    actions.push({
      onClick: handleAdvanceSearchclick,
      size: 'medium',
      text: formatMessage(messages.mainSearchActionAdvanced),
    });

    return actions;
  };

  const texts = {
    colleagues: formatMessage(messages.mainSearchColleagues),
    emptySubtitle: formatMessage(messages.mainSearchEmptyStateSubtitle),
    emptyTitle: formatMessage(messages.mainSearchEmptyStateTitle),
    hashtags: formatMessage(messages.mainSearchHashtags),
    placeholder: formatMessage(messages.mainSearchPlaceholder),
  };

  return (
    <MainSearchApi
      actions={getActions()}
      api={searchApi}
      cacheOptions
      getFilters={getFilters}
      id={MAIN_SEARCH_ID}
      onHashtagClick={handleOnItemClick}
      onUserClick={handleOnItemClick}
      texts={texts}
      type="SMU"
    />
  );
}

MainSearch.propTypes = {
  enabledDiscoveryPeople: PropTypes.bool,
  intl: PropTypes.object,
  router: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { mainSearch } = state;
  return ({
    enabledDiscoveryPeople: castStringToBoolean(
      getCommunityConfigValue(state, ENABLED_DISCOVERY_PEOPLE),
    ),
    searchValue: mainSearch?.searchValue,
  });
};

const actions = {
  showProfile: profileId => routeActions.profile({ profileId }),
  showDiscoveryPeople: routeActions.discover,
};

export default withRouter(connect(mapStateToProps, actions)(injectIntl(MainSearch)));
