// @packages
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { convertFromRaw, EditorState } from 'draft-js';

// @app
import { DEFAULT_STAR, MAX_COMMENT_CHARACTERS } from 'services/sendStarFlow/constants';
import { getB64DecodeUnicode } from 'utils/base64';

export const entitiesAmount = (entities) => {
  const amount = entities?.reduce((acc, entity) => {
    const { customizable, users = [] } = entity;
    if (customizable && !isEmpty(users)) {
      const amountUserChecked = users.filter(u => u.checked).length;
      acc += amountUserChecked;
    } else {
      acc += (entity.amount || 1);
    }
    return acc;
  }, 0);
  return amount;
};

export const hasStarsToSend = (remainingStars) => {
  const {
    hasKudosRemaining = undefined,
    hasUnlimitedStars = undefined,
    remainingStarCounter = {},
  } = remainingStars;

  return hasKudosRemaining ||
  (hasUnlimitedStars || (isEmpty(remainingStarCounter)
    ? false
    : remainingStarCounter.remaining > 0)
  );
};

export const isCoreValueDisabled = (remainingStars, isKudos = false) => {
  const {
    hasKudosRemaining = undefined,
    hasUnlimitedStars = undefined,
    remainingStarCounter = {},
  } = remainingStars;

  const disabled = isKudos ?
    !hasKudosRemaining :
    (hasUnlimitedStars
      ? !hasUnlimitedStars
      : isEmpty(remainingStarCounter)
        ? true
        : remainingStarCounter.remaining <= 0
    );

  return disabled;
};

export const getAvailableStar = (remainingStars, users, stars) => {
  const {
    hasKudosRemaining = undefined,
    hasUnlimitedStars = undefined,
    remainingStarCounter = {},
  } = remainingStars;

  if (hasUnlimitedStars) {
    return {
      star: DEFAULT_STAR,
    };
  } else {
    const { remaining } = remainingStarCounter;
    const starsAmount = remaining - entitiesAmount([...users]);
    const hasEnoughStars = starsAmount >= 0;
    if (hasEnoughStars) {
      return {
        remainingCounter: starsAmount,
        star: DEFAULT_STAR,
      };
    } else if (hasKudosRemaining) {
      const kudosStar = find(stars, star => star.isKudos);
      return {
        remainingCounter: remaining - 1,
        star: kudosStar,
      };
    } else {
      return { };
    }
  }
};

export const normalizeUsers = (users) => map(users,
  (user) => ({
    ...user,
    amount: 1,
    id: String(user.id),
    name: user.name || `${user.firstName} ${user.lastName}`,
    type: 'USER',
  }));

export const normalizeMentions = (mentions) => map(mentions,
  (mention) => ({
    key: mention?.fullName,
    userId: mention?.id,
  }));

export const normalizeComment = (comment = '') =>
  comment.substring(0, MAX_COMMENT_CHARACTERS);

export const getHashtagsState = (text = '') =>
  text.split(' ').reduce(
    (acc, cur, index) => {
      if (cur.startsWith('#')) {
        acc.entityRanges.push({
          offset: acc.offsetText.length + 1,
          length: cur.length,
        });
        acc.entityMap.push({ type: '#mention', mutability: 'IMMUTABLE' });
      }
      acc.offsetText += index ? ` ${cur}` : cur;
      return acc;
    },
    { entityRanges: [], entityMap: [], offsetText: '' },
  );

  export const getUsersState = (text = '', mentions = []) =>
  mentions.reduce(
    (acc, cur) => {
      let tempText = acc.offsetText.split(`@[${cur.id}]`, 1)[0];
      acc.offsetText = acc.offsetText.replace(`@[${cur.id}]`, cur.fullName);
      acc.entityRanges.push({
        offset: tempText.length,
        length: cur.fullName.length,
      });
      acc.entityMap.push({
        type: 'mention',
        mutability: 'IMMUTABLE',
        data: {
          mention: {
            id: cur.id,
            name: cur.fullName,
            profileImageCode: cur.profileImageCode,
          },
        },
      });
      return acc;
    },
    {
      entityRanges: [],
      entityMap: [],
      offsetText: text,
    },
  );

export const getEditorStateByComment = (comment, mentions) => {
  const users = getUsersState(comment, mentions);
  const hashtags = getHashtagsState(comment);
  const entityRanges = [...users.entityRanges, ...hashtags.entityRanges];
  const entityMap = [...users.entityMap, ...hashtags.entityMap];

  const rawContent = {
    blocks: [
      {
        key: 0,
        text: users.offsetText,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: entityRanges.reduce((acc, cur, key) => {
          acc.push({ ...cur, key });
          return acc;
        }, []),
        data: {},
      },
    ],
    entityMap: entityMap.reduce((acc, cur, key) => {
      acc[key] = cur;
      return acc;
    }, {}),
  };

  const content = convertFromRaw(rawContent);
  return EditorState.createWithContent(content);
};

export const normalizeMembers = (members) =>
  members?.map((user) => ({
    amount: 1,
    id: user.id,
    identification: user.identification,
    name: `${user.firstName} ${user.lastName}`,
    profileImageCode: user.imageCode,
    type: 'USER',
  }));

export const getCriteriaUserIds = (criteria) => {
  try {
    const str = getB64DecodeUnicode(criteria);
    const json = JSON.parse(str);
    return json?.to;
  } catch {
    return undefined;
  }
};

export const normalizeEntity = (user) => ({
  amount: 1,
  id: user.id,
  identification: user.identification,
  name: `${user.firstName} ${user.lastName}`,
  profileImageCode: user.profileImageCode,
  type: 'USER',
});

export const getWriteForMeProps = ({
  star,
  baseText,
  onChange,
  disabled,
  entities,
}) => ({
  disabled,
  onChange: (text) => onChange(getEditorStateByComment(text, [])),
  value: baseText,
  suggestionParams: {
    type: 'recognition',
    additionalInformation: {
      baseText,
      coreValue: {
        name: star?.nameShowed,
        description: star?.descriptionShowed,
      },
      to: entities?.[0]?.id,
      toUsers: entities?.map((entity) => entity?.id),
    },
  },
});
