// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { actionItemsUpdateMultiple } from 'services/items/actions';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectRequest } from 'smu-utils/reduxRequests/selectors';
import { selectProfileFirstname } from 'smu-unified-profile/services/profile/selectors';

// @app
import BethereCardDetails from 'components/BethereCardDetails';
import InfiniteScroll from 'components/InfiniteScroll';
import SMUEmptyState from 'components/SMUEmptyState';
import SkeletonOSFeed from 'components/Skeletons/OSFeed';
import {
  selectCommunityId,
  selectSessionUserId,
  selectSession,
} from 'containers/Authorization/selectors';
import { selectFeedPosts } from './selectors';

// @own
import './styles.scss';
import { apiGetFeed, apiGetTaggedFeed } from './api';
import {
  EMPTY_STATE_PROPS,
  OTHER_PROFILE,
  POSTS,
  PROFILE,
  REQUEST_ID_FEED,
  TAGGED,
} from './constants';
import messages from './messages';

function Feed({
  actionItemsUpdateMultiple,
  actionRequestInit,
  excludeSponsored,
  filters,
  firstName,
  intl: { formatMessage },
  orgId,
  posts,
  requestFeed,
  scrollableTarget,
  sessionId,
  size,
  tagged,
  title,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const prevRequesting = usePrevious(requestFeed.requesting);
  const data = requestFeed?.result?.posts || [];
  const totalItems = requestFeed?.result?.total_items || 0;
  const page = requestFeed?.result?.page || 0;
  const isEmpty = !data?.length && (!isLoading || requestFeed.error);
  const isOwnProfile = sessionId === filters?.userId;
  const isIsolated = !!scrollableTarget;

  function interceptResponse(response) {
    let posts = response?.posts || [];

    if (response.page) {
      const currentPosts = data || [];
      const newPosts = response?.posts || [];
      posts = [...currentPosts, ...newPosts];
    }

    return {
      ...response,
      posts,
    };
  }

  const onSuccess = (result) => {
    const posts = result?.posts;
    const items = posts?.map((post) => ({
      id: post?.post_id,
      data: {
        post,
        postInteractions: post?.comments,
      },
    }));

    if (items?.length) actionItemsUpdateMultiple(items);
  };

  function getFeed(page = 0) {
    if (!page && !isLoading) {
      setIsLoading(true);
    }
    actionRequestInit({
      api: tagged ? apiGetTaggedFeed : apiGetFeed,
      id: REQUEST_ID_FEED,
      interceptResponse,
      onSuccess,
      params: {
        excludeSponsored,
        orgId,
        page,
        size,
        ...filters,
      },
    });
  }

  function loadMore() {
    if (!requestFeed.requesting) {
      getFeed(page + 1);
    }
  }

  useEffect(() => {
    getFeed(0);
  }, [tagged, filters?.userId, filters?.text]);

  useEffect(() => {
    if (prevRequesting && !requestFeed?.requesting) {
      setIsLoading(false);
    }
  }, [prevRequesting, requestFeed?.requesting]);

  function buildEmptyState() {
    if (isIsolated) {
      const emptyStateProps =
        EMPTY_STATE_PROPS[isOwnProfile ? PROFILE : OTHER_PROFILE][tagged ? TAGGED : POSTS];
      return (
        <SMUEmptyState
          imageSrc={emptyStateProps?.src}
          subtitle={emptyStateProps?.subtext && formatMessage(messages[emptyStateProps?.subtext])}
          title={
            isOwnProfile
              ? formatMessage(messages[emptyStateProps?.text])
              : formatMessage(messages[emptyStateProps?.text], { firstName })
          }
        />
      );
    }
    return formatMessage(messages.bethereFeedEmpty);
  }

  return (
    <div className="bt-feed">
      {isLoading ? (
        <SkeletonOSFeed media />
      ) : isEmpty ? (
        <div className="bt-feed__empty">{buildEmptyState()}</div>
      ) : (
        <>
          {title && (
            <div className="bt-feed__header">
              <span className="bt-feed__header-title">{title}</span>
              <span className="bt-feed__header-total">
                {totalItems} {formatMessage(messages.bethereFeedResults)}
              </span>
            </div>
          )}
          <InfiniteScroll
            className="space-y-3"
            dataLength={data.length}
            hasMore={data.length < totalItems}
            loader={null}
            loadingType="starmeup"
            next={loadMore}
          >
            {posts.map((post) => (
              <BethereCardDetails
                key={post.post_id}
                post={post}
                postLocation={post.location}
                showComments
              />
            ))}
            {data.length < totalItems && <SkeletonOSFeed media items={1} />}
          </InfiniteScroll>
        </>
      )}
    </div>
  );
}

Feed.defaultProps = {
  excludeSponsored: true,
  showNewPost: false,
  size: 10,
  title: '',
};

Feed.propTypes = {
  actionRequestInit: PropTypes.func.isRequired,
  excludeSponsored: PropTypes.bool,
  filters: PropTypes.shape({
    text: PropTypes.string,
    userId: PropTypes.number,
  }),
  intl: PropTypes.object.isRequired,
  orgId: PropTypes.number,
  posts: PropTypes.array,
  requestFeed: PropTypes.object,
  scrollableTarget: PropTypes.object,
  session: PropTypes.object.isRequired,
  sessionId: PropTypes.number,
  showNewPost: PropTypes.bool,
  size: PropTypes.number,
  tagged: PropTypes.bool,
  title: PropTypes.string,
};

const mapStateToProps = (state) => ({
  firstName: selectProfileFirstname(state),
  orgId: selectCommunityId(state),
  posts: selectFeedPosts(state),
  requestFeed: selectRequest(state, REQUEST_ID_FEED),
  session: selectSession(state),
  sessionId: selectSessionUserId(state),
});

export default connect(mapStateToProps, {
  actionItemsUpdateMultiple,
  actionRequestInit,
})(injectIntl(Feed));
