// @packages
import React from 'react';
import UserPopup from 'smu-app-components/UserPopup';
import { useSelector } from 'react-redux';

// @app
import { selectSessionUserId } from 'containers/Authorization/selectors';

const UserInfoPopup = ({ userId, userUID, ...rest }) => {
  const ownId = useSelector(selectSessionUserId);
  const isOwner = String(ownId) === String(userId);
  const href = isOwner ? '/me' : `/profile/${userUID || `id/${userId}`}`;

  return (
    <UserPopup
      href={href}
      owner={isOwner}
      showButton
      userId={userId}
      {...rest}
    />
  );
};

export default UserInfoPopup;
