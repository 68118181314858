// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import SendStarSkeleton from 'components/Skeletons/SendStar';
import { ORIGIN } from 'services/sendStarFlow/constants';

// @own
import './styles.scss';
import SendStarError from './SendStarError';
import SendStarForm from './SendStarForm';
import SendStarHeader from './SendStarHeader';

const SendStar = ({
  addEmoji,
  allowMultipleUsers,
  buttonColor,
  className,
  descriptionProps,
  disabledCarbonCopySearch,
  disabledSendStarButton,
  showWriteForMe,
  entities,
  entitiesCC,
  entitiesQuantity,
  hasStarsToSend,
  headerColor,
  image,
  isEditing,
  isLoading,
  onCancel,
  onClickEntity,
  onClickEntityCC,
  onClose,
  onRemoveEntity,
  onSend,
  oneEntity,
  origin,
  remainingstars,
  removeEntityCC,
  showRemainingStarCounter,
  showUserClarification,
  star,
  stars,
  texts,
  togglePicker,
  totalStars,
  writeForMeProps,
  ...rest
}) => (
  <div className={cn('smu-send-star', `smu-send-star__origin-${origin}`, className)}>
    {isLoading ? (
      <SendStarSkeleton />
    ) : hasStarsToSend || isEditing ? (
      <>
        <SendStarHeader
          backgroundColor={headerColor}
          message={texts.sendStars}
          onClose={onClose}
          remainingstars={remainingstars}
          showRemainingStarCounter={showRemainingStarCounter}
          totalStars={totalStars}
        />
        <SendStarForm
          addEmoji={addEmoji}
          allowMultipleUsers={allowMultipleUsers}
          buttonColor={buttonColor}
          descriptionProps={descriptionProps}
          disabledCarbonCopySearch={disabledCarbonCopySearch}
          disabledSearch={disabledCarbonCopySearch}
          disabledSendStarButton={disabledSendStarButton}
          showWriteForMe={showWriteForMe}
          entities={entities}
          entitiesCC={entitiesCC}
          entitiesQuantity={entitiesQuantity}
          image={image}
          isEditing={isEditing}
          onCancel={onCancel}
          onClickEntity={onClickEntity}
          onClickEntityCC={onClickEntityCC}
          onRemoveEntity={onRemoveEntity}
          onRemoveEntityCC={removeEntityCC}
          onSend={onSend}
          oneEntity={oneEntity}
          showUserClarification={showUserClarification}
          star={star}
          stars={stars}
          texts={texts}
          togglePicker={togglePicker}
          writeForMeProps={writeForMeProps}
          {...rest}
        />
      </>
    ) : (
      <SendStarError
        actionMessage={texts?.close}
        errorMessage={texts?.noStarAvailable}
        onActionClick={onCancel}
      />
    )}
  </div>
);

SendStar.propTypes = {
  addEmoji: PropTypes.func,
  allowMultipleUsers: PropTypes.bool,
  buttonColor: PropTypes.string,
  className: PropTypes.string,
  descriptionProps: PropTypes.object,
  disabledCarbonCopySearch: PropTypes.bool,
  disabledSendStarButton: PropTypes.bool,
  entities: PropTypes.array,
  entitiesCC: PropTypes.array,
  entitiesQuantity: PropTypes.number,
  hasStarsToSend: PropTypes.bool,
  headerColor: PropTypes.string,
  image: PropTypes.object,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickEntity: PropTypes.func,
  onClickEntityCC: PropTypes.func,
  onClose: PropTypes.func,
  onRemoveEntity: PropTypes.func,
  onSend: PropTypes.func,
  oneEntity: PropTypes.bool,
  origin: PropTypes.oneOf(ORIGIN),
  remainingstars: PropTypes.number,
  removeEntityCC: PropTypes.func,
  showRemainingStarCounter: PropTypes.bool,
  showUserClarification: PropTypes.bool,
  star: PropTypes.object,
  stars: PropTypes.array,
  texts: PropTypes.object,
  togglePicker: PropTypes.func,
  totalStars: PropTypes.number,
};

export default SendStar;
