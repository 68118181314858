// @packages
import parse from 'url-parse';

// @app
import { objectToGetParams } from 'utils/url';

export function goToQueryModal(modal, router, params) {
  router.push(
    `${router?.location?.pathname}${objectToGetParams({
      modal,
      ...params,
    })}`,
  );
}

export function buildShareModalUrl(params, pathname = '/home') {
  const parsed = parse(window.location.href, true);
  parsed.set('query', params);

  if (pathname) {
    parsed.set('pathname', pathname);
  }

  return parsed.href;
}

export function buildPathname(href) {
  const parsed = parse(href);
  return `${parsed.pathname}${parsed.query}`;
}

export function buildClosePath(removeQuery = []) {
  const parsed = parse(window.location.href, true);
  delete parsed.query.modal;
  removeQuery.forEach(element => {
    delete parsed.query[element];
  });
  let str = parsed.toString();
  let path = str.replace(parsed.origin, '');
  return path;
}

export function buildModalUrl(params, pathname = '/home') {
  const parsed = parse(window.location.href, true);
  parsed.set('query', {
    ...parsed.query,
    ...params,
  });

  if (pathname) {
    parsed.set(pathname);
  }

  const query = parsed?.href.split('?')?.[1];

  return `${parsed.pathname}${query ? `?${query}` : ''}`;
}
