import moment from 'moment';

export const getTimesByInterval = (interval = 30, format = 'HH:mm') => {
  const arr = Array.from(Array(24 * (60 / interval)), (_, i) => i);
  return arr.map((i) =>
    moment()
      .startOf('day')
      .add({ minutes: i * interval })
      .format(format));
};
