// @packages
import React, { useState } from 'react';
import ButtonV2 from 'smu-ui-components/ButtonV2';
import DatePicker from 'smu-ui-components/Datepicker';
import IconV2 from 'smu-ui-components/IconV2';
import InputArea from 'smu-ui-components/InputArea';
import InputText from 'smu-ui-components/InputText';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import SelectV2 from 'smu-ui-components/SelectV2';
import UserAvatarList from 'smu-ui-components/UserAvatarList';

// @app
import {
  MAX_TITLE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
} from 'containers/CreateChillout/constants';
import Title from 'components/Title';

// @own
import './styles.scss';

function CreateChillout({
  date,
  description,
  disableSave,
  endTimes,
  from,
  isLoading,
  onCancel,
  onChangeDate,
  onClose,
  onHiddenClick,
  onSave,
  setDescription,
  setFrom,
  setTitle,
  setTo,
  startTimes,
  texts,
  title,
  to,
  users,
}) {
  const [valueFocused, setValueFocused] = useState(false);

  const modalHeader = () => (
    <div className="chillout-modal__header-wrapper">
      <div className="chillout-modal__header">
        <div className="chillout-modal__header-name">
          {texts.modalTitle}
        </div>
      </div>
      <ButtonV2
        className="chillout-modal__header-close"
        icon
        onClick={onClose}
        role="button"
        transparent
        variant="outline"
      >
        <IconV2 icon="close" size="small" />
      </ButtonV2>
    </div>
  );

  const form = () => (
    <div className="chillout-modal__content">
      <div className="chillout-modal__content-wrapper">
        <Title className="chillout-modal__title">{texts.who}</Title>
        <div className="chillout-modal__who">
          <UserAvatarList
            maxDisplay={5}
            onHiddenClick={onHiddenClick}
            size={50}
            users={users}
          />
        </div>
        <Title className="chillout-modal__title">{texts.when}</Title>
        <div className="chillout-modal__when">
          <div className="chillout-modal__form-date">
            <label className="chillout-modal__form-label">
              {texts.dateLabel}
            </label>
            <DatePicker
              align="right"
              className="chillout-modal__date"
              displayFormat="DD/MM/YYYY"
              onChange={newDate => onChangeDate(newDate)}
              onFocus={(e) => setValueFocused(!e || !!e?.focused)}
              styleInput
              value={date}
              valueFocused={valueFocused}
            />
          </div>
          <div className="chillout-modal__form-hour">
            <label className="chillout-modal__form-label">
              {texts.fromLabel}
            </label>
            <SelectV2
              className="chillout-modal__from"
              disabled={!startTimes?.length}
              onChange={value => setFrom(value)}
              options={startTimes}
              placeholder={texts.fromPlaceholder}
              size="large"
              value={from}
            />
          </div>
          <div className="chillout-modal__form-hour">
            <label className="chillout-modal__form-label">
              {texts.toLabel}
            </label>
            <SelectV2
              className="chillout-modal__to"
              disabled={!endTimes?.length}
              onChange={value => setTo(value)}
              options={endTimes}
              placeholder={texts.toPlaceholder}
              size="large"
              value={to}
            />
          </div>
        </div>
        <Title className="chillout-modal__title">{texts.why}</Title>
        <InputText
          className="chillout-modal__form-title"
          idName="create-chillout-title"
          label={texts.titleLabel}
          maxLength={MAX_TITLE_LENGTH}
          onChange={event => setTitle(event.target.value)}
          placeholder={texts.titlePlaceholder}
          type="text"
          value={title}
        />
        <InputArea
          className="chillout-modal__form-description"
          label={texts.descriptionLabel}
          maxLength={MAX_DESCRIPTION_LENGTH}
          onChange={event => setDescription(event.target.value)}
          placeholder={texts.descriptionPlaceholder}
          rows={6}
          value={description}
        />
        <div className="chillout-modal__form-buttons">
          <ButtonV2
            className="chillout-modal__form-cancel"
            onClick={onCancel}
            size="large"
            variant="outline"
          >
            {texts.cancel}
          </ButtonV2>
          <ButtonV2
            disabled={disableSave}
            onClick={onSave}
            size="large"
          >
            {texts.save}
          </ButtonV2>
        </div>
      </div>
    </div>
  );

  return (
    <div className="chillout-modal">
      {isLoading && (
        <LottieSpinner
          className="chillout-modal__loader"
          height={40}
          loadingType="starmeup"
          width={40}
        />
      )}
      {modalHeader()}
      {form()}
    </div>
  );
}

CreateChillout.propTypes = {
  date: PropTypes.object,
  description: PropTypes.string,
  disableSave: PropTypes.bool,
  endTimes: PropTypes.array,
  from: PropTypes.string,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeDate: PropTypes.func,
  onClose: PropTypes.func,
  onHiddenClick: PropTypes.func,
  onSave: PropTypes.func,
  setDescription: PropTypes.func,
  setFrom: PropTypes.func,
  setTitle: PropTypes.func,
  setTo: PropTypes.func,
  startTimes: PropTypes.array,
  texts: PropTypes.object,
  title: PropTypes.string,
  to: PropTypes.string,
  users: PropTypes.array,
};

export default CreateChillout;
