// @packages
import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { FormattedRelative } from 'react-intl';
import { buildImageUrl } from 'smu-utils/image';
import { trackEvent } from 'smu-utils/gtm';

// @app
import TextParser from 'containers/TextParser';
import messages from 'components/Bethere/messages';
import parseDate from 'utils/parseDate';
import { fullName } from 'utils/user';

export const getSendByProps = ({
  community,
  communityName,
  communityPhoto,
  from,
  intl,
  postUserId,
  postUserUid,
  userAvatar,
}) => {
  const profileByUID = postUserUid && !community ? `/profile/${postUserUid}` : '';
  const profileByID = postUserId && !community ? `/profile/id/${postUserId}` : '';

  return {
    href: profileByUID || profileByID,
    imageSrc: buildImageUrl({
      code: community ? communityPhoto : userAvatar,
      height: 27,
      width: 27,
    }),
    text: intl.formatMessage(messages.betherePostPrefix),
    userName: community ? communityName : from,
  };
};

export const getDateTimeProps = ({ date }) => {
  const dateFormatted = parseDate(
    moment(date).subtract(moment().utcOffset(), 'm'),
  );

  return {
    children: date ? (
      <span className="bt-card__date">
        <FormattedRelative value={dateFormatted.toDate()} />
      </span>
    ) : undefined,
  };
};

export const getMenuProps = (props) => ({
  onDelete: props?.onDelete,
  onTagguedUsers: props?.onTagguedUsers,
  showCopyUrl: true,
  showDelete: props?.isOwnerUser || props?.isCommunityModerator,
  showTaggedUsers: !!props?.tags?.length,
  url: props?.postUrl,
});

export const getCommentListProps = (props) => {
  let allComments = props?.comments;
  if (allComments) {
    allComments = [...allComments].sort(
      (a, b) => b?.pi_creation_date_ts - a?.pi_creation_date_ts,
    );
  }
  const comments = allComments?.map((c) => {
    const dateFormatted = c?.pi_creation_date_ts
      ? parseDate(
          moment(c.pi_creation_date_ts).subtract(moment().utcOffset(), 'm'),
        )
      : undefined;
    const dateTime = dateFormatted ? (
      <FormattedRelative value={dateFormatted} />
    ) : undefined;
    const mentions = c?.mentions?.map((u) => ({
      fullName: u?.user_name,
      id: u?.user_id,
      uid: u?.uid,
    }));
    const userAvatar = buildImageUrl({
      code: c?.user?.user_image,
      width: 27,
      height: 27,
    });
    const userName = fullName(c?.user, 'user_name', 'user_lastname');
    const userProfile = c?.user?.uid ? `/profile/${c?.user?.uid}` : undefined;
    const message = c?.pi_description ? (
      <TextParser
        presetUsers={/@(ID:)?(\[)?[0-9]+(\])?/gi}
        urlProps={{
          externalRedirect: true,
        }}
        users={mentions}
      >
        {c?.pi_description}
      </TextParser>
    ) : undefined;

    return {
      dateTime,
      enabled: !c?.pi_hidden,
      id: c?.pi_id,
      key: c?.uniqueId,
      mentions,
      message,
      userAvatar,
      userName,
      userProfile,
    };
  });
  return {
    comments,
  };
};

export const getDescriptionProps = (props) => ({
  children: props?.description,
  presetUsers: /@(ID:)?(\[)?[0-9]+(\])?/gi,
  urlProps: {
    externalRedirect: !props?.sponsored,
  },
  users: props?.mentions?.map((u) => ({
    fullName: fullName(u, 'user_name', 'user_lastname'),
    id: u?.user_id,
    uid: u?.uid,
  })),
});

export const getMediaProps = (props) => ({
  alwaysShowMedia: props?.alwaysShowMedia,
  className: cn('overflow-hidden', {
    'rounded-lg': !props?.sponsored,
  }),
  files: props?.files,
  href: props?.actionLink,
  legacy: props?.actionLink ? false : undefined,
  overlay: props?.overlay,
  onClick: () => {
    if (props?.sponsored && props?.actionLink) {
      trackEvent({
        action: 'click',
        category: 'card_activity',
        id: props.postId,
        label: 'tap_image_link',
        link: props?.actionLink,
        type: 'sponsored_content',
      });
    }
  },
});
