/* eslint-disable react/no-danger */
// @packages
import React from 'react';
import PropTypes from 'prop-types';
import xss from 'xss';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';

function QuestionnaireIntroduction({ description, title }) {
  return (
    <div className="questionnaires-flex-intro">
      <div
        className="questionnaires-flex-intro__title"
        dangerouslySetInnerHTML={{ __html: xss(title) }}
      />
      <div className="questionnaires-flex-intro__divider" />
      {description && (
        <div
          className="questionnaires-flex-intro__description"
          dangerouslySetInnerHTML={{ __html: xss(description) }}
        />
      )}
    </div>
  );
}

QuestionnaireIntroduction.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(QuestionnaireIntroduction);
