// @own
import {
  TYPE_ADMIN,
  TYPE_ADMIN_V2,
  TYPE_ANALYTICS,
  TYPE_ANALYTICS_V2,
} from './constants';

export function getItemTarget(item) {
  switch (item?.type) {
    case TYPE_ADMIN:
    case TYPE_ADMIN_V2:
    case TYPE_ANALYTICS:
    case TYPE_ANALYTICS_V2:
      return '_blank';
    default:
      return undefined;
  }
}
