// @own
import TypeBadge from './types/Badge';
import TypeBeHealthyInsight from './types/BeHealthyInsight';
import TypeDNA from './types/DNA';
import TypeStar from './types/Star';
import TypeYearInReview from './types/YearInReview';
import {
  TYPE_BADGE,
  TYPE_BEHEALTHY_INSIGHT,
  TYPE_DNA,
  TYPE_STAR,
  TYPE_YEAR_IN_REVIEW,
} from './constants';

const config = {
  [TYPE_BADGE]: {
    component: TypeBadge,
    height: 350,
    width: 576,
  },
  [TYPE_BEHEALTHY_INSIGHT]: {
    component: TypeBeHealthyInsight,
    height: 375,
    width: 750,
  },
  [TYPE_DNA]: {
    component: TypeDNA,
    height: 727,
    width: 1200,
  },
  [TYPE_STAR]: {
    component: TypeStar,
    height: 350,
    width: 564,
  },
  [TYPE_YEAR_IN_REVIEW]: {
    component: TypeYearInReview,
    height: 1024,
    width: 1024,
  },
};

export default config;
