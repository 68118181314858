// @packages
import axios from 'axios';
import { stringify } from 'querystring';
import { trackEvent } from 'smu-utils/gtm';
import { getToken } from 'smu-utils/storage';
import omit from 'lodash/omit';

// @app
import { errorHandler } from 'utils/errorHandler';
import { formData } from 'api';
import { OSMemberApiInstance, defaultApi, apiInstance } from 'utils/requestHelper';

function textSanitize(uncheckedString) {
  const regex =
    /<\s*script[.*\s*]>|<\s*\/\s*script\s*>|&lt;\s*script[.*\s*]&gt;|&lt;\s*\/\s*script\s*&gt;/i;
  let parsedString = uncheckedString.replace(regex, '');
  return parsedString;
}

let osFeedRequest = null;
export function getOsFeed(params) {
  trackEvent({
    action: 'load',
    category: 'feed',
    label: params?.page || 0,
  });

  if (osFeedRequest) {
    osFeedRequest.cancel();
  }

  osFeedRequest = axios.CancelToken.source();

  const initTime = Date.now();

  return new Promise((resolve, reject) => {
    OSMemberApiInstance({
      cancelToken: osFeedRequest.token,
      params,
      url: '/api/v1/community/member/feed',
    })
      .then(({ data }) => {
        if (!params?.page) {
          trackEvent({
            event: 'trackTiming',
            timingCategory: 'feed',
            timingValue: Date.now() - initTime,
            timingVar: 'load',
          });
        }
        return resolve(data);
      })
      .catch(
        (thrown) => !axios.isCancel(thrown) && reject(errorHandler(thrown)),
      );
  });
}

export function getOsFeedHighlights(api, params) {
  return api({
    url: '/v2/stellar/highlights',
    params,
  })
    .then(({ data }) => data.result.starsOnFire)
    .catch(errorHandler);
}

export function postOsFeedComment(api, comments, id) {
  return api({
    url: `/v2/stellar/stars/${id}/comments`,
    method: 'post',
    data: stringify({
      comments: textSanitize(comments),
    }),
    ...formData,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function postOsFeedLike(api, id) {
  return api({
    url: `/v2/stellar/stars/${id}/likes`,
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function postOsFeedRemoveLike(api, likeId) {
  return api({
    url: `/v2/stellar/likes/${likeId}/remove`,
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function postOsFeedLoadImage({ api, imageCode, id }) {
  return api({
    url: `/v2/stellar/stars/${id}/image/${imageCode}`,
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function osFeedUploadImage({ api, image }) {
  const formData = new FormData();
  formData.append('token', getToken());
  formData.append('file', image);
  formData.append('fileName', image.name);

  return api({
    url: '/v2/image/insert/image',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function postOsFeedModerateComment({ id, status }) {
  const enabled = status ? 'hide' : 'show';
  return apiInstance({
    url: `/v2/stellar/comments/${id}/${enabled}`,
    method: 'post',
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}

export function postOsFeedPreferenceV2({ osFeed }) {
  return OSMemberApiInstance({
    url: '/api/v1/community/member/feed/properties',
    method: 'post',
    data: {
      property: 'OS_FEED',
      propertyValue: osFeed,
    },
  })
    .then(({ data }) => data.propertyValue)
    .catch(errorHandler);
}

export function getOsFeedPreferenceV2() {
  return OSMemberApiInstance({
    url: '/api/v1/community/member/feed/properties',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function postOsFeedVistitedPostsApi(data) {
  return defaultApi({
    url: '/interactions/api/v1/impressions',
    params: {
      application: 'feed',
    },
    data: [...data?.map(u => omit(u, 'id'))],
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
