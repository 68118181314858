import {
  GET_EULA,
  GET_EULA_SUCCESS,
  GET_EULA_ERROR,
  SAVE_EULA,
  SAVE_EULA_SUCCESS,
  SAVE_EULA_ERROR,
} from './actionTypes';

const initialState = {
  fetching: false,
  feetchingSave: false,
  error: undefined,
  eula: undefined,
  result: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_EULA:
      return {
        ...state,
        fetching: true,
      };
    case GET_EULA_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: '',
        eula: payload,
      };
    case GET_EULA_ERROR:
      return {
        ...state,
        fetching: false,
        error: payload,
        eula: undefined,
      };
    case SAVE_EULA:
      return {
        ...state,
        feetchingSave: true,
      };
    case SAVE_EULA_SUCCESS:
      return {
        ...state,
        feetchingSave: false,
        error: '',
        result: payload,
      };
    case SAVE_EULA_ERROR:
      return {
        ...state,
        feetchingSave: false,
        error: payload,
        result: undefined,
      };
    default:
      return state;
  }
}
