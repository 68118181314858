// @packages
import React, { useEffect, useMemo } from 'react';
import BreadcrumbHeader from 'smu-ui-components/BreadcrumbHeader';
import Container from 'smu-layout-components/Container';
import OneOnOneDetailAPP from 'smu-app-components/OneOnOne/Detail';
import PropTypes from 'prop-types';
import UtilsProvider from 'smu-app-components/UtilsProvider';
import { injectIntl } from 'react-intl';
import { useOneOnOneDetail } from 'smu-services/briefme/api/v1/community/member/one-to-one/meets/[id]';
import { useSelector } from 'react-redux';

// @app
import {
  CALENDAR_PROVIDER,
  ENABLED_ONE_ON_ONE,
  ENABLED_ONE_ON_ONE_TP_AI_SUGGESTIONS,
} from 'services/communityConfigs/constants';
import {
  getCommunityConfigValue,
  getCommunityConfigValueBool,
} from 'services/communityConfigs/selectors';

// @own
import messages from './messages';
import { trackEventBackClick } from './analytics';

const OneOnOneDetail = ({ intl: { formatMessage }, params, router }) => {
  const id = params?.id;
  const [enabledOneOnOne, enabledOneOnOneTPAISuggestions, calendarProvider] = useSelector(
    state => [
      getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
      getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE_TP_AI_SUGGESTIONS),
      getCommunityConfigValue(state, CALENDAR_PROVIDER),
    ],
  );
  const { error, isLoading, isValidating, selectUser } = useOneOnOneDetail({
    id,
    shouldFetch: enabledOneOnOne,
  });
  const user = selectUser();

  const crumbs = useMemo(() => {
    const crumbs = [
      {
        onClick: trackEventBackClick,
        text: formatMessage(messages.breadcrumbHeader.crumbs.first),
        url: '/1on1',
      },
      {
        isLoading: isLoading || (isValidating && error),
        text: `${formatMessage(
          messages.breadcrumbHeader.crumbs.second,
        )} ${user?.firstName} ${user?.lastName}`,
      },
    ];

    if (error && !isValidating) {
      crumbs.pop();
    }

    return crumbs;
  }, [error, user]);

  const handleBreadcrumbHeaderArrowClick = () => {
    trackEventBackClick();
    router.goBack();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const utils = {
    calendarProvider,
    enableAddToCalendar: true,
    enableMeetingRoom: true,
    enableSyncWithCalendar: true,
    enableTalkingPointsAISuggestions: enabledOneOnOneTPAISuggestions,
    enabledSideBarPendingActions: true,
    mainFeatureIsEnabled: enabledOneOnOne,
  };

  return (
    <div className="pt-16">
      {enabledOneOnOne && (
        <div className="sticky top-[64px] w-full z-10">
          <BreadcrumbHeader
            breadcrumbsClassName="w-full max-w-[1260px] mx-auto"
            className="p-5"
            crumbs={crumbs}
            innerShadow
            legacy={false}
            onArrowClick={handleBreadcrumbHeaderArrowClick}
            rounded={false}
          />
        </div>
      )}
      <Container centered>
        <div className="py-8">
          <UtilsProvider utils={utils}>
            <OneOnOneDetailAPP id={Number(id)} />
          </UtilsProvider>
        </div>
      </Container>
    </div>
  );
};

OneOnOneDetail.propTypes = {
  intl: PropTypes.object,
  params: PropTypes.object,
  router: PropTypes.object,
};

export default injectIntl(OneOnOneDetail);
