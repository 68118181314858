import { put, takeEvery } from 'redux-saga/effects';

import {
  getCommunityCounters,
  getCommunityCountersSuccess,
  getCommunityCountersError,
} from './actions';
import { getCommunityCounters as getCommunityCountersAPI } from './api';

function* getCommunityCountersWorker() {
  try {
    const { result } = yield getCommunityCountersAPI();
    yield put(getCommunityCountersSuccess(result));
  } catch (error) {
    yield put(getCommunityCountersError(error));
  }
}

function* getCommunityCountersWatcher() {
  yield takeEvery(getCommunityCounters().type, getCommunityCountersWorker);
}

export default {
  getCommunityCountersWatcher,
};
