import { defineMessages } from 'react-intl';

export default defineMessages({
  generalMessagesFirst: {
    id: 'generalMessages.first',
    defaultMessage: 'First',
  },
  generalMessagesLast: {
    id: 'generalMessages.last',
    defaultMessage: 'Last',
  },
});
