// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { add } from 'smu-app-components/ToastNotifications/actions';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

// @app
import BethereCardDetails from 'components/BethereCardDetails';
import ModalStarDetails from 'components/Modals/StarDetails';
import useRequest from 'hooks/useRequest';
import { DEFAULT_TOAST_TIMEOUT } from 'utils/constants';
import { actionItemsUpdate } from 'services/items/actions';
import { apiGetPostById } from 'containers/withCard/api';
import { selectCommunityId } from 'containers/Authorization/selectors';
import { selectItemById } from 'services/items/selectors';

// @own
import messages from './messages';
import { eventOnOpenModal, eventOnCloseModal } from './analytics';

const ModalPostDetailsContainer = ({
  cardId,
  className,
  intl,
  onClose,
  open,
}) => {
  const dispatch = useDispatch();
  const item = useSelector((state) => selectItemById(state, cardId));
  const orgId = useSelector(selectCommunityId);
  const isDeleted = item?.post?.deleted;
  const requestRetails = useRequest({
    api: apiGetPostById,
    id: `POST_DETAILS_${cardId}`,
  });

  const showNotFound = () =>
    dispatch(
      add({
        message: intl.formatMessage(messages.notFound),
        timeout: DEFAULT_TOAST_TIMEOUT,
      }),
    );

  useEffect(() => {
    requestRetails.fetch({
      onError: onClose,
      onSuccess: (data) => dispatch(actionItemsUpdate(cardId, data)),
      params: {
        orgId,
        cardId,
      },
    });
  }, [cardId, orgId]);

  useEffect(() => {
    if (isDeleted) {
      showNotFound();
      onClose();
    }
  }, []);

  useEffect(() => {
    eventOnOpenModal();
    return () => eventOnCloseModal();
  }, []);

  if (isDeleted) {
    return null;
  }

  return (
    <ModalStarDetails className={className} onClose={onClose} open={open}>
      <BethereCardDetails
        alwaysShowMedia
        cardId={cardId}
        expanded
        onRemove={onClose}
        showComments
        showTags
      />
    </ModalStarDetails>
  );
};

ModalPostDetailsContainer.propTypes = {
  cardId: PropTypes.number,
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default injectIntl(ModalPostDetailsContainer);
