// @packages
import React from 'react';
import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';
import cn from 'classnames';

function MentionsParser({
  className,
  mentions,
  redirectToHashtag,
  redirectToProfile,
  text,
}) {
  function extractText(str, prefix) {
    const split = str.split(prefix);
    const firstValue = split[1] ? split[1].split(' ') : [];
    return firstValue ? firstValue[0] : '';
  }

  function buildUsername(user) {
    return `${user.user_name}${
      user.user_lastname ? ` ${user.user_lastname}` : ''
    }`;
  }

  function buildUser(str, key) {
    if (/@ID:/.test(str)) {
      const id = extractText(str, '@ID:')?.match(/[0-9]/g)?.join('');
      const interaction = mentions.find(i => id.includes(i.user_id));
      const userName = buildUsername(interaction);

      const mentionParsed = (_, key) => (
        <span
          className="mentions-parser__item mentions-parser__item--user"
          key={`user-${key}`}
          onClick={e => redirectToProfile(e, interaction.uid)}
        >
          {' '}
          {interaction ? userName : str}
        </span>
      );

      return (
        <span key={key} className="bt-interactions-parser__item">
          {reactStringReplace(str, /(\@ID:[0-9]+\b)/g, mentionParsed)}
          &nbsp;
        </span>
      );
    }
    return '';
  }

  function buildHashtag(str) {
    if (/#/.test(str)) {
      const regexMatchHashtag = /(\#[a-zA-Z-1-9]+\b)/g;
      const hashtagParsed = (hashtag, key) => {
        const hashtagWithoutSymbol = extractText(hashtag, '#');
        return (
          <span
            className="mentions-parser__item mentions-parser__item--hashtag"
            key={`hashtag-${key}`}
            onClick={e => redirectToHashtag(e, hashtagWithoutSymbol)}
          >
            {hashtag}
          </span>);
      };

      return (
        <span className="bt-interactions-parser__item">
          {reactStringReplace(str, regexMatchHashtag, hashtagParsed)}
          &nbsp;
        </span>
      );
    }

    return '';
  }

  const split = text
    .replace(/(?:\r\n|\r|\n)/g, ' ')
    .split(' ')
    .reduce((acc, cur, key) => {
      const hashtag = buildHashtag(cur, key);
      const user = buildUser(cur, key);

      if (hashtag) {
        acc.push(hashtag);
      } else if (user) {
        acc.push(user);
      } else if (cur) {
        acc.push(
          <span key={`item-${key}`} className="mentions-parser__item">
            {' '}
            {cur}
          </span>,
        );
      }

      return acc;
    }, []);

  return <p className={cn('mentions-parser', className)}>{split}</p>;
}

MentionsParser.defaultProps = {
  className: '',
  hashtagPrefix: '#',
  mentions: [],
  redirectToHashtag: () => undefined,
  redirectToProfile: () => undefined,
  text: '',
  userPrefix: '@ID:',
};

MentionsParser.propTypes = {
  className: PropTypes.string,
  hashtagPrefix: PropTypes.string,
  mentions: PropTypes.array,
  redirectToHashtag: PropTypes.func,
  redirectToProfile: PropTypes.func,
  text: PropTypes.string,
  userPrefix: PropTypes.string,
};

export default MentionsParser;
