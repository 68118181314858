import { defineMessages } from 'react-intl';

export default defineMessages({
  tellExperienceDescription: {
    id: 'footer.reportBug',
    defaultMessage:
      'Did you find a bug? Save a developer and report it!! Our team will be glad to hear all feedback!',
  },
  send: {
    id: 'footer.send',
    defaultMessage: 'SEND',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'CANCEL',
  },
  yourName: {
    id: 'footer.yourName',
    defaultMessage: 'Your name',
  },
  tellExperience: {
    id: 'footer.tellExperience',
    defaultMessage: 'Tell us about your experience',
  },
});
