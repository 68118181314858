// @packages
import React from 'react';
import ActionShare from 'smu-ui-components/Cards/components/ActionShare';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @own
import messages from './messages';

const StarActionShareContainer = ({
  intl,
  onClickIcon,
  ...rest
}) => (
  <ActionShare
    popupText={intl.formatMessage(messages.tooltip)}
    onClickIcon={onClickIcon}
    {...rest}
  />
);

StarActionShareContainer.propTypes = {
  messages: PropTypes.array,
  onClickIcon: PropTypes.func,
};

export default injectIntl(StarActionShareContainer);
