// @packages
import React from 'react';
import HTMLParser from 'smu-app-components/HTMLParser';
import useTheme from 'smu-custom-hooks/useTheme';
import { useSelector } from 'react-redux';

// @app
import { selectSessionUserId } from 'containers/Authorization/selectors';

const TextParserContainer = ({ children, ...rest }) => {
  const ownerId = useSelector(selectSessionUserId);
  const theme = useTheme();

  const getHashTagProps = hashtags => {
    const hashtag = hashtags?.[0]?.[0];
    const hashtagConfig = theme.getHashtagConfig(hashtag);

    return hashtagConfig
      ? {
        style: {
          color: hashtagConfig.color,
        },
      } : {};
  };

  return (
    <HTMLParser
      getHashTagProps={getHashTagProps}
      html={children}
      ownerId={ownerId}
      {...rest}
    />
  );
};

TextParserContainer.defaultProps = {
  children: '',
};

export default TextParserContainer;
