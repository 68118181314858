import {
  CLEAN_RECOGNITION_FIELDS,
  LOAD_DEFAULT_RECOGNITION_FIELDS,
  SET_RECOGNITION_FIELDS,
} from './actionTypes';

const initialState = {
  receivedFields: {},
  receivedFiltersApplied: false,
  sentFields: {},
  sentFiltersApplied: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CLEAN_RECOGNITION_FIELDS:
      return {
        receivedFields: {},
        sentFields: {},
      };
    case LOAD_DEFAULT_RECOGNITION_FIELDS:
      return {
        ...state,
        ...payload,
      };
    case SET_RECOGNITION_FIELDS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
