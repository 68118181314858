// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'smu-ui-components/Button';
import Icon from 'smu-ui-components/IconV2';
import LottieSpinner from 'smu-ui-components/LottieSpinner';

// @app
import { apiUrl } from 'utils/apiUrl';

// @own
import './styles.scss';

const ImagePreview = ({
  className,
  image,
  isUploading,
  onRemove,
}) => {
  const hasImage = image?.code;

  if (isUploading) {
    return (
      <LottieSpinner
        height={35}
        loadingType="starmeup"
        width={35}
      />
    );
  }

  return hasImage ? (
    <div className={cn('image-preview', className)}>
      <img
        alt="upload"
        className="image-preview__upload"
        src={`${apiUrl}/image/${image.code}/160/30/1`}
      />
      <span className="image-preview__text">{image.file}</span>
      <Button transparent noMargin noPadding icon onClick={onRemove}>
        <Icon className="image-preview__icon" icon="close" size="small" />
      </Button>
    </div>
  ) : null;
};

ImagePreview.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
  isUploading: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default ImagePreview;
