import cloneDeep from 'lodash/cloneDeep';

// @own
import {
  REQUEST_GET_BETHERE_INTERACTIONS,
  REQUEST_GET_BETHERE_INTERACTIONS_SUCCESS,
  REQUEST_GET_BETHERE_INTERACTIONS_FAIL,
} from './actionTypes';

const initialState = {};

const idState = {
  error: undefined,
  requesting: false,
  result: undefined,
};

function merge(state, id, update) {
  const prevState = cloneDeep(state);
  return {
    ...prevState,
    [id]: {
      ...idState,
      ...prevState[id],
      ...update,
    },
  };
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_GET_BETHERE_INTERACTIONS:
      return merge(state, payload?.postId, {
        requesting: true,
      });
    case REQUEST_GET_BETHERE_INTERACTIONS_SUCCESS:
      return merge(state, payload?.postId, {
        requesting: false,
        result: payload?.result,
      });
    case REQUEST_GET_BETHERE_INTERACTIONS_FAIL:
      return merge(state, payload?.postId, {
        requesting: false,
        result: payload?.error,
      });
    default:
      return state;
  }
}
