import { call, put, takeLatest, take } from 'redux-saga/effects';
import { closeModal } from 'smu-app-components/RootModalV2/actions';
import { getInterests as getInterestsAPI, saveInterests } from './api';
import {
  GET_INTERESTS,
  GET_INTERESTS_SUCCESS,
  GET_INTERESTS_FAIL,
  SAVE_INTERESTS,
  SAVE_INTERESTS_SUCCESS,
  SAVE_INTERESTS_FAIL,
} from './actionTypes';

function* getInterestsWorker({ payload }) {
  try {
    const { id } = payload;
    const data = yield call(getInterestsAPI, { id });
    yield put({ type: GET_INTERESTS_SUCCESS, payload: { data } });
  } catch (e) {
    yield put({ type: GET_INTERESTS_FAIL });
  }
}

function* getInterestsWatcher() {
  yield takeLatest(GET_INTERESTS, getInterestsWorker);
}

function* saveInterestsWorker({ payload }) {
  try {
    const { interestsToAdd, interestsToDelete, userId } = payload;
    const data = yield call(saveInterests, { interestsToAdd, interestsToDelete });
    yield put({ type: SAVE_INTERESTS_SUCCESS, payload: { data } });
    yield put({ type: GET_INTERESTS, payload: { id: userId } });
    yield take([GET_INTERESTS_SUCCESS, GET_INTERESTS_FAIL]);
    yield put(closeModal());
  } catch (e) {
    yield put({ type: SAVE_INTERESTS_FAIL });
  }
}

function* saveInterestsWatcher() {
  yield takeLatest(SAVE_INTERESTS, saveInterestsWorker);
}

export default {
  getInterestsWatcher,
  saveInterestsWatcher,
};
