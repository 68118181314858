// @packages
import React, { useContext } from 'react';
import MyTeamInsightUI from 'smu-ui-components/MyTeamInsight';
import { buildImageUrl } from 'smu-utils/image';

// @app
import { StarContext } from 'containers/OSFeed/OSFeedItem';

function MyTeamInsight() {
  const { data } = useContext(StarContext);
  const imageSrc =
    data?.iconImageCode && buildImageUrl({ code: data.iconImageCode });
  const headerImageSrc =
    data?.header?.imageCode && buildImageUrl({ code: data.header.imageCode });

  return (
    <MyTeamInsightUI
      actionHref={data?.deepLink}
      actionText={data?.buttonText}
      description={data?.description}
      headerBackgroundColor={data?.header?.color}
      headerImageSrc={headerImageSrc}
      headerTitle={data?.header?.title}
      imageBackgroundColor={data?.backgroundColor}
      imageSrc={imageSrc}
      noActionText={data?.noActionText}
      showHeaderImage
    />
  );
}

export default MyTeamInsight;
