// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import InputSelect from 'smu-ui-components/InputSelect';

// @own
import './styles.scss';

function SelectFields({
  fieldName,
  id,
  index,
  isValid,
  onChange,
  placeholder,
  values,
}) {
  return (
    <div className="questionnaire-demographics__form-field" key={index}>
      <label
        htmlFor={fieldName}
        className={cn(
          'questionnaire-demographics__form-label', { 'questionnaire-demographics__form-label--valid': isValid },
        )}
      >
        {fieldName}
      </label>
      <InputSelect
        className={cn(
          'questionnaire-demographics__form-select', { 'questionnaire-demographics__form-select--selected': isValid },
        )}
        onChange={event => onChange(event, id)}
        options={values.map(value => ({ value, displayValue: value }))}
        placeholder={placeholder}
      />
    </div>
  );
}


SelectFields.propTypes = {
  fieldName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
};

export default injectIntl(SelectFields);
