// @packages
import React, { useState, useRef } from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import Popup from 'smu-ui-components/Popup';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @app
import FeedSortModal from 'components/FeedSortModal';
import useWindowSize from 'hooks/useWindowSize';

// @own
import './styles.scss';
import { SORT_OPTIONS } from './constants';

const FeedSortButton = ({
  className,
  contentWidth,
  intl: { formatMessage },
  onClose,
  onOpen,
  onToggle,
  osFeedEnabled,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useWindowSize(open);

  const currentSorting = osFeedEnabled
    ? SORT_OPTIONS.SMART
    : SORT_OPTIONS.ALL;

  const getContentStyle = () => {
    const rect = ref?.current?.getBoundingClientRect();

    if (rect) {
      return {
        left: `${rect?.left - contentWidth + rect?.width + 4}px`,
        top: `${rect?.top + rect?.height + 10}px`,
        width: `${contentWidth}px`,
      };
    }

    return {
      left: 0,
      top: 0,
      width: contentWidth,
    };
  };

  const handleTriggerButtonClick = () => {
    if (onOpen) onOpen();
    setOpen(true);
  };

  const handleBackdropClick = () => {
    if (onClose) onClose();
    setOpen(false);
  };

  const handleToggle = () => {
    if (onToggle) onToggle();
    setOpen(false);
  };

  const buildButton = () => (
    <Button
      className={cn('feed-sort-button', className)}
      color="titanWhite"
      onClick={handleTriggerButtonClick}
      ref={ref}
      {...rest}
    >
      <Icon color="blue" icon={currentSorting.icon} size={24} />
    </Button>
  );

  return (
    <>
      <Popup
        className="feed-sort-button__popup"
        content={formatMessage(currentSorting.popupContentText)}
        position="left center"
        suiteInverted
        trigger={buildButton()}
      />
      <FeedSortModal
        contentStyle={getContentStyle()}
        headerIcon={currentSorting.icon}
        headerText={formatMessage(currentSorting.modalHeaderText)}
        onBackdropClick={handleBackdropClick}
        onToggle={handleToggle}
        open={open}
        toggleText={formatMessage(currentSorting.modalToggleText)}
      />
    </>
  );
};

FeedSortButton.defaultProps = {
  contentWidth: 332,
};

FeedSortButton.propTypes = {
  className: PropTypes.string,
  contentWidth: PropTypes.number,
  intl: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onToggle: PropTypes.func,
  osFeedEnabled: PropTypes.bool,
};

export default injectIntl(FeedSortButton);
