// @packages
import React, { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import WriteForMe from 'smu-labs/AI/containers/WriteForMe';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @app
import useToast from 'hooks/useToast';

// @own
import messages from './messages';
import { getEvents } from './helpers';

const WriteForMeApp = ({
  className,
  disabled,
  intl: { formatMessage: t },
  onChange,
  starName,
  suggestionParams,
  value,
}) => {
  const ref = useRef(null);
  const showMessage = useToast();
  const [isOutside, setIsOutside] = useState(false);
  const events = useMemo(() => getEvents(starName), [starName]);

  const handleError = () => {
    showMessage({
      message: t(messages.error),
    });
  };

  const handleClickMainButton = () => {
    const element = ref.current;
    const rect = element.getBoundingClientRect();
    const isOutside = rect.top < 0 || rect.bottom + 400 > window.innerHeight;
    setIsOutside(isOutside);
    events.onClickMainButton();
  };

  return (
    <div className={cn('absolute right-4 top-4 z-10', className)} ref={ref}>
      <WriteForMe
        disabled={disabled}
        events={{
          ...events,
          onClickMainButton: handleClickMainButton,
        }}
        mainButtonProps={{
          popupProps: {
            style: {
              zIndex: 9999,
            },
          },
        }}
        messages={{
          confirmationActionNo: t(messages.confirmationActionNo),
          confirmationActionYes: t(messages.confirmationActionYes),
          confirmationTitle: t(messages.confirmationTitle),
          firstStepAction: t(messages.firstStepAction),
          firstStepActionLoading: t(messages.firstStepActionLoading),
          firstStepDescription: t(messages.firstStepDescription, {
            xxx: starName,
          }),
          firstStepPlaceholder: t(messages.firstStepPlaceholder),
          firstStepTitle: t(messages.firstStepTitle),
          mainButtonTooltip: t(messages.mainButtonTooltip),
          secondStepActionDiscard: t(messages.secondStepActionDiscard),
          secondStepActionInsert: t(messages.secondStepActionInsert),
          secondStepActionSave: t(messages.secondStepActionSave),
          secondStepActionSettings: t(messages.secondStepActionSettings),
          secondStepActionUpdate: t(messages.secondStepActionUpdate),
          secondStepActionUpdateLoading: t(
            messages.secondStepActionUpdateLoading,
          ),
          secondStepDescription: t(messages.secondStepDescription),
          secondStepTitle: t(messages.secondStepTitle),
        }}
        onInsert={onChange}
        onError={handleError}
        params={suggestionParams}
        text={value}
        placement={isOutside ? 'left' : 'left-start'}
        width={670}
        menuProps={{
          style: {
            zIndex: 9999,
          },
        }}
      />
    </div>
  );
};

WriteForMeApp.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  starName: PropTypes.string,
  suggestionParams: PropTypes.object,
  value: PropTypes.string,
};

WriteForMeApp.defaultProps = {
  className: '',
  value: '',
};

export default injectIntl(WriteForMeApp);
