// @packages
import React from 'react';

// @app
import FeedProfile from 'containers/FeedProfile';
import RecognitionExpanded from 'components/OS/Event/RecognitionContent/RecognitionExpanded';
import withNextPage from 'containers/withNextPage';

// @own
import './styles.scss';

const PaginatedFeedProfile = withNextPage(FeedProfile, 'FeedProfile');

const PaginatedFeed = (props) => (
  <div className="paginated-feed">
    <PaginatedFeedProfile {...props} />
    <RecognitionExpanded />
  </div>
);

export default PaginatedFeed;
