import { defineMessages } from 'react-intl';

export default defineMessages({
  shareDNATitle: {
    id: 'shareDNA.Title',
    defaultMessage: 'This is how I bring diversity to {xxx}',
  },
  shareDNADescription: {
    id: 'shareDNA.Description',
    defaultMessage: 'This is my cultural DNA and what distinguishes me within the organization.',
  },
});
