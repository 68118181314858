// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import { actionRequestDestroy, actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { selectRequesting, selectResultProp } from 'smu-utils/reduxRequests/selectors';

// @app
import GenericModal from 'components/GenericModal';
import InfiniteScroll from 'components/InfiniteScroll';
import UsersList from 'components/UsersList';

// @own
import { apiGetSurveyUsers } from './api';
import { requestId } from './helpers';
import './styles.scss';

function SurveyAnswerUsersModal({
  actionRequestDestroy,
  actionRequestInit,
  members,
  membersTotal,
  onAvatarClick,
  optionId,
  questionId,
  questionnaireId,
  requestingMembers,
  ...rest
}) {
  const [usersPage, setUsersPage] = useState(0);
  const isRequestingUsers = !members.length && requestingMembers;

  const interceptResponse = (response) => ({
    ...response,
    result: [...members, ...response.result],
  });

  const getSurveyUsers = (page = 0) => {
    actionRequestInit({
      api: apiGetSurveyUsers,
      id: requestId(optionId),
      interceptResponse: response => interceptResponse(response),
      params: {
        optionId,
        page,
        questionId,
        questionnaireId,
      },
    });
  };

  const loadMore = () => {
    const nextPage = usersPage + 1;
    getSurveyUsers(nextPage);
    setUsersPage(nextPage);
  };

  const renderUsers = () => (
    <div className="surveys-answer" id="members">
      {isRequestingUsers ? (
        <LottieSpinner
          className="surveys-answer__members-loader"
          height={40}
          loadingType="starmeup"
          width={40}
        />
      ) : (
        <div className="surveys-answer__members">
          <InfiniteScroll
            dataLength={members.length}
            hasMore={members.length < membersTotal}
            loadingType="starmeup"
            next={loadMore}
            scrollableTarget="members"
            scrollThreshold={0.9}
          >
            <UsersList
              users={members.map((m) => ({ ...m, profileImageCode: m.imageCode }))}
              onAvatarClick={onAvatarClick}
            />
          </InfiniteScroll>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    getSurveyUsers();
    return () => actionRequestDestroy(requestId(optionId));
  }, []);

  return (
    <GenericModal
      renderBody={renderUsers}
      {...rest}
    />
  );
}

SurveyAnswerUsersModal.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  optionId: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  questionnaireId: PropTypes.number.isRequired,
  onAvatarClick: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.optionId;
  return ({
    members: selectResultProp(state, requestId(id), 'result', []),
    membersTotal: selectResultProp(state, requestId(id), 'total', 0),
    requestingMembers: selectRequesting(state, requestId(id)),
  });
};

export default connect(mapStateToProps, {
  actionRequestDestroy,
  actionRequestInit,
})(SurveyAnswerUsersModal);
