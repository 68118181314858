import { buildImageSMU } from 'utils/image';

export const helperOrganizationLogo = (imageId, negativeImageCode) => {
  const code = imageId ?? negativeImageCode;

  return code ? buildImageSMU({ code, width: 160, height: 160 }) : undefined;
};

export const helperUser = (member, blobUrl) => ({
  imageUrl: blobUrl.data,
  job: member.selectPropertyJob(),
  name: `${member.selectFirstName()} ${member.selectLastName()}`,
});
