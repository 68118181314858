import {
  REQUEST_GET_BETHERE_INTERACTIONS,
  REQUEST_GET_BETHERE_INTERACTIONS_SUCCESS,
  REQUEST_GET_BETHERE_INTERACTIONS_FAIL,
} from './actionTypes';

export function actionGetBethereInteractions(communityId, postId) {
  return {
    type: REQUEST_GET_BETHERE_INTERACTIONS,
    payload: {
      communityId,
      postId,
    },
  };
}

export function actionGetBethereInteractionsSuccess(result) {
  return {
    type: REQUEST_GET_BETHERE_INTERACTIONS_SUCCESS,
    payload: {
      result,
    },
  };
}

export function actionGetBethereInteractionsFail(error) {
  return {
    type: REQUEST_GET_BETHERE_INTERACTIONS_FAIL,
    payload: {
      error,
    },
  };
}
