// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'smu-ui-components/ButtonV2';
import cn from 'classnames';

// @own
import './styles.scss';

const ErrorState = ({
  descriptionMessage,
  errorImage,
  onHomeClick,
  onRetryClick,
  retryMessage,
  returnHomeMessage,
  titleMessage,
}) => (
  <div className="error-state">
    <img src={errorImage} alt="error state" className="error-state__img"/>
    <div className="error-state__title">
      {titleMessage}
    </div>
    <div className="error-state__description">
      {descriptionMessage}
    </div>
    <div className="error-state__buttons">
      {onHomeClick &&
        <Button
          className={cn({'error-state__buttons--margin-right': onRetryClick })}
          onClick={onHomeClick}
          variant="outline"
        >
          {returnHomeMessage}
        </Button>}
      {onRetryClick &&
        <Button onClick={onRetryClick}>
          {retryMessage}
        </Button>}
    </div>
  </div>
);

ErrorState.defaultProps = {
  errorImage: 'assets/img/astronaut-error.png',
  onHomeClick: null,
  onRetryClick: null,
};

ErrorState.propTypes = {
  errorImage: PropTypes.string,
  onHomeClick: PropTypes.func,
  onRetryClick: PropTypes.func,
};

export default ErrorState;
