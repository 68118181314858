// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';
import { INTERACTIONS } from './constants';

const AvatarInteraction = ({ className, interaction, ...rest }) => (
  <div className={cn('smu-avatar-interaction', className)} {...rest}>
    {INTERACTIONS[interaction] && (
      <div
        className="smu-avatar-interaction__interaction"
        style={{
          backgroundImage: `url("${INTERACTIONS[interaction]}")`,
        }}
      />
    )}
  </div>
);

AvatarInteraction.propTypes = {
  className: PropTypes.string,
  interaction: PropTypes.oneOf(Object.keys(INTERACTIONS)),
};

export default AvatarInteraction;
