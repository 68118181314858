// @packages
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { apiGetResources } from 'smu-services/notifications/v3/i18n/resources/get/api';
import { getToken, getBaseUrl, getInfra } from 'smu-utils/storage';
import { navigateToLoginV2 } from 'smu-utils/globalRequestHelper';

// @app
import setLog from 'utils/logs';
import { authorize as authorizeAction } from 'containers/Authorization/actions';
import { authorize as authorizeApi } from 'containers/Authorization/api';
import { getCommunityPreferences as getCommunityPreferencesApi } from 'services/communityConfigs/api';
import { getLocales } from 'containers/IntlProvider/api';
import { isLocalhost } from 'smu-utils/localhost';
import { isUnauthorized } from 'utils/errorHandler';
import { requestCommunityConfigs } from 'services/communityConfigs/actions';
import { requestLocales } from 'containers/IntlProvider/actions';

// @own
import { APP_LOADER_INIT } from './actionTypes';
import { appLoaderFail, appLoaderSuccess } from './actions';
import { PATHS, ERRORS_BY_PATH } from './constants';
import {
  getLanguageCode,
  getThemeName,
  isEnabledEngagement,
  isEnabledPerformance,
  isSurveysOnly,
  redirectToWebPerformance,
} from './helpers';

function* appLoaderWorker() {
  try {
    const token = getToken();
    const baseUrl = getBaseUrl();
    const infra = getInfra();
    const isLogedIn = token && baseUrl && infra;

    if (isLogedIn) {
      const authorizeData = yield call(authorizeApi);
      const languageCode = getLanguageCode(authorizeData);
      const [preferencesData, languageData] = yield all([
        call(getCommunityPreferencesApi),
        call(getLocales, { languageCode }),
      ]);

      const resourceNames = getThemeName(preferencesData);

      if (resourceNames !== 'default') {
        yield call(apiGetResources, {
          params: { resourceNames },
        });
      }

      yield put(authorizeAction({ data: authorizeData }));
      yield put(requestCommunityConfigs({ data: preferencesData }));

      const enabledEngagement = isEnabledEngagement(preferencesData);
      const surveysOnly = isSurveysOnly(preferencesData);

      if (enabledEngagement || surveysOnly) {
        yield put(requestLocales({ data: languageData, languageCode }));
        yield put(appLoaderSuccess());
      } else if (isEnabledPerformance(preferencesData) && !isLocalhost()) {
        yield redirectToWebPerformance();
      } else {
        setLog('LOGOUT_APP_LOADER', 'No modules enabled (SMU, BME)');
        yield navigateToLoginV2('SMU_WEB_v3');
      }
    } else {
      setLog('LOGOUT_APP_LOADER', `Missing cookies: token:${!!token} baseUrl:${!!baseUrl} infra:${!!infra}`);
      yield navigateToLoginV2('SMU_WEB_v3');
    }
  } catch (e) {
    const url = e?.config?.url;
    const errorPath = PATHS.find((path) => url?.includes(path));
    const errorType = ERRORS_BY_PATH[errorPath] || 'UNKNOWN';

    if (!isUnauthorized(e)) {
      yield put(appLoaderFail({
        error: e,
        errorType,
      }));
    }
  }
}

export function* appLoaderWatcher() {
  yield takeEvery(APP_LOADER_INIT, appLoaderWorker);
}

export default {
  appLoaderWatcher,
};
