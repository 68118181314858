// @packages
import React, { useRef, useEffect } from 'react';
import Container from 'smu-layout-components/Container';
import HTMLParser from 'smu-app-components/HTMLParser';
import PropTypes from 'prop-types';
import StarSelectorComponent from 'smu-ui-components/StarSelector';
import Sticky from 'react-stickynode';
import cn from 'classnames';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl } from 'react-intl';
import { useResources } from 'smu-services/starmeup-api/v2/stellar/resources/get';
import { withRouter } from 'react-router';

// @app
import StickyWrapper from 'components/StickyWrapper';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { isCoreValueDisabled } from 'containers/SendStar/helpers';
import { trackEvent } from 'utils/gtm';

// @own
import messages from './messages';
import { MESSAGE_TIMEOUT } from './constants';

let lastHeight = 0;

function StarSelector({
  addToastMessage,
  intl: { formatMessage },
  remainingStars,
  router,
  stars,
  suggestSendStar,
}) {
  const wrapperRef = useRef(null);

  const useResourcesHook = useResources({
    languageCode: getUserLanguage(),
    name: 'header_star_selector',
  });

  function onClick(star) {
    if (suggestSendStar) {
      trackEvent({
        category: 'Interactions',
        action: 'Click on Send Star in Star Flow',
        label: `Star: ${star.name}`,
      });
    }

    router.push(
      buildSendStarModalUrl({
        params: {
          coreValueId: star.id,
        },
      }),
    );
  }

  function onDisabledClick(isKudos) {
    addToastMessage({
      message: formatMessage(
        isKudos ? messages.noKudosAvailable : messages.noStarAvailable,
      ),
      timeout: MESSAGE_TIMEOUT,
    });
  }

  useEffect(
    () => () => {
      lastHeight = wrapperRef.current?.offsetHeight;
    },
    [],
  );

  return (
    <>
      <Container centered>
        <div
          ref={wrapperRef}
          style={{
            minHeight: lastHeight,
          }}
        >
          <StarSelectorComponent
            className="w-full mt-20"
            components={{
              HTMLParser,
            }}
            isLoading={useResourcesHook.isLoading}
            kudosDisabled={isCoreValueDisabled(remainingStars, true)}
            messages={{
              sendKudos: formatMessage(messages.sendKudos),
              sendStar: formatMessage(messages.sendStar),
            }}
            onClick={onClick}
            onDisabledClick={onDisabledClick}
            stars={stars}
            starsDisabled={isCoreValueDisabled(remainingStars, false)}
            title={useResourcesHook.selectResult()}
          />
        </div>
      </Container>
      <StickyWrapper className="!h-0" innerZ={11} top={63}>
        {
          ({status}) => (
            <Container
              centered
              className={cn('!px-0', {
                'invisible h-0': status !== Sticky.STATUS_FIXED,
              })}
              fluid
            >
              <StarSelectorComponent
                aria-hidden
                className="w-full"
                components={{
                  HTMLParser,
                }}
                isLoading={useResourcesHook.isLoading}
                isSticky
                kudosDisabled={isCoreValueDisabled(remainingStars, true)}
                messages={{
                  sendKudos: formatMessage(messages.sendKudos),
                  sendStar: formatMessage(messages.sendStar),
                }}
                onClick={onClick}
                onDisabledClick={onDisabledClick}
                stars={stars}
                starsDisabled={isCoreValueDisabled(remainingStars, false)}
                title={useResourcesHook.selectResult()}
              />
            </Container>
          )
        }
      </StickyWrapper>
    </>
  );
}

StarSelector.propTypes = {
  addToastMessage: PropTypes.func.isRequired,
  className: PropTypes.string,
  intl: PropTypes.object,
  remainingStars: PropTypes.object,
  router: PropTypes.object,
  stars: PropTypes.array.isRequired,
  suggestSendStar: PropTypes.bool,
};

const actions = {
  addToastMessage,
};

const mapStateToProps = (state) => {
  const { starFlow, session } = state;
  return {
    remainingStars: starFlow?.remainingStars,
    stars: session?.stars,
    suggestSendStar: starFlow?.suggestSendStar,
  };
};

export default connect(
  mapStateToProps,
  actions,
)(withRouter(injectIntl(StarSelector)));
