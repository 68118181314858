// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  actionRequestInit,
  actionRequestDestroy,
} from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import List from 'components/QuestionnaireResults/List';
import { selectError, selectRequest } from 'smu-utils/reduxRequests/selectors';

// @app
import { REPORT_TYPE } from 'containers/QuestionnaireResults/constants';

// @own
import { LISTS_RESULTS_ID, LISTS_RESULTS_PAGE_SIZE } from './constants';
import { apiGetSurveyTextList } from './api';

function QuestionnaireResultsListContainer({
  actionRequestInit,
  actionRequestDestroy,
  id,
  questionnaireId,
  request,
  ...rest
}) {
  const [page, setPage] = useState(0);
  const isLoading = request?.requesting;
  const options = request?.result?.result?.details || [];
  const totalItems = request?.result?.result?.totalAnswered || 0;

  const getResults = (page = 0) => {
    actionRequestInit({
      api: apiGetSurveyTextList,
      id: `${LISTS_RESULTS_ID}-${id}`,
      params: {
        page,
        questionId: id,
        questionnaireId,
        reportType: REPORT_TYPE,
      },
    });
  };

  const onChangePage = (page) => {
    if (!request?.request) {
      setPage(page);
      getResults(page);
    }
  };

  const normalice = (options) =>
    options?.map(item => {
      const [first] = item?.answers;
      return ({
        date: first?.answerDate,
        hint: first?.hint,
        member: first?.member,
      });
    });

  useEffect(() => {
    getResults();

    return () => {
      actionRequestDestroy(`${LISTS_RESULTS_ID}-${id}`);
    };
  }, []);

  return (
    <List
      {...rest}
      id={id}
      isLoading={isLoading}
      onChangePage={onChangePage}
      options={normalice(options)}
      page={page}
      pageSize={LISTS_RESULTS_PAGE_SIZE}
      totalItems={totalItems}
    />
  );
}

QuestionnaireResultsListContainer.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  error: PropTypes.object,
  questionnaireId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  request: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    error: selectError(state, `${LISTS_RESULTS_ID}-${ownProps?.id}`),
    request: selectRequest(state, `${LISTS_RESULTS_ID}-${ownProps?.id}`),
  };
}

export default connect(mapStateToProps, {
  actionRequestDestroy,
  actionRequestInit,
})(injectIntl(QuestionnaireResultsListContainer));
