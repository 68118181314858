import { defineMessages } from 'react-intl';

export default defineMessages({
  bethereFeedEmpty: {
    id: 'bethere.feed.empty',
    defaultMessage: 'No content to display.',
  },
  bethereFeedEmptyOtherProfilePostText: {
    id: 'bethere.feed.emptyOtherProfilePostText',
    defaultMessage: '{firstName} HAS NOT UPLOADED ANY POSTS YET',
  },
  bethereFeedEmptyProfilePostSubtext: {
    id: 'bethere.feed.emptyProfilePostSubtext',
    defaultMessage: 'Download the StarMeUp app for Android or iOS and share a special moment with your colleagues.',
  },
  bethereFeedEmptyProfilePostText: {
    id: 'bethere.feed.emptyProfilePostText',
    defaultMessage: "YOU HAVEN'T UPLOADED ANY POSTS YET",
  },
  bethereFeedEmptyOtherProfileTaggedText: {
    id: 'bethere.feed.emptyOtherProfileTaggedText',
    defaultMessage: '{firstName} HAS NOT BEEN TAGGED IN ANY POSTS YET.',
  },
  bethereFeedEmptyProfileTaggedText: {
    id: 'bethere.feed.emptyProfileTaggedText',
    defaultMessage: 'YOU HAVE NOT BEEN TAGGED IN ANY POSTS YET.',
  },
  bethereFeedResults: {
    id: 'bethere.feed.results',
    defaultMessage: 'Results',
  },
});
