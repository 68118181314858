// @packages
import React from 'react';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Icon from 'smu-ui-components/Icon';
import RootModal from 'smu-app-components/RootModalV2';

// @Own
import './styles.scss';

const MyTeamModal = ({ closeModal, data, title }) => {
  const bmeBaseUrl = `${getWebUrl('betterme')}/`;

  return (
    <div className="my-team-modal">
      <div className="my-team-modal__header">
        <span>
          {title}
        </span>
        <Icon
          className="my-team-modal__button"
          color="black"
          icon="close"
          onClick={() => closeModal()}
          size="extra-small"
          type="base"
        />
      </div>
      <div className="my-team-modal__list">
        {data?.map((avatar) => (
          <a href={`${bmeBaseUrl}newfeedback/${window.btoa(avatar.identification)}`}>
            <div className="my-team-modal__list-item">
              <AvatarMedia
                className="my-team-modal__list-item--user"
                firstName={avatar.firstName}
                job={avatar.properties.job}
                lastName={avatar.lastName}
                noHash
                profileImageCode={avatar.imageCode}
                type="medium"
                useLink={false}
              />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default RootModal(MyTeamModal, 'CULTURAL_MODAL', { width: '700px' });
