// @packages
import React from 'react';
import EnrollModal from 'smu-app-components/BeHealthy/EnrollModal';
import PropTypes from 'prop-types';

const ModalBeHealthyEnrollContainer = ({ onClose, open }) => (
  <EnrollModal isOpen={open} onClose={onClose} showNewQR />
);

ModalBeHealthyEnrollContainer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalBeHealthyEnrollContainer;
