// @packages
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ShareHtml = ({ className, height, html, onError, onSuccess, width }) => {
  const ref = useRef(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (html && ref?.current && loaded) {
      const w = ref?.current?.contentWindow;
      w.loadHtml({
        height,
        html,
        onError,
        onSuccess,
        width,
      });
    }
  }, [html, loaded]);

  return html ? (
    <iframe
      className={cn('share-html', className)}
      height={height}
      onLoad={() => setLoaded(true)}
      onError={() =>
        onError({
          message: 'Error loading iframe',
        })
      }
      ref={ref}
      src="/share-html/index.html"
      title="share html"
      width={width}
    />
  ) : null;
};

ShareHtml.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  html: PropTypes.string,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  width: PropTypes.number,
};

export default ShareHtml;
