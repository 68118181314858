// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import Avatar from 'components/Avatar';
import { buildImageSMU } from 'utils/image';

const AvatarSMU = ({ imageCode, imageHeight, imageWidth, ...rest }) => (
  <Avatar
    src={buildImageSMU({
      code: imageCode,
      height: imageHeight,
      width: imageWidth,
    })}
    {...rest}
  />
);

AvatarSMU.propTypes = {
  imageCode: PropTypes.string.isRequired,
  imageHeight: PropTypes.number.isRequired,
  imageWidth: PropTypes.number.isRequired,
};

export default AvatarSMU;
