import {
  GET_SEND_STAR_PROFILE,
  GET_SEND_STAR_PROFILE_SUCCESS,
  GET_SEND_STAR_PROFILE_FAIL,
  REMOVE_SEND_STAR_PROFILE,
} from './actionTypes';

const initialState = {
  fetching: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SEND_STAR_PROFILE:
      return {
        ...state,
        fetching: true,
      };
    case GET_SEND_STAR_PROFILE_SUCCESS:
      return {
        ...state,
        result: payload,
        fetching: false,
      };
    case GET_SEND_STAR_PROFILE_FAIL:
      return {
        ...state,
        fetching: false,
      };
    case REMOVE_SEND_STAR_PROFILE:
      return {
        ...state,
        result: undefined,
      };
    default:
      return state;
  }
}
