// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

function TextField({
  className,
  endAdornment,
  fullWidth,
  size,
  startAdornment,
  variant,
  ...rest
}) {
  return (
    <div
      className={cn('suite-text-field', className, {
        [`suite-text-field--variant-${variant}`]: variant,
        [`suite-text-field--size-${size}`]: size,
        'suite-text-field--full-width': fullWidth,
        'suite-text-field--with-start-adornment': startAdornment,
        'suite-text-field--with-end-adornment': endAdornment,
      })}
    >
      {startAdornment}
      <input className="suite-text-field__input" {...rest} />
      {endAdornment}
    </div>
  );
}

TextField.defaultProps = {
  type: 'text',
  fullWidth: false,
};

TextField.propTypes = {
  endAdornment: PropTypes.node,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  startAdornment: PropTypes.node,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['outlined']),
};

export default TextField;
