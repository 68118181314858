export const optionsConfig = {
  chart: {
    height: 300,
    spacingBottom: 10,
    spacingTop: 10,
  },
  title: {
    text: null,
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    labels: {
      enabled: false,
    },
    tickLength: 0,
  },
  yAxis: {
    gridLineDashStyle: 'LongDash',
    gridLineWidth: 1,
    title: {
      text: null,
    },
  },
  legend: {
    enabled: true,
  },
  plotOptions: {
    series: {
      animation: true,
      borderWidth: 0,
      pointWidth: 20,
      dataLabels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    bar: {
      groupPadding: 0.1,
      pointWidth: 60,
      pointHeight: 40,
    },
  },
};
