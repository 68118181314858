// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import SkeletonFeedItem from 'components/Skeletons/FeedItem';
import SkeletonMediaItem from 'components/Skeletons/MediaItem';

// @own
import './styles.scss';

function SkeletonOSFeed({ items, media }) {
  const array = Array.from(Array(items).keys());

  return (
    <div className="skeleton-os-feed">
      {array?.map((i) => (media ? <SkeletonMediaItem key={i} /> : <SkeletonFeedItem key={i} />))}
    </div>
  );
}

SkeletonOSFeed.defaultProps = {
  items: 10,
  media: false,
};

SkeletonOSFeed.propTypes = {
  items: PropTypes.number,
  media: PropTypes.bool,
};

export default SkeletonOSFeed;
