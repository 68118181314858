import { concat, pick, map, merge } from 'lodash';

import { actionTypes } from './actions';

const {
  requestSearchFilters,
  requestSearchFiltersResult,
  cleanSearchFilters,
  requestAdvancedSearch,
  requestAdvancedSearchResults,
  updateAdvancedSearchFields,
} = actionTypes;

const initialState = {
  results: [],
  fetching: true,
  fetchingNextPage: false,
  total: 0,
  inputFilters: undefined,
  selectFilters: undefined,
  fields: {},
  labels: {},
  page: 0,
};

export const getSelectFilters = ({ selectFilters }) => selectFilters;
export const getInputFilters = ({ inputFilters }) => inputFilters;

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case requestSearchFilters:
      return {
        ...initialState,
        fields: payload.fields,
      };
    case requestSearchFiltersResult:
      return {
        ...initialState,
        fields: pick(
          state.fields,
          map(concat(payload.inputFilters, payload.selectFilters), filter => filter.field),
        ),
        inputFilters: payload.inputFilters,
        selectFilters: payload.selectFilters,
      };
    case cleanSearchFilters:
      return {
        ...initialState,
        inputFilters: state.inputFilters,
        selectFilters: state.selectFilters,
      };
    case requestAdvancedSearch:
      return {
        ...state,
        fetching: payload.page === 0,
        fetchingNextPage: payload.page !== 0,
        page: payload.page,
        fields: payload.inputFields ? merge(payload.fields, payload.inputFields) : payload.fields,
        labels: payload.labels || state.labels,
      };
    case requestAdvancedSearchResults:
      return {
        ...state,
        page: state.page + 1,
        results: concat(payload.page === 0 ? [] : state.results, payload.results),
        fetching: false,
        fetchingNextPage: false,
        total: payload.page === 0 ? payload.total : state.total,
      };
    case updateAdvancedSearchFields:
      return {
        ...state,
        fields: payload.fields,
      };
    default:
      return state;
  }
}
