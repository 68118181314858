// @packages
import {
  getUserLanguage,
  getQueryLanguage,
  normaliceLanguage,
} from 'smu-utils/language';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import {
  ENABLED_BETTERME,
  ENABLED_STARMEUP,
  ENABLED_SURVEYS_ONLY,
  THEME_NAME,
} from 'smu-services/os-member/api/v1/community/preferences/configurations/constants';

// @app
import { castStringToBoolean } from 'utils/formatString';
import { replaceDomain } from 'utils/url';

export const getLanguageCode = (data) =>
  normaliceLanguage(getQueryLanguage()) ||
  data?.result?.language?.code ||
  getUserLanguage();

export const isEnabledPerformance = ({ result }) =>
  castStringToBoolean(result?.[ENABLED_BETTERME]);

export const isEnabledEngagement = ({ result }) =>
  castStringToBoolean(result?.[ENABLED_STARMEUP]);

export const isSurveysOnly = ({ result }) =>
  castStringToBoolean(result?.[ENABLED_SURVEYS_ONLY]);

export const getThemeName = ({ result }) => result?.[THEME_NAME];

export const redirectToWebPerformance = () => {
  const bettermeUrl = getWebUrl('betterme');
  const href = replaceDomain(window.location.href, bettermeUrl);
  window.location.href = href;
};
