import { call, put, takeEvery } from 'redux-saga/effects';
import { closeModal } from 'smu-app-components/RootModalV2/actions';

import {
  GET_EULA,
  GET_EULA_SUCCESS,
  GET_EULA_ERROR,
  SAVE_EULA,
  SAVE_EULA_SUCCESS,
  SAVE_EULA_ERROR,
} from './actionTypes';

import { getEula, saveEulaAgreement } from './api';

function* getEulaWorker() {
  try {
    const data = yield call(getEula);
    yield put({ type: GET_EULA_SUCCESS, payload: data.result });
  } catch (e) {
    yield put({ type: GET_EULA_ERROR, payload: e });
  }
}

function* getEulaWatcher() {
  yield takeEvery(GET_EULA, getEulaWorker);
}

function* saveEulaWorker() {
  try {
    const data = yield call(saveEulaAgreement);
    yield put({ type: SAVE_EULA_SUCCESS, payload: data.result });
    yield put(closeModal());
  } catch (e) {
    yield put({ type: SAVE_EULA_ERROR, payload: e });
  }
}

function* saveEulaWatcher() {
  yield takeEvery(SAVE_EULA, saveEulaWorker);
}
export default {
  getEulaWatcher,
  saveEulaWatcher,
};
