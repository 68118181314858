const { hostname } = window.location;

const redirectFromHosts = {
  'dev.starmeup.com': 'app-dev.starmeup.com',
  'qa.starmeup.com': 'app-qa.starmeup.com',
  'uat.starmeup.com': 'app-uat.starmeup.com',
  'www.starmeup.com': 'app.starmeup.com',
};

export const redirectTo = redirectFromHosts[hostname];

export function redirectToApp() {
  const newUrl = window.location.href.replace(hostname, redirectTo);
  window.location.replace(newUrl);
}
