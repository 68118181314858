// @packages
import React from 'react';
import Avatar from 'smu-ui-components/Avatar';
import UserPopup from 'smu-app-components/UserPopup';
import { buildImageUrl } from 'smu-utils/image';

export const getUserAvatarListProps = ({ ownId, texts, users }) => {
  const formattedUsers = users.map(user => ({
    ...user,
    src: buildImageUrl({
      code: user?.profileImageCode,
      height: 56,
      width: 56,
    }),
  }));

  const AvatarPopup = props => {
    const isOwner = String(ownId) === String(props?.id);
    const profileUrl = `/profile/${props?.uid}`;

    const popupUser = {
      avatarUrl: buildImageUrl({
        code: props?.profileImageCode,
        height: 45,
        width: 45,
      }),
      job: props?.job,
      name: props?.fullname,
    };

    return (
      <UserPopup
        buttonText={texts.userPopupButton}
        href={profileUrl}
        owner={isOwner}
        trigger={
          <Avatar
            height={56}
            src={popupUser.avatarUrl}
            style={props.style}
            width={56}
          >
            {props.children}
          </Avatar>
        }
        user={popupUser}
        userId={props?.id}
      />
    );
  };

  return {
    component: AvatarPopup,
    legacy: false,
    users: formattedUsers,
  };
};
