// @packages
import React, { useEffect } from 'react';
import Onboarding from 'smu-app-components/Onboarding';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { errorMessageHandler } from 'utils/errorHandler';
import { useCommunity } from 'smu-services/betterme/api/community';

// @app
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import {
  selectCommunityName,
  selectCommunityId,
} from 'containers/Authorization/selectors';
import {
  ENABLED_BETHERE_FEED,
  ENABLED_BETTERME,
  ENABLED_COMMUNICATIONS,
  ENABLED_DISCOVERY_PEOPLE,
} from 'services/communityConfigs/constants';

function OnboardingContainer({
  bethereEnabled,
  communityId,
  communityName,
  discoveryPeopleEnabled,
  enabledBetterme,
  onError,
  showOnlyPerformance,
  ...rest
}) {
  const { data, error, isLoading } = useCommunity({
    communityId:
      enabledBetterme && showOnlyPerformance ? communityId : undefined,
  });
  const enabledGoals = data?.result?.configurations?.allowsGoals;
  const enabledEvaluations = data?.result?.configurations?.allowsEvaluations;

  useEffect(() => {
    if (error) {
      errorMessageHandler();

      if (onError) {
        onError(error);
      }
    }
  }, [error, onError]);

  return (
    <Onboarding
      bethereEnabled={bethereEnabled}
      communityName={communityName}
      discoveryPeopleEnabled={discoveryPeopleEnabled}
      enabledEvaluations={enabledEvaluations}
      enabledGoals={enabledGoals}
      hasBetterme={enabledBetterme}
      isLoading={isLoading}
      showOnlyPerformance={showOnlyPerformance}
      {...rest}
    />
  );
}

OnboardingContainer.propTypes = {
  bethereEnabled: PropTypes.bool,
  communityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  communityName: PropTypes.string,
  discoveryPeopleEnabled: PropTypes.bool,
  enabledBetterme: PropTypes.bool,
  onError: PropTypes.func,
  showOnlyPerformance: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  bethereEnabled: getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED),
  communicationsEnabled: getCommunityConfigValueBool(
    state,
    ENABLED_COMMUNICATIONS,
  ),
  communityId: selectCommunityId(state),
  communityName: selectCommunityName(state),
  discoveryPeopleEnabled: getCommunityConfigValueBool(
    state,
    ENABLED_DISCOVERY_PEOPLE,
  ),
  enabledBetterme: getCommunityConfigValueBool(state, ENABLED_BETTERME),
});

export default connect(mapStateToProps)(OnboardingContainer);
