// @packages
import { urls } from 'utils/routes';

// @own
import UnifiedProfile from './UnifiedProfile';

export default () => ({
  path: urls.myProfile,
  component: UnifiedProfile,
});
