import { bethereApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import paramsBuilder from 'utils/paramsBuilder';

function getPostsAPI({ size = 6, orgId, userId }) {
  const params = {
    size,
    userId,
    postTypeExcluded: 'COMMUNITY',
  };
  return bethereApiInstance({
    url: `organization/${orgId}/posts?${paramsBuilder(params)}`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

function getPropertyValueAPI({ orgId }) {
  return bethereApiInstance({
    url: `organization/${orgId}/properties`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export { getPostsAPI, getPropertyValueAPI };
