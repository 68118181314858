/*
 * TODO: remove the code in the year 2022
 * Details are include in the US: https://jira.corp.globant.com/browse/GLB789-43307
 */

export function isYourself(orgId, name = '') {
  const isQA = /localhost|qa.starmeup.com/.test(window.location.href);
  const isPROD = /www.starmeup.com/.test(window.location.href);
  const isValidENV = isQA || isPROD;
  const isValidYear = new Date().getFullYear() === 2021;
  const isValidOrgId = (isPROD && (orgId === 1 || orgId === 2)) || (isQA && orgId === 3);
  const isValidName = name.toLowerCase() === 'inclusive & diverse';

  return isValidENV && isValidYear && isValidOrgId && isValidName;
}
