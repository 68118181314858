// @packages
import React, { useState } from 'react';
import Icon from 'smu-ui-components/IconV2';
import InputText from 'smu-ui-components/InputText';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import { useLocate } from 'smu-services/starmeup-api/v2/stellar/locate';
import { useSuggestedUsers } from 'smu-services/starmeup-api/v2/stellar/users/suggested';

// @app
import SuggestedUsersList from 'components/SuggestedUsersList';
import useDebounce from 'hooks/useDebounce';

// @own
import './styles.scss';
import messages from './messages';

const UserSearch = ({
  className,
  delay,
  intl: { formatMessage },
  onUserSelect,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, delay);

  const {
    isLoading: useLocateIsLoading,
    selectResult: selectUseLocateResult,
  } = useLocate({
    filterType: 'USER',
    searchString: debouncedSearchValue,
    shouldFetch: debouncedSearchValue,
  });

  const {
    isLoading: useSuggestedUsersIsLoading,
    selectResult: selectUseSuggestedUsersResult,
  } = useSuggestedUsers();

  const locateUsers = selectUseLocateResult();
  const suggestedUsers = selectUseSuggestedUsersResult();

  const handleInputChange = event => {
    setSearchValue(event?.target?.value || '');
  };

  return (
    <div className={cn('user-search', className)}>
      <div className="user-search__input-wrapper">
        <InputText
          className="user-search__input"
          idName="user-search"
          onChange={handleInputChange}
          placeholder={formatMessage(messages.inputPlaceholder)}
          value={searchValue}
        />
        <Icon
          className="user-search__input-icon"
          icon="search"
        />
      </div>
      <SuggestedUsersList
        className="user-search__list"
        headerMessage={formatMessage(messages.suggestedUsersListHeader)}
        isLoading={useLocateIsLoading || useSuggestedUsersIsLoading}
        loadingSize={6}
        onUserSelect={onUserSelect}
        users={debouncedSearchValue ? locateUsers : suggestedUsers}
      />
    </div>
  );
};

UserSearch.defaultProps = {
  delay: 500,
};

UserSearch.propTypes = {
  className: PropTypes.string,
  delay: PropTypes.number,
  intl: PropTypes.object,
  onUserSelect: PropTypes.func,
};

export default injectIntl(UserSearch);
