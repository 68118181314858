import { defineMessages } from 'react-intl';

export default defineMessages({
  emptyStateTitle: {
    id: 'Insights.EmptyState.Title',
    defaultMessage: 'We are still analyzing your information',
  },
  emptyStateDescription: {
    id: 'Insights.EmptyState.Description',
    defaultMessage: 'Soon we will have a lot of insights just for you!',
  },
  emptyStateButton: {
    id: 'Insights.EmptyState.Button',
    defaultMessage: 'Return to homepage',
  },
});
