import { defineMessages } from 'react-intl';

export default defineMessages({
  surveysWidgetAvailableTabs: {
    id: 'surveysWidget.availableTabs',
    defaultMessage: 'Available',
  },
  surveysWidgetEmptyAvailable: {
    id: 'surveysWidget.emptyAvailable',
    defaultMessage: 'No surveys available',
  },
  surveysWidgetEmptyResults: {
    id: 'surveysWidget.emptyResults',
    defaultMessage: 'No results available',
  },
  surveysWidgetResultsTabs: {
    id: 'surveysWidget.resultsTabs',
    defaultMessage: 'Results',
  },
  surveysWidgetTitle: {
    id: 'surveysWidget.title',
    defaultMessage: 'Surveys',
  },
  surveysWidgetModalTitle: {
    id: 'surveysWidget.modalTitle',
    defaultMessage: 'Dismiss results',
  },
  surveysWidgetModalDescription: {
    id: 'surveysWidget.modalDescription',
    defaultMessage: '{xxx} results will no longer be visible on this list. Are you sure you want to dismiss?',
  },
  surveysWidgetOngoingSurvey: {
    id: 'surveysWidget.ongoingSurvey',
    defaultMessage: 'Ongoing Survey',
  },
});
