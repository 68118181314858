// @packages
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'cropperjs/dist/cropper.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'emoji-mart/css/emoji-mart.css';
import 'swiper/swiper.scss';
import React from 'react';
import { render } from 'react-dom';

// @own
import './assets/ui-components-icons.font';
import './index.scss';
import Root from './Root';
import configureStore from './store/configureStore';
import packagejson from '../package.json';
import { redirectTo, redirectToApp } from './utils/redirect';
import { unregister } from './registerServiceWorker';

// eslint-disable-next-line no-console
console.log(`VERSION: ${packagejson.version}`);

const { store, history } = configureStore();
const MOUNT_NODE = document.getElementById('root');

if (redirectTo) {
  redirectToApp();
} else {
  render(<Root history={history} store={store} />, MOUNT_NODE);
}

unregister();
