// @packages
import React from 'react';

// @app
import Communications from 'containers/Communications';

const CommunicationsLayout = () => (
  <Communications/>
);


export default CommunicationsLayout;
