// @app
import { paths } from 'utils/routes';

// @own
import QuestionnaireResultsLayout from './Layout';

export default () => ({
  component: QuestionnaireResultsLayout,
  onEnter: () => {
    window.scrollTo(0, 0);
  },
  path: paths.questionnaireResults(),
});
