// @packages
import React from 'react';
import Modal from 'smu-ui-components/Modal';
import { injectIntl } from 'react-intl';

// @app
import ShareModal from 'containers/ShareModal';
import { TYPE_STAR } from 'containers/Share/constants';
import shareModalMessages from 'containers/ShareModal/messages';


function ShareStarContainer(props) {
  const { open, onClose, intl: { formatMessage } } = props;

  return (
    <Modal
      open={open}
      onClick={onClose}
    >
      <ShareModal
        className="bg-white w-[534px] rounded-md"
        type={TYPE_STAR}
        title={formatMessage(shareModalMessages.shareModalTitleStar)}
        onClose={onClose}
      />
    </Modal>
  );
}

export default injectIntl(ShareStarContainer);
