export const CLEAN_FEED = 'CLEAN_FEED';
export const FEED_SET_FIELDS = 'FEED_SET_FIELDS';
export const FEED_SET_OPTIONS = 'FEED_SET_OPTIONS';
export const MODERATE_COMMENT_SUCCESS = 'MODERATE_COMMENT_SUCCESS';
export const REMOVE_LIKE = 'REMOVE_LIKE';
export const REMOVE_LIKE_FAIL = 'REMOVE_LIKE_FAIL';
export const REMOVE_LIKE_SUCCESS = 'REMOVE_LIKE_SUCCESS';
export const REQUEST_FEED = 'REQUEST_FEED';
export const REQUEST_FEED_FAILED = 'REQUEST_FEED_FAILED';
export const REQUEST_FEED_NEXT_PAGE = 'REQUEST_FEED_NEXT_PAGE';
export const REQUEST_FEED_NEXT_PAGE_SUCCESS = 'REQUEST_FEED_NEXT_PAGE_SUCCESS';
export const REQUEST_FEED_RESET_PAGE = 'REQUEST_FEED_RESET_PAGE';
export const REQUEST_FEED_SUCCESS = 'REQUEST_FEED_SUCCESS';
export const SEND_COMMENT = 'SEND_COMMENT';
export const SEND_COMMENT_FAIL = 'SEND_COMMENT_FAIL';
export const SEND_COMMENT_SUCCESS = 'SEND_COMMENT_SUCCESS';
export const SEND_IMAGE = 'SEND_IMAGE';
export const SEND_IMAGE_FAIL = 'SEND_IMAGE_FAIL';
export const SEND_IMAGE_SUCCESS = 'SEND_IMAGE_SUCCESS';
export const SEND_LIKE = 'SEND_LIKE';
export const SEND_LIKE_FAIL = 'SEND_LIKE_FAIL';
export const SEND_LIKE_SUCCESS = 'SEND_LIKE_SUCCESS';
export const SHOW_SEND_STAR_FLOW = 'SHOW_SEND_STAR_FLOW';
export const TOGGLE_MAIN_COMMENT = 'TOGGLE_MAIN_COMMENT';
export const ADD_ITEM_TO_FEED = 'ADD_ITEM_TO_FEED';
export const TOGGLE_SEND_STAR_WIDGET = 'TOGGLE_SEND_STAR_WIDGET';
export const SEND_LIFT_STAR_SUCCESS = 'SEND_LIFT_STAR_SUCCESS';
