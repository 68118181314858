// @packages
import React from 'react';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import DiscoverUsersList from './List';
import './styles.scss';

function DiscoverUsers({
  className,
  enableOptions,
  isEmpty,
  isLoading,
  onCheckedUser,
  onClickUser,
  onSelectAll,
  options,
  selectedUsers,
  texts,
  totalElements,
  totalSelectedUsers,
  users,
}) {
  return (
    <Paper
      className={cn('discover-users shadow-base rounded-lg', className)}
      noPadding
    >
      <DiscoverUsersList
        enableOptions={enableOptions}
        isEmpty={isEmpty}
        isLoading={isLoading}
        onChecked={onCheckedUser}
        onClickUser={onClickUser}
        onSelectAll={onSelectAll}
        options={options}
        selectedUsers={selectedUsers}
        showFooter
        texts={texts}
        totalElements={totalElements}
        totalSelectedUsers={totalSelectedUsers}
        users={users}
      />
    </Paper>
  );
}

DiscoverUsers.propTypes = {
  className: PropTypes.string,
  isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCheckedUser: PropTypes.func,
  onClickUser: PropTypes.func,
  texts: PropTypes.shape({
    selectAll: PropTypes.string,
    selectedUsers: PropTypes.string,
  }),
  users: PropTypes.array,
};

DiscoverUsers.defaultProps = {
  className: '',
  isEmpty: false,
  isLoading: false,
  onCheckedUser: () => undefined,
  onClickUser: () => undefined,
  texts: {
    selectAll: '',
    selectedUsers: '',
  },
  users: [],
};

export default DiscoverUsers;
