// @packages
import React from 'react';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';

// @app
import RemainingStarsCounter from 'components/RemainingStarsCounter';
import StarContainer from 'components/StarContainer';

// @own
import './styles.scss';

const SendStarHeader = ({
  backgroundColor,
  message,
  onClose,
  remainingstars,
  showRemainingStarCounter,
  totalStars,
}) => (
  <div className="send-star-header" style={{ backgroundColor }}>
    <StarContainer>
      <div className="send-star-header__container">
        <div className="send-star-header__message">
          {message}
        </div>
        {showRemainingStarCounter &&
          <RemainingStarsCounter
            remainingstars={remainingstars}
            totalStars={totalStars}
          />
        }
      </div>
      {onClose &&
        <Icon
          className="send-star-header__close"
          icon="close"
          onClick={onClose}
          size="small"
        />
      }
    </StarContainer>
  </div>
);

SendStarHeader.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  remainingstars: PropTypes.number,
  showRemainingStarCounter: PropTypes.bool,
  star: PropTypes.object,
  totalStars: PropTypes.number,
};

export default SendStarHeader;
