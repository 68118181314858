// @packages
import React from 'react';
import Button from 'smu-ui-components/Button';
import Input from 'smu-ui-components/Input';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';
import TextArea from 'smu-ui-components/TextArea';
import { connect } from 'react-redux';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl } from 'react-intl';

// @app
import { trackEvent } from 'utils/gtm';
import { sendExperience } from './actions';

// @own
import './styles.scss';
import messages from './messages';

class FooterExperience extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      text: '',
      inputName: `${user.firstName} ${user.lastName}`,
      inputEmail: user.email,
    };
    this.send = this.send.bind(this);
    this.handleOnClickClose = this.handleOnClickClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { sending, close } = this.props;
    const { sending: nextSending, error } = nextProps;
    if ((sending && !nextSending) && error === '') {
      close();
    }
  }

  handleOnClickClose() {
    const { close } = this.props;
    trackEvent({
      category: 'Help',
      action: 'Close',
    });
    close();
  }

  send() {
    const { sendExperience, user } = this.props;
    trackEvent({
      category: 'Help',
      action: 'Send',
    });
    sendExperience({
      app: 'StarMeUp',
      appLanguage: getUserLanguage(),
      browserInfo: navigator.userAgent,
      community: user.organizationName,
      email: this.state.inputEmail,
      id: user.uid,
      message: this.state.text,
      osInfo: navigator.platform,
      user: this.state.inputName,
    });
  }

  render() {
    const { intl } = this.props;
    const { text, inputName, inputEmail } = this.state;
    const canSendMessage =
      text.length >= 3 && this.state.inputEmail.length >= 3 && this.state.inputName.length >= 3;

    return (
      <Panel className="send-experience" title={intl.formatMessage(messages.tellExperience)}>
        <Input
          className="send-experience__input"
          placeholder="Email"
          onChange={(e) => {
            this.setState({ inputEmail: e.target.value });
          }}
          value={inputEmail}
        />
        <Input
          className="send-experience__input"
          placeholder={intl.formatMessage(messages.yourName)}
          onChange={(e) => {
            this.setState({ inputName: e.target.value });
          }}
          value={inputName}
        />
        <TextArea
          className="send-experience__textarea"
          onChange={(e) => {
            this.setState({ text: e.target.value });
          }}
          value={text}
          placeholder={intl.formatMessage(messages.tellExperienceDescription)}
        />
        <br />
        <div className="send-experience__buttons">
          <Button onClick={this.handleOnClickClose}>{intl.formatMessage(messages.cancel)}</Button>
          <Button disabled={!canSendMessage} onClick={this.send}>
            {intl.formatMessage(messages.send)}
          </Button>
        </div>
      </Panel>
    );
  }
}

FooterExperience.propTypes = {
  close: PropTypes.func,
  user: PropTypes.object,
  sending: PropTypes.bool,
  error: PropTypes.string,
  sendExperience: PropTypes.func,
  intl: PropTypes.object,
};

// const FooterModal = RootModal(FooterExperience, 'FOOTEREXP_CONFIRMATION');

const mapStateToProps = ({ tellExperience: { sending, error } }) =>
  ({ sending, error });

export default RootModal(connect(mapStateToProps, { sendExperience })(injectIntl(FooterExperience)), 'FOOTEREXP_CONFIRMATION');
