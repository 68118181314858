// @packages
import React, { useEffect } from 'react';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import Icon from 'smu-ui-components/IconV2';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import Profile from 'smu-unified-profile';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import withRootModal from 'smu-app-components/RootModalV2/withRootModal';
import { connect } from 'react-redux';
import { trackEvent } from 'smu-utils/gtm';
import { useMember } from 'smu-services/os-member/api/v1/community/member';
import { withRouter } from 'react-router';

// @app
import Badges from 'containers/Badges';
import BethereFeed from 'containers/BethereFeed';
import HighlightedStar from 'containers/HighlightedStar';
import PaginatedFeed from 'containers/PaginatedFeed';
import UploadProfileImageModal from 'containers/UploadProfileImageModal';
import UserProfile from 'containers/UserProfile';
import WithCongratulationsFeed from 'containers/WithCongratulationsFeed';
import { MODAL_SHARE_DNA } from 'containers/QueryModals/constants';
import { SMU_GENERIC_MODAL } from 'containers/GenericModal/constants';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { feedSetOptions } from 'containers/withFeed/actions';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { goToQueryModal } from 'containers/QueryModals/helpers';
import { normaliceFilters } from 'utils/filters';
import { openModal } from 'smu-app-components/RootModalV2/actions';
import { selectKudosEnabled, selectCommunityId } from 'containers/Authorization/selectors';
import {
  ENABLED_ANNIVERSARIES,
  ENABLED_BADGES,
  ENABLED_BEHEALTHY,
  ENABLED_BEKIND,
  ENABLED_BETHERE_FEED,
  ENABLED_BETTERME,
  ENABLED_BIRTHDAYS,
  ENABLED_SHARE_DNA,
  ENABLED_STARMEUP,
} from 'services/communityConfigs/constants';
import {
  profileRedirectToCogratulationAnniversary,
  profileRedirectToCogratulationBirthday,
  redirectToCogratulationFeed,
} from 'utils/congratulations';

// @own
import './styles.scss';

function UnifiedProfile({
  communityId,
  enabledKudos,
  enabledShareDNA,
  feedSetOptions,
  location,
  openModal,
  preferences,
  receivedStarsFilters,
  router,
  sentStarsFilters,
  userInfo,
}) {
  const member = useMember();
  const showSendStar = /\/sendStar$/.test(location.pathname);

  const handleOnSendStarClick = () => {
    router.push(buildSendStarModalUrl({
      params: location.query,
    }));
  };

  const onChangeProfileImage = (file) => {
    openModal({
      modalType: SMU_GENERIC_MODAL,
      modalProps: {
        renderModal: (props) => <UploadProfileImageModal {...props} />,
        file,
        section: 'profile',
      },
    });
  };

  const applyFeedFilters = ({ filterBy, filters, options, userId }) =>
    feedSetOptions({
      filterBy,
      optionalFields: filters,
      userId,
      ...options,
    });

  const clearFeedFilters = ({ filterBy, options, userId }) =>
    feedSetOptions({
      ...options,
      filterBy,
      optionalFields: {},
      userId,
    });

  const socialTabComponents = {
    CELEBRATIONS_TAB: {
      Component: WithCongratulationsFeed,
    },
    RECEIVED_STARS_TAB: {
      Component: PaginatedFeed,
      filters: receivedStarsFilters,
      onApplyFilters: (props) => applyFeedFilters(props),
      onClearFilters: (props) => clearFeedFilters(props),
    },
    SENT_STARS_TAB: {
      Component: PaginatedFeed,
      filters: sentStarsFilters,
      onApplyFilters: (props) => applyFeedFilters(props),
      onClearFilters: (props) => clearFeedFilters(props),
    },
    TAGGED_POSTS_TAB: {
      Component: BethereFeed,
    },
    UPLOADED_POSTS_TAB: {
      Component: BethereFeed,
    },
  };

  const ownUid = get(userInfo, 'uid');

  function handleShareDNA() {
    trackEvent({
      action: 'share',
      category: 'dna',
      label: 'widget',
    });
    goToQueryModal(MODAL_SHARE_DNA, router, { opener: 'widget' });
  }

  function ShareDNA() {
    return (
      enabledShareDNA && (
        <div className="dna-widget__header-share" onClick={handleShareDNA}>
          <Icon icon="share" className="dna-widget__header-share-icon" />
        </div>
      )
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showSendStar && userInfo.id) {
      handleOnSendStarClick();
    }
  }, [showSendStar, userInfo?.id]);

  if (member.isLoading) {
    return <LottieSpinner className="h-screen w-screen flex items-center justify-center" />;
  }

  if (member.error) {
    return (
      <GenericErrorState
        action={{
          onClick: member.mutate,
        }}
      />
    );
  }

  return (
    <Profile
      DNAWidgetProps={{
        actions: [ShareDNA()],
        kudosDisabled: !enabledKudos,
      }}
      app="StarMeUp"
      className="unified-profile"
      communityId={communityId}
      components={{
        badges: Badges,
        highlightedStar: HighlightedStar,
        userProfile: UserProfile,
      }}
      onChangeProfileImage={onChangeProfileImage}
      onClickSeeCongratulations={() => redirectToCogratulationFeed(ownUid)}
      onClickSendStar={handleOnSendStarClick}
      onClickUserAnniversary={() => profileRedirectToCogratulationAnniversary(ownUid)}
      onClickUserBirthday={() => profileRedirectToCogratulationBirthday(ownUid)}
      ownUid={ownUid}
      preferences={preferences}
      socialTabComponents={socialTabComponents}
      user={member?.data?.result}
    />
  );
}

UnifiedProfile.propTypes = {
  communityId: PropTypes.number,
  enabledKudos: PropTypes.bool,
  feedSetOptions: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object,
  openModal: PropTypes.func,
  preferences: PropTypes.object,
  remainingStars: PropTypes.object,
  router: PropTypes.object,
  setInitial: PropTypes.func,
  userInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    filtersV2: { PROFILE, PROFILE_SENT },
  } = state;
  const remainingStars = get(state, 'starFlow.remainingStars');
  const userInfo = get(state, 'session.user');
  const enabledKudos = selectKudosEnabled(state);

  return {
    communityId: selectCommunityId(state),
    enabledKudos,
    enabledShareDNA: getCommunityConfigValueBool(state, ENABLED_SHARE_DNA),
    preferences: {
      enabledAnniversaries: getCommunityConfigValueBool(state, ENABLED_ANNIVERSARIES),
      enabledBadges: getCommunityConfigValueBool(state, ENABLED_BADGES),
      enabledBeHealthy: getCommunityConfigValueBool(state, ENABLED_BEHEALTHY),
      enabledBeHealthyNewQR: true,
      enabledBeKind: getCommunityConfigValueBool(state, ENABLED_BEKIND),
      enabledBeThere: getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED),
      enabledBetterMe: getCommunityConfigValueBool(state, ENABLED_BETTERME),
      enabledBirthdays: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
      enabledStarMeUp: getCommunityConfigValueBool(state, ENABLED_STARMEUP),
    },
    receivedStarsFilters: normaliceFilters(PROFILE),
    remainingStars,
    sentStarsFilters: normaliceFilters(PROFILE_SENT),
    userInfo,
  };
};

export default withRouter(
  withRootModal(
    connect(mapStateToProps, {
      feedSetOptions,
      openModal,
    })(UnifiedProfile),
  ),
);
