import {
  ACTION_ITEMS_ADD,
  ACTION_ITEMS_REMOVE,
  ACTION_ITEMS_UPDATE,
  ACTION_ITEMS_UPDATE_MULTIPLE,
} from './actionTypes';

export function actionItemsAdd(id, data) {
  return {
    type: ACTION_ITEMS_ADD,
    payload: {
      data,
      id,
    },
  };
}

export function actionItemsRemove(id) {
  return {
    type: ACTION_ITEMS_REMOVE,
    payload: {
      id,
    },
  };
}

export function actionItemsUpdate(id, data) {
  return {
    type: ACTION_ITEMS_UPDATE,
    payload: {
      data,
      id,
    },
  };
}

export function actionItemsUpdateMultiple(data) {
  return {
    type: ACTION_ITEMS_UPDATE_MULTIPLE,
    payload: {
      data,
    },
  };
}
