// @packages
import React from 'react';

// @app
import OnboardingPage from 'containers/OnboardingPage';

const OnboardingLayout = () => (
  <OnboardingPage/>
);

export default OnboardingLayout;
