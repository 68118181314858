import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'smu-ui-components/Button';

import logo from 'assets/SMU-logo.svg';
import './styles.scss';

const LivingValueSimpleView = ({
  buttonText,
  className,
  description,
  dismissText,
  onButtonClick,
  onDismissClick,
  onRemindClick,
  rememberText,
  title,
}) => (
  <div className={cn('simple-view', className)}>
    <div className="simple-view__header">
      <div>
        <img className="simple-view__logo" src={logo} width="250px" alt="logo" />
        <div className="simple-view__title">
          {title}
        </div>
        <div className="simple-view__description">
          {description}
        </div>
      </div>
    </div>
    <div className="simple-view__footer">
      {dismissText && onDismissClick &&
        <div className="simple-view__footer-button">
          <Button
            className="simple-view__footer-button--transparent"
            onClick={onDismissClick}
            noMargin
            transparent
          >
            {dismissText}
          </Button>
        </div>
      }
      {rememberText && onRemindClick &&
        <div className="simple-view__footer-button">
          <Button
            className="simple-view__footer-button--transparent"
            onClick={onRemindClick}
            noMargin
            transparent
          >
            {rememberText}
          </Button>
        </div>
      }
      <div className="simple-view__footer-button">
        <Button
          color="pink"
          fluid
          onClick={onButtonClick}
          noMargin
        >
          {buttonText}
        </Button>
      </div>
    </div>
  </div>
);

LivingValueSimpleView.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  dismissText: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  onDismissClick: PropTypes.func,
  onRemindClick: PropTypes.func,
  rememberText: PropTypes.string,
  title: PropTypes.string,
};

export default LivingValueSimpleView;
