// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import SkeletonComment from 'components/Skeletons/Comment';

const SkeletonAvatarMediaList = ({ className, total }) => {
  const array = Array.from(Array(total).keys());

  return (
    <div className={cn('skeleton-comment-list', className)}>
      {array?.map((i) => (
        <SkeletonComment
          className="skeleton-comment-list__item"
          key={`skeleton-comment-list-item-${i}`}
        />
      ))}
    </div>
  );
};

SkeletonAvatarMediaList.propTypes = {
  total: PropTypes.number,
};

SkeletonAvatarMediaList.defaultProps = {
  total: 6,
};

export default SkeletonAvatarMediaList;
