// @app
import { LIVING_VALUES_MODAL } from 'containers/LivingValues/constants';

function openLivingValues({
  closeModal,
  dispatch,
  onClose,
  openModal,
}) {
  const handleClose = () => {
    dispatch(closeModal());
    onClose();
  };

  return dispatch(
    openModal({
      modalType: LIVING_VALUES_MODAL,
      modalProps: { onClose: handleClose },
    }),
  );
}

export default openLivingValues;
