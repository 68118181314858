// @packages
import React from 'react';
import PropTypes from 'prop-types';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import cn from 'classnames';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import moment from 'moment';
import Paginator from 'smu-ui-components/DataTable/Paginator';

// @app
import QuestionHeader from 'components/QuestionnaireResults/QuestionHeader';
import Option from 'components/QuestionnaireResults/Option';

// @own
import './styles.scss';

function QuestionnaireResultsList({
  className,
  dateformat,
  description,
  listTexts,
  enabledMobile,
  onChangePage,
  isLoading,
  options,
  page,
  pageSize,
  summary,
  summaryTexts,
  title,
  totalItems,
}) {
  return (
    <div
      className={cn(
        'questionnaire-results-list',
        {
          'questionnaire-results-list--mobile': enabledMobile,
        },
        className,
      )}
    >
      <header className="questionnaire-results-list__header">
        <QuestionHeader
          className="questionnaire-results-graphics__header"
          description={description}
          title={title}
        />
        <div className="questionnaire-results-graphics__info-summary">
          <Option
            label={summaryTexts?.answered}
            enabledMobile={enabledMobile}
            value={summary?.numberOfAnsweredPeople || 0}
          />
        </div>
      </header>
      <div className="questionnaire-results-list__table">
        {isLoading && (
          <div className="questionnaire-results-list__table-loader">
            <LottieSpinner loadingType="starmeup" />
          </div>
        )}
        {!!options?.length && (
          <header className="questionnaire-results-list__table-header">
            <div className="questionnaire-results-list__table-column">
              {listTexts?.date}
            </div>
            <div className="questionnaire-results-list__table-column">
              {listTexts?.user}
            </div>
            <div className="questionnaire-results-list__table-column">
              {listTexts?.answers}
            </div>
          </header>
        )}
        <div className="questionnaire-results-list__table-body">
          {options.map(({ date, hint, member }, key) => (
            <div
              className="questionnaire-results-list__table-row"
              key={`row-${key}`}
            >
              <div className="questionnaire-results-list__table-column">
                {moment(date).format(dateformat)}
              </div>
              <div className="questionnaire-results-list__table-column">
                <AvatarMedia
                  firstName={member?.firstName}
                  identification={member?.uid}
                  job={member?.job}
                  lastName={member?.lastName}
                  noHash
                  profileImageCode={member?.imageCode}
                  target="_blank"
                  type="medium"
                />
              </div>
              <div className="questionnaire-results-list__table-column">
                {hint}
              </div>
            </div>
          ))}
        </div>
        {totalItems > pageSize && (
          <footer className="questionnaire-results-list__table-footer">
            <Paginator
              customMessages={{
                first: listTexts?.first,
                last: listTexts?.last,
              }}
              onPageChange={onChangePage}
              pageNumber={page + 1}
              pageSize={pageSize}
              totalItems={totalItems}
            />
          </footer>
        )}
      </div>
    </div>
  );
}

QuestionnaireResultsList.propTypes = {
  className: PropTypes.string,
  dateformat: PropTypes.string,
  description: PropTypes.string,
  listTexts: PropTypes.object,
  enabledMobile: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChangePage: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  summaryTexts: PropTypes.object,
  title: PropTypes.string,
  totalItems: PropTypes.number,
};

QuestionnaireResultsList.defaultProps = {
  dateformat: 'DD/MM/YYYY',
  listTexts: {
    answers: 'Answers',
    date: 'Date',
    first: 'First',
    last: 'Last',
    user: 'User',
  },
  enabledMobile: true,
  pageSize: 5,
  summaryTexts: {
    answered: 'Answered',
  },
};

export default QuestionnaireResultsList;
