import { paths } from 'utils/routes';

export default {
  path: paths.cookiesPolicy(),
  getComponent(nextState, callback) {
    import('containers/CookiesPolicyPage').then((moduleA) => {
      callback(null, moduleA.default);
    });
  },
};
