// @packages
import React from 'react';
import IconV2 from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

function IconText({
  icon,
  iconProps,
  text,
  ...rest
}) {
  return (
    <div className="smu-icon-text" {...rest}>
      <IconV2 className="smu-icon-text__icon" icon={icon} {...iconProps} />
      <span className="smu-icon-text__text">{text}</span>
    </div>
  );
}

IconText.propTypes = {
  icon: PropTypes.string.isRequired,
  iconProps: PropTypes.object,
  text: PropTypes.string.isRequired,
};

IconText.defaultProps = {
  iconProps: {},
};

export default IconText;
