// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import logoUsers from 'assets/img/logoUsers.svg';

// @own
import './styles.scss';

function DiscoverUsersDefault({ texts: { option1, option2, option3, title } }) {
  return (
    <div className="discover-users-default">
      <img alt="logo" className="discover-users-default__logo" src={logoUsers} />
      <div className="discover-users-default__texts">
        <h4 className="discover-users-default__title">{title}</h4>
        <ul className="discover-users-default__description">
          {option1 && (
            <li className="discover-users-default__option">
              <span>1.</span> {option1}
            </li>
          )}
          {option2 && (
            <li className="discover-users-default__option">
              <span>2.</span> {option2}
            </li>
          )}
          {option3 && (
            <li className="discover-users-default__option">
              <span>3.</span> {option3}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

DiscoverUsersDefault.propTypes = {
  texts: PropTypes.shape({
    option1: PropTypes.string,
    option2: PropTypes.string,
    option3: PropTypes.string,
    title: PropTypes.string,
  }),
};

DiscoverUsersDefault.defaultProps = {
  texts: {
    option1: '',
    option2: '',
    option3: '',
    title: '',
  },
};

export default DiscoverUsersDefault;
