// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';

// @app
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';
import Card from './Card';
import EmptyState from './EmptyState';

function Insights({
  isLoading,
  items,
  loadMoreMessage,
  onLoadMore,
  showLoadMore,
  skeletonCards,
}) {
  return (
    <div className="insights">
      <div className="insights__feed">
        {items.map((item) => (
          <div className="insights__card" key={item?.id}>
            <Card key={`insight-${item?.id}`} {...item} />
          </div>
        ))}
        {isLoading &&
          skeletonCards.map((key) => (
            <div className="insights__skeleton-card" key={`skeleton-card-${key}`}>
              <Skeleton animation="wave" height={250} />
            </div>
          ))}
        {items?.length < 1 && !isLoading && (
          <EmptyState />
        )}
      </div>
      {showLoadMore && !isLoading && (
        <footer className="insights__actions">
          <Button
            className="insights__load-more"
            onClick={() => {
              onLoadMore();
              trackEvent({
                action: 'Load_More',
                category: 'Insights',
              });
            }}
          >
            {loadMoreMessage}
          </Button>
        </footer>
      )}
    </div>
  );
}

Insights.propTypes = {
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  loadMoreMessage: PropTypes.string,
  onLoadMore: PropTypes.func,
  showLoadMore: PropTypes.bool,
  skeletonCards: PropTypes.array,
};

Insights.defaultProps = {
  isLoading: false,
  items: [],
  loadMoreMessage: 'Load more',
  onLoadMore: () => {},
  showLoadMore: true,
  skeletonCards: Array.from(Array(3).keys()),
};

export default Insights;
