// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// @app
import UserSettings from 'containers/UserSettings';
import { selectUserFetching } from 'services/userConfigs/selectors';

function UserSettingsLayout({ location }) {
  const group = location?.query?.group;
  const defaultExpandedId = group ? Number(group) : undefined;
  const isLoading = useSelector(selectUserFetching);

  return (
    <div className="pt-16 flex flex-col min-h-screen">
      {isLoading ? (
        <LottieSpinner
          className="flex-1 flex items-center justify-center"
          loadingType="starmeup"
        />
      ) : (
        <Container centered compact>
          <div className="py-5">
            <UserSettings defaultExpandedId={defaultExpandedId} />
          </div>
        </Container>
      )}
    </div>
  );
}

UserSettingsLayout.propTypes = {
  router: PropTypes.object,
};

export default UserSettingsLayout;
