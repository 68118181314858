/* eslint-disable react/no-danger */
// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import xss from 'xss';

// @own
import './styles.scss';

function QuestionnaireResultsQuestionHeader({ className, description, title }) {
  return (
    <header className={cn('questionnaire-results-question-header', className)}>
      <h4 className="questionnaire-results-question-header__title">{title}</h4>
      {description && (
        <span
          className="questionnaire-results-question-header__description"
          dangerouslySetInnerHTML={{ __html: xss(description) }}
        />
      )}
    </header>
  );
}

QuestionnaireResultsQuestionHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

QuestionnaireResultsQuestionHeader.defaultProps = {
  className: '',
  description: '',
};

export default QuestionnaireResultsQuestionHeader;
