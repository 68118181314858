import { call, put, takeEvery } from 'redux-saga/effects';
import { merge } from 'lodash';

import {
  GET_NEW_LEADERBOARD,
} from './actionTypes';

import {
  getNewLeaderboardFail,
  getNewLeaderboardSuccess,
} from './actions';

import { getNewLeaderboard as leaderboardAPI } from './api';

function* getNewLeaderboardWorker({ payload }) {
  const { updateLeaderboard, fields, persistedFields = {} } = payload || {};
  try {
    if (updateLeaderboard) {
      const results = yield call(leaderboardAPI, merge(fields, persistedFields));
      yield put(getNewLeaderboardSuccess(results));
    }
  } catch (error) {
    yield put(getNewLeaderboardFail(error));
  }
}

function* getNewLeaderboardWatcher() {
  yield takeEvery(GET_NEW_LEADERBOARD, getNewLeaderboardWorker);
}

export default {
  getNewLeaderboardWatcher,
};
