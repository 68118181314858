// @packages
import { browserHistory } from 'react-router';
import {
  call,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  filter as loFilter,
  isEmpty,
  map,
  merge,
  omit,
  reduce,
  uniqBy,
} from 'lodash';
import {
  getUserLanguage,
  getQueryLanguage,
  setCookieLanguage,
} from 'smu-utils/language';
import { navigateToLoginV2 } from 'smu-utils/globalRequestHelper';
import { openModal } from 'smu-app-components/RootModalV2/actions';
import { getOsApplications } from 'smu-app-components/Switcher/actions';

// @app
import parseInt from 'utils/parseInt';
import setLog from 'utils/logs';
import { CLOSE_MODAL } from 'smu-app-components/RootModalV2/actionTypes';
import {
  getItem,
  getToken,
} from 'smu-utils/storage';
import { filtersInitial } from 'containers/Filters/actions';
import { getFiltersByUses } from 'containers/FiltersV2/actions';
import { getLivingValuesTexts } from 'containers/LivingValues/actions';
import { getRemainingStars } from 'services/sendStarFlow/actions';
import { UNAUTHORIZED_ERROR, UNAUTHORIZED_ERROR_MESSAGE } from 'utils/constants';
import { USES } from 'containers/FiltersV2/constants';

// @own
import { REQUEST_AUTH, LOGOUT } from './actionTypes';
import {
  authorizeSuccess,
  authWidgetFail,
  logout,
} from './actions';
import {
  authorize as authorizeAPI,
} from './api';

const buildSession = ({ result, additionalInfo }, moreInfo) => {
  const cc = { ...additionalInfo, ...moreInfo };
  const basic = map(cc.values || [], star => ({
    ...star,
    minimum: 1,
    maximum: cc.maxUsersToGiveOn || 1,
  }));
  const defaultMinimum = 2;
  const kudos = {
    ...(cc.valueKudos || {}),
    minimum: cc.minDestinationUsersKudos || defaultMinimum,
    maximum: cc.maxDestinationUsersKudos || defaultMinimum,
    isKudos: true,
  };
  return {
    user: result,
    communityConfigurations: omit(cc, [
      'values',
      'valueKudos',
      'minDestinationUsersKudos',
      'maxDestinationUsersKudos',
      'maxUsersToGiveOn',
    ]),
    stars: cc.valueKudos ? [kudos, ...basic] : basic,
    starsDisabled: cc.valuesDisabled ? cc.valuesDisabled : undefined,
  };
};
const extractCustomFilters = (session) => {
  const cc = session.communityConfigurations;
  const getNames = fs => map(fs, f => f.field);
  const getChildren = (checkFilter, filters) =>
    loFilter(filters, f => f.parentField === checkFilter.field);
  const getAllChildren = (toTraverse, all, children = []) => {
    if (isEmpty(toTraverse)) {
      return children;
    }
    const [child, ...moreToTraverse] = toTraverse;
    return getAllChildren(
      [...getChildren(child, all), ...moreToTraverse],
      all,
      uniqBy([...children, ...getChildren(child, all)], f => f.field),
    );
  };

  let filters = cc.customFields || [];
  filters = map(filters, (filter) => {
    const children = getChildren(filter, filters);
    const grandChildren = getAllChildren(children, filters);
    return merge(
      filter,
      isEmpty(children) ? {} : { children: getNames(children) },
      isEmpty(grandChildren) ? {} : { grandChildren },
    );
  });
  filters = reduce(
    filters,
    (memo, filter) => {
      memo[filter.field] = omit(filter, ['field']);
      return memo;
    },
    {},
  );
  return {
    customFilters: filters,
    session,
  };
};

function logoutWorker() {
  setLog('LOGOUT_ACTION');
  navigateToLoginV2('SMU_WEB_v3');
}

function* authorizeWorker({ payload: { data, widget }}) {
  try {
    const token = yield call(getToken);
    if (!token) {
      if (widget) {
        yield put(authWidgetFail());
      } else {
        setLog('TOKEN_NOT_FOUND');
        navigateToLoginV2('SMU_WEB_v3');
      }
      return;
    }
    localStorage.removeItem('redirect');
    if (!data) {
      data = yield call(authorizeAPI);
    }
    yield put(getRemainingStars());
    const queryLanguage = getQueryLanguage();
    if (queryLanguage) {
      setCookieLanguage(queryLanguage);
    } else {
      setCookieLanguage(data?.result?.language?.code);
    }
    if (sessionStorage) {
      sessionStorage.setItem('connectedUser', JSON.stringify(data.result));
      sessionStorage.setItem('additionalInfo', JSON.stringify(data.additionalInfo));
    }
    const maxUsersToGiveOn = yield call(getItem, 'maxUsersToGiveOn');
    const { session, customFilters } = extractCustomFilters(buildSession(data, {
      maxUsersToGiveOn: parseInt(maxUsersToGiveOn),
    }));
    const filters = merge(customFilters, {
      country: {
        children: ['city'],
      },
      city: {
        parentField: 'country',
        children: ['office'],
      },
      office: {
        parentField: 'city',
      },
      organizationValue: {
        label: 'Type',
        fields: map(session.stars, star => ({
          label: star.nameShowed,
          value: String(star.id),
        })),
      },
      typeLeaderboard: {
        label: 'Action',
        fields: [
          {
            label: 'Received',
            value: '0',
          },
          {
            label: 'Sent',
            value: '1',
          },
        ],
      },
    });
    yield put(filtersInitial(filters));
    const { LEADERBOARD, PROFILE, PROFILE_SENT } = USES;
    const {
      communityConfigurations: {
        organization: {
          id: organizationId,
        },
      },
    } = session;
    yield put(getFiltersByUses({
      organizationId,
      use: `${LEADERBOARD},${PROFILE},${PROFILE_SENT}`,
    }));
    yield put(getLivingValuesTexts({
      languageCode: getUserLanguage(),
      name: 'living_values',
    }));
    yield put(authorizeSuccess(session));
    if (session?.communityConfigurations?.tcppOn && !session?.user?.tcppAccept) {
      yield put(openModal({ modalType: 'TCANDPP_MODAL' }));
      yield take([CLOSE_MODAL]);
    }
    if (!session?.communityConfigurations?.eulaAgreement) {
      yield put(openModal({ modalType: 'EULA_MODAL' }));
      yield take([CLOSE_MODAL]);
    }
    const {
      communityConfigurations: { livingValuesEnabled, userLivingValuesEnabled },
    } = session;
    const location = browserHistory?.getCurrentLocation();
    const isHome = location?.pathname === '/home' && isEmpty(location?.query);
    if (livingValuesEnabled && userLivingValuesEnabled && isHome) {
      yield call(browserHistory.push, '/initiatives');
    }
    yield put(getOsApplications({ params: { o: 'SMU_WEB_v3', v: 3 } }));
  } catch (e) {
    if ((e.status === UNAUTHORIZED_ERROR || e.message === UNAUTHORIZED_ERROR_MESSAGE) && !widget) {
      yield put(logout({ widget }));
    }
    if (widget) {
      yield put(authWidgetFail());
    }
  }
}

export function* authorizeWatcher() {
  yield takeEvery(REQUEST_AUTH, authorizeWorker);
}

export function* logoutWatcher() {
  yield takeLatest(LOGOUT, logoutWorker);
}
