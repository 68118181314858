import { merge, filter } from 'lodash';
import { takeEvery, call, put } from 'redux-saga/effects';

import { actionTypes, actions } from './actions';
import {
  requestAvailableFilters as requestAvailableFiltersAPI,
  requestAdvancedSearch as requestAdvancedSearchAPI,
} from './api';

function* requestAvailableFiltersWorker({ payload }) {
  try {
    const data = yield call(requestAvailableFiltersAPI);
    const [
      inputFilters,
      selectFilters,
    ] = [
      filter(data, f => f.type === 'STRING'),
      filter(data, f => f.type === 'LIST'),
    ];
    yield put(actions.requestSearchFiltersResult({
      inputFilters,
      selectFilters,
    }));
    const { dispatch } = payload || {};
    if (dispatch) {
      yield put({
        ...dispatch,
        payload: merge(dispatch.payload, {
          availableFilters: selectFilters,
          fields: payload.fields || {},
        }),
      });
    }
  } catch (e) {
    yield put(actions.requestSearchFiltersResult([]));
  }
}

export function* requestAvailableFiltersWatcher() {
  yield takeEvery(actionTypes.requestSearchFilters, requestAvailableFiltersWorker);
}

function* requestAdvancedSearchWorker({ payload }) {
  try {
    const {
      fields, inputFields, page, labels,
    } = payload;
    const data = yield call(requestAdvancedSearchAPI, { fields, page, labels });
    yield put(actions.requestAdvancedSearchResults({
      fields: inputFields ? merge(fields, inputFields) : fields,
      page,
      results: data.content || [],
      total: data.total,
    }));
  } catch (e) {
    yield put(actions.requestAdvancedSearchError(e));
  }
}

export function* requestAdvancedSearchWatcher() {
  yield takeEvery(actionTypes.requestAdvancedSearch, requestAdvancedSearchWorker);
}

export default {
  requestAvailableFiltersWatcher,
  requestAdvancedSearchWatcher,
};
