// @app
import { MODAL_LIVING_VALUES, MODAL_ONBOARDING } from 'containers/QueryModals/constants';

export const getUserMenuLinks = ({
  handleOpenModalSuggestions,
  hasLivingValuesTexts,
  intercomEnabled,
  livingValuesEnabled,
  livingValuesTexts,
  location,
  logout,
  onboardingEnabled,
  renderLink,
}) => ({
  helpCenter: {
    ...intercomEnabled
    ? {
      href: undefined,
      onClick: () => window.Intercom && window.Intercom('showSpace', 'help'),
    } : {
      target: '_blank',
      url: ({ language }) => (
        `https://customerportal.starmeup.com/${language === 'es' ? 'es' : 'en'}`
      ),
    },
  },
  livingValues: {
    children: livingValuesTexts?.menuTitle,
    href: `${location.pathname}?modal=${MODAL_LIVING_VALUES}`,
    renderLink,
    show: livingValuesEnabled && hasLivingValuesTexts,
  },
  logout: {
    onClick: logout,
  },
  quickTour: {
    href: `${location.pathname}?modal=${MODAL_ONBOARDING}`,
    renderLink,
    show: onboardingEnabled,
  },
  settings: {
    href: '/settings/account',
    renderLink,
  },
  suggestions: {
    onClick: handleOpenModalSuggestions,
  },
  whatsNew: {
    ...intercomEnabled
      ? {
        onClick: () => window.Intercom && window.Intercom('showSpace', 'news'),
      } : {
        href: 'https://news.intercom.com/starmeupnews',
        target: '_blank',
      },
  },
});
