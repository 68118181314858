import { put, call, takeLatest } from 'redux-saga/effects';
import {
  GET_HIGHLIGHTED_STAR,
  GET_HIGHLIGHTED_STAR_SUCCESS,
  GET_HIGHLIGHTED_STAR_FAIL,
} from './actionTypes';
import { getHighlightedStar } from './api';

const setLikeCount = (stars) => {
  const starsWithLikes = stars.map((star) => {
    let likeCount = 0;
    star.comments.forEach((iteraction) => {
      if (iteraction.type === 'LIKE') {
        likeCount += 1;
      }
    });
    return {
      ...star,
      likeCount,
    };
  });
  return starsWithLikes;
};

function* getHighlightedStarWorker({ payload }) {
  try {
    const result = yield call(getHighlightedStar, payload);
    const { status, messsage } = result;

    if (status === 'FAIL') {
      yield put({ type: GET_HIGHLIGHTED_STAR_FAIL, payload: messsage });
    }

    const postResults = setLikeCount(result);
    yield put({ type: GET_HIGHLIGHTED_STAR_SUCCESS, payload: postResults });
  } catch (error) {
    yield put({ type: GET_HIGHLIGHTED_STAR_FAIL, payload: error });
  }
}

function* getHighlightedStarWatcher() {
  yield takeLatest(GET_HIGHLIGHTED_STAR, getHighlightedStarWorker);
}

export default {
  getHighlightedStarWatcher,
};
