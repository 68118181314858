import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from './../../api';

export function getCommunityCounters() {
  const baseUrl = '/v2/stellar/communityCounters';
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times() }),
    method: 'get',
  })
    .then(({ data }) => (data)).catch(errorHandler);
}
