import { defineMessages } from 'react-intl';

export default defineMessages({
  readMore: {
    id: 'Insights.ReadMore',
    defaultMessage: 'Learn more',
  },
  'leader.activities.cultural_low_your_led.title': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.title',
    defaultMessage: 'Cultural activity of your led',
  },
  'leader.activities.cultural_low_your_led.indicator': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.indicator',
    defaultMessage: 'of the collaborators you lead had cultural activity (interactions) in the last 3 months',
  },
  'leader.feedback.not_received_for_your_led.title': {
    id: 'os.workflows.leaderFeedbackNot_received_for_your_led.title',
    defaultMessage: 'Collaborators to give feedback',
  },
  'leader.feedback.not_received_for_your_led.indicator': {
    id: 'os.workflows.leaderNot_received_for_your_led.indicator',
    defaultMessage: 'collaborators you lead did not receive feedback from you in the last 3 months',
  },
  'leader.feedback.not_sent_to_your_led.title': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.title',
    defaultMessage: 'Feedback not sent to your led',
  },
  'leader.feedback.not_sent_to_your_led.indicator': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.indicator',
    defaultMessage: 'collaborators you lead did not receive any feedback in the last 3 months',
  },
  'managers.no.feedback.title': {
    id: 'os.workflows.managersNoFeedback.title',
    defaultMessage: 'Feedback received by members in your {area}',
  },
  'managers.no.feedback.indicator': {
    id: 'os.workflows.managersNoFeedback.indicator',
    defaultMessage: 'Members in {name} have received any feedback in the last 3 months',
  },
  'no.active.new.hires.title': {
    id: 'os.workflows.noActiveNewHires.title',
    defaultMessage: 'New hires with cultural activity in your {area}',
  },
  'no.active.new.hires.indicator': {
    id: 'os.workflows.noActiveNewHires.indicator',
    defaultMessage: 'of the new employees in {name} showed cultural activity in the last 3 months',
  },
  'user.interactions.title': {
    id: 'os.workflows.userInteractions.title',
    defaultMessage: 'Cultural influencers',
  },
  'user.interactions.indicator': {
    id: 'os.workflows.userInteractions.indicator',
    defaultMessage: 'Interactions with colleagues in the last 3 months',
  },
  'area.turnover.rate.title': {
    id: 'os.workflows.areaTurnoverRate.title',
    defaultMessage: 'Turnover rate in your {area}',
  },
  'area.turnover.rate.description': {
    id: 'os.workflows.areaTurnoverRate.description',
    defaultMessage: 'Turnover percentage considers the last 12 months from the current month.',
  },
  'area.turnover.rate.indicator': {
    id: 'os.workflows.areaTurnoverRate.indicator',
    defaultMessage: 'Was the turnover rate of {name} you lead in the last 12 months',
  },
  'user.cultural.activity.title': {
    id: 'os.workflows.userCulturalActivity.title',
    defaultMessage: 'Your colleagues in the community',
  },
  'user.cultural.activity.indicator': {
    id: 'os.workflows.userCulturalActivity.indicator',
    defaultMessage: 'is your position according to your cultural activity for ',
  },
  'user.interactions.insight.collaborators.interactions.rising': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRising',
    defaultMessage: 'You are Rising!',
  },
  'user.interactions.insight.collaborators.interactions.rocking': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRocking',
    defaultMessage: 'You are Legendary!',
  },
  'user.interactions.insight.collaborators.interactions.shining': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsShining',
    defaultMessage: 'You are Shinning!',
  },
  'user.interactions.insight.collaborators.interactions.rising.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRisingDescription',
    defaultMessage: 'You have the potential to make a big impact in your organization. Seize it!',
  },
  'user.interactions.insight.collaborators.interactions.rocking.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRockingDescription',
    defaultMessage: 'You are generating a visible change in the lives of your colleagues.',
  },
  'user.interactions.insight.collaborators.interactions.shining.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsShiningDescription',
    defaultMessage: 'You have a positive impact on your organization and are on your way to making even more change.',
  },
  'user.interactions.insight.collaborators.interactions.same.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.SameDescription',
    defaultMessage: 'You stay at the same level about the previous period. Increase your interactions and keep growing.',
  },
  'user.interactions.insight.collaborators.interactions.up.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.UpDescription',
    defaultMessage: 'You level up compared to the previous period. Keep it up!',
  },
  'user.interactions.insight.collaborators.interactions.down.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.DownDescription',
    defaultMessage: 'You lowered your level compared to the previous period. Anyway, you are doing very well. You are still a cultural influencer! Pick up your interactions and keep growing.',
  },
  'user.cultural.activity.insight.collaborators.interactions.rising': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRising',
    defaultMessage: 'You are Rising!',
  },
  'user.cultural.activity.insight.collaborators.interactions.rocking': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRocking',
    defaultMessage: 'You are Radiant!',
  },
  'user.cultural.activity.insight.collaborators.interactions.shining': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsShining',
    defaultMessage: 'You are Shining!',
  },
  'user.cultural.activity.insight.collaborators.interactions.rising.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRisingDescription',
    defaultMessage: 'You have the potential to make a big impact in your community. Seize it!',
  },
  'user.cultural.activity.insight.collaborators.interactions.rocking.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRockingDescription',
    defaultMessage: 'You have a positive impact on your community and are on your way to making even more change.',
  },
  'user.cultural.activity.insight.collaborators.interactions.shining.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsShiningDescription',
    defaultMessage: 'You are generating a visible change in the lives of your colleagues.',
  },
  'user.cultural.valueofthemonth.title': {
    id: 'os.workflows.userCulturalValueOfTheMonth.title',
    defaultMessage: 'Who represent this value?',
  },
});
