export function removeMentionedNameFromComment(comment) {
  const mentionedUserRegexp = /@[[]\d{1,}[\]][(][a-zA-ZÀ-ÿ\d\s\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{1,}[)]/g;
  const mentionedFullName = /[(][a-zA-Z\d\-_\s].*[)]/g;
  let cleanedMentionedComment = comment.replace(mentionedUserRegexp, matched =>
    matched.replace(mentionedFullName, ''));
  return cleanedMentionedComment;
}

export function extractUidOnMention(comment) {
  const numberRegex = /[\d]{1,}/g;
  let mentionedId = comment.match(numberRegex);
  return mentionedId;
}
