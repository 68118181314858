// @app
import { defaultApi } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export const apiGetInsight = ({ insightId, params }) =>
  defaultApi({
    data: {},
    headers: { 'Content-Type': 'application/json' },
    params,
    url: `/briefme/api/v1/community/member/insights/${insightId}`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);

export const apiGetUserList = ({ userList }) =>
  defaultApi({
    url: `/os-member/api/v2/community/members?members=${userList}`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
