import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';
import Agreement from 'containers/Agreement';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import * as actions from './actions';

import messages from './messages';

const TCAndPPAgreement = ({
  intl,
  saveTCAndPPAgreement,
}) => (
  <Agreement
    agreement={<FormattedHTMLMessage {...messages.body} />}
    checkboxText={intl.formatMessage(messages.checkboxText)}
    eventCategory="TTCC PP"
    lblCancelBtn={intl.formatMessage(messages.cancel)}
    lblContinueBtn={intl.formatMessage(messages.continue)}
    saveAgreement={saveTCAndPPAgreement}
    title={intl.formatMessage(messages.title)}
  />
);

TCAndPPAgreement.propTypes = {
  intl: PropTypes.object,
  saveTCAndPPAgreement: PropTypes.func,
};

const connectedEula = connect(null, actions)(injectIntl(TCAndPPAgreement));

export default RootModal(connectedEula, 'TCANDPP_MODAL');
