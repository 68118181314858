// @packages
import React from 'react';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import Tabs from 'smu-ui-components/Tabs';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// @app
import BethereFeed from 'containers/BethereFeed';
import FeedFilters from 'containers/FeedFilters';
import OSFeed from 'containers/OSFeed';
import Star from 'components/Star';
import { ENABLED_BETHERE_FEED, ENABLED_FEED_FILTERS } from 'services/communityConfigs/constants';
import { SUPERNOVA } from 'containers/OSFeed/constants';
import { actions as routeActions } from 'utils/routes';
import { apiInstance } from 'utils/requestHelper';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { selectOsFeedEnabled } from 'containers/OSFeed/selectors';
import { selectSessionUserRights } from 'containers/Authorization/selectors';
import { trackEvent, trackPageView } from 'utils/gtm';

// @own
import './styles.scss';
import messages from './messages';
import {
  BETHERE_TAB,
  CORE_VALUE,
  HIGHLIGHTS_TAB,
  KUDOS,
  MOMENTS_TAB,
  STARMEUP,
  STARS_TAB,
} from './constants';

function FeedV2({
  enabledBethere,
  enabledFeedFilters,
  filters,
  intl: { formatMessage },
  onLoaded,
  router,
  showFeedOrderByToggle,
  showFilters,
  showProfile,
  showTabs,
  userRights,
}) {
  const queryTab = router?.location?.query?.tab;
  const showBethere = !enabledFeedFilters && enabledBethere;
  const showBethereFeed = showBethere && queryTab === BETHERE_TAB;
  const showHighlights = !enabledFeedFilters && queryTab === HIGHLIGHTS_TAB;
  const tabIndex = showBethereFeed || showHighlights ? 1 : 0;
  const showStars = enabledFeedFilters && queryTab === STARS_TAB;
  const showMoments = enabledFeedFilters && queryTab === MOMENTS_TAB;
  const filterType = showStars || showMoments ? queryTab.toUpperCase() : undefined;
  const isMedia = showBethereFeed || showMoments;

  const goToTab = (name) => router.push(`${router.location.pathname}${name ? `?tab=${name}` : ''}`);

  const clickRecent = () => {
    trackEvent({
      category: 'Home',
      action: 'Recent',
      label: 'Click on Tab',
    });
    goToTab();
  };

  const clickMostLiked = () => {
    trackEvent({
      action: 'Highlights',
      category: 'Home',
      label: 'Click on Tab',
    });
    goToTab(HIGHLIGHTS_TAB);
  };

  const clickBethere = () => {
    trackEvent({
      action: 'click_on_tab',
      category: 'home_bethere',
    });
    goToTab(BETHERE_TAB);
    trackPageView({
      path: '/home/bethere',
    });
  };

  const tabs = [
    {
      name: formatMessage(messages.recent),
      onClick: clickRecent,
    },
  ];

  if (enabledBethere) {
    tabs.push({
      name: formatMessage(messages.bethere),
      onClick: clickBethere,
    });
  } else {
    tabs.push({
      name: formatMessage(messages.mostLiked),
      onClick: clickMostLiked,
    });
  }

  return (
    <div className="feedv2">
      {showFilters && enabledFeedFilters && (
        <FeedFilters
          api={apiInstance}
          className="feedv2__filters"
          filter={queryTab}
          onClickFilter={goToTab}
        />
      )}
      {showTabs && !enabledFeedFilters && (
        <Panel noPadding className="feedv2__tabs-filter">
          <Tabs tabs={tabs} active={tabIndex} />
        </Panel>
      )}
      {showBethereFeed ? (
        <BethereFeed showNewPost />
      ) : (
        <OSFeed
          api={apiInstance}
          app={STARMEUP}
          filters={{
            type: filterType,
            ...filters,
          }}
          isMedia={isMedia}
          onLoaded={onLoaded}
          showFeedOrderByToggle={showFeedOrderByToggle && !enabledFeedFilters}
          showHighlights={showHighlights}
          showProfile={showProfile}
          userRights={userRights}
        >
          <Star type={CORE_VALUE} />
          <Star type={KUDOS} />
          <Star type={SUPERNOVA} />
        </OSFeed>
      )}
    </div>
  );
}

FeedV2.propTypes = {
  enabledBethere: PropTypes.bool,
  enabledFeedFilters: PropTypes.bool,
  filters: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onLoaded: PropTypes.func,
  osFeedEnabled: PropTypes.bool,
  router: PropTypes.object.isRequired,
  showFeedOrderByToggle: PropTypes.bool,
  showFilters: PropTypes.bool,
  showProfile: PropTypes.func.isRequired,
  showTabs: PropTypes.bool,
  userRights: PropTypes.array.isRequired,
};

FeedV2.defaultProps = {
  showFeedOrderByToggle: true,
  showFilters: true,
  showTabs: true,
};

const mapStateToProps = (state) => {
  const osFeedEnabled = selectOsFeedEnabled(state);
  const userRights = selectSessionUserRights(state);
  const enabledBethere = getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED);
  const enabledFeedFilters = getCommunityConfigValueBool(state, ENABLED_FEED_FILTERS);

  return {
    enabledBethere,
    enabledFeedFilters,
    osFeedEnabled,
    userRights,
  };
};

const actions = {
  showProfile: (profileId) => routeActions.profile({ profileId }),
};

export default withRouter(connect(mapStateToProps, actions)(injectIntl(FeedV2)));
