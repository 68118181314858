// @packages
import React from 'react';
import { FormattedRelative } from 'react-intl';
import { buildImageUrl } from 'smu-utils/image';

// @app
import messages from 'components/Star/messages';
import parseDate from 'components/OS/common/utils/parseDate';
import { fullName } from 'utils/user';

export const getIsCustomerStar = (starType) =>
  starType === 'R' || starType === 'M';

export const getProfileUrl = (user) => {
  if (user?.enabled && user?.uid) {
    return `/profile/${user?.uid}`;
  }

  return '';
};

export const getFullName = (user) => fullName(user, 'firstName', 'lastName');

export const getMainPhoto = (comments) =>
  comments?.find((c) => c.type === 'MAIN_PHOTO')?.imageCode;

export const getMessages = (comments) =>
  comments?.filter((c) => c?.type === 'COMMENT' || c?.type === 'IMAGE');

export const getTemplateProps = (themeHashtag) => {
  if (themeHashtag?.starsTopLeftCornerImageCode) {
    return {
      style: {
        backgroundImage: `url(${themeHashtag?.starsTopLeftCornerImageCode})`,
        backgroundPosition: 'left top',
        backgroundRepeat: 'no-repeat',
      },
    };
  }

  return {};
};

export const getMenuProps = (props) => ({
  isModerate: props?.isModerate,
  onModerate: props?.onModerateStar,
  showCopyUrl: true,
  showEdit: props?.canEdit,
  showModerate: props?.isModerator,
  url: props?.starUrl,
});

export const getDateTimeProps = (date) => {
  if (date) {
    const dateFormatted = parseDate(date);
    const customDate = dateFormatted.toDate();
    return {
      children: <FormattedRelative value={customDate} />,
    };
  }

  return {};
};

export const getUserAvatarProps = (user, themeHashtag) => ({
  borderColor: themeHashtag?.color,
  height: 72,
  href: user?.uid ? `/profile/${user?.uid}` : '',
  imageIcon: themeHashtag?.starsAvatarOverlayImageCode,
  src: buildImageUrl({
    code: user?.profileImageCode || 'none',
    width: 72,
    height: 72,
  }),
  width: 72,
});

export const getUserBadgeProps = (user, isStarReceiver) => ({
  level: user?.properties?.culturalLevelNumber,
  popupProps: {
    owner: isStarReceiver,
    userName: user?.firstName,
  },
  showName: false,
});

export const getStarTitleProps = (context) => {
  const star = context?.data?.starMeUpOrganizationStar;

  return {
    color: star?.backgroundColor,
    imageSrc: buildImageUrl({
      code: star?.imageCode || 'none',
      width: 36,
      height: 36,
    }),
    text: star?.name,
  };
};

export const getDescriptionProps = (context) => ({
  children: context?.data?.notes,
  urlProps: {
    externalRedirect: true,
  },
  users: context?.data?.listMentions?.map((u) => ({
    fullName: u?.fullName,
    id: u?.id,
    uid: u?.uid,
  })),
});

export const getSendByProps = (props, context) => {
  const userFrom = context?.data?.from;
  const { formatMessage, ownId } = props;
  const uid = userFrom?.uid;
  const isCustomerStar = getIsCustomerStar(context?.data?.typeStar);
  const href = !isCustomerStar && uid ? `/profile/${uid}` : undefined;
  const userName =
    userFrom.id === ownId
      ? formatMessage(messages.me)
      : getFullName(userFrom, 'firstName', 'lastName');

  return {
    href,
    imageSrc: buildImageUrl({
      code: userFrom?.profileImageCode || 'none',
      height: 27,
      width: 27,
    }),
    text: formatMessage(messages.by),
    userName,
  };
};

export const getActionBrightenProps = (context) => {
  const data = context?.data;

  return {
    liftState: data?.liftState,
    quantityUsersLift: data?.quantityUsersLift,
  };
};
