import {
  SEND_EXPERIENCE,
} from './actionTypes';

export function sendExperience(payload) {
  return {
    type: SEND_EXPERIENCE,
    payload,
  };
}
