import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams } from '../../api';

export function getInteractions({ uid }) {
  return apiInstance({
    url: addParams('/v2/stellar/interactions', {
      userId: uid,
    }),
    method: 'get',
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}
