import {
  GET_FILTERS_BY_USES,
  GET_FILTERS_BY_USES_FAIL,
  GET_FILTERS_BY_USES_SUCCESS,
  UPDATE_FILTERS_VALUES,
} from './actionTypes';

const initialState = {
  error: null,
  fetching: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_FILTERS_BY_USES:
      return {
        ...state,
        fetching: true,
      };
    case GET_FILTERS_BY_USES_FAIL:
      return {
        ...state,
        fetching: false,
        ...payload,
      };
    case GET_FILTERS_BY_USES_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case UPDATE_FILTERS_VALUES:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
