import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import cn from 'classnames';
import Button from 'smu-ui-components/Button';

import StarCard from '../StarCard';

import './styles.scss';

function matchUserImageCode(id, users) {
  let imageCode;
  const userMatched = find(users, { starId: id });
  if (userMatched) {
    imageCode = userMatched.user.profileImageCode;
  }
  return imageCode;
}

function matchUserName(id, users) {
  let userName;
  const userMatched = find(users, { starId: id });
  if (userMatched) {
    userName = `${userMatched.user.firstName} ${userMatched.user.lastName}`;
  }
  return userName;
}

const LivingValueView = ({
  buttonText,
  className,
  closeButton,
  defaultProfileImageCode,
  description,
  onButtonClick,
  onCardClick,
  stars,
  title,
  usersSelected,
  valuesDone,
}) => (
  <div className={cn(
    className,
    'living-values',
  )}
  >
    <div className="living-values__header">
      {closeButton && closeButton()}
      <div className="living-values__title">
        {title}
      </div>
      <div className="living-values__description">
        {description}
      </div>
    </div>
    <div className="living-values__values">
      {stars.map(star => (
        <StarCard
          color={star.backgroundColor}
          defaultProfileImageCode={defaultProfileImageCode}
          disabled={valuesDone && valuesDone.some(valueId => Number(valueId) === star.id)}
          imageCode={star.imageCode}
          key={star.id}
          name={star.nameShowed}
          onCardClick={() => onCardClick(star)}
          profileImageCode={matchUserImageCode(star.id, usersSelected)}
          userName={matchUserName(star.id, usersSelected)}
        />
      ))}
    </div>
    <div className="living-values__button">
      <Button
        color="pink"
        disabled={usersSelected.length === 0}
        fluid
        onClick={onButtonClick}
        noMargin
      >
        {buttonText}
      </Button>
    </div>
  </div>
);

LivingValueView.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  closeButton: PropTypes.node,
  defaultProfileImageCode: PropTypes.string,
  description: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  onCardClick: PropTypes.func,
  stars: PropTypes.array,
  title: PropTypes.string,
  usersSelected: PropTypes.array,
  valuesDone: PropTypes.array,
};

export default LivingValueView;
