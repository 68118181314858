// @packages
import React from 'react';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl } from 'react-intl';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import PropTypes from 'prop-types';

// @app
import { trackEvent } from 'utils/gtm';

// @own
import { buildOptions } from './constants';
import './styles.scss';
import messages from '../messages';

function LineGraph({
  eventData,
  graph,
  intl: { formatMessage },
  isWebView,
  type,
}) {
  moment.locale(getUserLanguage());

  const userData = Object.keys(graph?.values?.[0]?.data)
    .map((key) => graph?.values?.[0]?.data[key]);
  const categoriesDate = Object.keys(graph?.values?.[0]?.label)
    .map((key) => moment(graph?.values?.[0]?.label[key], 'YYYY-MM').format('MMM'));
  const communityData = Object.keys(graph?.values?.[1]?.data)
    .map((key) => graph?.values?.[1]?.data[key]);

  const MAIN_LINE_COLOR = '#cf21d6';
  const SHADOW_FILL_COLOR = '#d2d4d6';
  const SHADOW_LINE_COLOR = '#dee0e3';

  const config = {
    ...buildOptions,
    chart: {
      ...buildOptions.chart,
      width: isWebView ? 300 : 400,
    },
    xAxis: {
      ...buildOptions.xAxis,
      categories: categoriesDate,
      title: {
        text: formatMessage(messages.interacionsInLastMonths),
      },
    },
    yAxis: {
      ...buildOptions.yAxis,
      allowDecimals: false,
      title: {
        text: formatMessage(messages.interations),
      },
    },
    tooltip: {
      enabled: true,
      outside: true,
      valueDecimals: 0,
    },
    series: [{
      color: SHADOW_LINE_COLOR,
      fillColor: {
        linearGradient: [0, 0, 0, 300],
        stops: [
          [0, SHADOW_FILL_COLOR],
          [1, 'white'],
        ],
      },
      data: communityData,
      fillOpacity: 0.25,
      lineWidth: 2,
      marker: {
        enabled: false,
        lineWidth: 2,
        lineColor: 'white',
      },
      name: formatMessage(messages.AverageCommunity),
      type: 'areaspline',
    }, {
      color: MAIN_LINE_COLOR,
      cursor: 'pointer',
      data: userData,
      lineWidth: 2.5,
      marker: {
        symbol: 'cross',
        radius: 6,
        lineWidth: 1,
        lineColor: 'white',
      },
      name: formatMessage(messages.mainUser),
      type: 'spline',
    }],
  };

  return (
    <div
      className="insights-content__line-graph"
      onClick={() => trackEvent({
        action: `Interaction_${type}`,
        category: 'Insights',
        ...eventData,
      })}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={config}
      />
    </div>
  );
}

LineGraph.propTypes = {
  graph: PropTypes.object,
};

export default injectIntl(LineGraph);
