// @app
import { smuInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { times } from 'api';

export function apiGetAdvancedUserFilters() {
  return smuInstance({
    data: {},
    params: times(),
    url: '/starmeup-api/v2/stellar/userFields',
  })
    .then((data) => data)
    .catch(errorHandler);
}
