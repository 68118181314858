// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import useModal from 'smu-custom-hooks/useModal';
import usePrevious from 'smu-custom-hooks/usePrevious';
import useTheme from 'smu-custom-hooks/useTheme';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { openModal } from 'smu-app-components/RootModal/actions';
import { selectRequest } from 'smu-utils/reduxRequests/selectors';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import ModalConfirmation from 'components/Modals/Confirmation';
import ModalPostInteractions from 'containers/Modals/PostInteractions';
import ModalTagguedUsers from 'containers/Modals/TagguedUsers';
import SkeletonMediaItem from 'components/Skeletons/MediaItem';
import { actionItemsUpdate } from 'services/items/actions';
import { buildShareModalUrl } from 'containers/QueryModals/helpers';
import { selectItemById } from 'services/items/selectors';
import {
  selectCommunityId,
  selectIsModerator,
  selectSessionUserId,
  selectSessionUserUid,
} from 'containers/Authorization/selectors';
import commonMessages from 'common/messages';

// @own
import {
  apiAddInteractionById,
  apiDeletePost,
  apiGetPostById,
  apiRemoveLikeById,
} from './api';
import {
  TYPE_COMMENT,
  getRequestIdCard,
  getRequestIdCardComment,
  getRequestIdCardLike,
  getRequestIdCardLikeRemove,
  getRequestIdPostDelete,
} from './constants';
import { normaliceFile, normaliceComments } from './normalice';
import messages from './messages';

function withCard(WrappedComponent) {
  function CardHOC({
    actionItemsUpdate,
    actionRequestInit,
    cardId,
    cloudFront,
    deletedPost,
    expanded: expandedProps,
    feedId,
    index,
    intl: { formatMessage },
    isDeleted,
    item,
    onError,
    onRemove,
    openModal,
    orgId,
    post,
    postInteractions,
    profileUserId,
    requestCard,
    requestComment,
    requestDelete,
    requestLike,
    requestLikeRemove,
    router,
    taggedPostsProfile,
    uploadPostsProfile,
    userId,
    userUid,
    ...rest
  }) {
    const [expanded, setExpanded] = useState(expandedProps);
    const modal = useModal();
    const modalDetele = useModal();
    const modalTagguedUsers = useModal();
    const actionLink = get(item, 'post.actionLink');
    const canLike = get(item, 'post.post_user_can_like', false);
    const commentsCount = get(item, 'post.post_comments_count', 0);
    const community = get(item, 'post.on_behalf_of_community');
    const communityName = get(item, 'post.user.user_name');
    const communityPhoto = get(item, 'post.user.user_image');
    const date = get(item, 'post.post_publish_date')
      || get(item, 'post.post_creation_date');
    const description = get(item, 'post.post_description');
    const likesCount = get(item, 'post.post_likes_count', 0);
    const postId = get(item, 'post.post_id');
    const postUserUid = get(item, 'post.user.uid');
    const postUserId = get(item, 'post.user.user_id');
    const sponsored = get(item, 'post.sponsored', false);
    const tags = get(item, 'post.tags', []);
    const type = get(item, 'post.post_type');
    const userAvatar = get(item, 'post.user.user_image');
    const userLastName = get(item, 'post.user.user_lastname');
    const userName = get(item, 'post.user.user_name');
    const image = get(item, 'post.post_image_info.info');
    const imageInfo = get(item, 'post.post_image_info');
    const thumbnail = get(item, 'post.post_thumbnail.info');
    const postThumbnail = get(item, 'post.post_thumbnail');
    const video = get(item, 'post.post_video_info.info');
    const videoInfo = get(item, 'post.post_video_info');
    const deleted = get(item, 'post.deleted');
    const file = {
      image,
      video,
      thumbnail,
      cloudFront,
    };
    const from = `${userName}${userLastName ? ` ${userLastName}` : ''}`;
    const isCommunityModerator = selectIsModerator({ ...rest });
    const isOwnerUser = postUserUid === userUid || postUserId === userId;
    const mentions = get(item, 'post.mentions', []);
    const comments = item?.postInteractions?.filter(
      (i) => i.pi_type === TYPE_COMMENT,
    );
    const prevRequestDelete = usePrevious(requestDelete);
    const theme = useTheme();
    const hashtagConfig = theme.getHashtagConfigByText(description);
    const sendingLike = requestLike.requesting || requestLikeRemove.requesting;
    const normalicedComments = normaliceComments(comments);
    const sortedComments = sortBy(normalicedComments, ['pi_creation_date_ts']);
    const overlay = {
      image: hashtagConfig?.momentsBottomLeftCornerImageCode,
      color: hashtagConfig?.color,
    };

    const updateItem = (postId, update) =>
      actionItemsUpdate(postId, merge(item, update));

    function isRequestSuccess(prev, current) {
      return prev?.requesting && !current?.requesting && !current?.error;
    }

    function normaliceFiles() {
      const normalice = [normaliceFile(file, type, postId)];
      const maxAspectRatio = Math.max(...normalice.map((f) => f.aspectRatio));
      return normalice.map((n) => ({ ...n, aspectRatio: maxAspectRatio }));
    }

    function addLike(cardId) {
      if (!sendingLike) {
        actionRequestInit({
          api: apiAddInteractionById,
          id: getRequestIdCardLike(cardId),
          onSuccess: () => {
            updateItem(cardId, {
              post: {
                post_user_can_like: false,
                post_likes_count: likesCount + 1,
              },
            });

            trackEvent({
              action: 'like',
              category: 'card_activity',
              description,
              id: postId,
              type: sponsored ? 'sponsored_content' : type,
            });
          },
          params: {
            cardId,
            data: {
              pi_description: '',
              pi_mentions: 'string',
              pi_type: 'LIKE',
            },
            orgId,
            sponsored,
          },
        });
      }
    }

    function removeLike(cardId) {
      if (!sendingLike) {
        actionRequestInit({
          api: apiRemoveLikeById,
          id: getRequestIdCardLikeRemove(cardId),
          onSuccess: () => {
            updateItem(cardId, {
              post: {
                post_user_can_like: true,
                post_likes_count: likesCount - 1,
              },
            });

            trackEvent({
              action: 'unlike',
              category: 'card_activity',
              description,
              id: postId,
              type: sponsored ? 'sponsored_content' : type,
            });
          },
          params: {
            cardId,
            orgId,
            sponsored,
          },
        });
      }
    }

    function addComment(cardId, comment, mentions) {
      actionRequestInit({
        api: apiAddInteractionById,
        id: getRequestIdCardComment(cardId),
        onSuccess: (data) => {
          actionItemsUpdate(cardId, {
            post: {
              ...item?.post,
              post_comments_count: commentsCount + 1,
            },
            postInteractions: normaliceComments(data?.postInteractions),
          });

          trackEvent({
            action: 'comment_success',
            category: 'card_activity',
            description,
            id: postId,
            type: sponsored ? 'sponsored_content' : type,
          });
        },
        params: {
          cardId,
          data: {
            pi_description: comment,
            pi_mentions: JSON.stringify(mentions),
            pi_type: 'COMMENT',
          },
          orgId,
          sponsored,
        },
      });
    }

    function deleteCard() {
      actionRequestInit({
        api: apiDeletePost,
        id: getRequestIdPostDelete(postId),
        onSuccess: () => {
          updateItem(postId, {
            post: {
              deleted: true,
            },
          });
        },
        params: {
          orgId,
          postId,
        },
      });
    }

    function handleOnComment(description, mentions) {
      addComment(postId, description, mentions);
    }

    function handleOnLike(event) {
      event.stopPropagation();

      if (canLike) {
        addLike(postId);
      } else {
        removeLike(postId);
      }
    }

    function handleOnLikeText(e) {
      e.preventDefault();

      modal.open({
        contentDescription: description,
        contentId: postId,
        contentType: sponsored ? 'sponsored_content' : type,
        id: postId,
      });

      trackEvent({
        action: 'click',
        category: 'card_activity',
        description,
        id: postId,
        label: 'view_likes',
        type: sponsored ? 'sponsored_content' : type,
      });
    }

    function handleOnMessageText() {
      setExpanded(!expanded);

      trackEvent({
        action: 'click',
        category: 'card_activity',
        description,
        id: postId,
        label: 'comment',
        type: sponsored ? 'sponsored_content' : type,
      });

      if (!expanded && commentsCount && !sortedComments.length) {
        actionRequestInit({
          api: apiGetPostById,
          id: getRequestIdCard(postId),
          onSuccess: (data) => {
            actionItemsUpdate(postId, {
              post: item?.post,
              postInteractions: normaliceComments(data?.postInteractions),
            });
          },
          params: {
            cardId: postId,
            orgId,
          },
        });
      }
    }

    function redirectToProfile(event, uid) {
      event.stopPropagation();
      if (uid) {
        router.push(userUid === uid ? '/me' : `/profile/${uid}`);
      }
    }

    function redirectToHashtag(event, hashtag) {
      event.stopPropagation();
      router.push(`/hashtags/${hashtag}`);
    }

    function handleOnDelete() {
      modalDetele.open({
        children: formatMessage(messages.withCardAreYouSure),
        messageAccept: formatMessage(commonMessages.smuCommonYes),
        messageCancel: formatMessage(commonMessages.smuCommonNo),
        title: formatMessage(messages.withCardDiscardPost),
      });
    }

    function handleTagguedUsers() {
      modalTagguedUsers.open({
        users: tags,
      });
    }

    useEffect(() => {
      if (isRequestSuccess(prevRequestDelete, requestDelete)) {
        modalDetele.close();
        if (onRemove) onRemove(post);
      }
    }, [postId, prevRequestDelete, requestDelete, modalDetele.close]);

    useEffect(() => {
      if (!cardId) {
        trackEvent({
          action: sponsored ? 'sponsored_content' : 'home_card_bethere',
          category: 'impression',
          description,
          label: `${type} - ${index}`,
        });
      }
    }, [cardId]);

    if (deleted) {
      return null;
    }

    if (!item?.post) {
      return <SkeletonMediaItem showComments />;
    }

    if (item?.post) {
      return (
        <>
          {modal.isOpen && (
            <ModalPostInteractions
              onClose={modal.close}
              open={modal.isOpen}
              {...modal.data}
            />
          )}
          {modalDetele.isOpen && (
            <ModalConfirmation
              onAccept={deleteCard}
              onCancel={modalDetele.close}
              onClose={modalDetele.close}
              open={modalDetele.isOpen}
              {...modalDetele.data}
            />
          )}
          {modalTagguedUsers.isOpen && (
            <ModalTagguedUsers
              onClose={modalTagguedUsers.close}
              open={modalTagguedUsers.isOpen}
              {...modalTagguedUsers.data}
            />
          )}
          <WrappedComponent
            actionLink={actionLink}
            canLike={canLike}
            comments={sortedComments}
            commentsCount={commentsCount}
            commentsLoading={requestComment.requesting || requestCard.requesting}
            community={community}
            communityName={communityName}
            communityPhoto={communityPhoto}
            date={date}
            description={description}
            expanded={expanded}
            files={normaliceFiles()}
            from={from}
            hashtagConfig={hashtagConfig}
            imageInfo={imageInfo}
            isCommunityModerator={isCommunityModerator}
            isDeleting={requestDelete.requesting}
            isOwnerUser={isOwnerUser}
            likesCount={likesCount}
            mentions={mentions}
            onComment={handleOnComment}
            onDelete={handleOnDelete}
            onLike={handleOnLike}
            onLikeText={handleOnLikeText}
            onMessageText={handleOnMessageText}
            onTagguedUsers={handleTagguedUsers}
            overlay={overlay}
            placeholder={formatMessage(messages.withCardInputPlaceholder)}
            post={post}
            postId={postId}
            postThumbnail={postThumbnail}
            postUrl={buildShareModalUrl({ modal: 'post', id: postId })}
            postUserId={postUserId}
            postUserUid={postUserUid}
            redirectToHashtag={redirectToHashtag}
            redirectToProfile={redirectToProfile}
            sendingComment={requestComment?.requesting}
            sendingLike={sendingLike}
            sponsored={sponsored}
            tags={tags}
            type={type}
            userAvatar={userAvatar}
            videoInfo={videoInfo}
            {...rest}
          />
        </>
      );
    }

    return null;
  }

  const mapStateToProps = (state, ownProps) => {
    const cardId = ownProps?.cardId || ownProps?.post?.post_id;

    return {
      item: selectItemById(state, cardId),
      orgId: selectCommunityId(state),
      requestCard: selectRequest(state, getRequestIdCard(cardId)),
      requestComment: selectRequest(state, getRequestIdCardComment(cardId)),
      requestDelete: selectRequest(state, getRequestIdPostDelete(cardId)),
      requestLike: selectRequest(state, getRequestIdCardLike(cardId)),
      requestLikeRemove: selectRequest(
        state,
        getRequestIdCardLikeRemove(cardId),
      ),
      userId: selectSessionUserId(state),
      userUid: selectSessionUserUid(state),
    };
  };

  CardHOC.defaultProps = {
    cardId: undefined,
    index: 0,
    post: undefined,
    postInteractions: [],
  };

  CardHOC.propTypes = {
    actionItemsUpdate: PropTypes.func.isRequired,
    actionRequestInit: PropTypes.func.isRequired,
    cardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cloudFront: PropTypes.string,
    index: PropTypes.number,
    item: PropTypes.shape({
      canLike: PropTypes.bool,
      commentsCount: PropTypes.number,
      deleted: PropTypes.bool,
      likesCount: PropTypes.number,
    }),
    onError: PropTypes.func,
    openModal: PropTypes.func.isRequired,
    orgId: PropTypes.number.isRequired,
    post: PropTypes.object,
    postInteractions: PropTypes.array,
    requestCard: PropTypes.object.isRequired,
    requestComment: PropTypes.object.isRequired,
    requestDelete: PropTypes.object.isRequired,
    requestLike: PropTypes.object.isRequired,
    requestLikeRemove: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
    userUid: PropTypes.string.isRequired,
  };

  return connect(mapStateToProps, {
    actionItemsUpdate,
    actionRequestInit,
    openModal,
  })(withRouter(injectIntl(CardHOC)));
}

export default withCard;
