import {
  GET_INTERESTS,
  GET_INTERESTS_SUCCESS,
  GET_INTERESTS_FAIL,
  SAVE_INTERESTS,
  SAVE_INTERESTS_SUCCESS,
  SAVE_INTERESTS_FAIL,
} from './actionTypes';

const initialState = {
  fetching: false,
  interests: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_INTERESTS:
      return {
        ...state,
        fetching: true,
        interests: [],
      };
    case GET_INTERESTS_SUCCESS:
      return {
        ...state,
        interests: payload.data,
        fetching: false,
      };
    case GET_INTERESTS_FAIL:
      return {
        ...state,
        fetching: false,
        interests: [],
      };
    case SAVE_INTERESTS:
      return {
        ...state,
        fetching: true,
      };
    case SAVE_INTERESTS_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case SAVE_INTERESTS_FAIL:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
