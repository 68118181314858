// @packages
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const CongratulationLayout = ({
  params: { id, userId },
  location: {
    query: { type },
  },
  router,
}) => {
  useEffect(() => {
    if (id || userId) {
      router.replace(
        `/home?modal=congratulation&id=${id || ''}&userId=${
          userId || ''
        }&type=${type || ''}`,
      );
    } else {
      router.replace('/404');
    }
  }, []);

  return null;
};

CongratulationLayout.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    userId: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  router: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default CongratulationLayout;
