import {
  SAVE_TCANDPP,
  SAVE_TCANDPP_SUCCESS,
  SAVE_TCANDPP_ERROR,
} from './actionTypes';

const initialState = {
  feetchingSave: false,
  error: undefined,
  eula: undefined,
  result: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SAVE_TCANDPP:
      return {
        ...state,
        feetchingSave: true,
      };
    case SAVE_TCANDPP_SUCCESS:
      return {
        ...state,
        feetchingSave: false,
        error: '',
        result: payload,
      };
    case SAVE_TCANDPP_ERROR:
      return {
        ...state,
        feetchingSave: false,
        error: payload,
        result: undefined,
      };
    default:
      return state;
  }
}
