import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'tcAndPP.title',
    defaultMessage: 'TERMS AND CONDITIONS & PRIVACY POLICY',
  },
  body: {
    id: 'tcAndPP.body',
    defaultMessage: `By accepting, you acknowledge that you have read, understood and agree to be bound by the <a target='_blank' href='https://os.starmeup.com/en/legal/product-policies/terms-of-use.html'>Terms and Conditions</a> and <a target='_blank' href='https://os.starmeup.com/en/legal/product-policies/privacy-policy.html'>Privacy Policy</a> of StarMeUp (which may be amended by Globant from time to time).
    You can access to those terms either through the above links or via "About StarMeUp" option once you log in.`,
  },
  checkboxText: {
    id: 'tcAndPP.checkboxText',
    defaultMessage: 'I agree to the Terms and Conditions and Privacy Policy.',
  },
  continue: {
    id: 'common.accept',
    defaultMessage: 'ACCEPT',
  },
  cancel: {
    id: 'common.decline',
    defaultMessage: 'DECLINE ',
  },
});
