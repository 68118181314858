// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import SkeletonAvatarMedia from 'components/Skeletons/AvatarMedia';

// @own
import './styles.scss';

const SkeletonAvatarMediaList = ({ className, total }) => {
  const array = Array.from(Array(total).keys());

  return (
    <div className={cn('skeleton-avatar-media-list', className)}>
      {array?.map((i) => (
        <SkeletonAvatarMedia
          className="skeleton-avatar-media-list__item"
          key={`skeleton-avatar-media-list-item-${i}`}
        />
      ))}
    </div>
  );
};

SkeletonAvatarMediaList.propTypes = {
  total: PropTypes.number,
};

SkeletonAvatarMediaList.defaultProps = {
  total: 6,
};

export default SkeletonAvatarMediaList;
