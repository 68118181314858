import { REQUEST_ID_CONGRATULATION } from './constants';

export function requestId(id) {
  return `${REQUEST_ID_CONGRATULATION}_${id}`;
}

export function requestIdByUser(id) {
  return `${requestId(id)}_user`;
}

export function requestIdComments(id) {
  return `${requestId(id)}_comments`;
}

export function requestIdLikes(id) {
  return `${requestId(id)}_likes`;
}

export function requestIdlike(id) {
  return `${requestId(id)}_like`;
}

export function requestIdAddlike(id) {
  return `${requestId(id)}_add_like`;
}

export function requestIdUnlike(id) {
  return `${requestId(id)}_unlike`;
}

export function requestIdAddComment(id) {
  return `${requestId(id)}_add_comment`;
}

export function requestIdInteractions(id) {
  return `${requestId(id)}_interactions`;
}
