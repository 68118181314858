// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @app
import BadgesCongratulationsModal from 'components/Modals/BadgesCongratulations';

const BadgesCongratulations = ({ id, onClose, open }) => (
  <BadgesCongratulationsModal id={id} onClose={onClose} open={open} />
);

BadgesCongratulations.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default BadgesCongratulations;
