import { defineMessages } from 'react-intl';

export default defineMessages({
  all: {
    id: 'osFeedFilters.all',
    defaultMessage: 'All',
  },
  stars: {
    id: 'osFeedFilters.stars',
    defaultMessage: 'Stars',
  },
  moments: {
    id: 'osFeedFilters.moments',
    defaultMessage: 'Moments',
  },
});
