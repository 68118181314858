// @pacages
import React from 'react';
import CardDateTime from 'smu-ui-components/Cards/components/DateTime';
import CardDescription from 'smu-ui-components/Cards/components/Description';
import Location from 'smu-ui-components/Cards/components/Location';
import PostCardTemplate from 'smu-ui-components/Cards/templates/Post';
import SendBy from 'smu-ui-components/Cards/components/SendBy';
import SponsoredCardTemplate from 'smu-ui-components/Cards/templates/Sponsored';
import cn from 'classnames';
import { injectIntl } from 'react-intl';

// @app
import ActionCommentContainer from 'containers/Cards/components/ActionComment';
import ActionLikeContainer from 'containers/Cards/components/ActionLike';
import CardGallery from 'components/Bethere/CardGallery';
import CommentsContainer from 'containers/Comments';
import HeaderComments from 'components/Comments/Header';
import PostMenu from 'containers/PostMenu';
import TextParserContainer from 'containers/TextParser';
import UserInfoPopup from 'containers/UserInfoPopup';

import {
  getCommentListProps,
  getDateTimeProps,
  getDescriptionProps,
  getMediaProps,
  getMenuProps,
  getSendByProps,
} from './helpers';

const PostCard = (props) => {
  const {
    canLike,
    className,
    comments,
    commentsCount,
    commentsLoading,
    community,
    description,
    expanded,
    hashtagConfig,
    likesCount,
    onComment,
    onLike,
    onLikeText,
    onMessageText,
    postLocation,
    postUserId,
    sendingLike,
    showComments,
    sponsored,
  } = props;
  const Component = sponsored ? SponsoredCardTemplate : PostCardTemplate;
  let cardDescription;
  let commentsComponent;
  let sendByComponent;

  if (community) {
    sendByComponent = <SendBy {...getSendByProps(props)} />;
  } else {
    sendByComponent = (
      <UserInfoPopup
        trigger={<SendBy {...getSendByProps(props)} />}
        userId={postUserId}
      />
    );
  }

  const handleActionLikeClickCount = (e) => {
    if (likesCount && onLikeText) {
      onLikeText(e);
    }
  };

  if (description) {
    cardDescription = (
      <CardDescription>
        <TextParserContainer {...getDescriptionProps(props)} />
      </CardDescription>
    );
  }

  if (showComments && expanded) {
    commentsComponent = (
      <CommentsContainer
        className="rounded-b-lg"
        header={
          <HeaderComments
            className={cn({
              'mb-4': comments?.length,
            })}
            bethereInteractions
            isLoading={commentsLoading}
            onAddComment={onComment}
          />
        }
        {...getCommentListProps(props)}
      />
    );
  }

  return (
    <Component
      actionComment={
        <ActionCommentContainer
          count={commentsCount}
          onClickIcon={onMessageText}
        />
      }
      actionLike={
        <ActionLikeContainer
          active={!canLike}
          activeImage={hashtagConfig?.likeActive}
          count={likesCount}
          inactiveImage={hashtagConfig?.likeInactive}
          isLoading={sendingLike}
          onClickCount={handleActionLikeClickCount}
          onClickIcon={onLike}
        />
      }
      className={cn('shadow-base', className)}
      comments={commentsComponent}
      dateTime={<CardDateTime {...getDateTimeProps(props)} />}
      description={cardDescription}
      location={<Location>{postLocation?.name}</Location>}
      media={<CardGallery {...getMediaProps(props)} />}
      menu={<PostMenu {...getMenuProps(props)} />}
      sendBy={sendByComponent}
    />
  );
};

export default injectIntl(PostCard);
