// @packages
import React from 'react';
import RootModal from 'smu-app-components/RootModalV2';
import { closeModal } from 'smu-app-components/RootModalV2/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { trackEvent } from 'smu-utils/gtm';

// @app
import Onboarding from 'containers/Onboarding';

// @own
import './styles.scss';

const OnboardingModal = ({ closeModal, location, onClose, router }) => (
  <div className="onboarding-modal">
    <Onboarding
      onSendStar={() => {
        trackEvent({
          action: 'click',
          category: 'onboarding',
          label: 'start_explore',
        });
        router.push(location.pathname);
        closeModal();
      }}
      onCompleteProfile={() => {
        trackEvent({
          action: 'click',
          category: 'onboarding',
          label: 'complete_profile',
        });
        closeModal();
        router.push('/me');
      }}
      onClose={(step) => {
        if (step) {
          trackEvent({
            action: 'close',
            category: 'onboarding',
            label: `${step}`,
          });
        }
        router.push(location.pathname);
        onClose();
        closeModal();
      }}
      onError={closeModal}
      showOnlyPerformance={location?.query?.performance === 'true'}
    />
  </div>
);

const mapDispatchToProps = {
  closeModal,
};

export default withRouter(
  RootModal(
    connect(null, mapDispatchToProps)(OnboardingModal),
    'ONBOARDING_MODAL',
    { width: '820px', height: '560px' },
  ),
);
