// @packages
import React from 'react';
import Modal from 'smu-ui-components/Modal';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import ShareModal from 'containers/ShareModal';
import messages from 'containers/ShareModal/messages';
import { TYPE_BADGE } from 'containers/Share/constants';

const ShareBadgeModal = ({ intl: { formatMessage }, onClose, open }) => (
  <Modal open={open} onClick={onClose}>
    <ShareModal
      className="bg-white w-[576px] rounded-md"
      onClose={onClose}
      title={formatMessage(messages.shareModalTitleBadge)}
      type={TYPE_BADGE}
    />
  </Modal>
);

ShareBadgeModal.propTypes = {
  intl: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default injectIntl(ShareBadgeModal);
