// @packages
import { setItem } from 'smu-utils/storage';
import { call, put, takeLatest } from 'redux-saga/effects';

// @app
import addToast from 'utils/defaultToastAdd';

// @own
import { setCookiesConsentDecisionSucess, setCookiesConsentDecisionError } from './actions';
import { SET_COOKIES_CONSENT_DECISION } from './actionTypes';
import { setCookiesConsentDecision as setCookiesConsentDecisionAPI } from './api';

function* setCookiesConsentDecisionWorker(payload) {
  try {
    const data = yield call(setCookiesConsentDecisionAPI, payload.decision);
    yield put(setCookiesConsentDecisionSucess(data.result));
    yield call(setItem, 'cookiesConsent', payload.decision);
    if (data.status === 'FAIL') {
      yield put(addToast({
        message: data.message,
      }));
    }
    if (!payload.decision) {
      window.location.reload();
    }
  } catch (e) {
    yield put(setCookiesConsentDecisionError(e));
  }
}

export default function* setCookiesConsentDecisionWatcher() {
  yield takeLatest(SET_COOKIES_CONSENT_DECISION, setCookiesConsentDecisionWorker);
}
