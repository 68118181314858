import {
  GET_POSTS,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  GET_BETHERE_PROPERTIES,
  GET_BETHERE_PROPERTIES_SUCCESS,
  GET_BETHERE_PROPERTIES_FAIL,
} from './actionTypes';

const initialState = {
  posts: [],
  fetching: false,
  error: '',
  properties: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_POSTS:
      return {
        ...state,
        posts: [],
        fetching: true,
      };
    case GET_POSTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        posts: payload.posts,
      };
    case GET_POSTS_FAIL:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case GET_BETHERE_PROPERTIES:
      return {
        ...state,
        fetching: true,
      };
    case GET_BETHERE_PROPERTIES_FAIL:
      return {
        ...state,
        fetching: false,
        errorMessage: payload,
      };
    case GET_BETHERE_PROPERTIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        properties: payload,
      };
    default:
      return state;
  }
}
