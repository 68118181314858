// @packages
import React from 'react';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ButtonGroup from 'smu-ui-components/ButtonGroup';

// @app
import Option from 'components/QuestionnaireResults/Option';

// @own
import './syles.scss';

function QuestionnaireResultsHeader({
  className,
  exportProps,
  enabledMobile,
  options,
  showExportButton,
}) {
  return (
    <Panel
      className={cn(
        'questionnaire-results-header',
        {
          'questionnaire-results-header--mobile': enabledMobile,
        },
        className,
      )}
    >
      {options.map(({ label, value }, key) => (
        <Option
          className="questionnaire-results-header__option"
          key={`header-option-${key}`}
          label={label}
          enabledMobile={enabledMobile}
          value={value}
        />
      ))}
      {showExportButton && (
        <ButtonGroup
          className="questionnaire-results-header__export"
          colorButtonGroup="black"
          content="Export"
          primary
          sizeButtonGroup="medium"
          {...exportProps}
        />
      )}
    </Panel>
  );
}

QuestionnaireResultsHeader.propTypes = {
  className: PropTypes.string,
  exportProps: PropTypes.object,
  enabledMobile: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  showExportButton: PropTypes.bool,
};

QuestionnaireResultsHeader.defaultProps = {
  className: '',
  exportProps: {},
  enabledMobile: true,
  options: [],
  showExportButton: true,
};

export default QuestionnaireResultsHeader;
