// @packages
import React, { useContext } from 'react';

// @app
import { StarContext } from 'containers/OSFeed/OSFeedItem';

// @own
import BeHealthyInsight from './BeHealthyInsight';
import MyTeamInsight from './MyTeamInsight';

function DocumentCard() {
  const { data } = useContext(StarContext);

  if (data?.type?.includes('BH_CARD')) {
    return <BeHealthyInsight />;
  }

  return <MyTeamInsight />;
}

export default DocumentCard;
