import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from '../../../api';

export function setProfileBio(bioMessage) {
  return apiInstance({
    url: addParams('/v2/stellar/users/bio', { ...vo, ...times() }),
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { bio: bioMessage },
  })
    .then(({ data }) => {
      if (data.status !== 'OK') { return { error: true }; }
      return data;
    })
    .catch(errorHandler);
}

