// @packages
import React, { useContext } from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Divider from 'smu-ui-components/Divider';
import HTMLParser from 'smu-app-components/HTMLParser';
import Icon from 'smu-ui-components/IconV2';
import Image from 'smu-ui-components/Image';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';
import { buildImageUrl } from 'smu-utils/image';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// @app
import { MODAL_UPLOAD_MEDIA } from 'containers/QueryModals/constants';
import { StarContext } from 'containers/OSFeed/OSFeedItem';
import { TYPE_BEHEALTHY_INSIGHT } from 'containers/Share/constants';
import { goToQueryModal } from 'containers/QueryModals/helpers';

// @own
import messages from './messages';

function BeHealthyInsight({ intl: { formatMessage }, router }) {
  const { data, id } = useContext(StarContext);
  const title = data?.header?.title;
  const imageCode = data?.iconImageCode;
  const description = data?.description;

  const handleButtonClick = () => {
    goToQueryModal(MODAL_UPLOAD_MEDIA, router, {
      comment: formatMessage(messages.shareAsMomentComment),
      id,
      type: TYPE_BEHEALTHY_INSIGHT,
    });
  };

  return (
    <Paper className="overflow-hidden" shadow>
      <div
        className={cn(
          'w-full bg-titanWhite bg-no-repeat bg-bottom px-4 py-2',
          "bg-[url('/assets/img/feed_card_header_waves.svg')]",
        )}
      >
        <Typography className="font-rubik font-semibold text-sm text-gray-dune my-auto">
          {title}
        </Typography>
      </div>
      <div className="flex flex-col items-center space-y-4 p-4">
        <img
          alt="icon"
          className="h-16 w-16"
          src={buildImageUrl({
            code: imageCode,
            height: 150,
            width: 150,
          })}
        />
        <Typography className="font-openSans text-sm text-gray-dune text-center">
          <HTMLParser html={description} />
        </Typography>
        <div className="flex items-center space-x-2 text-gray-charcoal">
          <Icon icon="padlock" size="micro" />
          <Typography className="font-openSans text-xs">
            {formatMessage(messages.privacyDisclaimer)}
          </Typography>
        </div>
      </div>
      <Divider legacy={false} />
      <div className="flex w-full items-center justify-between p-4">
        <Image
          height={18}
          legacy={false}
          src="/assets/svg/logo-activate.svg"
          width={80}
        />
        <Button
          color="unifyBlue"
          enabledUnification
          onClick={handleButtonClick}
          size="medium"
          variant="outline"
        >
          {formatMessage(messages.button)}
        </Button>
      </div>
    </Paper>
  );
}

BeHealthyInsight.propTypes = {
  intl: PropTypes.object,
  router: PropTypes.object,
};

export default withRouter(injectIntl(BeHealthyInsight));
