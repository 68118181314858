// @packages
import { detectWebView } from 'smu-utils/device';
import { selectIsOpen } from 'smu-app-components/RootModalV2/selectors';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

// @app
import {
  ENABLED_SURVEYS_ONLY,
  FIRST_LOGIN_MEMBER,
  ONBOARDING_ENABLED,
} from 'services/communityConfigs/constants';
import { MODAL_ONBOARDING } from 'containers/QueryModals/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { paths } from 'utils/routes';

function ModalPriority({ router, location }) {
  const modalRef = useRef();
  const isWebView = detectWebView();
  const isQueryModal = !!location?.query?.modal;
  const isOpenModal = useSelector(selectIsOpen);

  const onboardingEnabled = useSelector((state) => (
    getCommunityConfigValueBool(state, ONBOARDING_ENABLED)
  ));

  const firsLoginMember = useSelector((state) => (
    getCommunityConfigValueBool(state, FIRST_LOGIN_MEMBER)
  ));

  const enabledSurveysOnly = useSelector((state) => (
    getCommunityConfigValueBool(state, ENABLED_SURVEYS_ONLY)
  ));

  const invalidLocations = [paths.onboarding()];
  const enabledLocation = !invalidLocations.some((l) => l === location.pathname);

  function enabledOpenModal() {
    return enabledLocation && !isOpenModal && !isWebView && !isQueryModal && !modalRef.current;
  }


  useEffect(() => {
    if (enabledOpenModal() && firsLoginMember && onboardingEnabled && !enabledSurveysOnly) {
      modalRef.current = MODAL_ONBOARDING;
      router.push(`${location.pathname}?modal=${MODAL_ONBOARDING}`);
    }
  }, [isOpenModal, firsLoginMember, onboardingEnabled, enabledSurveysOnly]);

  return null;
}

export default withRouter(ModalPriority);
