// @packages
import React from 'react';
import ButtonV2 from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/Icon';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import SelectV2 from 'smu-ui-components/SelectV2';
import cn from 'classnames';

// @own
import './styles.scss';

function DiscoverFilters({
  className,
  filters,
  isLoading,
  onReset,
  onSubmit,
  resetDisabled,
  resetText,
  submitDisabled,
  submitText,
  titleText,
}) {
  return (
    <Paper className={cn('discover-filters shadow-base rounded-lg', className)}>
      <header className="discover-filters__header">
        <h4 className="discover-filters__title">{titleText}</h4>
        <ButtonV2
          disabled={resetDisabled}
          enabledUnification
          onClick={onReset}
          size="small"
          variant="link"
        >
          {resetText}
        </ButtonV2>
      </header>
      <div className="discover-filters__filters">
        {isLoading ? (
          <LottieSpinner
            className="discover-filters__loader"
            height={40}
            loadingType="starmeup"
            width={40}
          />
        ) : (
          filters.map(
            (
              { onRemoveValue, options, optionsSelected, title, ...filter },
              key,
            ) => (
              <div className="discover-filters__filter" key={`filter-${key}`}>
                {title && (
                  <label className="discover-filters__filter-title">
                    {title}
                  </label>
                )}
                <SelectV2
                  className="discover-filters__filter-select"
                  options={options}
                  {...filter}
                  value=""
                />
                {!!optionsSelected?.length && (
                  <div className="discover-filters__chips">
                    {optionsSelected.map((v, k) => (
                      <div
                        className={cn('discover-filters__chip', {
                          'discover-filters__chip--disabled': filter.disabled,
                        })}
                        key={`filter-chip-${key}-${v?.label}`}
                      >
                        {v?.label}
                        <Icon
                          className="discover-filters__chip-close"
                          color="black"
                          icon="linear-close"
                          onClick={() => onRemoveValue(v?.label, k)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ),
          )
        )}
      </div>
      <footer className="discover-filters__footer">
        <ButtonV2
          className="discover-filters__submit"
          color="unifyBlue"
          disabled={submitDisabled}
          enabledUnification
          onClick={onSubmit}
          variant="outline"
        >
          {submitText}
        </ButtonV2>
      </footer>
    </Paper>
  );
}

DiscoverFilters.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  resetDisabled: PropTypes.bool,
  resetText: PropTypes.string,
  submitDisabled: PropTypes.bool,
  submitText: PropTypes.string,
  titleText: PropTypes.string,
};

DiscoverFilters.defaultProps = {
  className: '',
  filters: [],
  isLoading: false,
  onReset: () => undefined,
  onSubmit: () => undefined,
  resetDisabled: false,
  resetText: 'Reset',
  submitDisabled: false,
  submitText: 'See results',
  titleText: 'Filters',
};

export default DiscoverFilters;
