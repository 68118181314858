// @packages
import React from 'react';
import { map } from 'lodash';

export const formatHashtags = (string, parseToJsx) => {
  let formattedText = string;
  const hashtagRegex = /(#\w+)/g;
  const maxLength = 140;
  const hashtags = string.match(hashtagRegex);

  if (parseToJsx && hashtags) {
    const { tag, className } = parseToJsx;

    const segments = string
      .split(/(\s+)/)
      .map(segment => (hashtags.includes(segment)
        ? React.createElement(tag, { className }, segment)
        : segment
      ));
    return segments;
  }

  map(hashtags, (item) => {
    formattedText = formattedText.replace(
      item,
      `**${item.substring(0, (maxLength - 1))}**`,
    );
  });
  return formattedText;
};
