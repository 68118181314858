// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { getUserPreferences, getUserProperties } from './api';

import {
  REQUEST_USER_CONFIGS,
} from './actionTypes';

import {
  requestUserConfigsSuccess,
  requestUserConfigsFail,
} from './actions';

function* getUserConfigsWorker() {
  try {
    const { data: userPreferences } = yield call(getUserPreferences);
    const { data: userProperties } = yield call(getUserProperties);
    yield put(requestUserConfigsSuccess({
      ...userPreferences,
      ...userProperties?.result,
    }));
  } catch (e) {
    yield put(requestUserConfigsFail(e));
  }
}

export function* getUserConfigsWatcher() {
  yield takeEvery(REQUEST_USER_CONFIGS, getUserConfigsWorker);
}

export default {
  getUserConfigsWatcher,
};
