import { reduce, merge, omit, keys } from 'lodash';

export const mapObject = (object, func) => reduce(
  object,
  (newObject, v, k) => merge(newObject, { [k]: func(v, k) }),
  {},
);

export const filterObject = (object, func) => reduce(
  object,
  (newObject, v, k) => merge(newObject, func(v, k, object) ? { [k]: v } : {}),
  {},
);

export const swapKeys = (object, keymap) => reduce(
  keymap,
  (newObject, newKey, key) => merge(newObject, { [newKey]: object[key] }),
  omit(object, keys(keymap)),
);

export const parseJwtToObject = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
  return JSON.parse(jsonPayload);
};
