// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

// @app
import AdvancedUserFiltersComponent from 'components/AdvancedUserFilters';
import {
  getAdvancedUserFiltersRequesting,
  getAdvancedUserFiltersResult,
  getAdvancedUserFiltersSuccess,
} from 'services/advancedUserFilters/selectors';
import { getCategoriesValues } from 'services/categoryValues/selectors';
import { requestCategoryValues } from 'services/categoryValues/actions';
import { requestAdvancedUserFilters } from 'services/advancedUserFilters/actions';
import { requestAdvancedUserSearch } from 'services/advancedUserSearch/actions';
import {
  getAdvancedUserSearchFilters,
  getAdvancedUserSearchSuccess,
} from 'services/advancedUserSearch/selectors';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
} from 'services/advancedUserSearch/constants';
import commonMessages from 'common/messages';

// @own
import messages from './messages';
import { TYPE_LIST } from './constants';
import { getCategoryValuesIds, getOptions, sanityFilters } from './helpers';

function AdvancedUserFiltersContainer({ filters, router, intl }) {
  const [values, setValues] = useState(filters);
  const prevValues = usePrevious(values);
  const requesting = useSelector(getAdvancedUserFiltersRequesting);
  const result = useSelector(getAdvancedUserFiltersResult);
  const success = useSelector(getAdvancedUserFiltersSuccess);
  const successUsers = useSelector(getAdvancedUserSearchSuccess);
  const userFilters = useSelector(getAdvancedUserSearchFilters);
  const categoriesValues = useSelector(
    getCategoriesValues(getCategoryValuesIds(result)),
  );
  const dispatch = useDispatch();
  const isEmpty = !requesting && !result?.length;

  function fetchFilters() {
    dispatch(requestAdvancedUserFilters());
  }

  function fetchUsers(values) {
    dispatch(
      requestAdvancedUserSearch({
        data: { page: DEFAULT_PAGE, size: DEFAULT_SIZE, ...values },
      }),
    );
  }

  function fetchCategoryValues(category) {
    if (!categoriesValues?.[category]?.success) {
      dispatch(
        requestCategoryValues({
          id: category,
          type: 'GET',
          params: {
            category,
          },
        }),
      );
    }
  }

  function handleChange(field, value) {
    setValues({
      ...values,
      [field]: value,
    });
  }

  function normaliceFilters() {
    return result?.map((filter) => {
      const defaultProps = {
        onChange: (e) =>
          handleChange(filter?.field, e?.target ? e.target.value : e),
        name: filter?.field,
        placeholder: filter?.label,
        size: 'large',
        type: filter?.type,
        value: values?.[filter?.field] || '',
      };

      if (filter?.type === TYPE_LIST) {
        defaultProps.isLoading = categoriesValues?.[filter?.field]?.requesting;
        defaultProps.options = getOptions(
          categoriesValues?.[filter?.field]?.result,
        );
        defaultProps.noOptionsMessage = intl.formatMessage(
          commonMessages.smuCommonNoOptions,
        );
        defaultProps.loadingMessage = intl.formatMessage(
          commonMessages.smuCommonLoading,
        );
      }

      return defaultProps;
    });
  }

  function existFilter(field) {
    return result?.some((r) => r?.field === field);
  }

  function filterQuery() {
    return Object.keys(values)
      .reduce((acc, cur) => {
        if (cur && values[cur] && existFilter(cur)) {
          acc.push(`${cur}=${values[cur]}`);
        }
        return acc;
      }, [])
      .join('&');
  }

  useEffect(() => {
    if (!success && !requesting) {
      fetchFilters();
    }
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (prevValues && !isEqual(prevValues, values)) {
        router.push(`${router.location.pathname}?${filterQuery()}`);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [prevValues, values]);

  useEffect(() => {
    if (success && result) {
      result.forEach(
        (filter) =>
          filter?.type === TYPE_LIST && fetchCategoryValues(filter?.field),
      );
    }
  }, [success]);

  useEffect(() => {
    if (
      (!isEqual(sanityFilters(userFilters), sanityFilters(filters)) ||
        !successUsers)
    ) {
      fetchUsers(filters);
      setValues(filters);
    }
  }, [successUsers, filters]);

  return isEmpty ? null : (
    <AdvancedUserFiltersComponent
      filters={normaliceFilters()}
      isLoading={requesting}
      title={intl.formatMessage(messages.findCollegues)}
      typeList={TYPE_LIST}
    />
  );
}

AdvancedUserFiltersContainer.defaultProps = {
  filters: {},
  onChange: () => undefined,
};

AdvancedUserFiltersContainer.propTypes = {
  filters: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  router: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(AdvancedUserFiltersContainer));
