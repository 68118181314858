// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { apiGetUsersByKudosStarId } from './api';
import { REQUEST_USERS_BY_KUDOS_STAR_ID } from './actionTypes';
import {
  actionUserByKudosStarIdFail,
  actionUserByKudosStarIdSuccess,
} from './actions';

function* getUsersByKudosStarIdWorker({ payload }) {
  try {
    const data = yield call(apiGetUsersByKudosStarId, payload);
    yield put(actionUserByKudosStarIdSuccess(data));
  } catch (error) {
    yield put(actionUserByKudosStarIdFail({ error }));
  }
}

export function* getUsersByKudosStarIdWatcher() {
  yield takeEvery(REQUEST_USERS_BY_KUDOS_STAR_ID, getUsersByKudosStarIdWorker);
}

export default {
  getUsersByKudosStarIdWatcher,
};
