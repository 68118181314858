import { defaultApi } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export const apiGetInsights = ({ params }) =>
  defaultApi({
    data: {},
    headers: { 'Content-Type': 'application/json' },
    params,
    url: '/briefme/api/v1/community/member/insights',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
