// @app
import { errorHandler } from 'utils/errorHandler';
import { bethereApiInstance } from 'utils/requestHelper';

export function apiGetBethereProperties(communityId) {
  return bethereApiInstance({
    url: `/organization/${communityId}/properties`,
    data: {},
  })
    .then((data) => data)
    .catch(errorHandler);
}
