// @ package
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// @ apps
import Panel from 'smu-ui-components/Panel';
// @ own
import './styles.scss';

const EmptyState = ({
  className,
  children,
  imgSrc,
  messages,
}) => {
  const { subtitle, title } = messages;
  return (
    <Panel className={cn('empty-state', className)}>
      {imgSrc && (
        <img src={imgSrc} alt="empty-state" className="empty-state__image" />
      )}
      <div className="empty-state__text-wrapper">
        <span className="empty-state__text empty-state__text-title">{title}</span>
        {subtitle && (
          <span className="empty-state__text empty-state__text-subtitle">{subtitle}</span>
        )}
      </div>
      {children && children}
    </Panel>
  );
};

EmptyState.defaultProps = {
  messages: [],
};

EmptyState.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  imgSrc: PropTypes.string,
  messages: PropTypes.shape({
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
};

export default EmptyState;
