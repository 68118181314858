import { includes } from 'lodash';
import {
  AUTH_MESSAGES,
  INVALID_TOKEN,
  SPECIFIC_ERORS,
  UNAUTHORIZED_ERROR,
  UNAUTHORIZED_ERROR_MESSAGE,
} from 'utils/constants';

export const errorSanitizer = (data) => {
  let error = {};
  const {
    errorCode,
    errorMessage = '',
    message = '',
  } = data;

  if (
    errorCode === UNAUTHORIZED_ERROR
    || (errorMessage === UNAUTHORIZED_ERROR_MESSAGE || message === UNAUTHORIZED_ERROR_MESSAGE)
  ) {
    error.status = UNAUTHORIZED_ERROR;
    error.message = UNAUTHORIZED_ERROR_MESSAGE;
  } else if (
    message.includes(INVALID_TOKEN)
    || errorMessage.includes(INVALID_TOKEN)
  ) {
    error.status = UNAUTHORIZED_ERROR;
    error.message = UNAUTHORIZED_ERROR_MESSAGE;
  } else if (includes(AUTH_MESSAGES, message) || includes(AUTH_MESSAGES, errorMessage)) {
    error.status = UNAUTHORIZED_ERROR;
    error.message = errorMessage || message;
  } else if (
    message.includes(SPECIFIC_ERORS)
    || errorMessage.includes(SPECIFIC_ERORS)
  ) {
    error.status = undefined;
    error.message = errorMessage || message;
  } else {
    error.status = errorCode;
    error.message = errorMessage || message;
  }

  return error;
};
