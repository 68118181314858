import { TYPE_LIST } from './constants';

export function getCategoryValuesIds(filters = []) {
  return filters?.reduce((acc, cur) => {
    if (cur?.type === TYPE_LIST) {
      acc.push(cur?.field);
    }

    return acc;
  }, []);
}

export function getOptions(categoryValues = {}) {
  const keys = Object.keys(categoryValues);

  return keys?.reduce((acc, cur) => {
    if (cur && cur !== '0') {
      acc.push({
        label: categoryValues[cur],
        value: cur,
      });
    }

    return acc;
  }, []);
}

export function sanityFilters(filters = {}) {
  const copyFilters = { ...filters };
  delete copyFilters.page;
  delete copyFilters.size;
  return copyFilters;
}
