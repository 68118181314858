import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'interactionModal.title',
    defaultMessage: 'INTERACTIONS',
  },
  tabAll: {
    id: 'interactionModal.tabs.all',
    defaultMessage: 'ALL',
  },
});
