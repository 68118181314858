// @ Package
import React, { Component } from 'react';
import { partial } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// @ App
import Icon from 'smu-ui-components/IconV2';
import Search from 'containers/SendStarFlow/MainSearch';
import messagesSearch from 'containers/SendStar/messages';
import { interpolateVariables } from 'utils/formatString';
import { searchEntities } from 'services/sendStarFlow/api';
// @ Own
import StarCard from '../StarCard';
import './styles.scss';

class PickUser extends Component {
  constructor(props) {
    super(props);
    this.handlePickUser = this.handlePickUser.bind(this);
  }

  handlePickUser(user) {
    const {
      coreValue: { id, name },
      defaultStarMessage,
      goToView,
      pickUser,
    } = this.props;
    const defaultMessage = interpolateVariables(
      defaultStarMessage,
      { starName: name },
    );
    pickUser(id, user, defaultMessage);
    goToView('pickValue');
  }

  render() {
    const {
      coreValue: { name, color, imageCode },
      intl: { formatMessage },
      onClose,
    } = this.props;
    return (
      <div className="pick-user">
        <div className="pick-user__close">
          <Icon
            className="pick-user__close-icon"
            color="white"
            icon="close"
            onClick={onClose}
            size="small"
          />
        </div>
        <StarCard
          className="pick-user__card"
          color={color}
          imageCode={imageCode}
          name={name}
        />
        <div className="pick-user__search">
          <Search
            api={partial(searchEntities, false, 'USER')}
            autoFocus
            messages={{
              placeholder: formatMessage(messagesSearch.searchPlaceholder),
              messageNoResult: formatMessage(messagesSearch.basicNoResults),
            }}
            noLink
            onClickItem={this.handlePickUser}
            searchBox={{ glassFlavor: 'green', height: '50px' }}
            searchUsers=""
          />
        </div>
      </div>
    );
  }
}

PickUser.propTypes = {
  coreValue: PropTypes.shape({
    color: PropTypes.string,
    id: PropTypes.number,
    imageCode: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  defaultStarMessage: PropTypes.string.isRequired,
  goToView: PropTypes.func.isRequired,
  intl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  pickUser: PropTypes.func.isRequired,
};

export default injectIntl(PickUser);
