export const OTHER_PROFILE = 'OTHER_PROFILE';
export const PROFILE = 'PROFILE';
export const RECEIVED = 'RECEIVED';
export const SENT = 'SENT';

export const EMPTY_STATE_PROPS = {
  [PROFILE]: {
    [RECEIVED]: {
      sendStar: false,
      src: '/assets/img/empty_received.png',
      text: 'feedProfileEmptyStateReceivedText',
    },
    [SENT]: {
      sendStar: true,
      src: '/assets/img/empty_sent.png',
      subtext: 'feedProfileEmptyStateSentSubtext',
      text: 'feedProfileEmptyStateSentText',
    },
  },
  [OTHER_PROFILE]: {
    [RECEIVED]: {
      sendStar: true,
      src: '/assets/img/empty_received.png',
      subtext: 'feedProfileEmptyStateReceivedSubtextOther',
      text: 'feedProfileEmptyStateReceivedTextOther',
    },
    [SENT]: {
      sendStar: false,
      src: '/assets/img/empty_sent.png',
      text: 'feedProfileEmptyStateSentTextOther',
    },
  },
};
