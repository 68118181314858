// @packages
import { useState, useEffect, useCallback } from 'react';

let player = {
  id: null,
  onPause: null,
};

const useVideoAutoPlay = (videoId) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  const onPlay = useCallback(() => {
    setIsStarted(true);
    setIsPlaying(true);
    if (player.id !== videoId) {
      if (player.onPause) {
        player.onPause();
      }
      player.id = videoId;
      player.onPause = onPause;
    }
  }, [setIsPlaying]);

  const onReady = useCallback(() => {
    setIsReady(true);
  }, [setIsReady]);

  useEffect(
    () => () => {
      if (player.id === videoId) {
        player.id = null;
        player.onPause = null;
      }
    },
    [],
  );

  return {
    isPlaying,
    isReady,
    isStarted,
    onPause,
    onPlay,
    onReady,
  };
};

export default useVideoAutoPlay;
