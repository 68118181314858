// @ Packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @ App
import { changeSelectedStar } from 'services/sendStarFlow/actions';
import { DEFAULT_STAR } from 'services/sendStarFlow/constants';
import { entitiesAmount } from 'containers/SendStar/helpers';

// @own
import ListStarValues from './ListStarValues';
import './styles.scss';

export const checkStarAvailability = (star, remainingStars, entities) => {
  const { isKudos = undefined } = star;
  const {
    hasKudosRemaining = undefined,
    hasUnlimitedStars = undefined,
    remainingStarCounter = {},
  } = remainingStars;

  const isValueUnavailable = isKudos
    ? (!hasKudosRemaining)
    : (hasUnlimitedStars
      ? !hasUnlimitedStars
      : (remainingStarCounter.remaining === 0
        || entitiesAmount(entities) > remainingStarCounter.remaining)
    );
  return isValueUnavailable;
};

const WhatValue = ({
  changeSelectedStar,
  isEdit,
  listRecognitionText,
  selectedValue,
  values,
}) => {
  const handleOnClickValue = (selectedValue) => {
    if (selectedValue) {
      changeSelectedStar(selectedValue);
    } else {
      changeSelectedStar(DEFAULT_STAR);
    }
  };

  return (<ListStarValues
    isEdit={isEdit}
    listRecognitionText={listRecognitionText}
    onChangeValue={handleOnClickValue}
    selectedValue={selectedValue}
    values={values}
  />);
};

WhatValue.propTypes = {
  changeSelectedStar: PropTypes.func,
  intl: PropTypes.object,
  isEdit: PropTypes.bool,
  listRecognitionText: PropTypes.string.isRequired,
  entities: PropTypes.array,
  remainingStars: PropTypes.object,
  selectedValue: PropTypes.object,
  values: PropTypes.array,
};

const mapStateToProps = ({ starFlow }) => {
  const { entities, remainingStars } = starFlow;
  return {
    entities,
    remainingStars,
  };
};

export default connect(mapStateToProps, {
  changeSelectedStar,
})(injectIntl(WhatValue));
