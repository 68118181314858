import {
  GET_CULTURAL_DATA_FAIL,
  GET_CULTURAL_DATA_SUCCESS,
  GET_CULTURAL_DATA,
  RESET_CULTURAL_STATE,
} from './actionTypes';

const initialState = {
  result: [],
  fetching: true,
  failed: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CULTURAL_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        result: action.payload,
      };
    case GET_CULTURAL_DATA:
      return {
        ...state,
        fetching: true,
      };
    case GET_CULTURAL_DATA_FAIL:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case RESET_CULTURAL_STATE:
      return initialState;
    default:
      return state;
  }
};
