// @app
import { paths } from 'utils/routes';

// @own
import InsightsLayout from './InsightsLayout';

export default () => ({
  component: InsightsLayout,
  onEnter: () => {
    window.scrollTo(0, 0);
  },
  path: paths.insights(),
});
