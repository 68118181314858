export const getRequestIdCase = (caseId) => `case-${caseId}`;
export const getRequestIdCaseChannelSubscribe = (caseId) => `case-${caseId}-channel-subscribe`;
export const getRequestIdCaseComment = (caseId) => `case-${caseId}-comment`;
export const getRequestIdCaseComments = (caseId) => `case-${caseId}-comments`;
export const getRequestIdCaseCreate = () => 'case-create';
export const getRequestIdCaseFollow = (caseId) => `case-${caseId}-follow`;
export const getRequestIdCaseIgnore = (caseId) => `case-${caseId}-ignore`;
export const getRequestIdCaseVote = (caseId) => `case-${caseId}-vote`;
export const getRequestIdCaseStop = (caseId) => `case-${caseId}-stop`;
export const getRequestIdCasesPending = () => 'cases-pending';
export const getRequestIdCaseFollowers = () => 'case-followers';
export const getRequestIdQuestById = (questId) => `questionnaire-${questId}`;
export const getRequestIdQuestDemographics = (questId) => `questionnaire-demographics-${questId}`;
export const getRequestIdQuestLanguage = (questId) => `questionnaire-language-${questId}`;
export const getRequestIdQuestQuestion = (questId) => `questionnaire-question-${questId}`;
export const getRequestIdQuestComment = (questId) => `questionnaire-comment-${questId}`;
