// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';

// @app
import InsightsContainer from 'containers/Insights';

// @own
import './styles.scss';

function InsightsLayout() {
  return (
    <div className="insights-layout">
      <Container centered>
        <InsightsContainer />
      </Container>
    </div>
  );
}

export default InsightsLayout;
