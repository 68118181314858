// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import { STATUS } from 'services/sendStarFlow/constants';

// @own
import './styles.scss';

const SendStarFlowStatus = ({
  background,
  messageToShow,
  status,
  style,
}) => (
  <div className="send-star__status-bar" style={{style}}>
    <div
      className={cn({ 'send-star__status-bar--progress': status === STATUS.SENDING })}
      style={{ background }}
    />
    {messageToShow}
  </div>
);

SendStarFlowStatus.propTypes = {
  background: PropTypes.string,
  messageToShow: PropTypes.object,
  status: PropTypes.string,
  style: PropTypes.object,
};

export default SendStarFlowStatus;
