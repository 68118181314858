import { defineMessages } from 'react-intl';

export default defineMessages({
  smuCommonLoading: {
    id: 'SMU.Common.Loading',
    defaultMessage: 'Loading',
  },
  smuCommonNoOptions: {
    id: 'SMU.Common.NoOptions',
    defaultMessage: 'No options',
  },
  smuCommonNo: {
    id: 'SMU.Common.No',
    defaultMessage: 'No',
  },
  smuCommonYes: {
    id: 'SMU.Common.Yes',
    defaultMessage: 'Yes',
  },
  smuCommonError: {
    id: 'SMU.Common.Error',
    defaultMessage: 'Oops! something went wrong. Try again later.',
  },
});
