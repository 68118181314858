// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// @app
import ProfileByID from 'routes/ProfileByID';
import ProfileByUID from 'routes/ProfileByUID';
import { selectSessionUserUid } from 'containers/Authorization/selectors';

const OtherProfileLayout = (props) => {
  const profileId = props?.params?.profileId;
  const replace = props?.router?.replace;
  const showProfileById = profileId === 'id';
  const userUID = useSelector(selectSessionUserUid);
  const isOwner = profileId === userUID;

  useEffect(() => {
    if (!profileId && replace) {
      replace('/home');
    }
  }, [profileId, replace]);

  useEffect(() => {
    if (isOwner && replace) {
      replace('/me');
    }
  }, [isOwner]);

  if (showProfileById) {
    return <ProfileByID {...props} />;
  }

  if (profileId && !isOwner) {
    return <ProfileByUID {...props} />;
  }

  return null;
};

OtherProfileLayout.propTypes = {
  params: PropTypes.shape({
    userId: PropTypes.string,
  }),
  router: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default OtherProfileLayout;
