export function fullScreenChangeListener(callback, options) {
  /* Standard syntax */
  document.addEventListener('fullscreenchange', callback, options);

  /* Firefox */
  document.addEventListener('mozfullscreenchange', callback, options);

  /* Chrome, Safari and Opera */
  document.addEventListener('webkitfullscreenchange', callback, options);

  /* IE / Edge */
  document.addEventListener('msfullscreenchange', callback, options);
}

export function fullScreenChangeRemoveListener(callback, options) {
  /* Standard syntax */
  document.removeEventListener('fullscreenchange', callback, options);

  /* Firefox */
  document.removeEventListener('mozfullscreenchange', callback, options);

  /* Chrome, Safari and Opera */
  document.removeEventListener('webkitfullscreenchange', callback, options);

  /* IE / Edge */
  document.removeEventListener('msfullscreenchange', callback, options);
}
