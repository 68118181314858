// @packages
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectError, selectResultProp, selectRequesting } from 'smu-utils/reduxRequests/selectors';
import Button from 'smu-ui-components/ButtonV2';
import cloneDeep from 'lodash/cloneDeep';
import cn from 'classnames';
import get from 'lodash/get';
import Icon from 'smu-ui-components/IconV2';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useState } from 'react';
import usePrevious from 'smu-custom-hooks/usePrevious';

// @app
import { STATUS } from 'services/sendStarFlow/constants';
import { selectOrganizationId as selectOrgId, selectLanguageCode } from 'containers/Authorization/selectors';
import { selectStarFlowStatus } from 'services/sendStarFlow/selectors';
import { trackEvent as trackAnalytics, trackPageview } from 'smu-utils/gtm';

// @own
import {
  BG_TYPE_COLOR,
  BG_TYPE_GRADIENT,
  BG_TYPE_IMAGE,
  COMPONENTS_TYPES,
  PATH_BY_TYPE,
  REQUEST_VOTE_QUESTION,
  SELECT_TYPE_BOOL,
  SELECT_TYPE_ID_LIST,
  SELECT_TYPE_TEXT,
  TYPE_EMOJI,
  TYPE_IMAGE,
  TYPE_SEND_STAR,
  TYPE_NPS,
  TYPE_TEXT,
  SELECT_TYPE_NUMERIC,
  TYPE_TEXTFIELD,
} from './constants';
import { apiGetQuestionById, apiVote } from './api';
import Error from './Error';
import Finished from './Finished';
import Introduction from './Introduction';
import messages from './messages';
import './styles.scss';

function QuestionnairesFlex({
  actionRequestDestroy,
  actionRequestInit,
  error,
  initialData,
  intl: { formatMessage },
  lang,
  onClose,
  onFinished,
  orgId,
  requesting,
  result,
  sendStarFlowStatus,
}) {
  const memoizedInitialData = useMemo(() => {
    const firstOption = initialData.question?.data?.options?.[0];
    if (firstOption?.type === TYPE_NPS) {
      const newOptions = [];
      const { minValue, maxValue, step } = firstOption.data;

      for (let i = minValue; i <= maxValue; i += step) {
        newOptions.push({
          data: { number: i },
          first: i === minValue,
          id: firstOption.id,
          last: i === maxValue,
          position: i,
          type: firstOption.type,
          selected: {
            type: SELECT_TYPE_NUMERIC,
            data: {
              value: null,
            },
          },
        });
      }

      const newInitialData = cloneDeep(initialData);
      newInitialData.question.data.options = newOptions;
      return newInitialData;
    }

    return initialData;
  }, [initialData]);

  const memoizedResult = useMemo(() => {
    const firstOption = result?.question?.data?.options?.[0];
    if (firstOption?.type === TYPE_NPS) {
      const newOptions = [];
      const { minValue, maxValue, step } = firstOption.data;

      for (let i = minValue; i <= maxValue; i += step) {
        newOptions.push({
          data: { number: i },
          first: i === minValue,
          id: firstOption.id,
          last: i === maxValue,
          position: i,
          type: firstOption.type,
          selected: {
            type: SELECT_TYPE_NUMERIC,
            data: {
              value: null,
            },
          },
        });
      }

      const newInitialData = cloneDeep(result);
      newInitialData.question.data.options = newOptions;
      return newInitialData;
    }

    return result;
  }, [result]);

  const data = memoizedResult || memoizedInitialData;
  const initialOptions = get(data, 'question.data.options', []);
  const [optionsQuestion, setOptionsQuestion] = useState(initialOptions);
  const [isLoading, setIsLoading] = useState(false);
  const isFinished = get(data, 'finished');
  const isAnonymous = get(data, 'anonymous');
  const appearance = get(data, 'appearance');
  const title = get(data, 'title');
  const description = get(data, 'description');
  const total = get(data, 'totalQuestions', 0);
  const current = get(data, 'currentQuestionIndex', 0);
  const [showIntro, setShowIntro] = useState(!isLoading && !current && !isFinished);
  const titleQuestion = get(data, 'question.data.text');
  const descriptionQuestion = get(data, 'question.data.description');
  const layout = get(data, 'question.layout', '');
  const bgType = get(data, 'question.background.type');
  const bgColor = get(data, 'question.background.data.color');
  const bgColors = get(data, 'question.background.data.colors', []);
  const bgImage = get(data, 'question.background.data.path');
  const prevQuestionId = get(data, 'question.prevQuestionId', undefined);
  const minSelection = get(data, 'question.minimumSelection', 0);
  const maxSelection = get(data, 'question.maximumSelection', optionsQuestion.length);
  const questionId = get(data, 'question.id');
  const questionnaireId = get(data, 'id');
  const questionnaireTitle = get(data, 'title');
  const questionAnswersType = get(data, 'question.data.options[0].type', '');
  const isMultiple = maxSelection > 1;
  const isOptional = minSelection === 0;
  const isQuestion = !isFinished && !error && !showIntro;
  const prevRequesting = usePrevious(requesting);
  const prevIsFinished = usePrevious(isFinished);

  function getSelectedQuestions() {
    return optionsQuestion.filter((option) => {
      const type = get(option, 'selected.type');

      if (type === SELECT_TYPE_BOOL) {
        return get(option, 'selected.data.value');
      }

      if (type === SELECT_TYPE_NUMERIC) {
        const selected = get(option, 'selected.data.value');
        const value = get(option, 'data.number');
        return value === selected;
      }

      if (type === SELECT_TYPE_TEXT) {
        const minimumCharacters = get(option, 'data.minimumCharacters', 0);
        const value = get(option, 'selected.data.value', '');
        return value.length > minimumCharacters;
      }

      if (type === SELECT_TYPE_ID_LIST) {
        return get(option, 'selected.data.value', '');
      }

      return false;
    });
  }

  function handleBackButton() {
    setIsLoading(true);
    actionRequestInit({
      api: apiGetQuestionById,
      id: REQUEST_VOTE_QUESTION,
      params: {
        data: {
          answers: getSelectedQuestions(),
        },
        error,
        lang,
        orgId,
        questionId: prevQuestionId,
        questionnaireId,
      },
    });
  }

  function voteQuestion() {
    setIsLoading(true);
    actionRequestInit({
      api: apiVote,
      id: REQUEST_VOTE_QUESTION,
      params: {
        data: {
          answers: getSelectedQuestions(),
        },
        error,
        lang,
        orgId,
        questionId,
        questionnaireId,
      },
    });
  }

  function getAnswersTexts(answers) {
    const texts = answers.reduce((texts, item) => {
      const path = PATH_BY_TYPE[item.type];
      texts.push(item?.data[path]);
      return texts;
    }, []);
    return texts.join();
  }

  function handleAction() {
    const selected = getSelectedQuestions();
    const answerTexts = getAnswersTexts(selected);
    const privacy = isAnonymous ? 'annonymous' : 'non_annonymous';
    if (isFinished) {
      onClose();
    } else if (showIntro) {
      setShowIntro(false);
      trackAnalytics({
        action: 'click_start',
        category: 'questionnaires',
        label: questionnaireId,
        q_name: title,
        privacy,
      });
    } else {
      trackAnalytics({
        action: `click_next_${current + 1}`,
        category: 'questionnaires',
        label: `question: ${titleQuestion}. answers: ${answerTexts}`,
        q_id: questionnaireId,
        q_name: title,
        q_type: questionAnswersType,
        privacy,
      });
      voteQuestion();
    }
  }

  function handleChange(key, option, value) {
    const type = get(option, 'selected.type');
    const dataValue = get(option, 'selected.data.value');
    let copyOptions = [...optionsQuestion];

    if (type === SELECT_TYPE_TEXT) {
      copyOptions[key].selected.data.value = value;
    } else if (type === SELECT_TYPE_ID_LIST) {
      copyOptions[key].selected.data.value = String(value);
    } else if (type === SELECT_TYPE_BOOL) {
      if (isMultiple) {
        copyOptions[key].selected.data.value = !dataValue;
      } else {
        const options = copyOptions.map((option, index) => ({
          ...option,
          selected: {
            ...option.selected,
            data: {
              value: key === index && !dataValue,
            },
          },
          disabled: key !== index && !dataValue,
        }));
        copyOptions = [...options];
      }
    } else if (type === SELECT_TYPE_NUMERIC) {
      const options = copyOptions.map((option, index) => ({
        ...option,
        selected: {
          ...option.selected,
          data: {
            value: key === index ? value : null,
          },
        },
      }));
      copyOptions = [...options];
    }

    setOptionsQuestion(copyOptions);
  }

  function getStyles() {
    const styles = {};

    if (appearance) {
      styles.backgroundColor = appearance;
    }

    if (bgType === BG_TYPE_IMAGE && bgImage && !error) {
      styles.backgroundImage = `url("${bgImage}")`;
    }

    if (bgType === BG_TYPE_COLOR && bgColor) {
      styles.backgroundColor = bgColor;
    }

    if (bgType === BG_TYPE_GRADIENT && bgColors.length) {
      styles.backgroundImage = `linear-gradient(${bgColors.join()})`;
    }

    if (error || isFinished) {
      styles.backgroundImage = 'none';
    }

    return styles;
  }

  function getActionText() {
    let action = 'Next';

    if (isFinished) {
      action = 'Close';
    } else if (showIntro) {
      action = 'Begin';
    } else if (questionAnswersType === TYPE_SEND_STAR) {
      action = 'PickANewQuestion';
    } else if (isOptional && (getSelectedQuestions().length === 0)) {
      action = 'Skip';
    } else if (error) {
      action = 'Retry';
    }

    return action;
  }

  function getActionStyles() {
    const styles = {};

    if (isQuestion) {
      if (questionAnswersType === TYPE_SEND_STAR) {
        styles.backgroundColor = appearance;
      }
    }

    return styles;
  }

  function getDescription(type) {
    const description = {
      [TYPE_EMOJI]: isMultiple
        ? formatMessage(messages.QuestionnairesFlexDescriptionHelperEmojiMultiple)
        : formatMessage(messages.QuestionnairesFlexDescriptionHelperEmoji),
      [TYPE_IMAGE]: isMultiple
        ? formatMessage(messages.QuestionnairesFlexDescriptionHelperImageMultiple)
        : formatMessage(messages.QuestionnairesFlexDescriptionHelperImage),
      [TYPE_SEND_STAR]: formatMessage(messages.QuestionnairesFlexDescriptionHelperSendStar),
      [TYPE_TEXTFIELD]: '',
      [TYPE_TEXT]: isMultiple
        ? formatMessage(messages.QuestionnairesFlexDescriptionHelperTextMultiple)
        : formatMessage(messages.QuestionnairesFlexDescriptionHelperTextSingle),
    };

    return description[type];
  }

  useEffect(() => {
    if (prevRequesting && !requesting) {
      if (!error) {
        setOptionsQuestion(get(data, 'question.data.options', []));
      }
      setIsLoading(false);
    }
  }, [prevRequesting, requesting, error, data, setOptionsQuestion, setIsLoading]);

  useEffect(() => {
    const event = {
      q_name: questionnaireTitle,
      q_id: questionnaireId,
      privacy: isAnonymous ? 'anonymous' : 'non_anonymous',
    };
    if (isFinished) {
      trackPageview({
        pageName: '/questionnaires/typ',
        ...event,
      });
      trackAnalytics({
        action: 'finish',
        category: 'questionnaires',
        label: questionnaireId,
        q_name: questionnaireTitle,
      });
    } else if (showIntro) {
      trackPageview({
        pageName: '/questionnaires/start',
        ...event,
      });
    } else {
      trackPageview({
        pageName: `/questionnaires/question-${current + 1}`,
        q_type: questionAnswersType,
        ...event,
      });
    }
  }, [current, isFinished]);

  useEffect(() => {
    if (data && !prevIsFinished && isFinished) {
      onFinished();
    }
  }, [data, prevIsFinished, isFinished, onFinished]);

  useEffect(
    () => () => {
      actionRequestDestroy(REQUEST_VOTE_QUESTION);
    },
    [],
  );

  const totalSelectedQuestions = getSelectedQuestions().length;
  const isValidMin = totalSelectedQuestions >= minSelection;
  const isValidMax = totalSelectedQuestions <= maxSelection;
  const isValid = isFinished || (isValidMin && isValidMax);
  const disabled = showIntro ? false : !isValid;
  const questionDescription = descriptionQuestion || getDescription(questionAnswersType);

  return (
    <div className="questionnaires-flex" style={getStyles()}>
      <div className="questionnaires-flex__navigation">
        {prevQuestionId && (
          <Icon
            className="questionnaires-flex__navigation-icon questionnaires-flex__navigation-icon-back"
            icon="back"
            onClick={handleBackButton}
            size="small"
          />
        )}
        <Icon
          className="questionnaires-flex__navigation-icon questionnaires-flex__navigation-icon-close"
          icon="close"
          onClick={onClose}
          size="small"
        />
      </div>
      {(isLoading || [STATUS.SENDING, STATUS.WAITING].includes(sendStarFlowStatus)) && (
        <div className="questionnaires-flex__loading">
          <LottieSpinner className="questionnaires-flex__loading-spinner" loadingType="takepart" />
          <div className="questionnaires-flex__loading-background" style={getStyles()} />
        </div>
      )}
      {showIntro && (
        <div className="questionnaires-flex__intro">
          <Introduction description={description} title={title} />
        </div>
      )}
      {isFinished && (
        <div className="questionnaires-flex__finished">
          <Finished />
        </div>
      )}
      {error && (
        <div className="questionnaires-flex__error">
          <Error />
        </div>
      )}
      {isQuestion && (
        <div className="questionnaires-flex__question">
          <div className="questionnaires-flex__question-progress">
            {`${current + 1} ${formatMessage(messages.QuestionnairesFlexProgressPreposition)} ${total}`}
          </div>
          {titleQuestion && (
            <div
              className="questionnaires-flex__question-title"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: titleQuestion }}
            />
          )}
          {questionDescription && (
            <div className="questionnaires-flex__question-description">
              {questionDescription}
            </div>
          )}
          {questionAnswersType !== TYPE_SEND_STAR && (
            <div className="questionnaires-flex__question-divider" />
          )}
          <div
            className={cn(
              'questionnaires-flex__question-options',
              { [`questionnaires-flex__question-options--layout-${layout.toLowerCase()}`]: layout },
              `questionnaires-flex__question-options--type-${questionAnswersType}`,
            )}
          >
            {optionsQuestion.map((option, key) => {
              const Component = COMPONENTS_TYPES?.[option.type];

              return Component && (
                <div className="questionnaires-flex__question-component" key={`${key}`}>
                  <Component
                    appearance={appearance}
                    disabled={option?.disabled}
                    onChange={value => handleChange(key, option, value)}
                    onClick={value => handleChange(key, option, value)}
                    option={option}
                    requesting={requesting}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!error && (
        <>
          <div
            className={cn(
              'questionnaires-flex__submit',
              { [`questionnaires-flex__submit--type-${questionAnswersType}`]: isQuestion },
            )}
          >
            <Button
              className="questionnaires-flex__submit-button"
              disabled={disabled}
              fluid
              onClick={handleAction}
              style={getActionStyles()}
            >
              {formatMessage(messages[`QuestionnairesFlex${getActionText()}`])}
            </Button>
          </div>
          {showIntro && isAnonymous && (
            <div className="questionnaires-flex__anonymous">
              <img
                alt="Anonymous survey"
                className="questionnaires-flex__anonymous-image"
                src="/assets/img/icon_incognito.svg"
              />
              <div className="questionnaires-flex__anonymous-content">
                <span>
                  {formatMessage(messages.QuestionnairesFlexAnonymousDisclaimer)}
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

QuestionnairesFlex.defaultProps = {
  error: undefined,
  onClose: () => undefined,
  onFinished: () => undefined,
  requesting: false,
  result: undefined,
};

QuestionnairesFlex.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  error: PropTypes.object,
  initialData: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onFinished: PropTypes.func,
  orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  requesting: PropTypes.bool,
  result: PropTypes.object,
  sendStarFlowStatus: PropTypes.string,
};

const mapStateToProps = state => ({
  error: selectError(state, REQUEST_VOTE_QUESTION),
  lang: selectLanguageCode(state),
  orgId: selectOrgId(state),
  requesting: selectRequesting(state, REQUEST_VOTE_QUESTION),
  result: selectResultProp(state, REQUEST_VOTE_QUESTION, 'data'),
  sendStarFlowStatus: selectStarFlowStatus(state),
});

export default connect(mapStateToProps, { actionRequestDestroy, actionRequestInit })(
  injectIntl(QuestionnairesFlex),
);
