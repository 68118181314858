// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import Modal from 'components/Modal';
import YIR from 'containers/YIR';

const ModalYIRContainer = ({ onClose, ...rest }) => (
  <Modal {...rest} onClick={onClose}>
    <div className="relative w-full h-full">
      <Button
        className={cn(
          '!fixed right-5 top-5 z-20 w-8 h-8 rounded-full text-white transition-colors hover:bg-black',
          'flex items-center justify-center p-0',
        )}
        onClick={onClose}
        variant="text"
      >
        <Icon className="!text-sm text-white" icon="close" />
      </Button>
      <YIR
        className="w-full h-full flex items-center justify-center p-5"
        closeModal={onClose}
        height={844}
        width={390}
      />
    </div>
  </Modal>
);

YIR.propTypes = {
  onClose: PropTypes.func,
};

export default ModalYIRContainer;
