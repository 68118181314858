// @Packages
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Popup from 'smu-ui-components/Popup';
import UserAvatar from 'smu-ui-components/UserAvatar';

// @Own
import './styles.scss';

const Item = ({
  className,
  firstName,
  fullScreenImgOnClick,
  job,
  lastName,
  name,
  onAvatarClick,
  profileImageCode,
  size,
  style,
  userAvatarClassName,
}) => {
  const getInfo = () => (
    <Fragment>
      <h5 className="tooltip-user__text tooltip-user__name">{name || `${firstName} ${lastName}`}</h5>
      <h5 className="tooltip-user__text tooltip-user__job">{job}</h5>
    </Fragment>
  );

  return (
    <li className={className} style={style}>
      <Popup
        className="tooltip-user"
        content={getInfo()}
        on="hover"
        position="top center"
        suiteInverted
        trigger={
          <div>
            <UserAvatar
              bordered
              className={userAvatarClassName}
              fullScreenImgOnClick={fullScreenImgOnClick}
              height={size}
              onClick={onAvatarClick}
              profileImageCode={profileImageCode}
              width={size}
            />
          </div>
        }
      />
    </li>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  firstName: PropTypes.string,
  fullScreenImgOnClick: PropTypes.bool,
  job: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  onAvatarClick: PropTypes.func,
  profileImageCode: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  userAvatarClassName: PropTypes.string,
};

Item.defaultProps = {
  className: '',
  fullScreenImgOnClick: false,
  onAvatarClick: () => undefined,
  size: 32,
  userAvatarClassName: '',
};

export default Item;
