import { buildClosePath } from '../helpers';

const badgesCongratulations = ({ hookModal, query, onClose }) => {
  if (query?.id) {
    const closePath = buildClosePath(['id']);
    hookModal.open({
      id: query?.id,
      onClose: () => onClose(closePath),
    });
  }
};

export default badgesCongratulations;
