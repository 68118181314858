import { apiInstance, apiShareInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from '../../api';

export function getShareCode({ path }) {
  return apiInstance({
    url: addParams(path, {
      ...vo,
      ...times(),
    }),
    method: 'get',
  })
    .then(({ data }) => (data))
    .catch(errorHandler);
}

export function registerShared({ shareCode, type }) {
  const baseUrl = `/v2/share/registerShared/${shareCode}/socialNetwork/${type}`;
  return apiInstance({
    url: addParams(baseUrl, {
      ...vo,
      ...times(),
    }),
    method: 'get',
  })
    .then(({ data }) => (data))
    .catch(errorHandler);
}

export function urlShortener({ key, longUrl }) {
  return apiShareInstance({
    url: addParams(null, { key }),
    data: { longUrl },
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
  })
    .then(({ data }) => (data))
    .catch(errorHandler);
}
