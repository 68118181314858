import { concat } from 'lodash';

import {
  CHANGE_LIVING_VALUE_VIEW,
  CHANGE_STAR_MESSAGE,
  CLOSE_LIVING_VALUES,
  DISMISS_LIVING_VALUES_SUCCESS,
  GET_LIVING_VALUES_TEXTS_FAIL,
  GET_LIVING_VALUES_TEXTS_SUCCESS,
  PICK_LIVING_VALUE,
  PICK_USER,
  REMOVE_SELECTED_USER,
  SEND_LIVING_VALUES,
  SEND_LIVING_VALUES_FAIL,
  SEND_LIVING_VALUES_SUCCESS,
  SET_VALUES_DONE,
  START_LIVING_VALUES,
  START_LIVING_VALUES_FAIL,
  START_LIVING_VALUES_SUCCESS,
} from './actionTypes';

const initialState = {
  currentView: 'start',
  fetching: false,
  fetchingValues: false,
  manuallyFlow: false,
  texts: {},
  usersSelected: [],
  valueSelected: undefined,
  valuesDone: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CLOSE_LIVING_VALUES:
      return {
        ...initialState,
        texts: state.texts || {},
      };
    case CHANGE_LIVING_VALUE_VIEW:
      return {
        ...state,
        currentView: payload,
      };
    case CHANGE_STAR_MESSAGE:
      return {
        ...state,
        usersSelected: state.usersSelected.map((user) => {
          if (user.starId === payload.starId) {
            return {
              ...user,
              customMessage: payload.customMessage,
            };
          }
          return user;
        }),
      };
    case DISMISS_LIVING_VALUES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_LIVING_VALUES_TEXTS_FAIL:
      return {
        ...state,
      };
    case GET_LIVING_VALUES_TEXTS_SUCCESS:
      return {
        ...state,
        texts: payload,
      };
    case PICK_LIVING_VALUE:
      return {
        ...state,
        valueSelected: payload,
      };
    case PICK_USER:
      return {
        ...state,
        usersSelected: concat(state.usersSelected, payload),
      };
    case REMOVE_SELECTED_USER:
      return {
        ...state,
        usersSelected: state.usersSelected.filter(user => user.starId !== payload),
      };
    case SEND_LIVING_VALUES:
      return {
        fetching: true,
        ...state,
      };
    case SEND_LIVING_VALUES_FAIL:
      return {
        fetching: false,
        ...state,
      };
    case SEND_LIVING_VALUES_SUCCESS:
      return {
        fetching: false,
        ...state,
      };
    case SET_VALUES_DONE:
      return {
        ...state,
        valuesDone: payload,
      };
    case START_LIVING_VALUES:
      return {
        ...state,
        fetchingValues: true,
      };
    case START_LIVING_VALUES_FAIL:
    case START_LIVING_VALUES_SUCCESS:
      return {
        ...state,
        fetchingValues: false,
      };
    default:
      return state;
  }
}
