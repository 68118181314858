export const INFORMATIONAL = 'informational';
export const NECESSARY = 'necessary';
export const PERFORMANCE = 'performance';
export const TARGETING = 'targeting';
export const ANALYSIS = 'analysis';

export const DETAIL_COOKIES = 'detailCookies';
export const PURPOSE = 'purpose';
export const WHAT_IS = 'whatIs';
export const WHAT_USE = 'whatUse';

export const COOKIES_NAME = 'cookies';
export const LOCALE_RESOURCES_COOKIES = 'preferences.cookies.text';

export const LANDING_URL = 'https://os.starmeup.com';
export const BTN_SAVE_DEFAULT_MSG = 'SAVE';
export const ERROR_REQUEST_DEFAULT_MSG = 'We have a error with this request';
export const PREFERENCES_SUCCESS_DEFAULT_MSG = 'Preferences have been created succesfully';

export const DELAY_RELOCATION = 3 * 1000;
