import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from './../../api';

export function dismissLivingValues() {
  const baseUrl = '/v2/stellar/livingValues/dismiss';
  return apiInstance({
    url: addParams(baseUrl, {
      ...vo,
      ...times(),
    }),
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}

export function getLivingValues() {
  const baseUrl = '/v2/stellar/livingValues';
  return apiInstance({
    url: baseUrl,
    method: 'get',
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}

export function getLivingValuesTexts(params) {
  return apiInstance({
    url: '/v2/stellar/resources',
    params,
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}

export function postponeLivingValues() {
  const baseUrl = '/v2/stellar/livingValues/postpone';
  return apiInstance({
    url: addParams(baseUrl, {
      ...vo,
      ...times(),
    }),
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}

export function sendLivingValues(stars) {
  const baseUrl = '/v2/stellar/livingValues';
  return apiInstance({
    url: addParams(baseUrl, {
      ...vo,
      ...times(),
    }),
    headers: { 'Content-Type': 'application/json' },
    data: { stars },
    method: 'post',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
