// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import ModalTaggedUsers from 'components/Modals/TaggedUsers';

// @own
import messages from './messages';
import { helperNormalizeUsers } from './helpers';
import { eventOnOpenModal, eventOnCloseModal } from './analytics';

const ModalTagguedUsersContainer = ({
  users,
  intl: { formatMessage },
  ...rest
}) => {
  useEffect(() => {
    eventOnOpenModal();
    return () => eventOnCloseModal();
  }, []);

  return (
    <ModalTaggedUsers
      title={formatMessage(messages.title)}
      users={helperNormalizeUsers(users)}
      {...rest}
    />
  );
};

ModalTagguedUsersContainer.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.number,
      user_image_code: PropTypes.string,
      user_job: PropTypes.string,
      user_lastname: PropTypes.string,
      user_name: PropTypes.string,
    }),
  ),
};

export default injectIntl(ModalTagguedUsersContainer);
