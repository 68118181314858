import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, isUndefined, omit, merge, some, orderBy } from 'lodash';
import { injectIntl } from 'react-intl';
import cn from 'classnames';

import messages from './messages';

export const mergeChangedField = (fields, field, selected) => (
  isUndefined(selected)
    ? merge({}, omit(fields, field), { [field]: undefined })
    : merge({}, fields, { [field]: selected })
);

const findElement = (values, selected) => values.find(item => item.id === selected);

const handleParentsSelection = (
  availableFilters,
  filterId,
  parentField,
  selectedItem,
  selection,
) => {
  if (!parentField) {
    selection[filterId] = selectedItem.id || 0;
    return selection;
  }
  selection[filterId] = selectedItem.id || 0;
  const { parentField: filterParentField, values: parentValues } = availableFilters[parentField];
  const parentData = parentValues && parentValues.ALL ? parentValues.ALL : [];
  const selectParent = findElement(parentData, selectedItem.parentValueId);
  if (selectParent) {
    return handleParentsSelection(
      availableFilters,
      parentField,
      filterParentField,
      selectParent,
      selection,
    );
  }
  return selection;
};

const handleChildrenSelection = (
  availableFilters,
  filterId,
  selectedItems,
  selection,
) => {
  const hasChildren = availableFilters[filterId].children
    && !!availableFilters[filterId].children.length;
  if (hasChildren) {
    const { children } = availableFilters[filterId];
    children.map((item) => {
      const childValues = availableFilters[item].values.ALL || [];
      const childSelected = childValues.find(value => value.id === selectedItems[item]);
      if (childSelected && childSelected.parentValueId !== selectedItems[filterId]) {
        selection[item] = '0';
      }
      if (availableFilters[item].children
        && !!availableFilters[item].children.length) {
        return handleChildrenSelection(
          availableFilters,
          item,
          merge(selectedItems, selection),
          selection,
        );
      }
      return selection;
    });
  }
  return selection;
};

export const handleSelection = (
  availableFilters,
  field,
  fields,
  filterId,
  selected,
) => {
  const { values, parentField } = field;
  const selectedItem = findElement(values.ALL, selected);
  let selection = {};
  if (selectedItem) {
    const parentsSelection = handleParentsSelection(
      availableFilters,
      filterId,
      parentField,
      selectedItem,
      selection,
    );
    const childrenSelection = handleChildrenSelection(
      availableFilters,
      filterId,
      merge(fields, parentsSelection),
      {},
    ) || {};
    selection = merge(parentsSelection, childrenSelection);
  } else {
    selection[filterId] = '0';
  }
  return merge({}, fields, selection);
};

const Select = ({
  className: baseClassName,
  field,
  onChange,
  options,
  orderByLabel,
  placeholder,
  selectMessage,
  value,
}) => {
  const filterEmpty = s => (s === '' ? undefined : s);
  const valueInOptions = value && some(options, ({ id: v }) => v === value);
  const className = cn(baseClassName, {
    [`${baseClassName}__option-selected`]: baseClassName && valueInOptions,
  });
  const handleChange = (value) => {
    if (onChange) {
      onChange(filterEmpty(value));
    }
  };
  const values = orderByLabel && field !== 'periodTime' && field !== 'organizationValue' ? orderBy(options, ['label']) : options;
  return (
    <select
      className={className}
      onChange={e => handleChange(e.target.value)}
      placeholder={placeholder}
      value={value}
    >
      { placeholder &&
      <option key="empty" value="">
        {selectMessage}
      </option>}
      {map(values, ({ label, id }, index) => (
        <option key={`${index}-${id}`} value={id}>
          {label}
        </option>
      ))}
    </select>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  })),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  selectMessage: PropTypes.string,
  onChange: PropTypes.func,
  orderByLabel: PropTypes.bool,
  field: PropTypes.string,
};

const mapStateToProps = (state, { placeholder, intl }) => {
  const { formatMessage } = intl;
  return {
    selectMessage: placeholder && formatMessage(messages.select, { filter: placeholder }),
  };
};

export default injectIntl(connect(mapStateToProps)(Select));
