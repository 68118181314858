// @packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import UserSettings from 'smu-app-components/UserSettings';
import { connect } from 'react-redux';

// @app
import { NEW_NOTIFICATION_SETTINGS } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';

const Settings = ({ defaultExpandedId, newNotificationsSettings, ...rest }) => {
  const notificationProps = useMemo(
    () => ({
      defaultExpandedId,
      newNotificationsSettings,
    }),
    [defaultExpandedId, newNotificationsSettings],
  );

  return (
    <UserSettings notificationProps={notificationProps} {...rest} />
  );
};

Settings.propTypes = {
  defaultExpandedId: PropTypes.number,
  newNotificationsSettings: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  newNotificationsSettings: getCommunityConfigValueBool(
    state,
    NEW_NOTIFICATION_SETTINGS,
  ),
});

export default connect(mapStateToProps)(Settings);
