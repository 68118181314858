// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import useModal from 'smu-custom-hooks/useModal';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { closeModal, openModal } from 'smu-app-components/RootModalV2/actions';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import {
  openFullscreenModal,
  closeFullscreenModal,
} from 'smu-app-components/FullScreenModalV2/actions';

// @own
import { MODAL_HANDLERS, MODAL_COMPONENTS } from './constants';

function QueryModals({
  intl: { formatMessage },
  router: {
    push,
    location: { pathname, query },
  },
}) {
  const dispatch = useDispatch();
  const modal = query?.modal?.toLowerCase() ?? '';
  const prevModal = usePrevious(modal);
  const prevQuery = usePrevious(query);
  const hookModal = useModal();
  const ModalComponent = MODAL_COMPONENTS[modal];
  const showModalComponent =
    prevModal === modal && hookModal.isOpen && !!ModalComponent;

  function handleClose(path = '/home') {
    push(path);
  }

  useEffect(() => {
    if (hookModal.isOpen && !showModalComponent) {
      hookModal.close();
    }
  }, [hookModal.isOpen, showModalComponent]);

  useEffect(() => {
    if (
      modal &&
      !isEqual(prevQuery, query) &&
      typeof MODAL_HANDLERS[modal] === 'function'
    ) {
      MODAL_HANDLERS[modal]({
        closeFullscreenModal,
        closeModal,
        dispatch,
        formatMessage,
        hookModal,
        onClose: handleClose,
        openFullscreenModal,
        openModal,
        query,
        push,
      });
    }
  }, [
    MODAL_HANDLERS,
    closeFullscreenModal,
    dispatch,
    formatMessage,
    handleClose,
    hookModal,
    modal,
    openFullscreenModal,
    openModal,
    pathname,
    prevQuery,
    push,
    query,
  ]);

  return showModalComponent ? (
    <ModalComponent
      key={modal}
      onClose={hookModal.close}
      open={hookModal.isOpen}
      {...hookModal.data}
    />
  ) : null;
}

QueryModals.propTypes = {
  intl: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(QueryModals));
