import { defineMessages } from 'react-intl';

export default defineMessages({
  tooltip: {
    id: 'cards.actionBrighten.tooltip',
    defaultMessage: '+1 Join star',
  },
  disabled: {
    id: 'cards.actionBrighten.disabled',
    defaultMessage:
      "Looks like you can't join this recognition because you either sent it, received it, or it was created more than 30 days ago.",
  },
});
