import { takeEvery, put, call } from 'redux-saga/effects';
import { getProfile } from './api';

import {
  GET_SEND_STAR_PROFILE,
} from './actionTypes';

import {
  requestSendStarProfileSuccess,
  requestSendStarProfileFail,
} from './actions';

function* getUserConfigsWorker({ payload }) {
  try {
    const { uid } = payload;
    const result = yield call(getProfile, uid);
    yield put(requestSendStarProfileSuccess(result));
  } catch (e) {
    yield put(requestSendStarProfileFail(e));
  }
}

export function* getUserConfigsWatcher() {
  yield takeEvery(GET_SEND_STAR_PROFILE, getUserConfigsWorker);
}

export default {
  getUserConfigsWatcher,
};
