export const GET_LOOKUP_COOKIES_PAGE = 'GET_LOOKUP_COOKIES_PAGE';
export const GET_LOOKUP_COOKIES_PAGE_SUCCESS = 'GET_LOOKUP_COOKIES_PAGE_SUCCESS';
export const GET_LOOKUP_COOKIES_PAGE_FAIL = 'GET_LOOKUP_COOKIES_PAGE_FAIL';
export const GET_COMMUNITY_COOKIES_PREFERENCES = 'GET_COMMUNITY_COOKIES_PREFERENCES';
export const GET_COMMUNITY_COOKIES_PREFERENCES_FAIL = 'GET_COMMUNITY_COOKIES_PREFERENCES_FAIL';
export const GET_COMMUNITY_COOKIES_PREFERENCES_SUCCESS = 'GET_COMMUNITY_COOKIES_PREFERENCES_SUCCESS';
export const GET_LOCALE_RESOURCES = 'GET_LOCALE_RESOURCES';
export const GET_LOCALE_RESOURCES_FAIL = 'GET_LOCALE_RESOURCES_FAIL';
export const GET_LOCALE_RESOURCES_SUCCESS = 'GET_LOCALE_RESOURCES_SUCCESS';
export const GET_USER_COOKIES_PREFERENCES = 'GET_USER_COOKIES_PREFERENCES';
export const GET_USER_COOKIES_PREFERENCES_FAIL = 'GET_USER_COOKIES_PREFERENCES_FAIL';
export const GET_USER_COOKIES_PREFERENCES_SUCCESS = 'GET_USER_COOKIES_PREFERENCES_SUCCESS';
export const POST_USER_COOKIES_PREFERENCES = 'POST_USER_COOKIES_PREFERENCES';
export const POST_USER_COOKIES_PREFERENCES_FAIL = 'POST_USER_COOKIES_PREFERENCES_FAIL';
export const POST_USER_COOKIES_PREFERENCES_SUCCESS = 'POST_USER_COOKIES_PREFERENCES_SUCCESS';
export const SWITCH_USER_PREFERENCE = 'SWITCH_USER_PREFERENCE';
