// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import Link from 'components/Link';

// @own
import './styles.scss';

const AvatarInfo = ({
  className,
  description,
  href,
  onClickTitle,
  title,
  ...rest
}) => {
  const TitleComponent = href ? Link : 'span';

  return (
    <div
      className={cn('smu-avatar-info', className, {
        'smu-avatar-info--variant-link': href || onClickTitle,
      })}
      {...rest}
    >
      {title && (
        <TitleComponent
          className="smu-avatar-info__title"
          href={href}
          onClick={onClickTitle}
        >
          {title}
        </TitleComponent>
      )}
      {description && (
        <label className="smu-avatar-info__description">{description}</label>
      )}
    </div>
  );
};

AvatarInfo.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  onClickTitle: PropTypes.func,
  title: PropTypes.string,
};

export default AvatarInfo;
