import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { apiUrl } from 'utils/apiUrl';
import { FormattedMessage } from 'react-intl';
import Cn from 'classnames';

import MediaButton from 'components/MediaButton';
import { socialMediaShare } from 'routes/Activity/sagas';
import messages from './messages';
import './styles.scss';

const Badge = ({
  name,
  intoModal,
  imageCode,
  width,
  height,
  percentage,
  items,
  socialMedias,
  socialMediaShare,
}) => {
  const source = `${apiUrl}/image/${imageCode}/${width}/${height}/1`;
  return (
    <div className={Cn('badge-item', {
      'badge-item--modal': intoModal,
    })}
    >
      <img
        className="badge-item__image"
        alt={name}
        src={source}
      />
      <div className="badge-item__percentage">
        <div className="badge-item__percentage-container">
          <div className="badge-item__percentage-number">
            {(percentage * 100).toFixed()} %
            <p>
              <FormattedMessage {...messages.completed} />
            </p>
          </div>
          <div className="badge-item__percentage-description">
            {map(items, ({ key, description }) => (
              <p className="badge-item__percentage-description--line" key={key}>
                - {description}
              </p>
            ))}
          </div>
        </div>
        {percentage === 1 && (
          <div className="badge-item__percentage-medias">
            {map(socialMedias, media => (
              <MediaButton
                key={media.icon}
                onClick={() => {
                  const windowRef = window.open(
                    '',
                    'StarmeUp',
                    'resizable=no,menubar=no,location=no,status=no,width=600,height=550',
                  );
                  socialMediaShare({
                    ...media,
                    windowRef,
                  });
                }}
                media={media.icon}
                size={24}
              />
            ))}
          </div>
        )}
      </div>

    </div>
  );
};

Badge.defaultProps = {
  width: 90,
  height: 90,
  percentage: 0,
  socialMedias: [],
};

Badge.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  imageCode: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  intoModal: PropTypes.bool,
  percentage: PropTypes.number,
  socialMedias: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
  })),
  socialMediaShare: PropTypes.func.isRequired,
  width: PropTypes.number,
};

export default connect(null, { socialMediaShare })(Badge);
