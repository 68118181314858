export const GET_PENDING_QUESTIONNAIRES = 'GET_PENDING_QUESTIONNAIRES';
export const GET_PENDING_QUESTIONNAIRES_FAIL = 'GET_PENDING_QUESTIONNAIRES_FAIL';
export const GET_PENDING_QUESTIONNAIRES_SUCCESS = 'GET_PENDING_QUESTIONNAIRES_SUCCESS';

export const GET_QUESTIONNAIRE_BY_ID = 'GET_QUESTIONNAIRE_BY_ID';
export const GET_QUESTIONNAIRE_BY_ID_FAIL = 'GET_QUESTIONNAIRE_BY_ID_FAIL';
export const GET_QUESTIONNAIRE_BY_ID_SUCCESS = 'GET_QUESTIONNAIRE_BY_ID_SUCCESS';

export const PUT_LANGUAGE_BY_QUESTIONNAIRE_ID = 'PUT_LANGUAGE_BY_QUESTIONNAIRE_ID';
export const PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_FAIL = 'PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_FAIL';
export const PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_SUCCESS = 'PUT_LANGUAGE_BY_QUESTIONNAIRE_ID_SUCCESS';

export const POST_DEMOGRAPHICS_BY_QUESTIONNAIRE = 'POST_DEMOGRAPHICS_BY_QUESTIONNAIRE';
export const POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_FAIL = 'POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_FAIL';
export const POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_SUCCESS = 'POST_DEMOGRAPHICS_BY_QUESTIONNAIRE_SUCCESS';

export const POST_ANSWER_BY_QUESTION_ID = 'POST_ANSWER_BY_QUESTION_ID';
export const POST_ANSWER_BY_QUESTION_ID_FAIL = 'POST_ANSWER_BY_QUESTION_ID_FAIL';
export const POST_ANSWER_BY_QUESTION_ID_SUCCESS = 'POST_ANSWER_BY_QUESTION_ID_SUCCESS';

export const POST_COMMENTS_BY_QUESTIONNAIRE = 'POST_COMMENTS_BY_QUESTIONNAIRE';
export const POST_COMMENTS_BY_QUESTIONNAIRE_FAIL = 'POST_COMMENTS_BY_QUESTIONNAIRE_FAIL';
export const POST_COMMENTS_BY_QUESTIONNAIRE_SUCCESS = 'POST_COMMENTS_BY_QUESTIONNAIRE_SUCCESS';

export const RESET_QUESTIONNAIRE = 'RESET_QUESTIONNAIRE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
