// @own
import { ENABLED_FILTERS, FILTER_MOMENTS } from './constants';

export const isValidFilter = (filter) => ENABLED_FILTERS.includes(filter);

export const isEnabledFilter = ({ enabledBethere, filter }) => {
  const isValid = isValidFilter(filter);

  if (isValid && filter === FILTER_MOMENTS) {
    return enabledBethere;
  }

  return isValid;
};
