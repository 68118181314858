import {
  GET_COMMUNITY_COOKIES_PREFERENCES,
  GET_COMMUNITY_COOKIES_PREFERENCES_FAIL,
  GET_COMMUNITY_COOKIES_PREFERENCES_SUCCESS,
  GET_LOCALE_RESOURCES,
  GET_LOCALE_RESOURCES_FAIL,
  GET_LOCALE_RESOURCES_SUCCESS,
  GET_USER_COOKIES_PREFERENCES,
  GET_USER_COOKIES_PREFERENCES_FAIL,
  GET_USER_COOKIES_PREFERENCES_SUCCESS,
  POST_USER_COOKIES_PREFERENCES,
  POST_USER_COOKIES_PREFERENCES_FAIL,
  POST_USER_COOKIES_PREFERENCES_SUCCESS,
  SWITCH_USER_PREFERENCE,
  GET_LOOKUP_COOKIES_PAGE,
  GET_LOOKUP_COOKIES_PAGE_SUCCESS,
  GET_LOOKUP_COOKIES_PAGE_FAIL,
} from './actionType';

export function actionGetCommunityPreferences({ token }) {
  return {
    type: GET_COMMUNITY_COOKIES_PREFERENCES,
    payload: { token },
  };
}

export function actionGetCommunityPreferencesSuccess({ communityAllowsCookies }) {
  return {
    type: GET_COMMUNITY_COOKIES_PREFERENCES_SUCCESS,
    payload: { communityAllowsCookies },
  };
}
export function actionGetCommunityPreferencesFail(error) {
  return {
    type: GET_COMMUNITY_COOKIES_PREFERENCES_FAIL,
    payload: { error },
  };
}

export function actionGetLocaleResources({ token, language }) {
  return {
    type: GET_LOCALE_RESOURCES,
    payload: { token, language },
  };
}

export function actionGetLocaleResourcesSuccess({ token, cookiesResources }) {
  return {
    type: GET_LOCALE_RESOURCES_SUCCESS,
    payload: { token, cookiesResources },
  };
}

export function actionGetLocaleResourcesFail() {
  return {
    type: GET_LOCALE_RESOURCES_FAIL,
    payload: {},
  };
}

export function actionGetUserPreferences({ token }) {
  return {
    type: GET_USER_COOKIES_PREFERENCES,
    payload: { token },
  };
}

export function actionGetUserPreferencesSuccess({ userPreferences }) {
  return {
    type: GET_USER_COOKIES_PREFERENCES_SUCCESS,
    payload: { userPreferences },
  };
}

export function actionGetUserPreferencesFail({ error }) {
  return {
    type: GET_USER_COOKIES_PREFERENCES_FAIL,
    payload: { error },
  };
}

export function actionPostUserPreferences({
  userPreferences,
  token,
  cookiesResources,
  _continue,
}) {
  return {
    type: POST_USER_COOKIES_PREFERENCES,
    payload: {
      _continue,
      cookiesResources,
      token,
      userPreferences,
    },
  };
}

export function actionPostUserPreferencesSuccess({ userPreferences }) {
  return {
    type: POST_USER_COOKIES_PREFERENCES_SUCCESS,
    payload: { userPreferences },
  };
}

export function actionPostUserPreferencesFail({ error }) {
  return {
    type: POST_USER_COOKIES_PREFERENCES_FAIL,
    payload: { error },
  };
}

export function actionSwitchUserPreference(payload) {
  return {
    type: SWITCH_USER_PREFERENCE,
    payload,
  };
}

export function getLookupCookiesPage({
  language,
  requireAllRequest,
  token,
}) {
  return {
    type: GET_LOOKUP_COOKIES_PAGE,
    payload: {
      language,
      requireAllRequest,
      token,
    },
  };
}

export function getLookupCookiesPageSuccess() {
  return {
    type: GET_LOOKUP_COOKIES_PAGE_SUCCESS,
  };
}

export function getLookupCookiesPageFail() {
  return {
    type: GET_LOOKUP_COOKIES_PAGE_FAIL,
  };
}

