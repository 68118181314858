import { dataServiceInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function getCategory({ userId }) {
  const baseUrl = `v1/community/members/${userId}/metrics`;
  return dataServiceInstance({
    url: baseUrl,
    params: {
      period: '90.day',
      codes: 'os.cultural-level.name',
    },
  }).then(({ data }) => data.result)
    .catch(errorHandler);
}
