// @packages
import GoogleTagManagerAPP from 'smu-app-components/GoogleTagManager';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useGetMemberPreferences } from 'smu-services/os-member/api/v1/community/member/preferences/get';

// @app
import { ENABLED_INTERCOM } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { isProd } from 'utils/env';
import { selectCommunityName, selectCommunityId } from 'containers/Authorization/selectors';

// @own
import { COOKIES } from './constants';

const GoogleTagManager = ({
  communityId,
  communityName,
  intercomEnabled,
  userIsAdmin,
  userIsLeader,
}) => {
  const hook = useGetMemberPreferences({ name: COOKIES });

  const memberAllowCookies = useMemo(() => {
    const isLoaded = !hook.isLoading && !hook.error;
    const cookiesPerformance = hook.selectCookiesPerformance();
    return isLoaded && (cookiesPerformance || cookiesPerformance !== false);
  }, [hook.data]);

  if (memberAllowCookies) {
    return (
      <GoogleTagManagerAPP
        containerId={isProd() ? 'GTM-W7CPRG8' : 'GTM-PHZ6KQQ'}
        initialDataLayer={{
          communityId,
          communityName,
          intercomEnabled,
          userIsAdmin,
          userIsLeader,
        }}
      />
    );
  }

  return null;
};

GoogleTagManager.propTypes = {
  communityId: PropTypes.number,
  communityName: PropTypes.string,
  intercomEnabled: PropTypes.bool,
  userIsAdmin: PropTypes.bool,
  userIsLeader: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  communityName: selectCommunityName(state),
  intercomEnabled: getCommunityConfigValueBool(state, ENABLED_INTERCOM),
  userIsAdmin: getCommunityConfigValueBool(state, 'isAdmin'),
  userIsLeader: getCommunityConfigValueBool(state, 'isLeader'),
});

export default connect(mapStateToProps)(GoogleTagManager);
