// @Packages
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import Button from 'smu-ui-components/Button';
import InputArea from 'smu-ui-components/InputArea';

// @own
import './styles.scss';
// remove when the app will support device language
import messages from '../messages'; // eslint-disable-line

function QuestionnaireComments({
  handleSubmitComments,
  // remove when the app will support device language
  intl: { formatMessage }, // eslint-disable-line
  language,
}) {
  const [commentValue, setCommentValue] = useState('');

  const text = () => ({
    ENGLISH: {
      subtitle: 'Leave here your comments and/or suggestions',
      placeholder: 'Leave your comment here..',
      buttonNext: 'Next',
      buttonSkip: 'Skip',
    },
    SPANISH: {
      subtitle: 'Déjanos aquí tus comentarios y/o sugerencias',
      placeholder: 'Deje su comentario aquí ..',
      buttonNext: 'Siguiente',
      buttonSkip: 'Omitir',
    },
  })[language];

  const {
    subtitle,
    placeholder,
    buttonNext,
    buttonSkip,
  } = text();

  return (
    <Fragment>
      <div className="questionnaire-comments__subtitle">
        {subtitle}
      </div>
      <div className="questionnaire-comments__container">
        <InputArea
          className="questionnaire-comments__content"
          maxLength={400}
          onChange={e => setCommentValue(e.target.value)}
          placeholder={placeholder}
          value={commentValue}
        />
        <div className="questionnaire-comments__container-buttons">
          <Button
            className={cn('questionnaire-comments__button', { 'questionnaire-comments__button--disabled': commentValue.length < 3 })}
            disabled={commentValue.length < 3}
            onClick={() => handleSubmitComments(commentValue)}
          >
            {buttonNext}
          </Button>
          <button
            className="questionnaire-comments__button-skip"
            onClick={() => handleSubmitComments('')}
            type="button"
          >
            {buttonSkip}
          </button>
        </div>
      </div>
    </Fragment>
  );
}

QuestionnaireComments.propTypes = {
  handleSubmitComments: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
};

export default injectIntl(QuestionnaireComments);
