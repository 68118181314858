// @packages
import moment from 'moment';
import find from 'lodash/find';
import map from 'lodash/map';

// @app
import parseDate from 'utils/parseDate';

export const helperIsNotNDaysOld = (days, date) =>
  date && moment().subtract(days, 'days').isBefore(parseDate(date));

export const helperGetMainPhoto = (comments) =>
  find(comments, (c) => c?.type === 'MAIN_PHOTO')?.imageCode;

export const helperGetEntities = (activity) => {
  let users = [];

  if (!activity) {
    return users;
  }

  if (activity?.typeStar === 'K') {
    users = activity?.listUsersKudos;
  } else if (activity?.to) {
    users = [activity.to];
  }

  return map(users, (user) => ({
    ...user,
    name: `${user?.firstName} ${user?.lastName}`,
  }));
};

export const helperGetFeedPayload = (activityId, userId) => ({
  fields: {},
  options: {
    page: 0,
    size: 1,
    firstStarId: activityId,
    userId,
    useActivityUrl: true,
  },
  updateFeed: true,
});

export const helperGetStar = (stars, activity) =>
  find(stars, (s) => s.id === activity?.starMeUpOrganizationStar?.id);
