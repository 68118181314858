// @app
import { urls } from 'utils/routes';

// @packages
import UnifiedProfile from './UnifiedProfile';

export default () => ({
  path: urls.profile,
  component: UnifiedProfile,
});

