// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

function Image({ alt, className, id, onClick, src }) {
  return (
    <div
      className={cn('image-event-image', className)}
      onClick={() => onClick(id)}
    >
      <div className="image-event-image__wrapper">
        <img
          className="image-event-image__img"
          alt={alt}
          onClick={() => onClick(id)}
          src={src}
        />
        <div
          className="image-event-image__img-blur"
          style={{ backgroundImage: `url("${src}")` }}
        />
      </div>
    </div>
  );
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
};

export default Image;
