import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function getBadges({ userId }) {
  const baseUrl = `/v2/achievements/badges/${userId}`;
  return apiInstance({
    url: baseUrl,
  }).then(({ data }) => data.result)
    .catch(errorHandler);
}
