// @Packages
import React from 'react';
import PropTypes from 'prop-types';
import * as searchActions from 'smu-app-components/Search/actions';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import { connect } from 'react-redux';
import { map, merge } from 'lodash';
import { Popup } from 'semantic-ui-react';
import SMUSearchComponent from 'smu-ui-components/Search';
// @App
import addNamespace from 'containers/withNamespace';

export const actions = addNamespace(searchActions, 'star_flow');

const MAX_CUSTOMIZABLE_GROUP = 150;

const searchResultsItem = (itemProps, disabled, message) => {
  if (disabled) {
    return (
      <Popup
        className="send-star__search-popup"
        content={
          <div>
            {message}
          </div>
        }
        on="hover"
        position="bottom center"
        trigger={
          <div className="send-star__search-result-item">
            <AvatarMedia {...itemProps} />
          </div>
        }
      />
    );
  }
  return (<AvatarMedia {...itemProps} />);
};

const parseEntities = entities => map(
  entities || [],
  (entity) => {
    const isUser = !entity.type || entity.type === 'USER';
    return merge({}, entity, {
      profileImageCode: entity.imageCode,
      firstName: isUser
        ? entity.name
        : `${entity.name} | (${entity.amount}) ${entity.description}`,
      customizable: !isUser && entity.amount <= MAX_CUSTOMIZABLE_GROUP,
    });
  },
);

const MainSearch = ({
  disabled,
  ...rest
}) => (
  <SMUSearchComponent {...rest} />
);


MainSearch.propTypes = {
  disabled: PropTypes.bool,
};

const mapStateToProps = ({ starFlowSearch, session: { user } }, ownProps) => {
  const { disabled, messages: { noRemainingStars }, onClickItem } = ownProps;
  return ({
    customItem: itemProps => searchResultsItem(
      itemProps,
      disabled,
      noRemainingStars,
    ),
    disabled,
    onClickItem: disabled ? () => {} : onClickItem,
    search: {
      ...starFlowSearch,
      list: parseEntities(starFlowSearch.list),
    },
    user,
  });
};

export default connect(mapStateToProps, actions)(MainSearch);
