// @packages
import PropTypes from 'prop-types';
import { trackPageview } from 'smu-utils/gtm';
import { useEffect } from 'react';

function ExternalLayout({
  location: {
    query: { link },
    pathname,
  },
}) {
  useEffect(() => {
    trackPageview({
      pageName: pathname,
    });

    if (link) window.location.assign(link);
  }, [link]);

  return null;
}

ExternalLayout.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      link: PropTypes.string,
    }),
  }).isRequired,
  router: PropTypes.object.isRequired,
};

export default ExternalLayout;
