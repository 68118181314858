import React from 'react';
import { buildImageUrl } from 'smu-utils/image';
import { FormattedRelative } from 'react-intl';

// @app
import TextParser from 'containers/TextParser';
import parseDate from 'utils/parseDate';

export const getCommentsProps = (comments) =>
  comments?.map((c) => {
    const dateTime = c?.dateComment
      ? parseDate(c?.dateComment).toDate()
      : undefined;
    const userAvatar = buildImageUrl({
      code: c?.user?.profileImageCode,
      height: 27,
      width: 27,
    });
    const imageSrc = c.imageCode
      ? buildImageUrl({
          code: c.imageCode,
        })
      : undefined;
    const mentions = c?.listMentions?.map((u) => ({
      fullName: u?.fullName,
      id: u?.id,
      uid: u?.uid,
    }));

    return {
      dateTime: dateTime ? <FormattedRelative value={dateTime} /> : undefined,
      enabled: c?.enabled,
      id: c?.id,
      imageSrc,
      key: c?.id,
      message: (
        <TextParser
          urlProps={{
            externalRedirect: true,
          }}
          users={mentions}
        >
          {c?.comments || ''}
        </TextParser>
      ),
      userAvatar,
      userName: c?.user?.fullName,
      userProfile: c?.user?.uid ? `/profile/${c?.user?.uid}` : undefined,
    };
  });
