import { defineMessages } from 'react-intl';

export default defineMessages({
  errorHandlerUnAuthorize: {
    id: 'errorHandler.unAuthorize',
    defaultMessage: 'Your session has expired. Please try to login again.',
  },
  errorHandlerAnyError: {
    id: 'errorHandler.anyError',
    defaultMessage: 'Sorry, we are not able to load some widgets on the page, please try to refresh it later',
  },
});
