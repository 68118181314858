import {
  GET_SEND_STAR_PROFILE,
  GET_SEND_STAR_PROFILE_SUCCESS,
  GET_SEND_STAR_PROFILE_FAIL,
  REMOVE_SEND_STAR_PROFILE,
} from './actionTypes';

export function requestSendStarProfile({ uid }) {
  return {
    type: GET_SEND_STAR_PROFILE,
    payload: { uid },
  };
}

export function requestSendStarProfileSuccess(payload) {
  return {
    type: GET_SEND_STAR_PROFILE_SUCCESS,
    payload,
  };
}

export function requestSendStarProfileFail(payload) {
  return {
    type: GET_SEND_STAR_PROFILE_FAIL,
    payload,
  };
}

export function removeSendStarProfile() {
  return {
    type: REMOVE_SEND_STAR_PROFILE,
  };
}
