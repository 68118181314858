// @packages
import React from 'react';
import { connect } from 'react-redux';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import { injectIntl } from 'react-intl';
import { openModal, closeModal } from 'smu-app-components/RootModalV2/actions';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import cn from 'classnames';
import PropTypes from 'prop-types';

// @own
import './styles.scss';
import MyTeamModal from './MyTeamModal';
import messages from '../messages';
import { INSIGHT_TYPES } from '../constants';

function CulturalActivity({
  closeModal,
  data,
  graph,
  indicator,
  intl: { formatMessage },
  openModal,
  type,
}) {
  const baseUrl = `${getWebUrl()}/`;
  const bmeBaseUrl = `${getWebUrl('betterme')}/`;
  const isCollaboratorsBoard = type !== INSIGHT_TYPES.FEEDBACK_NOT_RECEIVED_FOR_YOUR_LED;

  function handleViewAll() {
    openModal({
      modalType: 'CULTURAL_MODAL',
      modalProps: {
        data,
        title: formatMessage(messages.CollaboratorsToGiveFeedback),
      },
    });
  }

  return (
    <div className="insights-leaderboard">
      {!isCollaboratorsBoard ? (
        <div className="insights-leaderboard__list">
          {data?.map((avatar) => (
            <a href={`${bmeBaseUrl}newfeedback/${window.btoa(avatar.identification)}`} target="_blank">
              <div className="insights-leaderboard__list-item">
                <AvatarMedia
                  className="insights-leaderboard__list-item--user"
                  firstName={avatar.firstName}
                  job={avatar.properties.job}
                  lastName={avatar.lastName}
                  noHash
                  profileImageCode={avatar.imageCode}
                  type="medium"
                  useLink={false}
                />
              </div>
            </a>
          )).slice(0, 5)}
          {data?.length > 5 &&
            <div className="insights-leaderboard__view-all" onClick={() => handleViewAll()}>
              {formatMessage(messages.ViewAll)}
            </div>
          }
          <MyTeamModal closeModal={closeModal} />
        </div>
      ) : (
        <>
          <h4 className="insights-leaderboard__title">{formatMessage(messages.YouAndYourColleagues)}</h4>
          <div className="insights-leaderboard__list">
            {data?.map((avatar, key) => (
              <a href={`${baseUrl}profile/${avatar?.properties?.uid}`} target="_blank">
                <div
                  className={cn('insights-leaderboard__list-item', {
                    'insights-leaderboard__list-item--main-user':
                      graph?.values[key]?.position === Number(indicator?.value),
                  })}
                >
                  <p className="insights-leaderboard__list-item--position">{graph?.values[key]?.position}</p>
                  <AvatarMedia
                    className="insights-leaderboard__list-item--user"
                    firstName={avatar.firstName}
                    job={avatar.properties.job}
                    lastName={avatar.lastName}
                    noHash
                    profileImageCode={avatar.imageCode}
                    type="medium"
                    useLink={false}
                  />
                </div>
              </a>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

CulturalActivity.defaultProps = {
  data: [],
};

CulturalActivity.propTypes = {
  data: PropTypes.object,
  graph: PropTypes.object,
  indicator: PropTypes.object,
};

export default connect(null, { openModal, closeModal })(injectIntl(CulturalActivity));
