import { buildClosePath } from '../helpers';

const culturalLevels = ({ hookModal, onClose }) => {
  const closePath = buildClosePath();

  hookModal.open({
    onClose: () => onClose(closePath),
  });
};

export default culturalLevels;
