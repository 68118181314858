import {
  GET_TRENDS,
  GET_TRENDS_SUCCESS,
  GET_TRENDS_FAIL,
} from './actionTypes';

const initialState = {
  result: [],
  fetching: false,
  error: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TRENDS:
      return {
        ...state,
        fetching: true,
      };
    case GET_TRENDS_SUCCESS:
      return {
        ...state,
        fetching: false,
        result: payload,
      };
    case GET_TRENDS_FAIL:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    default:
      return state;
  }
}
