// @packages
import React, { useState } from 'react';
import Container from 'smu-layout-components/Container';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { detectWebView } from 'smu-utils/device';
import { injectIntl } from 'react-intl';
import {
  selectError,
  selectRequesting,
  selectResultProp,
} from 'smu-utils/reduxRequests/selectors';

// @app
import BreadcrumbHeader from 'smu-ui-components/BreadcrumbHeader';
import QuestionnaireResults from 'containers/QuestionnaireResults';
import { QUESTIONNAIRE_RESULTS } from 'containers/QuestionnaireResults/constants';

// @own
import './styles.scss';
import messages from './messages';

function QuestionnaireResultsLayout({
  error,
  intl: { formatMessage },
  isLoading,
  params: { questionnaireId },
  status,
  title,
  totalQuestionsAnswered,
}) {
  const isWebView = detectWebView();
  const crumbs = [formatMessage(messages.cuestionnaireResultsHeaderPrevious)];
  const isOpenWithNoAnswers = !totalQuestionsAnswered && status === 'OPEN';
  const isClosedWithNoAnswers = !totalQuestionsAnswered && status === 'CLOSED';
  const isErrorLayout = error || isOpenWithNoAnswers || isClosedWithNoAnswers;

  if (title) {
    crumbs.push(title.toUpperCase());
  }
  const [exportingPDF, setExportingPDF] = useState(false);
  const [exportingCSV, setExportingCSV] = useState(false);

  return (
    <div
      className={cn('smu-questionnaire-results-layout', {
        'smu-questionnaire-results-layout--webview': isWebView,
        'smu-questionnaire-results-layout--error': isErrorLayout,
        'smu-questionnaire-results-layout--with-content': title && !isErrorLayout,
        'smu-questionnaire-results-layout--exporting': exportingPDF || exportingCSV,
      })}
    >
      {!isWebView && !isLoading && !exportingPDF && !exportingCSV && (
        <BreadcrumbHeader
          crumbs={crumbs}
        />
      )}
      <Container centered>
        {(exportingPDF || exportingCSV) && (
          <LottieSpinner
            className="smu-questionnaire-results-layout__loader-exporting"
            height={40}
            width={40}
          />
        )}
        <QuestionnaireResults
          className="smu-questionnaire-results-layout__results"
          enableExportPDF={!isWebView}
          exportingCSV={exportingCSV}
          exportingPDF={exportingPDF}
          onExportCSV={setExportingCSV}
          onExportPDF={setExportingPDF}
          questionnaireId={questionnaireId}
        />
      </Container>
      {isLoading && (
        <LottieSpinner
          className="smu-questionnaire-results-layout__loader"
          height={40}
          width={40}
        />
      )}
    </div>
  );
}

QuestionnaireResultsLayout.propTypes = {
  error: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  params: PropTypes.shape({
    questionnaireId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }).isRequired,
  status: PropTypes.string,
  title: PropTypes.string,
  totalQuestionsAnswered: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    error: selectError(state, QUESTIONNAIRE_RESULTS),
    isLoading: selectRequesting(state, QUESTIONNAIRE_RESULTS),
    status: selectResultProp(state, QUESTIONNAIRE_RESULTS, 'result.status'),
    title: selectResultProp(state, QUESTIONNAIRE_RESULTS, 'result.title'),
    totalQuestionsAnswered: selectResultProp(state, QUESTIONNAIRE_RESULTS, 'result.totalQuestionsAnswered'),
  };
}

export default connect(mapStateToProps)(injectIntl(QuestionnaireResultsLayout));
