import {
  APP_LOADER_ENABLED,
  APP_LOADER_FAIL,
  APP_LOADER_INIT,
  APP_LOADER_RESTART,
  APP_LOADER_SUCCESS,
} from './actionTypes';

export function appLoaderInit(payload) {
  return {
    type: APP_LOADER_INIT,
    payload,
  };
}

export function appLoaderFail({ error, errorType }) {
  return {
    type: APP_LOADER_FAIL,
    payload: {
      error,
      errorType,
    },
  };
}

export function appLoaderSuccess(payload) {
  return {
    type: APP_LOADER_SUCCESS,
    payload,
  };
}

export function appLoaderEnabled(payload) {
  return {
    type: APP_LOADER_ENABLED,
    payload,
  };
}

export function appLoaderRestart() {
  return {
    type: APP_LOADER_RESTART,
  };
}
