// @packages
import get from 'lodash/get';

// @own
import { isModerator } from 'utils/checkRights';

export const selectSessionStars = ({ session }) => get(session, 'stars');

export const selectSessionUser = ({ session }) => get(session, 'user');

export const selectSessionUserIdentification = ({ session }) =>
  session?.user?.identification;

export const selectSessionUserId = ({ session }) => get(session, 'user.id');

export const selectSessionUserFirstName = ({ session }) => get(session, 'user.firstName');

export const selectSessionUserUid = ({ session }) => get(session, 'user.uid');

export const selectSessionUserImageCode = ({ session }) => session?.user?.profileImageCode;

export const selectSessionUserOsFeed = ({ session }) =>
  get(session, 'user.osFeed');

export const selectSessionUserOsFeedEnabled = ({ session }) =>
  get(session, 'user.osFeed');

export const selectSessionOsFeedEnabled = ({ session }) =>
  get(session, 'communityConfigurations.osFeedEnabled');

export const selectSessionOsFeedFetching = ({ session }) =>
  get(session, 'communityConfigurations.osFeedFetching');

export const selectSessionUserRights = ({ session }) =>
  get(session, 'user.rights', []);

export const selectSession = ({ session }) => session;

export const selectCustomerModModule = (state) => {
  const modules = get(state, 'session.communityConfigurations.modules');
  const customerModule = modules.find(module => module.name === 'TM_CUSTOMER');
  return customerModule;
};

export const selectSessionConnectionWithJob = state =>
  get(state, 'session.communityConfigurations.newProfileConnectionWithJob');

export const selectHasNewProfileEnabled = ({ session }) =>
  get(session, 'communityConfigurations.newProfileEnabled');

export const selectHasWorldCupEnabled = ({ session }) =>
  get(session, 'communityConfigurations.worldCupCoreValueId');

export const selectHasMentionsEnabled = ({ session }) =>
  get(session, 'communityConfigurations.mentionsEnabled');

export const selectDefaultProfileImageCode = state =>
  get(state, 'session.communityConfigurations.defaultProfileImageCode');

export const selectIsCulturalIndicatorActive = state =>
  get(state, 'session.communityConfigurations.isCulturalIndicatorActive');

export const selectOrganizationId = ({ session }) =>
  get(session, 'communityConfigurations.organization.id');

export const selectAuthWidgetFail = state => get(state, 'session.authWidgetFail');

export const selectCommunityId = ({ session }) => get(session, 'communityConfigurations.organization.id');

export const selectUserRoles = ({ session }) => get(session, 'user.roles');

export const selectProfileUser = state => state?.upProfile?.result;

export const selectProfileUserId = state => state?.upProfile?.result?.id;

export const selectProfileFetching = state => state?.upProfile?.fetching;

export const selectOrganizationName = ({ session }) =>
  session?.communityConfigurations?.organization?.name;

export const selectOrganizationNegativeImageCode = ({ session }) =>
  session?.communityConfigurations.organization?.negativeImageCode;

export const selectOrganizationImageId = ({ session }) =>
  session?.communityConfigurations.organization?.imageId;

export const selectSwitchLogosEnabled = ({ session }) =>
  session?.communityConfigurations.switchLogosEnabled;

export const selectKudosEnabled = ({ session }) => session?.stars?.some((star) => star.isKudos);

export const selectLanguageCode = ({ session }) => session?.user?.language?.code;

export const selectCommunityName = ({ session }) => session?.user?.organizationName;

export const selectIsModerator = ({ session }) => isModerator(session);

export const getCommunityConfigurationValue = (state, value) =>
  state?.session?.communityConfigurations?.[value];
