import { get } from 'lodash';
import { navigateToLoginV2 } from 'smu-utils/globalRequestHelper';
import { UNAUTHORIZED_ERROR, NOT_FOUND_ERROR, NO_RIGHTS_ERROR} from './constants';

export function getStatusError(error) {
  const statusResponse = get(error, 'response.status');
  const statusRequest = get(error, 'request.status');
  return statusResponse || statusRequest;
}

export default function errorHandler(error) {
  if (getStatusError(error) === UNAUTHORIZED_ERROR) {
    navigateToLoginV2('SMU_WEB_v3');
  }
  throw new Error(error);
}

export function isNotFound(error) {
  return getStatusError(error) === NOT_FOUND_ERROR;
}

export function isNoRights(error) {
  return getStatusError(error) === NO_RIGHTS_ERROR;
}
