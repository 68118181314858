// @packages
import React, { useEffect } from 'react';
import Container from 'smu-layout-components/Container';
import PropTypes from 'prop-types';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { connect } from 'react-redux';
import { trackPageview } from 'smu-utils/gtm';
// @app
import CreateChillout from 'containers/CreateChillout';
import DiscoverFiltersContainer from 'containers/DiscoverFilters';
import DiscoverUsers from 'containers/DiscoverUsers';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import { ProvideDiscover } from 'hooks/useDiscover';
import { ENABLED_DISCOVERY_PEOPLE } from 'services/communityConfigs/constants';
import {
  getCommunityConfigFetching,
  getCommunityConfigValue,
} from 'services/communityConfigs/selectors';

// @own
import './styles.scss';

function DiscoverLayout({ enabledDiscoveryPeople, isLoading, router }) {
  const prevIsloading = usePrevious(isLoading);

  useEffect(() => {
    trackPageview({
      pageName: '/discover',
    });
  }, []);

  useEffect(() => {
    if (prevIsloading && !isLoading && !enabledDiscoveryPeople) {
      router.push('/home');
    }
  }, [prevIsloading, isLoading, enabledDiscoveryPeople]);

  return (
    <ProvideDiscover>
      <div className="discover-layout">
        <Container centered>
          {isLoading ? (
            <LottieSpinner
              className="discover-layout__loader"
              height={40}
              loadingType="starmeup"
              width={40}
            />
          ) : (
            enabledDiscoveryPeople && (
              <>
                <DiscoverFiltersContainer className="discover-layout__filters" />
                <DiscoverUsers className="discover-layout__users" />
                <CreateChillout />
              </>
            )
          )}
        </Container>
      </div>
    </ProvideDiscover>
  );
}

const mapStateToProps = (state) => ({
  enabledDiscoveryPeople: getCommunityConfigValue(state, ENABLED_DISCOVERY_PEOPLE) === 'true',
  isLoading: getCommunityConfigFetching(state),
});

DiscoverLayout.propTypes = {
  enabledDiscoveryPeople: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps)(DiscoverLayout);
