// @packages
import React from 'react';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';

// @app
import SkeletonCommentInput from 'components/Skeletons/CommentInput';
import SkeletonCommentList from 'components/Skeletons/CommentList';

// @own
import './styles.scss';

function SkeletonFeedItem({ showComments, className }) {
  return (
    <Panel
      className={cn('skeleton-feed-item rounded-lg border-none shadow-base', className, {
        'skeleton-feed-item--show-comments': showComments,
      })}
      noPadding
    >
      <Skeleton
        className="skeleton-feed-item__date"
        animation="wave"
        height={10}
        width={60}
      />
      <div className="skeleton-feed-item__body">
        <Skeleton
          className="skeleton-feed-item__avatar rounded-full"
          animation="wave"
          height={72}
          width={72}
        />
        <div className="skeleton-feed-item__details">
          <Skeleton animation="wave" height={10} width={128} />
          <div className="skeleton-feed-item__badge">
            <Skeleton
              animation="wave"
              height={34}
              variant="circle"
              width={34}
            />
            <Skeleton animation="wave" height={10} width={103} />
          </div>
          <div className="skeleton-feed-item__description">
            <Skeleton animation="wave" height={10} width="100%" />
            <Skeleton animation="wave" height={10} width="97%" />
          </div>
        </div>
      </div>
      <footer className="skeleton-feed-item__footer">
        <div className="skeleton-feed-item__avatars mr-2">
          <Skeleton animation="wave" height={32} variant="circle" width={32} />
        </div>
        <Skeleton animation="wave" height={10} width={130} />
        <div className="skeleton-feed-item__actions">
          <Skeleton animation="wave" height={18} width={20} />
          <Skeleton animation="wave" height={18} width={20} />
        </div>
      </footer>
      {showComments && (
        <div className="skeleton-feed-item__comments">
          <SkeletonCommentInput />
          <SkeletonCommentList total={2} />
        </div>
      )}
    </Panel>
  );
}

SkeletonFeedItem.propTypes = {
  showComments: PropTypes.bool,
};

SkeletonFeedItem.defaultProps = {
  showComments: false,
};

export default SkeletonFeedItem;
