// @packages
import React, { useEffect } from 'react';
import Maintenance from 'smu-ui-components/Maintenance';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';

// @app
import useOnline from 'hooks/useOnline';
import { appLoaderRestart } from 'services/appLoader/actions';
import { formatBoldText } from 'utils/boldText';
import {
  selectorAppLoaderErrorCode,
  selectorAppLoaderErrorType,
  selectorAppLoaderHasError,
} from 'services/appLoader/selectors';

// @own
import './styles.scss';
import { ERROR_SUBTITLE, ERROR_TITLE, ERROR_TRY_AGAIN } from './constants';

function AppMaintenance() {
  const dispatch = useDispatch();
  const online = useOnline();
  const prevOnline = usePrevious(online);
  const maintenanceEnabled = useSelector(selectorAppLoaderHasError);
  const errorType = useSelector(selectorAppLoaderErrorType);
  const errorCode = useSelector(selectorAppLoaderErrorCode);
  const handleRestart = () => dispatch(appLoaderRestart());

  useEffect(() => {
    if (maintenanceEnabled && prevOnline === false && online) {
      handleRestart();
    }
  }, [maintenanceEnabled, online]);

  useEffect(() => {
    let timer;
    if (online && maintenanceEnabled) {
      timer = setTimeout(() => {
        handleRestart();
      }, 20000);
    }
    return () => timer && clearTimeout(timer);
  }, [maintenanceEnabled, online]);

  return maintenanceEnabled ? (
    <Maintenance
      appLogo={online ? '/assets/img/logo.svg' : ''}
      className="app-maintenance"
      errorCode={errorCode}
      imageSrc={online ? '/assets/img/astronaut-error.png' : ''}
      onClick={handleRestart}
      showButton
      texts={{
        errorCode: errorType,
        goBack: ERROR_TRY_AGAIN,
        subtitle: formatBoldText(ERROR_SUBTITLE),
        title: ERROR_TITLE,
      }}
    />
  ) : null;
}

export default AppMaintenance;
