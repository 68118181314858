import { defineMessages } from 'react-intl';

export default defineMessages({
  uploadProfileImageCropperCancel: {
    defaultMessage: 'Cancel',
    id: 'uploadProfileImage.CropperCancel',
  },
  uploadProfileImageCropperSave: {
    defaultMessage: 'Save',
    id: 'uploadProfileImage.CropperSave',
  },
  uploadProfileImageCropperTitle: {
    defaultMessage: 'Upload Profile Photo',
    id: 'uploadProfileImage.CropperTitle',
  },
  uploadProfileImageDiscardChanges: {
    defaultMessage: 'Discard Changes',
    id: 'uploadProfileImage.DiscardChanges',
  },
  uploadProfileImageDiscardChangesMessage: {
    defaultMessage: 'Are you sure you want to discard your changes?',
    id: 'uploadProfileImage.DiscardChangesMessage',
  },
  uploadProfileImageNo: {
    defaultMessage: 'No',
    id: 'uploadProfileImage.No',
  },
  uploadProfileImageYes: {
    defaultMessage: 'Yes',
    id: 'uploadProfileImage.Yes',
  },
  uploadProfileImageError: {
    id: 'uploadProfileImage.Error',
    defaultMessage: 'Something went wrong. Try again in a few minutes',
  },
  uploadProfileImageSuccess: {
    id: 'uploadProfileImage.Success',
    defaultMessage: 'Your Profile picture was successfully updated',
  },
  uploadProfileImageLoading: {
    id: 'uploadProfileImage.Loading',
    defaultMessage: 'Uploading profile image',
  },
});
