// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Container from 'smu-layout-components/Container';
import { injectIntl } from 'react-intl';
import BreadcrumbHeader from 'smu-ui-components/BreadcrumbHeader';

// @app
import WithCongratulationsFeed from 'containers/WithCongratulationsFeed';

// @own
import './styles.scss';
import messages from './messages';

function CongratulationFeedLayout({
  intl: { formatMessage },
  params: { userId },
}) {
  return (
    <div className="congratulation-feed-layout">
      <BreadcrumbHeader
        className="congratulation-feed-layout__header"
        crumbs={[
          formatMessage(messages.congratulationFeedHeaderPrevious),
          formatMessage(messages.congratulationFeedHeaderCurrent),
        ]}
      />
      <Container centered compact>
        <WithCongratulationsFeed
          className="congratulation-feed-layout__feed"
          uuid={userId}
        />
      </Container>
    </div>
  );
}

CongratulationFeedLayout.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  params: PropTypes.shape({
    userId: PropTypes.string.isRequired,
  }),
};

export default injectIntl(CongratulationFeedLayout);
