// @packages
import React from 'react';
import Avatar from 'smu-ui-components/Avatar';
import Button from 'smu-ui-components/ButtonV2';
import Divider from 'smu-ui-components/Divider';
import Icon from 'smu-ui-components/IconV2';
import IconButton from 'smu-ui-components/IconButton';
import InfiniteScroll from 'smu-ui-components/InfiniteScrollV2';
import Link from 'smu-ui-components/Link';
import ModalUI from 'smu-ui-components/Modal';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import { buildImageUrl } from 'smu-utils/image';
import { useGroupCardDetail } from 'smu-services/os-member/api/v1/community/groups/feed/[id]/get';

// @own
import Skeleton from './Skeleton';

const Modal = ({ badge, cardId, isOpen, onButtonClick, onClose, ownId, texts }) => {
  const getKey = page => `/api/v1/community/member/groups/feed/${cardId}?pageNumber=${page || 0}`;
  const { data, isLoading, setSize, size } = useGroupCardDetail({ badgeId: badge?.id, getKey });
  const users = data?.reduce((acc, crr) => [...acc, ...crr?.users], []) || [];
  const totalUsers = data?.[0]?.pagination?.total;

  return (
    <ModalUI open={isOpen} onClose={onClose}>
      <Paper className="w-full max-w-144 relative">
        <IconButton
          className="absolute right-3 top-3 z-10 p-1 text-gray-dune"
          icon="linear-close"
          onClick={onClose}
        />
        <div className="flex items-center p-6 space-x-2">
          <img
            alt={badge.name}
            className="h-8 w-8"
            legacy={false}
            src={buildImageUrl({ code: badge.imageCode, height: 32, width: 32 })}
          />
          <Typography className="font-rubik font-semibold text-base text-gray-dune">
            {badge.name}
          </Typography>
        </div>
        <Divider legacy={false} />
        <div className="p-3 space-y-4 overflow-auto" id="badgeCardFeedDetail">
          <InfiniteScroll
            className="h-full space-y-4 p-3"
            dataLength={users?.length}
            hasMore={users?.length < totalUsers}
            height={512}
            next={() => setSize(size + 1)}
            scrollableTarget="badgeCardFeedDetail"
            style={{ height: '512px' }}
          >
            {users.map(user => (
              <div className="flex justify-between items-center" key={user.id}>
                <div className="flex items-center space-x-2">
                  <Avatar
                    component={Link}
                    height={40}
                    href={`/profile/${user.uid}`}
                    legacy={false}
                    src={buildImageUrl({
                      code: user.profileImageCode,
                      height: 40,
                      width: 40,
                    })}
                    width={40}
                  />
                  <Typography className="font-rubik font-semibold text-sm text-blue">
                    {`${user.firstName} ${user.lastName}`}
                  </Typography>
                </div>
                <Button
                  color="unifyBlue"
                  disabled={ownId === user.id}
                  enabledUnification
                  endIcon={<Icon icon="sent" size="xsmall"/>}
                  onClick={() => onButtonClick(user)}
                  size="medium"
                >
                  {texts.button}
                </Button>
              </div>
            ))}
            {isLoading && <Skeleton />}
          </InfiniteScroll>
        </div>
      </Paper>
    </ModalUI>
  );
};

Modal.defaultProps = {
  badge: {},
  texts: {},
};

Modal.propTypes = {
  badge: PropTypes.shape({
    id: PropTypes.number,
    imageCode: PropTypes.string,
    name: PropTypes.string,
  }),
  cardId: PropTypes.string,
  isOpen: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  ownId: PropTypes.number,
  texts: PropTypes.shape({
    button: PropTypes.string,
  }),
};

export default Modal;
