import { pick, cloneDeep, isEmpty } from 'lodash';

import {
  INITIALIZE_FEED,
  REBOOT_FEED,
  STARS_UPDATE,
  FEED_APPLY_FILTERS,
} from './actionTypes';

const initialState = {
  activeReport: 'recent',
  fields: null,
  availableFilters: null,
  filtersShown: false,
  filtersApplied: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case INITIALIZE_FEED:
      return {
        ...initialState,
        availableFilters: cloneDeep(payload.availableFilters) || [],
        fields: cloneDeep(payload.fields) || {},
      };
    case REBOOT_FEED:
      return initialState;
    case STARS_UPDATE:
      return {
        ...state,
        fields: payload.fields,
        filtersApplied: payload.initialApplyFilters
          ? !isEmpty(payload.fields)
          : state.filtersApplied,
      };
    case FEED_APPLY_FILTERS:
      return {
        ...state,
        ...cloneDeep(pick(payload, ['filtersShown', 'filtersApplied', 'fields', 'activeReport'])),
      };
    default:
      return state;
  }
}
