// @packages
import React from 'react';
import Skeleton from 'smu-ui-components/Skeleton';

const ModalSkeleton = () => (
  [1, 2, 3, 4, 5, 6, 7, 8].map(item => (
    <div className="flex justify-between items-center" key={item}>
      <div className="flex items-center space-x-2">
        <Skeleton height={40} variant="circle" width={40} />
        <Skeleton height={18} width={140} />
      </div>
      <Skeleton height={32} width={140} />
    </div>
  ))
);

export default ModalSkeleton;
