// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// @app
import ActionLikeContainer from 'containers/Cards/components/ActionLike';
import useRequest from 'hooks/useRequest';
import { actionItemsUpdate } from 'services/items/actions';
import { apiAddLike, apiRemoveLike } from 'services/stars/api';
import { selectSessionUserId } from 'containers/Authorization/selectors';

const StarActionLikeContainer = ({
  comments,
  onClickCount,
  onLike,
  onUnlike,
  starId,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    liked: false,
    likesCount: 0,
  });
  const ownId = useSelector(selectSessionUserId);
  const likes = comments?.filter((c) => c?.type === 'LIKE');
  const hasOwnLike = comments?.some(
    (c) => c?.user?.id === ownId && c?.type === 'LIKE',
  );

  const addLike = useRequest({
    api: apiAddLike,
    id: `ADD_LIKE_STAR_${starId}`,
  });

  const removeLike = useRequest({
    api: apiRemoveLike,
    id: `REMOVE_LIKE_STAR_${starId}`,
  });

  const isRemoving = removeLike.isRequesting;
  const isAdding = addLike.isRequesting;
  const isLoading = isRemoving || isAdding;
  const likesCount = likes?.length;
  const enabledClickCount = likesCount > 0 && !isLoading;

  const handleAddLike = () => {
    setState({ liked: true, likesCount: likesCount + 1 });

    addLike.fetch({
      params: { starId },
      onError: () => setState({ liked: false, likesCount }),
      onSuccess: ({ result }) => {
        dispatch(
          actionItemsUpdate(starId, { comments: [...comments, result] }),
        );

        if (onLike) onLike();
      },
    });
  };

  const handleRemoveLike = () => {
    const like = comments?.find(
      (c) => c.user.id === ownId && c.type === 'LIKE',
    );
    const likeId = like?.id;

    if (likeId) {
      setState({ liked: false, likesCount: likesCount - 1 });
      removeLike.fetch({
        params: { likeId },
        onError: () => setState({ liked: true, likesCount }),
        onSuccess: () => {
          dispatch(
            actionItemsUpdate(starId, {
              comments: comments.filter((c) => c.id !== likeId),
            }),
          );

          if (onUnlike) onUnlike();
        },
      });
    }
  };

  const handleLike = () => {
    if (isLoading) {
      return;
    }

    if (hasOwnLike) {
      handleRemoveLike();
    } else {
      handleAddLike();
    }
  };

  useEffect(() => {
    if (likesCount !== state?.likesCount) {
      setState({
        liked: hasOwnLike,
        likesCount,
      });
    }
  }, [likesCount]);

  return (
    <ActionLikeContainer
      active={state?.liked}
      count={state?.likesCount}
      onClickCount={enabledClickCount ? onClickCount : undefined}
      onClickIcon={handleLike}
      {...rest}
    />
  );
};

StarActionLikeContainer.propTypes = {
  comments: PropTypes.array,
  count: PropTypes.number,
  onLike: PropTypes.func,
  onUnlike: PropTypes.func,
  starId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default StarActionLikeContainer;
