// @packages
import React, { useEffect } from 'react';
import CollaboratorList from 'smu-app-components/MyTeam/Dashboard/CollaboratorsList';
import Dashboard from 'smu-layout-components/Dashboard';
import DashboardItem from 'smu-layout-components/DashboardItem';
import EngagementDetectorOverall from 'smu-app-components/MyTeam/Dashboard/EngagementDetectorOverall';
import EvaluationStatus from 'smu-app-components/MyTeam/Dashboard/EvaluationStatus';
import GoalStatus from 'smu-app-components/MyTeam/Dashboard/GoalStatus';
import Insights from 'smu-app-components/MyTeam/Dashboard/Insights';
import PendingTasks from 'smu-app-components/MyTeam/Dashboard/PendingTasks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import { useCommunity } from 'smu-services/betterme/api/community';
import { withRouter } from 'react-router';

// @app
import {
  ENABLED_BETTERME,
  ENABLED_DISENGAGEMENT_INDICATOR,
  ENABLED_MYTEAM_DASHBOARD,
  ENABLED_MYTEAM_INSIGHTS,
  ENABLED_PENDING_TASKS,
  ENABLED_YIR,
} from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import {
  selectCommunityId,
  selectSessionUserFirstName,
  selectSessionUserId,
} from 'containers/Authorization/selectors';

function MyTeamDashboard({
  className,
  communityId,
  enabledBetterme,
  enabledDisengagementIndicator,
  enabledMyTeamDashboard,
  enabledMyTeamInsights,
  enabledPendingTasks,
  enabledYIR,
  router,
  userFirstName,
  userId,
}) {
  const { data } = useCommunity({ shouldFetch: enabledBetterme, communityId });
  const enabledEvaluations = data?.result?.configurations?.allowsEvaluations;
  const enabledLeadershipMentor =
    data?.result?.configurations?.allowsGlbFeatures;
  const enabledGoals = data?.result?.configurations?.allowsGoals;
  const allowsAddToMyTeam = data?.result?.configurations?.allowsAddToMyTeam;
  const dateFrom =
    data?.result?.evaluationPeriod?.startTime ||
    data?.result?.defaultPeriod?.startTime;
  const dateTo =
    data?.result?.evaluationPeriod?.endTime ||
    data?.result?.defaultPeriod?.endTime;
  const enablersLength = [
    enabledPendingTasks,
    enabledDisengagementIndicator,
    enabledBetterme && enabledGoals,
    enabledBetterme && enabledEvaluations,
  ].filter((c) => c).length;
  const multipleEnablers = enablersLength > 1;
  const columns = multipleEnablers ? 4 : 3;
  const insightsSpan = multipleEnablers ? 4 : 2;
  const slidesPerView = multipleEnablers ? 3 : 2;

  const buildUserProfileUrl = (user) => {
    if (enabledBetterme) {
      return `${getWebUrl('betterme')}/profile/${window.btoa(
        user.identification,
      )}/dashboard`;
    }
    return `/profile/${user.uid}/dashboard`;
  };

  useEffect(() => {
    if (!enabledMyTeamDashboard) {
      if (enabledBetterme) {
        window.location.href = `${getWebUrl('betterme')}/myteam/dashboard`;
      } else {
        router.push('/home');
      }
    }
  }, [enabledMyTeamDashboard, enabledBetterme]);

  if (!enabledMyTeamDashboard) {
    return null;
  }

  return (
    <Dashboard className={className} columns={columns} spacing={5}>
      {enabledMyTeamInsights && (
        <DashboardItem span={insightsSpan}>
          <Insights
            enabledYIR={enabledYIR}
            swiperProps={{
              slidesPerGroup: 1,
              slidesPerView,
            }}
            username={userFirstName}
          />
        </DashboardItem>
      )}
      {enabledPendingTasks && (
        <DashboardItem>
          <PendingTasks />
        </DashboardItem>
      )}
      {enabledDisengagementIndicator && (
        <DashboardItem>
          <EngagementDetectorOverall />
        </DashboardItem>
      )}
      {enabledBetterme && enabledGoals && (
        <DashboardItem>
          <GoalStatus
            dateFrom={dateFrom}
            dateTo={dateTo}
            enableEmptyState
            showEmptyImage={false}
            userId={userId}
          />
        </DashboardItem>
      )}
      {enabledBetterme && enabledEvaluations && (
        <DashboardItem>
          <EvaluationStatus enableEmptyState showEmptyImage={false}/>
        </DashboardItem>
      )}
      <DashboardItem span={4}>
        <CollaboratorList
          buildUserProfileUrl={buildUserProfileUrl}
          enabledLeadershipMentor={enabledLeadershipMentor}
          showAddPeopleToMyTeam={enabledBetterme && allowsAddToMyTeam}
          showLeadershipRequests={enabledBetterme}
          showTitle
        />
      </DashboardItem>
    </Dashboard>
  );
}

MyTeamDashboard.propTypes = {
  communityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enabledBetterme: PropTypes.bool,
  enabledDisengagementIndicator: PropTypes.bool,
  enabledMyTeamDashboard: PropTypes.bool,
  enabledMyTeamInsights: PropTypes.bool,
  enabledPendingTasks: PropTypes.bool,
  enabledYIR: PropTypes.bool,
  intl: PropTypes.object,
  router: PropTypes.object,
  userFirstName: PropTypes.string,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  enabledBetterme: getCommunityConfigValueBool(state, ENABLED_BETTERME),
  enabledDisengagementIndicator: getCommunityConfigValueBool(
    state,
    ENABLED_DISENGAGEMENT_INDICATOR,
  ),
  enabledMyTeamDashboard: getCommunityConfigValueBool(
    state,
    ENABLED_MYTEAM_DASHBOARD,
  ),
  enabledMyTeamInsights: getCommunityConfigValueBool(
    state,
    ENABLED_MYTEAM_INSIGHTS,
  ),
  enabledPendingTasks: getCommunityConfigValueBool(
    state,
    ENABLED_PENDING_TASKS,
  ),
  enabledYIR: getCommunityConfigValueBool(state, ENABLED_YIR),
  userFirstName: selectSessionUserFirstName(state),
  userId: selectSessionUserId(state),
});

export default connect(mapStateToProps)(withRouter(MyTeamDashboard));
