// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import UserAvatarList from 'smu-ui-components/UserAvatarList';
import useModal from 'smu-custom-hooks/useModal';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @app
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';

// @own
import Header from '../Header';
import Modal from './Modal';
import { getUserAvatarListProps } from './helpers';

const Group = ({
  badge,
  cardId,
  ownId,
  router,
  texts,
  totalUsers,
  users,
}) => {
  const modal = useModal();

  const handleModalOpen = () => {
    modal.open();

    trackEvent({
      action: 'card_click',
      badge: badge.name,
      category: 'badge',
      label: 'view_full_list',
      type: 'group',
    });
  };

  const handleModalClose = () => {
    modal.close();
  };

  const handleModalButtonClick = user => {
    modal.close();

    trackEvent({
      action: 'card_click',
      badge: badge.name,
      category: 'badge',
      label: 'send_star',
      type: 'group',
    });

    router.push(buildSendStarModalUrl({
      params: { uid: user?.uid },
    }));
  };

  return (
    <Paper className="overflow-hidden" shadow>
      <Header imageCode={badge.imageCode} name={badge.name} />
      <div className="flex flex-col items-center space-y-4 px-4 py-6">
        <UserAvatarList
          {...getUserAvatarListProps({ ownId, texts, users })}
          legacy={false}
          onClickMore={handleModalOpen}
          total={totalUsers}
        />
        <div className="flex flex-col text-center">
          <Typography className="font-rubik font-semibold text-sm text-blue">
            {texts.title}
          </Typography>
          <Typography className="font-openSans text-sm text-gray-dune">
            {texts.subtitle}
          </Typography>
        </div>
        <Button
          color="unifyBlue"
          enabledUnification
          onClick={handleModalOpen}
          size="medium"
          variant="outline"
        >
          {texts.button}
        </Button>
      </div>
      <Modal
        badge={badge}
        cardId={cardId}
        isOpen={modal.isOpen}
        onButtonClick={handleModalButtonClick}
        onClose={handleModalClose}
        ownId={ownId}
        texts={{
          button: texts.modalButton,
        }}
      />
    </Paper>
  );
};

Group.defaultProps = {
  badge: {},
  texts: {},
  users: [],
};

Group.propTypes = {
  badge: PropTypes.shape({
    imageCode: PropTypes.string,
    name: PropTypes.string,
  }),
  cardId: PropTypes.number,
  ownId: PropTypes.string,
  router: PropTypes.object,
  texts: PropTypes.shape({
    button: PropTypes.string,
    modalButton: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    userPopupButton: PropTypes.string,
  }),
  totalUsers: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.shape({
    fullname: PropTypes.string,
    id: PropTypes.number,
    job: PropTypes.string,
    profileImageCode: PropTypes.string,
    uid: PropTypes.string,
  })),
};

export default withRouter(Group);
