// @app
import { paths } from 'utils/routes';

// @own
import ShareLayout from './ShareLayout';

export default () => ({
  component: ShareLayout,
  onEnter: () => {
    window.scrollTo(0, 0);
  },
  path: paths.share(),
});
