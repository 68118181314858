import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'widgetError.title',
    defaultMessage: 'An Error has occurred',
  },
  subtitle: {
    id: 'widgetError.subtitle',
    defaultMessage: 'Please try later or email us at support@starmeup.com',
  },
  goBack: {
    id: 'widgetError.goBack',
    defaultMessage: 'Retry',
  },
});
