// @packages
import { defineMessages } from 'react-intl';

export default defineMessages({
  sortOptions: {
    ALL: {
      modalHeader: {
        id: 'feedSortButton.sortOptions.ALL.modalHeader',
        defaultMessage: 'The feed is set to show unfiltered content from all the organization.',
      },
      modalToggle: {
        id: 'feedSortButton.sortOptions.ALL.modalToggle',
        defaultMessage: 'Switch to smart feed to show the content that is most relevant to you.',
      },
      popupContent: {
        id: 'feedSortButton.sortOptions.ALL.popupContent',
        defaultMessage: 'Switch to relevant content',
      },
    },
    SMART: {
      modalHeader: {
        id: 'feedSortButton.sortOptions.SMART.modalHeader',
        defaultMessage: 'The smart feed selects the content that is most relevant for you.',
      },
      modalToggle: {
        id: 'feedSortButton.sortOptions.SMART.modalToggle',
        defaultMessage: 'Switch to all activity to show unfiltered content from all the organization.',
      },
      popupContent: {
        id: 'feedSortButton.sortOptions.SMART.popupContent',
        defaultMessage: 'Switch to all content',
      },
    },
  },
});
