// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';

// @app
import AppMaintenance from 'containers/AppMaintenance';
import storeRef from 'utils/store';
import useFullScreen from 'hooks/useFullScreen';
import { ProvidePushNotifications } from 'hooks/usePushNotifications';
import { trackPageView } from 'utils/gtm';

// @own
import Router from './Router';

const Root = ({ store, history }) => {
  useFullScreen();
  storeRef(store);
  browserHistory.listen((location) => {
    trackPageView({
      path: location.pathname,
    });
  });

  return (
    <Provider store={store}>
      <ProvidePushNotifications>
        <AppMaintenance />
        <Router history={history} store={store} />
      </ProvidePushNotifications>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Root;
