// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { injectIntl } from 'react-intl';

// @app
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';
import { optionsConfig } from './constants';
import messages from '../messages';

function GraphicBar({
  eventData,
  graph,
  intl: { formatMessage },
  isWebView,
  type,
}) {
  const isFeedbackNotSent = type === 'FEEDBACK_NOT_SENT_TO_YOUR_LED';
  const yAxisMax = graph?.values[0]?.value + graph?.values[1]?.value;

  function getLowActivitySeries() {
    const series = graph?.values;
    const colors = ['#c2c7ce', '#0ba8a8'];
    const message = [
      formatMessage(messages.CollaboratorsWithoutActivity),
      formatMessage(messages.CollaboratorsWithActivity),
    ];
    const values = [100 - graph?.values[0]?.value, graph?.values[0]?.value];
    const newSeries = series?.map((s, key) => ({
      color: colors[key],
      data: [
        values[key],
      ],
      name: message[key],
    }));
    return newSeries;
  }

  function getFeedbackNotSentSeries() {
    const series = graph?.values;
    const colors = ['#0ba8a8', '#c2c7ce'];
    const message = [
      formatMessage(messages.CollaboratorsWithFeedback),
      formatMessage(messages.CollaboratorsWithoutFeedback),
    ];
    const values = [graph?.values[0]?.value, graph?.values[1]?.value];
    const mapSeries = series?.map((s, key) => ({
      color: colors[key],
      data: [
        values[key],
      ],
      name: message[key],
    }));
    const newSeries = mapSeries.reverse();
    return newSeries;
  }

  const config = {
    ...optionsConfig,
    chart: {
      ...optionsConfig.chart,
      type: 'bar',
      width: isWebView ? 300 : 400,
    },
    yAxis: {
      ...optionsConfig.yAxis,
      labels: {
        enabled: true,
        format: isFeedbackNotSent ? '{value}' : '{value}%',
      },
      plotLines: [{
        color: '#f337c6',
        label: {
          color: '#f337c6',
          y: -2,
          rotation: 0,
          align: 'center',
          formatter() {
            return `<div style="color: #f337c6">${formatMessage(messages.recommended)}</div>`;
          },
          useHTML: true,
        },
        value: graph?.recommended,
        width: 2,
        zIndex: 10,
      }],
      max: isFeedbackNotSent ? yAxisMax : 100,
      tickInterval: isFeedbackNotSent ? 1 : 25,
    },
    xAxis: {
      ...optionsConfig.xAxis,
    },
    series: isFeedbackNotSent ? getFeedbackNotSentSeries() : getLowActivitySeries(),
    plotOptions: {
      ...optionsConfig.plotOptions,
       series: {
        ...optionsConfig.plotOptions.series,
        cursor: 'pointer',
        stacking: 'normal',
        dataLabels: {
            enabled: false,
        },
      },
    },
    tooltip: {
      enabled: true,
      formatter() {
        return `${Math.round(this.y)}`;
      },
      outside: true,
    },
  };

  return (
    <div
      className="insights-content__graph"
      onClick={() => trackEvent({
        action: `Interaction_${type}`,
        category: 'Insights',
        ...eventData,
      })}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={config}
      />
    </div>
  );
}

GraphicBar.propTypes = {
  graph: PropTypes.object,
};

export default injectIntl(GraphicBar);
