// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { apiCategoryValues } from './api';

import { REQUEST_CATEGORY_VALUES } from './actionTypes';

import {
  requestCategoryValuesFail,
  requestCategoryValuesSuccess,
} from './actions';

function* categoryValuesWorker({ payload }) {
  try {
    const { data } = yield call(apiCategoryValues, payload);
    yield put(
      requestCategoryValuesSuccess({ id: payload?.id, result: data?.result }),
    );
  } catch (error) {
    yield put(requestCategoryValuesFail({ error }));
  }
}

export function* categoryValuesWatcher() {
  yield takeEvery(REQUEST_CATEGORY_VALUES, categoryValuesWorker);
}

export default {
  categoryValuesWatcher,
};
