// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CongratulationsFeed from 'smu-widgets/Congratulations/Feed';

// @app
import CongratulationEvent from 'components/OS/Event/CongratulationEvent';
import InteractionModal from 'components/InteractionModal';
import SkeletonOSFeed from 'components/Skeletons/OSFeed';
import { errorHandler } from 'utils/errorHandler';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { selectSessionUserUid } from 'containers/Authorization/selectors';
import {
  ENABLED_ANNIVERSARIES,
  ENABLED_BIRTHDAYS,
} from 'services/communityConfigs/constants';

// @own
import './styles.scss';

const WithCongratulationsFeed = ({
  backTo,
  className,
  enabledAnniversaries,
  enabledBirthdays,
  sessionUserUid,
  uuid,
}) => {
  const isOwner = uuid === sessionUserUid;

  return uuid && (
    <div className="with-congratulations-feed">
      <CongratulationsFeed
        className={className}
        components={{
          INTERACTION_BIRTHDAY: (initialData) => (
            enabledBirthdays
              ? <CongratulationEvent
                backTo={backTo}
                initialData={initialData}
                interactionId={initialData?.id}
                showButton={!isOwner}
              />
              : null
        ),
          INTERACTION_ANNIVERSARY: (initialData) => (
            enabledAnniversaries
              ? <CongratulationEvent
                backTo={backTo}
                initialData={initialData}
                interactionId={initialData?.id}
                showButton={!isOwner}
              />
              : null
          ),
        }}
        enabledAnniversaries={enabledAnniversaries}
        enabledBirthdays={enabledBirthdays}
        errorHandler={errorHandler}
        infiniteScrollProps={{ loader: null }}
        isOwner={isOwner}
        loader={<SkeletonOSFeed items={4} />}
        uuid={uuid}
      />
      <InteractionModal classNames="fullscreen-modal--custom-interacion" />
    </div>
  );
};

WithCongratulationsFeed.defaultProps = {
  cardProps: {},
};

WithCongratulationsFeed.propTypes = {
  cardProps: PropTypes.object,
  className: PropTypes.string,
  enabledAnniversaries: PropTypes.bool,
  enabledBirthdays: PropTypes.bool,
  sessionUserUid: PropTypes.string,
  uuid: PropTypes.string,
};

const mapStateToProps = (state) => ({
  enabledAnniversaries: getCommunityConfigValueBool(state, ENABLED_ANNIVERSARIES),
  enabledBirthdays: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
  sessionUserUid: selectSessionUserUid(state),
});

export default connect(mapStateToProps)(WithCongratulationsFeed);
