// @packages
import React, { useState, useRef } from 'react';
import { EditorState } from 'draft-js';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'smu-ui-components/Icon';

// @app
import RichInputText from 'containers/RichInputText';
import Emojis from 'components/Emojis';

// @own
import './styles.scss';

const Input = ({
  bethereInteractions,
  emojisStyle,
  handleIconClick,
  maxCharacters,
  mediaUploadEnabled,
  mentionsEnabled,
  onInputChange,
  onKeyPress,
  onUploadImage,
  placeholder,
}) => {
  const validImageFormat = ['image/png', 'image/jpg', 'image/jpeg'];
  const [isAvailable, setIsAvailable] = useState(false);
  const [emojisVisible, setEmojisVisible] = useState(false);
  const [inputText, setInputText] = useState('');
  const [emoji, setEmoji] = useState('');
  const inputContainer = useRef();

  const editorState = EditorState.createEmpty();

  const handleAvailable = () => setIsAvailable(true);
  const handleUnavailable = () => setIsAvailable(false);

  const handleEmojiClick = (val) => {
    if (val.native) {
      setEmoji(val.native);
    }
  };

  const defaultHandleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleIconClick();
    }
  };

  const handleUploadImage = (event) => {
    const imageFile = event.target.files[0];

    if (validImageFormat.includes(imageFile.type)) {
      onUploadImage(imageFile);
    }
  };

  const handleInputChange = ({ comment, mentions }) => {
    if (comment !== inputText) {
      setInputText(comment);
    }

    onInputChange({ comment, mentions });
  };

  const mergeEmojisStyle = {
    position: 'absolute',
    right: '28px',
    top: '-292px',
    transform: 'scale(0.7)',
    width: '280px',
    zIndex: 10,
    ...emojisStyle,
  };

  return (
    <div className="input">
      {emojisVisible && (
        <Emojis
          handleClick={handleEmojiClick}
          onClickOutside={() => setEmojisVisible(!emojisVisible)}
          style={mergeEmojisStyle}
        />
      )}
      <div ref={inputContainer} className="input__body">
        <RichInputText
          bethereInteractions={bethereInteractions}
          className="input__body-field"
          editorState={editorState}
          emoji={emoji}
          maxCharacters={maxCharacters}
          mentionsEnabled={mentionsEnabled}
          onAvailable={handleAvailable}
          onInputChange={handleInputChange}
          onKeyPress={onKeyPress || defaultHandleKeyPress}
          onUnavailable={handleUnavailable}
          onUploadImage={onUploadImage}
          placeholder={placeholder}
          setEmoji={setEmoji}
          usePortal
        />
        <div className="input__icons">
          {mediaUploadEnabled && (
            <div className="input__icons-icon">
              <Icon
                className="input__icons-icon-image"
                color="grey"
                icon="image"
                size="micro"
              />
              <input
                accept={validImageFormat.join(',')}
                className="input__upload"
                onChange={handleUploadImage}
                type="file"
              />
            </div>
          )}
          <div className="input__icons-icon">
            <Icon
              icon="emoji"
              size="micro"
              color="grey"
              onClick={() => setEmojisVisible(!emojisVisible)}
            />
          </div>
        </div>
      </div>
      <Icon
        className={cn('input__submit-btn', {
          'input__submit-btn--active': isAvailable,
        })}
        color="white"
        icon="send-message"
        onClick={handleIconClick}
      />
    </div>
  );
};

Input.propTypes = {
  bethereInteractions: PropTypes.bool,
  emojisStyle: PropTypes.object,
  handleIconClick: PropTypes.func,
  isWidget: PropTypes.bool,
  maxCharacters: PropTypes.number,
  mediaUploadEnabled: PropTypes.bool,
  mentionsEnabled: PropTypes.bool,
  onInputChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onUploadImage: PropTypes.func,
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  emojisStyle: {},
  handleIconClick: () => {},
  maxCharacters: 400,
  mediaUploadEnabled: true,
  mentionsEnabled: false,
  onInputChange: () => {},
  onUploadImage: () => {},
  placeholder: 'Write a message...',
};

export default Input;
