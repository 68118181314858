import { getShareId } from './helpers';

export const selectUploadShareImage = (state, id) =>
  state?.uploadShareImage?.[getShareId(id)];
export const selectUploadShareImageError = (state, id) =>
  state?.uploadShareImage?.[getShareId(id)]?.error;
export const selectUploadShareImageRequesting = (state, id) =>
  state?.uploadShareImage?.[getShareId(id)]?.requesting;
export const selectUploadShareImageResult = (state, id) =>
  state?.uploadShareImage?.[getShareId(id)]?.result;
