// @packages
import { defineMessages } from 'react-intl';

export default defineMessages({
  button: {
    id: 'Badges.Congratulations.Button',
    defaultMessage: 'Check it out!',
  },
  description: {
    id: 'Badges.Congratulations.YouEarnedTheBadge',
    defaultMessage: 'You earned the badge:',
  },
  title: {
    id: 'Badges.Congratulations.Title',
    defaultMessage: 'Congratulations!',
  },
});
