import orderBy from 'lodash/orderBy';
import times from 'lodash/times';

export function remainderDistribution(values = []) {
  const roundedDown = values?.map((value) => Math.floor(value));
  const sum = roundedDown?.reduce((acc, value) => acc + value);
  const diff = 40 - sum;
  const sortByDecimal = orderBy(values, (x) => x - Math.trunc(x), 'desc');
  const finalValue = sortByDecimal?.map((value, index) =>
    (index < diff ? Math.floor(value) + 1 : Math.floor(value)));

  return finalValue;
}

export function getSummaryColors(summary = []) {
  let summaryColors = [];

  if (summary?.length > 0) {
    const values = summary.map((value) => (value.percentageStars * 40) / 100);
    const finalValueSorted = orderBy(
      remainderDistribution(values),
      (x) => x,
      'desc',
    );
    summary.forEach((value, index) => {
      const timesValue = finalValueSorted[index];
      const valueTimes = times(timesValue, () => ({
        color1: value?.star?.backgroundColor,
        color2: value?.star?.backgroundColor,
        id: value.id,
      }));
      summaryColors = summaryColors.concat(valueTimes);
    });
  }

  return summaryColors;
}
