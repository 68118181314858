// @packages
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import { StarContext } from 'containers/OSFeed/OSFeedItem';
import { buildShareModalUrl } from 'containers/QueryModals/helpers';
import { helperIsNotNDaysOld } from 'utils/star';
import { trackEvent } from 'utils/gtm';

// @own
import withStarConext from './StarContext';
import KudosStar from './KudosStar';
import SimpleStar from './SimpleStar';
import {
  TYPE_STAR_CUSTOMER_KUDOS,
  TYPE_STAR_CUSTOMER_MODE,
  TYPE_STAR_KUDOS,
} from './constants';

function Star({ isModerator, ownId }) {
  const { data, onEditStar, onLiftStar } = useContext(StarContext);
  const isStarOwner = data?.from?.id === ownId;
  const starUrl = buildShareModalUrl({ modal: 'star', id: data?.id });
  const canEdit = isStarOwner && helperIsNotNDaysOld(5, data?.date);
  const isModerate = isModerator && !data?.enabled;

  function handleHashtagClick(from) {
    trackEvent({
      action: 'Click',
      category: 'Hashtags',
      label: `Click on ${from}`,
    });
  }

  const notesClassName = cn('star__description-notes', {
    'star__description-notes--shaddy': isModerate,
  });

  const isCustomerStar =
    data?.typeStar === TYPE_STAR_CUSTOMER_MODE ||
    data?.typeStar === TYPE_STAR_CUSTOMER_KUDOS;

  return (
    <div>
      {data?.typeStar !== TYPE_STAR_KUDOS && !isCustomerStar ? (
        <SimpleStar
          canEdit={canEdit}
          enabled={data?.enabled}
          isModerate={isModerate}
          isModerator={isModerator}
          notesClassName={notesClassName}
          onEditStar={onEditStar}
          onLiftStar={onLiftStar}
          starId={data?.id}
          starUrl={starUrl}
          trackHashtag={handleHashtagClick}
        />
      ) : (
        <KudosStar
          canEdit={canEdit}
          enabled={data?.enabled}
          isCustomerStar={isCustomerStar}
          isModerate={isModerate}
          isModerator={isModerator}
          notesClassName={notesClassName}
          onEditStar={onEditStar}
          onLiftStar={onLiftStar}
          starId={data?.id}
          starUrl={starUrl}
          trackHashtag={handleHashtagClick}
        />
      )}
    </div>
  );
}

Star.propTypes = {
  isModerator: PropTypes.bool,
};

export default withStarConext(Star, 'STAR');
