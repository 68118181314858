// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';

// @own
import messages from '../../messages';
import './styles.scss';

function Nps({
  disabled,
  onClick,
  option,
  intl: { formatMessage },
}) {
  const selected = get(option, 'selected.data.value');
  const number = get(option, 'data.number');

  return (
    <div className="questionnaires-flex-type-nps">
      {option?.first && (
        <div className="questionnaires-flex-type-nps__label">
          {formatMessage(messages.QuestionnairesFlexOptionDescriptionNpsNotLikely)}
        </div>
      )}
      <div className="questionnaires-flex-type-nps__content" onClick={() => onClick(number)}>
        {number !== undefined
    ? (
      <div
        className={cn({
            'questionnaires-flex-type-nps--active': selected === number,
            'questionnaires-flex-type-nps--disabled': disabled,
          })}
      >
        {number}
      </div>
    ) : null}
      </div>
      {option?.last &&
        <div className="questionnaires-flex-type-nps__label">
          {formatMessage(messages.QuestionnairesFlexOptionDescriptionNpsLikely)}
        </div>
      }
    </div>
  );
}

Nps.defaultProps = {
  disabled: false,
};

Nps.propTypes = {
  onClick: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default injectIntl(Nps);
