import React, { Component } from 'react';
import ButtonV2 from 'smu-ui-components/ButtonV2';
import Checkbox from 'smu-ui-components/Checkbox';
import HTML from 'components/HTML';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import { connect } from 'react-redux';
import { logout } from 'containers/Authorization/actions';
import { trackEvent } from 'utils/gtm';

import './styles.scss';

class Agreement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: false,
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSaveAgreement = this.handleSaveAgreement.bind(this);
    this.handleDeclineAgreement = this.handleDeclineAgreement.bind(this);
  }


  handleCheckboxChange() {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  handleDeclineAgreement() {
    const { logout, eventCategory } = this.props;
    trackEvent({
      category: eventCategory,
      action: 'Decline',
    });
    logout();
  }

  handleSaveAgreement() {
    const { saveAgreement, eventCategory } = this.props;
    trackEvent({
      category: eventCategory,
      action: 'Accept',
    });
    saveAgreement();
  }

  render() {
    const {
      agreement,
      checkboxText,
      fetching,
      lblCancelBtn,
      lblContinueBtn,
      title,
    } = this.props;
    return (
      <div className="agreement">
        <div className="agreement__header">{title}</div>
        <div className="agreement__body">
          <Segment loading={fetching} loadingType="starmeup" withChildren={false} >
            <HTML html={agreement} />
          </Segment>
        </div>
        <div className="agreement__footer">
          <div className="agreement__footer--agreement">
            <Checkbox onChange={this.handleCheckboxChange} checked={this.state.isChecked} className="agreement__footer--checkbox" />
            <span className="agreement__footer--text">
              {checkboxText}
            </span>
          </div>
          <div className="agreement__footer--buttons">
            <ButtonV2
              onClick={this.handleDeclineAgreement}
              variant="outline"
            >
              {lblCancelBtn}
            </ButtonV2>
            <ButtonV2
              disabled={!this.state.isChecked}
              onClick={this.handleSaveAgreement}
            >
              {lblContinueBtn}
            </ButtonV2>
          </div>
        </div>
      </div>
    );
  }
}

Agreement.defaultProps = {
  lblCancelBtn: 'CANCEL',
  lblContinueBtn: 'CONTINUE',
};

Agreement.propTypes = {
  agreement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checkboxText: PropTypes.string,
  eventCategory: PropTypes.string,
  fetching: PropTypes.bool,
  lblContinueBtn: PropTypes.string,
  lblCancelBtn: PropTypes.string,
  logout: PropTypes.func,
  saveAgreement: PropTypes.func,
  title: PropTypes.string,
};

const overloadedActions = {
  logout,
};

export default connect(null, overloadedActions)(Agreement);
