import { put, call, takeLatest } from 'redux-saga/effects';
import { getTopRank } from './api';
import {
  GET_TOP_RANK_USER,
  GET_TOP_RANK_USER_SUCCESS,
  GET_TOP_RANK_USER_FAIL,
} from './actionTypes';

function* getTopRankWorker() {
  try {
    const results = yield call(getTopRank);
    yield put({
      type: GET_TOP_RANK_USER_SUCCESS,
      payload: results,
    });
  } catch (error) {
    yield put({ type: GET_TOP_RANK_USER_FAIL });
  }
}

function* getTopRankWatcher() {
  yield takeLatest(GET_TOP_RANK_USER, getTopRankWorker);
}

export default {
  getTopRankWatcher,
};
