export const COMMENT = 'COMMENT';
export const FEED_ITEM_MAIN_PHOTO = 'FEED_ITEM_MAIN_PHOTO';
export const IMAGE = 'IMAGE';
export const LIFT_STAR = 'LIFT_STAR';
export const LIKE = 'LIKE';
export const MAIN_PHOTO = 'MAIN_PHOTO';
export const MAX_COMMENT = 400;
export const STAR = 'STAR';
export const STARIMAGE = 'STARIMAGE';
export const TYPE_STAR_CUSTOMER_KUDOS = 'R';
export const TYPE_STAR_CUSTOMER_MODE = 'M';
export const TYPE_STAR_KUDOS = 'K';
