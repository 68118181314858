import {
  CLEAN_STATE,
  BEING_EDITABLE,
  SET_VALUE,
  SAVE_VALUE,
  SAVE_VALUE_SUCCESS,
  SAVE_VALUE_ERROR,
} from './actionTypes';

const initialState = {
  field: undefined,
  value: undefined,
  beingChanged: undefined,
  fetching: undefined,
  result: undefined,
  error: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CLEAN_STATE:
      return {
        ...state,
        field: undefined,
        value: undefined,
        beingChanged: undefined,
      };
    case BEING_EDITABLE:
      return {
        ...state,
        field: payload.field,
        value: payload.value,
        beingChanged: true,
      };
    case SET_VALUE:
      return {
        ...state,
        value: payload,
      };
    case SAVE_VALUE:
      return {
        ...state,
        fetching: true,
      };
    case SAVE_VALUE_SUCCESS:
      return {
        ...state,
        field: undefined,
        value: undefined,
        beingChanged: false,
        result: payload,
        error: undefined,
        fetching: false,
      };
    case SAVE_VALUE_ERROR:
      return {
        ...state,
        field: undefined,
        value: undefined,
        beingChanged: false,
        fetching: false,
        error: payload,
        result: undefined,
      };
    default:
      return state;
  }
}
