// @app
import { errorHandler } from 'utils/errorHandler';
import { defaultApi } from 'utils/requestHelper';

export function apiGetCommunityMembers({ params }) {
  return defaultApi({
    data: {},
    params,
    url: '/os-member/api/v2/community/members',
  })
    .then((data) => data)
    .catch(errorHandler);
}
