export const optionsConfig = {
  chart: {
    height: 275,
    spacingBottom: 10,
    spacingTop: 10,
  },
  title: {
    text: null,
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    labels: {
      enabled: false,
    },
    tickLength: 0,
  },
  yAxis: {
    labels: {
      enabled: true,
      format: '{value}%',
    },
    gridLineDashStyle: 'LongDash',
    gridLineWidth: 1,
    tickAmount: 3,
    title: {
      text: null,
    },
  },
  legend: {
    enabled: true,
  },
  plotOptions: {
    series: {
      animation: true,
      borderWidth: 0,
      pointWidth: 20,
    },
    column: {
      groupPadding: 0.1,
      pointWidth: 80,
    },
  },
  tooltip: {
    enabled: true,
    formatter() {
      return `${Math.round(this.y)}%`;
    },
    outside: true,
  },
};
