// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { apiPostAdvancedUserSearch } from './api';

import { REQUEST_ADVANCED_USER_SEARCH } from './actionTypes';

import {
  requestAdvancedUserSearchSuccess,
  requestAdvancedUserSearchFail,
} from './actions';

function* postAdvancedUserSearchWorker({ payload }) {
  try {
    const { data } = yield call(apiPostAdvancedUserSearch, payload);
    yield put(requestAdvancedUserSearchSuccess({ result: data?.result }));
  } catch (error) {
    yield put(requestAdvancedUserSearchFail({ error }));
  }
}

export function* postAdvancedUserSearchWatcher() {
  yield takeEvery(REQUEST_ADVANCED_USER_SEARCH, postAdvancedUserSearchWorker);
}

export default {
  postAdvancedUserSearchWatcher,
};
