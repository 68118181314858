import { call, put, takeLatest } from 'redux-saga/effects';
import { getConnections } from './api';
import {
  GET_CONNECTIONS,
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_FAIL,
} from './actionTypes';

function* getConnectionsWorker({ payload }) {
  try {
    const { id, clusterBy, rolValue } = payload;
    const { nodes, edges } = yield call(getConnections, { id, clusterBy, rolValue });
    yield put({ type: GET_CONNECTIONS_SUCCESS, payload: { nodes, edges } });
  } catch (e) {
    yield put({ type: GET_CONNECTIONS_FAIL });
  }
}

function* getConnectionsWatcher() {
  yield takeLatest(GET_CONNECTIONS, getConnectionsWorker);
}

export default {
  getConnectionsWatcher,
};
