import { put, call, takeEvery } from 'redux-saga/effects';
import { merge } from 'lodash';

import { actionTypes, actions } from './actions';
import { getLeaderboard as getLeaderboardAPI } from './api';

function* getLeaderboardWorker({ payload }) {
  const { updateLeaderboard, fields, persistedFields = {} } = payload || {};
  try {
    if (updateLeaderboard) {
      const results = yield call(getLeaderboardAPI, merge(fields, persistedFields));
      yield put(actions.getLeaderboardSuccess(results));
    }
  } catch (error) {
    yield put(actions.getLeaderboardFailed(error));
  }
}

function* getLeaderboardWatcher() {
  yield takeEvery(actionTypes.getLeaderboard, getLeaderboardWorker);
}

function* updateLeaderboardFieldsWorker({ payload }) {
  const { dispatch } = payload;
  if (dispatch) {
    yield put(dispatch);
  }
}

function* updateLeaderboardFieldsWatcher() {
  yield takeEvery(actionTypes.updateLeaderboardFields, updateLeaderboardFieldsWorker);
}

export default {
  getLeaderboardWatcher,
  updateLeaderboardFieldsWatcher,
};
