// @packages
import React, { useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';
import { aspectRatioPercentage } from 'smu-utils/aspectRatio';
import { useSelector, useDispatch } from 'react-redux';

// @app
import { actionUploadShareImage, actionUploadShareImageDestroy } from 'services/uploadShareImage/actions';
import { dataUrlToFile } from 'utils/file';
import { resizeImageB64 } from 'utils/image';
import { selectCommunityId, selectSessionUser } from 'containers/Authorization/selectors';
import { selectUploadShareImageError, selectUploadShareImageResult } from 'services/uploadShareImage/selectors';

// @own
import './styles.scss';
import ShareHtml from './Html';
import SharePortal from './Portal';
import config from './config';
import shareReducer, { initialState } from './reducer';
import {
  TYPE_BADGE,
  TYPE_IMAGE_LOADED,
  TYPE_SHARE_END,
  TYPE_SHARE_ERROR,
  TYPE_SHARE_IMAGE,
  TYPE_SHARE_START,
  TYPE_STAR,
} from './constants';

function ShareContainer({ className, enableUploadFile, onError, onSuccess, step, type }) {
  const [htmlHeight, setHtmlHeight] = useState(config?.[type]?.height);
  const [state, setState] = useReducer(shareReducer, initialState);
  const contentRef = useRef(null);
  const shareId = `share_${type}`;
  const communityId = useSelector(selectCommunityId);
  const shareImageUrl = useSelector(state => selectUploadShareImageResult(state, shareId));
  const shareImageError = useSelector(state => selectUploadShareImageError(state, shareId));
  const dispatch = useDispatch();
  const userId = useSelector(selectSessionUser)?.id;
  const Component = config?.[type]?.component;
  const htmlWidth = config?.[type]?.width;

  function handleUploadImage(file) {
    const data = new FormData();
    data.append('file', file);
    data.append('communityId', communityId);
    data.append('userId', userId);
    dispatch(actionUploadShareImage({ data }, shareId));
  }

  function handleStart() {
    setState({
      type: TYPE_SHARE_START,
    });
  }

  function onReady() {
    if (type === TYPE_STAR || type === TYPE_BADGE) {
      setHtmlHeight(contentRef.current.clientHeight);
    }

    const html = contentRef.current.innerHTML;

    setState({
      html,
      type: TYPE_SHARE_END,
    });
  }

  function handleError(error) {
    setState({
      error,
      type: TYPE_SHARE_ERROR,
    });
  }

  function handleImageLoaded() {
    setState({
      type: TYPE_IMAGE_LOADED,
    });
  }

  async function buildCanvasImage(canvas) {
    try {
      const image = canvas.toDataURL('image/jpeg', 1.0);
      const imageB64 = await resizeImageB64(image, htmlWidth * 5, htmlHeight * 5);
      const blob = await dataUrlToFile({
        dataUrl: imageB64,
        fileName: `share_${new Date().getTime()}`,
      });

      if (enableUploadFile) {
        handleUploadImage(blob);
      } else if (onSuccess) {
        onSuccess(blob);
      }
    } catch (error) {
      handleError(error);
    }
  }

  useEffect(() => {
    if (shareImageError) {
      setState({
        error: shareImageError,
        type: TYPE_SHARE_ERROR,
      });
    }
  }, [shareImageError]);

  useEffect(() => {
    if (state.error && onError) {
      onError(state.error);
    }
  }, [state.error]);

  useEffect(() => {
    if (shareImageUrl) {
      setState({
        image: shareImageUrl,
        type: TYPE_SHARE_IMAGE,
      });
    }
  }, [shareImageUrl]);

  useEffect(() => {
    if (state.image && onSuccess) {
      onSuccess(state.image);
    }
  }, [state.image]);

  useEffect(() => {
    handleStart();

    return () => {
      dispatch(actionUploadShareImageDestroy(shareId));
    };
  }, [shareId]);

  return (
    <div className={cn('share-container', className)}>
      {!state.image && state.html && (
        <ShareHtml
          className="share-container__html"
          height={htmlHeight}
          html={state.html}
          onError={handleError}
          onSuccess={buildCanvasImage}
          width={htmlWidth}
        />
      )}
      <div className="share-container__element">
        <div className="share-container__element-share">
          <SharePortal id="share-portal">
            {state?.render && (
              <div ref={contentRef}>
                {Component && (
                  <Component onError={handleError} onReady={onReady} step={step} />
                )}
              </div>
            )}
          </SharePortal>
        </div>
      </div>
      <div
        className="w-full relative"
        style={{
          paddingTop: `${aspectRatioPercentage({
            height: htmlHeight,
            width: htmlWidth,
          })}%`,
        }}
      >
        {state.loading ? (
          <Skeleton
            className="absolute z-10 left-0 top-0 w-full h-full"
            variant="rect"
          />
        ) : (
          <img
            alt="share-preview"
            className="absolute z-10 left-0 top-0 w-full h-full"
            onError={handleError}
            onLoad={handleImageLoaded}
            src={state.image}
          />
        )}
      </div>
    </div>
  );
}

ShareContainer.defaultProps = {
  enableUploadFile: true,
};

ShareContainer.propTypes = {
  className: PropTypes.string,
  enableUploadFile: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  step: PropTypes.string,
  type: PropTypes.string,
};

export default ShareContainer;
