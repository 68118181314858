// @packages
import messages from 'smu-app-components/UserProfile/messages';
import { getWebUrl } from 'smu-utils/globalRequestHelper';

// @app
import Link from 'components/Link';

const bettermeUrl = getWebUrl('betterme');

export const isMyCollaborator = (bmeUser, bmeOtherUser) => {
  const byLeaders = bmeOtherUser?.leaders?.some(leader => leader.id === bmeUser?.id);
  const byLider = bmeOtherUser?.leader?.id === bmeUser?.id;
  return byLeaders || byLider;
};

export const isMyLeader = (bmeUser, bmeOtherUser) => {
  const byLeaders = bmeUser?.leaders?.some(leader => leader?.id === bmeOtherUser?.id);
  const byLeader = bmeUser?.leader === bmeOtherUser?.id;
  return byLeaders || byLeader;
};

export const isEnabledAddMyTeam = ({
  bmeAllowsAddToMyTeam,
  bmeOtherUser,
  bmeUser,
  enabledBetterme,
  owner,
}) =>
  enabledBetterme &&
  !owner &&
  bmeAllowsAddToMyTeam &&
  !isMyCollaborator(bmeUser, bmeOtherUser) &&
  !isMyLeader(bmeUser, bmeOtherUser);

export const helperActionsProps = ({
  bmeAllowsAddToMyTeam,
  bmeOtherUser,
  bmeUser,
  enabledBethere,
  enabledBetterme,
  enabledOneOnOne,
  enabledOneOnOneRequest,
  enabledStarMeUp,
  formatMessage,
  member,
  onSendStar,
  owner,
}) => {
  const actions = [];
  const moreMessage = formatMessage(messages.userProfileMore);
  const userUID = member.selectPropertyUID();
  const userIdentification = member.selectIdentification();
  const userIsLeader = member.selectConfigIsLeader();
  const userIsCollaborator = member.selectConfigIsCollaborator();
  const userIsMyLeader = member.selectConfigIsMyLeader();
  const userIsMyCollaborator = member.selectConfigIsMyCollaborator();
  const encodeIdentification = userIdentification ? window.btoa(userIdentification) : '';

  const enabledAddMyTeam = isEnabledAddMyTeam({
    bmeAllowsAddToMyTeam,
    bmeOtherUser,
    bmeUser,
    enabledBetterme,
    owner,
  });

  if (enabledStarMeUp) {
    actions.push({
      children: formatMessage(messages.userProfileSendStar),
      onClick: onSendStar,
    });
  }

  if (owner && enabledBethere) {
    actions.push({
      children: formatMessage(messages.userProfileUploadPost),
      href: `${window.location.pathname}?modal=upload-post`,
    });
  }

  if (enabledBetterme) {
    actions.push({
      children: formatMessage(messages.userProfileSendFeedback),
      href: owner
        ? `${bettermeUrl}/newfeedbacksend`
        : `${bettermeUrl}/newfeedback/${encodeIdentification}`,
    });
    actions.push({
      children: formatMessage(messages.userProfileRequestFeedback),
      href: `${bettermeUrl}/newfeedbackrequest${
        owner ? '' : `/${encodeIdentification}`
      }`,
    });
  }

  if (enabledAddMyTeam) {
    actions.push({
      children: formatMessage(messages.userProfileAddToMyTeam),
      href: `${bettermeUrl}/leadership-admin/${encodeIdentification}`,
    });
  }

  if (enabledOneOnOne) {
    const getCreateOneOnOneAction = uid => ({
      children: formatMessage(messages.userProfileCreateOneOnOne),
      href: `${window.location.pathname}?modal=create-1on1${owner ? '' : `&uid=${uid}`}`,
    });

    const getRequestOneOnOneAction = uid => ({
      children: formatMessage(messages.userProfileRequestOneOnOne),
      href: `${window.location.pathname}?modal=request-1on1${owner ? '' : `&uid=${uid}`}`,
    });

    if (owner) {
      if (userIsLeader) {
        actions.push(getCreateOneOnOneAction());
      }

      if (enabledOneOnOneRequest && userIsCollaborator) {
        actions.push(getRequestOneOnOneAction());
      }
    } else {
      if (userIsMyCollaborator) {
        actions.push(getCreateOneOnOneAction(userUID));
      }

      if (enabledOneOnOneRequest && userIsMyLeader) {
        actions.push(getRequestOneOnOneAction(userUID));
      }
    }
  }

  return {
    actions,
    components: {
      link: Link,
    },
    moreMessage,
  };
};
