// @packages
import { take, put, call } from 'redux-saga/effects';

// @own
import { apiGetInteractionsPosts } from './api';
import { REQUEST_GET_INTERACTIONS_POSTS } from './actionTypes';
import {
  actionGetInteractionsPostsFail,
  actionGetInteractionsPostsSuccess,
} from './actions';

function* getInteractionsPostsWorker({ payload: { communityId, postId } }) {
  try {
    const { data } = yield call(apiGetInteractionsPosts, communityId, postId);
    yield put(actionGetInteractionsPostsSuccess(data?.result));
  } catch (error) {
    yield put(actionGetInteractionsPostsFail(error));
  }
}

export function* getInteractionsPostsWatcher() {
  yield take(REQUEST_GET_INTERACTIONS_POSTS, getInteractionsPostsWorker);
}

export default {
  getInteractionsPostsWatcher,
};
