export const SEGMENTS_USERS_FILTERS = 'segmentsUsersFilters';
export const SEGMENTS_USERS_LIST = 'segmentsUsersList';
export const FILTERS_PARAMS = {
  dir: 'ASC',
  orderBy: 'name',
  page: 0,
  size: 200,
};
export const SELECT_PROPS = {
  isClearable: false,
  isSearchable: true,
  showSearchIcon: true,
  value: [],
};
