// @packages
import React from 'react';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';
import moment from 'moment';
import truncate from 'lodash/truncate';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { detectWebView } from 'smu-utils/device';
import { getUserLanguage } from 'smu-utils/language';
import { useSelector } from 'react-redux';

// @app
import { selectSessionStars } from 'containers/Authorization/selectors';

// @own
import './styles.scss';
import messages from './messages';
import ActionsDetail from './Actions';
import BackToInsights from './BackTo';
import CulturalActivity from './CulturalActivity';
import CulturalIndicator from './CulturalIndicator';
import GraphicBar from './GraphicBar';
import GraphicColumn from './GraphicColumn';
import IndicatorDetail from './Indicator';
import LineGraph from './LineGraph';
import OrganizationValue from './OrganizationValue';
import UsefulTip from './UsefulTip';
import UserGrid from './UserGrid';
import ViewMore from './ViewMore';
import { INSIGHT_TYPES } from './constants';

function InsightDetails({
  actions,
  className,
  data,
  intl: { formatMessage },
  isLoading,
  isModal,
  type,
  userList,
}) {
  moment.locale(getUserLanguage());

  const content = data?.content;
  const graph = data?.graph;
  const indicator = data?.indicator?.[0];
  const culturalIndicator = data?.indicator?.[1];
  const culturalEvolution = data?.indicator?.[2];
  const period = data?.period;
  const isColumn = graph?.type === 'column';
  const isBar = graph?.type === 'bar';
  const isLineGraph = graph?.type === 'lineGraph';
  const isUserList = userList.length > 0;
  const month = moment(period?.from).format('MMMM');
  const isWebView = detectWebView();
  const areaType = data?.properties?.type;
  const areaName = data?.properties?.name;
  const organizationValues = useSelector(state => selectSessionStars(state));

  const isManagerInsight = type === INSIGHT_TYPES.MANAGERS_NO_FEEDBACK
    || type === INSIGHT_TYPES.NO_ACTIVE_NEW_HIRES
    || type === INSIGHT_TYPES.AREA_TURNOVER_RATE;

  const isWhoRepresentsThisValue = type === INSIGHT_TYPES.WHO_REPRESENTS;

  const organizationValue = isWhoRepresentsThisValue
    && indicator?.value
    && organizationValues?.find(value => value.id === Number(indicator?.value));

  const normalizedTitle = messages?.[`${content?.title}`]
    ? (
      <FormattedHTMLMessage
        defaultMessage={messages[`${content?.title}`].defaultMessage}
        id={messages[`${content?.title}`].id}
        values={{
          area: areaType,
        }}
      />
    ) : content?.title;

  const normalizedDescription = messages?.[`${content?.description}`]
    ? (
      <FormattedHTMLMessage
        defaultMessage={messages[`${content?.description}`].defaultMessage}
        id={messages[`${content?.description}`].id}
      />
    ) : content?.description;

  const eventData = {
    description: normalizedDescription,
    kpi: indicator?.value,
    label: normalizedTitle,
    type,
  };

  function getPeriod() {
    const dateFrom = moment(period?.from).format('MMM');
    const dateTo = moment(period?.to).format('MMM');
    let finalPeriod = 2;

    if (dateFrom !== dateTo) {
      finalPeriod = `${moment(period?.from).format('MMMM')} - ${moment(period?.to).format('MMMM YYYY')}`;
    } else {
      finalPeriod = `${moment(period?.to).format('MMMM YYYY')}`;
    }

    return finalPeriod;
  }

  return (
    <div className="insight-details">
      {!isModal && (
        <div className="insight-details__back-to-and-period">
          <BackToInsights />
          {(period && !isLoading) && (
            <div className="insight-details__period">
              {getPeriod()}
            </div>
          )}
        </div>
      )}
      {isLoading && <Skeleton animation="wave" height={500} />}
      {!isLoading && (
        <>
          <Panel
            className={cn(
              'insight-details__panel',
              { 'insight-details__panel--is-modal': isModal },
              className,
            )}
            noPadding
          >
            {content && (
              <>
                <div className="insight-details__title">
                  {normalizedTitle}
                </div>
                <div className="insight-details__description">
                  {(organizationValue && messages?.[`${content?.description}`])
                    ? (
                      <>
                        <FormattedHTMLMessage
                          defaultMessage={
                            messages[`${content.description}`]?.firstPart?.defaultMessage
                          }
                          id={messages[`${content.description}`]?.firstPart?.id}
                          values={{
                            organizationValueName: organizationValue?.nameShowed?.toUpperCase(),
                          }}
                        />
                        {' '}
                        <i>
                          <ViewMore
                            text={`"${organizationValue.descriptionShowed}"`}
                            visibleText={truncate(
                              `"${organizationValue.descriptionShowed}"`,
                              { length: 250 },
                            )}
                          />
                        </i>
                        <br />
                        <br />
                        {formatMessage(messages[`${content.description}`]?.secondPart)}
                      </>
                    ) : normalizedDescription
                  }
                </div>
              </>
            )}
            <div className="insight-details__content">
              {indicator
              && !culturalIndicator
              && !isWhoRepresentsThisValue
              && (
                <IndicatorDetail
                  areaName={areaName}
                  indicator={indicator}
                  month={month}
                  type={type}
                />
              )}
              {culturalIndicator && (
                <CulturalIndicator
                  culturalEvolution={culturalEvolution}
                  culturalIndicator={culturalIndicator}
                />
              )}
              {organizationValue && (
                <OrganizationValue value={organizationValue} />
              )}
              {graph && (
                <>
                  {isColumn && (
                    <GraphicColumn
                      areaName={areaName}
                      areaType={areaType}
                      eventData={eventData}
                      graph={graph}
                      isManagerInsight={isManagerInsight}
                      isWebView={isWebView}
                      type={type}
                    />
                  )}
                  {isBar && (
                    <GraphicBar
                      eventData={eventData}
                      graph={graph}
                      isWebView={isWebView}
                      type={type}
                    />
                  )}
                  {isLineGraph && (
                    <LineGraph
                      eventData={eventData}
                      graph={graph}
                      isWebView={isWebView}
                      type={type}
                    />
                  )}
                </>
              )}
              {isUserList && (
                <>
                  {isWhoRepresentsThisValue
                    ? (
                      <UserGrid
                        insightType={type}
                        organizationValueId={organizationValue?.id}
                        users={userList}
                      />
                    ) : (
                      <CulturalActivity
                        data={userList}
                        eventData={eventData}
                        graph={graph}
                        indicator={indicator}
                        type={type}
                      />
                    )
                  }
                </>
              )}
              {actions && (
                <ActionsDetail
                  actions={actions}
                  eventData={eventData}
                />
              )}
            </div>
          </Panel>
          <UsefulTip eventData={eventData} />
        </>
      )}
    </div>
  );
}

InsightDetails.propTypes = {
  actions: PropTypes.array,
  className: PropTypes.string,
  data: PropTypes.object,
  intl: PropTypes.object,
  isLoading: PropTypes.bool,
  isModal: PropTypes.bool,
  type: PropTypes.string,
  userList: PropTypes.array,
};

export default injectIntl(InsightDetails);
