import { defineMessages } from 'react-intl';

export default defineMessages({
  newFeaturesCallToAction: {
    id: 'newFeatures.callToAction',
    defaultMessage: 'TAKE ME THERE!',
  },
  newFeaturesInteractionTimelineDescription: {
    id: 'newFeatures.interactionTimelineDescription',
    defaultMessage: `In an easy-to-read graph, you can now see your level of interaction with your colleagues on the StarMeUp platform for the last twelve months.
    \nHow many stars you've sent, the comments and "likes" you made month by month are all in one place. You can also compare your activity with the average across your organization.
    \nTake your participation on StarMeUp to the next level and make sure you stand out among your peers!`,
  },
  newFeaturesInteractionTimelineTitle: {
    id: 'newFeatures.interactionTimelineTitle',
    defaultMessage: 'Create more and better connections using the Interaction Timeline in your user profile!',
  },
  newFeaturesCulturalIndicatorDescription: {
    id: 'newFeatures.culturalIndicatorDescription',
    defaultMessage: `We want to celebrate the people making the biggest impact in these uncertain times by making them more visible in StarMeUp.
    \nThere is a new  status that is updated daily based on your interactions with your colleagues:  Legendary, Shining, or Rising. This status is showed under your profile picture. This status is based on all your activity in StarMeUp: from the stars you send and receive to the likes and comments you make.
    \nIn \"My profile,\” under the network tab, you will also be able to see those who are making the biggest impact in your company.`,
  },
  newFeaturesCulturalIndicatorTitle: {
    id: 'newFeatures.CulturalIndicatorTitle',
    defaultMessage: 'Amplify your impact on your organization as a Cultural Influencer.',
  },
  newFeaturesPageTitle: {
    id: 'newFeatures.pageTitle',
    defaultMessage: 'New',
  },
  newFeaturesPageSubtitle: {
    id: 'newFeatures.pageSubtitle',
    defaultMessage: 'Features',
  },
});
