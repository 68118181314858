// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const Title = ({
  children,
  className,
  onClick,
  type,
}) => (
  <div className={cn(className, 'title', `title-${type}`)} onClick={onClick}>
    {children}
  </div>
);

Title.defaultProps = {
  type: 'widget',
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  type: PropTypes.oneOf([
    'page',
    'page-section',
    'widget',
  ]),
  onClick: PropTypes.func,
};

export default Title;
