// @packages
import React, { useEffect } from 'react';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

// @app
import OSFeedItem from 'containers/OSFeed/OSFeedItem';
import SkeletonFeedItem from 'components/Skeletons/FeedItem';
import Star from 'components/Star';

// @own
import './styles.scss';
import messages from './messages';
import { getHighlightedStar } from './actions';
import { selectHighlihtedStars, selectHighlihtedFetching } from './selectors';

const HighlightedStar = ({
  fetching,
  getHighlightedStar,
  highlightedStars,
  userId,
}) => {
  const showComponent = fetching || highlightedStars?.[0];

  useEffect(() => {
    if (userId) {
      getHighlightedStar(userId);
    }
  }, [userId]);

  return showComponent ? (
    <Panel>
      <div className="highlighted-star">
        <div className="highlighted-star__title">
          <FormattedMessage {...messages.highlightedStarTitle} />
        </div>
        {fetching ? (
          <SkeletonFeedItem />
        ) : (
          highlightedStars?.map((feedItem, index) => (
            <OSFeedItem
              allowLiftStar={false}
              data={{
                data: feedItem,
                date: feedItem.date,
                id: feedItem.id,
              }}
              isShareable={false}
              key={`highlighted-star-${feedItem?.id}-${index}`}
              type={feedItem.type}
            >
              <Star type="CORE_VALUE" />
              <Star type="KUDOS" />
              <Star type="SUPERNOVA" />
            </OSFeedItem>
          ))
        )}
      </div>
    </Panel>
  ) : null;
};

HighlightedStar.propTypes = {
  fetching: PropTypes.bool,
  getHighlightedStar: PropTypes.func.isRequired,
  highlightedStars: PropTypes.array,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  fetching: selectHighlihtedFetching(state),
  highlightedStars: selectHighlihtedStars(state),
});

const actions = {
  getHighlightedStar,
};

export default connect(mapStateToProps, actions)(injectIntl(HighlightedStar));
