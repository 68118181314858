import { defineMessages } from 'react-intl';

export default defineMessages({
  notificationsMarkAllAsRead: {
    id: 'notificationsHistory.markAllAsRead',
    defaultMessage: 'MARK ALL AS READ',
  },
  notificationsTitle: {
    id: 'notificationsHistory.notifications',
    defaultMessage: 'notifications',
  },
});
