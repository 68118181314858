// @packages
import React from 'react';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const FeedSortToggle = ({
  className,
  headerIcon,
  headerText,
  onToggle,
  toggleText,
}) => (
  <div className={cn('feed-sort-toggle', className)}>
    <div className="feed-sort-toggle__header">
      <Icon
        className="feed-sort-toggle__header-icon"
        color="dazzleRose"
        icon={headerIcon}
        size={54}
      />
      <span>{headerText}</span>
    </div>
    <div
      className="feed-sort-toggle__footer"
      onClick={onToggle}
      role="button"
    >
      <Icon
        className="feed-sort-toggle__footer-icon"
        color="manatee"
        icon="arrows"
      />
      <span>{toggleText}</span>
    </div>
  </div>
);

FeedSortToggle.propTypes = {
  className: PropTypes.string,
  headerIcon: PropTypes.string,
  headerText: PropTypes.string,
  onToggle: PropTypes.func,
  toggleText: PropTypes.string,
};

export default FeedSortToggle;
