// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { selectResultProp, selectRequesting } from 'smu-utils/reduxRequests/selectors';

// @app
import InsightDetailsComponent from 'components/InsightDetails';

// @own
import { REQUEST_ID_GET_INSIGHT, REQUEST_ID_GET_USER_LIST } from './constants';
import { apiGetInsight, apiGetUserList } from './api';

function InsightDetails({ isModal, router }) {
  const dispatch = useDispatch();
  const insightId = router?.location?.query?.cardId || router?.params?.id;

  const insight = useSelector(state => selectResultProp(
    state, REQUEST_ID_GET_INSIGHT, 'result', {},
  ));

  const userList = useSelector(state => selectResultProp(
    state, REQUEST_ID_GET_USER_LIST, 'result', [],
  ));

  const isLoading = useSelector(state => (
    selectRequesting(state, REQUEST_ID_GET_INSIGHT)
    || selectRequesting(state, REQUEST_ID_GET_USER_LIST)
  ));

  function getInsight(params) {
    dispatch(
      actionRequestInit({
        api: apiGetInsight,
        id: REQUEST_ID_GET_INSIGHT,
        params: {
          insightId,
          params,
        },
      }),
    );
  }

  function getUserList(userList) {
    dispatch(
      actionRequestInit({
        api: apiGetUserList,
        id: REQUEST_ID_GET_USER_LIST,
        params: { userList },
      }),
    );
  }

  useEffect(() => {
    getInsight();

    return () => {
      dispatch(actionRequestDestroy(REQUEST_ID_GET_INSIGHT));
      dispatch(actionRequestDestroy(REQUEST_ID_GET_USER_LIST));
    };
  }, []);

  useEffect(() => {
    if (insight?.data?.data?.graph?.type === 'widgetUsersList') {
      const normalizedUserIds = insight?.data?.data?.graph?.values;

      const normalizedUserList = JSON.stringify(normalizedUserIds?.map(u => u?.value))
        ?.replace(/[\[\]']+/g, '');

      if (typeof normalizedUserList === 'string' && normalizedUserList.length) {
        getUserList(normalizedUserList);
      }
    }
  }, [insight]);

  return (insightId && (
    <InsightDetailsComponent
      actions={insight?.data?.actions}
      data={insight?.data?.data}
      isLoading={isLoading}
      isModal={isModal}
      type={insight?.code}
      userList={userList}
    />
  ));
}

InsightDetails.propTypes = {
  isModal: PropTypes.bool,
  router: PropTypes.object,
};

export default withRouter(InsightDetails);
