// @packages
import { browserHistory } from 'react-router';

// @app
import { paths } from 'utils/routes';

// @own
import CongratulationFeedLayout from './CongratulationFeedLayout';

export default () => ({
  component: CongratulationFeedLayout,
  onEnter: ({ params: { userId } }) => {
    if (!userId) {
      browserHistory.replace('/home');
    }
    window.scrollTo(0, 0);
  },
  path: paths.congratulationFeed(),
});
