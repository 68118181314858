// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import { trackEvent } from 'utils/gtm';

// @own
import messages from '../messages';

function UsefulTip({
  intl: { formatMessage },
  eventData,
}) {
  const [answered, setAnswered] = useState(false);

  return (
    <>
      {!answered && (
        <div className="insight-details__tip">
          <p className="insight-details__tip-intro">
            {formatMessage(messages.insightTip)}
          </p>
          <p
            className="insight-details__tip-option"
            onClick={() => {
              setAnswered(true);
              trackEvent({
                action: 'Useful_Yes',
                category: 'Insights',
                ...eventData,
              });
            }}
          >
            {formatMessage(messages.insightTipYes)}
          </p>
          <p
            className="insight-details__tip-option"
            onClick={() => {
              setAnswered(true);
              trackEvent({
                action: 'Useful_No',
                category: 'Insights',
                ...eventData,
              });
            }}
          >
            {formatMessage(messages.insightTipNo)}
          </p>
        </div>
      )}
      {answered && (
        <div className="insight-details__tip">
          <p>{formatMessage(messages.insightTipThankYou)}</p>
        </div>
      )}
    </>
  );
}

UsefulTip.propTypes = {
  eventData: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(UsefulTip);
