// @packages
import React from 'react';
import Avatar from 'smu-ui-components/Avatar';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';
import { buildImageUrl } from 'smu-utils/image';

const SelectOptionStarType = ({
  backgroundColor,
  disabled,
  imageCode,
  nameShowed,
}) => (
  <div
    className={cn('flex items-center space-x-2', {
      'grayscale opacity-50': disabled,
    })}
  >
    <Avatar
      src={buildImageUrl({
        code: imageCode,
        height: 30,
        width: 30,
      })}
      width={30}
      height={30}
    />
    <Typography
      className="uppercase font-semibold"
      style={{ color: disabled ? undefined : backgroundColor }}
    >
      {nameShowed}
    </Typography>
  </div>
);

SelectOptionStarType.propTypes = {
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  imageCode: PropTypes.string,
  nameShowed: PropTypes.string,
};

export default SelectOptionStarType;
