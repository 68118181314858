// @packages
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import ListItems from '../ListItems';
import Question from '../Question';
import QuestionnaireComments from '../QuestionnaireComments';
import QuestionnaireProgressBar from '../QuestionnaireProgressBar';

// @own
import './styles.scss';

function QuestionnairePanel({
  // currentCategory,
  currentQuestion,
  currentQuestionId,
  currentSubQuestionId,
  handleSubQuestionAnswer,
  handleSubmitAnswer,
  handleSubmitComments,
  hasSubQuestions,
  index,
  language,
  questionItems,
  showComments,
  subQuestionsItems,
  title,
  totalQuestions,
}) {
  const text = () => ({
    ENGLISH: {
      question: 'How do you feel about this statement?',
      subQuestion: 'You can select 2 options max.',
      buttonNext: 'Next',
    },
    SPANISH: {
      question: '¿Cómo te sientes acerca de esta declaración?',
      subQuestion: 'Puedes seleccionar 2 opciones máx.',
      buttonNext: 'Siguiente',
    },
  })[language];

  const {
    question,
    subQuestion,
    buttonNext,
  } = text();

  return (
    <div className="questionnaire-panel">
      <QuestionnaireProgressBar
        currentQuestion={index}
        maxQuestions={totalQuestions}
      />
      {showComments ? (
        <div
          className={cn(
            'questionnaire-panel__content',
            { 'questionnaire-panel__content--comment': showComments },
            { 'questionnaire-panel__content--subcontent': hasSubQuestions },
          )}
        >
          <h1 className="questionnaire-panel__title">{title}</h1>
          <QuestionnaireComments handleSubmitComments={handleSubmitComments} language={language} />
        </div>
      ) : (
        <Fragment>
          <div className={cn('questionnaire-panel__content', { 'questionnaire-panel__content--subcontent': hasSubQuestions })}>
            <h1 className="questionnaire-panel__title">{title}</h1>
            <div className="questionnaire-panel__category">
              {/* <h4 className="questionnaire-panel__category-text">{currentCategory}</h4> */}
              <Question question={currentQuestion} />
            </div>
          </div>
          {hasSubQuestions ? (
            <ListItems
              buttonText={buttonNext}
              currentId={currentSubQuestionId}
              handleSubmitAnswer={handleSubQuestionAnswer}
              items={subQuestionsItems}
              text={subQuestion}
              type="subQuestions"
            />
          ) : (
            <ListItems
              buttonText={buttonNext}
              currentId={currentQuestionId}
              handleSubmitAnswer={handleSubmitAnswer}
              items={questionItems}
              text={question}
              type="questions"
            />
          )}
        </Fragment>
      )}
    </div>
  );
}

QuestionnairePanel.defaultProps = {
  currentQuestionId: 0,
  currentSubQuestionId: 0,
  hasSubQuestions: false,
  title: '',
  totalQuestions: 0,
};

QuestionnairePanel.propTypes = {
  // currentCategory: PropTypes.string.isRequired,
  currentQuestion: PropTypes.string.isRequired,
  currentQuestionId: PropTypes.number,
  currentSubQuestionId: PropTypes.number,
  handleSubQuestionAnswer: PropTypes.func.isRequired,
  handleSubmitAnswer: PropTypes.func.isRequired,
  handleSubmitComments: PropTypes.func.isRequired,
  hasSubQuestions: PropTypes.bool,
  index: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  questionItems: PropTypes.array.isRequired,
  showComments: PropTypes.bool.isRequired,
  subQuestionsItems: PropTypes.array.isRequired,
  title: PropTypes.string,
  totalQuestions: PropTypes.number,
};

export default QuestionnairePanel;
