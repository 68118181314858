// @packages
import React, { createContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

// @app
import RecognitionEvent from 'components/OS/Event/RecognitionEvent';
import isDOMElement from 'utils/isDOMElement';
import { addUnvisitedOsFeedItem } from 'containers/OSFeed/actions';
import { selectUnvisitedItem, selectVisitedItem } from 'containers/OSFeed/selectors';

// @own
import Badge from '../ContentType/Badge';
import Congratulation from '../ContentType/Congratulation';
import Container from '../ContentType/Container';
import DocumentCard from '../ContentType/DocumentCard';
import Insight from '../ContentType/Insight';
import OSBethereMoments from '../ContentType/BethereMoments';
import Sponsored from '../ContentType/Sponsored';
import {
  ANNIVERSARIES,
  BADGES,
  BETHERE_MOMENTS,
  BETHERE_POST,
  BIRTHDAYS,
  CORE_VALUE,
  DOCUMENT_CARD,
  DOCUMENT_CARD_EXTERNAL,
  INSIGHT,
  INTERACTION_ANNIVERSARY,
  INTERACTION_BIRTHDAY,
  KUDOS,
  SUPERNOVA,
  TEAM_RECOGNITION,
} from '../constants';
import { isInViewport } from './helpers';

export const StarContext = createContext({});

function OSFeedItem({
  addUnvisitedOsFeedItem,
  children,
  className,
  data,
  ownId,
  props,
  type,
  unvisitedItems,
  visitedItems,
  ...rest
}) {
  const [componentList, setComponentList] = useState([
    {
      type: ANNIVERSARIES,
      component: Container,
    },
    {
      type: BADGES,
      component: Badge,
    },
    {
      type: BETHERE_MOMENTS,
      component: OSBethereMoments,
    },
    {
      type: BETHERE_POST,
      component: Sponsored,
    },
    {
      type: BIRTHDAYS,
      component: Container,
    },
    {
      type: DOCUMENT_CARD,
      component: DocumentCard,
    },
    {
      type: DOCUMENT_CARD_EXTERNAL,
      component: DocumentCard,
    },
    {
      type: INSIGHT,
      component: Insight,
    },
    {
      type: INTERACTION_ANNIVERSARY,
      component: Congratulation,
    },
    {
      type: INTERACTION_BIRTHDAY,
      component: Congratulation,
    },
    {
      type: TEAM_RECOGNITION,
      component: RecognitionEvent,
    },
  ]);

  const itemRef = useRef(null);
  const unvisitedItemsRef = useRef(unvisitedItems);
  const visitedItemsRef = useRef(visitedItems);
  const isSponsored = data?.data?.sponsored;

  React.Children.forEach(children, (element) => {
    // Verifies the object is a React element
    if (React.isValidElement(element)) {
      const { type: childrenType } = element.props;
      // Valid if another equal element already exists in the array
      if (!componentList.some((item) => item.type === childrenType)) {
        setComponentList([...componentList, { type: childrenType, component: () => element }]);
      }
    }
  });

  function getComponent() {
    const hasValidChildren =
      componentList.length > 0 && componentList.some((item) => item.type === type);

    if (hasValidChildren) {
      return componentList.find((item) => item.type === type).component;
    }
    return null;
  }

  function validImpression() {
    if (itemRef?.current && isDOMElement(itemRef?.current)) {
      if (
        isInViewport(itemRef?.current) &&
        data?.data?.sponsored &&
        !data?.data?.impressionStatus?.length &&
        !unvisitedItemsRef?.current?.some((u) => u?.id === data?.id) &&
        !visitedItemsRef?.current?.some((v) => v?.id === data?.id)
      ) {
        addUnvisitedOsFeedItem({
          id: data?.id,
          feedActivityId: data?.id,
          sourceId: data?.data?.post_id,
          statusDate: moment().format(),
        });
      }
    }
  }

  const Component = getComponent();

  useEffect(() => {
    if (isSponsored) {
      document.addEventListener('scroll', validImpression);
    }

    return () => {
      if (isSponsored) {
        document.removeEventListener('scroll', validImpression);
      }
    };
  }, [isSponsored]);

  useEffect(() => {
    if (unvisitedItems?.length) {
      unvisitedItemsRef.current = unvisitedItems;
    }
  }, [unvisitedItems]);

  useEffect(() => {
    if (visitedItems?.length) {
      visitedItemsRef.current = visitedItems;
    }
  }, [visitedItems]);

  return (
    <StarContext.Provider
      value={{
        ...data,
        ...props,
        ...rest,
        ownId,
        type,
      }}
    >
      {Component ? (
        <div className={className} ref={itemRef}>
          <Component />
        </div>
      ) : null}
    </StarContext.Provider>
  );
}

OSFeedItem.defaultProps = {
  data: {},
  type: CORE_VALUE,
};

OSFeedItem.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  props: PropTypes.object,
  type: PropTypes.oneOf([
    ANNIVERSARIES,
    BADGES,
    BETHERE_MOMENTS,
    BETHERE_POST,
    BIRTHDAYS,
    DOCUMENT_CARD,
    DOCUMENT_CARD_EXTERNAL,
    INSIGHT,
    INTERACTION_ANNIVERSARY,
    INTERACTION_BIRTHDAY,
    KUDOS,
    SUPERNOVA,
    TEAM_RECOGNITION,
  ]),
};

const mapStateToProps = (state) => ({
  unvisitedItems: selectUnvisitedItem(state),
  visitedItems: selectVisitedItem(state),
});

const actions = {
  addUnvisitedOsFeedItem,
};

export default connect(mapStateToProps, actions)(OSFeedItem);
