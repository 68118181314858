// @packages
import { map, pick, find } from 'lodash';

// @app
import { ENABLED_ANNIVERSARIES, ENABLED_BIRTHDAYS } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';

export const mapStateToProps = (state) => {
  const { profile, session, routing } = state;
  const { stars, communityConfigurations } = session;
  const { locationBeforeTransitions: { pathname } } = routing;
  let qualifiers;
  if (profile.receivedStarsDetail) {
    qualifiers = map(profile.receivedStarsDetail.coreValuesSummary || [], star => ({
      value: star.totalStars,
      color: star.star.backgroundColor,
    }));
    const kudos = find(stars, star => star.isKudos);
    if (kudos) {
      qualifiers = [
        ...qualifiers,
        {
          color: kudos.backgroundColor,
          value: profile.receivedStarsDetail.totalKudos,
        },
      ];
    }
  }
  return {
    withProfile: {
      enabledAnniversaries: getCommunityConfigValueBool(state, ENABLED_ANNIVERSARIES),
      enabledBirthdays: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
      user: profile.isProfile ? profile.result : session.user,
      sessionUserUid: session.user.uid,
      qualifiers,
      stats: pick(profile, [
        'fetchingEarB', 'fetchingRecS', 'fetchingSenS',
        'receivedS', 'sentS', 'remainingS', 'earnedB',
      ]),
      position: pick(profile, [
        'fetchingPositions', 'positionsAsSender', 'positionAsReceiver',
      ]),
      communityConfigurations,
      pathname,
    },
  };
};
