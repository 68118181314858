import {
  REQUEST_STARS_SUMMARY,
  REQUEST_STARS_SUMMARY_SUCCESS,
  REQUEST_STARS_SUMMARY_FAIL,
} from './actionTypes';

export function actionStarsSummary(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_STARS_SUMMARY,
  };
}

export function actionStarsSummarySuccess(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_STARS_SUMMARY_SUCCESS,
  };
}

export function actionStarsSummaryFail(id, payload) {
  return {
    id,
    payload,
    type: REQUEST_STARS_SUMMARY_FAIL,
  };
}
