// @packages
import React, { useEffect } from 'react';
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectResult, selectRequesting } from 'smu-utils/reduxRequests/selectors';
import PropTypes from 'prop-types';

// @app
import Insights from 'components/Insights';

// @own
import { REQUEST_ID_GET_INSIGHTS } from './constants';
import { apiGetInsights } from './api';

function InsightsContainer({ size }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectResult(state, REQUEST_ID_GET_INSIGHTS, 'result', {}));
  const isLoading = useSelector((state) => selectRequesting(state, REQUEST_ID_GET_INSIGHTS));
  const page = data?.page || 0;
  const result = data?.result || [];
  const returnedRecords = data?.total || 0;
  const showLoadMore = result?.length < returnedRecords;

  function interceptResponse(response) {
    return {
      ...response,
      result: [...result, ...response.result],
    };
  }

  function getInsights(params) {
    dispatch(
      actionRequestInit({
        api: apiGetInsights,
        id: REQUEST_ID_GET_INSIGHTS,
        interceptResponse,
        params: {
          params: {
            page,
            size,
            ...params,
          },
        },
      }),
    );
  }

  function handleLoadMore() {
    if (result?.length < returnedRecords) {
      getInsights({ page: page + 1 });
    }
  }

  useEffect(() => {
    getInsights();

    return () => {
      dispatch(actionRequestDestroy(REQUEST_ID_GET_INSIGHTS));
    };
  }, []);

  return (
    <Insights
      items={result}
      isLoading={isLoading}
      onLoadMore={handleLoadMore}
      showLoadMore={showLoadMore}
    />
  );
}

InsightsContainer.propTypes = {
  size: PropTypes.number,
};

InsightsContainer.defaultProps = {
  size: 9,
};

export default InsightsContainer;
