// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';

// @app
import GenericModal from 'components/GenericModal';

// @own
import { SMU_GENERIC_MODAL } from './constants';

function GenericModalContainer({
  close,
  onClose,
  renderModal,
  ...rest
}) {
  function handleClose() {
    onClose();
    close();
  }

  return renderModal ? (
    renderModal({
      ...rest,
      onClose: handleClose,
    })
  ) : (
    <GenericModal onClose={handleClose} {...rest} />
  );
}

GenericModalContainer.propTypes = {
  close: PropTypes.func.isRequired,
  renderModal: PropTypes.func,
  onClose: PropTypes.func,
};

GenericModalContainer.defaultProps = {
  onClose: () => undefined,
};

export default RootModal(GenericModalContainer, SMU_GENERIC_MODAL, {}, 'smu-generic-modal-content');
