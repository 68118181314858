// @packages
import React from 'react';

// @app
import CongratulationEvent from 'components/OS/Event/CongratulationEvent';
import { StarContext } from 'containers/OSFeed/OSFeedItem';

function Congratulation() {
  const { data, type } = React.useContext(StarContext);

  const initialData = {
    id: data?.id,
    type,
    user: {
      firstName: data?.user?.firstName,
      id: data?.user?.id,
      lastName: data?.user?.lastName,
      profileImageCode: data?.user?.imageCode,
      properties: {
        culturalLevelNumber: data?.user?.properties?.culturalLevelNumber,
      },
      uuid: data?.user?.uid,
    },
    reactions: [
      {
        count: data?.interaction?.likesCount,
        hasInteracted: data?.interaction?.liked,
        type: 'LIKE',
      },
      {
        count: data?.interaction?.commentsCount,
        hasInteracted: data?.interaction?.commented,
        type: 'COMMENT',
      },
    ],
  };

  return (
    <CongratulationEvent initialData={initialData} interactionId={data?.id} />
  );
}

export default Congratulation;
