import {
  CLEAN_RECOGNITION_FIELDS,
  LOAD_DEFAULT_RECOGNITION_FIELDS,
  SET_RECOGNITION_FIELDS,
} from './actionTypes';

export function cleanRecognitionFields(payload) {
  return {
    type: CLEAN_RECOGNITION_FIELDS,
    payload,
  };
}

export function loadDefaultRecognitionFields(payload) {
  return {
    type: LOAD_DEFAULT_RECOGNITION_FIELDS,
    payload,
  };
}

export function setRecognitionFields(payload) {
  return {
    type: SET_RECOGNITION_FIELDS,
    payload,
  };
}
