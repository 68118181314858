// @packages
import React from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import Checkbox from 'smu-ui-components/Checkbox';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

function DiscoverUsersUser({
  checked,
  email,
  firstName,
  id,
  imageCode,
  job,
  lastName,
  onChecked,
  onClick,
  uid,
}) {
  return (
    <div className="discover-users-user">
      <a
        className="discover-users-user__link"
        href={`/profile/${uid}`}
        rel="noopener"
        target="_blank"
      >
        <AvatarMedia
          className="discover-users-user__info"
          firstName={`${firstName} ${lastName}`}
          identification={uid}
          job={job}
          noHash
          onClick={onClick}
          profileImageCode={imageCode}
          type="medium"
          useLink={false}
        />
      </a>
      <div className="discover-users-user__email">{email}</div>
      <div className="discover-users-user__checkbox">
        <Checkbox
          checked={checked}
          color="violet"
          onClick={() => onChecked(id)}
          variant="rounded"
        />
      </div>
    </div>
  );
}

DiscoverUsersUser.propTypes = {
  checked: PropTypes.bool,
  email: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  imageCode: PropTypes.string,
  job: PropTypes.string,
  lastName: PropTypes.string,
  onChecked: PropTypes.func,
  onClick: PropTypes.func,
  uid: PropTypes.string.isRequired,
};

DiscoverUsersUser.defultProps = {
  checked: false,
  email: '',
  imageCode: '',
  job: '',
  onChecked: () => undefined,
  onClick: () => undefined,
};

export default DiscoverUsersUser;
