import {
  CLEAN_PROFILE,
} from 'containers/withProfile/actionTypes';

import {
  GET_CONNECTIONS,
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_FAIL,
} from './actionTypes';

const initialState = {
  fetching: false,
  nodes: [],
  edges: [],
  clusterBy: '',
  rolValue: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_CONNECTIONS:
      return {
        ...state,
        edges: [],
        clusterBy: payload.clusterBy,
        fetching: true,
        nodes: [],
        rolValue: payload.rolValue,
      };
    case GET_CONNECTIONS_SUCCESS:
      return {
        ...state,
        nodes: payload.nodes,
        edges: payload.edges,
        fetching: false,
      };
    case GET_CONNECTIONS_FAIL:
      return {
        ...state,
        edges: [],
        fetching: false,
        nodes: [],
      };
    case CLEAN_PROFILE:
      return initialState;
    default:
      return state;
  }
}

