import { takeEvery, call, put } from 'redux-saga/effects';

import { getBadgesSuccess, getBadgesFail } from './actions';
import { getBadges as getBadgesAPI } from './api';
import { GET_BADGES } from '../../containers/FeedProfile/actionTypes';

function* fetchBadgesWorker({ payload }) {
  try {
    const data = yield call(getBadgesAPI, { userId: payload });
    yield put(getBadgesSuccess(data));
  } catch (e) {
    yield put(getBadgesFail(e));
  }
}

export function* fetchBadgesWatcher() {
  yield takeEvery(GET_BADGES, fetchBadgesWorker);
}

export default {
  fetchBadgesWatcher,
};
