import {
  AUTH_SUCCESS,
  AUTH_WIDGET_FAIL,
  LOGOUT,
  REQUEST_AUTH,
} from './actionTypes';

export function authorize(payload = {}) {
  return {
    type: REQUEST_AUTH,
    payload,
  };
}

export function authorizeSuccess(payload) {
  return {
    type: AUTH_SUCCESS,
    payload,
  };
}

export function logout(payload) {
  return {
    payload,
    type: LOGOUT,
  };
}

export function authWidgetFail() {
  return {
    type: AUTH_WIDGET_FAIL,
  };
}
