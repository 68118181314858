// @packages
import React from 'react';

// @app
import AppLoader from 'containers/AppLoader';

const EmptyAppLoaderContainer = (props) => (
  <AppLoader {...props} showApp={false} />
);

export default EmptyAppLoaderContainer;
