import { defineMessages } from 'react-intl';

export default defineMessages({
  country: {
    id: 'filter.country',
    defaultMessage: 'Country',
  },
  city: {
    id: 'filter.city',
    defaultMessage: 'City',
  },
  office: {
    id: 'filter.office',
    defaultMessage: 'Office',
  },
  typeLeaderboard: {
    id: 'filter.typeLeaderboard',
    defaultMessage: 'Action',
  },
  organizationValue: {
    id: 'filter.organizationValue',
    defaultMessage: 'Type',
  },
});
