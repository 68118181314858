import { trackEvent } from 'smu-utils/gtm';

const event = (props) => {
  trackEvent({
    category: 'ModalInteractions',
    ...props,
  });
};

export const eventOnOpenModal = () =>
  event({
    action: 'openModal',
  });

export const eventOnCloseModal = () =>
  event({
    action: 'closeModal',
  });
