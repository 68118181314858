// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import Button from 'smu-ui-components/Button';

// @own
import './styles.scss';
// remove when the app will support device language
import messages from '../messages'; // eslint-disable-line
import {
  CHOOSE_LANGUAGE_EN,
  CHOOSE_LANGUAGE_ES,
  ENGLISH,
  ESPANOL,
  SPANISH,
} from '../constants';

function QuestionnaireLanguage({
  // remove when the app will support device language
  intl: { formatMessage }, // eslint-disable-line
  language: languageProps,
  onClick,
}) {
  const [language, setLanguage] = useState(languageProps);

  const text = () => ({
    ENGLISH: {
      buttonStart: 'Start',
    },
    SPANISH: {
      buttonStart: 'Comenzar',
    },
  })[language];

  const {
    buttonStart,
  } = text();

  return (
    <div className="questionnaire-language">
      <div className="questionnaire-language__header-container">
        <h4 className="questionnaire-language__header-title">
          {CHOOSE_LANGUAGE_EN}
        </h4>
        <div className="questionnaire-language__divider" />
        <h4 className="questionnaire-language__header-title">
          {CHOOSE_LANGUAGE_ES}
        </h4>
      </div>
      <div className="questionnaire-language__content">
        <h4
          className={cn(
            'questionnaire-language__content-text',
            { 'questionnaire-language__content-text--selected': language === ENGLISH },
          )}
          onClick={() => setLanguage(ENGLISH)}
          role="presentation"
        >
          {ENGLISH}
        </h4>
        <h4
          className={cn(
            'questionnaire-language__content-text',
            { 'questionnaire-language__content-text--selected': language === SPANISH },
          )}
          onClick={() => setLanguage(SPANISH)}
          role="presentation"
        >
          {ESPANOL}
        </h4>
      </div>
      <div className="questionnaire-language__button-container">
        <Button
          className={cn('questionnaire-language__button', { 'questionnaire-language__button--disabled': !language })}
          disabled={!language}
          noMargin
          noPadding
          onClick={() => onClick(language)}
        >
          {buttonStart}
        </Button>
      </div>
    </div>
  );
}

QuestionnaireLanguage.propTypes = {
  intl: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default injectIntl(QuestionnaireLanguage);
