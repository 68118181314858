import { defineMessages } from 'react-intl';

export default defineMessages({
  ContentTypeTitleTodayBirthdays: {
    id: 'ContentType.Title.TodayBirthdays',
    defaultMessage: "TODAY'S BIRTHDAYS!",
  },
  ContentTypeTitleYesterdayBirthdays: {
    id: 'ContentType.Title.YesterdayBirthdays',
    defaultMessage: "YESTERDAY'S BIRTHDAYS!",
  },
  ContentTypeSubtitleBirthdays: {
    id: 'ContentType.Subtitle.Birthdays',
    defaultMessage: 'Let’s celebrate life, wish them a Happy Birthday!',
  },
  ContentTypeTitleTodayAnniversaries: {
    id: 'ContentType.Title.TodayAnniversaries',
    defaultMessage: "TODAY'S ANNIVERSARIES!",
  },
  ContentTypeTitleYesterdayAnniversaries: {
    id: 'ContentType.Title.YesterdayAnniversaries',
    defaultMessage: "YESTERDAY'S ANNIVERSARIES!",
  },
  ContentTypeSubtitleAnniversaries: {
    id: 'ContentType.Subtitle.Anniversaries',
    defaultMessage: 'Celebrate another year in the organization, wish them a Happy Anniversary!!',
  },
  ContentTypeTitleCustomBirthdays: {
    id: 'ContentType.TitleCustom.Birthdays',
    defaultMessage: 'BIRTHDAYS! - {value}',
  },
  ContentTypeTitleCustomAnniversaries: {
    id: 'ContentType.TitleCustom.Anniversaries',
    defaultMessage: 'ANNIVERSARIES! - {value}',
  },
  ContentTypeTitleTeamRecognition: {
    id: 'ContentType.Title.TeamRecognition',
    defaultMessage: 'YOUR TEAM HAS BEEN DOING A GREAT JOB!',
  },
  ContentTypeSubtitleTeamRecognition: {
    id: 'ContentType.Subtitle.TeamRecognition',
    defaultMessage: 'Recognize them by their effort during the last 7 days',
  },
  ContentTypeButtonTeamRecognition: {
    id: 'ContentType.button.TeamRecognition',
    defaultMessage: 'Send Stars',
  },
  ContentTypeTextBethere: {
    id: 'ContentType.Bethere.Text',
    defaultMessage: 'See more pictures',
  },
  ContentTypeFooterBethere: {
    id: 'ContentType.Bethere.Footer',
    defaultMessage: 'From',
  },
  ContentTypePrefixSponsored: {
    id: 'ContentType.Prefix.Sponsored',
    defaultMessage: 'Sent by',
  },
  ContentTypeTooltipTeamRecognition: {
    id: 'ContentType.Tooltip.TeamRecognition',
    defaultMessage: 'See if your team has been recognized recently and celebrate their great work',
  },
  ContentTypeModalTitleTeamRecognition: {
    id: 'ContentType.ModalTitle.TeamRecognition',
    defaultMessage: 'Recognized Team Members',
  },
  ContentTypeTitleOtherTeamRecognition: {
    id: 'ContentType.Title.OtherTeamRecognition',
    defaultMessage: 'YOUR TEAM HAS BEEN DOING A GREAT JOB! - {value}',
  },
});
