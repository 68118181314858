import { omit } from 'lodash';
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from './../../api';

export function categoryValues(params) {
  const baseUrl = '/v2/stellar/categoryValues';
  const sendedParams = params.parentId
    ? params : omit(params, 'parentId');
  return apiInstance({
    url: addParams(baseUrl, {
      ...vo,
      ...times(),
      ...sendedParams,
    }),
    method: 'get',
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}
