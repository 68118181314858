import {
  GET_COMMUNITY_COOKIES_PREFERENCES_FAIL,
  GET_COMMUNITY_COOKIES_PREFERENCES_SUCCESS,
  GET_COMMUNITY_COOKIES_PREFERENCES,
  GET_LOCALE_RESOURCES_FAIL,
  GET_LOCALE_RESOURCES_SUCCESS,
  GET_LOCALE_RESOURCES,
  GET_LOOKUP_COOKIES_PAGE_FAIL,
  GET_LOOKUP_COOKIES_PAGE_SUCCESS,
  GET_LOOKUP_COOKIES_PAGE,
  GET_USER_COOKIES_PREFERENCES_FAIL,
  GET_USER_COOKIES_PREFERENCES_SUCCESS,
  GET_USER_COOKIES_PREFERENCES,
  POST_USER_COOKIES_PREFERENCES_FAIL,
  POST_USER_COOKIES_PREFERENCES_SUCCESS,
  POST_USER_COOKIES_PREFERENCES,
  SWITCH_USER_PREFERENCE,
} from './actionType';

const initialState = {
  communityAllowsCookies: undefined,
  cookiesResources: {},
  error: undefined,
  loading: false,
  loadingPage: false,
  userPreferences: undefined,
  token: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USER_COOKIES_PREFERENCES:
      return {
        ...state,
        loading: true,
      };
    case GET_COMMUNITY_COOKIES_PREFERENCES:
      return {
        ...state,
        loading: true,
        token: payload.token,
      };
    case GET_USER_COOKIES_PREFERENCES_SUCCESS:
      return {
        ...state,
        userPreferences: payload.userPreferences,
        loading: false,
      };
    case GET_USER_COOKIES_PREFERENCES_FAIL:
      return {
        ...state,
        error: payload.error,
        loading: false,
        userPreferences: undefined,
      };
    case GET_COMMUNITY_COOKIES_PREFERENCES_SUCCESS:
      return {
        ...state,
        communityAllowsCookies: payload.communityAllowsCookies,
      };
    case GET_COMMUNITY_COOKIES_PREFERENCES_FAIL:
      return {
        ...state,
        communityAllowsCookies: false,
        token: undefined,
      };
    case POST_USER_COOKIES_PREFERENCES:
      return {
        ...state,
        loading: true,
      };
    case POST_USER_COOKIES_PREFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case POST_USER_COOKIES_PREFERENCES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_LOCALE_RESOURCES:
      return {
        ...state,
        loadingPage: true,
        token: payload.token,
      };
    case GET_LOCALE_RESOURCES_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        cookiesResources: payload.cookiesResources,
      };
    case GET_LOCALE_RESOURCES_FAIL:
      return {
        ...state,
        cookiesResources: {},
      };
    case SWITCH_USER_PREFERENCE:
      return {
        ...state,
        userPreferences: payload.userPreferences,
      };
    case GET_LOOKUP_COOKIES_PAGE:
      return {
        ...state,
        loadingPage: true,
      };
    case GET_LOOKUP_COOKIES_PAGE_SUCCESS:
      return {
        ...state,
        loadingPage: false,
      };
    case GET_LOOKUP_COOKIES_PAGE_FAIL:
      return {
        ...state,
        loadingPage: false,
      };
    default:
      return state;
  }
}
