// @packages
import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import UserAvatarList from 'smu-ui-components/UserAvatarList';
import UserAvatar from 'smu-ui-components/UserAvatar';
import Media from 'react-media';
// @App
import CustomAvatarWrapper from 'components/CulturalIndicator/CustomAvatarWrapper';
// @own
import './styles.scss';

function RecognitionContent({ data, onClick, onHiddenAvatarClick }) {
  const UsesAvatarComponent = useCallback(
    size => (
      <UserAvatarList
        CustomAvatarWrapper={CustomAvatarWrapper}
        fullScreenImgOnClick={false}
        maxDisplay={size.size}
        onAvatarClick={onClick}
        onHiddenClick={onHiddenAvatarClick}
        size={50}
        users={data.users}
      />
    ),
    [data],
  );

  return (
    <div className="recognition-content">
      {data.users.length > 2 ? (
        <Media queries={{
          small: '(max-width: 400px)',
          medium: '(min-width: 401px) and (max-width: 720px)',
          large: '(min-width: 721px)',
        }}
        >
          {matches => (
            <Fragment>
              {matches.small && <UsesAvatarComponent size={3} />}
              {matches.medium && <UsesAvatarComponent size={5} />}
              {matches.large && <UsesAvatarComponent size={7} />}
            </Fragment>
          )}
        </Media>
      ) : (
        <Fragment>
          {data.users.map(user => (
            <span className="recognition-content__container">
              <UserAvatar
                height={50}
                key={user.id}
                name={user.firstName}
                onClick={() => onClick(user)}
                profileImageCode={user.profileImageCode || null}
                width={50}
              />
              <div className="recognition-content__container-text">
                <h5 className="recognition-content__text recognition-content__text--bold">{user.firstName}</h5>
                {user.job ? <h5 className="recognition-content__text">{user.job}</h5> : '-'}
              </div>
            </span>
          ))}
        </Fragment>
      )}
    </div>
  );
}

RecognitionContent.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  onHiddenAvatarClick: PropTypes.func,
};

export default RecognitionContent;
