// @packages
import React, { useContext, createContext } from 'react';
import firebase from 'firebase/app';

// @app
import { getConfig } from 'utils/firebase';

// @own
import { apiSubscribe, apiUnsubscribe } from './api';
import { deletePermissionToken, getPermissionToken } from './firebase';

const pushContext = createContext();

const config = getConfig();
if (firebase?.messaging?.isSupported()) {
  firebase.initializeApp(config);
}

function useProvidePush() {
  function onSubscribe(vapidKey, userId) {
    getPermissionToken({ vapidKey }).then((currentToken) => {
      if (currentToken) {
        apiSubscribe(currentToken, userId);
      }
    });
  }

  function onUnsubscribe() {
    deletePermissionToken();
    apiUnsubscribe();
  }

  return {
    onSubscribe,
    onUnsubscribe,
  };
}

export function ProvidePushNotifications({ children }) {
  const push = useProvidePush();
  return <pushContext.Provider value={push}>{children}</pushContext.Provider>;
}

export default function usePushNotifications() {
  return useContext(pushContext);
}
