import { defineMessages } from 'react-intl';

export default defineMessages({
  communicationsErrorStateReturnHome: {
    id: 'communications.errorState.returnHome',
    defaultMessage: 'Return to homepage',
  },
  communicationsErrorStateRetry: {
    id: 'communications.errorState.retry',
    defaultMessage: 'Retry',
  },
  communicationsEmptyStateTitle: {
    id: 'communications.emptyState.Title',
    defaultMessage: 'No content published yet',
  },
  communicationsEmptyStateDescription: {
    id: 'communications.emptyState.description',
    defaultMessage: "But don't worry, we are working on them. Soon you will be able to find out everything that is happening in your community.",
  },
});
