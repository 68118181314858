// @own
import {
  REQUEST_ADVANCED_USER_FILTERS,
  REQUEST_ADVANCED_USER_FILTERS_SUCCESS,
  REQUEST_ADVANCED_USER_FILTERS_FAIL,
} from './actionTypes';

const initialState = {
  error: null,
  requesting: false,
  result: undefined,
  success: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_ADVANCED_USER_FILTERS:
      return {
        ...state,
        requesting: true,
        success: false,
      };
    case REQUEST_ADVANCED_USER_FILTERS_SUCCESS:
      return {
        ...state,
        requesting: false,
        result: payload?.result,
        success: true,
      };
    case REQUEST_ADVANCED_USER_FILTERS_FAIL:
      return {
        ...state,
        error: payload?.error,
        requesting: false,
      };
    default:
      return state;
  }
}
