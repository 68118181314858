// @packages
import React, { useMemo } from 'react';
import Confirmation from 'smu-app-components/ConfirmationModalV2/Confirmation';
import ConfirmationModal from 'smu-app-components/ConfirmationModalV2';
import PropTypes from 'prop-types';
import cn from 'classnames';
import iconAlert from 'smu-app-components/ConfirmationModalV2/iconAlert.png';
import useTheme from 'smu-custom-hooks/useTheme';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// @app
import messages from 'containers/SendStar/messages';
import { ORIGIN, YOURSELF_BG_COLOR } from 'services/sendStarFlow/constants';
import { entitiesAmount } from 'containers/SendStar/helpers';
import { isYourself } from 'utils/yourself';
import { selectCommunityId } from 'containers/Authorization/selectors';

// @own
import './styles.scss';

const SendStarFlowConfirmation = ({
  className,
  comment,
  editId,
  entities,
  entitiesQuantity,
  intl: { formatMessage },
  isModal,
  onClose,
  orgId,
  origin,
  showCloseIcon,
  showConfetti,
  star,
  ...rest
}) => {
  const headerProps = {};
  const theme = useTheme();
  const lottieProps = useMemo(() => {
    const hashtagConfig = theme.getHashtagConfigByText(comment);
    if (hashtagConfig?.sendStarSuccessAnimationFileUrl) {
      return {
        delayToStart: 500,
        path: hashtagConfig?.sendStarSuccessAnimationFileUrl,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
    }

    return undefined;
  }, [comment]);

  const headerStyle = {
    backgroundColor: `${star && star.backgroundColor ? star.backgroundColor : ''}`,
  };

  if (isYourself(orgId, star?.name)) {
    headerProps.className = 'smu-send-star-flow-confirmation__yourself';
    headerStyle.backgroundColor = YOURSELF_BG_COLOR;
  }

  const confirmationProps = {
    className: cn(
      'smu-send-star-flow-confirmation',
      `smu-send-star-flow-confirmation__origin-${origin}`,
      className,
    ),
    entitiesQuantity,
    footerContent: (
      <div className="confirmation__footer-message-container">
        <img
          alt="iconAlert"
          className="confirmation__footer-message-icon"
          src={iconAlert}
        />
        <p className="confirmation__footer-message">
          {formatMessage(messages.successModalNote)}
        </p>
      </div>
    ),
    headerProps,
    headerStyle,
    message:
      entitiesAmount(entities) === 1 ? (
        editId ? (
          <FormattedMessage
            {...messages.successModalDescriptionEdit}
            values={{ user: entities[0].firstName }}
          />
        ) : (
          <FormattedMessage
            {...messages.successfulySentMultipleSingular}
          />
        )
      ) : (
        editId ? (
          <FormattedMessage
            {...messages.kudosSuccessModalDescriptionEdit}
            values={{ starName: star?.nameShowed }}
          />
        ) : (
          star && star.isKudos
            ? <FormattedMessage {...messages.successfulySentMultipleSingular} />
            : <FormattedMessage {...messages.successfulySentMultiple} />
        )
      ),
    recipients: entities,
    showClose: showCloseIcon,
    title: showConfetti ? formatMessage(messages.successModalTitleGol) : 'Hooray!',
    ...rest,
  };

  return isModal ? (
    <ConfirmationModal
      className="w-screen h-screen flex items-center justify-center fixed left-0 top-0"
      lottieProps={lottieProps}
      modalProps={confirmationProps}
      onModalClose={onClose}
      wrapperClassName="bg-white rounded-md overflow-hidden"
    />
  ) : (
    <Confirmation {...confirmationProps} />
  );
};

SendStarFlowConfirmation.defaultProps = {
  isModal: false,
  showCloseIcon: false,
};

SendStarFlowConfirmation.propTypes = {
  className: PropTypes.string,
  editId: PropTypes.number,
  entities: PropTypes.arrayOf(PropTypes.object),
  entitiesQuantity: PropTypes.number,
  intl: PropTypes.object,
  isModal: PropTypes.bool,
  onClose: PropTypes.func,
  orgId: PropTypes.number,
  origin: PropTypes.oneOf(ORIGIN),
  showCloseIcon: PropTypes.bool,
  star: PropTypes.object,
};

const mapStateToProps = ({ session, starFlow }) => ({
  comment: starFlow.comment,
  editId: starFlow.editId,
  entities: starFlow.entities,
  entitiesQuantity: starFlow.entitiesQuantity,
  orgId: selectCommunityId({ session }),
  showConfetti: starFlow.showConfetti,
  star: starFlow.star,
});

export default connect(mapStateToProps)(injectIntl(SendStarFlowConfirmation));
