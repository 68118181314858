// @packages
import { takeEvery, put, call } from 'redux-saga/effects';
import { setCookieLanguage } from 'smu-utils/language';

// @own
import { getLocales, getLanguages, changeLanguage } from './api';
import {
  REQUEST_LOCALES,
  REQUEST_LOCALES_SUCCESS,
  REQUEST_LOCALES_FAILED,
  REQUEST_LANGUAGES,
  REQUEST_LANGUAGES_SUCCESS,
  REQUEST_LANGUAGES_FAILED,
  CHANGE_LANGUAGE,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_FAILED,
} from './actionTypes';

function* localesWorker({ payload }) {
  try {
    let messages = payload?.data;

    if (!messages) {
      messages = yield call(getLocales, payload);
    }

    yield call(setCookieLanguage, payload.languageCode);

    yield put({
      type: REQUEST_LOCALES_SUCCESS,
      payload: {
        locale: payload.languageCode,
        messages,
      },
    });
  } catch (e) {
    yield put({
      type: REQUEST_LOCALES_FAILED,
      payload: e,
    });
  }
}

export function* localesWatcher() {
  yield takeEvery(REQUEST_LOCALES, localesWorker);
}

function* languagesWorker() {
  try {
    const data = yield call(getLanguages);
    yield put({
      type: REQUEST_LANGUAGES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: REQUEST_LANGUAGES_FAILED,
      payload: e,
    });
  }
}

export function* languagesWatcher() {
  yield takeEvery(REQUEST_LANGUAGES, languagesWorker);
}

function* changeLanguageWorker({ payload }) {
  try {
    yield call(changeLanguage, { locale: payload.languageCode });
    yield call(setCookieLanguage, payload.languageCode);

    yield put({
      type: CHANGE_LANGUAGE_SUCCESS,
      payload: {
        locale: payload.languageCode,
      },
    });

    if (payload?.reload) {
      yield window.location.reload();
    }
  } catch (e) {
    yield put({
      type: CHANGE_LANGUAGE_FAILED,
      payload: e,
    });
  }
}

export function* changeLanguageWatcher() {
  yield takeEvery(CHANGE_LANGUAGE, changeLanguageWorker);
}
