// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'smu-ui-components/Skeleton';
import cn from 'classnames';
import random from 'lodash/random';

// @own
import './styles.scss';

function SkeletonComment({ className }) {
  const [size] = useState({
    titleWidth: random(180, 220),
    descriptionWidth: random(30, 70),
  });

  return (
    <div className={cn('skeleton-comment', className)}>
      <header className="skeleton-comment__header">
        <Skeleton
          animation="wave"
          className="skeleton-comment__avatar"
          height={28}
          variant="circle"
          width={28}
        />
        <Skeleton
          animation="wave"
          className="skeleton-comment__title"
          height={14}
          width={size.titleWidth}
        />
        <Skeleton
          animation="wave"
          className="skeleton-comment__date"
          height={14}
          width={52}
        />
      </header>
      <Skeleton
        animation="wave"
        className="skeleton-comment__description"
        height={12}
        width={`${size.descriptionWidth}%`}
      />
    </div>
  );
}

SkeletonComment.propTypes = {
  className: PropTypes.string,
};

export default SkeletonComment;
