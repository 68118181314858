// @packages
import React from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';

// @app
import GenericModal from 'components/GenericModal';
import InsightDetails from 'containers/InsightDetails';

// @own
import { INSIGHT_DETAILS_MODAL } from './constants';

const InsightDetailsModal = ({ closeModal }) => (
  <GenericModal
    onClose={closeModal}
    showFooter={false}
    showHeader={false}
  >
    <InsightDetails isModal />
  </GenericModal>
);

InsightDetailsModal.propTypes = {
  closeModal: PropTypes.func,
};

const styles = {
  maxWidth: '1100px',
  width: '100%',
};

export default RootModal(InsightDetailsModal, INSIGHT_DETAILS_MODAL, styles);
