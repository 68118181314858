// @app
import { paths } from 'utils/routes';

// @own
import ActivityCommentsLayout from './ActivityCommentsLayout';

export default () => ({
  path: paths.activityComments(),
  component: ActivityCommentsLayout,
});
