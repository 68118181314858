import {
  GET_BADGES,
  GET_BADGES_SUCCESS,
  GET_BADGES_FAIL,
  CLEAN_BADGES,
} from './actionTypes';

export function getBadges(userId) {
  return {
    type: GET_BADGES,
    payload: userId,
  };
}

export function getBadgesSuccess(badges) {
  return {
    type: GET_BADGES_SUCCESS,
    payload: badges,
  };
}

export function getBadgesFail(error) {
  return {
    type: GET_BADGES_FAIL,
    payload: error,
  };
}

export function cleanBadges() {
  return {
    type: CLEAN_BADGES,
  };
}
