import {
  REQUEST_BETHERE_PROPERTIES,
  REQUEST_BETHERE_PROPERTIES_SUCCESS,
  REQUEST_BETHERE_PROPERTIES_FAIL,
} from './actionTypes';

export function actionBethereProperties(communityId) {
  return {
    type: REQUEST_BETHERE_PROPERTIES,
    payload: {
      communityId,
    },
  };
}

export function actionBetherePropertiesSuccess(result) {
  return {
    type: REQUEST_BETHERE_PROPERTIES_SUCCESS,
    payload: {
      result,
    },
  };
}

export function actionBetherePropertiesFail(error) {
  return {
    type: REQUEST_BETHERE_PROPERTIES_FAIL,
    payload: {
      error,
    },
  };
}
