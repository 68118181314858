import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function getHighlightedStar(userId) {
  return apiInstance({
    url: `/v2/stellar/user/${userId}/star/favorite`,
    method: 'get',
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}
