import { merge, includes } from 'lodash';
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { filterObject, mapObject } from 'utils/object';
import { addParams, times, vo } from './../../api';

export function requestAvailableFilters() {
  return apiInstance({
    url: addParams('/v2/stellar/userFields', { ...vo, ...times() }),
    method: 'get',
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

const cleanFields = fields => filterObject(fields, value => value !== '');

export function requestAdvancedSearch({ fields, labels, page }) {
  const labelFields = ['country', 'city', 'office'];
  const data = JSON.stringify(merge(
    mapObject(
      cleanFields(fields),
      (v, k) => (
        includes(labelFields, k) ? labels[k] : v
      ),
    ),
    { page, size: 20 },
  ));
  return apiInstance({
    url: addParams('/v2/stellar/advancedUserSearch', { ...vo, ...times() }),
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}
