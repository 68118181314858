import {
  INITIALIZE_FEED,
  REBOOT_FEED,
  STARS_UPDATE,
  FEED_APPLY_FILTERS,
} from './actionTypes';

export function initializeFeed(payload) {
  return {
    type: INITIALIZE_FEED,
    payload,
  };
}

export function rebootFeed(payload = {}) {
  return {
    type: REBOOT_FEED,
    payload,
  };
}

export function starsUpdate(payload = {}) {
  return {
    type: STARS_UPDATE,
    payload,
  };
}

export function applyFilters(payload = {}) {
  return {
    type: FEED_APPLY_FILTERS,
    payload,
  };
}
