// @app
import { paths } from 'utils/routes';

// @own
import Layout from './Layout';

export default () => ({
  component: Layout,
  onEnter: () => {
    window.scrollTo(0, 0);
  },
  path: paths.myTeam(),
});
