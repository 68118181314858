// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { buildImageUrl } from 'smu-utils/image';

const Header = ({ imageCode, name }) => (
  <div className="flex flex-col items-center">
    <div
      className={cn(
        'h-10 w-full bg-titanWhite bg-no-repeat bg-bottom',
        "bg-[url('/assets/img/feed_card_header_waves.svg')]",
      )}
    />
    <div className="flex flex-col items-center space-y-2 -mt-6">
      <img
        alt={name}
        className="h-16 w-16"
        src={buildImageUrl({ code: imageCode, height: 64, width: 64 })}
      />
      <div className="font-rubik font-semibold text-base text-gray-dune text-center px-4">
        {name}
      </div>
    </div>
  </div>
);

Header.propTypes = {
  imageCode: PropTypes.string,
  name: PropTypes.string,
};

export default Header;
