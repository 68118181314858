import React from 'react';
import cn from 'classnames';
import Icon from 'smu-ui-components/IconV2';

function EmojiWidget({ className, onClick }) {
  return (
    <Icon
      className={cn('emoji-widget__icon', className)}
      color="grey"
      icon="emoji"
      onClick={onClick}
      size="small"
    />
  );
}

export default EmojiWidget;
