import {
  ADD_LIFT_STAR_ENTITY,
  CHANGE_SELECTED_STAR,
  GET_REMAINING_STARS,
  GET_REMAINING_STARS_FAIL,
  GET_REMAINING_STARS_SUCCESS,
  LIFT_STAR,
  SET_EMOJI,
  STAR_FLOW_CLEAN_PLUS_RECEIVED,
  STAR_FLOW_CLOSE_SUGGEST_SENT_STAR,
  STAR_FLOW_COMMENT,
  STAR_FLOW_COMMENT_COUNTER,
  STAR_FLOW_DEC_OFFSET,
  STAR_FLOW_DEC_OFFSET_CC,
  STAR_FLOW_EDIT,
  STAR_FLOW_ENTITIES,
  STAR_FLOW_ENTITIES_CC,
  STAR_FLOW_ENTITY,
  STAR_FLOW_ENTITY_CC,
  STAR_FLOW_ERROR,
  STAR_FLOW_GET_MENTION,
  STAR_FLOW_GET_STAR_BY_ID,
  STAR_FLOW_GET_STAR_BY_ID_FAIL,
  STAR_FLOW_GET_STAR_BY_ID_SUCCESS,
  STAR_FLOW_INC_OFFSET,
  STAR_FLOW_INC_OFFSET_CC,
  STAR_FLOW_INITIAL,
  STAR_FLOW_NONE,
  STAR_FLOW_OPEN_SUGGEST_SENT_STAR,
  STAR_FLOW_PLUS_RECEIVED,
  STAR_FLOW_REMOVE_ENTITY,
  STAR_FLOW_REMOVE_ENTITY_CC,
  STAR_FLOW_REMOVE_IMAGE,
  STAR_FLOW_SEND,
  STAR_FLOW_SENT,
  STAR_FLOW_SENT_WAITING,
  STAR_FLOW_SERVER_ERROR,
  STAR_FLOW_TOGGLE_PICKER,
  STAR_FLOW_UPLOAD_IMAGE,
} from './actionTypes';

export function addEntities(entities) {
  return {
    type: STAR_FLOW_ENTITIES,
    payload: { entities },
  };
}

export function addEntity(entity) {
  return {
    type: STAR_FLOW_ENTITY,
    payload: entity,
  };
}

export function addEntityCC(entity) {
  return {
    type: STAR_FLOW_ENTITY_CC,
    payload: entity,
  };
}

export function addEntitiesCC(entities) {
  return {
    type: STAR_FLOW_ENTITIES_CC,
    payload: { entities },
  };
}

export function addLiftStarEntity(id) {
  return {
    type: ADD_LIFT_STAR_ENTITY,
    payload: { id },
  };
}

export function changeSelectedStar(star) {
  return {
    type: CHANGE_SELECTED_STAR,
    payload: { star },
  };
}

export function cleanPlusReceived() {
  return {
    type: STAR_FLOW_CLEAN_PLUS_RECEIVED,
  };
}

export function closesuggestSendStar() {
  return {
    type: STAR_FLOW_CLOSE_SUGGEST_SENT_STAR,
  };
}

export function decOffset() {
  return {
    type: STAR_FLOW_DEC_OFFSET,
  };
}

export function decOffsetCC() {
  return {
    type: STAR_FLOW_DEC_OFFSET_CC,
  };
}

export function editStar(parameters) {
  return {
    type: STAR_FLOW_EDIT,
    payload: parameters,
  };
}

export function getMentioned(payload) {
  return {
    type: STAR_FLOW_GET_MENTION,
    payload,
  };
}

export function getRemainingStars() {
  return {
    type: GET_REMAINING_STARS,
  };
}

export function getRemainingStarsFail(error) {
  return {
    type: GET_REMAINING_STARS_FAIL,
    payload: {
      error,
    },
  };
}

export function getRemainingStarsSuccess(payload) {
  return {
    type: GET_REMAINING_STARS_SUCCESS,
    payload,
  };
}

export function incOffset() {
  return {
    type: STAR_FLOW_INC_OFFSET,
  };
}

export function incOffsetCC() {
  return {
    type: STAR_FLOW_INC_OFFSET_CC,
  };
}

export function liftStar(starId, starValues, toUser) {
  return {
    type: LIFT_STAR,
    payload: { starId, starValues, toUser },
  };
}

export function plusToReceivedStars() {
  return {
    type: STAR_FLOW_PLUS_RECEIVED,
  };
}

export function removeEntity(entityId) {
  return {
    type: STAR_FLOW_REMOVE_ENTITY,
    payload: entityId,
  };
}

export function removeEntityCC(entityCCId) {
  return {
    type: STAR_FLOW_REMOVE_ENTITY_CC,
    payload: entityCCId,
  };
}

export function removeImage(payload) {
  return {
    type: STAR_FLOW_REMOVE_IMAGE,
    payload,
  };
}

export function sendStar(parameters) {
  return {
    type: STAR_FLOW_SEND,
    payload: parameters,
  };
}

export function sendStarError(message) {
  return {
    type: STAR_FLOW_ERROR,
    payload: message,
  };
}

export function sendStarServerError(code) {
  return {
    type: STAR_FLOW_SERVER_ERROR,
    payload: code,
  };
}

export function sendStarSuccess(showConfetti) {
  return {
    type: STAR_FLOW_SENT,
    payload: showConfetti,
  };
}

export function sentWaiting() {
  return {
    type: STAR_FLOW_SENT_WAITING,
  };
}

export function setInitial(payload) {
  return {
    type: STAR_FLOW_INITIAL,
    payload,
  };
}

export function setComment(payload) {
  return {
    type: STAR_FLOW_COMMENT,
    payload,
  };
}

export function setCommentLength(payload) {
  return {
    type: STAR_FLOW_COMMENT_COUNTER,
    payload,
  };
}

export function setEmoji(emoji) {
  return {
    type: SET_EMOJI,
    payload: emoji,
  };
}

export function setNone() {
  return {
    type: STAR_FLOW_NONE,
  };
}

export function suggestSendStar() {
  return {
    type: STAR_FLOW_OPEN_SUGGEST_SENT_STAR,
  };
}

export function togglePicker() {
  return {
    type: STAR_FLOW_TOGGLE_PICKER,
  };
}

export function uploadImage(payload) {
  return {
    type: STAR_FLOW_UPLOAD_IMAGE,
    payload,
  };
}

export function actionGetStarById({ starId }) {
  return {
    type: STAR_FLOW_GET_STAR_BY_ID,
    payload: {
      starId,
    },
  };
}

export function actionGetStarByIdFail(error) {
  return {
    type: STAR_FLOW_GET_STAR_BY_ID_FAIL,
    payload: {
      error,
    },
  };
}

export function actionGetStarByIdSuccess(payload) {
  return {
    type: STAR_FLOW_GET_STAR_BY_ID_SUCCESS,
    payload: {
      comment: payload?.comment,
      entities: payload?.entities,
      entitiesQuantity: payload?.entitiesQuantity,
      imageCode: payload?.imageCode,
      listMentions: payload?.listMentions,
      star: payload?.star,
    },
  };
}
