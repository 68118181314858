// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import Container from 'smu-layout-components/Container';

// @app
import Leaderboard from 'containers/LeaderboardV2';
import TopStarPanel from 'containers/TopStarsPanel';
import UserProfile from 'containers/UserProfile';
import { getProfile } from 'containers/withProfile/actions';

// @own
import './styles.scss';

const Home = ({ profile, user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!profile) {
      dispatch(
        getProfile({
          uid: user?.uid,
        }),
      );
    }
  }, [user]);

  return (
    <div>
      <Container centered compact className="leaderboard-layout ui-components">
        <div className="ui-components suite-grid">
          <div className="ui-components space-y-4 suite-column suite-column-desktop-size-4 suite-column-desktop-sm-size-4 hide-from-tablet">
            <UserProfile showStars showPanel />
            <TopStarPanel />
          </div>
          <div className="ui-components suite-column suite-column-desktop-size-8 suite-column-desktop-sm-size-8 suite-column-tablet-size-12">
            <Leaderboard />
          </div>
        </div>
      </Container>
    </div>
  );
};

Home.propTypes = {
  profile: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  profile: state?.profile.result,
  user: state?.session.user,
});

export default connect(mapStateToProps)(Home);
