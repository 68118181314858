// @Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { chunk } from 'lodash';
import Icon from 'smu-ui-components/Icon';
// @App
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import GroupList from 'components/GroupList';
import Paginator from 'smu-ui-components/DataTable/Paginator';
import RootModal from 'smu-app-components/RootModalV2';
// @Own
import './styles.scss';

const RecognitionExpanded = ({
  close,
  data,
  messages,
  pageSize,
}) => {
  const [page, setPage] = useState(0);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const chunkedResults = chunk(data, pageSize);
    setMembers(chunkedResults[page]);
  }, []);

  const handleAvatarClick = () => {
    close();
  };

  const handlePageChange = (page) => {
    const chunkedResults = chunk(data, pageSize);
    setPage(page);
    setMembers(chunkedResults[page]);
  };

  const renderCloseIcon = () => (
    <Icon
      className="recognition-expanded__icon"
      color="black"
      icon="close"
      onClick={() => close()}
      size="semi-medium"
      type="base"
    />
  );

  const renderModalTitle = () => (
    <div className="recognition-expanded__title">
      {messages.title}
    </div>
  );

  const renderFooter = () => {
    const totalMembers = data.length;
    return totalMembers > pageSize
      ? (
        <div className="recognition-expanded__paginator">
          <Paginator
            customMessages={{
              first: messages.paginator.first,
              last: messages.paginator.last,
            }}
            onPageChange={handlePageChange}
            pageNumber={page + 1}
            pageSize={pageSize}
            totalItems={totalMembers}
          />
        </div>
      ) : (
        null
      );
  };

  return (
    <div className="recognition-expanded">
      <GroupList
        footer={renderFooter()}
        leftTitle={renderModalTitle()}
        rightTitle={renderCloseIcon()}
      >
        <div className="recognition-expanded__users-wrapper">
          {members.map(member => (
            <div className="recognition-expanded__user">
              <AvatarMedia
                className="recognition-expanded__user-avatar"
                firstName={member.fullName || `${member.firstName} ${member.lastName}`}
                identification={member.uid}
                job={member.job || ''}
                key={member.id}
                lastName=""
                noHash
                onClick={handleAvatarClick}
                profileImageCode={member.profileImageCode}
              />
            </div>
          ))}
        </div>
      </GroupList>
    </div>
  );
};

RecognitionExpanded.defaultProps = {
  data: [],
  pageSize: 10,
};

RecognitionExpanded.propTypes = {
  messages: PropTypes.object,
  close: PropTypes.func,
  data: PropTypes.array,
  pageSize: PropTypes.number,
};

export default RootModal(RecognitionExpanded, 'RECOGNITION_TEAM_MODAL');
