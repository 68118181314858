// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

function QuestionnaireResultsOption({
  className,
  label,
  enabledMobile,
  value,
}) {
  return (
    <div
      className={cn(
        'questionnaire-results-option',
        {
          'questionnaire-results-option--mobile': enabledMobile,
        },
        className,
      )}
    >
      <div className="questionnaire-results-option__label">{label}</div>
      <div className="questionnaire-results-option__value">{value}</div>
    </div>
  );
}

QuestionnaireResultsOption.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  enabledMobile: PropTypes.bool,
  value: PropTypes.any,
};

QuestionnaireResultsOption.defaultProps = {
  className: '',
  label: '',
  enabledMobile: true,
  value: undefined,
};

export default QuestionnaireResultsOption;
