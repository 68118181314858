// @app
import { paths } from 'utils/routes';

// @own
import Leaderboard from './LeaderboardLayout';

export default () => ({
  path: paths.leaderboard(),
  component: Leaderboard,
});
