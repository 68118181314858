import {
  SEND_EXPERIENCE,
  SEND_EXPERIENCE_SUCCESS,
  SEND_EXPERIENCE_FAILED,
} from './actionTypes';

const initialState = {
  sending: false,
  error: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SEND_EXPERIENCE:
      return { ...state, sending: true, error: '' };
    case SEND_EXPERIENCE_SUCCESS:
      return { ...state, sending: false, error: '' };
    case SEND_EXPERIENCE_FAILED:
      return { ...state, sending: false, error: payload };
    default:
      return state;
  }
}
