// @app
import { paths } from 'utils/routes';

// @own
import RedirectLayout from './Layout';

export default {
  component: RedirectLayout,
  path: paths.redirect(),
};
