// @packages
import { errorHandler } from 'utils/errorHandler';

// @own
import { apiGetCommunityConfigurations } from 'smu-services/os-member/api/v1/community/preferences/configurations/api';

export function getCommunityPreferences() {
  return apiGetCommunityConfigurations()
    .then((data) => data)
    .catch(errorHandler);
}
