// @packages
import React, { useEffect } from 'react';
import Icon from 'smu-ui-components/IconV2';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectRequesting, selectResult } from 'smu-utils/reduxRequests/selectors';

// @app
import {
  selectLanguageCode,
  selectOrganizationName,
} from 'containers/Authorization/selectors';

// @own
import SkeletonHeader from './SkeletonHeader';
import { apiHeaderDesign } from './api';
import { COMMUNICATIONS_HEADER_DESIGN } from './constants';
import messages from './messages';
import './styles.scss';

const CommunicationsHeader = ({
  actionRequestInit,
  data,
  intl: { formatMessage },
  languageCode,
  loading,
}) => {
  const getHeaderDesign = () => {
    actionRequestInit({
      api: apiHeaderDesign,
      id: COMMUNICATIONS_HEADER_DESIGN,
      params: { languageCode },
    });
  };

  useEffect(() => {
    getHeaderDesign();
  }, []);

  if (loading) {
    return (<SkeletonHeader />);
  }

  return (
    <div className="communications-header shadow-base rounded-lg">
      <div className="communications-header__details">
        <div className="communications-header__text">
          <div className="communications-header__title">
            {data?.title}
          </div>
          <div className="communications-header__description">
            {data?.description}
          </div>
          {data?.url && (
            <Link
              className="communications-header__link"
              href={data?.url}
              legacy={false}
            >
              <span className="communications-header__link--text">
                {formatMessage(messages.communicationsHeaderLink)}
              </span>
              <Icon
                className="communications-header__link--icon"
                icon="finger_print"
                size={16}
              />
            </Link>
          )}
        </div>
      </div>
      <div className="communications-header__image-container">
        <div className="communications-header__image" style={{ backgroundImage: `url(${data?.images?.largeImage})` }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  communityName: selectOrganizationName(state),
  data: selectResult(state, COMMUNICATIONS_HEADER_DESIGN),
  languageCode: selectLanguageCode(state),
  loading: selectRequesting(state, COMMUNICATIONS_HEADER_DESIGN),
});

CommunicationsHeader.propTypes = {
  communityName: PropTypes.string,
  data: PropTypes.object,
  languageCode: PropTypes.string,
  loading: PropTypes.bool,
};

export default connect(mapStateToProps, { actionRequestInit })(injectIntl(CommunicationsHeader));
