// @packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import extend from 'lodash/extend';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import merge from 'lodash/merge';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { EditorState } from 'draft-js';
import { FormattedMessage, injectIntl } from 'react-intl';
import { actionRequestInit, actionRequestDestroy } from 'smu-utils/reduxRequests/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { selectResult } from 'smu-utils/reduxRequests/selectors';
import { withRouter } from 'react-router';

// @app
import {
  DEFAULT_STAR_COLOR,
  HASHTAG,
  MAX_COMMENT_CHARACTERS,
  MIN_CHARACTERS_TO_SEARCH,
  ORIGIN,
  STATUS,
  USER,
  getRequestIdSuggestionsHashtags,
  getRequestIdSuggestionsUsers,
} from 'services/sendStarFlow/constants';
import * as componentActions from 'services/sendStarFlow/actions';
import EmojiWidget from 'components/SendStar/Widgets/EmojiWidget';
import PhotoWidget from 'components/SendStar/Widgets/PhotoWidget';
import SendStarComponent from 'components/SendStar';
import SendStarSuggestions from 'components/SendStar/Suggestions';
import { ENABLED_STAR_SUGGESTION_TEXT } from 'services/communityConfigs/constants';
import { actionCommunityMembers, actionCommunityMembersClean } from 'services/communityMembers/actions';
import { actions as routeActions } from 'utils/routes';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { getLocationFields, initialLeaderboardInformation } from 'containers/Authorization';
import { getStarsValues, selectFetchingStarById, selectStarFlowStatus } from 'services/sendStarFlow/selectors';
import { requestSendStarProfile, removeSendStarProfile } from 'services/sendStarProfile/actions';
import { searchUsersOrHashtags } from 'services/sendStarFlow/api';
import { selectCommunityMembersRequesting, selectCommunityMembersResult } from 'services/communityMembers/selectors';
import { selectSendStarProfile, selectSendStarProfileFetching } from 'services/sendStarProfile/selectors';
import { trackEvent } from 'utils/gtm';

// @own
import messages from './messages';
import {
  entitiesAmount,
  getCriteriaUserIds,
  getEditorStateByComment,
  getWriteForMeProps,
  normalizeComment,
  normalizeEntity,
  normalizeMembers,
  normalizeMentions,
} from './helpers';

const SendStar = ({
  actionCommunityMembers,
  actionCommunityMembersClean,
  actionRequestDestroy,
  actionRequestInit,
  addEntities,
  addEntitiesCC,
  addEntity,
  addEntityCC,
  addToastMessage,
  amount,
  buttonColor,
  changeSelectedStar,
  className,
  comment,
  communityMembersRequesting,
  communityMembersResult,
  coreValueId,
  editId,
  editStar,
  emoji,
  entities,
  entitiesCC,
  fetchingSendProfile,
  fetchingStarById,
  hasStarsToSend,
  headerColor,
  image,
  intl: { formatMessage },
  isEditing,
  leaderboardFields,
  listMentions,
  location: {
    query: {
      coreValueId: locationCoreValueId,
      criteria,
      uid,
      valueId: locationValueId,
    },
  },
  mentionsEnabled,
  onCancel,
  onClose,
  onEdit,
  onSend,
  origin,
  paramProfile,
  pathname,
  remainingCounter,
  remainingStars: {
    hasKudosRemaining,
    hasUnlimitedStars,
    remainingStarCounter = {},
  },
  remainingStarsRequest,
  removeEntity,
  removeEntityCC,
  removeSendStarProfile,
  requestSendStarProfile,
  router,
  sendStar,
  setEmoji,
  showWriteForMe,
  singleEntities,
  star,
  stars,
  status,
  suggestedHashtags,
  suggestedUsers,
  togglePicker,
  uploadImage,
  uploadingImage,
  ...rest
}) => {
  const normalizedMembers = normalizeMembers(communityMembersResult);
  const normalizedComment = normalizeComment(router?.location?.query?.comment || comment);
  const [withGPT, setWithGPT] = useState(false);
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const [inputText, setInputText] = useState({
    mentions: [],
    value: '',
  });
  const allowMultipleUsers = !(star?.minimum === 1 && star?.maximum === 1 && star?.id);
  const canSendStar = !(new RegExp('^\\s+$').test(inputText?.value))
    && inputText?.value
    && amount >= star?.minimum
    && (star && star.id);
  const disabledCarbonCopySearch = entitiesCC && entitiesCC.length > 4;
  const entitiesQuantity = entitiesAmount(entities);
  const oneEntity = entities && entities.length === 1;
  const showRemainingStarCounter = !hasUnlimitedStars && !isEditing;
  const showUserClarification = star
    && star.id
    && entitiesQuantity > 1
    && !star.isKudos;
  const customValueId = coreValueId || locationCoreValueId || locationValueId;
  const paramStar = stars?.find(star => star.id === Number(customValueId));
  const onChangeWriteForMe = (value) => {
    setEditor(value);
    setWithGPT(true);
  };
  const writeForMeProps = getWriteForMeProps({
    baseText: inputText?.value,
    disabled: !star?.id || !entitiesQuantity,
    entities,
    onChange: onChangeWriteForMe,
    star,
  });
  const isLoading =
    fetchingStarById ||
    remainingStarsRequest?.requesting ||
    communityMembersRequesting;

  const getSearchMessages = () => ({
    placeholder: star?.isKudos ?
      formatMessage(messages.searchPlaceholderKudos) :
      formatMessage(messages.searchPlaceholder),
    messageNoResult: formatMessage(messages.noResults),
    noRemainingStars: hasKudosRemaining ?
      formatMessage(messages.noRemainingStarsKudos) :
      formatMessage(messages.noRemainingStars),
  });

  const getCarbonCopySearchMessages = () => ({
    placeholder: formatMessage(messages.searchPlaceholderCarbonCopy),
    messageNoResult: formatMessage(messages.noResults),
  });

  const getSendButtonText = () => {
    let text;

    if (isEditing) {
      text = (<FormattedMessage {...messages.confirmEdit} />);
    } else if (star && star.isKudos) {
      text = (<FormattedMessage {...messages.confirm} />);
    } else if (entitiesQuantity > 1) {
      text = (
        <FormattedMessage
          {...messages.confirmMultiplePlural}
          values={{
            n: entitiesQuantity,
          }}
        />
      );
    } else {
      text = entitiesQuantity === 1
        ? (<FormattedMessage {...messages.confirmMultipleSingular} />)
        : (<FormattedMessage {...messages.confirm} />);
    }

    return text;
  };

  const texts = () => ({
    cancel: formatMessage(messages.cancel),
    chooseRecognition: formatMessage(messages.chooseRecognition),
    close: formatMessage(messages.close),
    multipleUsersClarification: formatMessage(messages.multipleUsersClarification),
    noStarAvailable: formatMessage(messages.noStarAvailable),
    searchCCMessages: getCarbonCopySearchMessages(),
    searchMessages: getSearchMessages(),
    send: getSendButtonText(),
    sendStars: formatMessage(messages.sendStars),
    sendStarsServerError: formatMessage(messages.sendStarsServerError),
    sendStarsServerErrorHelper: formatMessage(messages.sendStarsServerErrorHelper),
    what: formatMessage(messages.what),
    who: formatMessage(messages.who),
    why: formatMessage(messages.why),
  });

  const getUsers = (text) => {
    actionRequestInit({
      id: getRequestIdSuggestionsUsers(),
      api: searchUsersOrHashtags,
      params: {
        filterType: USER,
        text,
      },
    });
  };

  const getHashtags = (text) => {
    actionRequestInit({
      id: getRequestIdSuggestionsHashtags(),
      api: searchUsersOrHashtags,
      params: {
        filterType: HASHTAG,
        text,
      },
    });
  };

  const onSearchUsers = ({ value }) => {
    if (value && value.length > MIN_CHARACTERS_TO_SEARCH) {
      getUsers(value);
    }
  };

  const onSearchHashtags = ({ value }) => {
    if (value && value.length > MIN_CHARACTERS_TO_SEARCH) {
      getHashtags(value);
    }
  };

  const onInputChange = (comment) => {
    setInputText(comment);
  };

  const onAddEntities = (users) => {
    if (users?.length) {
      const kudosStar = find(stars, star => star?.isKudos);
      const maxMembers = users.slice(0, remainingCounter);
      const mergeEntities = uniqBy([...entities, ...users], 'id');
      const showAllMembers =
        star?.isKudos ||
        hasUnlimitedStars ||
        remainingCounter >= mergeEntities?.length;

      if (showAllMembers) {
        addEntities(mergeEntities);
      } else if (kudosStar && hasKudosRemaining) {
        changeSelectedStar(kudosStar);
        addEntities(mergeEntities);
      } else if (maxMembers?.length) {
        addEntities(maxMembers);
      }
    }
  };

  const onClickEntity = (data) => {
    let entities = [];
    if (Array.isArray(data)) {
      entities = data.map(normalizeEntity);
    } else if (data) {
      entities.push(normalizeEntity(data));
    }
    if (entities?.length) onAddEntities(entities);
  };

  const onClickEntityCC = (data) => {
    if (data) {
      const result = Array.isArray(data) ? data : [data];
      const filtered = result?.filter(r => !entities?.some(e => e.id === r.id));
      const merged = [...(entitiesCC || []), ...filtered];
      const uniqEntities = uniqBy(merged, 'id');
      addEntitiesCC(uniqEntities);
    }
  };

  const onRemoveEntity = (entityId) => {
    if (entityId) {
      removeEntity(entityId);
    }
  };

  const shouldDisabledSearch = () => {
    if (star && star?.isKudos) {
      return (hasKudosRemaining
        ? !hasKudosRemaining
        : true
      );
    }
    return (hasUnlimitedStars
      ? !hasUnlimitedStars
      : remainingCounter <= 0 || entitiesAmount(entities) < 0
    );
  };

  const getRemainingStarsCount = () => {
    const remaining = remainingStarCounter?.remaining || 0;
    if (star?.isKudos) {
      return remaining - 1;
    }
    const total = remaining - entitiesQuantity;
    return total > 0 ? total : 0;
  };

  const addEmoji = (val) => {
    if (val.native) {
      togglePicker();
      setEmoji(val.native);
    }
  };

  const send = () => {
    if (inputText?.mentions?.length) {
      trackEvent({
        category: 'Hashtags',
        action: 'Hashtag in Star Description',
      });
    }
    trackEvent({
      action: 'success',
      category: 'send_star',
      description: inputText?.value,
      id: star?.id,
      int_type: withGPT ? 'with_GPT' : '',
      type: star?.nameShowed,
    });
    sendStar({
      comment: inputText?.value,
      entities: singleEntities,
      entitiesCC,
      leaderboardFields,
      onSuccess: onSend,
      star,
    });
    if (paramProfile) {
      removeSendStarProfile();
    }
  };

  const saveEdit = () => editStar({
    comment: inputText?.value,
    editId,
    imageCode: image ? image.code : '',
    leaderboardFields,
    onSuccess: onEdit,
    star,
  });

  const getDescriptionProps = () => {
    const parsers = [
      {
        entry: SendStarSuggestions,
        mentionTrigger: '@',
        onSearchInputChange: onSearchUsers,
        suggestions: suggestedUsers,
      },
      {
        entry: SendStarSuggestions,
        mentionTrigger: '#',
        onSearchInputChange: onSearchHashtags,
        suggestions: suggestedHashtags,
        supportWhitespace: true,
      },
    ];
    const contentState = editor?.getCurrentContent();
    const plainText = contentState?.getPlainText();
    return ({
      actions: [
        <EmojiWidget className="send-star__emoji-widget" onClick={togglePicker} />,
        <PhotoWidget
          className="send-star__photo-widget"
          disabled={uploadingImage}
          image={image}
          onChange={ev => uploadImage({
            file: ev.target.files[0],
            errorMessage: formatMessage(messages.uploadImageError),
          })}
        />,
      ],
      autoResize: true,
      editor,
      key: `description-${plainText?.length || 0}`,
      maxCharacters: MAX_COMMENT_CHARACTERS,
      onInputChange,
      onValueChanged: () => setEmoji(undefined),
      parsers: mentionsEnabled ? parsers : [],
      placeholder: formatMessage(messages.tellReason),
      value: emoji,
      variant: 'area',
      withCounter: true,
    });
  };

  useEffect(() => {
    if (comment) {
      setInputText({
        mentions: normalizeMentions(listMentions),
        value: comment,
      });
    }
  }, [comment]);

  useEffect(() =>
    () => {
      actionRequestDestroy(getRequestIdSuggestionsHashtags());
      actionRequestDestroy(getRequestIdSuggestionsUsers());
      if (uid) {
        removeSendStarProfile();
      }
    }, []);

  useEffect(() => {
    if (uid && !fetchingSendProfile) {
      requestSendStarProfile({ uid });
    }
  }, []);

  useEffect(() => {
    if (paramProfile) {
      const buildProfile = {
        id: paramProfile?.id,
        identification: paramProfile?.identification,
        name: paramProfile?.name || `${paramProfile?.firstName} ${paramProfile?.lastName}`,
        profileImageCode: paramProfile?.profileImageCode,
        uid: paramProfile?.uid,
      };
      addEntity(buildProfile);
    }
    if (paramStar) {
      changeSelectedStar(paramStar);
    }
  }, [paramProfile]);

  useEffect(() => {
    const users = getCriteriaUserIds(criteria);
    if (!isEditing && users?.length) {
      actionCommunityMembers({ params: { members: users.join() } });
    }
    return () => users?.length && actionCommunityMembersClean();
  }, [criteria]);

  useEffect(() => {
    if (normalizedComment) {
      const state = getEditorStateByComment(normalizedComment, listMentions);
      setEditor(state);
    }
  }, [normalizedComment]);

  useEffect(() => {
    onAddEntities(normalizedMembers);
  }, [normalizedMembers?.length]);

  return (
    <SendStarComponent
      addEmoji={addEmoji}
      allowMultipleUsers={allowMultipleUsers}
      buttonColor={buttonColor || star?.backgroundColor}
      className={className}
      descriptionProps={getDescriptionProps()}
      disabledCarbonCopySearch={disabledCarbonCopySearch}
      disabledSearch={shouldDisabledSearch()}
      disabledSendStarButton={!canSendStar
        || status === STATUS.SENDING
        || status === STATUS.WAITING
      }
      showWriteForMe={showWriteForMe}
      entities={entities}
      entitiesCC={entitiesCC}
      entitiesQuantity={entitiesQuantity}
      hasStarsToSend={hasStarsToSend}
      headerColor={headerColor || star?.backgroundColor || DEFAULT_STAR_COLOR}
      image={image}
      isEditing={isEditing}
      isLoading={isLoading}
      onCancel={onCancel}
      onClickEntity={onClickEntity}
      onClickEntityCC={onClickEntityCC}
      onClose={onClose}
      onRemoveEntity={onRemoveEntity}
      onRemoveEntityCC={removeEntityCC}
      onSend={isEditing ? saveEdit : (canSendStar ? send : undefined)}
      oneEntity={oneEntity}
      origin={origin}
      remainingStarsRequest={remainingStarsRequest}
      remainingstars={getRemainingStarsCount()}
      showRemainingStarCounter={showRemainingStarCounter}
      showUserClarification={showUserClarification}
      star={star}
      stars={stars}
      texts={texts()}
      togglePicker={togglePicker}
      totalStars={remainingStarCounter.total}
      writeForMeProps={writeForMeProps}
      {...rest}
    />
  );
};

const starType = PropTypes.shape({
  backgroundColor: PropTypes.string,
  imageCode: PropTypes.string,
  name: PropTypes.string,
  suggestedHashtags: [],
  suggestedUsers: [],
});

SendStar.propTypes = {
  actionCommunityMembers: PropTypes.func.isRequired,
  actionCommunityMembersClean: PropTypes.func.isRequired,
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  addEntities: PropTypes.func.isRequired,
  addEntitiesCC: PropTypes.func.isRequired,
  addEntity: PropTypes.func.isRequired,
  addEntityCC: PropTypes.func.isRequired,
  addToastMessage: PropTypes.func,
  amount: PropTypes.number.isRequired,
  buttonColor: PropTypes.string,
  changeSelectedStar: PropTypes.func,
  className: PropTypes.string,
  comment: PropTypes.string,
  communityMembersRequesting: PropTypes.bool,
  communityMembersResult: PropTypes.array,
  coreValueId: PropTypes.number,
  editId: PropTypes.number,
  editStar: PropTypes.func.isRequired,
  emoji: PropTypes.string,
  entities: PropTypes.array,
  entitiesCC: PropTypes.array,
  fetchingStarById: PropTypes.bool,
  hasStarsToSend: PropTypes.bool,
  headerColor: PropTypes.string,
  image: PropTypes.object,
  intl: PropTypes.object,
  isEditing: PropTypes.bool,
  leaderboardFields: PropTypes.object.isRequired,
  listMentions: PropTypes.array,
  mentionsEnabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onSend: PropTypes.func,
  origin: PropTypes.oneOf(ORIGIN),
  pathname: PropTypes.string,
  remainingCounter: PropTypes.number,
  remainingStars: PropTypes.object,
  remainingStarsRequest: PropTypes.object,
  removeEntity: PropTypes.func.isRequired,
  removeEntityCC: PropTypes.func.isRequired,
  sendStar: PropTypes.func.isRequired,
  setEmoji: PropTypes.func.isRequired,
  showWriteForMe: PropTypes.bool,
  singleEntities: PropTypes.array,
  star: starType,
  stars: PropTypes.array.isRequired,
  status: PropTypes.oneOf([
    STATUS.INITIAL,
    STATUS.LAST,
    STATUS.NONE,
    STATUS.SENDING,
    STATUS.WAITING,
  ]),
  suggestedHashtags: PropTypes.array,
  suggestedUsers: PropTypes.array,
  togglePicker: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  uploadingImage: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    routing,
    session,
    starFlow,
    starFlowSearch,
  } = state;

  const { locationBeforeTransitions: { pathname } } = routing;

  const {
    coreValueId,
    editId,
    entities,
    entitiesCC,
    hasStarsToSend,
    image,
    remainingCounter,
    remainingStars,
    remainingStarsRequest,
    star,
    uploadingImage,
  } = starFlow;

  const isEditing = editId !== undefined;

  let entitiesCCTemp = map(entitiesCC, e => extend({}, e, { name: `${e.firstName} ${e.lastName}` }));
  const singleEntities = flatMap(cloneDeep(entities), e => (
    e.users
      ? filter(e.users, user => user.checked)
      : [e]
  ));
  const amount = sum(map(singleEntities, e => e.amount || 1));
  const stars = getStarsValues(state);

  return {
    ...starFlow,
    amount,
    communityMembersRequesting: selectCommunityMembersRequesting(state),
    communityMembersResult: selectCommunityMembersResult(state),
    coreValueId,
    entities: isEmpty(entities) ? [] : entities,
    entitiesCC: isEmpty(entitiesCCTemp) ? undefined : entitiesCCTemp,
    fetchingSendProfile: selectSendStarProfileFetching(state),
    fetchingStarById: selectFetchingStarById(state),
    hasStarsToSend,
    image,
    isEditing,
    leaderboardFields: merge(
      {},
      initialLeaderboardInformation(session).fields,
      getLocationFields(session),
    ),
    mentionsEnabled: session.communityConfigurations.mentionsEnabled,
    paramProfile: selectSendStarProfile(state),
    pathname,
    remainingCounter,
    remainingStars,
    remainingStarsRequest,
    searchQuery: starFlowSearch.query,
    showWriteForMe: getCommunityConfigValueBool(state, ENABLED_STAR_SUGGESTION_TEXT),
    singleEntities,
    star,
    stars,
    status: selectStarFlowStatus(state),
    suggestedHashtags: selectResult(state, getRequestIdSuggestionsHashtags()) || [],
    suggestedUsers: selectResult(state, getRequestIdSuggestionsUsers()) || [],
    uploadingImage,
  };
};

const actions = {
  ...componentActions,
  actionCommunityMembers,
  actionCommunityMembersClean,
  actionRequestDestroy,
  actionRequestInit,
  addToastMessage,
  removeSendStarProfile,
  requestSendStarProfile,
  showAdvancedSearch: query => routeActions.advancedSearch({}, { query }),
};

export default connect(mapStateToProps, actions)(
  withRouter(injectIntl(SendStar)),
);
