import { mockMemberApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function getFiltersByUses(params) {
  return mockMemberApiInstance({
    url: 'v1/community/filters',
    params,
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}

export function getFiltersValues(data) {
  return mockMemberApiInstance({
    url: 'v1/community/fields/values',
    method: 'post',
    data,
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}
