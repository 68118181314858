// @packages
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import cn from 'classnames';
import { get } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import Panel from 'smu-ui-components/Panel';
import Button from 'smu-ui-components/Button';
import withRootModal from 'smu-app-components/RootModalV2/withRootModal';
import {
  selectRequesting,
  selectResult,
  selectResultProp,
} from 'smu-utils/reduxRequests/selectors';
import { actionRequestDestroy, actionRequestInit } from 'smu-utils/reduxRequests/actions';
import Segment from 'smu-ui-components/Segment';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { trackEvent } from 'smu-utils/gtm';

// @app
import ChillOutModal from 'components/Widgets/ChillOutModal';
import { WIDGET_CHILLOUT_MODAL } from 'components/Widgets/ChillOutModal/constants';
import ChillOutEvents from 'components/Widgets/ChillOutEvents';
import ChillOutError from 'components/Widgets/ChillOutError';

// @own
import {
  ANALYTICS_EVENTS,
  CHILLOUT_STATUS,
  EMPTY_TEXT_BUTTON,
  EMPTY_TEXT_DESCRIPTION,
  REQUEST_ID_GET_CHILLOUT,
  REQUEST_ID_GET_CHILLOUT_EVENT,
  TEXT_BUTTON,
  TEXT_DESCRIPTION_DEFAULT,
  TEXT_DESCRIPTION_ERROR,
  TEXT_TITLE,
  TEXT_TITLE_ERROR,
} from './constants';
import { apiGetChillOut, apiGetChillOutById } from './api';
import { normaliceEvents } from './utils';
import withConfig from './withConfig';
import './styles.scss';

function ChillOut({
  actionRequestDestroy,
  actionRequestInit,
  location,
  openModal,
  redirectRequesting,
  redirectUrl,
  requesting,
  result,
  router,
  savedPreferences,
  timerComingSoon,
  timerJoinEnabled,
  timerUpdateDate,
  ...rest
}) {
  const chilloutId = get(location, 'query.chilloutId');
  const [initialChilloutId] = useState(chilloutId);
  const [showError, setShowError] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const prevRedirectUrl = usePrevious(redirectUrl);
  const member = get(result, 'member', []).map(opt => ({ ...opt, matched: true }));
  const events = [...member];
  const customEvents = normaliceEvents(events, currentDate, timerComingSoon, timerJoinEnabled);
  const showDefaultContent = !showError && !savedPreferences;
  const showEmpty = !showError && !showDefaultContent && !customEvents.length;
  const showEvents = !showError && !showDefaultContent && !showEmpty && !!customEvents.length;
  const { open } = get(router, 'location.query', {});
  const { location: { pathname } } = router;

  const {
    onCancelModal,
    onCloseModal,
    onJoinEvent,
    onNextEvent,
    onOpenModalUrl,
    onPrevEvent,
    onSaveModal,
    onSetupPreferences,
    onUpdatePreferences,
  } = ANALYTICS_EVENTS.reduce((acc, cur) => {
    const { event, ...track } = cur;
    acc[event] = () => {
      trackEvent({ ...track });
      rest[event]();
    };
    return acc;
  }, {});

  function getChillout() {
    if (requesting) {
      return;
    }
    if (showError) {
      setShowError(false);
    }
    actionRequestInit({ api: apiGetChillOut, id: REQUEST_ID_GET_CHILLOUT, params: { size: 10 } });
  }

  function getChillOutById(id) {
    actionRequestInit({
      api: apiGetChillOutById,
      id: REQUEST_ID_GET_CHILLOUT_EVENT,
      params: { id },
    });
  }

  function resetUrl() {
    if (open) {
      router.push(pathname);
    }
  }

  function handleOnJoinEvent(e, id) {
    if (id) {
      e.preventDefault();
      getChillOutById(id);
    }
    onJoinEvent(e);
  }

  function handleCloseModal() {
    resetUrl();
    onCloseModal();
  }

  function handleCancelModal() {
    resetUrl();
    onCancelModal();
  }

  function handleSubmitSuccess() {
    resetUrl();
    getChillout();
  }

  function handleOpenModal() {
    openModal({
      modalType: WIDGET_CHILLOUT_MODAL,
      modalProps: {
        onCancel: handleCancelModal,
        onClose: handleCloseModal,
        onSave: onSaveModal,
        onSubmitSuccess: handleSubmitSuccess,
      },
    });
  }

  function handleSetPreferences() {
    if (showDefaultContent) {
      onSetupPreferences();
    } else {
      onUpdatePreferences();
    }
    router.push(`${pathname}?open=${CHILLOUT_STATUS}`);
  }

  useEffect(() => {
    if (chilloutId) {
      getChillOutById(chilloutId);
    }
  }, [chilloutId]);

  useEffect(() => {
    if (prevRedirectUrl !== redirectUrl && redirectUrl) {
      if (initialChilloutId) {
        window.location.replace(redirectUrl);
      } else {
        window.open(redirectUrl, '_blank');
        actionRequestDestroy(REQUEST_ID_GET_CHILLOUT_EVENT);
      }
    }
  }, [redirectUrl]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(moment());
    }, timerUpdateDate);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (open === CHILLOUT_STATUS) {
      onOpenModalUrl();
    }
  }, []);

  useEffect(() => {
    if (open === CHILLOUT_STATUS) {
      handleOpenModal();
    }
  }, [open]);

  return (
    <Fragment>
      <ChillOutModal />
      {showEvents && (
        <ChillOutEvents
          events={customEvents}
          isRedirecting={redirectRequesting}
          loading={requesting}
          onClickShowPreferences={() => handleSetPreferences()}
          onEndEvent={getChillout}
          onJoinEvent={handleOnJoinEvent}
          onNextEvent={onNextEvent}
          onPrevEvent={onPrevEvent}
        />
      )}
      {showError && <ChillOutError title={TEXT_TITLE_ERROR} subTitle={TEXT_DESCRIPTION_ERROR} />}
      {(showEmpty || showDefaultContent) && (
        <Panel
          className={cn('suite-widget-chillout', {
            'suite-widget-chillout--default': showDefaultContent,
            'suite-widget-chillout--empty': showEmpty,
            'suite-widget-chillout--loading': requesting,
          })}
          subtitle={showEmpty ? EMPTY_TEXT_DESCRIPTION : TEXT_DESCRIPTION_DEFAULT}
          title={TEXT_TITLE}
        >
          <Segment
            className="suite-widget-chillout__segment"
            loading={requesting}
            loadingType="starmeup"
            spinnerHeight={35}
            spinnerWidth={35}
            withChildren={false}
          >
            <Button
              className="suite-widget-chillout__button"
              color="black"
              noMargin
              onClick={() => handleSetPreferences()}
              primary
              size="medium"
            >
              {showEmpty ? EMPTY_TEXT_BUTTON : TEXT_BUTTON}
            </Button>
          </Segment>
        </Panel>
      )}
    </Fragment>
  );
}

ChillOut.defaultProps = {
  onCancelModal: () => {},
  onCloseModal: () => {},
  onJoinEvent: () => {},
  onNextEvent: () => {},
  onOpenModalUrl: () => {},
  onPrevEvent: () => {},
  onSaveModal: () => {},
  onSetupPreferences: () => {},
  onUpdatePreferences: () => {},
  timerComingSoon: 30,
  timerJoinEnabled: 5,
  timerUpdateDate: 5000,
};

ChillOut.propTypes = {
  actionRequestDestroy: PropTypes.func.isRequired,
  actionRequestInit: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  onCancelModal: PropTypes.func,
  onCloseModal: PropTypes.func,
  onJoinEvent: PropTypes.func,
  onNextEvent: PropTypes.func,
  onOpenModalUrl: PropTypes.func,
  onPrevEvent: PropTypes.func,
  onSaveModal: PropTypes.func,
  onSetupPreferences: PropTypes.func,
  onUpdatePreferences: PropTypes.func,
  openModal: PropTypes.func.isRequired,
  redirectRequesting: PropTypes.bool,
  redirectUrl: PropTypes.string,
  requesting: PropTypes.bool,
  result: PropTypes.object,
  router: PropTypes.object.isRequired,
  savedPreferences: PropTypes.bool,
  timerComingSoon: PropTypes.number,
  timerJoinEnabled: PropTypes.number,
  timerUpdateDate: PropTypes.number,
};

const mapStateToProps = state => ({
  redirectRequesting: selectRequesting(state, REQUEST_ID_GET_CHILLOUT_EVENT),
  redirectUrl: selectResultProp(state, REQUEST_ID_GET_CHILLOUT_EVENT, 'meetUrl'),
  requesting: selectRequesting(state, REQUEST_ID_GET_CHILLOUT),
  result: selectResult(state, REQUEST_ID_GET_CHILLOUT),
  savedPreferences: selectResultProp(state, REQUEST_ID_GET_CHILLOUT, 'savedPreferences', false),
});

const ChillOutWithRedux = connect(mapStateToProps, {
  actionRequestDestroy,
  actionRequestInit,
})(withRouter(ChillOut));
const ChillOutWithModal = withRootModal(ChillOutWithRedux);
const ChillOutWithConfig = withConfig(ChillOutWithModal);

export default ChillOutWithConfig;
