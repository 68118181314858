import cloneDeep from 'lodash/cloneDeep';

import {
  ACTION_CONGRATULATION_ADD_COMMENT,
  ACTION_CONGRATULATION_ADD_ITEM,
  ACTION_CONGRATULATION_ADD_LIKE,
  ACTION_CONGRATULATION_REMOVE_LIKE,
  ACTION_CONGRATULATION_UPDATE_COMMENTS,
} from './actionTypes';

const initialState = {};

const merge = (state, payload) => {
  const id = payload?.id;
  const prevState = cloneDeep(state);
  const prevData = cloneDeep(state?.[id]);
  const data = cloneDeep(payload?.data);

  return {
    ...prevState,
    [id]: {
      ...prevData,
      ...data,
    },
  };
};

const mergeReaction = (state, id, status, type) => {
  const data = state?.[id];
  const indexLike = data?.reactions?.findIndex((r) => r?.type === type);

  if (indexLike > -1) {
    data.reactions[indexLike].hasInteracted = status;
    data.reactions[indexLike].count += status ? 1 : -1;
  }

  return merge(state, {
    id,
    data,
  });
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_CONGRATULATION_ADD_COMMENT:
      return {
        ...mergeReaction(state, payload?.id, true, 'COMMENT'),
      };
    case ACTION_CONGRATULATION_ADD_ITEM:
      return {
        ...merge(state, payload),
      };
    case ACTION_CONGRATULATION_ADD_LIKE:
      return {
        ...mergeReaction(state, payload?.id, true, 'LIKE'),
      };
    case ACTION_CONGRATULATION_REMOVE_LIKE:
      return {
        ...mergeReaction(state, payload?.id, false, 'LIKE'),
      };
    case ACTION_CONGRATULATION_UPDATE_COMMENTS:
      return {
        ...merge(state, payload),
      };
    default:
      return state;
  }
}
