// @app
import { errorHandler } from 'utils/errorHandler';
import { apiInstance } from 'utils/requestHelper';

export function apiGetUsersByKudosStarId({ id, page = 0, size = 20 }) {
  return apiInstance({
    url: '/v2/stellar/usersByKudosStarId',
    method: 'get',
    params: {
      page,
      size,
      id,
    },
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
