// @packages
import moment from 'moment';
import { getUserLanguage } from 'smu-utils/language';

export default (date, locale = getUserLanguage()) => {
  const format = locale === 'pl' ? 'D.MM.YY' : 'MMM D, YYYY hh:mm:ss A';
  const formattedDate = locale === 'pl' ? moment(date).format('D.MM.YY') : date;
  const parsedDate = moment(formattedDate).add(moment().utcOffset(), 'minutes');
  const translatedDate = moment(parsedDate, format);
  return translatedDate;
};

export const parseDateUTC = (date, locale = getUserLanguage()) => {
  const format = locale === 'pl' ? '' : 'MMM D, YYYY hh:mm:ss A';
  const formattedDate = locale === 'pl' ? moment(date).format('') : date;
  const parsedDate = moment(formattedDate).utc();
  const translatedDate = moment(parsedDate, format);
  return translatedDate;
};
