// @packages
import React from 'react';
import Maintenance from 'smu-ui-components/Maintenance';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @own
import messages from './messages';

const WidgetErrorContainer = ({ intl: { formatMessage }, ...rest }) => (
  <Maintenance
    imageSrc="/assets/img/astronaut-error.png"
    showButton
    texts={{
      goBack: formatMessage(messages.goBack),
      subtitle: formatMessage(messages.subtitle),
      title: formatMessage(messages.title),
    }}
    {...rest}
  />
);

WidgetErrorContainer.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(WidgetErrorContainer);
