// @app
import { bethereApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { isValidURL } from 'utils/url';

// @own
import { TYPE_PHOTO } from './constants';

export function apiGetPosts({ communityId, page = 0, size = 30 }) {
  const params = {
    page,
    postTypeExcluded: 'COMMUNITY',
    size,
  };
  return bethereApiInstance({
    url: `organization/${communityId}/posts`,
    params,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiGetProperties({ communityId }) {
  return bethereApiInstance({
    url: `organization/${communityId}/properties`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export async function getImages(params) {
  try {
    const data = await apiGetPosts(params);
    const posts = (data?.posts || []).filter(
      (post) => post.post_type === TYPE_PHOTO && !post.sponsored,
    );

    const invalidPath = posts.find((post) => {
      const image = post?.post_image_info?.info;
      return !isValidURL(image?.original?.path);
    });

    let dataProperties;
    if (invalidPath) {
      dataProperties = await apiGetProperties(params);
    }

    return {
      posts,
      properties: dataProperties?.properties,
    };
  } catch (error) {
    return errorHandler(error);
  }
}
