import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const GroupList = ({
  rightTitle, rightSubtitle, leftTitle, leftSubtitle, children, footer,
}) => (
  <div className="group-list">
    <div className="group-list__header">
      <div className="group-list__column group-list__column--left">
        {leftTitle}
        {leftSubtitle && (
          <div className="group-list__column--time">
            {leftSubtitle}
          </div>)}
      </div>
      <div className="group-list__column group-list__column--right">
        {rightTitle}
        {rightSubtitle && (
          <div className="group-list__column--time">
            {rightSubtitle}
          </div>)}
      </div>
    </div>
    <div className="group-list__content">
      {children}
    </div>
    {footer && (
      <div className="group-list__footer">
        {footer}
      </div>)}
  </div>
);

GroupList.propTypes = {
  rightTitle: PropTypes.node,
  rightSubtitle: PropTypes.node,
  leftTitle: PropTypes.node,
  leftSubtitle: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
};

export default GroupList;
