import { stringify } from 'querystring';
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, formData, times, vo } from './../../api';

export function sendExperience(data) {
  return apiInstance({
    url: addParams('/v2/stellar/reportBug', { ...vo, ...times() }),
    ...formData,
    method: 'post',
    data: stringify(data),
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
