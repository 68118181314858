// @packages
import isEmpty from 'lodash/isEmpty';
// @app
import { takepartApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

// @own
import { LIST_OPTION_TYPE, LISTS_RESULTS_PAGE_SIZE } from './constants';

export const apiGetSurveyTextList = ({
  page,
  questionnaireId,
  questionId,
  reportType,
}) =>
  takepartApiInstance({
    params: {
      optionType: LIST_OPTION_TYPE,
      page,
      size: LISTS_RESULTS_PAGE_SIZE,
    },
    headers: { 'Content-Type': 'application/json' },
    url: `/api/v2/community/me/questionnaires/${questionnaireId}/reports/${reportType}/questions/${questionId}`,
  })
    .then(({ data }) => {
      if (!isEmpty(data?.result)) {
        return data;
      }
      throw new Error({
        message: 'Results are not available',
      });
    })
    .catch((error) => errorHandler(error, undefined, false));
