import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from './../../api';

export function getTrends({ size }) {
  const baseUrl = '/v2/stellar/trendingTopics';
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times(), size }),
    method: 'get',
  })
    .then(({ data }) => (
      data.result.map(({
        hId, type, hashtag, uses,
      }) => ({
        isSponsored: type === 'SPONSORED',
        id: hId,
        hashtag,
        uses,
      }))
    )).catch(errorHandler);
}
