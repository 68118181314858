// @packages
import { takeEvery, put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { isMobile } from 'react-device-detect';
import { getToken, setItem } from 'smu-utils/storage';

// @own
import { add as addToast } from 'smu-app-components/ToastNotifications/actions';
import {
  DECLINED,
  ACCEPTED,
  DECISION_ISSUED,
  DECISION_PENDING,
} from 'smu-app-components/BannerCookiesConsent/constants';
import { DEFAULT_TOAST_TIMEOUT } from 'utils/constants';
import {
  GET_COMMUNITY_COOKIES_PREFERENCES,
  GET_LOCALE_RESOURCES,
  GET_LOOKUP_COOKIES_PAGE,
  GET_USER_COOKIES_PREFERENCES,
  POST_USER_COOKIES_PREFERENCES,
} from './actionType';
import {
  getLocaleResources,
  storeUserPreferences,
  getCommunityPreferences,
  getUserPreferences,
} from './api';
import {
  actionGetCommunityPreferences,
  actionGetCommunityPreferencesFail,
  actionGetCommunityPreferencesSuccess,
  actionGetLocaleResources,
  actionGetLocaleResourcesFail,
  actionGetLocaleResourcesSuccess,
  actionGetUserPreferences,
  actionGetUserPreferencesFail,
  actionGetUserPreferencesSuccess,
  actionPostUserPreferencesFail,
  actionPostUserPreferencesSuccess,
  getLookupCookiesPageFail,
  getLookupCookiesPageSuccess,
} from './actions';
import { userPreferencesAccepted, userPreferencesDeclined } from './data';
import {
  DELAY_RELOCATION,
  ERROR_REQUEST_DEFAULT_MSG,
  LANDING_URL,
  PREFERENCES_SUCCESS_DEFAULT_MSG,
} from './constants';
import { lookUpAuth } from '../Authorization/api';
import { parseJwtToObject } from '../../utils/object';

const parseUserCookies = data => Object.entries(data).map(item => ({
  type: item[0],
  enabled: item[1],
}));

const parsePostUserCookies = (data) => {
  let newObjec = {
    cookiesConsent: DECISION_ISSUED,
  };
  Object.values(data).forEach((item) => { newObjec = { ...newObjec, [item.type]: item.enabled }; });
  return newObjec;
};

const redirectToLanding = () => {
  window.location.href = LANDING_URL;
};

const redirectToContinueLink = async (_continue) => {
  try {
    window.location.href = window.atob(_continue);
  } catch (error) {
    throw new Error();
  }
};

function* getPreferencesWorker({ payload }) {
  try {
    let { token } = payload;
    if (!token) {
      token = getToken();
    }
    const { data, status } = yield call(getUserPreferences, token);
    if (status === 200) {
      const cookiesResponse = data.cookies && JSON.parse(data.cookies);
      const cookiesConsent = cookiesResponse ? cookiesResponse.cookiesConsent : null;
      const ACCEPT = 'ACCEPT';
      const DECLINE = 'DECLINE';
      let userPreferences = {};

      if (cookiesConsent === DECLINED || cookiesConsent === DECLINE) {
        userPreferences = parseUserCookies(userPreferencesDeclined);
      }

      if (cookiesConsent === DECISION_ISSUED) {
        userPreferences = parseUserCookies(cookiesResponse);
      }

      if (cookiesConsent === DECISION_PENDING
          || cookiesConsent === ACCEPTED
          || cookiesConsent === ACCEPT
        ) {
        userPreferences = parseUserCookies(userPreferencesAccepted);
      }

      yield put(actionGetUserPreferencesSuccess({ userPreferences }));
    } else {
      yield put(actionGetUserPreferencesFail({ error: ERROR_REQUEST_DEFAULT_MSG }));
    }
  } catch (error) {
    yield put(addToast({
      message: error.message || ERROR_REQUEST_DEFAULT_MSG,
      timeout: DEFAULT_TOAST_TIMEOUT,
    }));
    yield put(actionGetUserPreferencesFail({ error: error.message }));
  }
}

function* getPreferencesWatcher() {
  yield takeEvery(GET_USER_COOKIES_PREFERENCES, getPreferencesWorker);
}


function* postPreferencesWorker({ payload }) {
  const {
    _continue,
    cookiesResources,
    token,
    userPreferences,
  } = payload;

  const { successfulLoad, failuredLoad } = cookiesResources;
  try {
    const data = parsePostUserCookies(userPreferences);
    const result = yield call(storeUserPreferences, data, token);

    if (result.status === 200) {
      yield put(actionPostUserPreferencesSuccess(result));
      yield put(addToast({
        message: successfulLoad || PREFERENCES_SUCCESS_DEFAULT_MSG,
        timeout: DEFAULT_TOAST_TIMEOUT,
        onClose: () => (!isMobile && redirectToContinueLink(_continue)),
      }));
      if (_continue && !isMobile) {
        yield delay(DELAY_RELOCATION);
        redirectToContinueLink(_continue);
      }
    } else {
      yield put(actionPostUserPreferencesFail({
        error: result.message || failuredLoad || ERROR_REQUEST_DEFAULT_MSG,
      }));
      yield put(addToast({
        message: failuredLoad || ERROR_REQUEST_DEFAULT_MSG,
        timeout: DEFAULT_TOAST_TIMEOUT,
      }));
    }
  } catch (error) {
    yield put(actionPostUserPreferencesFail({ error: error.message }));
    yield put(addToast({
      message: failuredLoad || ERROR_REQUEST_DEFAULT_MSG,
      timeout: DEFAULT_TOAST_TIMEOUT,
    }));
  }
}

function* postPreferencesWatcher() {
  yield takeEvery(POST_USER_COOKIES_PREFERENCES, postPreferencesWorker);
}

function* getLocaleResourcesWorker({ payload }) {
  try {
    let { token, language } = payload;

    if (!token && !getToken()) {
      redirectToLanding();
    }

    if (!token) {
      token = getToken();
    }

    const { data, status } = yield call(getLocaleResources, token, language);
    if (status === 200 && data.resource) {
      const resource = JSON.parse(data.resource);
      const cookiesResources = resource.cookies;
      yield put(actionGetLocaleResourcesSuccess({ token, cookiesResources }));
    } else {
      yield put(actionGetLocaleResourcesFail({ error: ERROR_REQUEST_DEFAULT_MSG }));
      redirectToLanding();
    }
  } catch (error) {
    yield put(actionGetLocaleResourcesFail({ error: error.message }));
    redirectToLanding();
  }
}

function* getLocaleResourcesWatcher() {
  yield takeEvery(GET_LOCALE_RESOURCES, getLocaleResourcesWorker);
}

function* getCommunityPreferencesWorker({ payload }) {
  try {
    let { token } = payload;
    if (!token) {
      token = getToken();
    }
    const { data, status } = yield call(getCommunityPreferences, token);
    if (status === 200) {
      const cookies = JSON.parse(data.cookies);
      const communityAllowsCookies = cookies && cookies.communityAllowsCookies;
      yield put(actionGetCommunityPreferencesSuccess({ communityAllowsCookies }));
    } else {
      yield put(actionGetCommunityPreferencesFail({ error: ERROR_REQUEST_DEFAULT_MSG }));
    }
  } catch (error) {
    yield put(actionGetCommunityPreferencesFail({ error: error.message }));
  }
}

function* getCommunityPreferencesWatcher() {
  yield takeEvery(GET_COMMUNITY_COOKIES_PREFERENCES, getCommunityPreferencesWorker);
}

function* getLookupWorker({ payload }) {
  try {
    const { token, language, requireAllRequest } = payload;
    const { identification } = parseJwtToObject(token);

    if (requireAllRequest) {
      const { result, status } = yield call(lookUpAuth, identification);
      if (status === 'OK') {
        setItem('baseUrl', result.baseUrl);
        yield put(getLookupCookiesPageSuccess());
      } else {
        throw new Error();
      }
    }
    yield put(actionGetUserPreferences({ token }));
    yield put(actionGetLocaleResources({ token, language }));
    yield put(actionGetCommunityPreferences({ token }));
  } catch (error) {
    yield put(getLookupCookiesPageFail());
    redirectToLanding();
  }
}

function* getLookupWatcher() {
  yield takeEvery(GET_LOOKUP_COOKIES_PAGE, getLookupWorker);
}

export default {
  getCommunityPreferencesWatcher,
  getLocaleResourcesWatcher,
  getPreferencesWatcher,
  postPreferencesWatcher,
  getLookupWatcher,
};
