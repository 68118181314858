// @packages
import { takeEvery, put, call } from 'redux-saga/effects';

// @own
import { getCommunityMemberMetrics } from './api';
import { REQUEST_COMMUNITY_MEMBER_METRICS } from './actionTypes';
import { actionCommunityMemberMetricsFail, actionCommunityMemberMetricsSuccess } from './actions';

function* getCommunityMemberMetricsWorker({ id, payload }) {
  try {
    const { data } = yield call(getCommunityMemberMetrics, payload);
    yield put(actionCommunityMemberMetricsSuccess(id, { result: data?.result }));
  } catch (error) {
    yield put(actionCommunityMemberMetricsFail(id, { error }));
  }
}

export function* getCommunityMemberMetricsWatcher() {
  yield takeEvery(REQUEST_COMMUNITY_MEMBER_METRICS, getCommunityMemberMetricsWorker);
}

export default {
  getCommunityMemberMetricsWatcher,
};
