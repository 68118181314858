import { dataServiceInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function fetchCulturalData({ userId }) {
  const baseUrl = `v1/community/members/${userId}/cultural-levels`;
  return dataServiceInstance({
    url: baseUrl,
    params: {
      fields: 'job,imageCode',
      limit: 50,
      page: 0,
      pageSize: 50,
    },
  }).then(({ data }) => data.result)
    .catch(errorHandler);
}
