// @app
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';
import messages from '../messages';

const ViewMore = ({
  intl: { formatMessage },
  text,
  visibleText,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {isExpanded ? text : visibleText}
      {(visibleText?.length < text?.length && !isExpanded) && (
        <>
          {' '}
          <span
            className="insights-content-view-more"
            onClick={() => setIsExpanded(true)}
          >
            {formatMessage(messages.ViewMore)}
          </span>
        </>
      )}
    </>
  );
};

ViewMore.propTypes = {
  intl: PropTypes.object,
  text: PropTypes.string,
  visibleText: PropTypes.string,
};

export default injectIntl(ViewMore);
