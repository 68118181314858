import { call, put, takeLatest } from 'redux-saga/effects';
import { getInteractions } from './api';
import { GET_MUTUAL_CONNECTIONS } from './actionTypes';
import {
  getMutualConnectionsSuccess,
  getMutualConnectionsFail,
} from './actions';

function* getMutualConnectionsWorker({ payload }) {
  try {
    const { uid, page, pageSize } = payload;
    const data = yield call(getInteractions, { uid, page, pageSize });
    yield put(getMutualConnectionsSuccess(data));
  } catch (e) {
    yield put(getMutualConnectionsFail(e));
  }
}

function* getMutualConnectionsWatcher() {
  yield takeLatest(GET_MUTUAL_CONNECTIONS, getMutualConnectionsWorker);
}

export default {
  getMutualConnectionsWatcher,
};
