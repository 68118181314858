import {
  ANALYSIS,
  DETAIL_COOKIES,
  INFORMATIONAL,
  NECESSARY,
  PERFORMANCE,
  PURPOSE,
  TARGETING,
  WHAT_IS,
  WHAT_USE,
} from './constants';

export const dataAsideList = ({
  configurationMadeTitle,
  weUseTitle,
  purposeTitle,
  whatIsTitle,
}) => ([
  {
    title: whatIsTitle,
    href: WHAT_IS,
  },
  {
    title: purposeTitle,
    href: PURPOSE,
  },
  {
    title: weUseTitle,
    href: WHAT_USE,
  },
  {
    title: configurationMadeTitle,
    href: DETAIL_COOKIES,
  },
]);

export const genericListData = ({
  analysisContent,
  analysisTitle,
  functionalityContent,
  functionalityTitle,
  performanceContent,
  performanceTitle,
  strictlyNecessaryContent,
  strictlyNecessaryTitle,
  targetingAdvertisinContent,
  targetingAdvertisinTitle,
}) => ([
  {
    title: strictlyNecessaryTitle,
    text: strictlyNecessaryContent,
  },
  {
    title: performanceTitle,
    text: performanceContent,
  },
  {
    title: functionalityTitle,
    text: functionalityContent,
  },
  {
    title: targetingAdvertisinTitle,
    text: targetingAdvertisinContent,
  },
  {
    title: analysisTitle,
    text: analysisContent,
  },
]);

export const rowsTable = ({
  analysisContent,
  analysisTitle,
  functionalityContent,
  functionalityTitle,
  performanceContent,
  performanceTitle,
  strictlyNecessaryContent,
  strictlyNecessaryTitle,
  targetingAdvertisinContent,
  targetingAdvertisinTitle,
}) => [
  {
    type: NECESSARY,
    title: strictlyNecessaryTitle,
    description: strictlyNecessaryContent,
  },
  {
    type: TARGETING,
    title: targetingAdvertisinTitle,
    description: targetingAdvertisinContent,
  },
  {
    type: INFORMATIONAL,
    title: functionalityTitle,
    description: functionalityContent,
  },
  {
    type: ANALYSIS,
    title: analysisTitle,
    description: analysisContent,
  },
  {
    type: PERFORMANCE,
    title: performanceTitle,
    description: performanceContent,
  },

];

export const userPreferencesAccepted = {
  analysis: true,
  informational: true,
  necessary: true,
  performance: true,
  targeting: true,
};

export const userPreferencesDeclined = {
  analysis: true,
  informational: true,
  necessary: true,
  performance: false,
  targeting: true,
};
