import { defineMessages } from 'react-intl';

export default defineMessages({
  discoverUsersDisclaimer: {
    id: 'discoverUsers.disclaimer',
    defaultMessage: 'Results are limited to 200 people. You can proceed with these results or you can refine your search.',
  },
  discoverUsersTitle: {
    id: 'discoverUsers.title',
    defaultMessage: 'Create new connections by discovering colleagues!',
  },
  discoverUsersOption1: {
    id: 'discoverUsers.option1',
    defaultMessage:
      'Use the filters on the left to find colleagues',
  },
  discoverUsersOption2: {
    id: 'discoverUsers.option2',
    defaultMessage: 'You can edit your results to narrow your selection',
  },
  discoverUsersOption3: {
    id: 'discoverUsers.option3',
    defaultMessage: 'Now you can connect with them! Use the options on the bottom bar.',
  },
  discoverUsersSelectedUsers: {
    id: 'discoverUsers.selectedUsers',
    defaultMessage: 'Selected colleagues',
  },
  discoverUsersSelectAll: {
    id: 'discoverUsers.selectAll',
    defaultMessage: 'Select all',
  },
  discoverUsersEmptyTitle: {
    id: 'discoverUsers.emptyTitle',
    defaultMessage: 'There are no results',
  },
  discoverUsersEmptySubtitle: {
    id: 'discoverUsers.emptySubtitle',
    defaultMessage: 'Please try again with a different set of filters to see results',
  },
  discoverUsersLargeResultsTitle: {
    id: 'discoverUsers.largeResultsTitle',
    defaultMessage: 'The number of results is too large',
  },
  discoverUsersLargeResultsSubtitle: {
    id: 'discoverUsers.largeResultsSubtitle',
    defaultMessage: 'Try adding more filters.',
  },
  discoverUsersUnselectAll: {
    id: 'discoverUsers.unselectAll',
    defaultMessage: 'Unselect All',
  },
});
