import firebase from 'firebase/app';
import 'firebase/messaging';

export const getPermissionToken = (vapidKey) => {
  const messaging = firebase?.messaging();
  return messaging?.getToken(messaging, vapidKey);
};

export const deletePermissionToken = () => {
  const messaging = firebase?.messaging();
  return messaging?.deleteToken();
};
