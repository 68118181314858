export const getDescriptionProps = (data) => ({
  children: data?.notes,
  urlProps: {
    externalRedirect: true,
  },
  users: data?.listMentions?.map((u) => ({
    fullName: u?.fullName,
    id: u?.id,
    uid: u?.uid,
  })),
});
