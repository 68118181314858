import React from 'react';
import PropTypes from 'prop-types';
import Button from 'smu-ui-components/Button';
import cn from 'classnames';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Panel from 'smu-ui-components/Panel';
import { injectIntl } from 'react-intl';
import Segment from 'smu-ui-components/Segment';
import { withRouter } from 'react-router';
// @App
import { goToProfileView } from 'components/ProfileLayout/actions';
import { parseDateUTC } from 'utils/parseDate';
import { trackEvent } from 'utils/gtm';
// @Own
import Stroboscope from './stroboscope';
import { FEATURES } from './constants';
import messages from './messages';
import './styles.scss';

const NewFeatures = ({
  goToProfileView,
  locale,
  intl: { formatMessage },
  router: { push },
}) => {
  const handlerButtonClick = ({ link, tab = undefined }) => {
    trackEvent({
      category: 'New Features',
      action: 'CulturalInfluencers_Go',
    });
    push(link);
    if (link === '/me' && tab) {
      goToProfileView(tab);
    }
  };

  return (
    <Segment
      className="new-features"
      loadingType="starmeup"
    >
      <div className="new-features__title-wrapper">
        <Stroboscope text={formatMessage(messages.newFeaturesPageTitle)} />
        <div className="new-features__title">{formatMessage(messages.newFeaturesPageSubtitle)}</div>
      </div>
      {FEATURES.map((feature, index) => (
        <Panel
          className="new-features__item"
          key={index}
          noPaddingSides={false}
        >
          <div className="new-features__feature-date">
            {parseDateUTC(feature.date, locale).format('MMMM YYYY')}
          </div>
          <div className={cn(
            'new-features__feature',
            { 'new-features__feature--odd': Boolean(index % 2) },
          )}
          >
            <div className="new-features__feature-image-wrapper">
              <img className="new-features__feature-image" src={feature.imageSrc} alt="" />
            </div>
            <div className="new-features__feature-content">
              <div className="new-features__feature-content-title">{formatMessage(feature.title)}</div>
              <div className="new-features__feature-content-description">{formatMessage(feature.description)}</div>
              {!isEmpty(feature.button) ? (
                <div className="new-features__feature-content-button-wrapper">
                  <Button
                    className="new-features__feature-content-button"
                    color="pink"
                    noMarginBottom
                    onClick={() => handlerButtonClick({ ...feature.button })}
                    size="narrow"
                  >
                    {formatMessage(feature.button.text)}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </Panel>
      ))}
    </Segment>
  );
};

NewFeatures.propTypes = {
  goToProfileView: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  locale: PropTypes.string,
  router: PropTypes.object,
};

const mapStateToProps = ({
  i18n: { locale },
}) => ({
  locale,
});

const withIntl = injectIntl(NewFeatures);
export default connect(
  mapStateToProps,
  { goToProfileView },
)(withRouter(withIntl));
