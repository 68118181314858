// @packages
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'smu-ui-components/Image';

// @own
import './styles.scss';

const OrganizationValue = ({ value }) => (
  <div className="insights-content-organization-value">
    <Image
      className="insights-content-organization-value__image"
      code={value.imageCode}
      height={110}
      width={110}
    />
    <span
      className="insights-content-organization-value__name"
      style={{ color: value.backgroundColor }}
    >
      {value.nameShowed?.toUpperCase()}
    </span>
  </div>
);

OrganizationValue.propTypes = {
  value: PropTypes.object,
};

export default OrganizationValue;
