// @packages
import React from 'react';
import Link from 'smu-ui-components/Link';
import NavMenu from 'smu-app-components/NavMenu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocalPathname } from 'smu-utils/url';
import { selectOpenMenu } from 'smu-app-components/MainHeader/selectors';
import { setHeaderMenuOpen } from 'smu-app-components/MainHeader/actions';
import { withRouter } from 'react-router';

// @app
import { ERROR_MENU } from 'services/appLoader/constants';
import { appLoaderFail } from 'services/appLoader/actions';
import { selectSessionUserId } from 'containers/Authorization/selectors';

// @own
import './styles.scss';
import { getItemTarget } from './helpers';

function NavMenuContainer({
  appLoaderFail,
  location,
  openMenu,
  setHeaderMenuOpen,
  userID,
}) {
  function handleClickItem(e, item) {
    if (item?.items?.length && !openMenu) {
      setHeaderMenuOpen({ openMenu: true });
    }
  }

  function handleClickLink(item) {
    const itemPathname = getLocalPathname(item?.url);
    if (location.pathname === itemPathname) window.scrollTo(0, 0);
  }

  function renderLink(children, item) {
    return (
      <Link
        className="smu-nav-menu__link"
        href={item?.url}
        legacy={false}
        onClick={() => handleClickLink(item)}
        target={getItemTarget(item)}
      >
        {children}
      </Link>
    );
  }

  function handleError(error) {
    appLoaderFail({
      error,
      errorType: ERROR_MENU,
    });
  }

  return (
    <NavMenu
      className="smu-nav-menu"
      collapsed={!openMenu}
      menuVersion={2}
      onClickItem={handleClickItem}
      onError={handleError}
      renderLink={renderLink}
      unifiedMenu
      url={window.location.href}
      userUID={String(userID)}
    />
  );
}

NavMenuContainer.propTypes = {
  appLoaderFail: PropTypes.func,
  openMenu: PropTypes.bool,
  setHeaderMenuOpen: PropTypes.func,
  userID: PropTypes.number,
};

const mapStateToProps = (state) => ({
  openMenu: selectOpenMenu(state),
  userID: selectSessionUserId(state),
});

const mapDispatchToProps = {
  appLoaderFail,
  setHeaderMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(NavMenuContainer),
);
