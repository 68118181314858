// @packages
import { defineMessages } from 'react-intl';

export default defineMessages({
  inputPlaceholder: {
    id: 'userSearch.inputPlaceholder',
    defaultMessage: 'Find someone',
  },
  suggestedUsersListHeader: {
    id: 'userSearch.suggestedUsersListHeader',
    defaultMessage: 'Suggested users',
  },
});
