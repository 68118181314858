// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import Button from 'smu-ui-components/ButtonV2';
import Panel from 'smu-ui-components/Panel';
import { getUserLanguage } from 'smu-utils/language';

// @app
import { selectSessionStars } from 'containers/Authorization/selectors';
import { trackEvent } from 'utils/gtm';

// @own
import './styles.scss';
import messages from './messages';
import OrganizationValue from './OrganizationValue';
import { INSIGHT_TYPES } from './constants';

function InsightsCard({
  className,
  data,
  id,
  intl: { formatMessage },
  router: { push },
}) {
  moment.locale(getUserLanguage());

  const areaName = data?.data?.properties?.name;
  const areaType = data?.data?.properties?.type;
  const cardCulturalIndicator = data?.data?.indicator?.[1];
  const cardData = data?.data?.indicator?.[0];
  const cardTitle = data?.data?.content?.title;
  const month = moment(data?.data?.period?.from).format('MMMM');
  const organizationValues = useSelector(state => selectSessionStars(state));

  const organizationValue = data?.type === INSIGHT_TYPES.WHO_REPRESENTS
    && cardData?.value
    && organizationValues?.find(value => value.id === Number(cardData?.value));

  const normalizedTitle = messages[`${cardTitle}`]
    ? formatMessage(messages[`${cardTitle}`], { area: areaType })
    : cardTitle;

  const normalizedCulturalTitle = messages[`${cardCulturalIndicator?.value}`]
    ? formatMessage(messages[`${cardCulturalIndicator?.value}`])
    : cardCulturalIndicator?.value;

  function getIndicator() {
    let description = '';

    if (
      messages[`${cardData?.description}`]
      && data?.type !== INSIGHT_TYPES.CULTURAL_ACTIVITY
    ) {
      description = messages[`${cardData?.description}`] ?
        `${formatMessage(messages[`${cardData?.description}`], { name: areaName })}.` :
        cardData?.description;
    } else {
      description = messages[`${cardData?.description}`] ?
        `${formatMessage(messages[`${cardData?.description}`])} ${month}.` :
        cardData?.description;
    }

    return description;
  }

  return (
    <Panel className={cn('insights-card', className)} noPadding>
      <header className="insights-card__header">
        {cardTitle && (
          <label className="insights-card__title">
            {normalizedTitle}
          </label>
        )}
      </header>
      {cardCulturalIndicator ? (
        <div className="insights-card__content-cultural">
          <div className="insights-card__content-cultural-indicator">
            {normalizedCulturalTitle}
          </div>
          <div className="insights-card__content-cultural-description">
            {getIndicator()}
          </div>
        </div>
      ) : (
        <div className="insights-card__content">
          {organizationValue
            ? <OrganizationValue value={organizationValue} />
            : (
              <>
                <div className="insights-card__content-metric">
                  {cardData?.value}
                </div>
                <div className="insights-card__content-description">
                  {getIndicator()}
                </div>
              </>
            )
          }
        </div>
      )}
      <Button
        className="insights-card__action"
        onClick={() => {
          push(`/insights/${id}`);
          trackEvent({
            action: 'Read_More',
            category: 'Insights',
            description: getIndicator(),
            kpi: cardData?.value,
            label: normalizedTitle,
            type: data?.type,
          });
        }}
        size="small"
      >
        {formatMessage(messages.readMore)}
      </Button>
    </Panel>
  );
}

InsightsCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  id: PropTypes.number,
  intl: PropTypes.object,
  router: PropTypes.object,
};

export default withRouter(injectIntl(InsightsCard));
