import { includes } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { apiUrl } from 'utils/apiUrl';

const Image = ({
  alt,
  animated = false,
  className,
  code,
  height,
  scale,
  width,
}) => {
  const maybeAnimated = animated ? '?imageAnimated=true' : '';
  const source = includes(code, 'data:image')
    ? code
    : `${apiUrl}/image/${code}/${width * scale}/${height * scale}/1${maybeAnimated}`;
  return (
    <img src={source} className={className} alt={alt} />
  );
};

Image.defaultProps = {
  alt: '',
  code: '',
  scale: 2,
};

Image.propTypes = {
  code: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  scale: PropTypes.number,
  className: PropTypes.string,
  alt: PropTypes.string,
  animated: PropTypes.bool,
};

export default Image;
