// @own
import { buildClosePath } from '../helpers';

const oneOnOneRequest = ({ hookModal, onClose, query }) => {
  hookModal.open({
    defaultValues: { toUserUid: query?.uid },
    onClose: () => onClose(buildClosePath(['uid'])),
  });
};

export default oneOnOneRequest;
