import { trackEvent } from 'smu-utils/gtm';

const event = (props) => {
  trackEvent({
    category: 'PostMenu',
    ...props,
  });
};

export const eventOnOpenMenu = () =>
  event({
    action: 'openMenu',
  });

export const eventOnCloseMenu = () =>
  event({
    action: 'closeMenu',
  });

export const eventOnDelete = () =>
  event({
    action: 'deletePost',
  });

export const eventOnEdit = () =>
  event({
    action: 'editPost',
  });

export const eventOnCopyUrl = () =>
  event({
    action: 'copyUrlPost',
  });

export const eventOnTaggedUsers = () =>
  event({
    action: 'taggedUsersPost',
  });

export const eventOnModerate = () =>
  event({
    action: 'moderatePost',
  });

export const eventOnNotModerate = () =>
  event({
    action: 'notModeratePost',
  });
