export const ANNIVERSARIES = 'ANNIVERSARIES';
export const ANOTHER = 'ANOTHER';
export const BADGES = 'BADGES';
export const BETHERE_MOMENTS = 'BETHERE_MOMENTS';
export const BETHERE_POST = 'BETHERE_POST';
export const BIRTHDAYS = 'BIRTHDAYS';
export const COMMENT = 'COMMENT';
export const CORE = 'CORE';
export const CORE_VALUE = 'CORE_VALUE';
export const DAYS_TO_SHOW = 2;
export const DEFAULT_PARAMS = { app: 'StarMeUp', page: 0, size: 30 };
export const DOCUMENT_CARD = 'DOCUMENT_CARD';
export const DOCUMENT_CARD_EXTERNAL = 'DOCUMENT_CARD_EXTERNAL';
export const FINISHED = 'FINISHED';
export const HIGHLIGHTS = 'HIGHLIGHTS';
export const IMAGE = 'IMAGE';
export const INSIGHT = 'INSIGHT';
export const INTERACTION_ANNIVERSARY = 'INTERACTION_ANNIVERSARY';
export const INTERACTION_BIRTHDAY = 'INTERACTION_BIRTHDAY';
export const K = 'K';
export const KUDOS = 'KUDOS';
export const LIKE = 'LIKE';
export const ORGANIZATION_MODERATOR = 'ORGANIZATION_MODERATOR';
export const OS_FEED = 'OS_FEED';
export const RECENT = 'RECENT';
export const STARMEUP = 'STARMEUP';
export const SUPERNOVA = 'SUPERNOVA';
export const TEAM_RECOGNITION = 'TEAM_RECOGNITION';
export const TODAY = 'TODAY';
export const YESTERDAY = 'YESTERDAY';
