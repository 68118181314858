import {
  GET_CULTURAL_DATA_FAIL,
  GET_CULTURAL_DATA_SUCCESS,
  GET_CULTURAL_DATA,
  RESET_CULTURAL_STATE,
} from './actionTypes';
import { CATEGORIES_LEVELS } from './constants';

const formatEmptyCategory = level => ({
  level,
  members: [],
});

const categorySorting = (a, b) => {
  if (a.level === 'ROCKING_STAR') {
    return -1;
  }
  if (a.level === 'SHINING_STAR' && b.level === 'RISING_STAR') {
    return -1;
  }
  return 0;
};

export const getCulturalData = payload => ({
  type: GET_CULTURAL_DATA,
  payload,
});

export const getCulturalDataSuccess = (categories) => {
  categories = CATEGORIES_LEVELS.map((categoryLevel) => {
    const category = categories.find(category => category.level === categoryLevel);
    return category || formatEmptyCategory(categoryLevel);
  });
  categories.sort(categorySorting);

  return {
    type: GET_CULTURAL_DATA_SUCCESS,
    payload: categories,
  };
};

export const getCulturalDataFail = () => ({
  type: GET_CULTURAL_DATA_FAIL,
});

export const resetCulturalState = () => ({
  type: RESET_CULTURAL_STATE,
});
