// @packages
import React from 'react';
import PropTypes from 'prop-types';
import ButtonV2 from 'smu-ui-components/ButtonV2';
import LottieSpinner from 'smu-ui-components/LottieSpinner';

// @own
import User from '../User';
import Default from '../Default';
import Footer from '../Footer';
import Empty from '../Empty';
import './styles.scss';

function DiscoverUsersList({
  enableOptions,
  isEmpty,
  isLoading,
  onChecked,
  onClickUser,
  onSelectAll,
  options: Options,
  selectedUsers,
  showFooter,
  texts,
  totalElements,
  totalSelectedUsers,
  users,
}) {
  return (
    <div className="discover-users-list">
      <header className="discover-users-list__header">
        <div className="discover-users-list__count">
          {texts.selectedUsers}
          <span>{isLoading ? 0 : totalSelectedUsers}</span>
        </div>
        <ButtonV2
          disabled={isLoading || !users.length}
          enabledUnification
          onClick={onSelectAll}
          size="small"
          variant="link"
        >
          {texts.selectAll}
        </ButtonV2>
      </header>
      {totalElements >= 200 && (
        <div className="discover-users-list__disclaimer">
          {texts.disclaimer}
        </div>
      )}
      {isLoading ? (
        <LottieSpinner
          className="discover-users-list__loader"
          height={40}
          loadingType="starmeup"
          width={40}
        />
      ) : users.length ? (
        <div className="discover-users-list__items">
          {users.map((user) => (
            <User
              key={user.id}
              onChecked={onChecked}
              onClick={() => onClickUser(user)}
              {...user}
            />
          ))}
        </div>
      ) : isEmpty ? (
        <Empty texts={texts} />
      ) : (
        <Default texts={texts} />
      )}
      {showFooter && Options && (
        <Footer>
          <Options enableOptions={enableOptions} users={selectedUsers} />
        </Footer>
      )}
    </div>
  );
}

DiscoverUsersList.propsTypes = {
  isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChecked: PropTypes.func,
  onClickUser: PropTypes.func,
  onSelectAll: PropTypes.func,
  showFooter: PropTypes.bool,
  texts: PropTypes.object,
  totalElements: PropTypes.number,
  totalSelectedUsers: PropTypes.number,
  users: PropTypes.array,
  components: PropTypes.shape({
    DiscoverOptions: PropTypes.elementType,
  }),
};

DiscoverUsersList.defaultProps = {
  isEmpty: false,
  isLoading: false,
  onChecked: () => undefined,
  onClickUser: () => undefined,
  onSelectAll: () => undefined,
  showFooter: false,
  texts: {},
  totalSelectedUsers: 0,
  users: [],
};

export default DiscoverUsersList;
