import {
  SET_PROFILE_BIO,
  SET_PROFILE_BIO_SUCCESS,
  SET_PROFILE_BIO_FAIL,
} from './actionTypes';

export function setProfileBio(message) {
  return {
    type: SET_PROFILE_BIO,
    payload: message,
  };
}

export function setProfileBioFail() {
  return {
    type: SET_PROFILE_BIO_FAIL,
  };
}

export function setProfileBioSuccess(message) {
  return {
    type: SET_PROFILE_BIO_SUCCESS,
    payload: message,
  };
}
