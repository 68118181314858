// @packages
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getUserLanguage } from 'smu-utils/language';
import { injectIntl } from 'react-intl';
import { useGroupsById } from 'smu-services/os-member/api/v1/community/groups/[id]';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

// @app
import ShareBadgeComponent from 'components/Share/Badge';
import { buildImageUrl } from 'smu-utils/image';
import { selectOrganizationName, selectSessionUser } from 'containers/Authorization/selectors';

// @own
import messages from './messages';

function ShareBadgeContainer({
  intl: { formatMessage },
  onReady,
  router: { location },
}) {
  const badgeContentRef = useRef(null);
  const badgeId = location?.query?.id;
  const useGroupsByIdHook = useGroupsById({ id: badgeId, lang: getUserLanguage() });
  const badgeData = useGroupsByIdHook.selectGroup();
  const userData = useSelector(selectSessionUser);
  const heightBadgeContent = badgeContentRef?.current?.clientHeight;
  const isSuccess = Boolean(badgeData?.id);
  const organizationName = useSelector(state => selectOrganizationName(state));
  const title = formatMessage(messages.title, { xxx: organizationName });
  const userJob = userData?.job;
  const userName = `${userData?.firstName || ''} ${userData?.lastName || ''}`;

  const badge = {
    description: badgeData?.description,
    imageUrl: buildImageUrl({
      code: badgeData?.imageCode,
      height: 36,
      width: 36,
    }),
    name: badgeData?.name,
  };

  const user = {
    imageUrl: buildImageUrl({
      code: userData?.profileImageCode,
      height: 75,
      width: 75,
    }),
    job: userJob,
    name: userName,
  };

  useEffect(() => {
    if (isSuccess && heightBadgeContent && onReady) {
      onReady();
    }
  }, [heightBadgeContent, isSuccess]);

  return (
    <ShareBadgeComponent
      badge={badge}
      ref={badgeContentRef}
      title={title}
      user={user}
    />
  );
}

ShareBadgeContainer.propTypes = {
  intl: PropTypes.object,
  onReady: PropTypes.func,
  router: PropTypes.object,
};

export default withRouter(injectIntl(ShareBadgeContainer));
