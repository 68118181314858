// @packages
import cloneDeep from 'lodash/cloneDeep';

// @own
import {
  REQUEST_UPLOAD_SHARE_IMAGE,
  REQUEST_UPLOAD_SHARE_IMAGE_DESTROY,
  REQUEST_UPLOAD_SHARE_IMAGE_FAIL,
  REQUEST_UPLOAD_SHARE_IMAGE_SUCCESS,
} from './actionTypes';
import { getShareId } from './helpers';

const initialState = {};

const idState = {
  error: undefined,
  requesting: false,
  result: undefined,
};

function merge(state, id, update) {
  const shareId = getShareId(id);
  const prevState = cloneDeep(state);
  return {
    ...prevState,
    [shareId]: {
      ...idState,
      ...prevState[shareId],
      ...update,
    },
  };
}

function destroy(state, id) {
  const shareId = getShareId(id);
  const newState = cloneDeep(state);
  delete newState[shareId];
  return newState;
}

export default function reducer(state = initialState, { type, payload, id }) {
  switch (type) {
    case REQUEST_UPLOAD_SHARE_IMAGE:
      return merge(state, id, { requesting: true });
    case REQUEST_UPLOAD_SHARE_IMAGE_SUCCESS:
      return merge(state, id, { result: payload?.result, requesting: false });
    case REQUEST_UPLOAD_SHARE_IMAGE_FAIL:
      return merge(state, id, { ...payload, requesting: false });
    case REQUEST_UPLOAD_SHARE_IMAGE_DESTROY:
      return destroy(state, id);
    default:
      return state;
  }
}
