import { buildClosePath } from '../helpers';

const congratulation = ({ hookModal, query, onClose }) => {
  if (query?.id || query?.userId) {
    const closePath = buildClosePath(['id', 'userId', 'type']);
    hookModal.open({
      id: query?.id,
      onClose: () => onClose(closePath),
      type: query?.type,
      userId: query?.userId,
    });
  }
};

export default congratulation;
