// @packages
import { buildImageUrl } from 'smu-utils/image';

// @app
import { buildModalUrl } from 'containers/QueryModals/helpers';

// @own
import messages from './messages';

export const getModalType = (type) => {
  const types = {
    BIRTHDAYS: 'INTERACTION_BIRTHDAY',
    ANNIVERSARIES: 'INTERACTION_ANNIVERSARY',
  };

  return types[type];
};

export const getDescription = (years, formatMessage) => {
  const nYears = years ? Number(years) : 0;
  const text = formatMessage(
    messages[`ContentTypeYear${nYears > 1 ? 's' : ''}`],
    {
      xxx: years,
    },
  );
  return nYears ? text : '';
};

export const getUserCarouselProps = (users, type, formatMessage) => ({
  legacy: false,
  userList: users?.map((u) => ({
    description: getDescription(u?.yearsAnniversary, formatMessage),
    fullName: u?.fullName,
    href: buildModalUrl({
      modal: 'congratulation',
      userId: u?.uid,
      type: getModalType(type),
    }),
    src: buildImageUrl({
      code: u?.profileImageCode,
      height: 56,
      width: 56,
    }),
  })),
});
