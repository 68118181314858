import {
  REQUEST_EARNED_BADGES,
  REQUEST_RECEIVED_STARS,
  REQUEST_SENT_STARS,
  GET_PROFILE,
  CLEAN_PROFILE,
  REQUEST_USERS_INTERACTION,
} from './actionTypes';

export function getProfile(payload) {
  return {
    type: GET_PROFILE,
    payload,
  };
}

export function cleanProfile() {
  return {
    type: CLEAN_PROFILE,
  };
}

export function getReceivedStars() {
  return {
    type: REQUEST_RECEIVED_STARS,
  };
}

export function getSentStars(payload) {
  return {
    type: REQUEST_SENT_STARS,
    payload,
  };
}

export function getEarnedBadges() {
  return {
    type: REQUEST_EARNED_BADGES,
  };
}

export function getUsersInteraction(id) {
  return {
    type: REQUEST_USERS_INTERACTION,
    id,
  };
}

