import {
  selectRequesting,
  selectError,
  selectResult,
} from 'smu-utils/reduxRequests/selectors';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { useSelector, useDispatch } from 'react-redux';

const useRequest = ({ id, ...rest }) => {
  const dispatch = useDispatch();
  const isRequesting = useSelector((state) => selectRequesting(state, id));
  const error = useSelector((state) => selectError(state, id));
  const result = useSelector((state) => selectResult(state, id));

  const fetch = (props) => {
    dispatch(
      actionRequestInit({
        id,
        ...rest,
        ...props,
      }),
    );
  };

  return {
    error,
    fetch,
    isRequesting,
    result,
  };
};

export default useRequest;
