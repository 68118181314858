// @packages
import { useEffect, useState } from 'react';

/**
 * A custom hook that tracks whether the content of a node has been rendered.
 * @param {React.MutableRefObject} nodeRef - The ref object that points to the node to observe.
 * @param {MutationObserverInit} config - The configuration object for the MutationObserver.
 * @returns {[boolean]} - A boolean indicating whether the content of the node has been rendered.
 */
export default function useContentRendered(nodeRef, config) {
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    const node = nodeRef.current;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        if (node.textContent) {
          setHasRendered(true);
        }
      });
    });

    if (node) {
      observer.observe(node, config);
    }

    return () => {
      observer.disconnect();
    };
  }, [nodeRef]);

  return [hasRendered];
}
