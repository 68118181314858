export const ADD_LIFT_STAR_ENTITY = 'ADD_LIFT_STAR_ENTITY';
export const CHANGE_SELECTED_STAR = 'CHANGE_SELECTED_STAR';
export const GET_REMAINING_STARS = 'GET_REMAINING_STARS';
export const GET_REMAINING_STARS_FAIL = 'GET_REMAINING_STARS_FAIL';
export const GET_REMAINING_STARS_SUCCESS = 'GET_REMAINING_STARS_SUCCESS';
export const LIFT_STAR = 'LIFT_STAR';
export const LIFT_STAR_FAIL = 'LIFT_STAR_FAIL';
export const LIFT_STAR_SUCCESS = 'LIFT_STAR_SUCCESS';
export const SET_EMOJI = 'SET_EMOJI';
export const STAR_FLOW_CLEAN_PLUS_RECEIVED = 'STAR_FLOW_CLEAN_PLUS_RECEIVED';
export const STAR_FLOW_CLOSE_SUGGEST_SENT_STAR = 'STAR_FLOW_CLOSE_SUGGEST_SENT_STAR';
export const STAR_FLOW_COMMENT = 'STAR_FLOW_COMMENT';
export const STAR_FLOW_COMMENT_COUNTER = 'STAR_FLOW_COMMENT_COUNTER';
export const STAR_FLOW_DEC_OFFSET = 'STAR_FLOW_DEC_OFFSET';
export const STAR_FLOW_DEC_OFFSET_CC = 'STAR_FLOW_DEC_OFFSET_CC';
export const STAR_FLOW_EDIT = 'STAR_FLOW_EDIT';
export const STAR_FLOW_ENTITIES = 'STAR_FLOW_ENTITIES';
export const STAR_FLOW_ENTITIES_CC = 'STAR_FLOW_ENTITIES_CC';
export const STAR_FLOW_ENTITY = 'STAR_FLOW_ENTITY';
export const STAR_FLOW_ENTITY_CC = 'STAR_FLOW_ENTITY_CC';
export const STAR_FLOW_ERROR = 'STAR_FLOW_ERROR';
export const STAR_FLOW_GET_MENTION = 'STAR_FLOW_GET_MENTION';
export const STAR_FLOW_GET_MENTION_ERROR = 'STAR_FLOW_GET_MENTION_ERROR';
export const STAR_FLOW_GET_MENTION_SUCCESS = 'STAR_FLOW_GET_MENTION_SUCCESS';
export const STAR_FLOW_GET_STAR_BY_ID = 'STAR_FLOW_GET_STAR_BY_ID';
export const STAR_FLOW_GET_STAR_BY_ID_FAIL = 'STAR_FLOW_GET_STAR_BY_ID_FAIL';
export const STAR_FLOW_GET_STAR_BY_ID_SUCCESS = 'STAR_FLOW_GET_STAR_BY_ID_SUCCESS';
export const STAR_FLOW_INC_OFFSET = 'STAR_FLOW_INC_OFFSET';
export const STAR_FLOW_INC_OFFSET_CC = 'STAR_FLOW_INC_OFFSET_CC';
export const STAR_FLOW_INITIAL = 'STAR_FLOW_INITIAL';
export const STAR_FLOW_NONE = 'STAR_FLOW_NONE';
export const STAR_FLOW_OPEN_SUGGEST_SENT_STAR = 'STAR_FLOW_OPEN_SUGGEST_SENT_STAR';
export const STAR_FLOW_PLUS_RECEIVED = 'STAR_FLOW_PLUS_RECEIVED';
export const STAR_FLOW_REMOVE_ENTITY = 'STAR_FLOW_REMOVE_ENTITY';
export const STAR_FLOW_REMOVE_ENTITY_CC = 'STAR_FLOW_REMOVE_ENTITY_CC';
export const STAR_FLOW_REMOVE_IMAGE = 'STAR_FLOW_UPLOAD_IMAGE';
export const STAR_FLOW_SEND = 'STAR_FLOW_SEND';
export const STAR_FLOW_SENT = 'STAR_FLOW_SENT';
export const STAR_FLOW_SENT_WAITING = 'STAR_FLOW_SENT_WAITING';
export const STAR_FLOW_SERVER_ERROR = 'STAR_FLOW_SERVER_ERROR';
export const STAR_FLOW_SET_IMAGE = 'STAR_FLOW_SET_IMAGE';
export const STAR_FLOW_TOGGLE_PICKER = 'STAR_FLOW_TOGGLE_PICKER';
export const STAR_FLOW_UPLOAD_IMAGE = 'STAR_FLOW_UPLOAD_IMAGE';
export const STAR_FLOW_UPLOAD_IMAGE_FAILED = 'STAR_FLOW_UPLOAD_IMAGE_FAILED';
export const STAR_FLOW_UPLOAD_IMAGE_SUCCESS = 'STAR_FLOW_UPLOAD_IMAGE_SUCCESS';
