import ReceivedStars from 'smu-app-components/YIR/share/ReceivedStars';
import UserDNA from 'smu-app-components/YIR/share/UserDNA';
import MostRecognizedValue from 'smu-app-components/YIR/share/MostRecognizedValue';
import LevelInfo from 'smu-app-components/YIR/share/LevelInfo';

const config = {
  'level-info': LevelInfo,
  'most-recognized-value': MostRecognizedValue,
  'received-stars': ReceivedStars,
  'user-dna': UserDNA,
};

export default config;
