import liftIconSrc from 'assets/icons/lift-star-done.svg';
import likeFullIconSrc from 'assets/icons/like-full.svg';

export const TYPE_ALL = 'all';
export const TYPE_BRIGHTENS = 'brightens';
export const TYPE_LIKES = 'likes';
export const TYPE_BRIGHTEN = 'brighten';
export const TYPE_LIKE = 'like';

export const TABS = [TYPE_ALL, TYPE_LIKES, TYPE_BRIGHTENS];

export const INTERACTIONS_TYPES = {
  [TYPE_BRIGHTENS]: TYPE_BRIGHTEN,
  [TYPE_LIKES]: TYPE_LIKE,
};

export const IMAGES = {
  [TYPE_BRIGHTENS]: liftIconSrc,
  [TYPE_LIKES]: likeFullIconSrc,
};
