// @packages
import React, { useEffect } from 'react';
import { buildImageUrl } from 'smu-utils/image';
import { useSelector } from 'react-redux';

// @app
import { selectOrganizationImageId } from 'containers/Authorization/selectors';

// @own
import config from './config';

const ShareYearInReviewContainer = ({ step, onError, ...rest }) => {
  const code = useSelector(selectOrganizationImageId);
  const logoSrc = buildImageUrl({
    code,
    width: 180,
    height: 180,
  });
  const Component = config[step];

  useEffect(() => {
    if (!Component && onError) {
      onError('The step of the year in review is not defined');
    }
  }, [step]);

  return Component ? (
    <Component
      logoSrc={logoSrc}
      onError={onError}
      showShareButton={false}
      {...rest}
    />
  ) : null;
};

export default ShareYearInReviewContainer;
