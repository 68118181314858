import Axios from 'axios';

export function getUnsubscribeData({ apiUrl, token }) {
  const URL = `${apiUrl}/v2/stellar/resources/unsubscribe`;
  return Axios.get(URL, {
    headers: {
      token,
    },
  })
    .then(({ data }) => data.result)
    .catch(({ data }) => data.result);
}

export function createUnsubscribeUser(data) {
  const {
    apiUrl,
    category,
    reasonCode,
    reasonText: reasonDescription,
    token,
  } = data;
  const URL = `${apiUrl}/v2/stellar/users/unsubscribe`;
  const params = {
    category,
    reasonCode,
    reasonDescription,
  };

  return Axios.post(URL, params, {
    headers: {
      token,
    },
  })
    .then(({ data }) => data.result)
    .catch(({ data }) => data.result);
}
