// @packages
import React from 'react';
import Chip from 'smu-ui-components/Chip';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import FeedSortButton from 'components/FeedSortButton';

// @own
import './styles.scss';

const FeedFilters = ({
  className,
  filters,
  onFeedSortButtonClose,
  onFeedSortButtonOpen,
  onFeedSortButtonToggle,
  osFeedEnabled,
  showFeedSortButton,
}) => (
  <div className={cn('smu-feed-filters', className)}>
    <div className="smu-feed-filters__chips">
      {filters?.map((filter, key) => (
        <Chip
          className={cn('smu-feed-filters__chip', {
            'smu-feed-filters__chip--active': filter?.active,
          })}
          color={filter?.active ? 'blue' : 'titanWhite'}
          key={`feed-filter-${key}`}
          onClick={filter?.active ? undefined : filter?.onClick}
        >
          {filter?.text}
        </Chip>
      ))}
    </div>
    {showFeedSortButton && (
      <FeedSortButton
        className="smu-feed-filters__feed-sort-button"
        onClose={onFeedSortButtonClose}
        onOpen={onFeedSortButtonOpen}
        onToggle={onFeedSortButtonToggle}
        osFeedEnabled={osFeedEnabled}
      />
    )}
  </div>
);

FeedFilters.defaultProps = {
  filters: [],
  showFeedSortButton: true,
};

FeedFilters.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array,
  onFeedSortButtonClose: PropTypes.func,
  onFeedSortButtonOpen: PropTypes.bool,
  onFeedSortButtonToggle: PropTypes.func,
  osFeedEnabled: PropTypes.bool,
  showFeedSortButton: PropTypes.bool,
};

export default FeedFilters;
