// @package
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'smu-ui-components/ButtonV2';
import cn from 'classnames';
import Panel from 'smu-ui-components/Panel';

// @app
import Title from 'components/Title';

// @own
import './styles.scss';

function SMUEmptyState({
  callToAction,
  className,
  imageSrc,
  subtitle,
  title,
}) {
  return (
    <Panel className={cn('smu-empty-state', className)}>
      {imageSrc && (
        <img alt="Empty state" className="smu-empty-state__image" src={imageSrc} />
      )}
      <Title className="smu-empty-state__title">
        {title}
      </Title>
      {subtitle && (
        <span className="smu-empty-state__subtitle">
          {subtitle}
        </span>
      )}
      {callToAction && (
        <Button
          className="smu-empty-state__button"
          onClick={callToAction.onClick}
          size="medium"
          variant="outline"
        >
          {callToAction.text}
        </Button>
      )}
    </Panel>
  );
}

SMUEmptyState.propTypes = {
  callToAction: PropTypes.object,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SMUEmptyState;
