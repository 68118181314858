// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// @ app
import StarTypeContainer from 'containers/Selects/StarType';
import messages from 'containers/CoreValuesPanel/messages';
import useToast from 'hooks/useToast';

// @own
import { checkStarAvailability } from '../';

const ListStarValues = ({
  entities,
  intl: { formatMessage },
  isEdit,
  listRecognitionText,
  onChangeValue,
  remainingStars,
  selectedValue,
  values,
}) => {
  const showMessage = useToast();

  const showUnavailableMessage = (star) => {
    const message = star?.isKudos
      ? messages.noKudosAvailableDisabled
      : messages.noStarAvailableDisabled;
    showMessage({
      message: formatMessage(message),
    });
  };

  const handleOnChange = (valueId) => {
    const star = values.find((star) => star.id === valueId);
    const unavailable = checkStarAvailability(
      star,
      remainingStars,
      entities,
    );

    if (unavailable) {
      showUnavailableMessage(star);
    } else {
      onChangeValue(star);
    }
  };

  return (
    <StarTypeContainer
      disabled={isEdit}
      isClearable={false}
      isSearchable={false}
      onChange={handleOnChange}
      placeholder={listRecognitionText}
      size="large"
      value={selectedValue?.id || ''}
    />
  );
};

ListStarValues.propTypes = {
  entities: PropTypes.array,
  intl: PropTypes.object,
  isEdit: PropTypes.bool,
  listRecognitionText: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  remainingStars: PropTypes.object,
  selectedValue: PropTypes.object,
  values: PropTypes.array.isRequired,
};

const mapStateToProps = ({ starFlow }) => {
  const { entities, remainingStars } = starFlow;
  return {
    entities,
    remainingStars,
  };
};

export default connect(mapStateToProps)(injectIntl(ListStarValues));
