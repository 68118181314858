// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { selectRequesting, selectResultProp } from 'smu-utils/reduxRequests/selectors';

// @own
import { REQUEST_ID_GET_CHILLOUT } from './constants';
import { apiGetChillOut } from './api';

function withChillOutConfig(Wrapped) {
  function ChillOutConfigHOC({
    actionRequestInit,
    enabledChillout,
    requesting,
    ...rest
  }) {
    const [showWrapper, setShowWrapper] = useState(false);
    const prevRequesting = usePrevious(requesting);

    function getChillout() {
      actionRequestInit({ api: apiGetChillOut, id: REQUEST_ID_GET_CHILLOUT, params: { size: 10 } });
    }

    useEffect(() => {
      getChillout();
    }, []);

    useEffect(() => {
      if (prevRequesting && !requesting && enabledChillout && !showWrapper) {
        setShowWrapper(true);
      }
    }, [requesting, enabledChillout]);

    return showWrapper && <Wrapped {...rest} />;
  }

  const mapStateToProps = state => ({
    enabledChillout: selectResultProp(state, REQUEST_ID_GET_CHILLOUT, 'enabledChillout', false),
    requesting: selectRequesting(state, REQUEST_ID_GET_CHILLOUT),
  });

  ChillOutConfigHOC.propTypes = {
    actionRequestInit: PropTypes.func.isRequired,
    enabledChillout: PropTypes.bool,
    requesting: PropTypes.bool,
  };

  return connect(mapStateToProps, { actionRequestInit })(ChillOutConfigHOC);
}

export default withChillOutConfig;
