import {
  SHARE_TYPE_FACEBOOK,
  SHARE_TYPE_LINKEDIN,
  SHARE_TYPE_TWITTER,
  SHARE_TYPE_WORKPLACE,
  facebookLink,
  linkedinLink,
  openShareDialog,
  twitterLink,
  workplaceLink,
} from 'utils/share';

import {
  COLOR_FACEBOOK,
  COLOR_FACEBOOK__WORKPLACE,
  COLOR_LINKEDIN,
  COLOR_TWITTER,
  NAME_FACEBOOK,
  NAME_FACEBOOK_WORKPLACE,
  NAME_LINKEDIN,
  NAME_TWITTER,
} from './constants';

const config = {
  [SHARE_TYPE_FACEBOOK]: {
    color: COLOR_FACEBOOK,
    name: NAME_FACEBOOK,
    onClick: (shareUrl) =>
      openShareDialog({
        quote: 'https://www.starmeup.com',
        link: facebookLink(shareUrl, {}),
      }),
  },
  [SHARE_TYPE_WORKPLACE]: {
    color: COLOR_FACEBOOK__WORKPLACE,
    name: NAME_FACEBOOK_WORKPLACE,
    onClick: (shareUrl) =>
      openShareDialog({
        quote: 'https://www.starmeup.com',
        link: workplaceLink(shareUrl, {}),
      }),
  },
  [SHARE_TYPE_LINKEDIN]: {
    color: COLOR_LINKEDIN,
    name: NAME_LINKEDIN,
    onClick: (shareUrl) =>
      openShareDialog({
        windowWidth: 750,
        windowHeight: 600,
        title: 'https://www.starmeup.com',
        link: linkedinLink(shareUrl, {}),
      }),
  },
  [SHARE_TYPE_TWITTER]: {
    color: COLOR_TWITTER,
    name: NAME_TWITTER,
    onClick: (shareUrl) =>
      openShareDialog({
        title: 'https://www.starmeup.com',
        link: twitterLink(shareUrl, {}),
      }),
  },
};

export default config;
