// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash/get';

// @own
import './styles.scss';

function Emoji({ disabled, onClick, option }) {
  const active = get(option, 'selected.data.value');
  let emojis = get(option, 'data.emoji', '');
  const existUPlus = emojis.indexOf('U+') > -1;

  if (emojis) {
    emojis = emojis
      .split('U+')
      .filter(e => !!e)
      .map(e => (existUPlus ? `0x${e}` : e));
  }

  return (
    <div
      className={cn('questionnaires-flex-type-emoji', {
        'questionnaires-flex-type-emoji--active': active,
        'questionnaires-flex-type-emoji--disabled': disabled,
      })}
      onClick={onClick}
    >
      {emojis.map(emoji => (
        <div
          className="questionnaires-flex-type-emoji__wrapper"
          key={emoji}
        >
          <span className="questionnaires-flex-type-emoji__item" role="img" aria-label={emoji}>
            {existUPlus ? String.fromCodePoint(emoji) : emoji}
          </span>
        </div>
      ))}
    </div>
  );
}

Emoji.defaultProps = {
  disabled: false,
};

Emoji.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};

export default Emoji;
