// @packages
import React from 'react';
import cn from 'classnames';

function PieChart({ className, data, total, ...rest }) {
  const customData = data.reduce((acc, cur, key) => {
    const percent = (100 * cur.value) / total;
    const prevPercent = acc[key - 1]?.countPercent || 0;

    acc.push({
      color: cur.color,
      countPercent: prevPercent + percent,
      strokeDasharray: `${percent} ${100 - percent}`,
      strokeDashoffset: 100 - prevPercent + 25,
    });
    return acc;
  }, []);

  return (
    <svg
      className={cn('donut', className)}
      height="100%"
      viewBox="3.5 3.5 35 35"
      width="100%"
      {...rest}
    >
      <circle
        className="donut-hole"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
      />
      <circle
        className="donut-ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke="#d2d3d4"
        strokeWidth="3"
      />
      {customData?.map((d, key) => (
        <circle
          key={key}
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke={d.color}
          strokeWidth="3"
          strokeDasharray={d.strokeDasharray}
          strokeDashoffset={d.strokeDashoffset}
        />
      ))}
    </svg>
  );
}

PieChart.defaultProps = {
  data: [],
  total: 90,
};

export default PieChart;
