// @packages
import React, { useState } from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';
import { EditorState } from 'draft-js';

// @app
import RichInputText from 'containers/RichInputText';
import Emojis from 'components/Emojis';

// @own
import './styles.scss';

const PostComment = ({
  className,
  comment,
  createEditorState,
  emoji,
  errorMessage,
  labelClassname,
  maxLength,
  mentionsEnabled,
  orgId,
  placeholder,
  setComment,
  setEmoji,
  style,
  textAreaClassName,
  title,
}) => {
  const { editorState } = comment;
  const textLength = editorState?.getCurrentContent()?.getPlainText()?.length;
  const [togglePickerEmoji, setTogglePickerEmoji] = useState(false);

  return (
    <div className={cn('post-comment', className)}>
      {title &&
      <div className={cn('post-comment__title', labelClassname)}>
        {title}
      </div>}
      <div className={cn('post-comment__textarea', textAreaClassName)}>
        <RichInputText
          className="post-comment__rich-input-text"
          comment={comment}
          editorState={createEditorState}
          emoji={emoji}
          maxCharacters={maxLength}
          mentionsEnabled={mentionsEnabled}
          onInputChange={(value) => setComment(value)}
          orgId={orgId}
          placeholder={placeholder}
          setEmoji={setEmoji}
        />
        <div className="post-comment__addons">
          <div className="post-comment__rich-input-text--counter">
            {`${textLength}/${maxLength}`}
          </div>
          <Button
            className="post-comment__emoji"
            onClick={() => setTogglePickerEmoji(true)}
          >
            <Icon icon="emoji" size="small" color="grey" />
          </Button>
          <div className="post-comment__emoji-popup">
            {togglePickerEmoji && (
            <Emojis
              handleClick={e => {
                if (e.native) {
                  setTogglePickerEmoji(false);
                  setEmoji(e.native);
                }
              }}
              onClickOutside={() => setTogglePickerEmoji(false)}
              style={style}
            />
            )}
          </div>
        </div>
      </div>
      <Typography className="post-comment__error">{errorMessage}</Typography>
    </div>
  );
};

PostComment.defaultProps = {
  comment: '',
  createEditorState: EditorState.createEmpty(),
  emoji: '',
  errorMessage: '',
  maxLength: 500,
  mentionsEnabled: false,
  placeholder: '',
  setComment: () => {},
  setEmoji: () => {},
  style: { left: '20px', bottom: '20px' },
  title: '',
};

PostComment.propTypes = {
  comment: PropTypes.string,
  createEditorState: PropTypes.object,
  emoji: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  mentionsEnabled: PropTypes.bool,
  placeholder: PropTypes.string,
  setComment: PropTypes.func,
  setEmoji: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.string,
};

export default PostComment;
