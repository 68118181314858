// @packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Badges from 'smu-app-components/BadgesWidget';
import { useSelector } from 'react-redux';

// @app
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import {
  ENABLED_BADGES_ASSIGNED_BY_COLLABORATOR,
  ENABLED_BADGES_ASSIGNED_BY_LEADER,
  ENABLED_SHARE_BADGE,
} from 'services/communityConfigs/constants';

function BadgesContainer({ className, isOwnProfile, isCollaborator, ...rest }) {
  const enabledBadgesAssignedByCollaborator = useSelector((state) =>
    getCommunityConfigValueBool(state, ENABLED_BADGES_ASSIGNED_BY_COLLABORATOR));
  const enabledBadgesAssignedByLeader = useSelector((state) =>
    getCommunityConfigValueBool(state, ENABLED_BADGES_ASSIGNED_BY_LEADER));
  const enabledShareBadge = useSelector((state) =>
    getCommunityConfigValueBool(state, ENABLED_SHARE_BADGE));

  const enabledAssignBadge = useMemo(() => {
    if (isCollaborator) {
      return enabledBadgesAssignedByLeader;
    }
    return isOwnProfile && enabledBadgesAssignedByCollaborator;
  }, [enabledBadgesAssignedByLeader, isOwnProfile, isCollaborator]);

  return (
    <Badges
      enabledAssignBadge={enabledAssignBadge}
      enabledShareBadge={isOwnProfile && enabledShareBadge}
      isOwnProfile={isOwnProfile}
      {...rest}
    />
  );
}

BadgesContainer.propTypes = {
  /**
   * Additional classes
   */
  className: PropTypes.string,
  /**
   * If the user is a collaborator
  */
  isCollaborator: PropTypes.bool,
  /**
   * If the user is the owner of the profile
   */
  isOwner: PropTypes.bool,
};

export default BadgesContainer;
