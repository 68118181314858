// @packages
import PropTypes from 'prop-types';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import take from 'lodash/take';
import { connect } from 'react-redux';

// @app
import { fixedFilters } from 'containers/Filters/sagas';
import { getCommunityConfigHasResult } from 'services/communityConfigs/selectors';

// @own
import { MEDIA_ICONS } from './constants';

export const getCurrentUser = ({ session }) => session && session.user;

export const getSocialMedias = (session) => {
  const socialNetworks = session?.communityConfigurations?.socialNetworks;

  const enabled = keys(socialNetworks)?.reduce((acc, cur) => {
    const icon = cur?.replace?.('share', '')?.toLowerCase();
    const type = MEDIA_ICONS[icon];
    const enabled =
      socialNetworks?.[cur] === '1' || cur === 'shareFacebookWorkplace';

    if (type) {
      acc.push({
        type,
        icon,
        enabled,
      });
    }

    return acc;
  }, []);

  return enabled;
};

export const getLocationFields = session => ({
  office: String(get(session, 'user.office.id', 0)),
  city: '0', // session.user.office.city.id,
  country: '0', // country: session.user.office.city.country.id,
});

export const initialLeaderboardInformation = (session) => {
  const userCustomFields = get(session, 'user.customFields', {});
  const customFields = get(session, 'communityConfigurations.customFields', []);
  const filters = take(
    reduce(
      get(session, 'communityConfigurations.leaderboardCustomFields', []),
      (fs, field) => {
        const customFilter = find(customFields, customField => customField.field === field);
        return customFilter ? concat(fs, [customFilter]) : fs;
      },
      map(filter(fixedFilters, f => f !== 'typeLeaderboard'), field => ({ field })),
    ),
    9,
  );
  const fields = reduce(
    get(session, 'communityConfigurations.leaderboardDefaultCustomFields', []),
    (fields, field) => merge(fields, { [field]: userCustomFields[field] }),
    getLocationFields(session),
  );
  return {
    fields: pick(fields, map(filters, f => f.field)),
    filters,
  };
};

const Authorization = ({
  children,
  communityConfigurations,
  hasCommunityConfigResult,
  user,
}) =>
  (user && communityConfigurations && hasCommunityConfigResult ? children : null);

Authorization.propTypes = {
  children: PropTypes.node.isRequired,
  hasCommunityConfigResult: PropTypes.bool,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    communityConfigurations: state.session?.communityConfigurations,
    hasCommunityConfigResult: getCommunityConfigHasResult(state),
    user: state.session?.user,
  };
}

export default connect(mapStateToProps)(Authorization);
