// @packages
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AvatarMedia from 'smu-ui-components/AvatarMedia';

// @app
import TrendItem from 'components/Trends/Item';

// @own
import './styles.scss';

const SendStarSuggestions = ({
  className,
  id,
  isFocused,
  mention,
  onMouseDown,
  onMouseEnter,
  onMouseUp,
}) => {
  const [keyUp, setKeyUp] = useState(false);
  const entryRef = useRef(null);
  const hasImage = mention?.profileImageCode || mention?.image;

  function handleOnKeyUp(event) {
    if (event.keyCode === 38 || event.keyCode === 40) {
      if (!keyUp) {
        setKeyUp(true);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleOnKeyUp);
    return () => document.removeEventListener('keyup', handleOnKeyUp);
  });

  useEffect(() => {
    if (keyUp && isFocused) {
      if ('scrollIntoViewIfNeeded' in document.body) {
        entryRef.current.scrollIntoViewIfNeeded(false);
      } else {
        entryRef.current.scrollIntoView(false);
      }
    }
  }, [isFocused, keyUp]);

  return (
    <div
      className={cn(
        'smu-suggestions',
        { 'smu-suggestions--focused:': isFocused },
        className,
      )}
      id={id}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseUp={onMouseUp}
      ref={entryRef}
    >
      {hasImage ? (
        <AvatarMedia
          firstName={mention?.name}
          height={36}
          job={mention?.job}
          key={mention?.id}
          profileImageCode={hasImage}
          useLink={false}
          width={36}
        />
      ) : (
        <TrendItem
          hashtag={mention?.hashtag}
          id={mention?.hid}
          uses={mention?.uses}
        />
      )}
    </div>
  );
};

SendStarSuggestions.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  isFocused: PropTypes.bool,
  mention: PropTypes.object.isRequired,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseUp: PropTypes.func,
};


export default SendStarSuggestions;
