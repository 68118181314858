// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

// @app
import CardVideo from 'components/CardVideo';
import useVideoAutoPlay from 'hooks/useVideoAutoPlay';

const CardVideoContainer = ({ videoId, playerProps, ...rest }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { isPlaying, isStarted, onPlay, isReady, onReady, onPause } =
    useVideoAutoPlay(videoId);

  useEffect(() => {
    if (!inView && isPlaying) {
      onPause();
    }
  }, [inView, isPlaying, onPause]);

  return (
    <CardVideo
      onClick={onPlay}
      onMouseEnter={onPlay}
      playerProps={{
        ...playerProps,
        controls: isStarted,
        loop: true,
        onReady,
        playing: isReady && isPlaying,
      }}
      ref={ref}
      showMedia={isStarted}
      showPlayIcon={!isStarted}
      {...rest}
    />
  );
};

CardVideoContainer.propTypes = {
  playerProps: PropTypes.object,
  videoId: PropTypes.number.isRequired,
};

export default CardVideoContainer;
