// @packages
import { getUserLanguage } from 'smu-utils/language';

// @app
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from './../../api';

export function getProfile(uid) {
  const baseUrl = `/v2/stellar/users/find/${uid}`;
  return apiInstance({
    url: addParams(baseUrl, { ...vo, ...times() }),
    method: 'get',
    headers: {
      language: 'en',
    },
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

export function getReceivedStars(id) {
  return apiInstance({
    url: addParams(`/v2/stellar/starsreceived/${id}`, { ...vo, ...times() }),
    method: 'get',
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

export function getSentStars(id) {
  const apiUrl = `/v2/stellar/starssent/${id}`;
  return apiInstance({
    url: addParams(apiUrl, { ...vo, ...times() }),
    method: 'get',
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

export function getReceivedStarsDetail(uid) {
  const baseURL = '/v2/stellar/stars/summary';
  const params = {
    ...vo,
    ...times(),
    userId: uid,
    filterBy: 'RECEIVED',
  };
  // TODO: this config should be obtained either from helper library or set in apiInstance
  const language = getUserLanguage();
  return apiInstance({
    url: addParams(baseURL, params),
    method: 'get',
    headers: {
      language,
    },
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

export function getEarnedBadges(id) {
  return apiInstance({
    url: addParams(`/v2/achievements/badges/${id}/earned`, { ...vo, ...times() }),
    method: 'get',
  })
    .then(({ data }) => (data.result.earned))
    .catch(errorHandler);
}

export function getUsersInteraction(id) {
  return apiInstance({
    url: addParams(`/v2/stellar/users/${id}/interaction`, { ...vo, ...times() }),
    method: 'get',
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}

export function getUserStarSummary(params) {
  return apiInstance({
    url: addParams('/v2/stellar/stars/summary', { params }),
    method: 'get',
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}
