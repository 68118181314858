import { paths } from 'utils/routes';

export default {
  path: paths.notFound(),
  getComponent(nextState, callback) {
    import('smu-layout-components/Page404').then((moduleA) => {
      callback(null, moduleA.default);
    });
  },
};
