export const INSIGHT_TYPES = {
  AREA_TURNOVER_RATE: 'AREA_TURNOVER_RATE',
  CULTURAL_ACTIVITY: 'CULTURAL_ACTIVITY',
  FEEDBACK_NOT_RECEIVED_FOR_YOUR_LED: 'FEEDBACK_NOT_RECEIVED_FOR_YOUR_LED',
  FEEDBACK_NOT_SENT_TO_YOUR_LED: 'FEEDBACK_NOT_SENT_TO_YOUR_LED',
  MANAGERS_NO_FEEDBACK: 'MANAGERS_NO_FEEDBACK',
  NO_ACTIVE_NEW_HIRES: 'NO_ACTIVE_NEW_HIRES',
  WHO_REPRESENTS: 'WHO_REPRESENTS',
};

export const optionsConfig = {
  chart: {
    spacingBottom: 3,
    spacingLeft: 3,
    spacingRight: 3,
    spacingTop: 3,
    height: 150,
    width: 200,
  },
  title: {
    text: null,
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    labels: {
      style: {
        width: '100px',
      },
      step: 1,
    },
    type: 'category',
  },
  yAxis: {
    labels: {
      enabled: false,
    },
    gridLineWidth: 0,
    minorGridLineWidth: 0,
    tickInterval: 10,
    title: {
      text: null,
    },
    plotLines: [{
      color: 'green',
      width: 2,
      value: 20,
      zIndex: 5,
    }],
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      borderWidth: 0,
      dataLabels: {
        color: '#FAFAFA',
        enabled: true,
        inside: true,
      },
      pointWidth: 20,
    },
  },
  tooltip: {
    enabled: false,
  },
  colors: ['violet', 'black'],
  series: {
    colors: ['violet', 'black'],
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 320,
        },
        chartOptions: {
          xAxis: {
            labels: {
              style: {
                width: '120px',
              },
            },
          },
        },
      },
    ],
  },
};
