// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';
import Feed from './Feed';

const COMPONENTS = {
  feed: Feed,
};

const getComponent = (name) => {
  const Component = COMPONENTS[name];
  return Component ? <Component /> : null;
};

const ComponentLayout = ({ params: { name } }) => (
  <div className="components-layout">{getComponent(name)}</div>
);

ComponentLayout.propTypes = {
  params: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default ComponentLayout;
