import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  CLEAN_PROFILE,
  REQUEST_RECEIVED_STARS,
  REQUEST_RECEIVED_STARS_SUCCESS,
  REQUEST_RECEIVED_STARS_FAILED,
  REQUEST_SENT_STARS,
  REQUEST_SENT_STARS_SUCCESS,
  REQUEST_SENT_STARS_FAILED,
  REQUEST_RECEIVED_STARS_DETAIL_SUCCESS,
  REQUEST_EARNED_BADGES,
  REQUEST_EARNED_BADGES_SUCCESS,
  REQUEST_EARNED_BADGES_FAILED,
  REQUEST_USERS_INTERACTION,
  REQUEST_USERS_INTERACTION_SUCCESS,
} from './actionTypes';

import { SET_PROFILE_BIO_SUCCESS } from '../ProfileBoard/ProfileBio/actionTypes';

const initialState = {
  result: null,
  receivedS: 0,
  sentS: 0,
  remainingS: 0,
  earnedB: 0,
  positionsAsSender: 0,
  positionAsReceiver: 0,
  fetching: true,
  fetchingRecS: true,
  fetchingSenS: true,
  fetchingEarB: true,
  receivedStarsDetail: null,
  fetchingPositions: true,
  error: '',
  isProfile: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROFILE:
      return {
        ...initialState,
        isProfile: payload.isProfile,
        fetching: true,
      };
    case CLEAN_PROFILE:
      return initialState;
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        result: payload,
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case REQUEST_RECEIVED_STARS:
      return {
        ...state,
        fetchingRecS: true,
      };
    case REQUEST_RECEIVED_STARS_SUCCESS:
      return {
        ...state,
        receivedS: payload,
        error: '',
      };
    case REQUEST_RECEIVED_STARS_FAILED:
      return {
        ...state,
        fetchingRecS: false,
        error: payload,
      };
    case REQUEST_SENT_STARS:
      return {
        ...state,
        fetchingSenS: true,
      };
    case REQUEST_SENT_STARS_SUCCESS:
      return {
        ...state,
        sentS: payload,
        fetchingSenS: false,
        error: '',
      };
    case REQUEST_SENT_STARS_FAILED:
      return {
        ...state,
        fetchingSenS: false,
        error: payload,
      };
    case REQUEST_EARNED_BADGES:
      return {
        ...state,
        fetchingEarB: true,
      };
    case REQUEST_EARNED_BADGES_SUCCESS:
      return {
        ...state,
        earnedB: payload,
        fetchingEarB: false,
        error: '',
      };
    case REQUEST_EARNED_BADGES_FAILED:
      return {
        ...state,
        fetchingEarB: false,
        error: payload,
      };
    case REQUEST_RECEIVED_STARS_DETAIL_SUCCESS:
      return {
        ...state,
        receivedStarsDetail: payload,
        fetchingRecS: false,
      };
    case SET_PROFILE_BIO_SUCCESS:
      return {
        ...state,
        result: { ...state.result, bio: payload },
      };
    case REQUEST_USERS_INTERACTION:
      return {
        ...state,
        fetching: true,
      };
    case REQUEST_USERS_INTERACTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        usersInteraction: payload,
      };
    default:
      return state;
  }
}
