// @packages
import React from 'react';
import Skeleton from 'smu-ui-components/Skeleton';

// @own
import './styles.scss';

const SkeletonHeader = () => (
  <div className="skeleton-header shadow-base rounded-lg">
    <Skeleton animation="wave" height={192} width="100%" />
  </div>
);

export default SkeletonHeader;
