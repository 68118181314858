// @packages
import React from 'react';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import InfiniteScroll from 'containers/InfiniteScroll';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const UserList = ({
  className,
  hasMore,
  id,
  onAvatarClick,
  onScrollEvent,
  result,
}) => (
  <div className={cn('kudos-expanded-user-list', className)} id={id}>
    <InfiniteScroll
      className="kudos-expanded-user-list__scroll"
      dataLength={result?.length}
      hasMore={hasMore}
      loadingType="starmeup"
      next={onScrollEvent}
      scrollableTarget={id}
      style={{ overflow: 'unset' }}
    >
      <div className="kudos-expanded-user-list__users">
        {result?.map((value) => (
          <AvatarMedia
            className="kudos-expanded-user-list__user"
            firstName={value?.name}
            identification={value?.uid}
            job={value?.identification}
            key={value?.id}
            lastName=""
            noHash
            onClick={onAvatarClick}
            profileImageCode={value?.imageCode}
          />
        ))}
      </div>
    </InfiniteScroll>
  </div>
);

UserList.defaultProps = {
  id: 'kudos-expanded-user-list',
  result: [],
};

UserList.propTypes = {
  className: PropTypes.string,
  hasMore: PropTypes.bool,
  id: PropTypes.string,
  onAvatarClick: PropTypes.func,
  onScrollEvent: PropTypes.func,
  result: PropTypes.array,
};

export default UserList;
