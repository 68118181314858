// @packages
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @packages
import StaticDNA from 'components/StaticDNA';

// @own
import './styles.scss';
import PieChart from './PieChart';

function ShareDNA({
  className,
  description,
  dna,
  organizationLogo,
  qualifiers,
  title,
  user,
}, ref) {
  return (
    <div className={cn('share-DNA', className)} ref={ref}>
      <header className="share-DNA__header" />
      <div className="share-DNA__info-user">
        <div className="share-DNA__user">
          <div className="share-DNA__user-wrapper">
            <img
              alt="avatar"
              className="share-DNA__avatar"
              src={user?.imageUrl}
            />
            <PieChart width="180px" height="180px" {...qualifiers} />
          </div>
          <div className="share-DNA__user-description">
            {user?.name && (
              <span className="share-DNA__user-name">{user?.name}</span>
            )}
            {user?.job && (
              <span className="share-DNA__user-job">{user?.job}</span>
            )}
          </div>
          <img
            alt="organization logo"
            className="share-DNA__organization-logo"
            src={organizationLogo}
          />
        </div>
      </div>
      <div className="share-DNA__stats">
        <StaticDNA className="share-DNA__stats-dna" values={dna} />
        <div className="share-DNA__dna-text">
          {title && <h4 className="share-DNA__title">{title}</h4>}
          {description && (
            <span className="share-DNA__description">{description}</span>
          )}
          <ul className="share-DNA__list">
            {qualifiers?.data
              ?.slice(0, 3)
              .map(({ value, color, text }, key) => (
                <li
                  className={cn('share-DNA__list-item', {
                    'share-DNA__list-item--disabled': !value,
                  })}
                  key={`list-item-${key}`}
                >
                  <span
                    className="share-DNA__list-circle"
                    style={{ backgroundColor: color }}
                  />
                  <span className="share-DNA__list-text">{text}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <img
        alt="share background"
        className="share-DNA__footer"
        src="/assets/img/share/dna-footer.svg"
      />
      <img
        alt="share-logo"
        className="share-DNA__logo"
        src="/assets/img/powered_logo.svg"
      />
    </div>
  );
}

ShareDNA.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  dna: PropTypes.array,
  qualifiers: PropTypes.array,
  title: PropTypes.string,
  user: PropTypes.object,
};

ShareDNA.defaultProps = {
  description: 'This represents my values in action',
  dna: [],
  qualifiers: [],
  title: 'This is my StarMeUp DNA',
  user: {},
};

export default forwardRef(ShareDNA);
