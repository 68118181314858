import {
  OS_GET_FILTERS_BY_USES,
  OS_GET_FILTERS_BY_USES_FAIL,
  OS_GET_FILTERS_BY_USES_SUCCESS,
  OS_GET_FILTERS_VALUES_BY_USES,
  OS_GET_FILTERS_VALUES_BY_USES_FAIL,
  OS_GET_FILTERS_VALUES_BY_USES_SUCCESS,
} from './actionTypes';

const initialState = {
  uses: {},
  values: {},
};

const initialStateFetch = {
  data: undefined,
  error: null,
  fetching: false,
};

const mergeState = (state, key, use, update) => ({
  ...state,
  [key]: {
    ...state[key],
    [use]: {
      ...state[key][use],
      ...update,
    },
  },
});

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case OS_GET_FILTERS_BY_USES:
      return mergeState(state, 'uses', payload.use, {
        ...initialStateFetch,
        fetching: true,
      });
    case OS_GET_FILTERS_BY_USES_FAIL:
      return mergeState(state, 'uses', payload.use, {
        ...initialStateFetch,
        error: payload.error,
      });
    case OS_GET_FILTERS_BY_USES_SUCCESS:
      return mergeState(state, 'uses', payload.use, {
        ...initialStateFetch,
        data: payload.data,
      });
    case OS_GET_FILTERS_VALUES_BY_USES:
      return mergeState(state, 'values', payload.use, {
        ...initialStateFetch,
        fetching: true,
      });
    case OS_GET_FILTERS_VALUES_BY_USES_FAIL:
      return mergeState(state, 'values', payload.use, {
        ...initialStateFetch,
        error: payload.error,
      });
    case OS_GET_FILTERS_VALUES_BY_USES_SUCCESS:
      return mergeState(state, 'values', payload.use, {
        ...initialStateFetch,
        data: payload.data,
      });
    default:
      return state;
  }
}
