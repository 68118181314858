// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @app
import ShareButtonsContainer from 'containers/ShareButtons';
import ShareContainer from 'containers/Share';
import commonMessages from 'common/messages';
import useToast from 'hooks/useToast';
import { TYPE_YEAR_IN_REVIEW } from 'containers/Share/constants';

const YIRShareContainer = ({ intl: { formatMessage }, onError, step }) => {
  const [shareUrl, setShareUrl] = useState();
  const showMessage = useToast();

  const handleOnError = () => {
    showMessage({
      message: formatMessage(commonMessages.smuCommonError),
    });

    if (onError) onError();
  };

  return (
    <div className="w-full">
      <ShareContainer
        onError={handleOnError}
        onSuccess={setShareUrl}
        step={step}
        type={TYPE_YEAR_IN_REVIEW}
      />
      <footer className="w-full p-3">
        <ShareButtonsContainer className="justify-center" shareUrl={shareUrl} />
      </footer>
    </div>
  );
};

YIRShareContainer.propTypes = {
  intl: PropTypes.object,
  onError: PropTypes.func,
  step: PropTypes.string,
};

export default injectIntl(YIRShareContainer);
