import {
  GET_CULTURAL_CATEGORY,
  GET_CULTURAL_CATEGORY_SUCCESS,
  GET_CULTURAL_CATEGORY_FAIL,
} from './actionTypes';

const defaultState = {
  result: {
    'os.cultural-level.name': undefined,
  },
  fetching: true,
  error: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CULTURAL_CATEGORY:
      return {
        ...state,
        fetching: true,
      };
    case GET_CULTURAL_CATEGORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        result: {
          'os.cultural-level.name': action.payload['os.cultural-level.name'],
        },
      };
    case GET_CULTURAL_CATEGORY_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
