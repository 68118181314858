// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withRootModal from 'smu-app-components/RootModalV2/withRootModal';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import { trackPageview } from 'smu-utils/gtm';

// @app
import ShareModal from 'containers/ShareModal';
import { SMU_GENERIC_MODAL } from 'containers/GenericModal/constants';

// @own
import './styles.scss';

function ShareLayout({ openModal, params: { type } }) {
  function handleError(error) {
    const errorMessage = error?.response?.data?.message || error?.message;
    const errorStack = error?.stack;
    const errorStatus = error?.response?.data?.status;
    const errorStatusCode = error?.response?.status;
    const baseUrl = `${getWebUrl()}/share/image_result_handler`;
    const query = `?errorMessage=${errorMessage}&errorStack=${errorStack}&errorStatus=${errorStatus}&errorStatusCode=${errorStatusCode}`;
    window.location.href = baseUrl + query;
  }

  function handleSuccess(shareImageUrl) {
    window.location.href = `${getWebUrl()}/share/image_result_handler?imageUrl=${shareImageUrl}`;
  }

  useEffect(() => {
    trackPageview({
      pageName: '/share',
    });
  }, []);

  useEffect(() => {
    if (type !== 'image_result_handler') {
      openModal({
        modalType: SMU_GENERIC_MODAL,
        modalProps: {
          renderModal: (props) => (
            <ShareModal
              onShareError={handleError}
              onShareSuccess={handleSuccess}
              type={type}
              {...props}
            />
          ),
        },
      });
    }
  }, [type]);

  return <div className="share-layout" />;
}

ShareLayout.propTypes = {
  openModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    type: PropTypes.string,
  }),
};

export default withRootModal(ShareLayout);
