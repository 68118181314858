// @packages
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import Button from 'smu-ui-components/ButtonV2';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { getWebUrl } from 'smu-utils/globalRequestHelper';
import { getPathHost } from 'smu-utils/storage';

// @app
import { ENABLED_COMMUNICATIONS, ENABLED_USEFUL_LINKS } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { getOsFeed } from 'containers/OSFeed/actions';
import { openNewTab } from 'utils/browser';
import { selectOsFeedError } from 'containers/OSFeed/selectors';
import {
  selectSessionUserRights,
  selectUserRoles,
} from 'containers/Authorization/selectors';
import CommunicationsFeed from 'containers/CommunicationsFeed';
import CommunicationsHeader from 'components/CommunicationsHeader';
import ErrorState from 'components/ErrorState';
import MainPanel from 'components/MainPanel';
import UsefulLinksWidget from 'containers/UsefulLinksWidget';

// @own
import { DEFAULT_PARAMS } from './constants';
import messages from './messages';
import './styles.scss';

const CommunicationsLayout = ({
  enabledCommunications,
  enabledUsefulLinks,
  error,
  getOsFeed,
  intl: {
    formatMessage,
  },
  rights,
  roles,
  router,
}) => {
  const isCusmizeEnabled = roles.find(r => r.role === 'ADMIN') ||
  rights.find(r => r.right === 'ORGANIZATION_ADMIN' || r.right === 'ORGANIZATION_MODERATOR');

  const handleReturnHome = () => {
    router.push('/home');
  };

  const handleRetry = () => {
    getOsFeed({
      params: {
        ...DEFAULT_PARAMS,
      },
    });
  };

  useEffect(() => {
    if (!enabledCommunications) {
      handleReturnHome();
    }
  }, []);

  return (
    <div className="communications">
      {!error && <CommunicationsHeader />}
      <div className={cn('communications__content', {
        'communications__content--error': error,
      })}
      >
        {error &&
          <ErrorState
            descriptionMessage={formatMessage(messages.communicationsErrorStateDescription)}
            onHomeClick={handleReturnHome}
            onRetryClick={handleRetry}
            retryMessage={formatMessage(messages.communicationsErrorStateRetry)}
            returnHomeMessage={formatMessage(messages.communicationsErrorStateReturnHome)}
            titleMessage={formatMessage(messages.communicationsErrorStateTitle)}
            errorImage={'assets/img/astronaut-error.png'}
          />}
        {!error &&
        <div className="communications__feed">
          <MainPanel>
            <CommunicationsFeed />
          </MainPanel>
        </div>}
        {!error &&
          <div className="communications__widget-container">
              {isCusmizeEnabled && (
              <Button
                className="communications__community-customize"
                color="communicationsHeader"
                onClick={() => openNewTab(`${getWebUrl('os-admin', getPathHost())}/accounts/communications/presentation`)}
              >
                {formatMessage(messages.communicationsCustomizeHeaderButton)}
              </Button>
              )}
            {enabledUsefulLinks &&
            <UsefulLinksWidget />}
          </div>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  enabledCommunications: getCommunityConfigValueBool(state, ENABLED_COMMUNICATIONS),
  enabledUsefulLinks: getCommunityConfigValueBool(state, ENABLED_USEFUL_LINKS),
  error: selectOsFeedError(state),
  rights: selectSessionUserRights(state),
  roles: selectUserRoles(state),
});

const actions = { getOsFeed };

export default withRouter(connect(mapStateToProps, actions)(injectIntl(CommunicationsLayout)));
