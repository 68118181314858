import { defineMessages } from 'react-intl';

export default defineMessages({
  sender: {
    id: 'leaderboard.sender',
    defaultMessage: 'SENDER',
  },
  receiver: {
    id: 'leaderboard.receiver',
    defaultMessage: 'RECEIVER',
  },
  setupFilters: {
    id: 'feed.setupFilters',
    defaultMessage: 'Set up your filters',
  },
  apply: {
    id: 'feed.applyFilters',
    defaultMessage: 'apply',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'cancel',
  },
  noResults: {
    id: 'feed.noResults',
    defaultMessage: 'No available results',
  },
});
