import { defineMessages } from 'react-intl';

export default defineMessages({
  withCardDiscardPost: {
    id: 'withCard.discardPost',
    defaultMessage: 'Discard Post',
  },
  withCardAreYouSure: {
    id: 'withCard.AreYouSure',
    defaultMessage: 'Are you sure you want to discard this post?',
  },
  withCardInputPlaceholder: {
    id: 'withCard.InputPlaceholder',
    defaultMessage: 'Leave a comment',
  },
});
