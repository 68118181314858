import {
  TYPE_BRIGHTENS,
  TYPE_LIKES,
} from 'containers/Modals/Interactions/constants';

export const TYPES_STARS_INTERACTIONS = {
  LIFT_STAR: TYPE_BRIGHTENS,
  LIKE: TYPE_LIKES,
};

export const normalizeStarsInteractions = (comments) =>
  comments?.reduce((acc, cur, index) => {
    const type = TYPES_STARS_INTERACTIONS[cur?.type];
    if (type) {
      if (!acc[type]) {
        acc[type] = {
          users: [],
        };
      }
      acc[type].users.push({
        ...cur?.user,
        position: index,
      });
    }
    return acc;
  }, {});
