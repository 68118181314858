export const CHILLOUT_STATUS = 'chillout-settings';
export const EMPTY_TEXT_BUTTON = 'SET UP CHILL OUT';
export const EMPTY_TEXT_DESCRIPTION = 'Update your preferences and see if something interesting is happening around.';
export const FORMAT_DAY = 'DD';
export const FORMAT_MONTH = 'MMM';
export const FORMAT_TIME = 'HH:mm';
export const REQUEST_ID_GET_CHILLOUT = 'REQUEST_ID_GET_CHILLOUT';
export const REQUEST_ID_GET_CHILLOUT_EVENT = 'REQUEST_ID_GET_CHILLOUT_EVENT';
export const TEXT_BUTTON = 'GET STARTED';
export const TEXT_DESCRIPTION_DEFAULT = 'Set up your preferences to start meeting your Globant colleagues';
export const TEXT_DESCRIPTION_ERROR = 'Cannot be displayed right now';
export const TEXT_TITLE = 'VIRTUAL CHILL OUT!';
export const TEXT_TITLE_ERROR = 'VIRTUAL CHILL OUT';
export const ANALYTICS_EVENTS = [
  {
    action: 'Cancel',
    category: 'Chill Modal',
    event: 'onCancelModal',
  },
  {
    action: 'Close',
    category: 'Chill Modal',
    event: 'onCloseModal',
  },
  {
    action: 'Join Event',
    category: 'Chill Widget',
    event: 'onJoinEvent',
  },
  {
    action: 'Next Event',
    category: 'Chill Widget',
    event: 'onNextEvent',
  },
  {
    action: 'Prev Event',
    category: 'Chill Widget',
    event: 'onPrevEvent',
  },
  {
    action: 'Open Modal by URL',
    category: 'Chill Modal',
    event: 'onOpenModalUrl',
  },
  {
    action: 'Save Preferences',
    category: 'Chill Modal',
    event: 'onSaveModal',
  },
  {
    action: 'Set preferences',
    category: 'Chill Widget',
    event: 'onSetupPreferences',
  },
  {
    action: 'Update preferences',
    category: 'Chill Widget',
    event: 'onUpdatePreferences',
  },
];

