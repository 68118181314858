// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { selectHasStarsToSend } from 'services/sendStarFlow/selectors';
import { useSelector } from 'react-redux';

// @app
import ActionBrightenContainer from 'containers/Cards/components/ActionBrighten';

const StarActionBrightenContainer = ({
  liftState,
  onClickCount,
  onClickIcon,
  quantityUsersLift,
  ...rest
}) => {
  const hasStarsToSend = useSelector(selectHasStarsToSend);
  const isBrighten = liftState === 2;
  const enabledClickIcon = hasStarsToSend && liftState === 1;
  const enabledClickCount = quantityUsersLift > 0;

  return (
    <ActionBrightenContainer
      active={isBrighten}
      count={quantityUsersLift}
      onClickCount={enabledClickCount ? onClickCount : undefined}
      onClickIcon={enabledClickIcon ? onClickIcon : undefined}
      {...rest}
    />
  );
};

StarActionBrightenContainer.propTypes = {
  liftState: PropTypes.number,
  onClickCount: PropTypes.func,
  onClickIcon: PropTypes.func,
  quantityUsersLift: PropTypes.number,
};

export default StarActionBrightenContainer;
