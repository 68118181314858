// @packages
import React from 'react';
import Popup from 'smu-ui-components/Popup';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'utils/gtm';
import { useSelector } from 'react-redux';

// @app
import UserPopupOther from 'containers/UserPopup/Other';
import UserPopupOwner from 'containers/UserPopup/Owner';
import { selectSessionUserId } from 'containers/Authorization/selectors';

// @own
import messages from './messages';
import './styles.scss';

const MentionsPopup = ({ intl, mention, userId }) => {
  const ownId = useSelector(selectSessionUserId);
  const isOwner = userId === ownId;

  const handleClickProfile = () => {
    trackEvent({
      category: 'Profile',
      action: 'Click',
      label: 'View Mention',
    });
  };

  const messageViewProfile = intl.formatMessage(messages.viewProfileText);

  return (
    <span className="mentions-popup">
      <Popup
        content={
          isOwner ? (
            <UserPopupOwner
              messageViewProfile={messageViewProfile}
              onClickProfile={handleClickProfile}
              userId={userId}
            />
          ) : (
            <UserPopupOther
              messageViewProfile={messageViewProfile}
              onClickProfile={handleClickProfile}
              userId={userId}
            />
          )
        }
        flowing
        hoverable
        position="top center"
        suiteInverted
        trigger={<span>{mention}</span>}
      />
    </span>
  );
};

MentionsPopup.propTypes = {
  userId: PropTypes.number.isRequired,
  mention: PropTypes.node.isRequired,
  intl: PropTypes.object,
};

export default injectIntl(MentionsPopup);
