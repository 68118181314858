import {
  SET_COOKIES_CONSENT_DECISION,
  SET_COOKIES_CONSENT_DECISION_SUCCESS,
  SET_COOKIES_CONSENT_DECISION_ERROR,
} from './actionTypes';

export function setCookiesConsentDecision(decision) {
  return {
    type: SET_COOKIES_CONSENT_DECISION,
    decision,
  };
}

export function setCookiesConsentDecisionSucess(response) {
  return {
    type: SET_COOKIES_CONSENT_DECISION_SUCCESS,
    payload: response,
  };
}

export function setCookiesConsentDecisionError(errorMessage) {
  return {
    type: SET_COOKIES_CONSENT_DECISION_ERROR,
    payload: errorMessage,
  };
}
