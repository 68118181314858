import { defineMessages } from 'react-intl';

export default defineMessages({
  surveyAnswerDetailAnswerTitle: {
    id: 'surveyAnswerDetail.answerTitle',
    defaultMessage: 'Answer',
  },
  surveyAnswerDetailOptionTitle: {
    id: 'surveyAnswerDetail.optionTitle',
    defaultMessage: 'Option',
  },
  surveyAnswerDetailUsersTitle: {
    id: 'surveyAnswerDetail.usersTitle',
    defaultMessage: 'Users',
  },
});
