// @packages
import React from 'react';
import Icon from 'smu-ui-components/Icon';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';
import messages from '../messages';

function QuestionnaireError({ intl: { formatMessage } }) {
  return (
    <div className="questionnaires-flex-error">
      <Icon
        className="questionnaires-flex-error__icon"
        color="white"
        icon="no-connection-icon"
        size={100}
      />
      <h4 className="questionnaires-flex-error__text">
        {formatMessage(messages.QuestionnairesFlexError)}
      </h4>
    </div>
  );
}

QuestionnaireError.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(QuestionnaireError);
