export const buildOptions = {
    chart: {
      height: 300,
      spacingBottom: 10,
      spacingTop: 10,
    },
    title: {
      text: null,
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineColor: '#e6e6e6',
    },
    yAxis: {
      minRange: 10,
      floor: 0,
      gridLineDashStyle: 'longdash',
    },
    legend: {
      enabled: true,
    },
  };
