// @own
import Emoji from './Types/Emoji';
import Image from './Types/Image';
import Text from './Types/Text';
import SendStar from './Types/SendStar';
import TextField from './Types/TextField';
import Nps from './Types/Nps';

export const BG_TYPE_COLOR = 'COLOR';
export const BG_TYPE_GRADIENT = 'GRADIENT';
export const BG_TYPE_IMAGE = 'IMAGE';
export const REQUEST_VOTE_QUESTION = 'REQUEST_VOTE_QUESTION';
export const SELECT_TYPE_BOOL = 'BOOL';
export const SELECT_TYPE_ID_LIST = 'ID_LIST';
export const SELECT_TYPE_TEXT = 'TEXT';
export const SELECT_TYPE_NUMERIC = 'NUMERIC';
export const TYPE_EMOJI = 'EMOJI';
export const TYPE_IMAGE = 'IMAGE';
export const TYPE_LIKERT = 'LIKERT';
export const TYPE_SEND_STAR = 'SEND_STAR';
export const TYPE_TEXT = 'TEXT';
export const TYPE_TEXTFIELD = 'TEXTFIELD';
export const TYPE_NPS = 'NPS';

export const COMPONENTS_TYPES = {
  [TYPE_EMOJI]: Emoji,
  [TYPE_IMAGE]: Image,
  [TYPE_LIKERT]: Text,
  [TYPE_SEND_STAR]: SendStar,
  [TYPE_TEXTFIELD]: TextField,
  [TYPE_TEXT]: Text,
  [TYPE_NPS]: Nps,
};

export const PATH_BY_TYPE = {
  [TYPE_EMOJI]: 'emoji',
  [TYPE_IMAGE]: 'path',
  [TYPE_LIKERT]: 'text',
  [TYPE_TEXT]: 'text',
};
