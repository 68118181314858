// @app
import { errorHandler } from 'utils/errorHandler';
import { defaultApi } from 'utils/requestHelper';

export function getCommunityMember({ userId }) {
  return defaultApi({
    url: `/os-member/api/v2/community/members/${userId}`,
    data: {},
  })
    .then((data) => data)
    .catch(errorHandler);
}
