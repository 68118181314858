import { defineMessages } from 'react-intl';

export default defineMessages({
  congratulationsHeaderTitle: {
    id: 'congratulations.header.title',
    defaultMessage: 'Celebrations',
  },
  congratulationsHeaderSubtitle: {
    id: 'congratulations.header.subtitle',
    defaultMessage:
      'Celebrating birthdays and anniversaries within the company will make your colleagues feel valued and appreciated.',
  },
  congratulationsBirthdayTabTitle: {
    id: 'congratulations.birthday.tabTitle',
    defaultMessage: 'Birthdays',
  },
  congratulationsBirthdayPlaceholder: {
    id: 'congratulations.birthday.placeholder',
    defaultMessage: 'Write a Happy Birthday message',
  },
  congratulationsAnniversaryTabTitle: {
    id: 'congratulations.anniversary.tabTtitle',
    defaultMessage: 'Anniversaries',
  },
  congratulationsAnniversaryPlaceholder: {
    id: 'congratulations.anniversary.placeholder',
    defaultMessage: 'Write a Happy Anniversary message',
  },
  congratulationsYouCongratulated: {
    id: 'congratulations.you.congratulated',
    defaultMessage: 'You congratulated',
  },
  congratulationsViewCard: {
    id: 'congratulations.view.card',
    defaultMessage: 'View congratulations card.',
  },
  congratulationsEmptyStateTitle: {
    id: 'congratulations.emptyState.title',
    defaultMessage: 'THERE ARE NO SPECIAL CELEBRATIONS TODAY',
  },
  congratulationsEmptyStateSubtitle: {
    id: 'congratulations.emptyState.subtitle',
    defaultMessage:
      "You can always make someone's day special by sending a star!",
  },
  congratulationsErrorInvalidDate: {
    id: 'congratulations.error.invalidDate',
    defaultMessage: 'The date is not valid',
  },
});
