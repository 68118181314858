import moment from 'moment';
import { omit } from 'lodash';
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { fixedFilters } from 'containers/Filters/sagas';


import { addParams, times, vo } from './../../api';

const periodToTimestamps = (period, now) => {
  const innerPeriodToTimestamps = () => {
    switch (period) {
      case 'thisMonth':
        return [
          moment(now).startOf('month')
            .valueOf(),
          now,
        ];
      case 'oneMonthBefore':
        return [
          moment(now).startOf('month').subtract(1, 'month')
            .valueOf(),
          moment(now).startOf('month')
            .valueOf(),
        ];
      case 'twoMonthBefore':
        return [
          moment(now).startOf('month').subtract(2, 'month')
            .valueOf(),
          moment(now).startOf('month').subtract(1, 'month')
            .valueOf(),
        ];
      case 'threeMonthBefore':
        return [
          moment(now).startOf('month').subtract(3, 'month')
            .valueOf(),
          moment(now).startOf('month').subtract(2, 'month')
            .valueOf(),
        ];
      case 'thisYear':
        return [
          moment(now).startOf('year')
            .valueOf(),
          now,
        ];
      case 'lastYear':
        return [
          moment(now).startOf('year').subtract(1, 'year')
            .valueOf(),
          moment(now).startOf('year')
            .valueOf(),
        ];
      case 'allTime':
        return [0, now];
      default:
        return [now, now];
    }
  };
  const [from, to] = innerPeriodToTimestamps();
  return {
    fromStringDate: from,
    toStringDate: to,
  };
};

export function getLeaderboard(fields = {}) {
  const { periodTime = 'thisMonth' } = fields;
  const baseUrl = '/v2/stellar/customFields/leaderboard';
  const config = {
    customFields: JSON.stringify(omit(fields, fixedFilters)),
    countryId: fields.country || 0,
    cityId: fields.city || 0,
    organizationOffice: fields.office || 0,
    period: 4,
    ...periodToTimestamps(periodTime, times().timestamp),
    organizationValue: fields.organizationValue || 0,
    typeLeaderboard: fields.typeLeaderboard === 'RECEIVER' ? 1 : 0,
    size: 10,
  };
  const params = {
    ...vo,
    ...times(),
    ...config,
  };
  return apiInstance({
    url: addParams(baseUrl, params),
    method: 'get',
  })
    .then(({ data }) => (data.result))
    .catch(errorHandler);
}
