// @own
import {
  CHANGE_LANGUAGE,
  REQUEST_LANGUAGES,
  REQUEST_LOCALES,
} from './actionTypes';

export function requestLocales(payload) {
  return {
    type: REQUEST_LOCALES,
    payload,
  };
}

export function requestLanguages(payload) {
  return {
    type: REQUEST_LANGUAGES,
    payload,
  };
}

export function changeLanguage(payload) {
  return {
    type: CHANGE_LANGUAGE,
    payload,
  };
}
