import {
  getCommunityCounters,
  getCommunityCountersSuccess,
  getCommunityCountersError,
} from './actions';

const initialState = {
  starsSent: undefined,
  activeUsers: undefined,
  fetching: false,
  error: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case getCommunityCounters().type:
      return {
        ...state,
        fetching: true,
      };
    case getCommunityCountersSuccess().type:
      return {
        ...state,
        starsSent: payload.starsSent,
        activeUsers: payload.activeUsers,
        fetching: false,
      };
    case getCommunityCountersError().type:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    default:
      return state;
  }
}
