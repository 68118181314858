// @packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useModal from 'smu-custom-hooks/useModal';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { connect, useSelector } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { selectItemById } from 'services/congratulations/selectors';
import { selectRequest } from 'smu-utils/reduxRequests/selectors';
import {
  actionAddComment,
  actionAddItem,
  actionAddLike,
  actionRemoveLike,
  actionUpdateComments,
} from 'services/congratulations/actions';

// @app
import ModalInteractions from 'containers/Modals/Interactions';
import { TYPE_ALL, TYPE_LIKES } from 'containers/Modals/Interactions/constants';
import { capitalize } from 'utils/formatString';
import { selectSessionUserId, selectCommunityName } from 'containers/Authorization/selectors';
import { trackEvent } from 'utils/gtm';

// @own
import {
  requestId,
  requestIdAddComment,
  requestIdAddlike,
  requestIdByUser,
  requestIdComments,
  requestIdLikes,
  requestIdUnlike,
} from './requestIds';
import {
  apiAddComment,
  apiAddLike,
  apiGetByUserId,
  apiGetInteractionById,
  apiGetInteractionsById,
  apiUnlike,
} from './api';
import messages from './messages';
import normalice, { normaliceComments, normaliceLikes } from './normalice';
import { ICONS, REWARDS_URL } from './constants';
import { selectMentionEnabled } from './selectors';

function withCongratulation(WrappedComponent) {
  function CongratulationHOC({
    actionAddComment,
    actionAddItem,
    actionAddLike,
    actionRemoveLike,
    actionRequestInit,
    actionUpdateComments,
    communityName,
    expandedComments,
    initialData,
    interactionId,
    linkEnabled,
    mentionsEnabled,
    onError,
    referredYear,
    requestAddComment,
    requestAddLike,
    requestByUser,
    requestInteraction,
    requestLikes,
    requestUnlike,
    rewardsEnabled,
    sessionUserId,
    showButton,
    type,
    userId,
  }) {
    const modal = useModal();
    const [congratulationId, setCongratulationId] = useState(interactionId);
    const [showLoader, setShowLoader] = useState(false);
    const [isLoadingLike, setIsLoadingLike] = useState(false);
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const item = useSelector((state) => selectItemById(state, congratulationId));
    const [commentsToggle, setCommentsToggle] = useState(expandedComments);
    const prevRequestByUser = usePrevious(requestByUser);
    const currentType = item?.type || type;
    const like = item?.reactions?.find((r) => r?.type === 'LIKE');
    const liked = like?.hasInteracted;
    const likesCount = like?.count;
    const comment = item?.reactions?.find((r) => r?.type === 'COMMENT');
    const commentsCount = comment?.count;
    const id = interactionId || userId;
    const shortType = currentType
      ? currentType.replace('INTERACTION_', '').toLowerCase()
      : '';
    const isOwner = sessionUserId === item?.user?.id;
    const isLoading = requestInteraction?.requesting || requestByUser?.requesting;
    const isError = requestInteraction?.error || requestByUser?.error;
    const baseUrl = `${window.location.origin}/home?modal=congratulation`;
    const detailUrlById = `${baseUrl}&id=${interactionId}`;
    const detailUrlByUserId = `${baseUrl}&type=${currentType}&userId=${userId}`;
    const detailUrl = interactionId ? detailUrlById : detailUrlByUserId;

    function isRequestSuccess(prev, current) {
      return prev && prev.requesting && !current.requesting && !current.error;
    }

    function handleAddItem(data) {
      const item = normalice(data);
      actionAddItem(item?.id, item);
    }

    function handleUpdateComments(data) {
      actionUpdateComments(item?.id, normaliceComments(data?.result));
    }

    function getText(key, values) {
      const id = `congratulation${capitalize(shortType)}${key}`;
      const text = messages[id];

      if (text) {
        return <FormattedMessage {...text} values={values} />;
      }

      return '';
    }

    function getTexts() {
      return {
        button: rewardsEnabled && isOwner
          ? getText('ButtonRewards')
          : getText('Button'),
        count: getText(`Count${isOwner ? 'Owner' : ''}`, {
          xxx: <strong>{likesCount + commentsCount}</strong>,
          xxxx: <strong>{item?.user?.firstName}</strong>,
        }),
        description: getText('Description'),
        title: getText('Title', {
          xxx: item?.user?.firstName,
        }),
        userDescription: rewardsEnabled && isOwner
          ? getText('UserDescriptionRewards', { xxx: communityName })
          : undefined,
      };
    }

    function getLikeInteractions() {
      const interactions = requestLikes?.result?.result;
      const isLoading = requestLikes?.requesting;
      const users = isLoading ? [] : normaliceLikes(interactions);

      return {
        [TYPE_ALL]: {
          total: likesCount,
          isLoading,
        },
        [TYPE_LIKES]: {
          isLoading,
          total: likesCount,
          users,
        },
      };
    }

    function getInteractionByUserId() {
      actionRequestInit({
        api: apiGetByUserId,
        id: requestIdByUser(id),
        params: {
          isOwner,
          referredYear,
          types: currentType,
          userId,
        },
        onSuccess: (data) => {
          handleAddItem(data);
          setIsLoadingLike(false);
          setIsLoadingComments(false);
        },
        onError: (error) => {
          setIsLoadingLike(false);
          setIsLoadingComments(false);
          if (onError) onError(error);
        },
      });
    }

    function getInteractionById(id) {
      actionRequestInit({
        api: apiGetInteractionById,
        id: requestId(id),
        params: {
          interactionId,
        },
        onSuccess: handleAddItem,
        onError,
      });
    }

    function getCommentsById() {
      setIsLoadingComments(true);
      actionRequestInit({
        api: apiGetInteractionsById,
        id: requestIdComments(id),
        params: {
          interactionId: item?.id,
          types: 'COMMENT',
        },
        onSuccess: (data) => {
          handleUpdateComments(data);
          setIsLoadingComments(false);
        },
        onError: () => setIsLoadingComments(false),
      });
    }

    function getLikesById() {
      actionRequestInit({
        api: apiGetInteractionsById,
        id: requestIdLikes(id),
        params: {
          interactionId: item?.id,
          types: 'LIKE',
        },
      });
    }

    function addLike() {
      setIsLoadingLike(true);
      actionRequestInit({
        api: apiAddLike,
        id: requestIdAddlike(id),
        params: {
          activityId: item?.id,
          type: currentType,
          userId: item?.user?.id,
        },
        onSuccess: () => {
          if (item?.id) {
            actionAddLike(item.id);
            setIsLoadingLike(false);
          } else {
            getInteractionByUserId();
          }

          trackEvent({
            action: 'like',
            category: 'card_activity',
            id: interactionId,
            type: shortType,
          });
        },
        onError: () => setIsLoadingLike(false),
      });
    }

    function unlike() {
      setIsLoadingLike(true);
      actionRequestInit({
        api: apiUnlike,
        id: requestIdUnlike(id),
        params: {
          activityId: item.id,
        },
        onSuccess: () => {
          actionRemoveLike(item?.id);
          setIsLoadingLike(false);

          trackEvent({
            action: 'unlike',
            category: 'card_activity',
            id: interactionId,
            type: shortType,
          });
        },
        onError: () => setIsLoadingLike(false),
      });
    }

    function addComment(comment) {
      setIsLoadingComments(true);
      actionRequestInit({
        api: apiAddComment,
        id: requestIdAddComment(id),
        params: {
          comment,
          sourceId: item?.id,
          sourceType: currentType,
          toUserId: item?.user?.id,
        },
        onSuccess: () => {
          if (item?.id) {
            actionAddComment(item?.id);
            getCommentsById();
          } else {
            getInteractionByUserId();
          }

          trackEvent({
            action: 'comment_success',
            category: 'card_activity',
            id: interactionId,
            type: shortType,
          });
        },
        onError: () => setIsLoadingComments(false),
      });
    }

    function handleLike() {
      if (requestAddLike.requesting || requestUnlike.requesting) {
        return;
      }

      if (liked) {
        unlike();
      } else {
        addLike();
      }
    }

    function handleLikeText() {
      if (item?.id) {
        getLikesById();
        modal.open({
          contentId: interactionId,
          contentType: shortType,
          defaultType: TYPE_LIKES,
        });

        trackEvent({
          action: 'click',
          category: 'card_activity',
          id: interactionId,
          label: 'view_likes',
          type: shortType,
        });
      }
    }

    function handleComment(comment) {
      const validComment = comment && String(comment).trim();
      if (!requestAddComment.requesting && validComment) {
        addComment(comment);
      }
    }

    function handleCommentsToggle() {
      setCommentsToggle(!commentsToggle);

      trackEvent({
        action: 'click',
        category: 'card_activity',
        id: interactionId,
        label: 'comment',
        type: shortType,
      });
    }

    function handleButtonClick() {
      if (rewardsEnabled && isOwner) {
        window.open(REWARDS_URL, '_blank');
      } else {
        setCommentsToggle(true);
      }

      trackEvent({
        action: 'click',
        category: 'card_activity',
        id: interactionId,
        label: 'congrats_CTA',
        type: shortType,
      });
    }

    useEffect(() => {
      if (initialData) {
        handleAddItem(initialData);
      }
    }, []);

    useEffect(() => {
      if (!initialData && interactionId) {
        getInteractionById(interactionId);
        setShowLoader(true);
      }
    }, [initialData]);

    useEffect(() => {
      if (!initialData && userId) {
        getInteractionByUserId(userId);
        setShowLoader(true);
      }
    }, [userId]);

    useEffect(() => {
      if (commentsToggle && item?.id && !item?.comments?.length) {
        getCommentsById();
      }
    }, [commentsToggle, item?.id]);

    useEffect(() => {
      if (isRequestSuccess(prevRequestByUser, requestByUser)) {
        const id = requestByUser?.result?.id;
        setCongratulationId(id);
      }
    }, [prevRequestByUser, requestByUser]);

    useEffect(() => {
      if (item && showLoader) {
        setShowLoader(false);
      }
    }, [item, showLoader]);

    return (
      <>
        {modal.isOpen && (
          <ModalInteractions
            interactions={getLikeInteractions()}
            onClose={modal.close}
            open={modal.isOpen}
            {...modal.data}
          />
        )}
        <WrappedComponent
          comments={item?.comments}
          commentsCount={commentsCount}
          commentsToggle={commentsToggle}
          detailUrl={detailUrl}
          icon={ICONS[shortType]}
          isError={isError}
          isLoading={showLoader && (!item || isLoading)}
          isLoadingComments={isLoadingComments}
          isLoadingLike={isLoadingLike}
          isOwner={isOwner}
          liked={liked}
          likesCount={likesCount}
          linkEnabled={linkEnabled}
          mentionsEnabled={mentionsEnabled}
          onButtonClick={handleButtonClick}
          onComment={handleComment}
          onCommentsToggle={handleCommentsToggle}
          onLike={handleLike}
          onLikeText={handleLikeText}
          shortType={shortType}
          showButton={(rewardsEnabled && isOwner) || showButton}
          texts={getTexts()}
          {...item}
          type={currentType}
        />
      </>
    );
  }

  CongratulationHOC.defaultProps = {
    referredYear: new Date().getFullYear(),
  };

  CongratulationHOC.propTypes = {
    actionAddComment: PropTypes.func,
    actionAddItem: PropTypes.func,
    actionAddLike: PropTypes.func,
    actionRemoveLike: PropTypes.func,
    actionRequestInit: PropTypes.func.isRequired,
    addToastMessage: PropTypes.func.isRequired,
    communityName: PropTypes.string,
    expandedComments: PropTypes.bool,
    initialData: PropTypes.object,
    interactionId: PropTypes.string,
    linkEnabled: PropTypes.bool,
    mentionsEnabled: PropTypes.bool,
    onError: PropTypes.func,
    openFullscreenModal: PropTypes.func.isRequired,
    referredYear: PropTypes.number,
    requestAddComment: PropTypes.object,
    requestByUser: PropTypes.object.isRequired,
    requestComments: PropTypes.object.isRequired,
    requestInteraction: PropTypes.object.isRequired,
    rewardsEnabled: PropTypes.bool,
    sessionUserId: PropTypes.string,
    showButton: PropTypes.bool,
    type: PropTypes.string,
    userId: PropTypes.string,
  };

  const mapStateToProps = (state, ownProps) => {
    const id = ownProps?.interactionId || ownProps?.userId;

    return {
      communityName: selectCommunityName(state),
      mentionsEnabled: selectMentionEnabled(state),
      requestAddComment: selectRequest(state, requestIdAddComment(id)),
      requestAddLike: selectRequest(state, requestIdAddlike(id)),
      requestByUser: selectRequest(state, requestIdByUser(id)),
      requestComments: selectRequest(state, requestIdComments(id)),
      requestInteraction: selectRequest(state, requestId(id)),
      requestLikes: selectRequest(state, requestIdLikes(id)),
      requestUnlike: selectRequest(state, requestIdUnlike(id)),
      sessionUserId: selectSessionUserId(state),
    };
  };

  return connect(mapStateToProps, {
    actionAddComment,
    actionAddItem,
    actionAddLike,
    actionRemoveLike,
    actionRequestInit,
    actionUpdateComments,
    addToastMessage,
  })(injectIntl(CongratulationHOC));
}

export default withCongratulation;
