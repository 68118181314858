// @packages
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// @app
import CongratulationEvent from 'components/OS/Event/CongratulationEvent';
import ModalCongratulation from 'components/Modals/Congratulation';
import { ENABLED_REWARDS } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';

// @own
import { eventOnOpenModal, eventOnCloseModal } from './analytics';

const ModalCongratulationContainer = ({
  className,
  id,
  onClose,
  open,
  type,
  userId,
}) => {
  const rewardsEnabled = useSelector(
    state => getCommunityConfigValueBool(state, ENABLED_REWARDS),
  );

  useEffect(() => {
    eventOnOpenModal();
    return () => eventOnCloseModal();
  }, []);

  return (
    <ModalCongratulation className={className} onClose={onClose} open={open}>
      <CongratulationEvent
        expandedComments
        interactionId={id}
        linkEnabled={false}
        onError={onClose}
        rewardsEnabled={rewardsEnabled}
        showButton={false}
        type={type}
        userId={userId}
      />
    </ModalCongratulation>
  );
};

export default ModalCongratulationContainer;
