import { map, merge } from 'lodash';
import { paths } from 'utils/routes';
import withNextPage from 'containers/withNextPage';
import { cleanProfile } from 'containers/withProfile/actions';
import { cleanFeed } from 'containers/withFeed/actions';
import { getDefaultValues } from 'containers/FiltersV2/functions';
import { cleanRecognitionFields, setRecognitionFields } from 'containers/Recognition/actions';
import Timeline from 'components/Timeline';

export default ({ dispatch, getState }) => ({
  path: paths.timeline(),
  component: withNextPage(Timeline),
  onEnter: () => {
    const {
      filtersV2: { PROFILE, PROFILE_SENT },
      recognition: {
        receivedFields,
        receivedFiltersApplied,
        sentFields,
        sentFiltersApplied,
      },
    } = getState();
    const defaultReceivedValues = getDefaultValues(PROFILE);
    const defaultSentValues = getDefaultValues(PROFILE_SENT);
    const fields = {
      receivedFields: merge(defaultReceivedValues, receivedFields),
      receivedFiltersApplied,
      sentFields: merge(defaultSentValues, sentFields),
      sentFiltersApplied,
    };
    dispatch(setRecognitionFields(fields));
    window.scrollTo(0, 0);
  },
  onLeave: () => map([cleanProfile(), cleanRecognitionFields(), cleanFeed('options.hashtag')], dispatch),
});
