// @app
import { buildModalUrl } from 'containers/QueryModals/helpers';

/**
 * Extracts the ID from the given pathname using the provided regex pattern.
 *
 * @param {RegExp} regex - The regular expression pattern to match against the pathname.
 * @param {string} pathname - The pathname from which to extract the ID.
 * @returns {string|null} - The extracted ID or null if no match is found.
 */
const getIdByPathname = (regex, pathname) => {
  const match = pathname?.match(regex);
  return match?.[1];
};

/**
 * Builds the URL for a post modal based on the given pathname.
 *
 * @param {string} pathname - The current pathname.
 * @returns {string} The URL for the post modal.
 */
const buildPostModalUrl = (pathname) => {
  const regex = /\/posts\/(\d+)/;
  const id = getIdByPathname(regex, pathname);
  return buildModalUrl({ modal: 'post', id });
};

/**
 * Builds a modal URL for the "star" modal type.
 *
 * @param {string} pathname - The current pathname.
 * @returns {string} The modal URL for the "star" modal type.
 */
const buildStarModalUrl = (pathname) => {
  const regex = /\/me\/activity\/(\d+)/;
  const id = getIdByPathname(regex, pathname);
  return buildModalUrl({ modal: 'star', id });
};

/**
 * Normalizes the given URL pathname.
 *
 * @param {string} pathname - The URL pathname to be normalized.
 * @returns {string} The normalized URL pathname.
 */
const normalizeUrl = (pathname) => {
  if (/^\/posts\//.test(pathname)) {
    return buildPostModalUrl(pathname);
  }

  if (/^\/me\/activity\//.test(pathname)) {
    return buildStarModalUrl(pathname);
  }

  return pathname;
};

export default normalizeUrl;
