// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { openFullscreenModal } from 'smu-app-components/FullScreenModalV2/actions';
import { withRouter } from 'react-router';

// @app
import DiscoverOptions from 'components/DiscoverOptions';
import ToastNotificationAction from 'components/ToastNotificationAction';
import copyToClipboard from 'utils/copyToClipboard';
import useDiscover from 'hooks/useDiscover';
import { CREATE_CHILLOUT_VIRTUAL, ENABLED_DISCOVERY_PEOPLE } from 'services/communityConfigs/constants';
import { DISCOVER_CREATE_CHILLOUT } from 'containers/CreateChillout/constants';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { commaSeparatedUsers } from 'containers/CreateChillout/helpers';
import { getAvailableStar, hasStarsToSend } from 'containers/SendStar/helpers';
import { getCommunityConfigValue } from 'services/communityConfigs/selectors';
import { getLabelFilters } from 'utils/discover';
import { getStarsValues } from 'services/sendStarFlow/selectors';
import { trackEvent } from 'utils/gtm';

// @own
import messages from './messages';

function DiscoverOptionsContainer({
  addToastMessage,
  createChillOutVirtual,
  enableOptions,
  intl: { formatMessage },
  openFullscreenModal,
  remainingStars,
  router,
  stars,
  users,
}) {
  const discover = useDiscover();

  const handleCopyToClipboard = () => {
    trackEvent({
      action: 'copy_emails',
      category: 'discover',
      label: getLabelFilters(discover.filters),
      list_count: discover.totalUsersSelected,
    });
    trackEvent({
      action: 'copy_emails_success',
      category: 'discover',
      label: getLabelFilters(discover.filters),
      list_count: discover.totalUsersSelected,
    });
    copyToClipboard(commaSeparatedUsers(users));
    addToastMessage({
      message: formatMessage(messages.discoveryOptionsCopiedToClipboard),
      timeout: 15000,
      customClose: props => (
        <ToastNotificationAction
          {...props}
          className="discover-options__close-toast"
          onClick={() => {
            trackEvent({
              action: 'copy_emails_success',
              category: 'discover',
              label: 'got_it',
            });
          }}
        >
          {formatMessage(messages.discoveryOptionsGotIt)}
        </ToastNotificationAction>
      ),
    });
  };

  const handleSendStars = () => {
    if (hasStarsToSend(remainingStars)) {
      const initialStarFlow = getAvailableStar(remainingStars, users, stars);
      const { star } = initialStarFlow;

      if (star) {
        router.push(buildSendStarModalUrl({
          params: {
            coreValueId: star?.id,
            users,
          },
        }));
      } else {
        addToastMessage({
          message: formatMessage(messages.discoverOptionsSendStarNotEnough),
          timeout: 15000,
        });
      }
    } else {
      addToastMessage({
        message: formatMessage(messages.discoverOptionsSendStarUnabled),
        timeout: 15000,
      });
    }
  };

  let options = [
    {
      enabled: enableOptions,
      icon: 'copy',
      id: 'copy',
      key: 'discover-option-copy',
      onClick: handleCopyToClipboard,
      tooltip: formatMessage(messages.discoverOptionsCopyToClipboard),
    },
    {
      enabled: enableOptions,
      icon: 'calendar',
      id: 'create',
      key: 'discover-option-calendar',
      onClick: () => {
        trackEvent({
          action: 'create_chillout',
          category: 'discover',
          label: getLabelFilters(discover.filters),
          list_count: discover.totalUsersSelected,
        });
        openFullscreenModal({
          modalType: DISCOVER_CREATE_CHILLOUT,
          modalProps: {
            users,
          },
        });
      },
      tooltip: formatMessage(messages.discoverOptionsCreateChillout),
    },
    {
      enabled: enableOptions,
      icon: 'received',
      id: 'send',
      key: 'discover-option-sent',
      onClick: () => {
        trackEvent({
          action: 'send_star',
          category: 'discover',
          label: getLabelFilters(discover.filters),
          list_count: discover.totalUsersSelected,
        });
        handleSendStars();
      },
      tooltip: formatMessage(messages.discoverOptionsSendStar),
    },
  ];

  if (!createChillOutVirtual) {
    options = options.filter(option => option.id !== 'create');
  }

  return (
    <DiscoverOptions options={options} />
  );
}

const mapStateToProps = (state) => ({
  createChillOutVirtual: getCommunityConfigValue(state, CREATE_CHILLOUT_VIRTUAL) === 'true',
  enabledDiscoveryPeople: getCommunityConfigValue(state, ENABLED_DISCOVERY_PEOPLE),
  remainingStars: {
    ...get(state, 'starFlow.remainingStars', {}),
    ...get(state, 'starFlow.remainingCounter', 0),
  },
  stars: getStarsValues(state),
});

DiscoverOptions.propTypes = {
  addToastMessage: PropTypes.func.isRequired,
  createChillOutVirtual: PropTypes.bool,
  enableOptions: PropTypes.bool,
  enabledDiscoveryPeople: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  openFullscreenModal: PropTypes.func.isRequired,
  remainingStars: PropTypes.object,
  router: PropTypes.object,
  users: PropTypes.array,
};

export default connect(mapStateToProps, {
  addToastMessage,
  openFullscreenModal,
})(injectIntl(withRouter(DiscoverOptionsContainer)));
