// @packages
import React from 'react';
import PropTypes from 'prop-types';
import SendStarAPP from 'smu-app-components/SendStar';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

// @app
import { DEFAULT_PARAMS } from 'containers/OSFeed/constants';
import { ENABLED_STAR_SUGGESTION_TEXT } from 'services/communityConfigs/constants';
import { decodeQueryParams } from 'utils/base64';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { getOsFeed } from 'containers/OSFeed/actions';
import { getRemainingStars } from 'services/sendStarFlow/actions';
import { getSentStars } from 'containers/withProfile/actions';
import {
  getCommunityConfigurationValue,
  selectSessionStars,
  selectSessionUserId,
} from 'containers/Authorization/selectors';

const SendStar = ({
  location: {
    pathname,
    query: {
      comment,
      coreValueId,
      criteria,
      editId,
      id,
      uid,
      users,
      valueId,
    },
  },
  onClose,
  open,
}) => {
  const dispatch = useDispatch();
  const coreValues = useSelector(selectSessionStars);
  const userId = useSelector(selectSessionUserId);
  const [enabledMentions, enabledWriteForMe] = useSelector((state) => ([
    getCommunityConfigurationValue(state, 'mentionsEnabled'),
    getCommunityConfigValueBool(state, ENABLED_STAR_SUGGESTION_TEXT),
  ]));

  const handleSendSuccess = () => {
    dispatch(getSentStars());
    dispatch(getRemainingStars());

    if (pathname === '/home') {
      dispatch(getOsFeed({ params: DEFAULT_PARAMS }));
    }
  };

  return (
    <SendStarAPP
      coreValues={coreValues}
      editId={Number(editId || id) || undefined}
      enabledMentions={enabledMentions}
      enabledWriteForMe={enabledWriteForMe}
      onClose={onClose}
      onSendSuccess={handleSendSuccess}
      open={open}
      query={{
        comment,
        coreValueId: Number(coreValueId || valueId) || undefined,
        criteria: criteria ? decodeQueryParams(criteria) : undefined,
        uid,
        users: users ? decodeQueryParams(users) : undefined,
      }}
      userId={userId}
    />
  );
};

SendStar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      comment: PropTypes.string,
      coreValueId: PropTypes.string,
      criteria: PropTypes.string,
      editId: PropTypes.string,
      id: PropTypes.string,
      uid: PropTypes.string,
      users: PropTypes.string,
      valueId: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default withRouter(SendStar);
