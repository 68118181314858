import { defineMessages } from 'react-intl';

export default defineMessages({
  questionnaireResultsHeaderSegment: {
    id: 'questionnaireResults.Header.Segment',
    defaultMessage: 'Segment',
  },
  questionnaireResultsHeaderDueDate: {
    id: 'questionnaireResults.Header.DueDate',
    defaultMessage: 'Due Date',
  },
  questionnaireResultsHeaderQuestions: {
    id: 'questionnaireResults.Header.Questions',
    defaultMessage: 'Questions',
  },
  questionnaireResultsHeaderUsers: {
    id: 'questionnaireResults.Header.Users',
    defaultMessage: 'Users',
  },
  questionnaireResultsHeaderResponses: {
    id: 'questionnaireResults.Header.Responses',
    defaultMessage: 'Responses',
  },
  questionnaireResultsHeaderCompleted: {
    id: 'questionnaireResults.Header.Completed',
    defaultMessage: 'Completed',
  },
  questionnaireResultsHeaderExport: {
    id: 'questionnaireResults.Header.Export',
    defaultMessage: 'Export',
  },
  questionnaireResultsQuestionTitle: {
    id: 'questionnaireResults.Question.Title',
    defaultMessage: 'Question',
  },
  questionnaireResultsSummaryTitle: {
    id: 'questionnaireResults.Summary.Title',
    defaultMessage: 'Question Summary',
  },
  questionnaireResultsSummaryAnswered: {
    id: 'questionnaireResults.Summary.Answered',
    defaultMessage: 'Total Answers',
  },
  questionnaireResultsSummarySkipped: {
    id: 'questionnaireResults.Summary.Skipped',
    defaultMessage: 'Skipped',
  },
  questionnaireResultsSummaryAverage: {
    id: 'questionnaireResults.Summary.Average',
    defaultMessage: 'Average',
  },
  questionnaireResultsTooltipAnswers: {
    id: 'questionnaireResults.Tooltip.Answers',
    defaultMessage: 'Answers',
  },
  questionnaireResultsListDate: {
    id: 'questionnaireResults.List.Date',
    defaultMessage: 'Date',
  },
  questionnaireResultsListUser: {
    id: 'questionnaireResults.List.User',
    defaultMessage: 'User',
  },
  questionnaireResultsListAnswers: {
    id: 'questionnaireResults.List.Answers',
    defaultMessage: 'Answers',
  },
  questionnaireResultsListFirst: {
    id: 'questionnaireResults.List.First',
    defaultMessage: 'First',
  },
  questionnaireResultsListLast: {
    id: 'questionnaireResults.List.Last',
    defaultMessage: 'Last',
  },
  questionnaireResultsErrorTitle: {
    id: 'questionnaireResults.Error.Title',
    defaultMessage: 'The survey results cannot be displayed',
  },
  questionnaireResultsErrorSubtitle: {
    id: 'questionnaireResults.Error.Subtitle',
    defaultMessage: 'Please try to refresh the page later',
  },
  questionnaireResultsOpenWithNoAnswerTitle: {
    id: 'questionnaireResults.OpenWithNoAnswer.Title',
    defaultMessage: 'Still no answers',
  },
  questionnaireResultsOpenWithNoAnswerSubtitle: {
    id: 'questionnaireResults.OpenWithNoAnswer.Subtitle',
    defaultMessage: "But don't worry, there is time. As soon as there are results, we will notify you.",
  },
  questionnaireResultsClosedWithNoAnswerTitle: {
    id: 'questionnaireResults.ClosedWithNoAnswer.Title',
    defaultMessage: 'Sorry, but no responses were recorded',
  },
  questionnaireResultsClosedWithNoAnswerSubtitle: {
    id: 'questionnaireResults.ClosedWithNoAnswer.Subtitle',
    defaultMessage: "This survey has already closed, and we don't have any results to show.",
  },
});
