// @packages
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Card from 'smu-ui-components/Card';
import Typography from 'smu-ui-components/Typography';

// @app
import SkeletonTopStars from 'components/Skeletons/TopStars';
import TopStar from 'components/TopStar';

// @own
import * as actions from './actions';
import messages from './messages';
import { getLeaderboard } from '../Leaderboard/api';
import { getTopStars as APIgetTopStars } from './api';

function TopStarPanel({
  fetching,
  getTopStars,
  results,
  worldCupCoreValueId,
  worldCupEnable,
}) {
  useEffect(() => {
    if (!worldCupEnable) {
      getTopStars(() => APIgetTopStars({ size: 3 }));
    } else {
      getTopStars(() =>
        getLeaderboard({
          organizationValue: worldCupCoreValueId,
          typeLeaderboard: 'RECEIVER',
        }));
    }
  }, []);

  if (fetching) {
    return <SkeletonTopStars className="shadow-base" />;
  }

  return (
    <Card className="rounded-lg px-3 py-4 shadow-base">
      {worldCupEnable ? (
        <Typography className="block text-sm text-blue" variant="title1">
          <FormattedMessage {...messages.topStarsTitleChampions} />
        </Typography>
      ) : (
        <Typography className="block text-sm text-blue" variant="title1">
          <FormattedMessage {...messages.topStarsTitle} />
        </Typography>
      )}
      <div className="space-y-3 mt-3">
        {results?.map((value) => (
          <TopStar key={value.id} {...value} />
        ))}
      </div>
    </Card>
  );
}

TopStarPanel.propTypes = {
  error: PropTypes.string,
  fetching: PropTypes.bool,
  getTopStars: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  results: PropTypes.array,
  worldCupCoreValueId: PropTypes.number,
  worldCupEnable: PropTypes.bool,
};

const mapStateToProps = ({ topStars, session }) => {
  const { communityConfigurations } = session;
  const worldCupEnable = Boolean(
    communityConfigurations && communityConfigurations.worldCupCoreValueId,
  );
  return {
    worldCupCoreValueId: communityConfigurations.worldCupCoreValueId,
    worldCupEnable,
    ...topStars,
  };
};

export default withRouter(connect(mapStateToProps, actions)(TopStarPanel));
