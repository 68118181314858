// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// @own
import './styles.scss';
import messages from '../messages';
import { INSIGHT_TYPES } from '../constants';

function IndicatorDetail({
  areaName,
  indicator,
  intl: { formatMessage },
  month,
  type,
}) {
  function getIndicator() {
    let description = '';
    if (
      messages[`${indicator?.description}`]
      && type !== INSIGHT_TYPES.CULTURAL_ACTIVITY
    ) {
      description = messages[`${indicator?.description}`] ?
        `${formatMessage(messages[`${indicator?.description}`], { name: areaName })}.` :
        indicator?.description;
    } else {
      description = messages[`${indicator?.description}`] ?
        `${formatMessage(messages[`${indicator?.description}`])} ${month}.` :
        indicator?.description;
    }
    return description;
  }

  return (
    <div className="insights-content__kpi">
      <div className="insights-content__kpi-value">{indicator?.value}</div>
      <div className="insights-content__kpi-description">
        {getIndicator()}
      </div>
    </div>
  );
}

IndicatorDetail.propTypes = {
  indicator: PropTypes.object,
};

export default injectIntl(IndicatorDetail);
