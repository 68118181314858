import {
  CHANGE_LIVING_VALUE_VIEW,
  CHANGE_STAR_MESSAGE,
  CLOSE_LIVING_VALUES,
  DISMISS_LIVING_VALUES_FAIL,
  DISMISS_LIVING_VALUES_SUCCESS,
  DISMISS_LIVING_VALUES,
  GET_LIVING_VALUES_TEXTS,
  GET_LIVING_VALUES_TEXTS_FAIL,
  GET_LIVING_VALUES_TEXTS_SUCCESS,
  PICK_LIVING_VALUE,
  PICK_USER,
  REMINDER_LIVING_VALUES_FAIL,
  REMINDER_LIVING_VALUES_SUCCESS,
  REMINDER_LIVING_VALUES,
  REMOVE_SELECTED_USER,
  SEND_LIVING_VALUES_FAIL,
  SEND_LIVING_VALUES_SUCCESS,
  SEND_LIVING_VALUES,
  SET_VALUES_DONE,
  START_LIVING_VALUES,
  START_LIVING_VALUES_FAIL,
  START_LIVING_VALUES_SUCCESS,
} from './actionTypes';

export function changeStarMessage(starId, customMessage) {
  return {
    type: CHANGE_STAR_MESSAGE,
    payload: { starId, customMessage },
  };
}

export function closeLivingValues() {
  return {
    type: CLOSE_LIVING_VALUES,
  };
}

export function dismissLivingValues() {
  return {
    type: DISMISS_LIVING_VALUES,
  };
}

export function dismissLivingValuesFail() {
  return {
    type: DISMISS_LIVING_VALUES_FAIL,
  };
}

export function dismissLivingValuesSuccess({ isDismissed }) {
  return {
    type: DISMISS_LIVING_VALUES_SUCCESS,
    payload: { isDismissed },
  };
}

export function getLivingValuesTexts({ languageCode, name }) {
  return {
    type: GET_LIVING_VALUES_TEXTS,
    payload: { languageCode, name },
  };
}

export function getLivingValuesTextsFail() {
  return {
    type: GET_LIVING_VALUES_TEXTS_FAIL,
  };
}

export function getLivingValuesTextsSuccess(payload) {
  return {
    type: GET_LIVING_VALUES_TEXTS_SUCCESS,
    payload,
  };
}

export function goToView(view) {
  return {
    type: CHANGE_LIVING_VALUE_VIEW,
    payload: view,
  };
}

export function pickLivingValue(id, name, color, imageCode) {
  return {
    type: PICK_LIVING_VALUE,
    payload: {
      color,
      id,
      imageCode,
      name,
    },
  };
}

export function pickUser(starId, user, defaultMessage) {
  return {
    type: PICK_USER,
    payload: { starId, user, defaultMessage },
  };
}

export function remindLivingValues() {
  return {
    type: REMINDER_LIVING_VALUES,
  };
}

export function remindLivingValuesFail() {
  return {
    type: REMINDER_LIVING_VALUES_FAIL,
  };
}

export function remindLivingValuesSuccess() {
  return {
    type: REMINDER_LIVING_VALUES_SUCCESS,
  };
}

export function removeSelectedUser(starId) {
  return {
    type: REMOVE_SELECTED_USER,
    payload: starId,
  };
}

export function sendLivingValues(livingValues) {
  return {
    type: SEND_LIVING_VALUES,
    payload: { livingValues },
  };
}

export function sendLivingValuesFail() {
  return {
    type: SEND_LIVING_VALUES_FAIL,
  };
}

export function sendLivingValuesSuccess() {
  return {
    type: SEND_LIVING_VALUES_SUCCESS,
  };
}

export function setValuesDone(values) {
  return {
    type: SET_VALUES_DONE,
    payload: values,
  };
}

export function startLivingValues(router = {}) {
  return {
    type: START_LIVING_VALUES,
    payload: { router },
  };
}

export function startLivingValuesFail(payload) {
  return {
    type: START_LIVING_VALUES_FAIL,
    payload,
  };
}

export function startLivingValuesSuccess(payload) {
  return {
    type: START_LIVING_VALUES_SUCCESS,
    payload,
  };
}
