import { defineMessages } from 'react-intl';

export default defineMessages({
  bethereCommentsPlaceholder: {
    id: 'bethere.commentsPlaceholder',
    defaultMessage: 'Leave a comment',
  },
  bethereDeletePost: {
    id: 'bethere.deletePost',
    defaultMessage: 'Delete Post',
  },
  betherePageText: {
    id: 'bethere.pageText',
    defaultMessage: 'Page',
  },
  betherePostNotags: {
    id: 'bethere.postNotags',
    defaultMessage: 'There are no tags in this post',
  },
  betherePostPrefix: {
    id: 'bethere.postPrefix',
    defaultMessage: 'Sent by',
  },
  betherePreviousText: {
    id: 'bethere.previousText',
    defaultMessage: 'Previous',
  },
  smuCommonAccept: {
    id: 'SMU.Common.Yes',
    defaultMessage: 'Yes',
  },
  smuCommonCancel: {
    id: 'SMU.Common.No',
    defaultMessage: 'No',
  },
});
