// @packages
import React, { useRef } from 'react';
import Icon from 'smu-ui-components/Icon';
import ImageGallery from 'react-image-gallery';
import cn from 'classnames';
import {
  getLocalPathname,
  isValidProtocol,
} from 'smu-utils/url';
import { trackEvent } from 'smu-utils/gtm';
import { withRouter } from 'react-router';

// @own
import './styles.scss';

function OSFeedBanner({ images, router }) {
  const refCarousel = useRef();

  const handleNav = () => {
    trackEvent({
      action: 'Arrow',
      category: 'Feed banner',
      label: 'Click an arrow',
    });
  };

  const onImageClick = (index) => {
    const href = images[index]?.link;
    trackEvent({
      action: `Click_${images[index]?.name}`,
      category: 'Feed banner',
      label: 'Click feed banner',
    });
    const validHref = isValidProtocol(href) ? href : `https://${href}`;
    const localPathname = getLocalPathname(href);
    const isLocal = !!localPathname;

    if (isLocal) {
      router.push(localPathname);
    } else {
      window.open(validHref);
    }
  };

  const renderLeftNav = (onClick) => (
    <button
      aria-label="left"
      className={cn('banner-chevron banner-chevron__left', {
        'banner-chevron__left--hidden':
          refCarousel?.current?.getCurrentIndex() === 0,
      })}
      onClick={() => {
        handleNav();
        onClick();
      }}
    >
      <Icon className="banner-chevron__icon" icon="chevron-left" />
    </button>
  );

  const renderRightNav = (onClick) => (
    <button
      aria-label="right"
      className={cn('banner-chevron banner-chevron__right', {
        'banner-chevron__right--hidden':
          refCarousel?.current?.getCurrentIndex() === images.length - 1,
      })}
      onClick={() => {
        handleNav();
        onClick();
      }}
    >
      <Icon className="banner-chevron__icon" icon="chevron-right" />
    </button>
  );

  return (
    <div
      className="smu-carousel shadow-base aspect-[620/174]"
      data-testid="gallery"
    >
      <ImageGallery
        additionalClass="gallery"
        autoPlay
        infinite
        items={images}
        onClick={() => onImageClick(refCarousel.current.getCurrentIndex())}
        ref={refCarousel}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        showBullets={images?.length > 1}
        showFullscreenButton={false}
        showPlayButton={false}
        showThumbnails={false}
        slideInterval={8000}
      />
    </div>
  );
}

export default withRouter(OSFeedBanner);
