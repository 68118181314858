// @app
import { apiInstance } from 'utils/requestHelper';

export function apiGetCommunityGuidelines(params) {
  return apiInstance({
    params,
    url: 'v2/stellar/resources',
  })
  .then(({ data }) => data);
}
