// @packages
import React, { useEffect } from 'react';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import Profile from 'smu-unified-profile';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import messages from 'smu-unified-profile/components/pages/Profile/messages';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import withRootModal from 'smu-app-components/RootModalV2/withRootModal';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useMembers } from 'smu-services/os-member/api/v2/community/members/[id]';
import { withRouter } from 'react-router';

// @app
import Badges from 'containers/Badges';
import BethereFeed from 'containers/BethereFeed';
import HighlightedStar from 'containers/HighlightedStar';
import PaginatedFeed from 'containers/PaginatedFeed';
import UserProfile from 'containers/UserProfile/Other';
import WithCongratulationsFeed from 'containers/WithCongratulationsFeed';
import useToast from 'hooks/useToast';
import {
  ENABLED_ANNIVERSARIES,
  ENABLED_BADGES,
  ENABLED_BEHEALTHY,
  ENABLED_BEKIND,
  ENABLED_BETHERE_FEED,
  ENABLED_BETTERME,
  ENABLED_BIRTHDAYS,
  ENABLED_STARMEUP,
} from 'services/communityConfigs/constants';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { feedSetOptions } from 'containers/withFeed/actions';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { getFullPathname } from 'utils/url';
import { mapStateToProps as mstp } from 'containers/withProfile';
import { normaliceFilters } from 'utils/filters';
import {
  profileRedirectToCogratulationAnniversary,
  profileRedirectToCogratulationBirthday,
  redirectToCogratulationFeed,
} from 'utils/congratulations';
import { selectProfileUser, selectCommunityId } from 'containers/Authorization/selectors';
import { selectStarFlowStatus } from 'services/sendStarFlow/selectors';
import { trackEvent } from 'utils/gtm';

function OtherProfile({
  communityId,
  feedSetOptions,
  intl: { formatMessage },
  location,
  params,
  preferences,
  profileUser,
  receivedStarsFilters,
  router,
  sentStarsFilters,
  userInfo,
}) {
  const showMessage = useToast();
  const member = useMembers({
    userUID: params?.profileId,
  });
  const user = member?.data?.result;
  const userEnabled = user?.enabled;
  const userUID = user?.properties?.uid;
  const showSendStar = /\/sendStar$/.test(location.pathname);

  const handleOnSendStarClick = (user) => {
    trackEvent({
      category: 'Profile',
      action: 'Profile_Send_Star',
    });

    router.push(buildSendStarModalUrl({
      params: { users: [user] },
    }));
  };

  const applyFeedFilters = ({
    filterBy,
    filters,
    options,
    userId,
  }) =>
    feedSetOptions({
      filterBy,
      optionalFields: filters,
      userId,
      ...options,
    });

  const clearFeedFilters = ({ filterBy, options, userId }) =>
    feedSetOptions({
      ...options,
      filterBy,
      optionalFields: {},
      userId,
    });

  const socialTabComponents = {
    CELEBRATIONS_TAB: {
      Component: WithCongratulationsFeed,
    },
    RECEIVED_STARS_TAB: {
      Component: PaginatedFeed,
      filters: receivedStarsFilters,
      onApplyFilters: props => applyFeedFilters(props),
      onClearFilters: props => clearFeedFilters(props),
    },
    SENT_STARS_TAB: {
      Component: PaginatedFeed,
      filters: sentStarsFilters,
      onApplyFilters: props => applyFeedFilters(props),
      onClearFilters: props => clearFeedFilters(props),
    },
    TAGGED_POSTS_TAB: {
      Component: BethereFeed,
    },
    UPLOADED_POSTS_TAB: {
      Component: BethereFeed,
    },
  };

  const ownUid = get(userInfo, 'uid');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params?.profileId]);

  useEffect(() => {
    if (ownUid === userUID) {
      const path = getFullPathname(window.location.href);
      const ownerPath = path.replace(`/profile/${ownUid}`, '/me');
      router.replace(ownerPath);
    }
  }, [ownUid, userUID]);

  useEffect(() => {
    if (showSendStar && profileUser.id) {
      handleOnSendStarClick(profileUser);
    }
  }, [showSendStar, profileUser.id]);

  useEffect(() => {
    if (userUID && !userEnabled) {
      showMessage({
        message: formatMessage(messages.profileDisabled),
      });
      router.replace('/home');
    }
  }, [userUID, userEnabled]);

  if (member.isLoading) {
    return <LottieSpinner className="h-screen w-screen flex items-center justify-center" />;
  }

  if (!userEnabled) {
    return null;
  }

  if (member.error) {
    return (
      <GenericErrorState
        action={{
          onClick: member.mutate,
        }}
      />
    );
  }

  return (
    <Profile
      app="StarMeUp"
      className="unified-profile"
      communityId={communityId}
      onClickSeeCongratulations={() => redirectToCogratulationFeed(user?.uid)}
      onClickSendStar={() => handleOnSendStarClick(profileUser)}
      onClickUserAnniversary={() => profileRedirectToCogratulationAnniversary(user?.uid)}
      onClickUserBirthday={() => profileRedirectToCogratulationBirthday(user?.uid)}
      ownUid={ownUid}
      preferences={preferences}
      socialTabComponents={socialTabComponents}
      user={member?.data?.result}
      components={{
        badges: Badges,
        highlightedStar: HighlightedStar,
        userProfile: UserProfile,
      }}
    />
  );
}


OtherProfile.propTypes = {
  atTop: PropTypes.bool,
  bethereEnabled: PropTypes.bool,
  bio: PropTypes.string,
  communityId: PropTypes.number,
  feedSetOptions: PropTypes.func.isRequired,
  fetchingStar: PropTypes.bool,
  intl: PropTypes.object,
  isInteractionEnabled: PropTypes.bool,
  location: PropTypes.object,
  modalContent: PropTypes.object.isRequired,
  newProfileConnection: PropTypes.bool.isRequired,
  newUserProfileEnabled: PropTypes.bool.isRequired,
  openModal: PropTypes.func,
  params: PropTypes.object,
  preferences: PropTypes.object,
  profileCustomFields: PropTypes.array,
  profileUser: PropTypes.object,
  router: PropTypes.object,
  scrolledUp: PropTypes.bool,
  showSendStarValues: PropTypes.bool,
  toStickTo: PropTypes.object,
  userBioEnabled: PropTypes.bool.isRequired,
  userInfo: PropTypes.object,
  usersInteraction: PropTypes.object,
  votingInfo: PropTypes.array,
  withProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { filtersV2: { PROFILE, PROFILE_SENT }} = state;
  const profileStarDetails = get(state, 'profile.receivedStarsDetail');
  const bethereEnabled = get(state, 'session.communityConfigurations.bethereEnabled');
  const kudosStar = find(get(state, 'session.stars'), { isKudos: true });
  const kudosStarDetails = pick(kudosStar, 'id', 'backgroundColor', 'imageCode', 'nameShowed');
  const newUserProfileEnabled = get(state, 'session.communityConfigurations.newProfileEnabled');
  const newProfileConnection = get(state, 'session.communityConfigurations.newProfileConnection');
  let starsDetails;
  if (profileStarDetails) {
    const stars = profileStarDetails.coreValuesSummary.map((item => (
      {
        id: item.id,
        backgroundColor: item.star.backgroundColor,
        imageCode: item.imageCode,
        nameShowed: item.star.nameShowed,
        totalStars: item.totalStars,
      }
    )));
    if (kudosStar !== undefined) {
      kudosStarDetails.totalStars = profileStarDetails.totalKudos;
      stars.push(kudosStarDetails);
    }
    starsDetails = orderBy(stars, ['totalStars'], ['desc']);
  }
  const interactions = get(state, 'profile.usersInteraction');
  const userName = get(state, 'profile.result.firstName');
  let usersInteraction;
  if (interactions) {
    let stars = get(state, 'session.stars');
    let starsDisabled = get(state, 'session.starsDisabled');
    if (starsDisabled) {
      stars = stars.concat(starsDisabled);
    }
    if (interactions.firstStar) {
      const firstStarDate = interactions.firstStar;
      if (interactions.mostlyValueIdReceived) {
        const mostlyValueReceived = find(
          stars,
          { id: interactions.mostlyValueIdReceived },
        )?.nameShowed;
        interactions.mostlyValueReceived = mostlyValueReceived;
      }
      if (interactions.mostlyValueIdSent) {
        const mostlyValueSent = find(stars, { id: interactions.mostlyValueIdSent })?.nameShowed;
        interactions.mostlyValueSent = mostlyValueSent;
      }
      usersInteraction = {
        firstStarDate,
        userName,
        ...interactions,
      };
    } else {
      usersInteraction = {
        isEmpty: true,
      };
    }
  }

  return {
    bethereEnabled,
    bio: get(state, 'profile.result.bio', ''),
    communityId: selectCommunityId(state),
    fetchingStar: state.profile.fetchingRecS,
    isInteractionEnabled: get(state, 'session.communityConfigurations.interactionBetweenUsers'),
    modalContent: {
      modalType: 'PROFILE_EXPANDED',
      modalProps: {
        user: state.profile.result,
        profileCustomFields: state.session.communityConfigurations.profileCustomFields,
        customFields: state.session.communityConfigurations.customFields,
        starsDetails,
        newUserProfileEnabled,
      },
    },
    newProfileConnection,
    newUserProfileEnabled,
    preferences: {
      enabledAnniversaries: getCommunityConfigValueBool(state, ENABLED_ANNIVERSARIES),
      enabledBadges: getCommunityConfigValueBool(state, ENABLED_BADGES),
      enabledBeHealthy: getCommunityConfigValueBool(state, ENABLED_BEHEALTHY),
      enabledBeHealthyNewQR: true,
      enabledBeKind: getCommunityConfigValueBool(state, ENABLED_BEKIND),
      enabledBeThere: getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED),
      enabledBetterMe: getCommunityConfigValueBool(state, ENABLED_BETTERME),
      enabledBirthdays: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
      enabledStarMeUp: getCommunityConfigValueBool(state, ENABLED_STARMEUP),
    },
    profileCustomFields: state.session.communityConfigurations.profileCustomFields,
    profileUser: selectProfileUser(state),
    receivedStarsFilters: normaliceFilters(PROFILE),
    sentStarsFilters: normaliceFilters(PROFILE_SENT),
    showSendStarValues: get(state, 'feed.showSendStarValues'),
    status: selectStarFlowStatus(state),
    userBioEnabled: state.session.communityConfigurations.userBioEnabled,
    userInfo: get(state, 'session.user'),
    usersInteraction,
    votingInfo: state.session.communityConfigurations.votingInfo,
    withProfile: mstp(state, ownProps).withProfile,
  };
};

const withIntl = injectIntl(withRouter(OtherProfile));

export default connect(mapStateToProps, {
  feedSetOptions,
})(withRootModal(withIntl));
