// @packages
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @app
import ModalStarDetails from 'components/Modals/StarDetails';
import StarDetailsContainer from 'containers/StarDetails';
import { actionOsFeedUpdatePost } from 'containers/OSFeed/actions';
import { selectFirstResult } from 'containers/withFeed/selectors';

// @own
import { eventOnOpenModal, eventOnCloseModal } from './analytics';

const ModalStarDetailsContainer = ({ className, onClose, open, starId }) => {
  const dispatch = useDispatch();
  const star = useSelector(selectFirstResult);

  const handleUpdateFeedPost = () => {
    dispatch(
      actionOsFeedUpdatePost({
        id: Number(starId),
        data: star,
      }),
    );
  };

  const handleClose = () => {
    handleUpdateFeedPost();
    if (onClose) onClose();
  };

  useEffect(() => {
    eventOnOpenModal();
    return () => eventOnCloseModal();
  }, []);

  return (
    <ModalStarDetails className={className} onClose={handleClose} open={open}>
      <StarDetailsContainer
        activityId={starId}
        expanded
        onError={onClose}
      />
    </ModalStarDetails>
  );
};

export default ModalStarDetailsContainer;
