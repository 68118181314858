import { smuInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { getUserLanguage } from 'smu-utils/language';

export function getInterests({ id }) {
  const language = getUserLanguage();
  return smuInstance({
    url: '/os-member/api/v1/community/groups?pageSize=500',
    method: 'post',
    data: {
      groupType: 'interest',
      userId: id,
    },
    headers: {
      language,
    },
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}

export function saveInterests({ interestsToAdd, interestsToDelete }) {
  return smuInstance.put('/os-member/api/v1/community/groups/members', {
    interestsToAdd,
    interestsToDelete,
    roles: ['MEMBER'],
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}
