// @packages
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';

// @app
import { selectLanguageCode } from 'containers/Authorization/selectors';

// @own
import { getPendingQuestionnairesFail, getPendingQuestionnairesSuccess } from './actions';
import { GET_PENDING_QUESTIONNAIRES } from './actionTypes';
import { getPendingQuestionnaires } from './api';

function* getPendingQuestionnairesWorker({ payload: { orgId } }) {
  try {
    const lang = yield select(selectLanguageCode);
    const payload = yield call(getPendingQuestionnaires, orgId, lang);
    yield put(getPendingQuestionnairesSuccess(payload));
  } catch ({ message }) {
    yield put(addToastMessage({ message, timeout: 3000 }));
    yield put(getPendingQuestionnairesFail(message));
  }
}

export function* subscriptionsWatcher() {
  yield takeLatest(GET_PENDING_QUESTIONNAIRES, getPendingQuestionnairesWorker);
}

export default {
  subscriptionsWatcher,
};
