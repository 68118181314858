import { bethereApiInstance, defaultApi } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

function apiGetBethereInteractions({ orgId, cardId }) {
  return defaultApi({
    method: 'get',
    url: `bethere/organization/${orgId}/posts/${cardId}/interactions`,
  })
    .then(({ data }) => data)
    .catch((error) => errorHandler(error, undefined, false));
}

function apiGetSponsoredInteractions({ orgId, cardId }) {
  return defaultApi({
    method: 'get',
    url: `interactions/api/v1/organization/${orgId}/posts/${cardId}/interactions`,
  })
    .then(({ data }) => data)
    .catch((error) => errorHandler(error, undefined, false));
}

export async function apiGetPostById({ orgId, cardId }) {
  try {
    const bethereData = await apiGetBethereInteractions({ orgId, cardId });

    if (bethereData?.post?.sponsored) {
      const sponsoredData = await apiGetSponsoredInteractions({
        orgId,
        cardId,
      });
      return {
        post: bethereData?.post,
        postInteractions: sponsoredData?.postInteractions,
      };
    }

    return {
      post: bethereData?.post,
      postInteractions: bethereData?.postInteractions,
    };
  } catch (error) {
    return errorHandler(error, undefined, false);
  }
}

export function apiGetSponsoredReactions({ orgId, cardId }) {
  return defaultApi({
    method: 'get',
    url: `interactions/api/v1/organization/${orgId}/posts/${cardId}/interactions`,
  })
    .then(({ data }) => data)
    .catch((error) => errorHandler(error, undefined, false));
}

function apiAddInteraction({ orgId, cardId, data }) {
  return bethereApiInstance({
    data,
    method: 'post',
    url: `organization/${orgId}/posts/${cardId}/interactions`,
  })
    .then(({ data }) => data)
    .catch((error) => errorHandler(error, undefined, false));
}

function apiAddSponsoredInteraction({ orgId, cardId, data }) {
  return defaultApi({
    data,
    method: 'post',
    url: `interactions/api/v1/organization/${orgId}/posts/${cardId}/interactions`,
  })
    .then(({ data }) => data)
    .catch((error) => errorHandler(error, undefined, false));
}

export async function apiAddInteractionById({
  orgId,
  cardId,
  data,
  sponsored,
}) {
  try {
    if (sponsored) {
      return apiAddSponsoredInteraction({ orgId, cardId, data });
    }
    return apiAddInteraction({ orgId, cardId, data });
  } catch (error) {
    return errorHandler(error, undefined, false);
  }
}

function apiRemoveLike({ orgId, cardId }) {
  return bethereApiInstance({
    method: 'delete',
    url: `organization/${orgId}/posts/${cardId}/likes/me`,
  })
    .then(({ data }) => data)
    .catch((error) => errorHandler(error, undefined, false));
}

function apiRemoveSponsoredLike({ orgId, cardId }) {
  return defaultApi({
    method: 'delete',
    url: `interactions/api/v1/organization/${orgId}/posts/${cardId}/likes/me`,
  })
    .then(({ data }) => data)
    .catch((error) => errorHandler(error, undefined, false));
}

export function apiRemoveLikeById({ orgId, cardId, sponsored }) {
  try {
    if (sponsored) {
      return apiRemoveSponsoredLike({ orgId, cardId });
    }
    return apiRemoveLike({ orgId, cardId });
  } catch (error) {
    return errorHandler(error, undefined, false);
  }
}

export function apiDeletePost({ orgId, postId }) {
  return bethereApiInstance({
    method: 'delete',
    url: `organization/${orgId}/posts/${postId}`,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
