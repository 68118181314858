// @packages
import FileSave from 'file-saver';
// @app
import { takepartApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export const apiGetResultById = ({ questionnaireId, reportType }) =>
  takepartApiInstance({
    data: {},
    headers: { 'Content-Type': 'application/json' },
    url: `/api/v2/community/me/questionnaires/${questionnaireId}/reports/${reportType}`,
  })
    .then(({ data }) => {
      if (data?.result?.id) {
        return data;
      }
      throw new Error({
        message: 'Results are not available',
      });
    })
    .catch((error) => errorHandler(error, undefined, false));

export const apiGetCSV = ({ questionnaireId }) => {
  takepartApiInstance({
    data: { questionnaireId },
    method: 'post',
    responseType: 'arraybuffer',
    timeout: 0,
    url: '/api/v2/community/me/questionnaires/reports/surveys?format=csv',
  })
    .then(({ data }) => {
      const blob = new Blob([data], { type: 'text/csv'});
      FileSave.saveAs(blob, `${questionnaireId}_results.csv`);
    })
    .catch(errorHandler);
};
