
export default function escapeHtml(text) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '%27',
    '%': '&permil;',
  };

  return text.replace(/[&<>"'%]/g, m => map[m]);
}
