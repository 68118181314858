export const getAdvancedUserSearchRequesting = (state) =>
  state?.advancedUserSearch?.requesting || false;

export const getAdvancedUserSearchResult = (state) =>
  state?.advancedUserSearch?.result;

export const getAdvancedUserSearchError = (state) =>
  state?.advancedUserSearch?.error || null;

export const getAdvancedUserSearchSuccess = (state) =>
  state?.advancedUserSearch?.success || false;

export const getAdvancedUserSearchPage = (state) =>
  getAdvancedUserSearchResult(state)?.pageable?.page || 0;

export const getAdvancedUserSearchTotal = (state) =>
  getAdvancedUserSearchResult(state)?.total || 0;

export const getAdvancedUserSearchFilters = (state) =>
  state?.advancedUserSearch?.filters || {};
