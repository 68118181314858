// @packages
import React from 'react';

// @own
import './styles.scss';

const DiscoverUsersFooter = (props) => (
  <footer className="discover-users-footer" {...props} />
);

export default DiscoverUsersFooter;
