export const CHANGE_LIVING_VALUE_VIEW = 'CHANGE_LIVING_VALUE_VIEW';
export const CHANGE_STAR_MESSAGE = 'CHANGE_STAR_MESSAGE';
export const CLOSE_LIVING_VALUES = 'CLOSE_LIVING_VALUES';
export const DISMISS_LIVING_VALUES = 'DISMISS_LIVING_VALUES';
export const DISMISS_LIVING_VALUES_FAIL = 'DISMISS_LIVING_VALUES_FAIL';
export const DISMISS_LIVING_VALUES_SUCCESS = 'DISMISS_LIVING_VALUES_SUCCESS';
export const GET_LIVING_VALUES_TEXTS = 'GET_LIVING_VALUES_TEXTS';
export const GET_LIVING_VALUES_TEXTS_FAIL = 'GET_LIVING_VALUES_TEXTS_FAIL';
export const GET_LIVING_VALUES_TEXTS_SUCCESS = 'GET_LIVING_VALUES_TEXTS_SUCCESS';
export const PICK_LIVING_VALUE = 'PICK_LIVING_VALUE';
export const PICK_USER = 'PICK_USER';
export const REMINDER_LIVING_VALUES = 'REMINDER_LIVING_VALUES';
export const REMINDER_LIVING_VALUES_FAIL = 'REMINDER_LIVING_VALUES_FAIL';
export const REMINDER_LIVING_VALUES_SUCCESS = 'REMINDER_LIVING_VALUES_SUCCESS';
export const REMOVE_SELECTED_USER = 'REMOVE_SELECTED_USER';
export const SEND_LIVING_VALUES = 'SEND_LIVING_VALUES';
export const SEND_LIVING_VALUES_FAIL = 'SEND_LIVING_VALUES_FAIL';
export const SEND_LIVING_VALUES_SUCCESS = 'SEND_LIVING_VALUES_SUCCESS';
export const SET_VALUES_DONE = 'SET_VALUES_DONE';
export const START_LIVING_VALUES = 'START_LIVING_VALUES';
export const START_LIVING_VALUES_FAIL = 'START_LIVING_VALUES_FAIL';
export const START_LIVING_VALUES_SUCCESS = 'START_LIVING_VALUES_SUCCESS';
