// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import Link from 'components/Link';

// @own
import './styles.scss';

const Avatar = ({
  className,
  height,
  href,
  onClick,
  rounded,
  src,
  style,
  width,
  ...rest
}) => {
  const ContentComponent = href ? Link : 'div';
  const customStyles = {
    ...style,
  };

  if (src) customStyles.backgroundImage = `url("${src}")`;
  if (height) customStyles.height = `${height}px`;
  if (width) customStyles.width = `${width}px`;

  return (
    <ContentComponent
      className={cn('smu-avatar', className, {
        'smu-avatar--rounded': rounded,
        'smu-avatar--variant-link': href || onClick,
      })}
      href={href}
      onClick={onClick}
      style={customStyles}
      {...rest}
    />
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  href: PropTypes.string,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  width: PropTypes.number,
};

Avatar.defaultProps = {
  height: 40,
  width: 40,
  rounded: true,
};

export default Avatar;
