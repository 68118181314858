// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

// @app
import FeedFilters from 'components/FeedFilters';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { postOsFeedPreferenceV2 } from 'containers/OSFeed/actions';
import { selectOsFeedEnabled, selectOsFeedFetching } from 'containers/OSFeed/selectors';
import {
  DISABLED_FEED_ORDERBY_TOGGLE,
  ENABLED_BETHERE_FEED,
} from 'services/communityConfigs/constants';

// @own
import messages from './messages';
import { FILTER_MOMENTS, FILTER_STARS } from './constants';
import {
  eventOnClickConnections,
  eventOnClickModalClose,
  eventOnClickModalToggle,
  eventOnClickTab,
} from './analytics';
import { isEnabledFilter } from './helpers';

const FeedFiltersContainer = ({
  className,
  disabledFeedOrderByToggle,
  enabledBethere,
  filter,
  intl: { formatMessage },
  onClickFilter,
  osFeedEnabled,
  osFeedFetching,
  osFeedHasResult,
  postOsFeedPreferenceV2,
  ...rest
}) => {
  const enabledFilter = isEnabledFilter({ enabledBethere, filter });

  const handleClickFilter = (type) => {
    if (onClickFilter) onClickFilter(type);
    eventOnClickTab(type);
  };

  const handleToggleClick = () => {
    postOsFeedPreferenceV2({
      osFeed: !osFeedEnabled,
    });
    eventOnClickModalToggle(osFeedEnabled);
  };

  const filters = [
    {
      active: !enabledFilter,
      onClick: () => handleClickFilter(),
      text: formatMessage(messages.all),
    },
    {
      active: FILTER_STARS === filter,
      onClick: () => handleClickFilter(FILTER_STARS),
      text: formatMessage(messages.stars),
    },
  ];

  if (enabledBethere) {
    filters.push({
      active: FILTER_MOMENTS === filter,
      onClick: () => handleClickFilter(FILTER_MOMENTS),
      text: formatMessage(messages.moments),
    });
  }

  return (
    <FeedFilters
      className={className}
      filters={filters}
      onFeedSortButtonClose={eventOnClickModalClose}
      onFeedSortButtonOpen={eventOnClickConnections}
      onFeedSortButtonToggle={handleToggleClick}
      osFeedEnabled={osFeedEnabled}
      showFeedSortButton={!disabledFeedOrderByToggle}
      {...rest}
    />
  );
};

FeedFiltersContainer.propTypes = {
  className: PropTypes.string,
  disabledFeedOrderByToggle: PropTypes.bool,
  enabledBethere: PropTypes.bool,
  filter: PropTypes.string,
  intl: PropTypes.object,
  onClickFilter: PropTypes.func,
  osFeedEnabled: PropTypes.bool,
  osFeedFetching: PropTypes.bool,
  postOsFeedPreferenceV2: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  disabledFeedOrderByToggle: getCommunityConfigValueBool(
    state,
    DISABLED_FEED_ORDERBY_TOGGLE,
  ),
  enabledBethere: getCommunityConfigValueBool(state, ENABLED_BETHERE_FEED),
  osFeedEnabled: selectOsFeedEnabled(state),
  osFeedFetching: selectOsFeedFetching(state),
});

const mapActionsToProps = {
  postOsFeedPreferenceV2,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(injectIntl(FeedFiltersContainer));
