// @packages
import { defineMessages } from 'react-intl';

export default defineMessages({
  shareModalTitleDNA: {
    id: 'shareModal.Title.DNA',
    defaultMessage: 'Share DNA',
  },
  shareModalShare: {
    id: 'shareModal.Share',
    defaultMessage: 'Share',
  },
  shareModalErrorInvalidShare: {
    id: 'shareModal.Error.InvalidShare',
    defaultMessage: 'Share action is invalid',
  },
  shareModalTitleStar: {
    id: 'shareModal.Title.Star',
    defaultMessage: 'Share your recognition with your social community',
  },
  shareModalSubtitleStar: {
    id: 'shareModal.Subtitle.Star',
    defaultMessage: 'Share on social media',
  },
  shareModalPolicyStar: {
    id: 'shareModal.Policy.Star',
    defaultMessage: 'Community Guidelines',
  },
  shareModalPolicyAgreeStar: {
    id: 'shareModal.PolicyAgree.Star',
    defaultMessage: 'You hereby confirm you have read and will comply with StarMeUp\'s Community Guidelines and that you are not sharing any content that includes confidential information (including personal data from anyone else)',
  },
  shareModalPolicyAgreeStarNotGuidelines: {
    id: 'shareModal.PolicyAgreeNotGuidelines.Star',
    defaultMessage: 'You hereby confirm you are not sharing any content that includes confidential information (including personal data from anyone else)',
  },
  shareModalTitleBadge: {
    id: 'shareModal.Title.Badge',
    defaultMessage: 'Share your badge on social media and shine brighter!',
  },
});
