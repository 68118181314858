// @packages
import React from 'react';
import FullScreenModal from 'smu-app-components/FullScreenModalV2';

// @own
import SendStar from 'containers/SendStar';

const SendStarModal = ({ ...rest }) => <SendStar {...rest} />;

export const SEND_STAR_FLOW_MAIN = 'SEND_STAR_FLOW_MAIN';
export default FullScreenModal(SendStarModal, SEND_STAR_FLOW_MAIN);
