// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';

// @app
import InsightDetails from 'containers/InsightDetails';

// @own
import './styles.scss';

function InsightDetailsLayout() {
  return (
    <div className="insight-details-layout">
      <Container centered>
        <InsightDetails />
      </Container>
    </div>
  );
}

export default InsightDetailsLayout;
