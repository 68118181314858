// @packages
import React from 'react';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import PropTypes from 'prop-types';
import QuestionnaireResults from 'smu-app-components/SurveysResult';
import cn from 'classnames';
import { connect } from 'react-redux';
import { detectWebView } from 'smu-utils/device';

// @app
import {
  ENABLED_TAKEPART_SURVEY,
  SURVEYS_THRESHOLD_NUMBER,
} from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import {
  selectCommunityId,
  selectLanguageCode,
  selectUserRoles,
} from 'containers/Authorization/selectors';

// @own
import './styles.scss';

function QuestionnaireResultsLayout({
  enabledTakePartSurvey,
  orgId,
  roles,
  thresholdNumber,
  userLanguage,
}) {
  const isPlatformAdmin = !!roles?.find((r) => r?.role === 'OS_PLATFORM_ADMIN');
  const isWebView = detectWebView();

  if (!enabledTakePartSurvey) {
    return <GenericErrorState image="padlock" statusCode={403} />;
  }

  return (
    <div
      className={cn('smu-questionnaire-results-container', {
        'smu-questionnaire-results-container--webview': isWebView,
      })}
    >
      <QuestionnaireResults
        defaultBackPath="/questionnaires?tab=CLOSED"
        isPlatformAdmin={isPlatformAdmin}
        orgId={orgId}
        thresholdNumber={thresholdNumber || 4}
        userLanguage={userLanguage}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  enabledTakePartSurvey: getCommunityConfigValueBool(
    state,
    ENABLED_TAKEPART_SURVEY,
  ),
  orgId: selectCommunityId(state),
  roles: selectUserRoles(state),
  thresholdNumber: getCommunityConfigValueBool(state, SURVEYS_THRESHOLD_NUMBER),
  userLanguage: selectLanguageCode(state),
});

QuestionnaireResultsLayout.propTypes = {
  enabledTakePartSurvey: PropTypes.bool,
  orgId: PropTypes.string,
  roles: PropTypes.array,
  thresholdNumber: PropTypes.number,
  userLanguage: PropTypes.string,
};

export default connect(mapStateToProps)(QuestionnaireResultsLayout);
