// @packages
import React from 'react';

// @app
import FeedV2 from 'containers/FeedV2';

const ComponentFeed = () => (
  <div className="component-feed">
    <FeedV2 />
  </div>
);

export default ComponentFeed;
