// @packages
import React from 'react';

// @own
import './styles.scss';

function TestLayout() {
  return (
    <div className="test-layout" />
  );
}

export default TestLayout;
