// @packages
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Link from 'smu-ui-components/Link';
import Button from 'smu-ui-components/Button';

// @own
import {
  TEXT_IN_MINUTE,
  TEXT_IN_MINUTES,
  TEXT_JOIN,
  TEXT_NOW,
} from '../constants';
import './styles.scss';

function Event({
  day,
  endTime,
  isComingSoon,
  isJoinEnabled,
  isNow,
  matched,
  minutesComingSoon,
  month,
  onJoinEvent,
  startTime,
  title,
  url,
}) {
  const eventEnabled = isNow || isComingSoon;
  let textLabel = isNow ? TEXT_NOW : '';

  if (isComingSoon) {
    textLabel = minutesComingSoon > 1 ? TEXT_IN_MINUTES : TEXT_IN_MINUTE;
    textLabel = textLabel.replace('{}', minutesComingSoon);
  }

  // TODO: Move to the SUITE smu-utils package
  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  function getLinkProps(chilloutId) {
    if (chilloutId) {
      return {
        to: `${window.location.pathname}?chilloutId=${chilloutId}`,
      };
    }

    return {
      href: url,
      rel: 'noopener noreferrer',
      target: '_blank',
    };
  }

  const chilloutId = getParameterByName('chilloutId', url);

  return (
    <div
      className={cn('chill-out-event', {
        'chill-out-event--with-top-padding': eventEnabled,
      })}
    >
      <div className="chill-out-event__date">
        <small className="chill-out-event__date-month">{month}</small>
        <h1 className="chill-out-event__date-day">{day}</h1>
      </div>
      <div className="chill-out-event__details">
        {eventEnabled && (
          <div
            className={cn('ui small label chill-out-event__details-status', {
              'chill-out-event__details-status--coming-soon': isComingSoon,
            })}
          >
            {textLabel}
          </div>
        )}
        <p className="chill-out-event__details-title">{title}</p>
        <p className="chill-out-event__details-subtitle">
          <span className="chill-out-event__details-subtitle-text">
            {matched ? 'Match with your preferences' : 'Community event'}
          </span>
          <span className="chill-out-event__details-subtitle-time">
            {startTime} - {endTime}
          </span>
        </p>
        {isJoinEnabled && (
          <Link className="chill-out-event__details-join-btn-link" {...getLinkProps(chilloutId)}>
            <Button
              active
              className="chill-out-event__details-join-btn"
              color="black"
              onClick={(e) => onJoinEvent(e, chilloutId)}
              primary
              size="small"
            >
              {TEXT_JOIN}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}

Event.defaultProps = {
  isComingSoon: false,
  isJoinEnabled: false,
  isNow: false,
  matched: false,
  minutesComingSoon: 0,
  onJoinEvent: () => {},
};

Event.propTypes = {
  day: PropTypes.string,
  endTime: PropTypes.string,
  isComingSoon: PropTypes.bool,
  isJoinEnabled: PropTypes.bool,
  isNow: PropTypes.bool,
  matched: PropTypes.bool,
  minutesComingSoon: PropTypes.number,
  month: PropTypes.string,
  onJoinEvent: PropTypes.func,
  startTime: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

export default Event;
