import {
  CLEAN_NOTIFICATIONS_HIS,
  MARK_ALL_NOTIFICATION_HISTORY_AS_READ,
  REQUEST_NOTIFICATIONS_HIS,
  SET_READ_NOTIFICATION_HISTORY,
} from './actionTypes';

export function getNotifications(page) {
  return {
    type: REQUEST_NOTIFICATIONS_HIS,
    payload: page,
  };
}

export function cleanNotifications() {
  return {
    type: CLEAN_NOTIFICATIONS_HIS,
  };
}

export function setReadNotificationHistory(notificationId) {
  return {
    type: SET_READ_NOTIFICATION_HISTORY,
    payload: notificationId,
  };
}

export function markAllNotificationsAsRead() {
  return {
    type: MARK_ALL_NOTIFICATION_HISTORY_AS_READ,
  };
}
