// @packages
import React, { useEffect } from 'react';
import Carousel from 'smu-ui-components/Carousel';
import LottieSpinner from 'smu-ui-components/LottieSpinner';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import Segment from 'smu-ui-components/Segment';
import cn from 'classnames';
import usePrevious from 'smu-custom-hooks/usePrevious';

// @own
import Event from './Event';
import Header from './Header';
import './styles.scss';

function ChillOutEvents({
  events,
  isRedirecting,
  loading,
  onClickShowPreferences,
  onEndEvent,
  onJoinEvent,
  onNextEvent,
  onPrevEvent,
  totalEventsShow,
}) {
  const sliceEvents = events.slice(0, totalEventsShow);
  const nowEvents = events.filter(e => e.isNow);
  const prevNowEvents = usePrevious(nowEvents);

  useEffect(() => {
    if (
      prevNowEvents &&
      nowEvents &&
      prevNowEvents.length &&
      prevNowEvents.length > nowEvents.length
    ) {
      onEndEvent();
    }
  });

  return (
    <Panel className="chill-out-events" noPadding>
      <Header onClickShowPreferences={onClickShowPreferences} />
      <Segment
        className="chill-out-events__segment"
        loading={loading}
        loadingType="starmeup"
        spinnerHeight={35}
        spinnerWidth={35}
        withChildren={false}
      >
        <div className="chill-out-events__body">
          {isRedirecting && (
            <LottieSpinner className="chill-out-events__loader" height={50} width={50} />
          )}
          <Carousel
            containerClassName={cn('chill-out-events__carousel', {
              'chill-out-events__carousel--redirecting': isRedirecting,
            })}
            items={sliceEvents.map(event => (
              <Event
                key={`event-${event.url}`}
                onEndEvent={() => onEndEvent(event)}
                onJoinEvent={onJoinEvent}
                {...event}
              />
            ))}
            onNext={onNextEvent}
            onPrev={onPrevEvent}
            scrollByPage
            size={1}
          />
        </div>
      </Segment>
    </Panel>
  );
}

ChillOutEvents.defaultProps = {
  events: [],
  isRedirecting: false,
  loading: false,
  onClickShowPreferences: () => {},
  onEndEvent: () => {},
  onJoinEvent: () => {},
  onNextEvent: () => {},
  onPrevEvent: () => {},
  totalEventsShow: 3,
};

ChillOutEvents.propTypes = {
  events: PropTypes.array,
  isRedirecting: PropTypes.bool,
  loading: PropTypes.bool,
  onClickShowPreferences: PropTypes.func,
  onEndEvent: PropTypes.func,
  onJoinEvent: PropTypes.func,
  onNextEvent: PropTypes.func,
  onPrevEvent: PropTypes.func,
  totalEventsShow: PropTypes.number,
};

export default ChillOutEvents;
