// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

function DNA({ className, values }) {
  return (
    <div className={cn('static-DNA', className)}>
      {values.map(({ color1, color2 }, key) => (
        <div className="static-DNA__level" key={`dna-${key}`}>
          <div
            className="static-DNA__level-circle"
            style={{
              backgroundColor: color1,
            }}
          />
          <div className="static-DNA__level-line" />
          <div
            className="static-DNA__level-circle"
            style={{
              backgroundColor: color2,
            }}
          />
        </div>
      ))}
    </div>
  );
}

DNA.defaultProps = {
  values: [],
};

DNA.propTypes = {
  className: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      color1: PropTypes.string.isRequired,
      color2: PropTypes.string.isRequired,
    }),
  ),
};

export default DNA;
