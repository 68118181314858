// @packages
import { useEffect } from 'react';

// @app
import {
  fullScreenChangeListener,
  fullScreenChangeRemoveListener,
} from 'utils/fullScreenChangeListener';

const useFullScreen = () => {
  useEffect(() => {
    let prevScrollPosition = window.scrollY;
    let scrollPosition = window.scrollY;

    const handleScroll = () => {
      prevScrollPosition = scrollPosition;
      scrollPosition = window.scrollY;
    };

    const handleFullScreenChange = () => {
      if (
        !document.fullscreenElement &&
        scrollPosition === 0 &&
        scrollPosition !== prevScrollPosition
      ) {
        window.scrollTo(0, prevScrollPosition);
      }
    };

    window.addEventListener('scroll', handleScroll);
    fullScreenChangeListener(handleFullScreenChange);

    return () => {
      fullScreenChangeRemoveListener(handleFullScreenChange);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};

export default useFullScreen;
