import { includes } from 'lodash';

export const isOtherNotification = (type) => {
  const otherNotifications = [
    'COREVALUE',
    'REMAINING_STAR',
    'CUSTOMNOTIFICATION',
    'UNSEENSTAR',
    'MENTION',
    'CUSTOM_GLOBAL_NOTIFICATION',
    'YEARINREVIEW',
    'DEEP_LINK',
  ];
  return includes(otherNotifications, type);
};
