// @packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @own
import './styles.scss';

const AvatarMedia = ({ className, avatar, interaction, info, ...rest }) => (
  <div className={cn('smu-avatar-media', className)} {...rest}>
    <div className="smu-avatar-media__interaction">
      {avatar}
      {interaction}
    </div>
    {info}
  </div>
);

AvatarMedia.propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  className: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  interaction: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

export default AvatarMedia;
