import { omit } from 'lodash';
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, vo, times } from './../../api';

export function getFiltersByUses(params) {
  const { organizationId } = params;
  const paramsToSend = omit(params, 'organizationId');
  return apiInstance({
    url: addParams(`/v2/communities/${organizationId}/fields/uses`, {
      ...paramsToSend,
    }),
    method: 'get',
  }).then(({ data }) => (
    data.result
  )).catch(errorHandler);
}

export function getFiltersValues(params) {
  const baseUrl = '/v2/stellar/categoryValues';
  return apiInstance({
    data: params,
    method: 'post',
    url: addParams(baseUrl, {
      ...vo,
      ...times(),
    }),
  }).then(({ data }) => (
    data.result
  )).catch(errorHandler);
}
