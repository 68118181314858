import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// @app
import { actionItemsUpdateMultiple } from 'services/items/actions';
import { helperGetFeedPayload } from 'utils/star';
import { selectSessionUserId } from 'containers/Authorization/selectors';
import { uploadImage } from 'services/sendStarFlow/api';

// @own
import {
  feedSetFields,
  feedSetOptions,
  getFeed,
  getFeedFailed,
  getFeedNextPage,
  getFeedNextPageSuccess,
  getFeedResetPage,
  getFeedSuccess,
  removeLike,
  removeLikeFail,
  removeLikeSuccess,
  sendCommentFail,
  sendCommentSuccess,
  sendLike,
  sendLikeFail,
  sendLikeSuccess,
  toggleMainComment,
} from './actions';
import {
  SEND_COMMENT,
  SEND_IMAGE,
  SEND_IMAGE_SUCCESS,
  SEND_IMAGE_FAIL,
} from './actionTypes';
import {
  getFeed as getFeedAPI,
  sendComment as sendCommentAPI,
  sendLike as sendLikeAPI,
  removeLike as removeLikeAPI,
  toggleMainComment as toggleMainCommentAPI,
  commentImage as commentImageAPI,
} from './api';

import { normalizeItems } from './helpers';

// TODO: How can I dehardcode this .feed?
const grabState = s => s.feed;

const setLikesCommentsCount = obj =>
  cloneDeep(obj).map((card) => {
    card.commentCount = 0;
    card.likeCount = 0;
    card.comments.map((comments) => {
      if (comments.type === 'LIKE') {
        card.likeCount += 1;
      } else {
        card.commentCount += 1;
      }
    });
    return card;
  });

function* getFeedWorker({ type }) {
  try {
    let { options } = yield select(grabState);
    const getOptions = pick(options, [
      'page',
      'size',
      'orderBy',
      'filterBy',
      'optionalFields',
      'firstStarId',
      'userId',
      'hashtag',
      'useActivityUrl',
      'useProfileUrl',
    ]);
    const results = yield call(getFeedAPI, {
      ...getOptions,
    });
    const postResults = setLikesCommentsCount(results);
    const items = normalizeItems(postResults);

    if (items?.length) {
      yield put(actionItemsUpdateMultiple(items));
    }

    if (includes([getFeed().type, getFeedResetPage().type], type)) {
      yield put(getFeedSuccess(postResults));
    } else {
      yield put(getFeedNextPageSuccess(postResults));
    }
  } catch (error) {
    yield put(getFeedFailed(error));
  }
}

function* getFeedWatcher() {
  yield takeEvery(
    [getFeed().type, getFeedNextPage().type, getFeedResetPage().type],
    getFeedWorker,
  );
}

function* feedSetOptionsWorker({ payload }) {
  const { updateFeed } = payload || {};
  if (updateFeed) {
    yield put(getFeed());
  }
}

function* feedSetOptionsWatcher() {
  yield takeEvery(feedSetOptions().type, feedSetOptionsWorker);
}

function* feedSetFieldsWorker({ payload }) {
  const { updateFeed } = payload || {};
  if (updateFeed) {
    yield put(getFeed());
  }
}

function* feedSetFieldsWatcher() {
  yield takeEvery(feedSetFields().type, feedSetFieldsWorker);
}

function* sendCommentWorker({ payload }) {
  try {
    const result = yield call(sendCommentAPI, payload);
    yield put(sendCommentSuccess({ ...payload, result }));
  } catch (err) {
    yield put(sendCommentFail(err));
  }
}

function* sendCommentWatcher() {
  yield takeEvery(SEND_COMMENT, sendCommentWorker);
}

function* sendLikeWorker({ payload }) {
  try {
    const result = yield call(sendLikeAPI, payload);
    yield put(sendLikeSuccess({ ...payload, result }));
  } catch (err) {
    yield put(sendLikeFail(err));
  }
}

function* sendLikeWatcher() {
  yield takeEvery(sendLike().type, sendLikeWorker);
}

function* removeLikeWorker({ payload }) {
  try {
    const result = yield call(removeLikeAPI, payload);
    yield put(removeLikeSuccess({ ...payload, result }));
  } catch (err) {
    yield put(removeLikeFail(err));
  }
}

function* removeLikeWatcher() {
  yield takeEvery(removeLike().type, removeLikeWorker);
}

function* toggleMainCommentWorker({ payload }) {
  try {
    const { activityId, action } = payload;
    const userId = yield select(selectSessionUserId);
    const feedPayload = helperGetFeedPayload(activityId, userId);
    yield call(toggleMainCommentAPI, { activityId, action });
    yield put(feedSetFields(feedPayload));
  } catch (err) {
    yield put(getFeedFailed(err));
  }
}

function* toggleMainCommentWatcher() {
  yield takeEvery(toggleMainComment().type, toggleMainCommentWorker);
}

function* uploadImageWorker({ payload }) {
  try {
    const response = yield call(uploadImage, { image: payload.file });
    const comment = yield call(commentImageAPI, {
      imageCode: response.result.code,
      id: payload.id,
    });
    yield put({ type: SEND_IMAGE_SUCCESS, payload: { id: payload.id, comment: comment.result } });
  } catch (e) {
    yield put({ type: SEND_IMAGE_FAIL });
  }
}

export function* uploadImageWatcher() {
  yield takeEvery(SEND_IMAGE, uploadImageWorker);
}

export default {
  getFeedWatcher,
  feedSetOptionsWatcher,
  feedSetFieldsWatcher,
  sendCommentWatcher,
  sendLikeWatcher,
  removeLikeWatcher,
  toggleMainCommentWatcher,
  uploadImageWatcher,
};
