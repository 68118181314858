// @packages
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import withRootModal from 'smu-app-components/RootModalV2/withRootModal';
import withFullScreenModal from 'smu-app-components/FullScreenModalV2/withFullScreenModal';

// @app
import { actions as routeActions } from 'utils/routes';
import { feedSetFields, toggleMainComment } from 'containers/withFeed/actions';
import { isModerator } from 'utils/checkRights';
import { liftStar, setInitial } from 'services/sendStarFlow/actions';
import {
  selectHasMentionsEnabled,
  selectSession,
  selectSessionStars,
  selectSessionUserId,
} from 'containers/Authorization/selectors';
import {
  selectHasStarsToSend,
  selectLiftStarEntities,
} from 'services/sendStarFlow/selectors';

const withStarProps = (WrappedComponent, type) => {
  function StarContext({
    canEdit,
    enabled,
    feedSetFields,
    goToHome,
    handleProfileRedirectionOnClick,
    hasStarsToSend,
    intl: { formatMessage },
    isCustomerStar,
    isModerate,
    isModerator,
    liftStar,
    liftStarEntities,
    location,
    mentionsEnabled,
    notesClassName,
    openFullscreenModal,
    openModal,
    ownId,
    showActivity,
    showProfile,
    starId,
    starUrl,
    toggleMainComment,
    trackHashtag,
  }) {
    const handleModerateStar = () => toggleMainComment({
      action: enabled ? 'hide' : 'show',
      activityId: starId,
    });

    const props = () => ({
      STAR: {
        addToastMessage,
        canEdit,
        formatMessage,
        isModerate,
        isModerator,
        onModerateStar: handleModerateStar,
        ownId,
        showActivity,
        showProfile,
        starUrl,
      },
      SIMPLE_STAR: {
        addToastMessage,
        canEdit,
        feedSetFields,
        formatMessage,
        goToHome,
        isModerate,
        isModerator,
        location,
        notesClassName,
        onModerateStar: handleModerateStar,
        openFullscreenModal,
        ownId,
        showProfile,
        starUrl,
        trackHashtag,
      },
      KUDOS: {
        canEdit,
        feedSetFields,
        formatMessage,
        goToHome,
        isCustomerStar,
        isModerate,
        isModerator,
        location,
        notesClassName,
        onModerateStar: handleModerateStar,
        openFullscreenModal,
        openModal,
        ownId,
        showProfile,
        starUrl,
        trackHashtag,
      },
      FOOTER: {
        feedSetFields,
        goToHome,
        handleProfileRedirectionOnClick,
        hasStarsToSend,
        isModerator,
        liftStar,
        liftStarEntities,
        location,
        mentionsEnabled,
        openModal,
        ownId,
      },
    })[type];

    const ownProps = props();

    return (
      <WrappedComponent {...ownProps} />
    );
  }

  StarContext.propTypes = {
    addToastMessage: PropTypes.func.isRequired,
    feedSetFields: PropTypes.func,
    goToHome: PropTypes.func,
    handleProfileRedirectionOnClick: PropTypes.func,
    hasStarsToSend: PropTypes.bool,
    intl: PropTypes.object,
    isCustomerStar: PropTypes.bool,
    isModerator: PropTypes.bool,
    liftStar: PropTypes.func.isRequired,
    liftStarEntities: PropTypes.array,
    location: PropTypes.object,
    mentionsEnabled: PropTypes.bool,
    notesClassName: PropTypes.string,
    openFullscreenModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    ownId: PropTypes.number,
    postOsFeedModerateComment: PropTypes.func,
    shaddyNotes: PropTypes.bool,
    showActivity: PropTypes.func.isRequired,
    showBrightenStarDiscovery: PropTypes.bool,
    showProfile: PropTypes.func.isRequired,
    toggleMainComment: PropTypes.func,
    trackHashtag: PropTypes.func,
  };

  function mapStateToProps(state) {
    const session = selectSession(state);
    const moderator = isModerator(session);
    const ownId = selectSessionUserId(state);
    const liftStarEntities = selectLiftStarEntities(state);
    const mentionsEnabled = selectHasMentionsEnabled(state);
    const hasStarsToSend = selectHasStarsToSend(state);
    const stars = selectSessionStars(state);

    return {
      hasStarsToSend,
      isModerator: moderator,
      liftStarEntities,
      mentionsEnabled,
      ownId,
      stars,
    };
  }

  const actions = {
    addToastMessage,
    feedSetFields,
    goToHome: state => routeActions.home(null, state),
    liftStar,
    setInitial,
    showActivity: activityId => routeActions.activity({ activityId }),
    showProfile: profileId => routeActions.profile({ profileId }),
    toggleMainComment,
  };

  const StarWithModals = withFullScreenModal(withRootModal(injectIntl(StarContext)));

  return withRouter(connect(mapStateToProps, actions)(StarWithModals));
};

export default withStarProps;
