// @Packages
import React, { Fragment, useState, useEffect } from 'react';
import { closeFullscreenModal } from 'smu-app-components/FullScreenModal/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'utils/gtm';
import FullScreenModal from 'smu-app-components/FullScreenModal';
import Icon from 'smu-ui-components/Icon';
import onClickOutside from 'react-onclickoutside';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import Tabs from 'smu-ui-components/Tabs';
import LottieSpinner from 'smu-ui-components/LottieSpinner';

// @App
import Star from 'assets/icons/lift-star-done.svg';

// @Own
import Messages from './messages';
import Activity from './Activity';
import {
  LIKE,
  LIFT_STAR,
  ALL,
  TABS,
  TABS_INDEX,
} from './constants';
import './styles.scss';
import { MODAL_INTERACTION_ACTIVITY } from './actionsType';


const InteractionModal = ({
  closeFullscreenModal,
  defaultType,
  getAnalyticsEvent,
  intl: { formatMessage },
  isLoading,
  starData,
}) => {
  const [activeTab, setActiveTab] = useState(TABS_INDEX[defaultType]);
  const [data, setData] = useState([]);
  const content = data?.content || [];

  const loadData = () => {
    const availableSections = [LIKE, LIFT_STAR];
    const tempSections = {
      likesTotal: 0,
      liftStarTotal: 0,
      content: [],
    };

    tempSections.content = starData
      .filter(star => availableSections.includes(star.type))
      .map((star) => {
        if (availableSections.includes(star.type)) {
          if (star.type === LIKE) {
            tempSections.likesTotal++;
          } else {
            tempSections.liftStarTotal++;
          }

          return {
            id: star.id,
            imageCode: star.user.profileImageCode,
            job: star.user.job,
            name: star.user.fullName,
            profileId: star.user.uid,
            type: star.type,
          };
        }

        return false;
      });

    setData(tempSections);
  };


  const activateSection = (sectionType) => {
    if (getAnalyticsEvent) {
      trackEvent({ ...getAnalyticsEvent(sectionType) });
    } else {
      const trackData = {
        category: 'Card Activity',
        action: '',
      };
      if (sectionType === ALL) {
        trackData.action = 'Interactions_AllTab';
      } else {
        trackData.action = sectionType === LIKE ? 'Interactions_LikesTab' : 'Interactions_BrightensTab';
      }
      trackEvent(trackData);
    }
  };

  useEffect(() => {
    activateSection(TABS[activeTab]);
  }, [activeTab]);

  useEffect(() => {
    loadData();
  }, [isLoading, starData]);


  const getTabs = () => {
    const tabs = [
      {
        name: formatMessage(Messages.tabAll),
        onClick: () => { setActiveTab(0); },
      },
    ];

    if (data.likesTotal > 0) {
      tabs.push({
        name: (
          <Fragment>
            <Icon
              color="red"
              icon="like-full"
              size="micro"
              className="interaction__modal-tabs-hearth"
            />
            <p>{data.likesTotal}</p>
          </Fragment>
        ),
        onClick: () => { setActiveTab(1); },
      });
    }

    if (data.liftStarTotal > 0) {
      tabs.push({
        name: (
          <Fragment>
            <img
              src={Star}
              className="interaction__modal-tabs-star"
              alt=""
            />
            <p>{data.liftStarTotal}</p>
          </Fragment>
        ),
        onClick: () => { setActiveTab(2); },
      });
    }

    return tabs;
  };

  return (
    <Panel extraClass="interaction__modal">
      <div className="interaction__modal-header">
        <h4 className="interaction__modal-title">
          {formatMessage(Messages.title)}
        </h4>
        <Icon
          className="interaction__modal-header-close"
          color="gray"
          icon="close"
          onClick={closeFullscreenModal}
          size="micro"
        />
      </div>
      {isLoading ? (
        <LottieSpinner
          className="interaction__modal-loader"
          loadingType="starmeup"
          height={40}
          width={40}
        />
      ) : (
        <>
          <div className="interaction__modal-tabs">
            <Tabs
              active={activeTab}
              extraTabClassName="interaction__modal-tabs-extra-tab-className"
              tabs={getTabs()}
            />
          </div>
          <div className="interaction__modal-body">
            {content
              .filter((c) => (TABS[activeTab] === ALL ? c : c.type === TABS[activeTab]))
              .map((activity, i) => (
                <Activity key={i} {...activity} />
              ))}
          </div>
        </>
      )}
    </Panel>
  );
};

InteractionModal.defaultProps = {
  defaultType: ALL,
  isLoading: false,
  starData: [],
};

InteractionModal.propTypes = {
  closeFullscreenModal: PropTypes.func,
  defaultType: PropTypes.oneOf([LIKE, LIFT_STAR, ALL]),
  getAnalyticsEvent: PropTypes.func,
  intl: PropTypes.object,
  isLoading: PropTypes.bool,
  starData: PropTypes.object,
};

function InteractionModalWrapper({ renderInteraction, ...rest }) {
  return renderInteraction(InteractionModal, { ...rest });
}

InteractionModalWrapper.defaultProps = {
  renderInteraction: (Cmp, props) => <Cmp {...props} />,
};

export default FullScreenModal(
  connect(null, { closeFullscreenModal })(onClickOutside(injectIntl(InteractionModalWrapper), {
    handleClickOutside: instance => () => instance.props.closeFullscreenModal(),
  })),
  MODAL_INTERACTION_ACTIVITY,
);
