import { apiInstance, authInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times, vo } from 'api';

export function authorize() {
  return apiInstance({
    url: addParams('/v2/sec/userwithtoken', { ...vo, ...times() }),
    method: 'get',
    headers: {
      'Accept-Language': '',
      language: '',
    },
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function lookUpAuth(email) {
  const params = { ...vo, email };
  return authInstance({
    url: '/v2/sec/lookup',
    params,
    method: 'get',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
