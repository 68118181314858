// @packages
import React from 'react';
import Icon from 'smu-ui-components/Icon';
import RootModal from 'smu-app-components/RootModal';
import PropTypes from 'prop-types';
import Divider from 'smu-ui-components/Divider';
import { injectIntl } from 'react-intl';
import Panel from 'smu-ui-components/Panel';
import Button from 'smu-ui-components/ButtonV2';

// @own
import { BETHERE_CONFIRMATION_MODAL } from './constants';
import messages from '../messages';
import './styles.scss';

function ModalConfirmation({
  closeModal,
  description,
  extraModalProps: { onAccept, onCancel },
  intl: { formatMessage },
  title,
}) {
  function handleOnCancel() {
    closeModal();

    if (onCancel) {
      onCancel();
    }
  }

  function handleOnAccept() {
    closeModal();

    if (onAccept) {
      onAccept();
    }
  }

  return (
    <div className="bt-modal-confirmation">
      <Panel title={title} className="bt-modal-confirmation__panel">
        <div className="bt-modal-confirmation__close">
          <Icon
            className="bt-modal-confirmation__close-icon"
            color="white"
            icon="close"
            onClick={closeModal}
            size="large"
          />
        </div>
        <div className="bt-modal-confirmation__body">{description}</div>
        <Divider nomargin />
        <div className="bt-modal-confirmation__footer">
          <Button onClick={handleOnCancel} variant="outline">{formatMessage(messages.smuCommonCancel)}</Button>
          <Button color="black" onClick={handleOnAccept}>{formatMessage(messages.smuCommonAccept)}</Button>
        </div>
      </Panel>
    </div>
  );
}

ModalConfirmation.defaultProps = {
  description: '',
  title: '',
  extraModalProps: {
    onAccept: () => undefined,
    onCancel: () => undefined,
  },
};

ModalConfirmation.propTypes = {
  closeModal: PropTypes.func.isRequired,
  description: PropTypes.string,
  extraModalProps: PropTypes.object,
  title: PropTypes.string,
};

const modalStyles = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  marginTop: '0',
};

export default RootModal(injectIntl(ModalConfirmation), BETHERE_CONFIRMATION_MODAL, modalStyles);
