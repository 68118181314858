// @packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useModal from 'smu-custom-hooks/useModal';
import { buildImageUrl } from 'smu-utils/image';
import { aspectRatioPercentage } from 'smu-utils/aspectRatio';

// @app
import ModalImageSMU from 'components/Modals/ImageSMU';
import CardPhoto from 'components/CardPhoto';
import { trackEvent } from 'utils/gtm';

function MainPhoto({ mainPhoto }) {
  const modal = useModal();
  const [image, setImage] = useState();
  const thumbnailBlurUrl = buildImageUrl({ code: mainPhoto, width: 10, height: 10 });
  const imageSrc = buildImageUrl({ code: mainPhoto });
  const aspectRatio = image ? aspectRatioPercentage(image) : 0;

  function handleOnImageClick(e) {
    e.stopPropagation();
    trackEvent({
      action: 'Click on photo',
      category: 'Card Activity',
    });
    modal.open();
  }

  const onLoadThumb = (e) => {
    setImage({
      height: e.target.naturalHeight,
      width: e.target.naturalWidth,
    });
  };

  return (
    <>
      <CardPhoto
        aspectRatio={aspectRatio}
        className="rounded-lg"
        imageSrc={imageSrc}
        maxAspectRatio={120}
        onClick={handleOnImageClick}
        onLoadThumb={onLoadThumb}
        showMedia
        thumbnailBlurUrl={thumbnailBlurUrl}
      />
      {modal.isOpen && (
        <ModalImageSMU
          className="star-main-photo__modal"
          imageCode={mainPhoto}
          imageHeight={0}
          imageWidth={0}
          onClick={modal.close}
          onClose={modal.close}
          open
        />
      )}
    </>
  );
}

MainPhoto.propTypes = {
  mainPhoto: PropTypes.string,
  openFullscreenModal: PropTypes.func,
};

export default MainPhoto;
