// @packages
import React from 'react';
import Button from 'smu-ui-components/Button';
import Icon from 'smu-ui-components/IconV2';
import InputRichText from 'smu-ui-components/InputRichText';
import cn from 'classnames';

// @app
import Emojis from 'components/Emojis';
import SelectSearchUserContainer from 'containers/Selects/SearchUser';
import StarContainer from 'components/StarContainer';
import Title from 'components/Title';
import WhatValue from 'containers/WhatValue';
import WriteForMe from 'containers/AI/WriteForMe';

// @own
import './styles.scss';
import ImagePreview from '../ImagePreview';
import SelectedEntities from '../SelectedEntities';
import { EMOJIS_STYLES } from './constants';

const SendStarForm = ({
  addEmoji,
  allowMultipleUsers,
  buttonColor,
  decOffset,
  decOffsetCC,
  descriptionProps,
  disabledCarbonCopySearch,
  disabledSearch,
  disabledSendStarButton,
  entities,
  entitiesCC,
  entitiesQuantity,
  errorCode,
  image,
  incOffset,
  incOffsetCC,
  isEditing,
  offset,
  offsetCC,
  onCancel,
  onClickEntity,
  onClickEntityCC,
  onRemoveEntity,
  onRemoveEntityCC,
  onSend,
  oneEntity,
  pickerVisibility,
  removeImage,
  showUserClarification,
  showWriteForMe,
  star,
  stars,
  texts,
  togglePicker,
  uploadingImage,
  writeForMeProps,
}) => (
  <div className="send-star-form">
    <div
      className="send-star-form__who"
      style={{ zIndex: pickerVisibility ? 0 : 2 }}
    >
      <div className="send-star-form__colleague">
        <StarContainer>
          <Title className="send-star-form__title">{texts.who}</Title>
          {!isEditing && (
            <SelectSearchUserContainer
              autoFocus
              disabled={disabledSearch}
              exclude={entities}
              onChange={onClickEntity}
              placeholder={texts?.searchMessages?.placeholder}
            />
          )}
          {!!entities?.length && (
            <SelectedEntities
              allowEditing={!isEditing}
              entitiesToShow={entities}
              offset={offset}
              onLeft={decOffset}
              onRemoveEntity={onRemoveEntity}
              onRight={incOffset}
            />
          )}
        </StarContainer>
      </div>
    </div>
    <div className="send-star-form__what">
      <StarContainer>
        <Title className="send-star-form__title">{texts.what}</Title>
        <WhatValue
          entities={entities}
          isEdit={isEditing}
          listRecognitionText={texts.chooseRecognition}
          selectedValue={star}
          values={stars}
        />
        {showUserClarification && (
          <div className="send-star-form__what-info">
            <span className="send-star-form__what-info-wrapper">
              <Icon
                className="send-star-form__what-info-icon"
                color="blue-info"
                icon="info"
              />
              {texts.multipleUsersClarification}
            </span>
          </div>
        )}
      </StarContainer>
    </div>
    <div
      className={cn({
        'send-star-form__message-container':
          !allowMultipleUsers ||
          !entities ||
          (entities && entitiesQuantity === 0),
        'send-star-form__message-container--kudos':
          allowMultipleUsers && oneEntity,
        'send-star-form__message-container--kudos-multiple':
          allowMultipleUsers && entities && entitiesQuantity > 1,
      })}
    >
      <StarContainer>
        <div className="send-star-form__message">
          <Title className="send-star-form__title">{texts.why}</Title>
          <div className="send-star-form__textarea">
            {showWriteForMe && (
              <WriteForMe starName={star?.nameShowed} {...writeForMeProps} />
            )}
            <InputRichText
              richTextClass={cn('send-star-form__why', {
                'send-star-form__why--ai': showWriteForMe,
              })}
              {...descriptionProps}
            />
          </div>
          <ImagePreview
            image={image}
            isUploading={uploadingImage}
            onRemove={removeImage}
          />
          <div className="send-star-form__emojis">
            {pickerVisibility && (
              <Emojis
                handleClick={addEmoji}
                onClickOutside={togglePicker}
                style={EMOJIS_STYLES}
              />
            )}
          </div>
        </div>
        {!isEditing && !allowMultipleUsers && entitiesQuantity <= 1 && (
          <div className="send-star-form__carbon-copy">
            <div className="send-star-form__search-cc z-20 relative">
              {!isEditing && (
                <SelectSearchUserContainer
                  autoFocus
                  disabled={disabledCarbonCopySearch}
                  exclude={[...(entitiesCC || []), ...(entities || [])]}
                  onChange={onClickEntityCC}
                  placeholder={texts?.searchCCMessages?.placeholder}
                />
              )}
            </div>
            {!!entitiesCC?.length && (
              <SelectedEntities
                allowEditing={!isEditing}
                entitiesToShow={entitiesCC}
                offset={offsetCC}
                onLeft={decOffsetCC}
                onRemoveEntity={onRemoveEntityCC}
                onRight={incOffsetCC}
              />
            )}
          </div>
        )}
        <div className="send-star-form__footer">
          <div className="send-star-form__footer-error">
            {errorCode === 500 && (
              <>
                <div className="send-star-form__footer-error-primary">
                  {texts.sendStarsServerError}
                </div>
                <div className="send-star-form__footer-error-secondary">
                  {texts.sendStarsServerErrorHelper}
                </div>
              </>
            )}
          </div>
          <div className="send-star-form__footer-buttons">
            <Button noMarginBottom noMarginRight onClick={onCancel}>
              {texts.cancel}
            </Button>
            <Button
              disabled={disabledSendStarButton}
              noMarginBottom
              noMarginRight
              onClick={onSend}
              style={{ background: buttonColor, color: 'white' }}
              transparent
            >
              {texts.send}
            </Button>
          </div>
        </div>
      </StarContainer>
    </div>
  </div>
);

export default SendStarForm;
