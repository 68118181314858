// @packages
import React, { useEffect, Fragment } from 'react';
import Icon from 'smu-ui-components/IconV2';
import Link from 'smu-ui-components/Link';
import cn from 'classnames';
import { actionRequestInit } from 'smu-utils/reduxRequests/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectLanguageCode } from 'containers/Authorization/selectors';
import { selectRequesting, selectResult, selectError } from 'smu-utils/reduxRequests/selectors';

// @own
import { apiUsefulLinks } from './api';
import SkeleteonUsefulLinks from './SkeleteonUsefulLinks';
import { COMMUNICATIONS_USEFUL_LINKS } from './constants';
import messages from './messages';
import './styles.scss';

const UsefulLinksWidget = ({
  actionRequestInit,
  data,
  error,
  intl: { formatMessage },
  languageCode,
  loading,
}) => {
  const getUsefulLinks = () => {
    actionRequestInit({
      api: apiUsefulLinks,
      id: COMMUNICATIONS_USEFUL_LINKS,
      params: { languageCode },
    });
  };

  useEffect(() => {
    getUsefulLinks();
  }, []);

  if (data?.message || error) {
    return null;
  }

  if (loading) {
    return <SkeleteonUsefulLinks />;
  }

  return (
    <div className="useful-links-widget shadow-base rounded-lg">
      <div className="useful-links-widget__title">{formatMessage(messages.usefulLinksTitle)}</div>
      <div className="useful-links-widget__links">
        {data?.map((item, idx) => (
          <Fragment>
            <div
              className={cn('useful-links-widget__link-container', {
                'useful-links-widget__link-container--no-margin-top': idx === 0,
                'useful-links-widget__link-container--no-margin-bottom': idx === (data?.length - 1),
              })}
              key={+new Date()}
            >
              <Link
                className="useful-links-widget__link-title"
                href={item?.link}
              >
                <span className="useful-links-widget__link-title--text">
                  {item?.title}
                </span>
                <Icon
                  className="useful-links-widget__link--icon"
                  icon="link"
                  size={16}
                  color="button"
                />
              </Link>
              <div className="useful-links-widget__link-description">
                {item?.description}
              </div>
            </div>
            {idx !== (data?.length - 1) &&
              <div className="useful-links-widget__divider"/>}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: selectResult(state, COMMUNICATIONS_USEFUL_LINKS),
  error: selectError(state, COMMUNICATIONS_USEFUL_LINKS),
  languageCode: selectLanguageCode(state),
  loading: selectRequesting(state, COMMUNICATIONS_USEFUL_LINKS),
});

export default connect(mapStateToProps, { actionRequestInit })(injectIntl(UsefulLinksWidget));
