import {
  REQUEST_USERS_BY_KUDOS_STAR_ID,
  REQUEST_USERS_BY_KUDOS_STAR_ID_CLEAN,
  REQUEST_USERS_BY_KUDOS_STAR_ID_FAIL,
  REQUEST_USERS_BY_KUDOS_STAR_ID_SUCCESS,
} from './actionTypes';

export function actionUserByKudosStarId(payload) {
  return {
    payload,
    type: REQUEST_USERS_BY_KUDOS_STAR_ID,
  };
}

export function actionUserByKudosStarIdSuccess(payload) {
  return {
    payload,
    type: REQUEST_USERS_BY_KUDOS_STAR_ID_SUCCESS,
  };
}

export function actionUserByKudosStarIdFail(payload) {
  return {
    payload,
    type: REQUEST_USERS_BY_KUDOS_STAR_ID_FAIL,
  };
}

export function actionUserByKudosStarIdClean(payload) {
  return {
    payload,
    type: REQUEST_USERS_BY_KUDOS_STAR_ID_CLEAN,
  };
}
