import { defineMessages } from 'react-intl';

export default defineMessages({
  discoverFiltersTitle: {
    id: 'discoverFilters.title',
    defaultMessage: 'Filters',
  },
  discoverFiltersReset: {
    id: 'discoverFilters.reset',
    defaultMessage: 'Reset',
  },
  discoverFiltersSeeResults: {
    id: 'discoverFilters.SeeResults',
    defaultMessage: 'See results',
  },
  discoverFiltersNoOptions: {
    id: 'discoverFilters.NoOptions',
    defaultMessage: 'No options',
  },
  discoverFiltersConfirmationTitle: {
    id: 'discoverFilters.ConfirmationTitle',
    defaultMessage: 'Discard current selection',
  },
  discoverFiltersConfirmationDescription: {
    id: 'discoverFilters.ConfirmationDescription',
    defaultMessage: 'By editing the filters you will discard the current colleagues selection',
  },
  discoverFiltersConfirmationYes: {
    id: 'discoverFilters.ConfirmationYes',
    defaultMessage: 'Yes',
  },
  discoverFiltersConfirmationNo: {
    id: 'discoverFilters.ConfirmationNo',
    defaultMessage: 'No',
  },
});
