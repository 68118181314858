import { buildClosePath } from '../helpers';

const postDetails = ({ hookModal, query, onClose }) => {
  if (query?.id) {
    const closePath = buildClosePath(['id']);
    hookModal.open({
      cardId: Number(query.id),
      onClose: () => onClose(closePath),
    });
  }
};

export default postDetails;
