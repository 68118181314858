// @packages
import React from 'react';
import Datepicker from 'smu-ui-components/Datepicker';
import GenericEmptyState from 'smu-ui-components/GenericEmptyState';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import Image from 'smu-ui-components/Image';
import Paper from 'smu-ui-components/Paper';
import PropTypes from 'prop-types';
import Typography from 'smu-ui-components/Typography';
import cn from 'classnames';

// @app
import SkeletonCongratulationsUsers from 'components/Skeletons/Congratulations/Users';

// @own
import './styles.scss';
import Users from './Users';
import Chip from './Chip';

function Congratulations({
  className,
  datepickerProps,
  emptyState,
  isError,
  isLoading,
  messageHeaderSubtitle,
  messageHeaderTitle,
  messagePlaceholder,
  messageViewCard,
  messageYouCongratulated,
  onClickUser,
  onComment,
  onRetry,
  onViewCard,
  referredYear,
  showInteractions,
  sourceType,
  tabs,
  users,
}) {
  const showLoader = isLoading;
  const showError = isError && !isLoading;
  const showEmptyState = !showError && !showLoader && !users?.length;
  const showUsers = !showError && !showLoader && !!users?.length;

  return (
    <div className={cn('smu-congratulations space-y-4', className)}>
      <Paper className="px-5 py-2 flex items-center justify-between" shadow>
        <div className="flex flex-col space-y-1 pr-2">
          <Typography className="text-blue font-medium text-xl font-rubik">
            {messageHeaderTitle}
          </Typography>
          <Typography className="text-blue font-openSans text-sm max-w-md block min-h-[60px]">
            {messageHeaderSubtitle}
          </Typography>
        </div>
        <Image
          className="flex-none"
          height={86}
          legacy={false}
          src="/assets/img/celebrations-header.svg"
          width={88}
        />
      </Paper>
      <div className="flex items-center w-full relative z-10">
        {tabs?.length > 1 && (
          <div className="flex space-x-2">
            {tabs.map((tab) => (
              <Chip {...tab} />
            ))}
          </div>
        )}
        <Datepicker
          className="ml-auto border"
          size="medium"
          {...datepickerProps}
        />
      </div>
      <Paper className="w-full flex flex-col" shadow>
        {showLoader && (
          <SkeletonCongratulationsUsers className="w-full" totalUsers={8} />
        )}
        {showUsers && (
          <Users
            messagePlaceholder={messagePlaceholder}
            messageViewCard={messageViewCard}
            messageYouCongratulated={messageYouCongratulated}
            onClickUser={onClickUser}
            onComment={onComment}
            onViewCard={onViewCard}
            referredYear={referredYear}
            showInteractions={showInteractions}
            sourceType={sourceType}
            users={users}
          />
        )}
        {(showEmptyState || showError) && (
          <div className="py-10">
            {showEmptyState && (
              <GenericEmptyState
                description={emptyState?.description}
                image={emptyState?.image}
                title={emptyState?.title}
              />
            )}
            {showError && (
              <GenericErrorState
                action={{
                  onClick: onRetry,
                }}
              />
            )}
          </div>
        )}
      </Paper>
    </div>
  );
}

Congratulations.propTypes = {
  className: PropTypes.string,
  datepickerProps: PropTypes.object,
  emptyState: PropTypes.object,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  messageHeaderSubtitle: PropTypes.string,
  messageHeaderTitle: PropTypes.string,
  messagePlaceholder: PropTypes.string,
  messageViewCard: PropTypes.string,
  messageYouCongratulated: PropTypes.string,
  onClickUser: PropTypes.func,
  onComment: PropTypes.func,
  onRetry: PropTypes.func,
  onViewCard: PropTypes.func,
  referredYear: PropTypes.number,
  showInteractions: PropTypes.bool,
  sourceType: PropTypes.string,
  tabs: PropTypes.array,
  users: PropTypes.array,
};

Congratulations.defaultProps = {
  onClickUser: () => {},
  onComment: () => {},
  onRetry: () => {},
  onViewCard: () => {},
  tabs: [],
  users: [],
};

export default Congratulations;
