import { defineMessages } from 'react-intl';

export default defineMessages({
  insightTip: {
    id: 'Insights.Tip',
    defaultMessage: 'Was this information useful to you?',
  },
  insightTipYes: {
    id: 'Insights.TipYes',
    defaultMessage: 'Yes',
  },
  insightTipNo: {
    id: 'Insights.TipNo',
    defaultMessage: 'No',
  },
  insightTipThankYou: {
    id: 'Insights.TipThankYou',
    defaultMessage: 'Thank you for helping us!',
  },
  insightActionsTitle: {
    id: 'Insights.Actions.Title',
    defaultMessage: 'Suggested actions',
  },
  insightBack: {
    id: 'Insights.Back',
    defaultMessage: 'Back to Insights',
  },
  interations: {
    id: 'Insights.Interactions',
    defaultMessage: 'Interactions',
  },
  interacionsInLastMonths: {
    id: 'Insights.InteracionsInLastMonths',
    defaultMessage: 'Your interactions in these 3 months.',
  },
  YouAndYourColleagues: {
    id: 'Insights.YouAndYourColleagues',
    defaultMessage: 'You & your colleagues',
  },
  YourTeam: {
    id: 'Insights.YourTeam',
    defaultMessage: 'Your team',
  },
  ViewAll: {
    id: 'Insights.ViewAll',
    defaultMessage: 'View all',
  },
  MissSomebody: {
    id: 'Insights.UserGrid.MissSomebody',
    defaultMessage: 'Recognize someone else',
  },
  ViewMore: {
    id: 'Insights.ViewMore',
    defaultMessage: 'View more.',
  },
  mainUser: {
    id: 'Insights.mainUser',
    defaultMessage: 'Me',
  },
  AverageCommunity: {
    id: 'Insights.AverageCommunity',
    defaultMessage: 'Average community interactions',
  },
  recommended: {
    id: 'Insights.Recommended',
    defaultMessage: 'Target',
  },
  CollaboratorsWithActivity: {
    id: 'Insights.CollaboratorsWithActivity',
    defaultMessage: 'Collaborators with cultural activity',
  },
  CollaboratorsWithoutActivity: {
    id: 'Insights.CollaboratorsWithoutActivity',
    defaultMessage: 'Collaborators with low cultural activity',
  },
  CollaboratorsWithFeedback: {
    id: 'Insights.CollaboratorsWithFeedback',
    defaultMessage: 'Collaborators with your feedback',
  },
  CollaboratorsWithoutFeedback: {
    id: 'Insights.CollaboratorsWithoutFeedback',
    defaultMessage: 'Collaborators without your feedback',
  },
  CollaboratorsToGiveFeedback: {
    id: 'os.workflows.leaderFeedbackNot_received_for_your_led.title',
    defaultMessage: 'Collaborators to give feedback',
  },
  ManagersAverage: {
    id: 'os.workflows.ManagersAverage',
    defaultMessage: 'Average in other {area}s',
  },
  'leader.activities.cultural_low_your_led.title': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.title',
    defaultMessage: 'Cultural activity of your led',
  },
  'leader.activities.cultural_low_your_led.description': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.description',
    defaultMessage: 'Teams with a high level of interaction are more productive, more engaged, and show less interest in leaving the organization. All this helps your team feel more valued.',
  },
  'leader.activities.cultural_low_your_led.indicator': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.indicator',
    defaultMessage: 'of the collaborators you lead had cultural activity (interactions) in the last 3 months',
  },
  'leader.feedback.not_received_for_your_led.title': {
    id: 'os.workflows.leaderFeedbackNot_received_for_your_led.title',
    defaultMessage: 'Collaborators to give feedback',
  },
  'leader.feedback.not_received_for_your_led.description': {
    id: 'os.workflows.leaderFeedbackNot_received_for_your_led.description',
    defaultMessage: 'Collaborators who receive feedback more often tend to perform better. Also, they are more committed to the organizational culture. Encouraging feedback between team members is key to your led´s growth.',
  },
  'leader.feedback.not_received_for_your_led.indicator': {
    id: 'os.workflows.leaderNot_received_for_your_led.indicator',
    defaultMessage: 'collaborators you lead did not receive feedback from you in the last 3 months',
  },
  'leader.feedback.not_sent_to_your_led.title': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.title',
    defaultMessage: 'Feedback not sent to your led',
  },
  'leader.feedback.not_sent_to_your_led.description': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.description',
    defaultMessage: 'Leaders in the organization usually give feedback to their led, on average, every 5 days. Did you know that people who receive more feedback tend to have better performance evaluations?',
  },
  'leader.feedback.not_sent_to_your_led.indicator': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.indicator',
    defaultMessage: 'collaborators you lead did not receive any feedback in the last 3 months',
  },
  'managers.no.feedback.title': {
    id: 'os.workflows.managersNoFeedback.title',
    defaultMessage: 'Feedback received by members in your {area}',
  },
  'managers.no.feedback.description': {
    id: 'os.workflows.managersNoFeedback.description',
    defaultMessage: 'Collaborators who receive feedback more often tend to perform better. Also, they are more committed to the organizational culture.',
  },
  'managers.no.feedback.indicator': {
    id: 'os.workflows.managersNoFeedback.indicator',
    defaultMessage: 'Members in {name} have received any feedback in the last 3 months',
  },
  'no.active.new.hires.title': {
    id: 'os.workflows.noActiveNewHires.title',
    defaultMessage: 'New hires with cultural activity in your {area}',
  },
  'no.active.new.hires.description': {
    id: 'os.workflows.noActiveNewHires.description',
    defaultMessage: 'Teams with a high level of interaction are more productive, more engaged, and show less interest in leaving the organization.',
  },
  'no.active.new.hires.indicator': {
    id: 'os.workflows.noActiveNewHires.indicator',
    defaultMessage: 'of the new employees in {name} showed cultural activity in the last 3 months',
  },
  'user.interactions.title': {
    id: 'os.workflows.userInteractions.title',
    defaultMessage: 'Cultural influencers',
  },
  'user.interactions.description': {
    id: 'os.workflows.userInteractions.description',
    defaultMessage: `The more you interact with other people, the more connections you generate. And your impact on your organization will be greater.
     Did you know that cultural influencers like you interact at least 6 more times with their colleagues than the average?`,
  },
  'user.interactions.indicator': {
    id: 'os.workflows.userInteractions.indicator',
    defaultMessage: 'Interactions with colleagues in the last 3 months',
  },
  'area.turnover.rate.title': {
    id: 'os.workflows.areaTurnoverRate.title',
    defaultMessage: 'Turnover rate in your {area}',
  },
  'area.turnover.rate.description': {
    id: 'os.workflows.areaTurnoverRate.description',
    defaultMessage: 'Turnover percentage considers the last 12 months from the current month.',
  },
  'area.turnover.rate.indicator': {
    id: 'os.workflows.areaTurnoverRate.indicator',
    defaultMessage: 'Was the turnover rate of {name} you lead in the last 12 months',
  },
  'user.cultural.activity.title': {
    id: 'os.workflows.userCulturalActivity.title',
    defaultMessage: 'Your colleagues in the community',
  },
  'user.cultural.activity.description': {
    id: 'os.workflows.userCulturalActivity.description',
    defaultMessage: 'The more you interact with other people, the more connections you will generate. And your impact on your community will be greater.',
  },
  'user.cultural.activity.indicator': {
    id: 'os.workflows.userCulturalActivity.indicator',
    defaultMessage: 'is your position according to your cultural activity for ',
  },
  'leader.activities.cultural_low_your_led.action.send.star': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.actionSend.star',
    defaultMessage: 'Recognize a colleague',
  },
  'leader.activities.cultural_low_your_led.action.send.feedback': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.actionSend.feedback',
    defaultMessage: 'Send feedback',
  },
  'leader.activities.cultural_low_your_led.action.create.post': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.actionCreate.post',
    defaultMessage: 'Post a photo or video',
  },
  'leader.feedback.not_received_for_your_led.action.send.feedback': {
    id: 'os.workflows.leaderFeedbackNot_received_for_your_led.actionSend.feedback',
    defaultMessage: 'Send feedback',
  },
  'leader.feedback.not_sent_to_your_led.action.send.feedback': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.actionSend.feedback',
    defaultMessage: 'Recognize a colleague',
  },
  'managers.no.feedback.action.send.feedback': {
    id: 'os.workflows.managersNoFeedback.actionSend.feedback',
    defaultMessage: 'Send feedback',
  },
  'no.active.new.hires.action.send.star': {
    id: 'os.workflows.noActiveNewHires.actionSend.star',
    defaultMessage: 'Recognize a colleague',
  },
  'no.active.new.hires.action.send.feedback': {
    id: 'os.workflows.noActiveNewHires.actionSend.feedback',
    defaultMessage: 'Send feedback',
  },
  'user.interactions.action.send.star': {
    id: 'os.workflows.userInteractions.actionSend.star',
    defaultMessage: 'Recognize a colleague',
  },
  'user.interactions.action.send.feedback': {
    id: 'os.workflows.userInteractions.actionSend.feedback',
    defaultMessage: 'Send feedback',
  },
  'user.interactions.action.meet.colleagues.with.influence': {
    id: 'os.workflows.userInteractions.actionMeetColleaguesWithInfluence',
    defaultMessage: 'Meet your influential colleagues',
  },
  'user.interactions.action.create.post': {
    id: 'os.workflows.userInteractions.actionCreate.post',
    defaultMessage: 'Post a photo or video',
  },
  'area.turnover.rate.action.send.feedback': {
    id: 'os.workflows.areaTurnoverRate.actionSend.feedback',
    defaultMessage: 'Send feedback',
  },
  'area.turnover.rate.action.send.star': {
    id: 'os.workflows.areaTurnoverRate.actionSend.star',
    defaultMessage: 'Recognize a colleague',
  },
  'area.turnover.rate.action.create.post': {
    id: 'os.workflows.areaTurnoverRate.actionSend.post',
    defaultMessage: 'Post a photo or video',
  },
  'user.cultural.activity.action.send.star': {
    id: 'os.workflows.userCulturalActivity.actionSend.star',
    defaultMessage: 'Recognize a colleague',
  },
  'user.cultural.activity.action.meet.leaders.with.influence': {
    id: 'os.workflows.userCulturalActivity.actionMeetColleaguesWithInfluence',
    defaultMessage: 'Meet your influential colleagues',
  },
  'user.cultural.activity.action.leaderboard': {
    id: 'os.workflows.userCulturalActivity.actionLeaderboard',
    defaultMessage: 'See your community leaderboard',
  },
  'user.cultural.activity.action.create.post': {
    id: 'os.workflows.userCulturalActivity.actionCreate.post',
    defaultMessage: 'Post a photo or video',
  },
  'leader.activities.cultural_low_your_led.graph.leader_metric': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.graphLeader_metric',
    defaultMessage: 'Your team',
  },
  'leader.activities.cultural_low_your_led.graph.organization_metric': {
    id: 'os.workflows.leaderActivitiesCultural_low_your_led.graphOrganization_metric',
    defaultMessage: 'Organization average',
  },
  'leader.feedback.not_received_for_your_led.graph.leader_metric': {
    id: 'os.workflows.leaderFeedbackNot_received_for_your_led.graphLeader_metric',
    defaultMessage: 'Your team',
  },
  'leader.feedback.not_received_for_your_led.graph.organization_metric': {
    id: 'os.workflows.leaderFeedbackNot_received_for_your_led.graphOrganization_metric',
    defaultMessage: 'Organization average',
  },
  'leader.feedback.not_sent_to_your_led.graph.leader_metric': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.graphLeader_metric',
    defaultMessage: 'Your team',
  },
  'leader.feedback.not_sent_to_your_led.graph.organization_metric': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.graphOrganization_metric',
    defaultMessage: 'Organization average',
  },
  'managers.no.feedback.graph.area_metric': {
    id: 'os.workflows.managersNoFeedback.graphLeader_metric',
    defaultMessage: 'Your team',
  },
  'managers.no.feedback.graph.organization_metric': {
    id: 'os.workflows.managersNoFeedback.graphOrganization_metric',
    defaultMessage: 'Organization average',
  },
  'no.active.new.hires.graph.area_metric': {
    id: 'os.workflows.noActiveNewHires.graphLeader_metric',
    defaultMessage: 'Your team',
  },
  'no.active.new.hires.graph.organization_metric': {
    id: 'os.workflows.noActiveNewHires.graphOrganization_metric',
    defaultMessage: 'Organization average',
  },
  'area.turnover.rate.graph.area_metric': {
    id: 'os.workflows.areaTurnoverRate.graphLeader_metric',
    defaultMessage: 'Your team',
  },
  'area.turnover.rate.graph.other_areas_metric': {
    id: 'os.workflows.areaTurnoverRate.graphOther_areas_metric',
    defaultMessage: 'Organization average',
  },
  'user.interactions.insight.collaborators.interactions.rising': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRising',
    defaultMessage: 'You are Rising!',
  },
  'user.interactions.insight.collaborators.interactions.rocking': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRocking',
    defaultMessage: 'You are Shining!',
  },
  'user.interactions.insight.collaborators.interactions.shining': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsShining',
    defaultMessage: 'You are Legendary!',
  },
  'user.interactions.insight.collaborators.interactions.rising.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRisingDescription',
    defaultMessage: 'You have the potential to make a big impact in your organization. Seize it!',
  },
  'user.interactions.insight.collaborators.interactions.rocking.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRockingDescription',
    defaultMessage: 'You are generating a visible change in the lives of your colleagues.',
  },
  'user.interactions.insight.collaborators.interactions.shining.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsShiningDescription',
    defaultMessage: 'You have a positive impact on your organization and are on your way to making even more change.',
  },
  'user.interactions.insight.collaborators.interactions.same.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.SameDescription',
    defaultMessage: 'You stay at the same level about the previous period. Increase your interactions and keep growing.',
  },
  'user.interactions.insight.collaborators.interactions.up.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.UpDescription',
    defaultMessage: 'You level up compared to the previous period. Keep it up!',
  },
  'user.interactions.insight.collaborators.interactions.down.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.DownDescription',
    defaultMessage: 'You lowered your level compared to the previous period. Anyway, you are doing very well. You are still a cultural influencer! Pick up your interactions and keep growing.',
  },
  'user.cultural.activity.insight.collaborators.interactions.rising': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRising',
    defaultMessage: 'You are Rising!',
  },
  'user.cultural.activity.insight.collaborators.interactions.rocking': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRocking',
    defaultMessage: 'You are Legendary!',
  },
  'user.cultural.activity.insight.collaborators.interactions.shining': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsShining',
    defaultMessage: 'You are Shining!',
  },
  'user.cultural.activity.insight.collaborators.interactions.rising.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRisingDescription',
    defaultMessage: 'You have the potential to make a big impact in your community. Seize it!',
  },
  'user.cultural.activity.insight.collaborators.interactions.rocking.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsRockingDescription',
    defaultMessage: 'You have a positive impact on your community and are on your way to making even more change.',
  },
  'user.cultural.activity.insight.collaborators.interactions.shining.description': {
    id: 'os.workflows.userInteractionsInsightCollaborators.interactionsShiningDescription',
    defaultMessage: 'You are generating a visible change in the lives of your colleagues.',
  },
  'leader.feedback.not_sent_to_your_led.action.team.feedback.behalf': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.action.feedbackBehalf',
    defaultMessage: 'Recognize your team',
  },
  'leader.feedback.not_received_for_your_led.action.team.dashboard': {
    id: 'os.workflows.leaderFeedbackNot_received_to_your_led.action.feedbackBehalf',
    defaultMessage: 'My teams feedback',
  },
  'leader.feedback.not_received_for_your_led.action.team.feedback.behalf': {
    id: 'os.workflows.leaderFeedbackNot_received_to_your_led.action.feedbackBehalf',
    defaultMessage: 'Recognize your team',
  },
  'leader.feedback.not_sent_to_your_led.action.team.feedback': {
    id: 'os.workflows.leaderFeedbackNot_sent_to_your_led.action.feedbackBehalf',
    defaultMessage: 'Recognize your team',
  },
  'user.cultural.activity.action.meet.colleagues.with.influence': {
    id: 'os.workflows.user_activity.action.meetColleagues',
    defaultMessage: 'View your activity',
  },
  'user.cultural.valueofthemonth.title': {
    id: 'os.workflows.userCulturalValueOfTheMonth.title',
    defaultMessage: 'Who represent this value?',
  },
  'user.cultural.valueofthemonth.description': {
    firstPart: {
      id: 'os.workflows.userCulturalValueOfTheMonth.description.firstPart',
      defaultMessage: 'What is <b>{organizationValueName}</b> value about?',
    },
    secondPart: {
      id: 'os.workflows.userCulturalValueOfTheMonth.description.secondPart',
      defaultMessage: 'Recognize your colleagues by sending them a star.',
    },
  },
});
