import { defineMessages } from 'react-intl';

export default defineMessages({
  QuestionnaireStoriesTitle: {
    id: 'Questionnaire.Title.Stories',
    defaultMessage: 'Questionnaires',
  },
  QuestionnaireButtonClose: {
    id: 'Questionnaire.Button.Close',
    defaultMessage: 'Close',
  },
  QuestionnaireButtonNext: {
    id: 'Questionnaire.Button.Next',
    defaultMessage: 'Next',
  },
  QuestionnaireButtonSkip: {
    id: 'Questionnaire.Button.Skip',
    defaultMessage: 'Skip',
  },
  QuestionnaireButtonStart: {
    id: 'Questionnaire.Button.Start',
    defaultMessage: 'Start',
  },
  QuestionnaireButtonRetry: {
    id: 'Questionnaire.Button.Retry',
    defaultMessage: 'Retry',
  },
  QuestionnaireTitleDemographics: {
    id: 'Questionnaire.Title.Demographics',
    defaultMessage: 'Attention please!',
  },
  QuestionnaireTitleInfoDemographics: {
    id: 'Questionnaire.Title.InfoDemographics',
    defaultMessage: 'You are about to enter incognito mode',
  },
  QuestionnaireSubtitleInfoDemographics: {
    id: 'Questionnaire.Subtitle.InfoDemographics',
    defaultMessage: 'It is important that you know your data is completely anonymous.',
  },
  QuestionnaireHeaderInfoDemographics: {
    id: 'Questionnaire.Header.InfoDemographics',
    defaultMessage: 'To continue please provide the following information:',
  },
  QuestionnaireSubtitleComments: {
    id: 'Questionnaire.Subtitle.Comments',
    defaultMessage: 'Leave here your comments and/suggestions',
  },
  QuestionnairePlaceholderComments: {
    id: 'Questionnaire.Placeholder.Comments',
    defaultMessage: 'Leave your comment here..',
  },
  QuestionnaireTitleCompleted: {
    id: 'Questionnaire.Title.Completed',
    defaultMessage: 'Thank you!!',
  },
  QuestionnaireSubtitleCompleted: {
    id: 'Questionnaire.Subtitle.Completed',
    defaultMessage: 'You have completed the survey.',
  },
  QuestionnaireInfoCompleted: {
    id: 'Questionnaire.Info.Completed',
    defaultMessage: 'We are grateful for your participation.',
  },
  QuestionnaireMessageError: {
    id: 'Questionnaire.Message.Error',
    defaultMessage: 'Something went wrong. Please check your internet connection and press “Retry.”',
  },
  ErrorQuestionnariesNotFound: {
    id: 'Error.QuestionnariesNotFound',
    defaultMessage: "We're sorry! The survey you are looking for is no longer available",
  },
  ErrorQuestionnariesNoRights: {
    id: 'Error.QuestionnariesNoRights',
    defaultMessage: 'It seems that you do not have access to see this survey',
  },
});
