import {
  CLEAN_FEED,
  FEED_SET_FIELDS,
  FEED_SET_OPTIONS,
  MODERATE_COMMENT_SUCCESS,
  REMOVE_LIKE,
  REMOVE_LIKE_FAIL,
  REMOVE_LIKE_SUCCESS,
  REQUEST_FEED,
  REQUEST_FEED_FAILED,
  REQUEST_FEED_NEXT_PAGE,
  REQUEST_FEED_NEXT_PAGE_SUCCESS,
  REQUEST_FEED_RESET_PAGE,
  REQUEST_FEED_SUCCESS,
  SEND_COMMENT,
  SEND_COMMENT_SUCCESS,
  SEND_IMAGE,
  SEND_IMAGE_FAIL,
  SEND_IMAGE_SUCCESS,
  SEND_LIKE,
  SEND_LIKE_FAIL,
  SEND_LIKE_SUCCESS,
  SHOW_SEND_STAR_FLOW,
  ADD_ITEM_TO_FEED,
  TOGGLE_SEND_STAR_WIDGET,
  SEND_LIFT_STAR_SUCCESS,
} from './actionTypes';

const initialState = {
  results: [],
  fetching: true,
  error: '',
  fields: null,
  hasMore: false,
  options: {
    filterBy: 'RECEIVED',
    page: 0,
    size: 20,
  },
  showSendStarValues: false,
  showSendStarWidget: false,
  starForMyself: false,
};

function commnetReducer(state, { type, payload }) {
  switch (type) {
    case MODERATE_COMMENT_SUCCESS:
      return {
        ...state,
        enabled: payload.enabled,
      };
    default:
      return state;
  }
}

function removeLike(state, payload) {
  const commentId = payload?.likeId;
  const comments = state?.comments?.filter(c => c?.id !== commentId);
  return { comments };
}

function addLike(state, payload) {
  const comments = state?.comments;
  comments.push(payload?.result);
  return { comments };
}

function starReducer(state, { type, payload }) {
  switch (type) {
    case SEND_COMMENT:
      return { ...state, loadingComment: true };
    case SEND_COMMENT_SUCCESS:
      return {
        ...state,
        loadingComment: false,
        comments: [payload.result].concat(...state.comments),
      };
    case MODERATE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: state.comments.map(comment => (
          (comment.id === payload.id) ? commnetReducer(comment, { type, payload }) : comment
        )),
      };
    case SEND_LIKE:
      return {
        ...state,
        sendingLike: true,
      };
    case SEND_LIKE_SUCCESS:
      return {
        ...state,
        ...addLike(state, payload),
        sendingLike: false,
      };
    case SEND_LIKE_FAIL:
      return {
        ...state,
        sendingLike: false,
      };
    case REMOVE_LIKE:
      return {
        ...state,
        sendingLike: true,
      };
    case REMOVE_LIKE_SUCCESS:
      return {
        ...state,
        ...removeLike(state, payload),
        sendingLike: false,
      };
    case REMOVE_LIKE_FAIL:
      return {
        ...state,
        sendingLike: false,
      };
    case SEND_LIFT_STAR_SUCCESS:
      return {
        ...state,
        liftState: state.liftState + 1,
        comments: [payload.result].concat(...state.comments),
        usersLift: [...state.usersLift, payload.result.user],
        quantityUsersLift: state.quantityUsersLift + 1,
      };
    case SEND_IMAGE:
      return {
        ...state,
        sendingImage: true,
      };
    case SEND_IMAGE_SUCCESS:
      return {
        ...state,
        sendingImage: false,
        comments: [payload.comment].concat(...state.comments),
      };
    case SEND_IMAGE_FAIL:
      return {
        ...state,
        sendingImage: false,
      };
    default:
      return state;
  }
}

const requestPage = (state, getNextPage) => ({
  ...state,
  fetching: true,
  error: '',
  options: {
    ...state.options,
    page: getNextPage(state.options.page),
  },
});

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_FEED:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case REQUEST_FEED_SUCCESS:
      if (payload.length > 0) {
        return {
          ...state,
          error: '',
          fetching: false,
          hasMore: payload?.length >= state?.options?.size,
          options: {
            ...state.options,
            firstStarId: payload[0].baseStarId || payload[0].id,
          },
          results: payload,
          showSendStarWidget: payload.showSendStarWidget,
          starForMyself: payload.starForMyself,
        };
      }
      return {
        ...state,
        fetching: false,
        hasMore: false,
        results: payload,
      };
    case REQUEST_FEED_FAILED:
      return {
        ...state,
        fetching: false,
        error: payload,
      };
    case CLEAN_FEED:
      return initialState;
    case REQUEST_FEED_RESET_PAGE:
      return {
        ...state,
        fetching: true,
        error: '',
        options: {
          ...initialState.options,
          page: 0,
        },
      };
    case REQUEST_FEED_NEXT_PAGE:
      return requestPage(state, page => (page ? page + 1 : 1));
    case REQUEST_FEED_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        error: '',
        fetching: false,
        hasMore: payload?.length >= state?.options?.size,
        results: state.results.concat(payload),
      };
    case FEED_SET_OPTIONS:
      return {
        ...state,
        options: {
          ...initialState.options,
          ...payload,
        },
      };
    case FEED_SET_FIELDS:
      return {
        ...state,
        fields: { ...(payload.fields || {}) },
        options: {
          ...initialState.options,
          ...(payload.options || state.options),
        },
        showSendStarWidget: { ...(payload.showSendStarWidget || state.showSendStarWidget) },
      };
    case SEND_COMMENT:
    case SEND_COMMENT_SUCCESS:
    case SEND_LIKE:
    case SEND_LIKE_SUCCESS:
    case SEND_LIKE_FAIL:
    case REMOVE_LIKE:
    case REMOVE_LIKE_SUCCESS:
    case REMOVE_LIKE_FAIL:
    case SEND_IMAGE:
    case SEND_IMAGE_SUCCESS:
    case SEND_IMAGE_FAIL:
    case SEND_LIFT_STAR_SUCCESS:
      return {
        ...state,
        results: state.results.map(star => (
          (star.id === payload.id) ? starReducer(star, { type, payload }) : star
        )),
      };
    case MODERATE_COMMENT_SUCCESS:
      return {
        ...state,
        results: state.results.map(star => (
          (star.id === payload.starId) ? starReducer(star, { type, payload }) : star
        )),
      };
    case SHOW_SEND_STAR_FLOW:
      return {
        ...state,
        showSendStarValues: true,
      };
    case ADD_ITEM_TO_FEED:
      return {
        ...state,
        results: [payload, ...state.results],
      };
    case TOGGLE_SEND_STAR_WIDGET:
      return {
        ...state,
        showSendStarWidget: payload,
      };
    default:
      return state;
  }
}
