// @packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';
import cn from 'classnames';

// @own
import './styles.scss';

const CardVideo = React.forwardRef(
  (
    {
      aspectRatio,
      className,
      maxAspectRatio,
      onClick,
      playerProps,
      showMedia,
      showPlayIcon,
      style,
      thumbnailBlurUrl,
      ...rest
    },
    ref,
  ) => {
    const styles = useMemo(() => {
      const styles = { ...style };
      if (aspectRatio) {
        styles.paddingTop = `${
          aspectRatio > maxAspectRatio ? maxAspectRatio : aspectRatio
        }%`;
      }
      return styles;
    }, [style, aspectRatio]);

    return (
      <div
        className={cn('smu-card-video', className, {
          'smu-card-video--pointer': onClick,
        })}
        onClick={onClick}
        ref={ref}
        style={styles}
        {...rest}
      >
        {showPlayIcon && (
          <img
            alt="play video"
            className="smu-card-video__play"
            src="/assets/img/play_video.svg"
          />
        )}
        {thumbnailBlurUrl && (
          <div
            className="smu-card-video__blur"
            style={{ backgroundImage: `url("${thumbnailBlurUrl}")` }}
          />
        )}
        {showMedia && (
          <ReactPlayer
            className="smu-card-video__player"
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            height="100%"
            muted
            type="video/mp4"
            width="100%"
            {...playerProps}
          />
        )}
      </div>
    );
  },
);

CardVideo.defaultProps = {
  aspectRatio: 0,
  maxAspectRatio: 0,
  showPlayIcon: false,
};

CardVideo.propTypes = {
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
  maxAspectRatio: PropTypes.number,
  onClick: PropTypes.func,
  playerProps: PropTypes.object,
  showMedia: PropTypes.bool,
  showPlayIcon: PropTypes.bool,
  style: PropTypes.object,
  thumbnailBlurUrl: PropTypes.string,
};

export default CardVideo;
