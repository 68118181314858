// @packages
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import usePrevious from 'smu-custom-hooks/usePrevious';
import { add as addToastMessage } from 'smu-app-components/ToastNotifications/actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'utils/gtm';
import { trackEventSendStarFlowOpen } from 'smu-app-components/SendStar/analytics';
import { withRouter } from 'react-router';

// @app
import CoreValuesPanelComponent from 'components/CoreValuesPanel';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';

// @own
import messages from './messages';
import { CONTROL_RIGHT, CORE_VALUE_BOX_SIZE, MESSAGE_TIMEOUT } from './constants';

function CoreValuesPanel({
  addToastMessage,
  entities,
  intl: { formatMessage },
  remainingStars,
  router,
  stars,
  suggestSendStar,
}) {
  const panelRef = useRef();
  const prevPanelRef = usePrevious(panelRef);
  const [controlsOptions, setControlsOptions] = useState({
    isScrollable: false,
    left: false,
    right: false,
  });

  function getControlsOptions() {
    const isScrollable = panelRef?.current?.scrollWidth > panelRef?.current?.clientWidth;
    return ({
      isScrollable,
      left: isScrollable && panelRef?.current?.scrollLeft !== 0,
      right: isScrollable && panelRef?.current?.scrollLeft <= panelRef?.current?.clientWidth,
    });
  }

  function onClick(star) {
    if (suggestSendStar) {
      trackEvent({
        category: 'Interactions',
        action: 'Click on Send Star in Star Flow',
        label: `Star: ${star.name}`,
      });
    }

    trackEventSendStarFlowOpen({
      int_type: 'values_bar',
      label: 'give_a_star',
      selectedStarName: star.name,
    });

    router.push(buildSendStarModalUrl({
      params: {
        coreValueId: star.id,
        users: entities,
      },
    }));
  }

  function onControlClick(direction) {
    if (panelRef?.current) {
      if (direction === CONTROL_RIGHT) {
        const futurePosition = panelRef?.current?.scrollLeft + CORE_VALUE_BOX_SIZE;
        if (controlsOptions?.isScrollable
          && panelRef?.current?.scrollWidth > (panelRef?.current?.clientWidth + futurePosition)
        ) {
          setControlsOptions({
            ...controlsOptions,
            left: true,
            right: true,
          });
        } else {
          setControlsOptions({
            ...controlsOptions,
            left: true,
            right: false,
          });
        }
        panelRef.current.scrollLeft = futurePosition;
      } else {
        const futurePosition = panelRef?.current?.scrollLeft - CORE_VALUE_BOX_SIZE;
        if (controlsOptions?.isScrollable && futurePosition <= 0) {
          setControlsOptions({
            ...controlsOptions,
            left: false,
            right: true,
          });
        } else {
          setControlsOptions({
            ...controlsOptions,
            left: true,
            right: true,
          });
        }
        panelRef.current.scrollLeft = futurePosition;
      }
    }
  }

  function onDisabledClick(isKudos) {
    addToastMessage({
      message: formatMessage(isKudos
        ? messages.noKudosAvailable
        : messages.noStarAvailable),
      timeout: MESSAGE_TIMEOUT,
    });
  }

  useEffect(() => {
    setControlsOptions(getControlsOptions());
  }, [panelRef?.current?.clientWidth]);

  useEffect(() => {
    if (!prevPanelRef && panelRef) {
      setControlsOptions(getControlsOptions());
    }
  }, [panelRef]);


  return (
    <CoreValuesPanelComponent
      isScrollable={controlsOptions?.isScrollable}
      onClick={onClick}
      onControlClick={onControlClick}
      onDisabledClick={onDisabledClick}
      ref={panelRef}
      remainingStars={remainingStars}
      showLeftControl={controlsOptions?.left}
      showRightControl={controlsOptions?.right}
      stars={stars}
    />
  );
}

CoreValuesPanel.propTypes = {
  addToastMessage: PropTypes.func.isRequired,
  className: PropTypes.string,
  entities: PropTypes.array.isRequired,
  intl: PropTypes.object,
  remainingStars: PropTypes.object,
  router: PropTypes.object,
  setInitial: PropTypes.func.isRequired,
  stars: PropTypes.array.isRequired,
  suggestSendStar: PropTypes.bool,
};

const actions = {
  addToastMessage,
};

const mapStateToProps = (state) => {
  const { starFlow, session } = state;
  return {
    entities: starFlow?.entities,
    remainingStars: starFlow?.remainingStars,
    stars: session?.stars,
    suggestSendStar: starFlow?.suggestSendStar,
  };
};

export default connect(mapStateToProps, actions)(withRouter(injectIntl(CoreValuesPanel)));
