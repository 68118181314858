// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import Avatar from 'components/Avatar';
import AvatarInfo from 'components/AvatarInfo';
import AvatarMedia from 'components/AvatarMedia';
import Modal from 'components/Modal';

// @own
import './styles.scss';

const ModalTagguedUsers = ({
  children,
  className,
  description,
  onClose,
  title,
  users,
  ...rest
}) => (
  <Modal
    className={cn('smu-modal-taggued-users', className)}
    onClick={onClose}
    {...rest}
  >
    <div className="smu-modal-taggued-users__content">
      <Button
        className="smu-modal-taggued-users__close"
        onClick={onClose}
        variant="text"
      >
        <Icon className="smu-modal-taggued-users__close-icon" icon="close" />
      </Button>
      <header className="smu-modal-taggued-users__header">
        {title && <h4 className="smu-modal-taggued-users__title">{title}</h4>}
        {description && (
          <label className="smu-modal-taggued-users__description">
            {description}
          </label>
        )}
      </header>
      <div className="smu-modal-taggued-users__users">
        {users?.map((user, index) => (
          <div
            className="smu-modal-taggued-users__user"
            key={`${user?.id}-${index}`}
          >
            <AvatarMedia
              avatar={<Avatar href={user?.href} src={user?.src} />}
              info={
                <AvatarInfo
                  description={user?.description}
                  href={user?.href}
                  title={user?.title}
                />
              }
            />
          </div>
        ))}
      </div>
    </div>
  </Modal>
);

ModalTagguedUsers.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  className: PropTypes.string,
  onClose: PropTypes.func,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      src: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

export default ModalTagguedUsers;
