// @packages
import React from 'react';
import PropTypes from 'prop-types';

// @own
import './styles.scss';

function Card({ children }) {
  return (
    <div className="image-event-card">
      {children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
