// @packages
import map from 'lodash/map';
import pick from 'lodash/pick';
import { getToken } from 'smu-utils/storage';
import { stringify } from 'querystring';

// @app
import TextSanitize from 'utils/inputSanitize';
import escapeHtml from 'utils/escapeHtml';
import { addParams, formData, times, vo } from 'api';
import { apiInstance, apiInstanceNonWrapped } from 'utils/requestHelper';
import { emailRegex } from 'utils/constants';
import { errorHandler } from 'utils/errorHandler';
import { removeMentionedNameFromComment } from 'utils/mentions';

// @own
import { HASHTAG } from './constants';

function buildFullName(user) {
  return user?.name || `${user?.firstName} ${user?.lastName}`;
}

function buildHashtag(hashtag) {
  return `#${hashtag}`;
}

export function assignImage({ imageCode, starId }) {
  return apiInstance({
    url: `/v2/stellar/stars/${starId}/main/image/${imageCode}`,
    ...formData,
    data: stringify({
      ...vo,
      ...times(),
    }),
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function editStar({ comment, editId, imageCode }) {
  const parameters = {
    starId: editId,
    notes: removeMentionedNameFromComment(comment),
    imageCode,
  };
  return apiInstance({
    url: '/v2/stellar/editstar',
    ...formData,
    data: stringify({
      ...vo,
      ...times(),
      ...parameters,
    }),
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function liftStar(starId) {
  return apiInstance({
    url: addParams(`/v2/stellar/star/${starId}/lift`),
    method: 'get',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function getRemainingStars() {
  return apiInstance({
    url: '/v2/stellar/user/remaining/stars',
  })
    .then(({ data }) => data.result)
    .catch(errorHandler);
}

export function searchEntities(allowMultipleUsers, filterType, query) {
  const queryEscaped = escapeHtml(query);

  if (allowMultipleUsers) {
    const emails = queryEscaped.match(emailRegex);
    if (emails) {
      return apiInstance({
        url: addParams('/v2/stellar/users/emails', {
          ...vo,
          ...times(),
        }),
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          emails,
        },
      })
        .then(({ data: result }) => result.result)
        .catch(errorHandler);
    }
  }

  const params = {
    ...vo,
    ...times(),
    searchString: query,
    filterType,
    size: 10,
  };

  return apiInstance({
    method: 'get',
    params,
    url: '/v2/stellar/locate',
  })
    .then(({ data: result }) => result.result)
    .catch(errorHandler);
}

export function searchUsersOrHashtags({ filterType, text }) {
  const params = {
    ...times(),
    searchString: text,
    filterType,
    size: 10,
  };

  return apiInstance({
    url: addParams('/v2/stellar/locate', {
      ...params,
    }),
    method: 'get',
  })
    .then(({ data }) => {
      if (filterType === HASHTAG) {
        const hashtags = data?.result?.map(d => ({
          hashtag: d?.hashtag,
          hid: d?.hid,
          name: buildHashtag(d?.hashtag),
          uses: d?.uses,
        }));
        return hashtags;
      }
      return data?.result?.map(d => ({
        id: d?.id,
        job: d?.job,
        name: buildFullName(d),
        profileImageCode: d?.profileImageCode,
      }));
    })
    .catch(errorHandler);
}

export function sendKudos({ entities, comment }) {
  const params = {
    entities: map(entities, (entity) => {
      if (Number.isInteger(entity.id)) {
        // getProfile from withProfile/api returns an int for id instead of a string
        // Kudos asks for a string (not other stars)
        let strId = entity.id.toString();
        // getProfile doesn't return a type and it's requested from the backend
        // That's why it's harcoded.
        entity = {
          type: 'USER',
          ...entity,
          id: strId,
        };
      }
      return pick(entity, ['id', 'type']);
    }),
    notes: removeMentionedNameFromComment(comment),
  };
  return apiInstanceNonWrapped({
    url: addParams('/v2/stellar/assignKudosStar', { ...vo, ...times() }),
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(params),
  })
    .then(({ data }) => [data])
    .catch(errorHandler);
}

function sendStar({
  user, comment, star, CCUsers,
}) {
  const starId = String(star.id);
  const parameters = {
    email: user.identification,
    notes: TextSanitize(removeMentionedNameFromComment(comment)),
    ccUsers: JSON.stringify(CCUsers),
  };
  const baseUrl = `/v2/stellar/assignstar/${starId}`;

  return apiInstanceNonWrapped({
    url: baseUrl,
    ...formData,
    data: stringify(parameters),
    params: { ...vo, ...times() },
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function sendStars({
  entities, comment, star, CCUsers,
}) {
  const calls = map(entities, user =>
    sendStar({
      user,
      comment,
      star,
      CCUsers,
    }));
  return Promise.all(calls);
}

export function sendMultipleStar({
  entities, comment, star, imageCode,
}) {
  const valueId = String(star.id);
  const who = entities.reduce((who, entity) => {
    const actualReceivers = who.receivers || [];
    const actualGroups = who.groups || [];
    if (entity.customizable) {
      if (entity.users) {
        const users = entity.users.reduce((users, user) => {
          users.push(user.id);
        }, []);
        who.receivers = [...actualReceivers, ...users];
      } else {
        const group = {
          identification: entity.id,
          type: entity.type,
        };
        who.groups = [...actualGroups, group];
      }
    } else {
      who.receivers = [...actualReceivers, entity.id];
    }
    return who;
  }, {});

  const data = {
    ...who,
    imageCode,
    notes: TextSanitize(removeMentionedNameFromComment(comment)),
    valueId,
  };

  return apiInstanceNonWrapped({
    data,
    method: 'post',
    url: '/v2/stellar/multiple-stars',
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function uploadImage({ image }) {
  const formData = new FormData();
  formData.append('token', getToken());
  formData.append('file', image);
  formData.append('fileName', image.name);

  return apiInstance({
    url: '/v2/image/insert/image',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function usersByEntity({ id, type, amount }) {
  const parameters = {
    type,
    name: id,
    size: amount,
    page: 0,
  };

  return apiInstance({
    url: '/v2/stellar/usersByEntity',
    method: 'get',
    params: {
      ...vo,
      ...times(),
      ...parameters,
    },
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiGetStarById({ starId, userId }) {
  return apiInstance({
    url: `v2/stellar/feed/${starId}/user/${userId}`,
    params: {
      firstStarId: starId,
      page: 0,
      size: 1,
      filterBy: 'RECEIVED',
      orderBy: 'DESC',
    },
  })
    .then(({ data }) => data?.result?.[0])
    .catch(errorHandler);
}
