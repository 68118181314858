// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Button from 'smu-ui-components/Button';
import questionnaireFinishedImage from 'components/Questionnaires/utils/assets/clap.svg';

// @own
import QuestionnaireProgressBar from '../QuestionnaireProgressBar';
// remove when the app will support device language
import messages from '../messages'; // eslint-disable-line
import './styles.scss';

function QuestionnaireFinished({
  closeQuestionnaire,
  index,
  // remove when the app will support device language
  intl: { formatMessage }, // eslint-disable-line
  language,
  title,
  totalQuestions,
}) {
  const text = () => ({
    ENGLISH: {
      titleText: 'Thank you!!',
      subtitle: 'You have completed the survey.',
      info: 'We are grateful for your participation.',
      buttonClose: 'Close',
    },
    SPANISH: {
      titleText: '¡¡Gracias!!',
      subtitle: 'Ha completado la encuesta.',
      info: 'Estamos muy agradecidos por su participación.',
      buttonClose: 'Cerrar',
    },
  })[language];

  const {
    titleText,
    subtitle,
    info,
    buttonClose,
  } = text();

  return (
    <div className="questionnaire-finished">
      <QuestionnaireProgressBar
        currentQuestion={index}
        maxQuestions={totalQuestions}
      />
      <h1 className="questionnaire-finished__title">{title}</h1>
      <div className="questionnaire-finished__content">
        <img src={questionnaireFinishedImage} className="questionnaire-finished__content-image" alt="" />
        <div className="questionnaire-finished__content-title">
          {titleText}
        </div>
        <div className="questionnaire-finished__content-subtitle">
          {subtitle}
        </div>
        <div className="questionnaire-finished__content-info">
          {info}
        </div>
      </div>
      <div className="questionnaire-finished__button-container">
        <Button
          className="questionnaire-finished__button"
          noMargin
          noPadding
          onClick={closeQuestionnaire}
        >
          {buttonClose}
        </Button>
      </div>
    </div>
  );
}

QuestionnaireFinished.propTypes = {
  closeQuestionnaire: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalQuestions: PropTypes.number.isRequired,
};

export default injectIntl(QuestionnaireFinished);
