import { notificationsApiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';

export function apiSubscribe(deviceId, uniqueId) {
  return notificationsApiInstance({
    url: 'api/v2/devices',
    method: 'post',
    data: {
      deviceId,
      platform: 'WEB_PUSH',
      uniqueId,
    },
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}

export function apiUnsubscribe(deviceId, uniqueId) {
  return notificationsApiInstance({
    url: 'api/v2/devices',
    method: 'delete',
    data: {
      deviceId,
      platform: 'WEB_PUSH',
      uniqueId,
    },
  })
    .then(({ data }) => data)
    .catch(errorHandler);
}
