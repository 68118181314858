// @packages
import cache, { getHash } from 'smu-utils/requestCache';
// @app
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { addParams, times } from 'api';

export function getTopStars({ size }) {
  const baseUrl = '/v2/stellar/toprank/receivers';
  const hash = getHash({size, url: baseUrl});
  const resultCache = cache.get(hash);

  if (resultCache) {
    return resultCache;
  }

  const params = { ...times(), size };

  return apiInstance({
    url: addParams(baseUrl, params),
    method: 'get',
  })
    .then(({ data }) => {
      cache.set(hash, data.result);
      return data.result;
    })
    .catch(errorHandler);
}
