// @packages
import React from 'react';
import PropTypes from 'prop-types';
// @own
import './styles.scss';

function EmptySurvey({
  imageSrc,
  text,
}) {
  return (
    <div className="surveys-widget__empty">
      <img className="surveys-widget__empty-image" src={imageSrc} alt="Empty survey" />
      <div className="surveys-widget__empty-text">
        {text}
      </div>
    </div>
  );
}

EmptySurvey.propTypes = {
  imageSrc: PropTypes.string,
  text: PropTypes.string,
};

export default EmptySurvey;
