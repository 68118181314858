export function fullName(user, name, fullName) {
  let full = '';

  if (user) {
    full = `${user[name] ? user[name] : ''}`;

    if (full) {
      full = `${full}${user[fullName] ? ` ${user[fullName]}` : ''}`;
    } else {
      full = user[fullName] || '';
    }
  }

  return full;
}
