// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';

// @app
import Modal from 'components/Modal';

const ModalShare = ({ children, footer, onClose, title, ...rest }) => (
  <Modal onClick={onClose} {...rest}>
    <div className="w-full max-w-xl rounded-md shadow-md bg-white overflow-hidden">
      <header className="flex items-center p-3">
        {title && <h3 className="font-rubik font-semibold">{title}</h3>}
        <Button
          className="ml-auto p-3 rounded-full"
          onClick={onClose}
          variant="text"
        >
          <Icon className="!text-sm" icon="close" />
        </Button>
      </header>
      <div className="w-full">{children}</div>
      {footer && <footer className="p-5 w-full">{footer}</footer>}
    </div>
  </Modal>
);

ModalShare.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
};

export default ModalShare;
