// @packages
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { withRouter } from 'react-router';

// @app
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { getRemainingStars } from 'services/sendStarFlow/selectors';
import { hasStarsToSend } from 'containers/SendStar/helpers';

const GiveStarModal = ({
  params: { coreValueId, uid },
  remainingStars,
  router,
}) => {
  useEffect(() => {
    if (hasStarsToSend(remainingStars)) {
      router.push(buildSendStarModalUrl({
        params: { coreValueId, uid },
        pathname: '/home',
      }));
    } else if (!hasStarsToSend(remainingStars)) {
      router.push('/home');
    }
  }, []);

  return null;
};

const mapStateToProps = (state) => ({
  remainingStars: getRemainingStars(state),
});

export default connect(mapStateToProps)(withRouter(GiveStarModal));
