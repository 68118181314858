import { getWebUrl } from 'smu-utils/globalRequestHelper';

const baseUrl = `${getWebUrl()}/`;
const bmeBaseUrl = `${getWebUrl('betterme')}/`;

export const actionLinks = {
  'leader.activities.cultural_low_your_led.action.send.star': {
    link: `${baseUrl}me/sendStar`,
  },
  'leader.activities.cultural_low_your_led.action.send.feedback': {
    link: `${bmeBaseUrl}newfeedbacksend`,
  },
  'leader.activities.cultural_low_your_led.action.create.post': {
    link: `${baseUrl}home?modal=upload-post`,
  },
  'leader.feedback.not_received_for_your_led.action.send.feedback': {
    link: `${bmeBaseUrl}newfeedbacksend`,
  },
  'leader.feedback.not_sent_to_your_led.action.send.feedback': {
    link: `${bmeBaseUrl}myteam/feedbacks`,
  },
  'managers.no.feedback.action.send.feedback': {
    link: `${bmeBaseUrl}newfeedbacksend`,
  },
  'no.active.new.hires.action.send.star': {
    link: `${baseUrl}me/sendStar`,
  },
  'no.active.new.hires.action.send.feedback': {
    link: `${bmeBaseUrl}newfeedbacksend`,
  },
  'user.interactions.action.send.star': {
    link: `${baseUrl}me/sendStar`,
  },
  'user.interactions.action.send.feedback': {
    link: `${bmeBaseUrl}newfeedbacksend`,
  },
  'user.interactions.action.meet.colleagues.with.influence': {
    link: `${baseUrl}me`,
  },
  'user.interactions.action.create.post': {
    link: `${baseUrl}home?modal=upload-post`,
  },
  'area.turnover.rate.action.send.feedback': {
    link: `${bmeBaseUrl}newfeedbacksend`,
  },
  'area.turnover.rate.action.send.star': {
    link: `${baseUrl}me/sendStar`,
  },
  'area.turnover.rate.action.create.post': {
    link: `${baseUrl}home?modal=upload-post`,
  },
  'user.cultural.activity.action.send.star': {
    link: `${baseUrl}me/sendStar`,
  },
  'user.cultural.activity.action.meet.leaders.with.influence': {
    link: `${baseUrl}me`,
  },
  'user.cultural.activity.action.create.post': {
    link: `${baseUrl}home?modal=upload-post`,
  },
  'user.cultural.activity.action.leaderboard': {
    link: `${baseUrl}leaderboard`,
  },
  'leader.feedback.not_sent_to_your_led.action.team.feedback.behalf': {
    link: `${bmeBaseUrl}newfeedbackonbehalfrequest`,
  },
  'leader.feedback.not_sent_to_your_led.action.team.feedback': {
    link: `${bmeBaseUrl}myteam/feedbacks`,
  },
  'leader.feedback.not_received_for_your_led.action.team.dashboard': {
    link: `${bmeBaseUrl}myteam/feedbacks`,
  },
  'leader.feedback.not_received_for_your_led.action.team.feedback.behalf': {
    link: `${bmeBaseUrl}newfeedbackonbehalfrequest`,
  },
  'user.cultural.activity.action.meet.colleagues.with.influence': {
    link: `${baseUrl}me/metrics`,
  },
};
