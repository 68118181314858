// @packages
import React, { useEffect } from 'react';
import Panel from 'smu-ui-components/Panel';
import PropTypes from 'prop-types';
import Tabs from 'smu-ui-components/Tabs';
import includes from 'lodash/includes';
import map from 'lodash/map';
import useModal from 'smu-custom-hooks/useModal';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// @app
import Badge from 'components/BadgesWidget/BadgeItem';
import ModalInteractions from 'containers/Modals/Interactions';
import OSFeedItem from 'containers/OSFeed/OSFeedItem';
import SMUEmptyState from 'components/SMUEmptyState';
import SkeletonOSFeed from 'components/Skeletons/OSFeed';
import Star from 'components/Star';
import activityMessages from 'routes/Activity/messages';
import { buildSendStarModalUrl } from 'containers/NewSendStar/helpers';
import { feedResults as getFeedResults } from 'containers/withFeed';
import { feedSetOptions, cleanFeed } from 'containers/withFeed/actions';
import { getRemainingStars } from 'services/sendStarFlow/selectors';
import { getSocialMedias } from 'containers/Authorization';
import { hasStarsToSend } from 'containers/SendStar/helpers';
import { trackEvent } from 'utils/gtm';
import {
  selectHasNewProfileEnabled,
  selectSession,
} from 'containers/Authorization/selectors';
import {
  selectFeedFetching,
  selectFeedFilterBy,
  selectFeedPage,
} from 'containers/Feed/selectors';
import { selectFeedOptions } from 'containers/withFeed/selectors';
import { selectFiltersFetching } from 'containers/FiltersV2/selectors';
import { selectUser } from 'containers/withProfile/selectors';

// @own
import './styles.scss';
import { EMPTY_STATE_PROPS, OTHER_PROFILE, PROFILE, SENT } from './constants';
import messages from './messages';
import { selectBadgesResults, selectBadgesFetching } from './selectors';

const sentStarsAnalytics = (type) => ({
  action: 'send_star',
  category: 'send_star',
  label: type === SENT ? 'sent_stars' : 'received_stars',
});

const FeedProfile = ({
  cleanFeed,
  feedSetOptions,
  fetching,
  fetchingNextPage,
  filterBy,
  filtersFetching,
  hasMore,
  intl: { formatMessage },
  isFeed,
  isIsolated,
  myProfile,
  newProfileEnabled,
  noResults,
  options,
  remainingStars,
  results,
  router,
  sessionId,
  tabIndex,
  user,
  userId,
}) => {
  const category = myProfile ? 'My Profile' : 'Profile';
  const isOwnProfile = sessionId === userId;
  const hasStars = hasStarsToSend(remainingStars);
  const modal = useModal();

  const handleSendStars = () => {
    sentStarsAnalytics(filterBy);
    const users = isOwnProfile ? [] : [user];

    router.push(buildSendStarModalUrl({
      params: {
        users,
      },
    }));
  };

  function buildEmptyState() {
    if (isIsolated) {
      const emptyStateProps =
        EMPTY_STATE_PROPS[isOwnProfile ? PROFILE : OTHER_PROFILE][filterBy];
      return (
        <SMUEmptyState
          callToAction={
            emptyStateProps?.sendStar &&
            hasStars && {
              onClick: handleSendStars,
              text: formatMessage(messages.feedProfileEmptyStateButton),
            }
          }
          imageSrc={emptyStateProps?.src}
          subtitle={
            emptyStateProps?.subtext &&
            formatMessage(messages[emptyStateProps?.subtext])
          }
          title={
            myProfile
              ? formatMessage(messages[emptyStateProps?.text])
              : formatMessage(messages[emptyStateProps?.text], {
                  firstName: user?.firstName,
                })
          }
        />
      );
    }
    return <FormattedMessage {...messages.noResults} />;
  }

  const tabs = [
    {
      name: formatMessage(messages.received),
      onClick: () => {
        trackEvent({
          category,
          action: 'Received',
        });
        feedSetOptions({
          filterBy: 'RECEIVED',
          userId,
          updateFeed: true,
          useProfileUrl: true,
        });
      },
    },
    {
      name: formatMessage(messages.sent),
      onClick: () => {
        trackEvent({
          category,
          action: 'Sent',
        });
        feedSetOptions({
          filterBy: 'SENT',
          userId,
          updateFeed: true,
          useProfileUrl: true,
        });
      },
    },
    {
      name: formatMessage(messages.badges),
      onClick: () => {
        trackEvent({
          category,
          action: 'Badges',
        });
        feedSetOptions();
      },
    },
  ];

  useEffect(() => {
    if (!filtersFetching && userId) {
      feedSetOptions({ filterBy, userId, ...options });
    }
  }, [options?.updateFeed, filterBy, userId, filtersFetching]);

  useEffect(() => () => cleanFeed(), []);

  return (
    <div>
      {!newProfileEnabled && (
        <Panel noPadding>
          <Tabs tabs={tabs} active={tabIndex} />
        </Panel>
      )}
      {fetching && !fetchingNextPage ? (
        <SkeletonOSFeed />
      ) : (
        <div className="space-y-3">
          {!noResults &&
            (isFeed ? (
              <>
                {modal.isOpen && (
                  <ModalInteractions
                    onClose={modal.close}
                    open={modal.isOpen}
                    {...modal.data}
                  />
                )}
                {
                  results.map((feedItem, index) => (
                    <OSFeedItem
                      key={`feed-profile-item-${feedItem.id}-${index}`}
                      type={feedItem.type}
                      data={{
                        data: feedItem,
                        date: feedItem.date,
                        id: feedItem.id,
                        isOwnProfile,
                      }}
                    >
                      <Star type="CORE_VALUE" />
                      <Star type="KUDOS" />
                      <Star type="SUPERNOVA" />
                    </OSFeedItem>
                  ))
                }
              </>
            ) : (
              <Panel noPadding className="panel-badges">
                {results.map((badge) => (
                  <div className="panel-badges__container" key={badge.id}>
                    <Badge {...badge} />
                  </div>
                ))}
              </Panel>
            ))}
          {noResults
            ? buildEmptyState()
            : hasMore && <SkeletonOSFeed items={2} />}
        </div>
      )}
    </div>
  );
};

FeedProfile.propTypes = {
  cleanFeed: PropTypes.func,
  feedOptions: PropTypes.object.isRequired,
  feedSetOptions: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  fetchingNextPage: PropTypes.bool,
  filterBy: PropTypes.string,
  filterType: PropTypes.bool,
  hasMore: PropTypes.bool,
  intl: PropTypes.object,
  isFeed: PropTypes.bool,
  isIsolated: PropTypes.bool,
  myProfile: PropTypes.bool,
  newProfileEnabled: PropTypes.bool,
  noResults: PropTypes.bool,
  options: PropTypes.object,
  results: PropTypes.array.isRequired,
  router: PropTypes.object,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  user: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state = {}, { myProfile, intl }) => {
  const { formatMessage } = intl;
  const badgesFetching = selectBadgesFetching(state);
  const badgesResults = selectBadgesResults(state);
  const feedFetching = selectFeedFetching(state);
  const feedOptions = selectFeedOptions(state);
  const feedPage = selectFeedPage(state);
  const filterBy = selectFeedFilterBy(state);
  const initialFeedResults = getFeedResults(state);
  const newProfileEnabled = selectHasNewProfileEnabled(state);
  const remainingStars = getRemainingStars(state);
  const session = selectSession(state);
  const user = selectUser(state);
  const filtersFetching = selectFiltersFetching(state);

  const tabIndex = (filterBy) => {
    let tab = 0;
    switch (filterBy) {
      case 'SENT':
        tab = 1;
        break;
      case 'RECEIVED':
        tab = 0;
        break;
      case 'CUSTOMER':
        tab = 0;
        break;
      case '':
        tab = -1;
        break;
      default:
        tab = 2;
        break;
    }
    return tab;
  };
  const isFeed = tabIndex(filterBy) < 2;

  const toBadge = ({
    achievement,
    criteriaFulfilled = [],
    id,
    percentage,
  }) => ({
    id: `${id}-${achievement.id}`,
    name: achievement.name,
    backgroundColor: achievement.backgroundColor,
    percentage,
    imageCode: achievement.imageCode,
    items: map(criteriaFulfilled, ({ criteria, id }) => ({
      description: criteria.i18nDescription,
      key: `${id}-${criteria.id}`,
    })),
    socialMedias: !myProfile
      ? []
      : map(getSocialMedias(session), (media) => {
          const twitterDesc = formatMessage(messages.twitterBadgeDescription, {
            name: achievement.i18nName,
            hashtag: achievement.name.replace(/ /g, ''),
          });
          const facebookIcons = ['facebook', 'facebook-workplace'];
          return {
            ...media,
            description:
              media.icon === 'twitter'
                ? twitterDesc
                : includes(facebookIcons, media.icon)
                ? formatMessage(activityMessages.facebookShareDescription)
                : '',
            title: includes(facebookIcons, media.icon)
              ? formatMessage(activityMessages.facebookShareTitle)
              : '',
            path: `/v2/share/sharecode/badge/${id}`,
          };
        }),
  });

  const badges = badgesResults && map(badgesResults, toBadge);
  const fetching = isFeed ? feedFetching : badgesFetching;
  const fetchingNextPage = feedPage > 0 && feedFetching;
  const results = isFeed ? initialFeedResults : badges;
  const sessionId = session?.user?.id;

  return {
    feedOptions,
    fetching,
    fetchingNextPage,
    filtersFetching,
    isFeed,
    newProfileEnabled,
    noResults: results && results.length === 0 && !fetching,
    remainingStars,
    results,
    sessionId,
    tabIndex,
    user,
  };
};

export default injectIntl(
  connect(mapStateToProps, { feedSetOptions, cleanFeed })(withRouter(FeedProfile)),
);
