// @packages
import React from 'react';
import Proptypes from 'prop-types';

// @own
import './styles.scss';

const StarContainer = ({ children }) => (
  <div className="star-container">
    {children}
  </div>
);

StarContainer.propTypes = {
  children: Proptypes.node.isRequired,
};

export default StarContainer;
