// @Packages
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popup } from 'semantic-ui-react';
// @App
import CoreValueItem from 'components/CoreValueItem';
// @Own
import './styles.scss';

const CoreValueButton = ({
  backgroundColor,
  descriptionShowed,
  disabled,
  handleClick,
  handleDisabledClick,
  imageCode,
  nameShowed,
}) => (

  <Popup
    trigger={
      <button
        className="core-value__button"
        onClick={disabled ? handleDisabledClick : handleClick}
        style={{ backgroundColor }}
      >
        <div className={cn({ 'core-value__disabled-layer': disabled })} />
        <div className="core-value__button-wrapper">
          <CoreValueItem
            className="core-value__button-img"
            starValueImgCode={imageCode}
            width={34}
            height={34}
          />
          <span className="core-value__button-text">{nameShowed}</span>
        </div>
      </button>
    }
    content={
      <div>
        {descriptionShowed}
      </div>
    }
    on="hover"
    position="bottom center"
    hideOnScroll
    className="core-value__popup"
  />
);

CoreValueButton.defaultProps = {
  handleDisabledClick: () => {},
};

CoreValueButton.propTypes = {
  backgroundColor: PropTypes.string,
  descriptionShowed: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  handleDisabledClick: PropTypes.func,
  imageCode: PropTypes.string,
  nameShowed: PropTypes.string,
};

export default CoreValueButton;
