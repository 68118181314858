import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'modalShare.title',
    defaultMessage: 'Share',
  },
  dnaTitle: {
    id: 'modalShare.dna.title',
    defaultMessage: 'Share DNA',
  },
});
