// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import CardDescription from 'smu-ui-components/Cards/components/Description';
import CardTitle from 'smu-ui-components/Cards/components/Title';
import CardUserName from 'smu-ui-components/Cards/components/UserName';
import InsightCardTemplate from 'smu-ui-components/Cards/templates/Insight';
import Link from 'smu-ui-components/Link';
import PropTypes from 'prop-types';
import { buildImageUrl } from 'smu-utils/image';

// @app
import AvatarLink from 'components/AvatarLink';

const FeedInsightCard = ({ insight }) => {
  const userProfileUrl = `/profile/${insight?.user?.uid}`;

  return (
    <InsightCardTemplate
      button={insight?.targets?.map(target => (
        <Link
          className="block mb-3 last:mb-0"
          href={target.action}
          key={target.label}
          legacy={false}
          underline="never"
        >
          <Button
            color="unifyBlue"
            enabledUnification
            variant="outline"
          >
            {target.label}
          </Button>
        </Link>
      ))}
      className="shadow-base"
      description={<CardDescription>{insight.description}</CardDescription>}
      title={<CardTitle className="text-blue">{insight.title}</CardTitle>}
      userAvatar={
        <AvatarLink
          height={72}
          href={userProfileUrl}
          src={buildImageUrl({
            code: insight?.user?.imageCode,
            height: 72,
            width: 72,
          })}
          width={72}
        />
      }
      userName={
        <CardUserName href={userProfileUrl}>
          {`${insight?.user?.firstName} ${insight?.user?.lastName}`}
        </CardUserName>
      }
    />
  );
};

FeedInsightCard.propTypes = {
  insight: PropTypes.shape({
    description: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.shape({
      action: PropTypes.string,
      label: PropTypes.string,
    })),
    title: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      imageCode: PropTypes.string,
      lastName: PropTypes.string,
      uid: PropTypes.string,
    }),
  }),
};

export default FeedInsightCard;
