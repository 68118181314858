import { defineMessages } from 'react-intl';

export default defineMessages({
  goodWork: {
    id: 'star.goodWork',
    defaultMessage: 'good work!',
  },
  previous: {
    id: 'star.previous',
    defaultMessage: 'PREVIOUS',
  },
  page: {
    id: 'star.page',
    defaultMessage: 'PAGE',
  },
  twitterShareDescription: {
    id: 'star.twitterShareDescription',
    defaultMessage: 'New #StarmeUp recognition! #{starName}',
  },
  facebookShareDescription: {
    id: 'star.facebookShareDescription',
    defaultMessage: 'StarmeUp helps organizations grow a healthy culture leveraging peer-to-peer recognition, reinforcing organizational values, bringing transparency and meritocracy to everyday´s actions.',
  },
  facebookShareTitle: {
    id: 'star.facebookShareTitle',
    defaultMessage: 'Discover those that make your organization unique',
  },
});
