import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'eula.title',
    defaultMessage: 'END USER LICENSE AGREEMENTS',
  },
  checkboxText: {
    id: 'eula.checkboxText',
    defaultMessage: 'I agree to the terms and conditions provided in this document.',
  },
  continue: {
    id: 'common.continue',
    defaultMessage: 'continue',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'cancel',
  },
});
