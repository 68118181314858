// @packages
import { useEffect } from 'react';
import { trackPageview } from 'smu-utils/gtm';

function ShareLayout() {
  useEffect(() => {
    trackPageview({
      pageName: '/share',
    });
  }, []);

  return null;
}

export default ShareLayout;
