// @packages
import React from 'react';
import Button from 'smu-ui-components/ButtonV2';
import Icon from 'smu-ui-components/IconV2';
import PropTypes from 'prop-types';
import cn from 'classnames';

// @app
import Modal from 'components/Modal';

// @own
import './styles.scss';

const ModalCongratulation = ({ className, children, onClose, ...rest }) => (
  <Modal
    className={cn('smu-modal-congratulation', className)}
    onClick={onClose}
    {...rest}
  >
    <div className="smu-modal-congratulation__content">
      <Button
        className="smu-modal-congratulation__close"
        onClick={onClose}
        variant="text"
      >
        <Icon className="smu-modal-congratulation__close-icon" icon="close" />
      </Button>
      {children}
    </div>
  </Modal>
);

ModalCongratulation.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default ModalCongratulation;
