export const firebaseQa = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: 'smu-and-qa.firebaseapp.com',
  databaseURL: 'https://smu-and-qa.firebaseio.com',
  projectId: 'smu-and-qa',
  storageBucket: 'smu-and-qa.appspot.com',
  messagingSenderId: '1040253284424',
  appId: '1:1040253284424:web:54831de052e294584d287d',
};

export const firebaseProd = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: 'smu-and-qa.firebaseapp.com',
  databaseURL: 'https://smu-and-qa.firebaseio.com',
  projectId: 'smu-and-qa',
  storageBucket: 'smu-and-qa.appspot.com',
  messagingSenderId: '1040253284424',
  appId: '1:1040253284424:web:fce15dcd0a406ce04d287d',
};

export const appToken = 'BPrYmVIIjxouAc_d1jU2VvyFCJwcfFBc1_Rd2NJ-2JJJ5u6MHPfyOxocGMnaPe33W16qQFv3piUjujq5IJn9vHg';
