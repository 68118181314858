// @packages
import React, { useEffect } from 'react';
import GenericErrorState from 'smu-app-components/GenericErrorState';
import PropTypes from 'prop-types';
import SurveysDetailsModal from 'smu-app-components/SurveysDetails/components/Modal';
import useModal from 'smu-custom-hooks/useModal';
import { useSelector } from 'react-redux';
import { useSurveyById } from 'smu-services/takepart/api/v3/community/questionnaires/[id]';
import { buildImageUrl } from 'smu-utils/image';

// @app
import SurveysWrapper from 'components/Questionnaires/QuestionnaireModal/Wrapper';
import { ENABLED_TAKEPART_SURVEY } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { selectCommunityId, selectOrganizationImageId } from 'containers/Authorization/selectors';

function QuestionnaireLayout({ params, router, location }) {
  const backPath = location.query?.backPath;
  const id = params?.questionnaireId;
  const surveyId = id ? Number(id) : undefined;
  const orgId = useSelector(selectCommunityId);
  const orgImageId = useSelector(selectOrganizationImageId);
  const communityLogo =
    orgImageId && buildImageUrl({ code: orgImageId, width: 40, height: 40 });
  const enabledTakePartSurvey = useSelector((state) =>
    getCommunityConfigValueBool(state, ENABLED_TAKEPART_SURVEY));
  const modal = useModal();
  const swrOptions = { revalidateIfStale: true };
  const swrConfig = {
    id: surveyId,
    shouldFetch: !!surveyId && enabledTakePartSurvey,
  };
  const { selectSurveyType } = useSurveyById(swrConfig, swrOptions);
  const surveyType = selectSurveyType();
  const isTrivia = surveyType === 'TRIVIA';

  const handleSeeResults = () => {
    router.push('/me/be-kind');
  };

  const handleGoToHome = () => {
    router.push(backPath || '/questionnaires');
  };

  const handleClose = () => {
    handleGoToHome();
    modal.close();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    modal.open();
  }, []);

  if (!enabledTakePartSurvey) {
    return <GenericErrorState image="padlock" statusCode={403} />;
  }

  if (isTrivia) {
    return <SurveysWrapper id={surveyId} onClose={handleClose} />;
  }

  return (
    <SurveysDetailsModal
      open={modal.isOpen}
      onClose={handleClose}
      surveyProps={{
        communityLogo,
        onGoToHome: handleGoToHome,
        onSeeResults: handleSeeResults,
        orgId,
        surveyId,
        variant: 'container',
      }}
    />
  );
}

QuestionnaireLayout.propTypes = {
  params: PropTypes.shape({
    questionnaireId: PropTypes.string.isRequired,
  }),
};

export default QuestionnaireLayout;
