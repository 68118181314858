import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { mutate } from 'swr';

import { cloneDeep, find } from 'lodash';
import moment from 'moment';
import parseDate from 'utils/parseDate';
import {
  selectSessionUserId,
  selectSessionUserUid,
} from 'containers/Authorization/selectors';
import {
  actionItemsUpdate,
  actionItemsUpdateMultiple,
} from 'services/items/actions';
import { selectItemById } from 'services/items/selectors';

import {
  addOsFeedVisitedItem,
  cleanOsFeed,
  cleanUnvisitedOsFeedItems,
  getOsFeed as getOsFeedAction,
  getOsFeedFail,
  getOsFeedHighlights as getOsFeedHighlightAction,
  getOsFeedHighlightsFail,
  getOsFeedHighlightsSuccess,
  getOsFeedPreferenceV2Fail,
  getOsFeedPreferenceV2Success,
  getOsFeedSuccess,
  postOsFeedCommentFail,
  postOsFeedCommentSuccess,
  postOsFeedLikeFail,
  postOsFeedLikeSuccess,
  postOsFeedLoadImageFail,
  postOsFeedLoadImageSuccess,
  postOsFeedModerateCommentFail,
  postOsFeedModerateCommentSuccess,
  postOsFeedPreferenceV2Fail,
  postOsFeedPreferenceV2Success,
  postOsFeedRemoveLikeFail,
  postOsFeedRemoveLikeSuccess,
  postOsFeedTurnToggleSuccess,
  postOsFeedVisitedPostsFail,
} from './actions';

import {
  GET_OS_FEED,
  GET_OS_FEED_HIGHLIGHTS,
  GET_OS_FEED_PREFERENCE,
  POST_OS_FEED_COMMENT,
  POST_OS_FEED_LIKE,
  POST_OS_FEED_LOAD_IMAGE,
  POST_OS_FEED_MODERATE_COMMENT,
  POST_OS_FEED_PREFERENCE,
  POST_OS_FEED_REMOVE_LIKE,
  POST_OS_FEED_TURN_TOGGLE,
  POST_OS_FEED_VISITED_POSTS,
} from './actionTypes';

import {
  getOsFeed,
  getOsFeedHighlights,
  getOsFeedPreferenceV2,
  osFeedUploadImage,
  postOsFeedComment,
  postOsFeedLike,
  postOsFeedLoadImage,
  postOsFeedModerateComment,
  postOsFeedPreferenceV2,
  postOsFeedRemoveLike,
  postOsFeedVistitedPostsApi,
} from './api';

import {
  COMMENT,
  CORE_VALUE,
  DAYS_TO_SHOW,
  HIGHLIGHTS,
  IMAGE,
  K,
  KUDOS,
  LIKE,
  SUPERNOVA,
} from './constants';

import { selectUnvisitedItem, selectFilters } from './selectors';
import { normalizeItems } from './helpers';

function setLikesCommentsCount(obj, userUid) {
  return cloneDeep(obj).map((card) => {
    // Provisional until the content-type highlighted will be ended
    if (!card.type || card.type === CORE_VALUE || card.type === KUDOS || card.type === SUPERNOVA) {
      card.data = card.data ? card.data : card;
      card.type = card.data
        ? card.type
        : card.typeStar === K
          ? KUDOS
          : CORE_VALUE;

      card.data.commentCount = 0;
      card.data.likeCount = 0;
      card.data.comments.map((comments) => {
        if (comments.type === LIKE) {
          card.data.likeCount += 1;
          if (comments.user.id === userUid) {
            card.data.hasOwnLike = true;
          }
        } else if (comments.type === COMMENT || comments.type === IMAGE) {
          card.data.commentCount += 1;
        }
      });

      return card;
    }
    return card;
  });
}

export function hasShowSendStarWidget(activity) {
  return moment()
    .subtract(DAYS_TO_SHOW, 'days')
    .isBefore(parseDate(activity.date));
}

function* getOsFeedWorker({ payload: { params } }) {
  try {
    if (!params?.page) {
      yield put(cleanOsFeed());
    }
    const userId = yield select(selectSessionUserId);
    const { result, page } = yield call(getOsFeed, params);
    const postResults = setLikesCommentsCount(result, userId);
    const items = normalizeItems(postResults);

    if (items?.length) {
      yield put(actionItemsUpdateMultiple(items));
    }

    let hasMore = result?.length > 0;

    if (!params?.page && page?.totalElements < params?.size) {
      hasMore = false;
    }

    yield put(
      getOsFeedSuccess({
        feed: postResults,
        hasMore,
        totalElements: page?.totalElements,
      }),
    );
  } catch (error) {
    yield put(getOsFeedFail(error));
  }
}

export function* osFeedWatcher() {
  yield takeLatest(GET_OS_FEED, getOsFeedWorker);
}

function* getOsFeedHighlightsWorker({ payload: { api, params } }) {
  try {
    const userUid = yield select(selectSessionUserUid);
    const userId = yield select(selectSessionUserId);
    const payload = yield call(getOsFeedHighlights, api, params);
    const postResults = setLikesCommentsCount(payload, userId);

    if (postResults?.length === 1) {
      const activity = postResults[0];
      let starForMyself = false;
      if (activity?.typeStar === 'K') {
        starForMyself = Boolean(find(activity.listUsersKudos, { uid: userUid }));
      } else {
        starForMyself = activity?.to?.uid === userUid;
      }
      postResults.starForMyself = starForMyself;
    }

    yield put(getOsFeedHighlightsSuccess({
      feed: postResults,
      totalElements: 0,
    }));
  } catch (error) {
    yield put(getOsFeedHighlightsFail(error));
  }
}

export function* osFeedHighlightsWatcher() {
  yield takeLatest(GET_OS_FEED_HIGHLIGHTS, getOsFeedHighlightsWorker);
}

function* postOsFeedCommentWorker({ payload: { api, comments, id } }) {
  try {
    const item = yield select((state) => selectItemById(state, id));
    const itemsComments = item?.comments || [];
    const { result } = yield call(postOsFeedComment, api, comments, id);
    yield put(actionItemsUpdate(id, {
      comments: itemsComments?.concat(result),
    }));
    yield put(postOsFeedCommentSuccess({ comment: result }));
  } catch (error) {
    yield put(postOsFeedCommentFail(error));
  }
}

export function* osFeedCommentWatcher() {
  yield takeLatest(POST_OS_FEED_COMMENT, postOsFeedCommentWorker);
}

function* postOsFeedLikeWorker({ payload: { api, id } }) {
  try {
    const payload = yield call(postOsFeedLike, api, id);
    const { result } = payload;
    yield put(postOsFeedLikeSuccess({ like: result }));
  } catch (error) {
    yield put(postOsFeedLikeFail(error));
  }
}

export function* osFeedLikeWatcher() {
  yield takeLatest(POST_OS_FEED_LIKE, postOsFeedLikeWorker);
}

function* postOsFeedRemoveLikeWorker({ payload: { api, id, likeId } }) {
  try {
    yield call(postOsFeedRemoveLike, api, likeId);
    yield put(postOsFeedRemoveLikeSuccess({ like: { id, likeId } }));
  } catch (error) {
    yield put(postOsFeedRemoveLikeFail(error));
  }
}

export function* osFeedRemoveLikeWatcher() {
  yield takeLatest(POST_OS_FEED_REMOVE_LIKE, postOsFeedRemoveLikeWorker);
}

function* postOsFeedPreferenceV2Worker({ payload: { api, osFeed, tab } }) {
  try {
    const currentParams = yield select(selectFilters);
    const params = { ...currentParams, page: 0 };
    yield call(postOsFeedPreferenceV2, { osFeed });
    yield put(postOsFeedPreferenceV2Success({ osFeed }));
    if (tab === HIGHLIGHTS) {
      yield put(getOsFeedHighlightAction({ api, params }));
    } else {
      yield put(getOsFeedAction({ params }));
    }
  } catch (error) {
    yield put(postOsFeedPreferenceV2Fail(error));
  }
}

export function* postOsFeedPreferenceV2Watcher() {
  yield takeLatest(POST_OS_FEED_PREFERENCE, postOsFeedPreferenceV2Worker);
}

function* postOsFeedTurnToggleWorker({ payload: { osFeed } }) {
  try {
    yield call(postOsFeedPreferenceV2, { osFeed });
    yield put(postOsFeedTurnToggleSuccess({ osFeed }));
  } catch (error) {
    yield put(postOsFeedPreferenceV2Fail(error));
  }
}

export function* postOsFeedTurnToggleWatcher() {
  yield takeLatest(POST_OS_FEED_TURN_TOGGLE, postOsFeedTurnToggleWorker);
}

function* osFeedLoadImageWorker({ payload: { api, file, id } }) {
  try {
    const response = yield call(osFeedUploadImage, { api, image: file });
    const comment = yield call(postOsFeedLoadImage, {
      api,
      imageCode: response.result.code,
      id,
    });
    yield put(postOsFeedLoadImageSuccess({
      image: {
        starId: id,
        ...comment.result,
      },
    }));
  } catch (error) {
    yield put(postOsFeedLoadImageFail(error));
  }
}

export function* osFeedLoadImageWatcher() {
  yield takeLatest(POST_OS_FEED_LOAD_IMAGE, osFeedLoadImageWorker);
}

function* postOsFeedModerateCommentWorker({
  payload: { api, starId, id, status },
}) {
  try {
    const result = yield call(postOsFeedModerateComment, { api, id, status });
    yield put(
      postOsFeedModerateCommentSuccess({ comment: { starId, result } }),
    );
    const item = yield select((state) => selectItemById(state, starId));
    if (item) {
      yield put(
        actionItemsUpdate(starId, {
          comments: item?.comments?.map((item) => ({
            ...item,
            enabled: item?.id === id ? !item?.enabled : item?.enabled,
          })),
        }),
      );
    }
  } catch (error) {
    yield put(postOsFeedModerateCommentFail(error));
  }
}

export function* postOsFeedModerateCommentWatcher() {
  yield takeLatest(POST_OS_FEED_MODERATE_COMMENT, postOsFeedModerateCommentWorker);
}

function* getOsFeedPreferenceV2Worker() {
  try {
    const result = yield call(getOsFeedPreferenceV2);
    yield put(getOsFeedPreferenceV2Success({ osFeed: result }));
  } catch (error) {
    yield put(getOsFeedPreferenceV2Fail(error));
  }
}

export function* getOsFeedPreferenceV2Watcher() {
  yield takeLatest(GET_OS_FEED_PREFERENCE, getOsFeedPreferenceV2Worker);
}

function* postOsFeedVisitedPostsWorker() {
  try {
    const unvisitedItems = yield select(selectUnvisitedItem);
    if (unvisitedItems.length) {
      yield call(postOsFeedVistitedPostsApi, unvisitedItems);
      yield put(addOsFeedVisitedItem(unvisitedItems));
      yield put(cleanUnvisitedOsFeedItems());
      yield mutate('/os-member/api/v1/community/member/feed/communications/feed/count');
    }
  } catch (error) {
    yield put(postOsFeedVisitedPostsFail(error));
  }
}

export function* postOsFeedVisitedPostsWatcher() {
  yield takeLatest(POST_OS_FEED_VISITED_POSTS, postOsFeedVisitedPostsWorker);
}

export default {
  getOsFeedPreferenceV2Watcher,
  osFeedCommentWatcher,
  osFeedHighlightsWatcher,
  osFeedLikeWatcher,
  osFeedLoadImageWatcher,
  osFeedRemoveLikeWatcher,
  osFeedWatcher,
  postOsFeedModerateCommentWatcher,
  postOsFeedPreferenceV2Watcher,
  postOsFeedTurnToggleWatcher,
  postOsFeedVisitedPostsWatcher,
};
