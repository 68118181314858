import { defineMessages } from 'react-intl';

export default defineMessages({
  QuestionnairesFlexBegin: {
    id: 'QuestionnairesFlex.Begin',
    defaultMessage: 'Begin',
  },
  QuestionnairesFlexClose: {
    id: 'QuestionnairesFlex.Close',
    defaultMessage: 'Close',
  },
  QuestionnairesFlexNext: {
    id: 'QuestionnairesFlex.Next',
    defaultMessage: 'Next',
  },
  QuestionnairesFlexPickANewQuestion: {
    id: 'QuestionnairesFlex.PickANewQuestion',
    defaultMessage: 'Pick a new question',
  },
  QuestionnairesFlexSkip: {
    id: 'QuestionnairesFlex.Skip',
    defaultMessage: 'Skip',
  },
  QuestionnairesFlexRetry: {
    id: 'QuestionnairesFlex.Retry',
    defaultMessage: 'Retry',
  },
  QuestionnairesFlexError: {
    id: 'QuestionnairesFlex.Error',
    defaultMessage: 'Something went wrong. Please try again',
  },
  QuestionnairesFlexFinishedTitle: {
    id: 'QuestionnairesFlex.FinishedTitle',
    defaultMessage: 'Thank you for your responses!',
  },
  QuestionnairesFlexAnonymousDisclaimer: {
    id: 'QuestionnairesFlex.AnonymousDisclaimer',
    defaultMessage: "Don't worry, your identity won't be revealed or linked to your answers.",
  },
  QuestionnairesFlexProgressPreposition: {
    id: 'QuestionnairesFlex.AnonymousProgressPreposition',
    defaultMessage: 'of',
  },
  QuestionnairesFlexDescriptionHelperEmoji: {
    id: 'QuestionnairesFlex.DescriptionHelperEmoji',
    defaultMessage: 'Select an emoji',
  },
  QuestionnairesFlexDescriptionHelperEmojiMultiple: {
    id: 'QuestionnairesFlex.DescriptionHelperEmojiMultiple',
    defaultMessage: 'Select one or more emojis',
  },
  QuestionnairesFlexDescriptionHelperImage: {
    id: 'QuestionnairesFlex.DescriptionHelperImage',
    defaultMessage: 'Select an image',
  },
  QuestionnairesFlexDescriptionHelperImageMultiple: {
    id: 'QuestionnairesFlex.DescriptionHelperImageMultiple',
    defaultMessage: 'Select one or more images',
  },
  QuestionnairesFlexDescriptionHelperSendStar: {
    id: 'QuestionnairesFlex.DescriptionHelperSendStar',
    defaultMessage: 'Do you have a teammate in mind? If so, send them a star!',
  },
  QuestionnairesFlexDescriptionHelperTextSingle: {
    id: 'QuestionnairesFlex.DescriptionHelperTextSingle',
    defaultMessage: 'Select an option',
  },
  QuestionnairesFlexDescriptionHelperTextMultiple: {
    id: 'QuestionnairesFlex.DescriptionHelperTextMultiple',
    defaultMessage: 'Select one or more options',
  },
  QuestionnairesFlexOptionDescriptionNpsNotLikely: {
    id: 'QuestionnairesFlex.Option.DescriptionNpsNotLikely',
    defaultMessage: 'Not very likely',
  },
  QuestionnairesFlexOptionDescriptionNpsLikely: {
    id: 'QuestionnairesFlex.Option.DescriptionNpsLikely',
    defaultMessage: 'Very likely',
  },
});
