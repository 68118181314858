import React from 'react';
import TextParser from 'containers/TextParser';
import parseDate from 'utils/parseDate';
import { FormattedRelative } from 'react-intl';
import { buildImageUrl } from 'smu-utils/image';
import { fullName } from 'utils/user';

export const getCommentListProps = (props) => {
  let allComments = props?.comments;
  if (allComments) {
    allComments = [...allComments].sort(
      (a, b) => b?.createdDate - a?.createdDate,
    );
  }
  const comments = allComments?.map((c) => {
    const dateFormatted = c?.createdAt
      ? parseDate(c?.createdAt).toDate()
      : undefined;
    const dateTime = dateFormatted ? (
      <FormattedRelative value={dateFormatted} />
    ) : undefined;
    const userAvatar = buildImageUrl({
      code: c?.user?.imageCode,
      width: 27,
      height: 27,
    });
    const mentions = c?.listMentions?.map((u) => ({
      fullName: u?.fullName,
      id: u?.id,
      uid: u?.uid,
    }));
    const message = c?.comment ? (
      <TextParser
        urlProps={{
          externalRedirect: true,
        }}
        users={mentions}
      >
        {c?.comment}
      </TextParser>
    ) : undefined;
    const userName = fullName(c?.user, 'firstName', 'lastName');
    const userProfile = c?.user?.uid ? `/profile/${c?.user?.uid}` : undefined;

    return {
      dateTime,
      enabled: c?.enabled,
      id: c?.id,
      key: c?.id,
      message,
      userAvatar,
      userName,
      userProfile,
    };
  });

  return {
    comments,
  };
};
