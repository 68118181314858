export const BETHERE_TAB = 'bethere-feed';
export const CORE_VALUE = 'CORE_VALUE';
export const FINISHED = 'FINISHED';
export const HIGHLIGHTS = 'HIGHLIGHTS';
export const MOMENTS_TAB = 'moments';
export const STARS_TAB = 'stars';
export const HIGHLIGHTS_TAB = 'highlights-feed';
export const KUDOS = 'KUDOS';
export const NONE = 'NONE';
export const PENDING = 'PENDING';
export const RECENT = 'RECENT';
export const RECENT_TAB = 'recent-feed';
export const STARMEUP = 'StarMeUp';
export const TAB_INDEX = {
  [RECENT_TAB]: 0,
  [HIGHLIGHTS_TAB]: 1,
  [BETHERE_TAB]: 1,
};
