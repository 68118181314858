// @packages
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

// @app
import getAspectRatio from 'utils/aspectRatio';
import { isValidURL } from 'utils/url';
import { fullName } from 'utils/user';

// @own
import { TYPE_VIDEO, TYPE_PHOTO } from './constants';

export function getImagePath(url) {
  const arr = url ? url.split('/') : [];
  arr.pop();
  return arr.join('/');
}

function getImage(image, cloudFront, size) {
  const name = get(image, `${size}.name`);
  const originalPath = get(image, 'original.path');
  const path = isValidURL(originalPath)
    ? getImagePath(originalPath)
    : `https://${cloudFront}`;
  return `${path}/${name}`;
}

function normaliceImage(file) {
  const { cloudFront } = file;
  const image = getImage(file.image, cloudFront, 'original');
  const size = get(file.image, 'original.size');
  const thumbnail = getImage(file.image, cloudFront, 'small');

  return {
    thumbnail,
    image,
    size,
  };
}

function normaliceVideo(file) {
  const { cloudFront } = file;
  const size = get(file.thumbnail, 'original.size');
  const thumbnail = getImage(file.thumbnail, cloudFront, 'small');
  const video = get(file.video, 'path');

  return {
    thumbnail,
    video,
    size,
  };
}

export function normaliceFile(file, type, id) {
  let media = {};

  if (type === TYPE_PHOTO) {
    media = normaliceImage(file);
  }

  if (type === TYPE_VIDEO) {
    media = normaliceVideo(file);
  }

  if (media.size) {
    media.aspectRatio = getAspectRatio(media.size);
  }

  return {
    id,
    type,
    ...media,
  };
}

export function normaliceComment(comment) {
  return {
    ...comment,
    uniqueId: uniqueId('comment_'),
    user: {
      uid: comment?.user?.uid,
      user_email: comment?.user?.email || comment?.user?.user_email,
      user_id: comment?.user?.id || comment?.user?.user_id,
      user_identification: comment?.user?.identification || comment?.user?.user_identification,
      user_image: comment?.user?.profileImageCode || comment?.user?.user_image,
      user_job: comment?.user?.job || comment?.user?.user_job,
      user_lastname: comment?.user?.lastName || comment?.user?.user_lastname,
      user_name: comment?.user?.firstName || comment?.user?.user_name,
    },
    mentions: comment?.mentions?.map(m => ({
      uid: m?.uid,
      user_email: m?.email || m?.user_email,
      user_id: m?.id || m?.user_id,
      user_identification: m?.identification || m?.user_identification,
      user_image_code: m?.profileImageCode || m?.user_image_code,
      user_name: m?.fullName || fullName(m, 'user_name', 'user_lastname'),
    })),
  };
}

export const normaliceComments = (comments) => comments?.map(normaliceComment);
