// @packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RootModal from 'smu-app-components/RootModalV2';
import { FormattedMessage, FormattedRelative } from 'react-intl';
import { closeModal } from 'smu-app-components/RootModalV2/actions';
import { connect } from 'react-redux';

// @app
import GroupList from 'components/GroupList';
import UserList from 'containers/KudosExpanded/UserList';
import parseDate from 'utils/parseDate';
import {
  actionUserByKudosStarId,
  actionUserByKudosStarIdClean,
} from 'services/usersByKudosStarId/actions';
import {
  selectUsersByKudosStarIdHasMore,
  selectUsersByKudosStarIdPage,
  selectUsersByKudosStarIdRequesting,
  selectUsersByKudosStarIdResult,
} from 'services/usersByKudosStarId/selectors';

// @own
import messages from './messages';

const KudosExpanded = ({
  actionUserByKudosStarId,
  actionUserByKudosStarIdClean,
  closeModal,
  date,
  fetching,
  hasMore,
  id,
  kudosStar,
  page,
  requesting,
  result,
}) => {
  useEffect(() => {
    actionUserByKudosStarId({ id, page });
    return () => actionUserByKudosStarIdClean();
  }, []);

  const handleScrollBottom = () => {
    if (!requesting && hasMore) {
      actionUserByKudosStarId({ id, page: page + 1 });
    }
  };

  return (
    <GroupList
      leftTitle={kudosStar}
      rightTitle={<FormattedMessage {...messages.time} />}
      rightSubtitle={<FormattedRelative value={parseDate(date).toDate()} />}
    >
      <UserList
        fetching={fetching}
        hasMore={hasMore}
        onAvatarClick={closeModal}
        onScrollEvent={handleScrollBottom}
        result={result}
      />
    </GroupList>
  );
};

KudosExpanded.propTypes = {
  closeModal: PropTypes.func,
  date: PropTypes.string.isRequired,
  hasMore: PropTypes.bool,
  id: PropTypes.number.isRequired,
  kudosStar: PropTypes.string,
  nameToShow: PropTypes.string,
  page: PropTypes.number,
  requesting: PropTypes.bool,
  result: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
  const { nameToShow } = ownProps;
  return {
    hasMore: selectUsersByKudosStarIdHasMore(state),
    kudosStar: nameToShow.toUpperCase(),
    page: selectUsersByKudosStarIdPage(state),
    requesting: selectUsersByKudosStarIdRequesting(state),
    result: selectUsersByKudosStarIdResult(state),
  };
};

const mapDispatchToProps = {
  actionUserByKudosStarId,
  actionUserByKudosStarIdClean,
  closeModal,
};

const ConnectedKudos = connect(
  mapStateToProps,
  mapDispatchToProps,
)(KudosExpanded);

export default RootModal(ConnectedKudos, 'KUDOSEXP_CONFIRMATION');
