// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import BreadcrumbHeader from 'smu-ui-components/BreadcrumbHeader';

// @app
import Recognition from 'containers/Recognition';
import RecognitionExpanded from 'components/OS/Event/RecognitionContent/RecognitionExpanded';
import InteractionModal from 'components/InteractionModal';

// @own
import './styles.scss';
import messages from './messages';

function Timeline({ intl, params }) {
  const { profileId, direction } = params;
  const { formatMessage } = intl;
  const crumbs = [
    formatMessage(messages.timelineProfile),
    formatMessage(messages.timelineRecognitions),
  ];
  return (
    <div className="profile-timeline">
      <RecognitionExpanded />
      <InteractionModal classNames="fullscreen-modal--custom-interacion" />
      <BreadcrumbHeader className="profile-timeline__header" crumbs={crumbs} />
      <div className="profile-timeline__container">
        <div className="profile-timeline__feed">
          <Recognition userId={profileId} filterSent={direction === 'sent'} />
        </div>
      </div>
    </div>
  );
}

Timeline.propTypes = {
  intl: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(Timeline));
