import React from 'react';
import { map } from 'lodash';

export const splitTextWithDots = (text, maxLength) => {
  if (text.length > maxLength) {
    let newText = text.slice(0, maxLength);
    newText += '...';
    return newText;
  }
  return text;
};

export const interpolateVariables = (string = '', variables) => {
  let stringInterpolated = string;
  const interpolations = string.match(/\[(.*?)\]/g);
  map(interpolations, (item) => {
    const key = item.slice(1, (item.length - 1));
    if (variables[key]) {
      stringInterpolated = stringInterpolated.replace(item, variables[key]);
    }
  });
  return stringInterpolated;
};

export const formatHashtags = (string, parseToJsx) => {
  let formattedText = string;
  const hashtagRegex = /(#\w+)/g;
  const maxLength = 140;
  const hashtags = string.match(hashtagRegex);

  if (parseToJsx && hashtags) {
    const { tag, className } = parseToJsx;

    const segments = string
      .split(/(\s+)/)
      .map(segment => (hashtags.includes(segment)
        ? React.createElement(tag, { className }, segment)
        : segment
      ));
    return segments;
  }

  map(hashtags, (item) => {
    formattedText = formattedText.replace(
      item,
      `**${item.substring(0, (maxLength - 1))}**`,
    );
  });
  return formattedText;
};

export const formatNumber = (number) => number.toLocaleString().replace(',', '.');

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

export const getInitials = (string) => {
  if (string) {
    const splitted = string.split(' ');
    if (splitted.length >= 2) {
      return splitted[0].charAt(0).toUpperCase() + splitted[1].charAt(0).toUpperCase();
    }
    return splitted[0].charAt(0).toUpperCase() + splitted[0].charAt(1).toUpperCase();
  }
  return '';
};

export const castStringToBoolean = (value) => value === 'true';
