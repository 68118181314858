import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactInfiniteScroll from 'react-infinite-scroll-component';
import LottieSpinner from 'smu-ui-components/LottieSpinner';

const SPINNER_SIZE = 40;

const InfiniteScroll = ({
  children,
  className,
  loadingType,
  ...props
}) => {
  const Loader = (
    <LottieSpinner
      className="infinite-scroll__loader"
      loadingType={loadingType}
      height={SPINNER_SIZE}
      width={SPINNER_SIZE}
    />
  );

  return (
    <ReactInfiniteScroll
      className={cn('infinite-scroll', className)}
      loader={Loader}
      scrollThreshold={0.7}
      {...props}
    >
      {children}
    </ReactInfiniteScroll>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataLength: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadingType: PropTypes.string,
  next: PropTypes.func.isRequired,
};

export default InfiniteScroll;
