// @packages
import { convertToRaw, Modifier, EditorState, ContentState } from 'draft-js';
import AvatarMedia from 'smu-ui-components/AvatarMedia';
import cn from 'classnames';
import Editor from 'draft-js-plugins-editor';
import isEmpty from 'lodash/isEmpty';
import Portal from 'smu-ui-components/Portal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import suggestionsPlugin from 'draft-js-mention-plugin';

// @app
import TrendItem from 'components/Trends/Item';
import { searchEntities } from 'services/sendStarFlow/api';

// @own
import './styles.scss';

class RichInputText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.props.editorState,
      suggestedHashtags: [],
      suggestedMentions: [],
      valueMentionUser: '',
      valueMentionHashtag: '',
    };

    const theme = {
      mentionSuggestions: 'rich-input-text__suggestions',
      mentionSuggestionsEntry: 'rich-input-text__entry',
      mentionSuggestionsEntryFocused: 'rich-input-text__entry--focused',
    };

    this.mention = suggestionsPlugin({
      mentionTrigger: '@',
      entityMutability: 'IMMUTABLE',
      theme,
      supportWhitespace: true,
      mentionComponent: sugestionProps => (
        <span className="rich-input-text__mention">
          {sugestionProps.children}
        </span>
      ),
    });

    this.hashtag = suggestionsPlugin({
      mentionTrigger: '#',
      entityMutability: 'IMMUTABLE',
      theme,
      supportWhitespace: true,
      mentionComponent: sugestionProps => (
        <span className="rich-input-text__hashtag">
          {sugestionProps.children}
        </span>
      ),
    });
  }

  componentDidMount() {
    const { shouldFocus } = this.props;
    if (shouldFocus) {
      this.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { emoji } = this.props;

    if (emoji && prevProps.emoji !== emoji) {
      this.setText(emoji);
      this.props.setEmoji(undefined);
    }

    if (
      this.getPlainText(prevProps.comment?.editorState) !==
      this.getPlainText(this.props.comment?.editorState)
    ) {
      this.setState({
        editorState: this.props.comment?.editorState,
      });
    }
  }

  getPlainText = (editorState) => editorState?.getCurrentContent?.()?.getPlainText() || '';

  getPlainTextLength = (editorState) => this.getPlainText(editorState).length;

  getSelectedTextLength = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString();
    return selectedText.length;
  }

  onChange = (editorState) => {
    const { onInputChange, onAvailable, onUnavailable } = this.props;

    let inputUpdated = editorState.getCurrentContent().getPlainText();
    let jsonEditorState = convertToRaw(editorState.getCurrentContent());
    const mentions = [];

    if (jsonEditorState.entityMap && !isEmpty(jsonEditorState.entityMap)) {
      inputUpdated = this.changeNamesByIds(jsonEditorState);

      Object.keys(jsonEditorState.entityMap).forEach((key) => {
        if (jsonEditorState.entityMap[key].type === 'mention') {
          mentions.push({
            key: jsonEditorState.entityMap[key].data.mention.name,
            userId: jsonEditorState.entityMap[key].data.mention.id,
          });
        }
      });
    }

    if (inputUpdated) {
      onAvailable();
    } else {
      onUnavailable();
    }

    onInputChange({ editorState, comment: inputUpdated, mentions });
    this.setState({ editorState });
  };

  onSearchMentionChange = ({ value }) => {
    let usersList = [];
    this.setState({
      valueMentionUser: value,
    });
    searchEntities(false, 'USER', value).then((result) => {
      if (result) {
        result.map(user => usersList.push({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          job: user.job,
          profileImageCode: user.profileImageCode,
        }));

        this.setState((prevState) => {
          if (prevState.valueMentionUser === value) {
            return {
              suggestedMentions: usersList,
            };
          }
          return null;
        });
      }
    });
  };

  onSearchHashtagChange = ({ value }) => {
    let hashtagList = [];

    this.setState({
      valueMentionHashtag: value,
    });

    searchEntities(false, 'HASHTAG', value).then((result) => {
      if (result) {
        result.map(hashtag => hashtagList.push({
          hashtag: hashtag.hashtag,
          hid: hashtag.hid,
          name: `#${hashtag.hashtag}`,
          uses: hashtag.uses,
        }));

        this.setState((prevState) => {
          if (prevState.valueMentionHashtag === value) {
            return {
              suggestedHashtags: hashtagList,
            };
          }
          return null;
        });
      }
    });
  };

  setEditor(editor) {
    this.setState({
      editorState: editor,
    }, () => {
      this.focus();
    });
  }

  setText = (text) => {
    const { editorState } = this.state;
    const currentTextLength = this.getPlainTextLength(editorState);
    const selectedTextLength = this.getSelectedTextLength(editorState);

    if (currentTextLength - selectedTextLength + text.length <= this.props.maxCharacters) {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const ncs = Modifier.insertText(contentState, selection, text);
      const es = EditorState.push(editorState, ncs, 'insert-fragment');
      this.setState({
        editorState: es,
      }, () => {
        this.focus();
      });
    }
  }

  handlePaste = (text, html, editorState) => {
    const { maxCharacters, onInputChange } = this.props;
    const contentState = editorState.getCurrentContent();
    const currentTextLength = contentState.getPlainText().length;
    const pasteTextLength = text.length;
    const selection = window.getSelection();
    const selectedText = selection.toString();
    const selectedTextLength = selectedText.length;

    if (currentTextLength - selectedTextLength + pasteTextLength > maxCharacters) {
      let truncatedText = text.substring(0, maxCharacters - currentTextLength);

      if (selection.rangeCount > 0) {
        if (selectedTextLength >= maxCharacters) {
          let truncatedText = text.substring(0, maxCharacters);
          const contentState = ContentState.createFromText(truncatedText);
          const newState = EditorState.createWithContent(contentState);
          onInputChange({
            editorState: newState,
            comment: this.getPlainText(newState),
          });
          return 'handled';
        } else if (selectedTextLength > 0) {
          truncatedText =
            selectedText + truncatedText.substring(selectedTextLength);
          truncatedText = truncatedText.substring(0, maxCharacters);
        }
      }

      const newContentState = Modifier.replaceText(
        contentState,
        editorState.getSelection(),
        truncatedText,
        editorState.getCurrentInlineStyle(),
      );

      const newState = EditorState.push(
        editorState,
        newContentState,
        'insert-characters',
      );
      onInputChange({ editorState: newState, comment: this.getPlainText(newState) });
      return 'handled';
    }

    return '';
  };

  handleBeforeInput = () => {
    const currentText = this.state.editorState.getCurrentContent().getPlainText();
    if (currentText.length >= this.props.maxCharacters) {
      return 'handled';
    }
    return '';
  }

  changeNamesByIds = (jsonEditorState) => {
    const { bethereInteractions } = this.props;
    let finalComment = '';

    jsonEditorState.blocks.map((block) => {
      let tempTextBlock = block.text;

      block.entityRanges.map((entityRange) => {
        const entity = jsonEditorState.entityMap[entityRange.key];

        if (entity.type === 'mention') {
          const mentionsParser = bethereInteractions
            ? `@ID:${entity.data.mention.id}`
            : `@[${entity.data.mention.id}]`;

          tempTextBlock = tempTextBlock.replace(
            [...block.text].slice(
              entityRange.offset,
              entityRange.offset + entityRange.length,
            ).join(''),
            mentionsParser,
          );
        }
      });

      finalComment = finalComment.concat(`${tempTextBlock}\n`);
    });

    return finalComment;
  }

  focus = () => {
    this.editor.focus();
  };

  render() {
    const {
      className,
      placeholder,
      onKeyPress,
      usePortal,
      mentionsEnabled,
    } = this.props;

    const Entry = (props) => {
      const {
        mention,
        theme,
        searchValue, // eslint-disable-line no-unused-vars
        isFocused, // eslint-disable-line no-unused-vars
        ...parentProps
      } = props;
      return (
        <div {...parentProps} className={cn('rich-input-text__entry--item', { 'rich-input-text__entry--focused': isFocused })} >
          {mention.hashtag
            ? (
              <TrendItem hashtag={mention.hashtag} uses={mention.uses} id={mention.hid} />
            ) : (
              <AvatarMedia
                key={mention.id}
                profileImageCode={mention.profileImageCode}
                firstName={mention.name}
                job={mention.job}
                width={36}
                height={36}
                useLink={false}
              />
            )
          }
        </div>
      );
    };

    const { MentionSuggestions: MentionsList } = this.mention;
    const { MentionSuggestions: HashtagList } = this.hashtag;
    const plugins = [this.mention, this.hashtag];

    const renderMentionsSuggestions = () => (
      <MentionsList
        onSearchChange={this.onSearchMentionChange}
        suggestions={this.state.suggestedMentions}
        entryComponent={Entry}
      />
    );

    const renderHashtagsSuggestions = () => (
      <HashtagList
        onSearchChange={this.onSearchHashtagChange}
        suggestions={this.state.suggestedHashtags}
        entryComponent={Entry}
      />
    );

    return (
      <div className={className} onClick={this.focus}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          ref={(element) => { this.editor = element; }}
          placeholder={placeholder}
          keyBindingFn={onKeyPress}
          handlePastedText={this.handlePaste}
          handleBeforeInput={this.handleBeforeInput}
        />
        {usePortal
          ? (
            <Portal>
              {mentionsEnabled &&
                renderMentionsSuggestions()
              }
              {renderHashtagsSuggestions()}
            </Portal>
          ) : (
            <span>
              {mentionsEnabled &&
                renderMentionsSuggestions()
              }
              {renderHashtagsSuggestions()}
            </span>
          )
        }
      </div>
    );
  }
}

RichInputText.defaultProps = {
  bethereInteractions: false,
  maxCharacters: 140,
  mentionsEnabled: false,
  onAvailable: () => { },
  onUnavailable: () => { },
  usePortal: false,
};

RichInputText.propTypes = {
  bethereInteractions: PropTypes.bool,
  className: PropTypes.string,
  editorState: PropTypes.object,
  emoji: PropTypes.string,
  handleAvailable: PropTypes.func,
  handleUnavailable: PropTypes.func,
  isFocused: PropTypes.bool,
  maxCharacters: PropTypes.number,
  mention: PropTypes.object,
  mentionsEnabled: PropTypes.bool,
  onAvailable: PropTypes.func,
  onInputChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onUnavailable: PropTypes.func,
  placeholder: PropTypes.string,
  searchValue: PropTypes.string,
  setEmoji: PropTypes.func,
  shouldFocus: PropTypes.bool,
  theme: PropTypes.object,
  usePortal: PropTypes.bool,
};

export default RichInputText;
