// @packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @app
import InfiniteScroll from 'components/InfiniteScroll';
import { getFeedNextPage } from 'containers/withFeed/actions';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const withNextPage = (WrappedComponent) => {
  const WithNextPage = ({
    getFeedNextPage,
    results,
    fetching,
    hasMore,
    ...rest
  }) => (
    <InfiniteScroll
      dataLength={results?.length}
      hasMore={!fetching && hasMore}
      loader={null}
      loadingType="starmeup"
      next={getFeedNextPage}
    >
      <WrappedComponent fetching={fetching} hasMore={hasMore} {...rest} />
    </InfiniteScroll>
  );

  WithNextPage.displayName = `WithNextPage(${getDisplayName(
    WrappedComponent,
  )})`;

  WithNextPage.propTypes = {
    fetching: PropTypes.bool.isRequired,
    getFeedNextPage: PropTypes.func.isRequired,
    hasMore: PropTypes.bool,
  };

  const mapStateToProps = ({ feed }) => ({
    fetching: feed?.fetching,
    results: feed?.results,
    hasMore: feed?.hasMore,
  });

  return connect(mapStateToProps, { getFeedNextPage })(WithNextPage);
};

export default withNextPage;
