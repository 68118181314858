// @packages
import React, { useState, useRef } from 'react';
import CropperComponent from 'smu-ui-components/Cropper';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { trackEvent } from 'smu-utils/gtm';

// @app
import GenericModal from 'components/GenericModal';
import { changeProfileImage } from 'containers/UserInfo/actions';

// @own
import './styles.scss';
import messages from './messages';
import { CROPPER, DISCARD } from './constants';

function UploadProfileImageModal({
  changeProfileImage,
  file,
  intl: { formatMessage },
  onClose,
  user,
  ...rest
}) {
  const [type, setType] = useState(CROPPER);
  const childCropperRef = useRef();

  const handleCropImage = async () => {
    if (childCropperRef?.current) {
      const { exportToBlob } = childCropperRef?.current;
      let fileBlob = await exportToBlob();
      changeProfileImage({
        file: fileBlob,
        id: user.id,
        texts: {
          error: formatMessage(messages.uploadProfileImageError),
          loading: formatMessage(messages.uploadProfileImageLoading),
          success: formatMessage(messages.uploadProfileImageSuccess),
        },
      });
      trackEvent({
        action: 'picture_success',
        category: 'profile',
      });
      onClose();
    }
  };

  const handleModalClose = () => {
    trackEvent({
      action: 'picture_cancel',
      category: 'profile',
    });
    onClose();
  };

  const getProps = (type) => {
    const modalProps = {
      CROPPER: {
        actions: [
          {
            children: formatMessage(messages.uploadProfileImageCropperCancel),
            onClick: () => setType(DISCARD),
            variant: 'outline',
          },
          {
            children: formatMessage(messages.uploadProfileImageCropperSave),
            onClick: handleCropImage,
          },
        ],
        children: <CropperComponent
          file={file}
          ref={childCropperRef}
          roundMask
          {...rest}
        />,
        onClose: type === CROPPER ? () => setType(DISCARD) : handleModalClose,
        title: formatMessage(messages.uploadProfileImageCropperTitle),
      },
      DISCARD: {
        actions: [
          {
            children: formatMessage(messages.uploadProfileImageNo),
            onClick: () => setType(CROPPER),
            variant: 'outline',
          },
          {
            children: formatMessage(messages.uploadProfileImageYes),
            onClick: handleModalClose,
          },
        ],
        children: formatMessage(messages.uploadProfileImageDiscardChangesMessage),
        title: formatMessage(messages.uploadProfileImageDiscardChanges),
      },
    };

    return modalProps[type];
  };

  return (
    <GenericModal
      className={cn(
        'upload-profile-user-modal',
        { 'upload-profile-user-modal--cropper': type === CROPPER },
      )}
      {...getProps(type)}
      {...rest}
    />
  );
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

UploadProfileImageModal.propTypes = {
  changeProfileImage: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps, {
  changeProfileImage,
})(injectIntl(UploadProfileImageModal));
