import {
  REQUEST_USER_CONFIGS,
  REQUEST_USER_CONFIGS_SUCCESS,
  REQUEST_USER_CONFIGS_FAIL,
} from './actionTypes';

export function requestUserConfigs() {
  return {
    type: REQUEST_USER_CONFIGS,
  };
}

export function requestUserConfigsSuccess(payload) {
  return {
    type: REQUEST_USER_CONFIGS_SUCCESS,
    payload,
  };
}

export function requestUserConfigsFail(payload) {
  return {
    type: REQUEST_USER_CONFIGS_FAIL,
    payload,
  };
}

