// @packages
import React from 'react';
import ModalOneOnOneRequest from 'smu-app-components/OneOnOne/Request/Modal';
import UtilsProvider from 'smu-app-components/UtilsProvider';
import { useSelector } from 'react-redux';

// @app
import { ENABLED_ONE_ON_ONE } from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';

const ModalOneOnOneRequestContainer = props => {
  const enabledOneOnOne = useSelector(
    state => getCommunityConfigValueBool(state, ENABLED_ONE_ON_ONE),
  );

  const utils = {
    mainFeatureIsEnabled: enabledOneOnOne,
  };

  return (
    <UtilsProvider utils={utils}>
      <ModalOneOnOneRequest {...props} />
    </UtilsProvider>
  );
};

export default ModalOneOnOneRequestContainer;
