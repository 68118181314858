// @packages
import React, { useEffect } from 'react';
import Container from 'smu-layout-components/Container';
import Link from 'smu-ui-components/Link';
import Media from 'react-media';
import PendingTasksWidget from 'smu-app-components/PendingTasksWidget';
import PropTypes from 'prop-types';
import TrendingHashtagsWidget from 'smu-app-components/TrendingHashtags/Widget';
import WeeklyActivityWidget from 'smu-app-components/BeHealthy/WeeklyActivityWidget';
import get from 'lodash/get';
import { connect, useDispatch, useSelector } from 'react-redux';

// @app
import BadgesContainer from 'containers/Badges';
import ChillOut from 'components/Widgets/ChillOut';
import CommunityCounters from 'containers/CommunityCounters';
import CongratulationsWidget from 'containers/CongratulationsWidget';
import CoreValuesPanel from 'containers/CoreValuesPanel';
import FeedV2 from 'containers/FeedV2';
import MainPanel from 'components/MainPanel';
import RecognitionExpanded from 'components/OS/Event/RecognitionContent/RecognitionExpanded';
import RightColumn from 'components/RightColumn';
import StarSelector from 'containers/StarSelector';
import StickyWrapper from 'components/StickyWrapper';
import SurveysWidget from 'containers/SurveysWidget';
import TopStarPanel from 'containers/TopStarsPanel';
import UserProfile from 'containers/UserProfile';
import { DESKTOP_SM } from 'utils/screenResolutions';
import {
  ENABLED_ANNIVERSARIES,
  ENABLED_BADGES,
  ENABLED_BEHEALTHY,
  ENABLED_BEKIND,
  ENABLED_BIRTHDAYS,
  ENABLED_PENDING_TASKS_HOME,
  ENABLED_TAKEPART_SURVEY,
  ENABLED_TRENDING_HASHTAGS,
  STAR_SELECTOR_VERSION,
} from 'services/communityConfigs/constants';
import { getCommunityConfigValueBool, getCommunityConfigValue } from 'services/communityConfigs/selectors';
import { getProfile } from 'containers/withProfile/actions';
import { selectSessionUser } from 'containers/Authorization/selectors';

// @own
import './styles.scss';
import { selectCommunityId } from './selectors';

function Home({
  atTop,
  communityCounters,
  enabledAnniversaries,
  enabledBadges,
  enabledBeHealthy,
  enabledBeKind,
  enabledBirthdays,
  enabledPendingTasks,
  enabledTakePartSurvey,
  enabledTrendingHashtags,
  profile,
  scrolledUp,
  starSelectorVersion,
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectSessionUser);
  const buildHashtagUrl = hashtag => `/hashtags/${hashtag}`;
  const offsetTop = starSelectorVersion === 'V2' ? 136 : 80;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!profile) {
      dispatch(
        getProfile({
          uid: currentUser.uid,
        }),
      );
    }
  }, [currentUser]);

  return (
    <div>
      <RecognitionExpanded />
      {starSelectorVersion === 'V2' ? (
        <StarSelector />
      ) : (
        <CoreValuesPanel bottomPassed={!atTop} scrolledUp={scrolledUp} />
      )}
      <Container centered className="home-layout">
        <div className="ui-components home-layout__grid">
          <div className="ui-components suite-column suite-column-desktop-size-3 suite-column-desktop-sm-size-4 hide-from-mobile">
            <Media query={{ maxWidth: DESKTOP_SM }}>
              {(matches) =>
                (matches ? (
                  <div className="space-y-4">
                    <UserProfile showStars showPanel />
                    {enabledPendingTasks && <PendingTasksWidget />}
                    {enabledTakePartSurvey && <SurveysWidget />}
                    {(enabledBirthdays || enabledAnniversaries) && (
                      <CongratulationsWidget />
                    )}
                    <ChillOut />
                    {enabledBadges && (
                      <BadgesContainer
                        basePath="home"
                        isOwnProfile
                        userId={currentUser?.id}
                        userName={currentUser?.firstName}
                        withCard
                      />
                    )}
                    {enabledBeHealthy && enabledBeKind && (
                      <WeeklyActivityWidget
                        buttonProps={{
                          component: Link,
                          href: '/me/be-kind',
                          legacy: false,
                        }}
                        userIsNotEnrolledProps={{
                          buttonProps: {
                            component: Link,
                            href: '/me/be-kind',
                            legacy: false,
                          },
                        }}
                      />
                    )}
                    {enabledTrendingHashtags && (
                      <TrendingHashtagsWidget buildHashtagUrl={buildHashtagUrl} shadow />
                    )}
                    {communityCounters && <CommunityCounters />}
                    <TopStarPanel />
                  </div>
                ) : (
                  <StickyWrapper top={offsetTop} innerZ={10}>
                    <div className="space-y-4 flex flex-col">
                      <UserProfile showStars showPanel />
                      {enabledBadges && (
                        <BadgesContainer
                          basePath="home"
                          isOwnProfile
                          userId={currentUser?.id}
                          userName={currentUser?.firstName}
                          withCard
                        />
                      )}
                      {enabledBeHealthy && enabledBeKind && (
                        <WeeklyActivityWidget
                          buttonProps={{
                            component: Link,
                            href: '/me/be-kind',
                            legacy: false,
                          }}
                          userIsNotEnrolledProps={{
                            buttonProps: {
                              component: Link,
                              href: '/me/be-kind',
                              legacy: false,
                            },
                          }}
                        />
                      )}
                      {enabledTrendingHashtags && (
                        <TrendingHashtagsWidget buildHashtagUrl={buildHashtagUrl} shadow />
                      )}
                    </div>
                  </StickyWrapper>
                ))
              }
            </Media>
          </div>
          <div className="ui-components suite-column suite-column-desktop-size-6 suite-column-desktop-sm-size-8 suite-column-tablet-size-12">
            <MainPanel>
              <FeedV2 />
            </MainPanel>
          </div>
          <Media query={{ maxWidth: DESKTOP_SM }}>
            {(matches) =>
              !matches && (
                <div className="ui-components suite-column suite-column-desktop-size-3">
                  <StickyWrapper top={offsetTop} innerZ={10}>
                    <div className="space-y-4">
                      <ChillOut />
                      {enabledPendingTasks && <PendingTasksWidget />}
                      {enabledTakePartSurvey && <SurveysWidget />}
                      {(enabledBirthdays || enabledAnniversaries) && (
                        <CongratulationsWidget />
                      )}
                      {communityCounters && <CommunityCounters />}
                      {!(enabledPendingTasks) && (
                        <RightColumn>
                          <TopStarPanel />
                        </RightColumn>
                      )}
                    </div>
                  </StickyWrapper>
                </div>
              )
            }
          </Media>
        </div>
      </Container>
    </div>
  );
}

Home.propTypes = {
  atTop: PropTypes.bool,
  communityCounters: PropTypes.bool,
  enabledAnniversaries: PropTypes.bool,
  enabledBadges: PropTypes.bool,
  enabledBeHealthy: PropTypes.bool,
  enabledBeKind: PropTypes.bool,
  enabledBirthdays: PropTypes.bool,
  enabledTakePartSurvey: PropTypes.bool,
  enabledTrendingHashtags: PropTypes.bool,
  location: PropTypes.object,
  params: PropTypes.object,
  profile: PropTypes.object,
  router: PropTypes.object,
  scrolledUp: PropTypes.bool,
  starSelectorVersion: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { session, profile } = state;

  return {
    communityCounters: session.communityConfigurations.comunityCounters,
    communityId: selectCommunityId(state),
    enabledAnniversaries: getCommunityConfigValueBool(state, ENABLED_ANNIVERSARIES),
    enabledBadges: getCommunityConfigValueBool(state, ENABLED_BADGES),
    enabledBeHealthy: getCommunityConfigValueBool(state, ENABLED_BEHEALTHY),
    enabledBeKind: getCommunityConfigValueBool(state, ENABLED_BEKIND),
    enabledBirthdays: getCommunityConfigValueBool(state, ENABLED_BIRTHDAYS),
    enabledPendingTasks: getCommunityConfigValueBool(state, ENABLED_PENDING_TASKS_HOME),
    enabledTakePartSurvey: getCommunityConfigValueBool(state, ENABLED_TAKEPART_SURVEY),
    enabledTrendingHashtags: getCommunityConfigValueBool(state, ENABLED_TRENDING_HASHTAGS),
    profile: get(profile, 'result', null),
    starSelectorVersion: getCommunityConfigValue(state, STAR_SELECTOR_VERSION),
  };
};

export default connect(mapStateToProps)(Home);
