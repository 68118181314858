import {
  CLEAN_PROFILE,
} from 'containers/withProfile/actionTypes';

import {
  CHANGE_PROFILE_VIEW,
} from './actionTypes';
import { ABOUT } from './constants';


const initialState = {
  results: [],
  currentView: ABOUT,
  error: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CHANGE_PROFILE_VIEW:
      return {
        ...state,
        currentView: payload.view,
      };
    case CLEAN_PROFILE:
      return initialState;
    default:
      return state;
  }
}
