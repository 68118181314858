// @packages
import React from 'react';
import Container from 'smu-layout-components/Container';
import MyTeam from 'smu-app-components/MyTeam';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ENABLED_BETTERME } from 'services/communityConfigs/constants';
import { connect } from 'react-redux';
import { useCommunity } from 'smu-services/betterme/api/community';

// @app
import MyTeamDashboard from 'containers/MyTeam/Dashboard';
import { getCommunityConfigValueBool } from 'services/communityConfigs/selectors';
import { selectCommunityId } from 'containers/Authorization/selectors';

function MyTeamLayout({ communityId, enabledBetterme }) {
  const { isLoading, error } = useCommunity({
    shouldFetch: enabledBetterme,
    communityId,
  });

  return (
    <div className={cn('pt-16 h-full')}>
      <Container centered className="h-full">
        <MyTeam
          className="py-8"
          isError={!!error}
          isLoading={isLoading}
          path="/myteam/dashboard"
        >
          <MyTeamDashboard className="mt-5" />
        </MyTeam>
      </Container>
    </div>
  );
}

MyTeamLayout.propTypes = {
  communityId: PropTypes.string,
  enabledBetterme: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  communityId: selectCommunityId(state),
  enabledBetterme: getCommunityConfigValueBool(state, ENABLED_BETTERME),
});

export default connect(mapStateToProps)(MyTeamLayout);
